import type { FC } from "react";
import type { IdentityRolesEnum } from "sdk/gql/graphql";

import { Layout } from "antd";
import BottomBar from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/BottomBar/index";
import { useAuth } from "contexts/AuthContext";
import styles from "layouts/PortalLayout.module.less";

const { Footer } = Layout;

const ClaimCaseActionsBar: FC = () => {
  const { selectedRole } = useAuth();

  return ["ClaimAssessor", "ClaimAssessorManager", "TenantAdmin"].includes(selectedRole as IdentityRolesEnum) ? (
    <Footer className={styles.footer}>
      <BottomBar />
    </Footer>
  ) : null;
};

export default ClaimCaseActionsBar;
