import type { IAuth } from "contexts/types";

import { createContext, useContext } from "react";
import createApolloClient from "services/apolloClientServices";

export function resetApolloCache(cache) {
  cache.reset();
}

const AuthContext = createContext<IAuth | null>({
  accessPermissions: [],
  apolloClient: createApolloClient(),
  claimStatusValueToComment: () => null,
  hasAccessPermissions: () => false,
  hasRole: () => false,
  isLoggedIn: false,
  oauthSignIn: () => {},
  setRequestPermissions: () => {},
  setRole: () => {},
  signIn: () => {},
  signInLoading: false,
  signOut: () => {},
  user: {},
});
AuthContext.displayName = "AuthContext";

function useAuth() {
  const context = useContext(AuthContext ?? {});

  if (context == null) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthContext, useAuth };
