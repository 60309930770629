import type { PlanCopayMechanismsEnum } from "sdk/gql/graphql";

import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import utils from "libs/utils";
import { lazy } from "react";

const COPAY_MECHANISMS: Record<PlanCopayMechanismsEnum, React.FC<any>> = {
  AAAAFFINA: lazy(() => import("./Mechanisms/AAAAFFINA").catch(utils.loadErrorHandler)),
  AAACorporate: lazy(() => import("./Mechanisms/AAACorporate").catch(utils.loadErrorHandler)),
  AIG2024: lazy(() => import("./Mechanisms/AIG2024").catch(utils.loadErrorHandler)),
  AIH: lazy(() => import("./Mechanisms/AIH").catch(utils.loadErrorHandler)),
  AxonActive2024: lazy(() => import("./Mechanisms/AxonActive2024").catch(utils.loadErrorHandler)),
  AxonActiveDependant: lazy(() => import("./Mechanisms/AxonActiveDependant").catch(utils.loadErrorHandler)),
  BCCard2024: lazy(() => import("./Mechanisms/BCCard2024").catch(utils.loadErrorHandler)),
  COPAY23ADM23: lazy(() => import("./Mechanisms/COPAY23ADM23").catch(utils.loadErrorHandler)),
  COPAY24ADM23: lazy(() => import("./Mechanisms/COPAY24ADM24").catch(utils.loadErrorHandler)),
  Elcom: lazy(() => import("./Mechanisms/Elcom").catch(utils.loadErrorHandler)),
  FCVAndFCH: lazy(() => import("./Mechanisms/FCVAndFCH").catch(utils.loadErrorHandler)),
  FCVAndFCH2024: lazy(() => import("./Mechanisms/FCVAndFCH2024").catch(utils.loadErrorHandler)),
  FreyssinetAll: lazy(() => import("./Mechanisms/FreyssinetAll").catch(utils.loadErrorHandler)),
  FwdCare100And250And500: lazy(() => import("./Mechanisms/FwdCare100And250And500").catch(utils.loadErrorHandler)),
  FwdCare100And250And500Ver1: lazy(() => import("./Mechanisms/FwdCare100And250And500Ver1").catch(utils.loadErrorHandler)),
  Grove2024: lazy(() => import("./Mechanisms/Grove2024").catch(utils.loadErrorHandler)),
  HANES2024: lazy(() => import("./Mechanisms/HANES2024").catch(utils.loadErrorHandler)),
  HDbank: lazy(() => import("./Mechanisms/HDbank").catch(utils.loadErrorHandler)),
  HomeCredit2024: lazy(() => import("./Mechanisms/HomeCredit2024").catch(utils.loadErrorHandler)),
  HomeCreditAndEmbedit: lazy(() => import("./Mechanisms/HomeCreditAndEmbedit").catch(utils.loadErrorHandler)),
  InPatientMaternityOutPatientDentalAllMedicalProviders10Percent: lazy(() =>
    import("./Mechanisms/InPatientMaternityOutPatientDentalAllMedicalProviders10Percent").catch(utils.loadErrorHandler),
  ),
  InPatientMaternityOutPatientDentalAllMedicalProviders20Percent: lazy(() =>
    import("./Mechanisms/InPatientMaternityOutPatientDentalAllMedicalProviders20Percent").catch(utils.loadErrorHandler),
  ),
  InPatientMaternityOutPatientDentalAllMedicalProviders30Percent: lazy(() =>
    import("./Mechanisms/InPatientMaternityOutPatientDentalAllMedicalProviders30Percent").catch(utils.loadErrorHandler),
  ),
  InPatientMaternityOutPatientDentalBlvpForSpecificMedicalProviders20Percent: lazy(() =>
    import("./Mechanisms/InPatientMaternityOutPatientDentalBlvpForSpecificMedicalProviders20Percent").catch(utils.loadErrorHandler),
  ),
  InPatientMaternityOutPatientDentalForSpecificMedicalProviders20Percent: lazy(() =>
    import("./Mechanisms/InPatientMaternityOutPatientDentalForSpecificMedicalProviders20Percent").catch(utils.loadErrorHandler),
  ),
  InPatientOutPatientDentalAllMedicalProviders20Percent: lazy(() => import("./Mechanisms/InPatientOutPatientDentalAllMedicalProviders20Percent").catch(utils.loadErrorHandler)),
  LG2024: lazy(() => import("./Mechanisms/LgVer2024").catch(utils.loadErrorHandler)),
  LGElectronicsDevelopment: lazy(() => import("./Mechanisms/LGElectronicsDevelopment").catch(utils.loadErrorHandler)),
  LgElectronicsVietnamHaiPhong: lazy(() => import("./Mechanisms/LgElectronicsVietnamHaiPhong").catch(utils.loadErrorHandler)),
  MPHN2024: lazy(() => import("./Mechanisms/MPHN2024").catch(utils.loadErrorHandler)),
  Multiplier: lazy(() => import("./Mechanisms/Multiplier").catch(utils.loadErrorHandler)),
  QuocTeHanQuocHCM: lazy(() => import("./Mechanisms/QuocTeHanQuocHCM").catch(utils.loadErrorHandler)),
  Schaeffler2024: lazy(() => import("./Mechanisms/Schaeffler2024").catch(utils.loadErrorHandler)),
  Songmoi2024: lazy(() => import("./Mechanisms/Songmoi2024").catch(utils.loadErrorHandler)),
  SonToa: lazy(() => import("./Mechanisms/SonToa").catch(utils.loadErrorHandler)),
  TEKA2024: lazy(() => import("./Mechanisms/TEKA2024").catch(utils.loadErrorHandler)),
  VNG2024AllMedicalProvider20Percent: lazy(() => import("./Mechanisms/VNG2024AllMedicalProvider20Percent").catch(utils.loadErrorHandler)),
  VNG2024AllMedicalProvider30Percent: lazy(() => import("./Mechanisms/VNG2024AllMedicalProvider30Percent").catch(utils.loadErrorHandler)),
  VNG2024PrivateMedicalProvider: lazy(() => import("./Mechanisms/VNG2024PrivateMedicalProvider").catch(utils.loadErrorHandler)),
  VngSponsor: lazy(() => import("./Mechanisms/VngSponsor").catch(utils.loadErrorHandler)),
  VngVolunteer: lazy(() => import("./Mechanisms/VngVolunteer").catch(utils.loadErrorHandler)),
  YOLA2024: lazy(() => import("./Mechanisms/Yola2024").catch(utils.loadErrorHandler)),
} as const;

const Copay = () => {
  const { claimDateRanges, isDetailFormFilled } = useClaim();

  const claimPlans = claimDateRanges?.claim_date_ranges.ranges ?? [];

  if (claimPlans.length > 1) {
    return null;
  }

  const plan = claimPlans[0]?.plan;

  if (plan?.copay_mechanism == null) {
    return null;
  }
  const CopayMechanismComponent = COPAY_MECHANISMS[plan.copay_mechanism];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (CopayMechanismComponent == null) {
    return null;
  }

  return <CopayMechanismComponent disabled={!isDetailFormFilled} />;
};

export default Copay;
