import { init as initApm } from "@elastic/apm-rum";
import ErrorBoundary from "app/common/components/ErrorBoundary";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./app";

const router = createBrowserRouter([{ Component: Root, ErrorBoundary, path: "*" }]);

const apm = initApm({
  distributedTracingOrigins: ["https://apple.papaya.services"],

  environment: import.meta.env.DEV ? "development" : "production",

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: "https://ba3d7d3cfabb4bd380eb2b0d351bdf74.apm.asia-southeast1.gcp.elastic-cloud.com:443",

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: "cassava",
  // Set service version (required for sourcemap feature)
  serviceVersion: "1",
});

const App = () => <RouterProvider router={router} />;

const root = createRoot(document.querySelector("#root") as HTMLElement);
// root.render(<App />);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
