// export type ClaimCaseDates = "admissionDate" | "dischargeDate" | "physicalExaminationDate" | "eventDate";

import { InsuredBenefitTypesEnum } from "sdk/gql/types";

export interface DateItem {
  key: "admissionDate" | "dischargeDate" | "eventDate" | "physicalExaminationDate";
  key_snake_case: "admission_date" | "discharge_date" | "event_date" | "physical_examination_date";
  label: string;
}

export const ADMISSION_DATE: DateItem = {
  key: "admissionDate",
  key_snake_case: "admission_date",
  label: "Ngày vào viện",
};
export const DISCHARGE_DATE: DateItem = {
  key: "dischargeDate",
  key_snake_case: "discharge_date",
  label: "Ngày ra viện",
};
export const EVENT_DATE: DateItem = {
  key: "eventDate",
  key_snake_case: "event_date",
  label: "Ngày sự kiện bảo hiểm",
};
export const PHYSICAL_EXAMINATION_DATE: DateItem = {
  key: "physicalExaminationDate",
  key_snake_case: "physical_examination_date",
  label: "Ngày thăm khám",
};

export const DateItems: DateItem[] = [ADMISSION_DATE, DISCHARGE_DATE, EVENT_DATE, PHYSICAL_EXAMINATION_DATE];

export const claimCaseDates = {
  Accident: [EVENT_DATE, PHYSICAL_EXAMINATION_DATE],
  [InsuredBenefitTypesEnum.Dental]: [PHYSICAL_EXAMINATION_DATE],
  [InsuredBenefitTypesEnum.InPatient]: [ADMISSION_DATE, DISCHARGE_DATE],
  [InsuredBenefitTypesEnum.Life]: [EVENT_DATE],
  [InsuredBenefitTypesEnum.Maternity]: [ADMISSION_DATE, DISCHARGE_DATE],
  [InsuredBenefitTypesEnum.Others]: [],
  [InsuredBenefitTypesEnum.OutPatient]: [PHYSICAL_EXAMINATION_DATE],
} as const;

export const claimCaseDateRequired = {
  Accident: [EVENT_DATE.key],
  [InsuredBenefitTypesEnum.Dental]: [PHYSICAL_EXAMINATION_DATE.key],
  [InsuredBenefitTypesEnum.InPatient]: [ADMISSION_DATE.key, DISCHARGE_DATE.key],
  [InsuredBenefitTypesEnum.Life]: [EVENT_DATE.key],
  [InsuredBenefitTypesEnum.Maternity]: [ADMISSION_DATE.key, DISCHARGE_DATE.key],
  [InsuredBenefitTypesEnum.Others]: [],
  [InsuredBenefitTypesEnum.OutPatient]: [PHYSICAL_EXAMINATION_DATE.key],
};

export const claimCaseDateDisabled = {
  Accident: [EVENT_DATE.key],
  [InsuredBenefitTypesEnum.Dental]: [PHYSICAL_EXAMINATION_DATE.key],
  [InsuredBenefitTypesEnum.InPatient]: [ADMISSION_DATE.key],
  [InsuredBenefitTypesEnum.Life]: [EVENT_DATE.key],
  [InsuredBenefitTypesEnum.Maternity]: [ADMISSION_DATE.key],
  [InsuredBenefitTypesEnum.Others]: [],
  [InsuredBenefitTypesEnum.OutPatient]: [PHYSICAL_EXAMINATION_DATE.key],
};

export const InsuranceEventDatesBasedOnBenefitType = {
  Accident: { event_date: EVENT_DATE, physical_examination_date: PHYSICAL_EXAMINATION_DATE },
  [InsuredBenefitTypesEnum.Dental]: { physical_examination_date: PHYSICAL_EXAMINATION_DATE },
  [InsuredBenefitTypesEnum.InPatient]: { admission_date: ADMISSION_DATE, discharge_date: DISCHARGE_DATE },
  [InsuredBenefitTypesEnum.Life]: { event_date: EVENT_DATE },
  [InsuredBenefitTypesEnum.Maternity]: { admission_date: ADMISSION_DATE, discharge_date: DISCHARGE_DATE },
  [InsuredBenefitTypesEnum.Others]: [],
  [InsuredBenefitTypesEnum.OutPatient]: { physical_examination_date: PHYSICAL_EXAMINATION_DATE },
} as const;

export const FwdInsuranceEventDatesBasedOnBenefitType = {
  [InsuredBenefitTypesEnum.Dental]: {
    admission_date: PHYSICAL_EXAMINATION_DATE,
  },
  [InsuredBenefitTypesEnum.InPatient]: {
    admission_date: ADMISSION_DATE,
    discharge_date: DISCHARGE_DATE,
  },
  [InsuredBenefitTypesEnum.OutPatient]: {
    admission_date: PHYSICAL_EXAMINATION_DATE,
  },
} as const;

export const FwdClaimCaseFieldMapping = {
  abuse_indicator: "Có dấu hiệu gian lận",
  admit_date: "Ngày vào viện",
  amount: "Số tiền yêu cầu sau thẩm định",
  claim_benefit_code: "Mã quyền lợi",
  claim_eligible_amount: "Số tiền bồi thường",
  claim_form: "Claim Form",
  claim_pendings: "Yêu cầu bổ sung",
  claim_pendings__followUpCode: "[PENDING] Mã pending",
  claim_pendings__followUpStatus: "[PENDING] Trạng thái pending",
  claim_reference: "Mã YCBT",
  claim_sequence_no: "Chuỗi claim",
  claim_total_amount: "Số tiền yêu cầu sau thẩm định",
  claim_type: "Loại claim",
  claimPendings: "Pending",
  claimReference: "Mã YCBT",
  claimTotalAmount: "Số tiền yêu cầu sau thẩm định",
  decision: "Chấp thuận",
  description: "Mô tả",
  discharge_date: "Ngày ra viện",
  discharge_papers: "Giấy ra viện",
  diseases: "Chẩn đoán",
  diseases__diseases_id: "[ICD] ID ICD",
  diseases__diseases_name: "[ICD] Tên ICD",
  ins_id: "Mã người được bảo hiểm",
  is_decision: "Kết quả chấp thuận",
  is_direct_billing: "Bảo lãnh viện phí",
  is_jet_claim: "Là claim JET",
  medical_provider__medical_provider_id: "[CSYT] ID CSYT",
  medical_provider__medical_provider_name: "[CSYT] Tên CSYT",
  other_papers: "Giấy tờ khác",
  payment_amount: "Số tiền chi trả",
  payment_method__bank_acc: "[Thanh toán] Tài khoản ngân hàng",
  payment_method__bank_branch: "[Thanh toán] Chi nhánh",
  payment_method__bank_city: "[Thanh toán] Tỉnh/Thành phố",
  payment_method__bank_id: "[Thanh toán] ID ngân hàng",
  payment_method__issue_date: "[Thanh toán] Ngày hiệu lực của CMND/CCCD",
  payment_method__issue_place: "[Thanh toán] Nơi cấp CMND/CCCD",
  payment_method__method: "[Thanh toán] Phương thức",
  payment_method__personal_id: "[Thanh toán] CMND/CCCD",
  payment_method__premium_polname: "[Thanh toán] Tên chủ hợp đồng",
  payment_method__premium_polnum: "[Thanh toán] Mã hợp đồng",
  payment_source: "Bên thanh toán",
  plan_code: "Mã sản phẩm",
  po_id: "Mã chủ hợp đồng",
  pol_num: "Số hợp đồng",
  product_info__: "[Sản phẩm]",
  product_info__plan_code: "[Sản phẩm] Mã sản phẩm",
  product_info__pol_num: "[Sản phẩm] Mã hợp đồng",
  reject_reason: "Lý do từ chối",
  reject_reason_desc: "Mô tả lý do từ chối",
  request_claim_id: "Mã claim",
  status: "Trạng thái phản hồi",
} as const;

export const FwdAdjustCallBackLogFieldOrder = ["claim_reference", "amount", "claim_pendings"] as const;
