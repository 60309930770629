import type { FC } from "react";

import { yellow } from "@ant-design/colors";
import { Space, Tooltip } from "antd";
import { CLAIM_ICON_FRAGMENT } from "app/common/components/ClaimIcons/query";
import { PORTAL_PATH } from "app/portal/config/paths";
import { CLAIM_DETAIL_PATH, getInsurerClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/utils";
import { papaya } from "config/colors";
import usePQuery from "contexts/usePQuery";
import { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import { Bot, Building2, Cog, ExternalLink, HeartHandshake, Laptop, Mail, Mailbox, Rocket, Siren, Smartphone, Stethoscope, Sun, User } from "lucide-react";
import { generatePath, Link, useSearchParams } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import If from "../If";
import LabelDisplay from "../LabelDisplay";

type Props = {
  claim: {
    id: string;
  };
  newTab?: boolean;
  showCode?: boolean;
  showLabels?: boolean;
  showLALabels?: boolean;
};

const ClaimDataForClaimIconQuery = graphql(
  `
    query ClaimIcon($claimCaseId: uuid!) {
      claim_cases_by_pk(claim_case_id: $claimCaseId) {
        id
        ...ClaimDetailForTat
        ...ClaimIcons
      }
    }
  `,
  [CLAIM_DETAIL_FOR_TAT_FRAGMENT, CLAIM_ICON_FRAGMENT],
);

const ClaimIcons: FC<Props> = ({ claim: { id }, newTab = false, showCode = true, showLabels = true, showLALabels = true }) => {
  const { data } = usePQuery(ClaimDataForClaimIconQuery, { variables: { claimCaseId: id } });
  const claim = data?.claim_cases_by_pk;
  const [searchParams, setSearchParams] = useSearchParams({ keyword: "", page: "1", size: "30" });

  if (claim == null) return null;
  const insurerClaimType = getInsurerClaimType(claim);
  const path = CLAIM_DETAIL_PATH[insurerClaimType];
  const directBilling = searchParams.get("direct_billing") === "true";
  const isJet = searchParams.get("is_jet") === "true";
  const isStp = searchParams.get("is_stp") === "true";
  const requiredTat = claim.insured_certificate.policy.policy_setting?.tat_max ?? 24;
  const isOverTat = claim.tatInHourUpToNow > (claim.insured_certificate.policy.policy_setting?.tat_max ?? 24);
  let icon;

  switch (claim.source) {
    case "CARE_APP":
    case "InsuredPersons":
    case "MBAL_APP": {
      icon = (
        <Tooltip title="Nguồn: Papaya Care">
          <Smartphone size={16} />
        </Tooltip>
      );
      break;
    }
    case "POST": {
      icon = (
        <Tooltip title="Nguồn: Bưu điện">
          <Mailbox size={16} />
        </Tooltip>
      );
      break;
    }
    case "MedicalProviders": {
      icon = (
        <Tooltip title="Nguồn: Cơ sở y tế">
          <Stethoscope size={15} />
        </Tooltip>
      );
      break;
    }
    case "Assessments": {
      icon = (
        <Tooltip title="Nguồn: Thẩm định viên">
          <User size={15} />
        </Tooltip>
      );
      break;
    }
    case "SLV_SYSTEM": {
      icon = (
        <Tooltip title="Nguồn: Mặt trời">
          <Sun color="orange" size={15} />
        </Tooltip>
      );
      break;
    }
    case "EMAIL": {
      icon = (
        <Tooltip title="Nguồn: Email">
          <div>
            <If condition={claim.source_emails[0]?.email_id != null} else={<Mail size={16} />}>
              <Link
                to={
                  claim.source_emails[0]?.email_id == null
                    ? ""
                    : generatePath(PORTAL_PATH.MAILBOX.EMAIL_DETAIL, {
                        emailId: claim.source_emails[0]?.email_id,
                        mailbox: "",
                      })
                }
              >
                <Mail size={16} />
              </Link>
            </If>
          </div>
        </Tooltip>
      );
      break;
    }
    case "FWD_WEB": {
      icon = (
        <Tooltip title="Nguồn: FWD-Web(E-Claim)">
          <Laptop size={16} />
        </Tooltip>
      );
      break;
    }
    case "BAOMINH_HISTORY":
    case "FWD_HISTORY":
    case "MBAL_HISTORY":
    case "SLV_HISTORY": {
      icon = (
        <Tooltip title="Nguồn: Lịch sử bồi thường từ CTBH">
          <Building2 size={16} />
        </Tooltip>
      );
      break;
    }
    default: {
      break;
    }
  }

  const dbIcon = (
    <If condition={claim.is_direct_billing}>
      <Tooltip title="Bảo lãnh viện phí">
        <HeartHandshake
          className="cursor-pointer"
          color={papaya}
          onClick={() =>
            setSearchParams((params) => {
              params.set("direct_billing", String(!directBilling));
              return params;
            })
          }
          size={15}
        />
      </Tooltip>
    </If>
  );

  const tatIcon = (
    <If condition={isOverTat}>
      <Tooltip title={`Quá TAT. Quy định: ${requiredTat}, thực tế: ${claim.tatDayHourMin}`}>
        <Siren color={papaya} size={20} />
      </Tooltip>
    </If>
  );
  return (
    <Space size={claim.labels.length > 0 ? undefined : 0}>
      <Space size={8}>
        <If condition={showCode}>
          <Link target={newTab ? "_blank" : undefined} to={generatePath(path, { claimCaseId: claim.id })}>
            {claim.code}{" "}
            <If condition={newTab}>
              <ExternalLink size={12} />
            </If>
          </Link>
        </If>
        <Space size={4}>
          {tatIcon}
          {icon}
          {dbIcon}
          <If condition={claim.is_jet === true}>
            <Tooltip title="JET - xử lý trong 30 phút">
              <Rocket
                className="cursor-pointer"
                color={papaya}
                onClick={() =>
                  setSearchParams((params) => {
                    params.set("is_jet", String(!isJet));
                    return params;
                  })
                }
                size={20}
              />
            </Tooltip>
          </If>
          <If condition={["INITIALIZING", "RUNNING"].includes(claim.stp_status)}>
            <Tooltip title="Đang trong luồng xử lý STP">
              <Cog className="animate-spin" color={yellow[6]} size={20} />
            </Tooltip>
          </If>
          <If condition={["SUCCEEDED"].includes(claim.stp_status)}>
            <Tooltip title="STP (Straight Through Process) - xử lý tự động">
              <Bot
                className="cursor-pointer"
                color={papaya}
                onClick={() =>
                  setSearchParams((params) => {
                    params.set("is_stp", String(!isStp));
                    return params;
                  })
                }
                size={20}
              />
            </Tooltip>
          </If>
        </Space>
      </Space>
      <If condition={showLabels}>
        <Space>
          <LabelDisplay data={claim.labels} editable={false} objectId={claim.id} />
          <If condition={showLALabels}>
            <LabelDisplay data={claim.insured_certificate.insured_person.labels} objectId={claim.insured_certificate.insured_person.id} />
          </If>
        </Space>
      </If>
    </Space>
  );
};

export default ClaimIcons;
