import type { QueryHookOptions } from "@apollo/client";
import type { MetadataTypesEnum } from "sdk/gql/graphql";
import type { ResultOf, VariablesOf } from "sdk/v2/graphql";

import usePQuery from "contexts/usePQuery";
import { debounce } from "lodash";
import { graphql } from "sdk/v2/graphql";

export const GetMetadataForHookDocument = graphql(`
  query MetadataForHook($where: metadata_bool_exp, $limit: Int) {
    metadata(where: $where, order_by: { value: asc }, limit: $limit) {
      id
      name: title
      metadata_id
      title
      type
      value
    }
  }
`);

export default function useMetadata(
  {
    limit = 10,
    skip = false,
    types,
    values,
  }: {
    limit?: number;
    skip?: boolean;
    types: MetadataTypesEnum[];
    values?: string[];
  },
  options?: Pick<QueryHookOptions<ResultOf<typeof GetMetadataForHookDocument>>, "onCompleted" | "variables">,
) {
  const where: VariablesOf<typeof GetMetadataForHookDocument>["where"] = { locale: { _eq: "vi-VN" }, type: { _in: types } };
  if (values != null) {
    where.value = { _in: values.filter(Boolean) };
  }
  const { data, loading, refetch, ...rest } = usePQuery(GetMetadataForHookDocument, {
    ...options,
    skip,
    variables: {
      limit,
      where: {
        ...options?.variables?.where,
        ...where,
      },
    },
  });

  const searchMetadata = debounce(
    (keyword) =>
      refetch({
        limit,
        where: {
          ...where,
          _or: [
            {
              title: { _ilike: `%${keyword.trim()}%` },
            },
            {
              value: { _ilike: `%${keyword.trim()}%` },
            },
          ],
        },
      }),
    200,
  );

  return {
    data,
    loading,
    refetch,
    searchMetadata,
    ...rest,
  };
}
