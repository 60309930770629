import BalanceDetailFragment from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/BalanceDetailFragment";
import { graphql } from "sdk/v2/graphql";

const claimCaseForClaimCasePaymentScreenFragment = graphql(`
  fragment ClaimCaseForClaimCasePaymentScreen on ClaimBenefitDetailsOutput {
    claimCaseId
    coPaymentAmount
    coverageAmount
    estimatedPaidAmount
    nationalHealthInsuranceAmount
    requestedAmount
  }
`);

export default graphql(
  `
    query ClaimBenefitDetails($claimId: UUID!) {
      claimBenefitDetails(claimId: $claimId) {
        id: claimCaseId
        actualPaidAmount
        ...ClaimCaseForClaimCasePaymentScreen
        coPaymentAmount
        customAmount
        deductibleAmount
        estimatedPaidAmount
        nationalHealthInsuranceAmount
        nationalHealthInsuranceRatio
        nonPaidAmount
        requestedAmount
        shortfallAmount
        claimCaseDetails {
          id: claimCaseDetailId
          assessedTime
          copayAmount
          coPaymentAmount
          coPaymentRatio
          deductibleAmount
          gracePeriodStartDate
          insuredCertificateHistoryId
          isMagic
          key
          nonPaidAmount
          note
          paidAmount
          paidFromDate
          paidTime
          paidToDate
          planInsuredBenefitId
          requestAmount
          requestFromDate
          requestTime
          requestToDate
          shortfallAmount
          totalPaidAmount
          totalPaidAmountBeforeCopay
          totalRequestAmount
          balanceDetails {
            id
            ...BalanceDetail
          }
          claim_case_detail {
            id
            paid_percentage
            request_percentage
            updated_at
            created_at
            created_user {
              id
              name
            }
            plan_insured_benefit {
              id
              apply_copay
            }
            updated_user {
              id
              name
            }
          }
          plan_insured_benefit {
            id
            coefficient
            formula_type
          }
        }
        customAmountReasonType {
          comment
          value
        }
      }
    }
  `,
  [claimCaseForClaimCasePaymentScreenFragment, BalanceDetailFragment],
);
