import type { TableColumnsType, TablePaginationConfig } from "antd";
import type { ResultOf } from "sdk/v2/graphql";

import { Space, Tooltip } from "antd";
import TableList from "app/common/components/TableListV2";
import { PORTAL_PATH } from "app/portal/config/paths";
import { LA_WITH_DEPENDANT_QUERY } from "app/portal/screens/SalePortal/InsuredLeads/queries";
import { FORMATTER, TYPE } from "config/constants";
import usePQuery from "contexts/usePQuery";
import utils from "libs/utils";
import { clone } from "lodash";
import { InfoIcon } from "lucide-react";
import { generatePath, Link, useSearchParams } from "react-router-dom";

const InsuredLeads = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const size = Number(searchParams.get("size") ?? 30);
  const page = Number(searchParams.get("page") ?? 1);
  const { data, loading } = usePQuery(LA_WITH_DEPENDANT_QUERY, {
    variables: {
      limit: size,
      offset: (page - 1) * size,
      where: {
        children_insured_certificates: {},
      },
    },
  });
  const columns: TableColumnsType<
    { children?: ResultOf<typeof LA_WITH_DEPENDANT_QUERY>["insured_certificates"] } & ResultOf<typeof LA_WITH_DEPENDANT_QUERY>["insured_certificates"][number]
  > = [
    {
      dataIndex: ["insured_person", "name"],
      render: (name, record) => <Link to={generatePath(PORTAL_PATH.INSURED_PERSON_DETAIL, { id: record.insured_person.id })}>{name}</Link>,
      title: "Tên",
    },
    {
      dataIndex: ["insured_person", "phone"],
      title: "Phone",
    },
    {
      dataIndex: ["insured_person", "email"],
      title: "Email",
    },
    {
      dataIndex: ["insured_person", "dob"],
      render: (dob) => utils.formatDate(dob, FORMATTER.DATE_FORMAT),
      title: "DoB",
    },
    {
      dataIndex: ["insured_person", "age"],
      title: "Tuổi",
    },
    {
      dataIndex: ["insured_person", "gender"],
      render: (gender) => TYPE.GENDER_TYPE[gender],
      title: "Giới tính",
    },
    {
      dataIndex: ["policy", "corporate_company", "name"],
      title: "CTY",
    },
    {
      dataIndex: ["policy", "policy_number"],
      render: (number, record) => (
        <div>
          <Link to={generatePath(PORTAL_PATH.POLICY_DETAIL, { id: record.policy.policy_id })}>{number}</Link>
          <div>{record.policy.insurer_company?.name}</div>
        </div>
      ),
      title: "CTBH",
    },
    {
      render: (_, record) => {
        if ((record.children?.length ?? 0) > 0) {
          return "";
        }
        if (record.insured_relationship_type != null) {
          return record.insured_relationship_type.comment;
        }
        const age = record.insured_person.age ?? 0;
        const parentAge = record.parent_insured_certificate?.insured_person.age ?? 0;
        if (age < 18 && age < parentAge) {
          return "Con (x)";
        }
        if (age < parentAge + 12 && age > parentAge - 12) {
          if (record.insured_person.gender == null) {
            return "Không rõ (x)";
          }
          if (record.insured_person.gender === 1) {
            return "Vợ (x)";
          }
          return "Chồng (x)";
        }
        return "Tú thân phụ mẫu (x)";
      },
      title: (
        <Space>
          <span>Mối quan hệ</span>
          <Tooltip
            title={
              <ul>
                <li>Con: Nhỏ hơn 18t.</li>
                <li>Vợ/Chồng: lớn hơn 18t, khoảng cách tuổi không quá 12 so với chủ HĐ.</li>
                <li>Có ký tự (x) là system suy luận ra, không có nghĩa là theo dữ liệu của CTBH/HR cung cấp</li>
              </ul>
            }
          >
            <InfoIcon size={14} />
          </Tooltip>
        </Space>
      ),
    },
    {
      dataIndex: ["insured_person", "claim_cases_aggregate", "aggregate", "count"],
      title: "# Claims",
    },
  ];

  const paginationConfig: TablePaginationConfig = {
    current: page,
    onChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    onShowSizeChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    pageSize: size,
    total: data?.insured_certificates_aggregate.aggregate?.count,
  };

  const leads = data?.insured_certificates.map((i) =>
    // eslint-disable-next-line no-param-reassign
    ({ ...clone(i), children: i.children_insured_certificates.flat() }),
  );

  return <TableList dataSource={leads} loading={loading} nativeScroll={{ x: "max-content" }} pagination={paginationConfig} schema={columns} scroll={{ x: 500 }} sticky />;
};

export default InsuredLeads;
