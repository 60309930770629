import { Tag } from "antd";
import { TYPE } from "config/constants";

const ClaimStatus = ({
  bordered = true,
  className = "",
  onClick,
  value,
}: {
  bordered?: boolean;
  className?: string;
  onClick?: VoidFunction;
  value?: {
    comment: null | string;
    value: string;
  };
}) => {
  if (value == null) return null;
  return (
    <Tag bordered={bordered} className={[className].join(" ")} color={TYPE.CLAIM_STATUS_TO_COLOR[value.value]} onClick={onClick}>
      {value.comment}
    </Tag>
  );
};

export default ClaimStatus;
