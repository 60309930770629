import { LOCAL_STORAGE_KEYS, PAPAYA_IP_WHITELIST } from "config/constants";
import { useCallback, useEffect, useState } from "react";

export default function useIP() {
  const [ip, setIP] = useState<null | string>(localStorage.getItem(LOCAL_STORAGE_KEYS.IP));
  const checkUserInWhitelistToUseOAuth = useCallback((userIp: string): userIp is (typeof PAPAYA_IP_WHITELIST)[number] => PAPAYA_IP_WHITELIST.includes(userIp), []);

  useEffect(() => {
    fetch("https://checkip.amazonaws.com", {
      method: "GET",
    })
      .then((res) => res.text())
      .then((IP) => {
        if (IP == null) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.IP, IP);
        } else {
          setIP(IP.trim());
        }
      });
  }, []);

  return { checkUserInWhitelistToUseOAuth, ip } as const;
}
