import type { LucideProps} from "lucide-react";

import { papaya } from "config/colors";
import { Wand2Icon } from "lucide-react";
import { forwardRef } from "react";

interface IconProps extends Omit<LucideProps, "ref"> {}

type RefType = {};

const Icon = forwardRef<RefType, IconProps>(({ className, ...props }, ref) => <Wand2Icon className={`anticon ${className}`} color={papaya} ref={ref} {...props} />);

export default Icon;
