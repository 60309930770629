import { Button, Space } from "antd";

import "./styles.css";

const ErrorPage = () => (
  <div>
    <Space className="body" direction="vertical">
      <div>Có lỗi xảy ra. Vui lòng thử lại sau.</div>
      <Button onClick={() => window.history.back()} type="link">
        Quay lại trang trước
      </Button>
    </Space>
  </div>
);

export default ErrorPage;
