import type { GenerateConfig } from "rc-picker/lib/generate";

import { DatePicker } from "antd";
import { format as formatDate, getWeek, isValid, parse as parseDate, startOfWeek } from "date-fns";
import { vi } from "date-fns/locale";
import config from "rc-picker/lib/generate/dateFns";

const localeParse = (format?: string) =>
  format
    ?.replace(/Y/g, "y")
    .replaceAll("D", "d")
    .replace(/gggg/, "yyyy")
    .replaceAll("g", "G")
    .replaceAll(/([Ww])o/g, "wo");

const dealLocal = (str: string) => str.replaceAll("_", "");

const locale: GenerateConfig<Date>["locale"] = {
  format: (_, date, format) => {
    const localeFormat = localeParse(format);
    if (localeFormat == null) {
      return null as any;
    }

    return formatDate(date, localeFormat, {
      locale: vi,
    });
  },
  getShortMonths: () => {
    const clone = vi;

    return Array.from({ length: 12 }).map((_, i) => clone.localize.month(i, { width: "abbreviated" }));
  },
  getShortWeekDays: () => {
    const clone = vi;

    return Array.from({ length: 7 }).map((_, i) => clone.localize.day(i, { width: "short" }));
  },
  getWeek: (_, date) => getWeek(date, { locale: vi }),
  getWeekFirstDate: (_, date) => startOfWeek(date, { locale: vi }),
  getWeekFirstDay: () => {
    const clone = vi;

    return clone.options?.weekStartsOn ?? 1;
  },
  parse: (_, text, formats) => {
    for (const item of formats) {
      const format = localeParse(item);
      const formatText = text;
      const date = parseDate(formatText, format ?? "dd/MM/yyyy", new Date(), {
        locale: vi,
      });

      if (isValid(date)) {
        if (date.getFullYear() < 1000) {
          return null;
        }
        return date;
      }
    }

    return null;
  },
};

const MyDatePicker = DatePicker.generatePicker<Date>({ ...config, locale });
export default MyDatePicker;
