import type { FC } from "react";

import { SyncOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Flex, Form, Input, Modal, Space, Tooltip, Typography } from "antd";
import If from "app/common/components/If";
import LabelDisplay from "app/common/components/LabelDisplay";
import WandIcon from "app/common/components/WandIcon";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { SLACK } from "config";
import { papaya } from "config/colors";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import dedent from "dedent";
import { isGreaterThanZero } from "libs/hidash";
import utils from "libs/utils";
import { Eraser, Info, Languages, Save } from "lucide-react";
import { useParams } from "react-router-dom";
import { ClaimNoteTypesEnum, Role } from "sdk/gql/types";

import styles from "./AssessmentSummary.module.css";
import { OpenAiTranslateDocument, UpdateAssessmentSummaryDocument, WriteAssessmentSummaryDocument } from "./index.generated";

const { TextArea } = Input;

gql`
  mutation openAITranslate($input: CreateChatCompletionRequestInput!) {
    createChatCompletion(createChatCompletionRequestInput: $input) {
      choices {
        message {
          content
        }
      }
    }
  }
  mutation updateAssessmentSummary($id: uuid!, $input: claim_notes_set_input!) {
    update_claim_notes_by_pk(pk_columns: { id: $id }, _set: $input) {
      __typename
    }
  }

  mutation writeAssessmentSummary($input: claim_notes_insert_input!) {
    insert_claim_notes_one(object: $input, on_conflict: { constraint: claim_notes_pkey, update_columns: [content] }) {
      __typename
    }
  }
`;

const AssessmentSummary: FC<{ readOnly?: boolean }> = ({ readOnly = false }) => {
  const {
    claim,
    notification,
    permissions: { canUpdateSummary },
  } = useClaim();
  const { user } = useAuth();
  const { claimCaseId } = useParams();

  const [writeAssessmentSummaryMutation] = usePMutation(WriteAssessmentSummaryDocument, {
    onCompleted: () => notification.api.success({ key: notification.key, message: "Lưu thành công" }),
    refetchQueries: ["ClaimDetail"],
  });
  const [updateAssessmentSummaryMutation] = usePMutation(UpdateAssessmentSummaryDocument, {
    onCompleted: () => notification.api.success({ key: notification.key, message: "Xóa thành công" }),
    refetchQueries: ["ClaimDetail"],
  });
  const [openAiTranslateMutation, { loading: translating }] = usePMutation(OpenAiTranslateDocument, {
    refetchQueries: ["ClaimDetail"],
  });
  const [openAiImproving, { loading: improving }] = usePMutation(OpenAiTranslateDocument, {
    refetchQueries: ["ClaimDetail"],
  });

  const assessmentSummary = claim?.assessment_summary[0];
  const [assessmentSummaryForm] = Form.useForm();
  const reactiveAssessmentSummaryContent = Form.useWatch<string | undefined>("content", assessmentSummaryForm);

  if (claim == null) return null;

  const shortfallAmount = claim.claim_case_payment?.shortfall_amount;

  return (
    <Form
      disabled={!canUpdateSummary}
      form={assessmentSummaryForm}
      initialValues={{ content: assessmentSummary?.content }}
      layout="vertical"
      onFinish={(newValues) => {
        writeAssessmentSummaryMutation({
          variables: {
            input: {
              claim_case_id: claimCaseId,
              content: newValues.content,
              id: assessmentSummary?.id,
              type: ClaimNoteTypesEnum.AssessmentSummary,
              username: user?.name,
            },
          },
        });
      }}
    >
      <div>
        <Form.Item
          label={
            <div>
              Tổng kết thẩm định{" "}
              <Tooltip title="Thông tin ghi tại đây sẽ được thể hiện trong các Correspondences để thể hiện chi tiết kết quả thẩm định">
                <Info size={14} />
              </Tooltip>
            </div>
          }
          name="content"
        >
          <TextArea autoSize={{ minRows: 5 }} className={styles.contentEditable} disabled={!canUpdateSummary || readOnly} spellCheck variant="outlined" />
        </Form.Item>
        <LabelDisplay editable={false} objectId={claim.insured_certificate.insured_person.insured_person_id} />
      </div>
      <Flex justify="space-between">
        <If condition={isGreaterThanZero(shortfallAmount)}>Không chi trả chi phí {utils.formatNumber(shortfallAmount)}đ do vượt giới hạn...</If>
        <Space className={styles.saveBtn} style={{ float: "right", marginBottom: 10, width: "unset" }}>
          {reactiveAssessmentSummaryContent != null && reactiveAssessmentSummaryContent.trim() !== "" && (
            <Typography.Paragraph
              copyable={{
                onCopy: () => navigator.clipboard.writeText(reactiveAssessmentSummaryContent),
                tooltips: false,
              }}
            />
          )}
          <Button
            icon={<Languages color="blue" size={14} style={{ marginLeft: 5 }} />}
            loading={translating}
            onClick={async () => {
              if (reactiveAssessmentSummaryContent != null && reactiveAssessmentSummaryContent.trim() !== "") {
                const { data } = await openAiTranslateMutation({
                  variables: {
                    input: {
                      maxTokens: 256,
                      messages: [
                        {
                          content: `translate to english and keep format:${reactiveAssessmentSummaryContent}`,
                          role: Role.User,
                        },
                      ],
                      model: "gpt-3.5-turbo",
                      n: 1,
                      stream: false,
                      temperature: 0.3,
                    },
                  },
                });
                Modal.confirm({
                  cancelText: "Copy",
                  content: <div style={{ whiteSpace: "pre-wrap" }}>{data?.createChatCompletion?.choices.map((i) => i?.message.content).join("\n")}</div>,
                  okText: "OK",
                  onCancel: () => {
                    if (data?.createChatCompletion == null) return;
                    navigator.clipboard.write([
                      new ClipboardItem({
                        "text/plain": new Blob([`${data.createChatCompletion.choices.map((i) => i?.message.content).join("\n")}`], {
                          type: "text/plain",
                        }),
                      }),
                    ]);
                  },
                  title: "Nội dung đã dịch",
                  width: "40%",
                });
              }
            }}
            size="large"
          >
            Dịch sang tiếng Anh{" "}
          </Button>
          <Button
            disabled={reactiveAssessmentSummaryContent == null || (reactiveAssessmentSummaryContent.trim() === "" && reactiveAssessmentSummaryContent.length < 100)}
            icon={<WandIcon size={18} style={{ marginTop: 5 }} />}
            loading={improving}
            onClick={async () => {
              if (reactiveAssessmentSummaryContent != null && reactiveAssessmentSummaryContent.trim() !== "") {
                notification.api.info({ duration: 0, icon: <SyncOutlined color={papaya} spin />, key: notification.key, message: "Đang cải thiện nội dung" });
                const { data } = await openAiImproving({
                  variables: {
                    input: {
                      maxTokens: 512,
                      messages: [
                        {
                          content: dedent`
                            you're a customer service expert. you communicate to customer via texts.
                            improve the following texts to make it so the customer who reads it like it. do not add your explanation or guide.
                            ${reactiveAssessmentSummaryContent}
                          `,
                          role: Role.User,
                        },
                      ],
                      model: "gpt-4",
                      n: 1,
                      stream: false,
                      temperature: 0,
                    },
                  },
                });
                notification.api.success({ key: notification.key, message: "Đang cải thiện nội dung" });
                Modal.confirm({
                  cancelText: "Không dùng",
                  content: <div style={{ whiteSpace: "pre-wrap" }}>{data?.createChatCompletion?.choices.map((i) => i?.message.content).join("\n")}</div>,
                  icon: <WandIcon size={20} />,
                  okText: "Dùng nội dung này",
                  onOk: () => {
                    if (data?.createChatCompletion == null) return;
                    utils.sendMessageToSlack({
                      channel: SLACK.CHANNEL_IDS.TRACK_USAGE,
                      message: dedent`
                        <@${user?.slack_member_id}> uses gpt to improve summary for <${window.location.href}#assessment|${claim.code}>.
                        Old texts: ${reactiveAssessmentSummaryContent}
                      `,
                    });
                    assessmentSummaryForm.setFieldValue("content", data.createChatCompletion.choices.map((i) => i?.message.content).join("\n"));
                  },
                  title: "Nội dung đã được cải thiện",
                  width: "40%",
                });
              }
            }}
            size="large"
          />
          <Button
            disabled={reactiveAssessmentSummaryContent == null || reactiveAssessmentSummaryContent.trim() === "" || !canUpdateSummary || readOnly}
            htmlType="submit"
            icon={<Save size={16} />}
            size="large"
            type="primary"
          >
            Lưu
          </Button>
          <Button
            danger
            disabled={assessmentSummary?.id == null || !canUpdateSummary || readOnly}
            icon={<Eraser size={16} />}
            onClick={() =>
              Modal.confirm({
                cancelText: "Không xóa",
                okText: "Xóa",
                onOk: () => {
                  if (assessmentSummary?.id != null) {
                    updateAssessmentSummaryMutation({
                      variables: {
                        id: assessmentSummary.id,
                        input: { deleted_at: "now()" },
                      },
                    });
                  }
                },
                title: "Vui lòng xác nhận xóa",
              })
            }
            size="large"
            type="primary"
          >
            Xóa
          </Button>
        </Space>
      </Flex>
    </Form>
  );
};

export default AssessmentSummary;
