import { graphql } from "sdk/v2/graphql";

const fragment = graphql(`
  fragment DateRangeForClaim on ClaimDateRange {
    id: key
    certificateHistoryId
    endDate
    gracePeriodStartDate
    key
    planId
    startDate
    sumAssured
    plan {
      id
      code
      copay_mechanism
      plan_id
      plan_balances(where: { allow_assessment: { _eq: true } }) {
        id
        name
        balanceRemain: value
        balanceUsed: value
        value
        plan_balance_benefits {
          id
          plan_insured_benefit {
            id
            insured_benefit {
              id
              type
            }
          }
        }
        plan_balance_type {
          id: value
          comment
        }
      }
      plan_remarks(order_by: { created_at: desc }) {
        id
        description
        plan_id
        created_at
      }
    }
    policy_plan {
      id
      plan_name
    }
  }
`);

export default graphql(
  `
    query ClaimDateRanges($claimId: uuid!) {
      claim_cases_by_pk(claim_case_id: $claimId) {
        id
        claim_date_ranges {
          id: claimId
          ranges {
            ...DateRangeForClaim
          }
        }
      }
    }
  `,
  [fragment],
);
