import type { ModalFuncProps } from "antd";
import type { ModalFunc } from "antd/lib/modal/confirm";
import type { InsertClaimCaseDeclineHistoryDocument } from "hooks/useDecline";
import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";
import type { VariablesOf } from "sdk/v2/graphql";

import { getOperationName } from "@apollo/client/utilities";
import { Button, message, Modal, notification, Popconfirm, Space, Typography } from "antd";
import If from "app/common/components/If";
import { CLAIM_CASE_PATH, PORTAL_PATH } from "app/portal/config/paths";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import confirmChangeStatusModal from "app/portal/screens/ClaimPortal/ClaimContext/confirmChangeStatusModal";
import useNotice from "app/portal/screens/ClaimPortal/ClaimContext/hooks/useNotice";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useFwd from "app/portal/screens/ClaimPortal/ClaimContext/useFwd";
import useUpdateClaimTat from "app/portal/screens/ClaimPortal/ClaimContext/useUpdateClaimTat";
import { DateItems } from "config/common";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import { useInsertClaimCaseDeclineHistory } from "hooks/useDecline";
import { debounce, isEmpty, pick, uniqBy } from "lodash";
import { Info } from "lucide-react";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import ClaimCaseStatusSelect from "../ClaimCaseStatusSelect";
import showModalInvalidDateRange from "../DetailForm/components/showModalInvalidDateRange";
import styles from "./BottomBar.module.css";
import DeclineInfoForm from "./components/DeclineInfoForm/DeclineInfoForm";

const { Text } = Typography;

const ApproveClaimDocument = graphql(`
  mutation ApproveClaim($claimId: ID!) {
    approveClaim(claimId: $claimId) {
      claimId
      gracePeriodStatus
      newStatus
    }
  }
`);

const BottomBar = () => {
  const {
    claim,
    claimType,
    correspondenceSettings,
    isClaimHistory,
    saveStatusCallbacks,
    status: { saveStatus, saveStatusLoading, setStatus, status },
  } = useClaim();
  const { notice } = useNotice();
  const { globalData } = useAuth();
  const navigate = useNavigate();
  const [approveClaim, { loading: checkApprovalLoading }] = usePMutation(ApproveClaimDocument, {
    awaitRefetchQueries: true,
    refetchQueries: ["ClaimDetail", getOperationName(ClaimBenefitDetailsDocument)].filter(Boolean),
  });
  const disabled = claim?.benefit_type == null || saveStatusLoading;

  const declineInfo = claim?.claim_case_decline_histories[0];
  const declineModalRef = useRef<null | ReturnType<ModalFunc>>(null);
  const [declineModal, contextHolder] = Modal.useModal();
  const [insertClaimCaseDeclineHistory] = useInsertClaimCaseDeclineHistory();
  const updateClaimTat = useUpdateClaimTat();

  const onSaveDeclineStatus = (claimCaseStatus: ClaimCaseStatusesEnum, claimCaseDeclineHistory: VariablesOf<typeof InsertClaimCaseDeclineHistoryDocument>["data"]) => {
    if (claim == null) {
      return;
    }

    insertClaimCaseDeclineHistory({
      onCompleted: () => {
        declineModalRef.current?.destroy();
        saveStatus({
          variables: {
            claimId: claim.claim_case_id,
            input: { status: claimCaseStatus },
          },
        });
      },
      refetchQueries: ["ClaimDetail"],
      variables: { data: claimCaseDeclineHistory },
    });
  };

  const declineInfoModalConfig: ModalFuncProps = {
    content: <DeclineInfoForm onSaveDeclineStatus={debounce(onSaveDeclineStatus, 1000)} />,
    footer: null,
    maskClosable: true,
    styles: { body: { paddingBottom: "0px" } },
    title: "Thông tin từ chối",
    type: "info",
  };

  const warningInvalidDate = useCallback(async (): Promise<boolean> => {
    const dateWarningFields = ["physical_examination_date", "admission_date", "discharge_date", "event_date"];
    const warningValues = pick(claim, dateWarningFields);
    const dateItems = DateItems.filter((item) => warningValues[item.key_snake_case] != null)
      .map((item) => {
        const d = warningValues[item.key_snake_case];
        if (d == null) return null;
        return {
          ...item,
          value: new Date(d),
        };
      })
      .filter(Boolean);

    if (dateItems.length === 0) {
      return true;
    }
    if (claim == null) {
      return true;
    }
    return showModalInvalidDateRange({
      dateItems,
      dued_at: claim.insured_certificate.dued_at,
      issued_at: claim.insured_certificate.issued_at,
    });
  }, [claim]);
  const { autoDecisionStatus, loading: deciding } = useFwd();

  const onSaveStatus = async () => {
    if (isEmpty(claim)) {
      return;
    }
    const shouldContinueSaveForm = await warningInvalidDate();
    if (!shouldContinueSaveForm) {
      return;
    }

    if (claim.status !== status && status === "Approved") {
      if (claimType.fwdMr === true) {
        if (autoDecisionStatus.isDecidable === false && claim.is_direct_billing === false) {
          autoDecisionStatus.reasons.forEach((reason) => notification.error({ message: reason }));
          return;
        }
        const willChangeStatus = await confirmChangeStatusModal({
          claimCode: claim.code,
          claimStatues: globalData?.claim_case_statuses,
          toChangeStatus: "Approved",
        });
        if (!willChangeStatus) {
          return;
        }
      }
      const approveClaimRes = await approveClaim({
        onCompleted: (data) => {
          uniqBy(saveStatusCallbacks, (fn) => `${fn}`).forEach((f) => f(data.approveClaim.newStatus));
        },
        variables: { claimId: claim.id },
      });
      if (approveClaimRes.errors != null) {
        notification.error({ message: "Có lỗi xảy ra khi chấp thuận" });
        return;
      }
      await updateClaimTat({ claimId: claim.id, overwriteCache: true });
      const { approveClaim: approveClaimData } = approveClaimRes.data ?? {};
      switch (approveClaimData?.gracePeriodStatus) {
        case "NOT_END": {
          Modal.confirm({
            content: "YCBT này đang trong thời gian ân hạn. Chuyển sang chấp thuận trong thời gian ân hạn?",
            onOk: () => {
              saveStatus({
                variables: {
                  claimId: claim.id,
                  input: { status: "APPROVED_WAITING_FOR_GRADE_PERIOD_END" },
                },
              });
            },
            title: "Kết quả chấp thuận",
          });
          return;
        }
        case "LAPSED": {
          notification.info({ message: "Hợp đồng đang trong trạng thái lapsed" });
          return;
        }
        case "REVERSED": {
          Modal.confirm({
            content: "Hợp đồng đang trong trạng thái reverse. Trình case?",
            onOk: () => {
              navigate(`${PORTAL_PATH.CLAIM_CASE.replace(":claimCaseId", claim.claim_case_id)}/${CLAIM_CASE_PATH.CHECK_CASE}`);
            },
            title: "Kết quả chấp thuận",
          });
          return;
        }
        case "PREMIUM_PAID_AND_NO_SUM_ASSURED_CHANGED": {
          notification.info({ message: "Chấp thuận thành công. Hợp đồng đã thanh toán phí bảo hiểm" });
          return;
        }
        case "PREMIUM_PAID_AND_SUM_ASSURED_CHANGED": {
          notification.info({ message: "Chấp thuận thành công. Đã thanh toán phí bảo hiểm và thay đổi số tiền bảo hiểm" });
          return;
        }
        case "END_BUT_NO_MORE_INFO": {
          notification.warning({ message: "Chấp thuận không thành công: Đã hết thời gian ân hạn nhưng chưa có thông tin từ MBAL là đã đóng phí hay chưa (vẫn còn đang nợ phí)" });
          return;
        }
        default: {
          notification.success({ message: "Chấp thuận thành công" });
          return;
        }
      }
    }

    // Other status -> Declined
    if (claim.status !== status && status === "Declined") {
      declineModalRef.current = declineModal.info(declineInfoModalConfig);
      return;
    }

    if (claim.status === "Declined" && claim.status !== status && isEmpty(declineInfo?.deleted_reason)) {
      notification.error({ message: "Vui lòng xóa lý do từ chối trước khi đổi trạng thái" });
      return;
    }
    saveStatus({
      variables: {
        claimId: claim.id,
        input: { status },
      },
    });
  };

  const onStatusChange = (claimCaseStatus) => {
    setStatus(claimCaseStatus);

    if (claimCaseStatus === "Declined") {
      declineModalRef.current = declineModal.info(declineInfoModalConfig);
    }
  };

  const { enableAutoCorrespondence, toggleClaimAutoCorrespondence } = correspondenceSettings;

  return (
    <div className={[styles.bottomBar, "fixedLayout"].join(" ")}>
      <div className={styles.bottomStatusContainer}>
        <Space className={styles.leftSide}>
          <ClaimCaseStatusSelect defaultSelected onChange={onStatusChange} style={{ width: 250 }} />
          {notice}
          <If condition={claimType.slvHs === true}>
            <div>
              Group: {claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.code} -{" "}
              {claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.group_status.comment}
            </div>
          </If>
        </Space>
        <Space className={styles.rightSide} size={16}>
          <If condition={enableAutoCorrespondence === false}>
            <Space>
              <Text type="warning">
                <Info size={16} />
                <Text type="warning"> Chức năng gửi thư tự động đang được tắt.</Text>
                <Popconfirm
                  cancelText="Huỷ"
                  okText="Bật"
                  onConfirm={() => {
                    if (claim == null) return;
                    toggleClaimAutoCorrespondence({
                      onCompleted: () => {
                        message.success("Chức năng gửi thư tự động đã được mở lại");
                      },
                      refetchQueries: ["getCustomSetting"],
                      variables: {
                        upsertCustomSettingInput: {
                          moduleName: "CORRESPONDENCE",
                          settingApplicationObject: {
                            objectId: claim.claim_case_id,
                            objectType: "PUBLIC_CLAIM_CASES",
                          },
                          settingName: "ENABLE_AUTO_CORRESPONDENCE",
                          settingValues: {
                            booleanValue: true,
                          },
                        },
                      },
                    });
                  }}
                  title="Bật lại chức năng thư tự động cho claim này?"
                >
                  <Button type="link">Mở lại</Button>
                </Popconfirm>
              </Text>
            </Space>
          </If>
          <Button disabled={disabled || isClaimHistory} loading={saveStatusLoading || checkApprovalLoading || deciding} onClick={onSaveStatus} size="large" type="primary">
            {claim ? "Thay đổi trạng thái" : undefined}
          </Button>
        </Space>
      </div>
      {contextHolder}
    </div>
  );
};

export default BottomBar;
