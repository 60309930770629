import type { FC } from "react";
import type { FwdSyncTypesEnum } from "sdk/gql/graphql";

import { isPrimitive } from "@sentry/utils";
import { Button, Col, Collapse, Empty, Modal, Row, Space } from "antd";
import If from "app/common/components/If";
import { FWD_SYNC_LOGS } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.gql";
import { FwdClaimCaseFieldMapping } from "config/common";
import { COMMON_POLL_INTERVAL, FORMATTER, TYPE } from "config/constants";
import usePQuery from "contexts/usePQuery";
import usePollInterval from "hooks/usePollInterval";
import { useFwdPendingCodes } from "hooks/usePublicData";
import { isEqualZero } from "libs/hidash";
import utils from "libs/utils";
import { get, isEmpty, isObject, uniq } from "lodash";
import { ArrowDown, ArrowUp, View } from "lucide-react";

const EXCLUDE_KEYS = {
  admit_date_icu: "admit_date_icu",
  discharge_date_icu: "discharge_date_icu",
  hospitalize_icu_no_date: "hospitalize_icu_no_date",
  hospitalize_no_date: "hospitalize_no_date",
  is_icu: "is_icu",
  is_major_surgery: "is_major_surgery",
  is_surgery_icu: "is_surgery_icu",
  schema: "schema",
};

const Logs: FC<{ data: Record<string, number | object | object[] | string> }> = ({ data }) => {
  const fwdPendingCodes = useFwdPendingCodes("object");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data === null || data === undefined) return null;
  const getKeysList = (key: string, val) => {
    if (isPrimitive(val)) return key;
    if (typeof val === "object") {
      return Object.entries(val).map(([nestedKey, nestedVal]) => `${key}__${isObject(nestedVal) ? getKeysList(nestedKey, nestedVal) : nestedKey}`);
    }
    return key;
  };
  const renderValue = (key: string, value: boolean | number | object | object[] | string) => {
    if (typeof value === "boolean") {
      return <Space size={2}>{String(value)}</Space>;
    }
    if (Array.isArray(value) && key.startsWith("claim_pendings")) {
      return value.map((i) => (
        <p>
          {fwdPendingCodes?.[i.followUpCode] ?? i.followUpCode}: {TYPE.VN_PENDING_STATUS_NAME[i.followUpStatus] ?? i.followUpStatus}
        </p>
      ));
    }

    if (typeof value !== "object") return <span>{value}</span>;

    return <span>{JSON.stringify(value)}</span>;
  };

  const renderEntries = (dataItem: object, nestedKeys: string[]) =>
    nestedKeys.map((nestedKey) => (
      <li key={nestedKey}>
        <Row>
          <Col span={10}>
            <span>{FwdClaimCaseFieldMapping[nestedKey] === undefined ? nestedKey : FwdClaimCaseFieldMapping[nestedKey]}:</span>
          </Col>
          <Col span={14}>
            <span>{renderValue(nestedKey, get(dataItem, nestedKey.includes("claim_pendings") ? (nestedKey.split("__")[1] ?? "") : nestedKey.split("__")))}</span>
          </Col>
        </Row>
      </li>
    ));

  return (
    <ul>
      {Object.entries(data)
        .sort((a, b) => Number(isPrimitive(b[1])) - Number(isPrimitive(a[1])))
        .map((item) => {
          const [key, value] = item;

          if (EXCLUDE_KEYS[key] !== undefined) return null;

          const nestedKeys = getKeysList(key, value);
          if (Array.isArray(value)) {
            return value.map((valueItem) => {
              const nestedKeysInArrayItem = getKeysList(key, valueItem);
              return renderEntries(valueItem, nestedKeysInArrayItem);
            });
          }
          if (Array.isArray(nestedKeys) && nestedKeys.length > 0) return renderEntries(data, nestedKeys as string[]);
          return (
            <li key={key}>
              <Row>
                <Col span={10}>
                  <span>{FwdClaimCaseFieldMapping[key] === undefined ? key : FwdClaimCaseFieldMapping[nestedKeys]}:</span>
                </Col>
                <Col span={14}>
                  <span>{renderValue(key, value)}</span>
                </Col>
              </Row>
            </li>
          );
        })}
    </ul>
  );
};

const COLORS = ["#f50", "#2db7f5", "#52c41a", "#eb2f96", "#722ed1", "#fa8c16", "cyan"];

const SyncLogHistory = ({ claimCaseId, onCancel, open, type }: { claimCaseId: string; onCancel: () => void; open: boolean; type?: FwdSyncTypesEnum }) => {
  const { data, loading } = usePQuery(FWD_SYNC_LOGS, {
    pollInterval: usePollInterval(COMMON_POLL_INTERVAL),
    skip: !open || type == null,
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        claim_case_id: { _eq: claimCaseId },
        type: { _eq: type },
      },
    },
  });

  const logColors: Record<string, string> = {};
  uniq(data?.fwd_sync_logs.map((i) => i.request_id)).forEach((i, index) => {
    if (i == null || COLORS[index] == null) return;
    logColors[i] = COLORS[index];
  });

  return (
    <Modal
      cancelButtonProps={{ style: { visibility: "hidden" } }}
      destroyOnClose
      loading={loading}
      maskClosable
      okText="Đóng"
      onCancel={onCancel}
      onClose={onCancel}
      onOk={onCancel}
      open={open}
      styles={{ body: { maxHeight: 900, overflow: "auto" } }}
      title="Thông tin"
      width={800}
    >
      <If condition={isEqualZero(data?.fwd_sync_logs) === false} else={<Empty />}>
        <Collapse
          items={data?.fwd_sync_logs.map((item) => ({
            children: (
              <Space direction="vertical" style={{ width: "100%" }}>
                <span>Yêu cầu lúc: {utils.formatDate(item.created_at, FORMATTER.DATE_TIME_FORMAT)}</span>
                <span>Request ID: {item.request_id}</span>
                {item.user == null ? null : <span>Người thực hiện: {item.user.name}</span>}
                {isEmpty(item.response_data) ? null : (
                  <>
                    <span>
                      Dữ liêu trả về
                      <Button
                        icon={<View size={12} />}
                        onClick={() => Modal.info({ content: <pre>{JSON.stringify(item.response_data, null, 2)}</pre>, title: "Raw data", width: 800 })}
                        type="text"
                      />
                    </span>
                    <Logs data={item.response_data} />
                  </>
                )}
                {isEmpty(item.data) ? null : (
                  <>
                    <span>
                      Dữ liệu gửi đi
                      <Button
                        icon={<View size={12} />}
                        onClick={() => Modal.info({ content: <pre>{JSON.stringify(item.data, null, 2)}</pre>, title: "Raw data", width: 800 })}
                        type="text"
                      />
                    </span>
                    <Logs data={item.data} />
                  </>
                )}
              </Space>
            ),
            key: item.id,
            label: (
              <Space style={item.request_id == null ? {} : { color: logColors[item.request_id] }}>
                {item.direction === "SENT" ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
                <span>
                  {item.direction === "SENT" ? "Yêu cầu gửi đi" : "Callback"}{" "}
                  <span style={{ color: item.is_success ? "green" : "#db1d1d" }}>{item.is_success === true ? "thành công" : "thất bại"}</span>
                </span>
                <span>{utils.formatDate(item.created_at, FORMATTER.DATE_TIME_FORMAT)}</span>
                <span>
                  (Request ID: <span>{item.request_id}</span>)
                </span>
                {item.user == null && item.direction === "SENT" ? "(tự động)" : ""}
              </Space>
            ),
          }))}
        />
      </If>
    </Modal>
  );
};

export default SyncLogHistory;
