import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import BenefitTag from "app/common/components/BenefitTagV2";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import TableList from "app/common/components/TableListV2";
import { PORTAL_PATH } from "app/portal/config/paths";
import { FORMATTER } from "config/constants";
import { utils } from "libs/utils";
import { Link } from "react-router-dom";

const ClaimGroup = ({
  claimGroup,
  claimId,
}: {
  claimGroup: NonNullable<ClaimContextType["claim"]>["claim_case_group_claim_cases"][number]["claim_case_group"];
  claimId: string;
}) => (
  <TableList
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data={claimGroup?.claim_case_group_claim_cases?.filter(({ claim_case }) => claim_case.claim_case_id !== claimId)}
    hideWhenEmpty
    schema={[
      {
        dataIndex: ["claim_case", "code"],
        render: (text, record) => (
          <Link target="_blank" to={`${PORTAL_PATH.CLAIM_CASE.replace(":claimCaseId", record.claim_case.claim_case_id)}/info`}>
            {text}
          </Link>
        ),
        title: "#",
      },
      {
        dataIndex: ["claim_case_group_claim_case_type", "comment"],
        title: "Loại",
      },
      {
        dataIndex: ["claim_case", "created_at"],
        render: (text: string) => utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
        title: "Tạo lúc",
      },
      {
        dataIndex: ["claim_case", "insured_benefit_type", "comment"],
        render: (text: string, record) => <BenefitTag text={text} value={record.claim_case.benefit_type} />,
        title: "Quyền lợi",
      },
      {
        dataIndex: ["claim_case", "is_direct_billing"],
        filters: [
          {
            text: "Có",
            value: true,
          },
          {
            text: "Không",
            value: false,
          },
        ],
        onFilter: (value, record) => record.claim_case.is_direct_billing === value,
        render: (text) => (
          <span>
            {text === true ? (
              <>
                <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                <span
                  style={{
                    color: "#52c41a",
                    fontWeight: "bold",
                  }}
                >
                  Có
                </span>
              </>
            ) : (
              <>
                <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                <span
                  style={{
                    color: "var(--red-alert)",
                    fontWeight: "bold",
                  }}
                >
                  Không
                </span>
              </>
            )}
          </span>
        ),
        title: "BLVP",
      },
      {
        dataIndex: ["claim_case", "claim_case_status"],
        render: (claimCaseStatus) => <ClaimStatus value={claimCaseStatus} />,
        title: "Trạng thái",
      },
    ]}
  />
);

export default ClaimGroup;
