/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactNode } from "react";

type JsxChild = ReactNode | boolean | null | number | string | undefined;
type JsxChildren = JsxChild | JsxChild[];

const If = ({ children, condition, else: elseChildren = null }: { children: JsxChildren; condition: boolean; else?: JsxChildren }) => {
  if (condition) {
    return <>{children}</>;
  }
  if (elseChildren != null) {
    return <>{elseChildren}</>;
  }
  return null;
};

export default If;
