import { graphql } from "sdk/v2/graphql";

export const PENDING_CODES_QUERY = graphql(`
  query PendingCodes {
    fwd_pending_codes(order_by: { code: asc }) {
      name
      code
      ppy_pending_code
    }
    pending_codes(order_by: { code: asc }) {
      id
      code
      description
    }
  }
`);
export const SLV_CASE_REASONS_QUERY = graphql(`
  query SlvCaseReasonsQuery {
    slv_case_investigator_reasons {
      comment
      value
    }
    slv_case_re_uw_reasons {
      comment
      value
    }
  }
`);

export const CLAIM_PENDING_CODES = graphql(`
  query ClaimPendingCodes($where: claim_pending_codes_bool_exp!) {
    claim_pending_codes(where: $where, order_by: { created_at: asc }) {
      id
      code
      extended_text
      removed_at
      started_at
      status
      updated_at
      created_at
      claim_pending_code_documents {
        id
        claim_document_id
        created_at
        claim_document_type {
          comment
          value
        }
        file {
          id
          name
          file_id
          url
        }
      }
      created_by_user {
        id
        name
        avatar_url
      }
      fwd_pending_code {
        code
        ppy_pending_code
      }
      pending_code {
        id
        description
        document_type
        internal
      }
    }
  }
`);
