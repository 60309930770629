import type { InsuredBenefitTypes } from "sdk/gql/graphql";

import { Tag } from "antd";

const BenefitTag = (props: { className?: string; isSmall?: boolean; onClick?: () => void; style?: any; value?: Pick<InsuredBenefitTypes, "comment" | "value"> }) => {
  const { className, onClick, style, value } = props;
  let color = "";
  switch (value?.value) {
    case "Life": {
      color = "gold";
      break;
    }
    case "InPatient": {
      color = "volcano";
      break;
    }
    case "OutPatient": {
      color = "cyan";
      break;
    }
    case "Accident": {
      color = "red";
      break;
    }
    case "Others": {
      color = "default";
      break;
    }
    case "Maternity": {
      color = "pink";
      break;
    }
    case "Dental": {
      color = "purple";
      break;
    }
    default: {
      color = "default";
    }
  }
  return (
    <Tag className={[className ?? ""].join(" ")} color={color} onClick={onClick} style={style}>
      {value?.comment}
    </Tag>
  );
};

export default BenefitTag;
