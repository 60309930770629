import type { AssessmentClaimCaseDetail } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/types";
import type { PlanInsuredBenefitOnClaimDetailFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { ClaimDetailQuery, DateRangeForClaimFragment } from "sdk/gql/graphql";

import slvHiDeductible from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/InsuranceDeductible/SlvHiDeductible";

export type InsuranceDeductible = {
  limitPaidTime: null | number;
};

export type InsuranceDeductibleProps = {
  claim?: ClaimDetailQuery["claim_cases_by_pk"];
  claimDetailList?: AssessmentClaimCaseDetail[];
  currentClaimDetail?: AssessmentClaimCaseDetail;
  planInsuredBenefits?: { [key: string]: PlanInsuredBenefitOnClaimDetailFragment[] } | null;
  selectedDateRangeKey: string;
  selectedPlan?: DateRangeForClaimFragment;
};

const getInsuranceDeductible = (input: InsuranceDeductibleProps): InsuranceDeductible | null => {
  const { selectedPlan } = input;
  const planCode = selectedPlan?.plan?.code;
  if (planCode?.startsWith("SLV_HI") === true) {
    return slvHiDeductible(input);
  }

  return null;
};
export default getInsuranceDeductible;
