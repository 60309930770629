import { Form, InputNumber } from "antd";
import utils from "libs/utils";

import type { ClaimCaseDetailFormItem } from "../../schema";

type Props = {} & ClaimCaseDetailFormItem;
const RequestAmountField = ({ name }: Props) => (
  <Form.Item label="Số tiền yêu cầu" name={name}>
    <InputNumber
      className="w-100"
      formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
      parser={(value) => utils.parseNumber(value)}
      placeholder="Số tiền yêu cầu"
      size="large"
      wheel="false"
    />
  </Form.Item>
);
export default RequestAmountField;
