import { graphql } from "sdk/v2/graphql";

import BalanceDetailFragment from "./BalanceDetailFragment";

export default graphql(
  `
    query ClaimInsuredBenefitDetail($claimCaseId: UUID!, $planInsuredBenefitId: UUID!, $insuredCertificateHistoryId: UUID!, $gracePeriodStartDate: DateTime) {
      claimInsuredBenefitDetail(
        claimCaseId: $claimCaseId
        planInsuredBenefitId: $planInsuredBenefitId
        insuredCertificateHistoryId: $insuredCertificateHistoryId
        gracePeriodStartDate: $gracePeriodStartDate
      ) {
        coPaymentRatio
        key
        paidAmount
        planInsuredBenefitId
        balanceDetails {
          id
          ...BalanceDetail
        }
        plan_insured_benefit {
          id
          apply_copay
          coefficient
          formula_type
          plan_id
        }
      }
    }
  `,
  [BalanceDetailFragment],
);
