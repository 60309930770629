import { Descriptions, Form, Radio, Space } from "antd";
import useSummary from "app/portal/screens/ClaimPortal/ClaimContext/useSummary";

import type { ClaimCaseDetailFormItem } from "../../schema";

type Props = {} & ClaimCaseDetailFormItem;
const WaitingRemarksField = ({ name }: Props) => {
  const { items, runCheck } = useSummary();
  const claimsToEffectiveDate = items
    .filter((i) => i.key === "claim_to_effective_days")
    .map((item) => runCheck(item, false))
    .filter(Boolean);

  return (
    <Space direction="vertical">
      <Form.Item
        label="Thời gian chờ"
        rules={[
          {
            message: "Vui lòng chọn thời gian chờ",
            required: true,
          },
        ]}
      >
        <Descriptions items={claimsToEffectiveDate} />
        <Form.Item name={name} noStyle>
          <Radio.Group
            buttonStyle="solid"
            optionType="button"
            options={[
              {
                label: "Không áp dụng thời gian chờ",
                value: "Không áp dụng thời gian chờ",
              },
              {
                label: "Áp dụng thời gian chờ - chưa qua thời gian chờ",
                value: "Áp dụng thời gian chờ - chưa qua thời gian chờ",
              },
              {
                label: "Áp dụng thời gian chờ - đã qua thời gian chờ",
                value: "Áp dụng thời gian chờ - đã qua thời gian chờ",
              },
            ]}
          />
        </Form.Item>
      </Form.Item>
    </Space>
  );
};
export default WaitingRemarksField;
