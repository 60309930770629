import usePLazyQuery from "contexts/usePLazyQuery";
import { graphql } from "sdk/v2/graphql";

export const UploadFileMetadatasDocument = graphql(`
  query UploadFileMetadatas($files: [UploadFileMetadatasInsertInput!]!, $fileType: FileTypeInputEnum, $id: ID!) {
    uploadFileMetadatas(files: $files, fileType: $fileType, id: $id) {
      bucketName
      bucketNameV2
      bucketObjectKey
      fileName
      publicUrl
      uploadUrl
      extendedFileData {
        importedFileId
      }
    }
  }
`);

const useUploadMetadata = () => usePLazyQuery(UploadFileMetadatasDocument);
export default useUploadMetadata;
