import { Col, Row } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import { Link } from "react-router-dom";

import styles from "./RemarkCopay.module.css";

const RemarkCopay = (props: { policyId: string | undefined; remarkCopay?: null | string }) => {
  const { policyId, remarkCopay } = props;
  if (!policyId) return null;
  return remarkCopay ? (
    <div className={styles.remarkCopay}>
      <h3>
        <Link target="policy_info" to={PORTAL_PATH.POLICY_INFO.replace(":id", policyId)}>
          Đồng chi trả
        </Link>
      </h3>
      <Row className={styles.remarkCopayItem}>
        <Col span={24}>{remarkCopay}</Col>
      </Row>
    </div>
  ) : (
    <div />
  );
};

export default RemarkCopay;
