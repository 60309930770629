import { Col, Image, Row, Typography } from "antd";

import styles from "./MaintenanceScreen.module.less";

const MaintenanceScreen = () => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Row justify="center">
        <Col lg={4} md={6} xl={2} xs={12}>
          <Image alt="logo" className={styles.logo} preview={false} src="/images/extended-logo.png" />
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={10} md={12} xl={8} xs={20}>
          <Image alt="maintenance" className={styles.maintenanceImg} preview={false} src="/images/maintenance.png" />
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col className={styles.paragraph}>
          <Typography.Title level={3}>Hệ thống đang bảo trì</Typography.Title>
          <Typography.Paragraph>Chúng tôi sẽ quay lại trong thời gian sớm nhất.</Typography.Paragraph>
          <Typography.Paragraph>
            Liên hệ với chúng tôi nếu bạn cần sự hỗ trợ <Typography.Link href="mailto:info@papaya.asia">info@papaya.asia</Typography.Link>.
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
  </div>
);

export default MaintenanceScreen;
