/* eslint-disable react-refresh/only-export-components */
import type { ReactNode } from "react";
import type { HasuraTypes } from "sdk/v2/graphql";

import ClaimCaseScreen from "app/portal/screens/ClaimPortal/ClaimCaseScreen";
import ClaimInfoScreen from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen";
import ClaimListScreen from "app/portal/screens/ClaimPortal/ClaimListScreen";
import GmailAddonScreen from "app/portal/screens/DocsPortal/GmailAddonScreen";
import PaymentRequests from "app/portal/screens/SalePortal/PaymentRequests";
import Pending from "app/portal/screens/TotalReport/Pending";
import SlvTpaReport from "app/portal/screens/TotalReport/SlvTpaReport";
import { INSURER_COMPANY_IDS, NON_HEALTHCARE_COMPANY_IDS } from "config/constants";
import utils from "libs/utils";
import { lazy } from "react";

import InsuredLeads from "../screens/SalePortal/InsuredLeads";
import OTP from "../screens/TotalReport/OTP";
import { CLAIM_CASE_PATH, COMPANY_PATH, PORTAL_PATH } from "./paths";

/**
 * frequently used screen should not be lazily loaded
 */

// const ClaimInfoScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen").catch(utils.loadErrorHandler));
// const ClaimListScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimListScreen").catch(utils.loadErrorHandler));
// const ClaimCaseScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen").catch(utils.loadErrorHandler));
const ListBookingScreen = lazy(() => import("app/portal/screens/Booking/Bookings").catch(utils.loadErrorHandler));
const ApprovalBookingsScreen = lazy(() => import("app/portal/screens/Booking/ApprovalBookings").catch(utils.loadErrorHandler));

const HealthProfileUploadTool = lazy(() => import("app/portal/screens/Booking/HealthProfileUploadTool").catch(utils.loadErrorHandler));

const OCROverlayScreen = lazy(() => import("app/portal/screens/Experiments/OCROverlay").catch(utils.loadErrorHandler));
const LabelScreen = lazy(() => import("app/portal/screens/AdminPortal/LabelScreen").catch(utils.loadErrorHandler));
const UserScreen = lazy(() => import("app/portal/screens/AdminPortal/UserScreen").catch(utils.loadErrorHandler));
const EditUserScreen = lazy(() => import("app/portal/screens/AdminPortal/UserScreen/screens/detail").catch(utils.loadErrorHandler));
const ClaimPaymentScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCasePaymentScreen").catch(utils.loadErrorHandler));
const PresentCaseScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/PresentCaseScreen/PresentCaseScreen").catch(utils.loadErrorHandler));
const ClaimDocumentScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseDocumentScreen").catch(utils.loadErrorHandler));
const ClaimOriginalScreen = lazy(() => import("app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseOriginalScreen").catch(utils.loadErrorHandler));
const HomeScreen = lazy(() => import("app/common/screens/HomeScreen").catch(utils.loadErrorHandler));
const DashboardScreen = lazy(() => import("../../common/screens/DashBoardScreen").catch(utils.loadErrorHandler));
// Admin Portal
const CompanyScreen = lazy(() => import("../screens/AdminPortal/CompanyScreen").catch(utils.loadErrorHandler));
const CompanyInfoScreen = lazy(() => import("../screens/AdminPortal/CompanyScreen/screens/CompanyInfo").catch(utils.loadErrorHandler));
const CompanyCreateScreen = lazy(() => import("../screens/AdminPortal/CompanyScreen/screens/CompanyInfo/screens/CompanyCreateScreen").catch(utils.loadErrorHandler));
const PolicyScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen").catch(utils.loadErrorHandler));
const PlanScreen = lazy(() => import("../screens/AdminPortal/PlanScreen").catch(utils.loadErrorHandler));
const PlanDetailScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen").catch(utils.loadErrorHandler));
const PlanBenefitDetailScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBenfitScreen/detail").catch(utils.loadErrorHandler));
const PlanBenefitScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBenfitScreen").catch(utils.loadErrorHandler));
const PlanBalanceScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen").catch(utils.loadErrorHandler));
const PlanBalanceDetailScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanBalanceScreen/detail").catch(utils.loadErrorHandler));
const PolicyDetailScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/PolicyDetailScreen").catch(utils.loadErrorHandler));
const CreatePolicyScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/CreatePolicyScreen").catch(utils.loadErrorHandler));
const PolicyInfoScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/PolicyInfoScreen").catch(utils.loadErrorHandler));
const InsuredPersonScreen = lazy(() => import("../screens/AdminPortal/InsuredPersonScreen").catch(utils.loadErrorHandler));
const InsuredPersonCreateScreen = lazy(() => import("../screens/AdminPortal/InsuredPersonScreen/screens").catch(utils.loadErrorHandler));
const InsuredPersonDetailScreen = lazy(() => import("../screens/AdminPortal/InsuredPersonScreen/screens/detail").catch(utils.loadErrorHandler));
const DynamicCardScreen = lazy(() => import("../screens/AdminPortal/DynamicCardScreen").catch(utils.loadErrorHandler));
const DynamicCardDetailScreen = lazy(() => import("../screens/AdminPortal/DynamicCardScreen/screens/DynamicCardDetailScreen").catch(utils.loadErrorHandler));
const MedicalProviderScreen = lazy(() => import("../screens/AdminPortal/MedicalProviderScreen").catch(utils.loadErrorHandler));
const MedicalProviderDetailScreen = lazy(() => import("../screens/AdminPortal/MedicalProviderScreen/screens/detail").catch(utils.loadErrorHandler));
const MedicalProviderEmployeeDetailScreen = lazy(() => import("../screens/AdminPortal/MedicalProviderScreen/screens/employeeDetail").catch(utils.loadErrorHandler));
const CertificateDetailScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/CertificateDetailScreen").catch(utils.loadErrorHandler));
const PolicyCoPaymentScreen = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/PolicyCoPaymentScreen").catch(utils.loadErrorHandler));
const MedicalProviderCreate = lazy(() => import("../screens/AdminPortal/MedicalProviderScreen/screens/createMedicalProvider").catch(utils.loadErrorHandler));
const MedicalProviderEdit = lazy(() => import("../screens/AdminPortal/MedicalProviderScreen/screens/editMedicalProvider").catch(utils.loadErrorHandler));
const ActivateInsuranceCertificate = lazy(() => import("../screens/AdminPortal/PolicyScreen/screens/ActivateInsuranceCertificate").catch(utils.loadErrorHandler));
// Admin User Functions

// Claim Portal
const CreateClaimCaseScreen = lazy(() => import("../screens/ClaimPortal/CreateClaimCaseScreen").catch(utils.loadErrorHandler));
const SampleScreen = lazy(() => import("../../common/screens/SampleScreen").catch(utils.loadErrorHandler));
const ClaimHistoryScreen = lazy(() => import("../screens/ClaimPortal/CreateClaimCaseScreen/screens/ClaimHistoryScreen").catch(utils.loadErrorHandler));
const DecisionScreen = lazy(() => import("../screens/ClaimPortal/DecisionScreen").catch(utils.loadErrorHandler));
const GracePeriodScreen = lazy(() => import("../screens/ClaimPortal/GracePeriodScreen").catch(utils.loadErrorHandler));
const ClaimCaseAssignmentScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseAssignmentScreen").catch(utils.loadErrorHandler));
const ClaimAssessorsScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseAssignmentScreen/assessors").catch(utils.loadErrorHandler));
const ClaimAssessorScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseAssignmentScreen/assessor").catch(utils.loadErrorHandler));

// Hospital Portal
const HospitalCertificatesScreen = lazy(() => import("../screens/HospitalPortal/CertificatesScreen").catch(utils.loadErrorHandler));
const HospitalClaimListScreen = lazy(() => import("../screens/HospitalPortal/HospitalClaimScreen").catch(utils.loadErrorHandler));
const HospitalClaimDetailScreen = lazy(() => import("../screens/HospitalPortal/HospitalClaimScreen/screens/ClaimScreen").catch(utils.loadErrorHandler));
const HospitalClaimUpdateScreen = lazy(() => import("../screens/HospitalPortal/HospitalClaimScreen/screens/UpdateDirectBillingScreen").catch(utils.loadErrorHandler));
const InsurerSelectionScreen = lazy(() => import("../screens/HospitalPortal/InsurerSelectionScreen").catch(utils.loadErrorHandler));
const BenefitInsuredScreen = lazy(() => import("../screens/HospitalPortal/CertificatesScreen/screens/BenefitInsuredScreen").catch(utils.loadErrorHandler));
const PlanInfoScreen = lazy(() => import("../screens/AdminPortal/PlanScreen/screens/PlanDetailScreen/screens/PlanInfoScreen").catch(utils.loadErrorHandler));
const HospitalSettlementReportScreen = lazy(() => import("../screens/HospitalPortal/HospitalSettlementReportScreen").catch(utils.loadErrorHandler));
const PendingHospitalClaimScreen = lazy(() => import("../screens/HospitalPortal/PendingHospitalClaimScreen").catch(utils.loadErrorHandler));

// CM Portal
const PermissionScreen = lazy(() => import("../screens/CMPortal/PermissionScreen").catch(utils.loadErrorHandler));
const OcrQcList = lazy(() => import("../screens/OCRAndFraud/OCRPaperList").catch(utils.loadErrorHandler));
const MbalOcrPaperListScreen = lazy(() => import("../screens/OCRAndFraud/MbalOcrPaperListScreen").catch(utils.loadErrorHandler));
const PaperInfo = lazy(() => import("../screens/OCRAndFraud/OCRPaperList/screens/PaperInfo").catch(utils.loadErrorHandler));
const MbalOcrPaperInfoScreen = lazy(() => import("../screens/OCRAndFraud/MbalOcrPaperListScreen/detail").catch(utils.loadErrorHandler));
const FwdMrOcrQcScreen = lazy(() => import("../screens/OCRAndFraud/FwdMrOcrQcScreen").catch(utils.loadErrorHandler));

const ClaimDecisionPreviewScreen = lazy(() => import("../screens/OCRAndFraud/FwdMrOcrQcScreen/ClaimDecisionPreview").catch(utils.loadErrorHandler));

const InsuredCertificatesAndPersonsImportResultScreen = lazy(() =>
  import("../screens/AdminPortal/PolicyScreen/screens/PolicyInfoScreen/components/InsuredCertificatesAndPersonsImportResultScreen").catch(utils.loadErrorHandler),
);
const ClaimHistory = lazy(() => import("../screens/ClaimPortal/CreateClaimCaseScreen/screens/ClaimHistoryScreen/history").catch(utils.loadErrorHandler));
const ClaimCaseToolsScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseToolsScreen").catch(utils.loadErrorHandler));
const ClaimCaseLogScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseLogScreen").catch(utils.loadErrorHandler));
const CorrespondenceScreen = lazy(() => import("../screens/AdminPortal/CorrespondenceScreen").catch(utils.loadErrorHandler));
const CreateNewTemplateScreen = lazy(() => import("../screens/AdminPortal/CorrespondenceScreen/CreateNewCorrespondence/CreateNewTemplate").catch(utils.loadErrorHandler));
const CorrespondenceDetailScreen = lazy(() => import("../screens/AdminPortal/CorrespondenceScreen/CorrespondenceDetailScreen").catch(utils.loadErrorHandler));
const EditEmailTemplateScreen = lazy(() => import("../screens/AdminPortal/CorrespondenceScreen/EditEmailTemplateScreen").catch(utils.loadErrorHandler));
const TheGuildUserFragmentScreen = lazy(() => import("../screens/Experiments/TheGuildUserFragmentScreen").catch(utils.loadErrorHandler));
const ApolloClientUserFragmentScreen = lazy(() => import("../screens/Experiments/ApolloClientUseFragmentScreen").catch(utils.loadErrorHandler));
const InsuredBenefitScreen = lazy(() => import("../screens/AdminPortal/InsuredBenefitScreeen").catch(utils.loadErrorHandler));
const ClaimCaseCorrespondenceScreenNew = lazy(() => import("../screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseCorrespondenceScreen4").catch(utils.loadErrorHandler));
const OtherTemplateScreen = lazy(() => import("../screens/AdminPortal/CorrespondenceScreen/OtherTemplateScreen").catch(utils.loadErrorHandler));
const ReportManagement = lazy(() => import("../screens/AdminPortal/ReportManagement").catch(utils.loadErrorHandler));
const ClaimCaseAssignScreen = lazy(() => import("../screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseAssignScreen").catch(utils.loadErrorHandler));
const BatchDocsUploadScreen = lazy(() => import("../screens/ClaimPortal/BatchDocsUploadScreen").catch(utils.loadErrorHandler));
const MrMcManualReportForRE = lazy(() => import("../screens/TotalReport/MrMcManualReportForRE").catch(utils.loadErrorHandler));
const MrMcManualReportForDB = lazy(() => import("../screens/TotalReport/MrMcManualReportForDB").catch(utils.loadErrorHandler));
const ManualReportForHealthcare = lazy(() => import("../screens/TotalReport/ManualReportForHealthcare").catch(utils.loadErrorHandler));
const ManualPayoutReport = lazy(() => import("../screens/TotalReport/ManualPayoutReport").catch(utils.loadErrorHandler));
const PayoutReport = lazy(() => import("../screens/TotalReport/PayoutReport").catch(utils.loadErrorHandler));
const AssessmentAnalytics = lazy(() => import("../screens/TotalReport/AssessmentAnalytics").catch(utils.loadErrorHandler));
const Productivity = lazy(() => import("../screens/TotalReport/Productivity").catch(utils.loadErrorHandler));
const MonthlyProductivity = lazy(() => import("../screens/TotalReport/Productivity/monthly").catch(utils.loadErrorHandler));
const AllProductivity = lazy(() => import("../screens/TotalReport/Productivity/allProductivity").catch(utils.loadErrorHandler));
const ReportByAssessedBenefits = lazy(() => import("../screens/TotalReport/ReportByAssessedBenefits").catch(utils.loadErrorHandler));
const InsurerRefund = lazy(() => import("../screens/TotalReport/InsurerRefund").catch(utils.loadErrorHandler));
const TatPayoutReport = lazy(() => import("../screens/TotalReport/ReportPayoutTAT").catch(utils.loadErrorHandler));
const FwdHsTatPayoutReport = lazy(() => import("../screens/TotalReport/ReportPayoutTATFwdHS").catch(utils.loadErrorHandler));
const PolicyReport = lazy(() => import("../screens/TotalReport/PolicyReport").catch(utils.loadErrorHandler));
const StpFwdMrReport = lazy(() => import("../screens/TotalReport/StpFwdMrReport").catch(utils.loadErrorHandler));
const TotalReport = lazy(() => import("../screens/TotalReport").catch(utils.loadErrorHandler));
const MonthlyFwdMrReport = lazy(() => import("../screens/TotalReport/MonthlyFwdMrReport").catch(utils.loadErrorHandler));
const HealthcareReport = lazy(() => import("../screens/TotalReport/ReportHealthcare").catch(utils.loadErrorHandler));
const Tools = lazy(() => import("../screens/Tools").catch(utils.loadErrorHandler));
const ClaimCasesQAScreen = lazy(() => import("../screens/ClaimPortal/ClaimCasesQAScreen").catch(utils.loadErrorHandler));
const ReimbursementPayout = lazy(() => import("../screens/Payout/ReimbursementPayout/ReimbursementPayout").catch(utils.loadErrorHandler));
const PayoutCreationTab = lazy(() => import("../screens/Payout/ReimbursementPayout/PayoutCreationTab/PayoutCreationTab").catch(utils.loadErrorHandler));
const PayoutErrorTab = lazy(() => import("../screens/Payout/ReimbursementPayout/PayoutErrorTab/PayoutErrorTab").catch(utils.loadErrorHandler));
const PayoutHistoriesTab = lazy(() => import("../screens/Payout/ReimbursementPayout/PayoutHistoriesTab/PayoutHistoriesTab").catch(utils.loadErrorHandler));
const PayoutBatchDetail = lazy(() => import("../screens/Payout/ReimbursementPayout/PayoutHistoriesTab/PaymentRequestDetail").catch(utils.loadErrorHandler));
const DirectBillingPayout = lazy(() => import("../screens/Payout/DirectBillingPayout").catch(utils.loadErrorHandler));
const DirectBillingPayoutCreation = lazy(() => import("../screens/Payout/DirectBillingPayout/BatchPayment").catch(utils.loadErrorHandler));
const DirectBillingPayoutFailed = lazy(() => import("../screens/Payout/DirectBillingPayout/BatchFailed").catch(utils.loadErrorHandler));
const DirectBillingPayoutHistories = lazy(() => import("../screens/Payout/DirectBillingPayout/BatchHistories").catch(utils.loadErrorHandler));
const DirectBillingPayoutBatchDetail = lazy(() => import("../screens/Payout/DirectBillingPayout/BatchDetail").catch(utils.loadErrorHandler));
const ConfirmClaimCaseBeneficiaries = lazy(() => import("../screens/Payout/ConfirmClaimCaseBeneficiaries").catch(utils.loadErrorHandler));
const MbalCorrespondenceReport = lazy(() => import("../screens/TotalReport/Mbal").catch(utils.loadErrorHandler));
const SlvTatReport = lazy(() => import("../screens/TotalReport/SlvTatReport").catch(utils.loadErrorHandler));
const FwdHsReport = lazy(() => import("../screens/TotalReport/FwdHs").catch(utils.loadErrorHandler));
const MailboxScreen = lazy(() => import("../screens/Mailbox/mailboxes").catch(utils.loadErrorHandler));
// Booking
const ContractsScreen = lazy(() => import("../screens/Booking/ContractsScreen").catch(utils.loadErrorHandler));
const BookingDetail = lazy(() => import("../screens/Booking/ContractsScreen/BookingDetail").catch(utils.loadErrorHandler));
// Insurance requests
const InsuranceRequestsScreen = lazy(() => import("../screens/InsuranceRequests/InsuranceRequestsScreen").catch(utils.loadErrorHandler));
const CreateInsuranceRequestScreen = lazy(() => import("../screens/InsuranceRequests/CreateInsuranceRequestScreen").catch(utils.loadErrorHandler));
const InsuranceRequestDetailScreen = lazy(() => import("../screens/InsuranceRequests/InsuranceRequestDetailScreen").catch(utils.loadErrorHandler));
const AAAReportScreen = lazy(() => import("../screens/TotalReport/AAAReport").catch(utils.loadErrorHandler));

const NoPermissionScreen403 = lazy(() => import("../../common/screens/403NoPermissionScreen").catch(utils.loadErrorHandler));

interface ExtendProps {
  defaultBackPath?: string;
  overrideStyles?: React.CSSProperties;
}

export interface IRoute {
  comp: (T) => null | ReactNode;
  exact?: boolean;
  extendProps?: ExtendProps & Record<string, any>;
  path: string;
  // IdentityAccessPermissionsEnum
  permissions?: HasuraTypes<"identity_access_permissions_enum">[];
  requireAuth?: boolean;
  routes?: IRoute[];
  subTitle?: string;
  title?: string;
}

const ClaimHistoryTitle = "Claim History";
const ClaimCaseAssignmentTitle = "Claim Case Assignment";
const CustomHeight = "calc(100% - 46px)";
const ClaimCaseList = "Yêu cầu bồi thường";

const CLAIM_CASE_SUB_PATHS: IRoute[] = [
  {
    comp: PresentCaseScreen,
    path: CLAIM_CASE_PATH.CHECK_CASE,
    title: "Trình case",
  },
  {
    comp: ClaimDocumentScreen,
    path: CLAIM_CASE_PATH.GALLERY,
    title: "Hình ảnh/chứng từ",
  },
  {
    comp: ClaimOriginalScreen,
    path: CLAIM_CASE_PATH.ORIGINAL,
    title: "Claim gốc",
  },
  {
    comp: ClaimCaseLogScreen,
    path: CLAIM_CASE_PATH.ACTION_LOGS,
    title: "Lịch sử hoạt động",
  },
  {
    comp: ClaimPaymentScreen,
    path: CLAIM_CASE_PATH.PAYMENT_CLAIM,
    title: "Thanh toán",
  },
  {
    comp: ClaimCaseToolsScreen,
    path: CLAIM_CASE_PATH.TOOLS,
    title: "Tools",
  },
  {
    comp: ClaimCaseCorrespondenceScreenNew,
    extendProps: {
      overrideStyles: {
        padding: 0,
      },
    },
    path: CLAIM_CASE_PATH.CORRESPONDENCE,
    title: "Correspondences",
  },
  {
    comp: ClaimCaseAssignScreen,
    extendProps: { isPresentCase: false },
    path: CLAIM_CASE_PATH.ASSIGN,
    title: "Assign",
  },
  {
    comp: ClaimInfoScreen,
    path: CLAIM_CASE_PATH.CLAIM_CASE_INFO,
    routes: [
      {
        comp: ClaimHistory,
        path: "claim-history/:insuredPersonId",
        permissions: ["claim_case_view"],
        title: ClaimHistoryTitle,
      },
    ],
    title: "Claim Case",
  },
  {
    comp: ClaimInfoScreen,
    path: "",
    routes: [
      {
        comp: ClaimHistory,
        path: "claim-history/:insuredPersonId",
        permissions: ["claim_case_view"],
        title: ClaimHistoryTitle,
      },
    ],
    title: "Claim Case",
  },
  // {
  //   key: "createClaim",
  //   path: CLAIM_CASE_PATH.CERTIFICATE_CREATE_CLAIM_WITHOUT_ID,
  //   comp: ClaimInfoScreen,
  //   title: "Tạo claim",
  // },
];

export const routes: IRoute[] = [
  {
    comp: HomeScreen,
    path: "/",
    title: "Home Screen",
  },
  {
    comp: NoPermissionScreen403,
    path: PORTAL_PATH[403],
    title: "403",
  },
  // {
  //   path: "*",
  //   comp: NotFoundScreen,
  //   title: "404 Not Found",
  // },
  {
    comp: DashboardScreen,
    path: PORTAL_PATH.DASHBOARD,
    permissions: ["claim_case_view"],
    title: "Dashboard Screen",
  },
  /**
   * Access routes by role
   * Denied if don't have permission
   * */
  /**
   * HospitalPortal here
   *
   */
  {
    comp: HospitalClaimDetailScreen,
    path: PORTAL_PATH.HOSPITAL_CLAIM_CREATE,
    permissions: ["direct_billing_edit"],
    title: "Điền thông tin yêu cầu bảo hiểm",
  },
  {
    comp: HospitalClaimUpdateScreen,
    path: PORTAL_PATH.HOSPITAL_CLAIM_UPDATE,
    permissions: ["direct_billing_edit"],
    title: "Điền thông tin yêu cầu bảo hiểm",
  },
  {
    comp: BenefitInsuredScreen,
    path: PORTAL_PATH.HOSPITAL_CERTIFICATES_BENEFIT,
    permissions: ["direct_billing_view"],
    title: "Thông tin khách hàng",
  },
  {
    comp: HospitalCertificatesScreen,
    path: PORTAL_PATH.HOSPITAL_CERTIFICATES_SEARCH,
    permissions: ["direct_billing_view"],
    title: "Tìm người được bảo hiểm",
  },
  {
    comp: HospitalClaimDetailScreen,
    path: PORTAL_PATH.HOSPITAL_CLAIM_DETAIL,
    permissions: ["direct_billing_view"],
    title: "Chi tiết yêu cầu bảo hiểm",
  },
  {
    comp: HospitalClaimListScreen,
    path: PORTAL_PATH.HOSPITAL_CLAIM,
    permissions: ["direct_billing_view"],
    title: "",
  },
  {
    comp: HospitalClaimListScreen,
    extendProps: {
      status: ["Initialize"],
    },
    path: PORTAL_PATH.HOSPITAL_CLAIM_UNPROCESSED,
    permissions: ["direct_billing_view"],
    title: "",
  },
  {
    comp: HospitalClaimListScreen,
    path: PORTAL_PATH.HOSPITAL_CLAIM_SENT,
    permissions: ["direct_billing_view"],
    title: "",
  },
  {
    comp: HospitalClaimListScreen,
    extendProps: {
      status: ["ApprovedWaitingDoc", "Declined", "Cancelled"],
    },
    path: PORTAL_PATH.HOSPITAL_CLAIM_RESULTED,
    permissions: ["direct_billing_view"],
    title: "",
  },
  {
    comp: PendingHospitalClaimScreen,
    extendProps: {
      status: ["Pending"],
    },
    path: PORTAL_PATH.HOSPITAL_CLAIM_PENDING,
    permissions: ["direct_billing_view"],
    title: "Danh sách yêu cầu",
  },
  {
    comp: InsurerSelectionScreen,
    path: PORTAL_PATH.HOSPITAL_INSURER_SELECTION,
    permissions: ["direct_billing_view"],
    title: "",
  },
  {
    comp: HospitalSettlementReportScreen,
    path: PORTAL_PATH.HOSPITAL_MANAGEMENT_REPORT,
    permissions: ["direct_billing_view"],
    title: "Báo cáo để đối soát cho CSYT",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      notInInsurerIds: NON_HEALTHCARE_COMPANY_IDS,
    },
    path: PORTAL_PATH.CLAIM_LIST,
    permissions: ["claim_case_view"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      notInInsurerIds: NON_HEALTHCARE_COMPANY_IDS,
    },
    path: PORTAL_PATH.HC_CLAIM_LIST,
    permissions: ["claim_case_view"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      includesOnlyTestingClaims: true,
      notInInsurerIds: NON_HEALTHCARE_COMPANY_IDS,
    },
    path: PORTAL_PATH.TEST_CLAIM_LIST,
    permissions: ["claim_case_view"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_MR_CLAIM_LIST,
    permissions: ["fwd_mr_view"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_HS_CLAIM_LIST,
    permissions: ["fwd_hs_assessor"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.MBAges,
    },
    path: PORTAL_PATH.MBAL_CLAIM_LIST,
    permissions: ["mb_view"],
    title: "",
  },
  {
    comp: ClaimListScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.SLV,
    },
    path: PORTAL_PATH.SLV_HS_CLAIM_LIST,
    permissions: ["slv_view"],
    title: "",
  },
  {
    comp: DecisionScreen,
    path: PORTAL_PATH.DECISION,
    permissions: ["claim_case_view"],
    title: "Trạng thái sync data qua FWD",
  },
  {
    comp: GracePeriodScreen,
    path: PORTAL_PATH.GRACE_PERIOD,
    permissions: ["claim_case_view"],
    title: "",
  },
  {
    comp: ClaimHistoryScreen,
    path: PORTAL_PATH.HISTORY_CLAIM,
    permissions: ["claim_case_view"],
    routes: [
      {
        comp: ClaimHistory,
        path: PORTAL_PATH.CLAIM_HISTORY,
        permissions: ["claim_case_view"],
        title: ClaimHistoryTitle,
      },
    ],
    title: "Thông tin người được bảo hiểm",
  },
  {
    comp: ClaimHistoryScreen,
    path: PORTAL_PATH.HC_CREATE_CLAIM_CASE_LA,
    permissions: ["claim_case_view"],
    routes: [
      {
        comp: ClaimHistory,
        path: PORTAL_PATH.CLAIM_HISTORY,
        permissions: ["claim_case_view"],
        title: ClaimHistoryTitle,
      },
    ],
    title: "Thông tin người được bảo hiểm",
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.CLAIM_CASE,
    permissions: ["claim_case_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.HC_CLAIM_CASE,
    permissions: ["claim_case_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.TEST_CLAIM_CASE,
    permissions: ["claim_case_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.FWD_MR_CLAIM_CASE,
    permissions: ["fwd_mr_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.MBAL_HS_CLAIM_CASE,
    permissions: ["mb_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: ClaimCaseScreen,
    path: PORTAL_PATH.SLV_HS_CLAIM_CASE,
    permissions: ["slv_view"],
    routes: CLAIM_CASE_SUB_PATHS,
    title: ClaimCaseList,
  },
  {
    comp: BatchDocsUploadScreen,
    path: PORTAL_PATH.CLAIM.BATCH_UPLOAD,
    permissions: ["claim_case_edit"],
    title: "Batch Docs Upload",
  },
  { comp: ReportManagement, path: PORTAL_PATH.REPORT_MANAGEMENT, permissions: ["report_management_view"], title: "" },
  {
    comp: CreateClaimCaseScreen,
    path: PORTAL_PATH.CREATE_CLAIM_CASE,
    permissions: ["claim_case_edit"],
    title: "",
  },
  {
    comp: CreateClaimCaseScreen,
    path: PORTAL_PATH.HC_CREATE_CLAIM_CASE,
    permissions: ["claim_case_edit"],
    title: "",
  },
  {
    comp: CreateClaimCaseScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_MR_CREATE_CLAIM_CASE,
    permissions: ["claim_case_edit"],
    title: "",
  },
  {
    comp: CreateClaimCaseScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.MBAges,
    },
    path: PORTAL_PATH.MBAL_HS_CREATE_CLAIM_CASE,
    permissions: ["mb_view", "claim_case_edit"],
    title: "",
  },
  {
    comp: ClaimCaseAssignmentScreen,
    path: PORTAL_PATH.HC_CLAIM_CASE_ASSIGNMENT,
    permissions: ["claim_case_view"],
    title: ClaimCaseAssignmentTitle,
  },
  {
    comp: ClaimCaseAssignmentScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_MR_CLAIM_CASE_ASSIGNMENT,
    permissions: ["claim_case_view"],
    title: ClaimCaseAssignmentTitle,
  },
  {
    comp: ClaimCaseAssignmentScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.MBAges,
    },
    path: PORTAL_PATH.MBAL_HS_CLAIM_CASE_ASSIGNMENT,
    permissions: ["mb_view", "claim_case_view"],
    title: ClaimCaseAssignmentTitle,
  },
  {
    comp: ClaimCaseAssignmentScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.SLV,
    },
    path: PORTAL_PATH.SLV_HS_CLAIM_CASE_ASSIGNMENT,
    permissions: ["slv_view", "claim_case_view"],
    title: ClaimCaseAssignmentTitle,
  },
  {
    comp: ClaimCasesQAScreen,
    path: PORTAL_PATH.CLAIM_CASE_QA,
    permissions: ["qa_view"],
    title: "QA",
  },
  {
    comp: ClaimAssessorsScreen,
    path: PORTAL_PATH.CLAIM_CASE_ASSESSORS,
    permissions: ["claim_case_admin"],
    title: "Claim Assessors",
  },
  {
    comp: ClaimAssessorScreen,
    path: PORTAL_PATH.CLAIM_CASE_ASSESSOR,
    permissions: ["claim_case_admin"],
    title: "Claim Assessor",
  },
  {
    comp: MedicalProviderEdit,
    path: PORTAL_PATH.MEDICAL_PROVIDER_EDIT,
    permissions: ["medical_provider_edit"],
    title: "Cập nhật thông tin bệnh viện",
  },
  {
    comp: MedicalProviderCreate,
    path: PORTAL_PATH.MEDICAL_PROVIDER_CREATE,
    permissions: ["medical_provider_edit"],
    title: "Tạo mới bệnh viện",
  },
  {
    comp: MedicalProviderEmployeeDetailScreen,
    path: PORTAL_PATH.MEDICAL_PROVIDER_EMPLOYEE_DETAIL,
    permissions: ["medical_provider_view"],
    title: "Thông tin tài khoản đăng nhập",
  },
  {
    comp: MedicalProviderEmployeeDetailScreen,
    path: PORTAL_PATH.MEDICAL_PROVIDER_EMPLOYEE_DETAIL,
    permissions: ["medical_provider_view"],
    title: "Thông tin tài khoản đăng nhập",
  },
  {
    comp: MedicalProviderDetailScreen,
    path: PORTAL_PATH.MEDICAL_PROVIDER_DETAIL,
    permissions: ["medical_provider_view"],
    title: "Thông tin bệnh viện",
  },
  {
    comp: MedicalProviderScreen,
    path: PORTAL_PATH.MEDICAL_PROVIDER_LIST,
    permissions: ["medical_provider_view"],
    title: "",
  },
  {
    comp: DynamicCardDetailScreen,
    path: PORTAL_PATH.DYNAMIC_CARD_CREATE,
    permissions: ["card_edit"],
    title: "Tạo Template thẻ",
  },
  {
    comp: DynamicCardDetailScreen,
    path: PORTAL_PATH.DYNAMIC_CARD_DETAIL,
    permissions: ["card_view"],
    title: "Chi tiết mẫu thẻ",
  },
  {
    comp: DynamicCardScreen,
    path: PORTAL_PATH.DYNAMIC_CARD_LIST,
    permissions: ["card_view"],
    title: "",
  },
  {
    comp: InsuredPersonCreateScreen,
    path: PORTAL_PATH.INSURED_PERSON_CREATE,
    permissions: ["insured_person_edit"],
    title: "Tạo mới người được bảo hiểm",
  },
  {
    comp: InsuredPersonDetailScreen,
    path: PORTAL_PATH.INSURED_PERSON_DETAIL,
    permissions: ["insured_person_view"],
    routes: [
      {
        comp: ClaimHistory,
        path: PORTAL_PATH.CLAIM_HISTORY,
        permissions: ["claim_case_view"],
        title: ClaimHistoryTitle,
      },
    ],
    title: "Chi tiết người được bảo hiểm",
  },
  {
    comp: InsuredPersonScreen,
    path: PORTAL_PATH.INSURED_PERSON_LIST,
    permissions: ["insured_person_view"],
    title: "",
  },
  {
    comp: InsuredBenefitScreen,
    path: PORTAL_PATH.INSURED_BENEFIT_LIST,
    permissions: ["insured_person_view"],
    title: "",
  },
  {
    comp: PolicyCoPaymentScreen,
    path: PORTAL_PATH.POLICY_COPAYMENT_CREATE,
    title: "Tạo mới đồng chi trả hợp đồng BH",
  },
  {
    comp: PolicyCoPaymentScreen,
    path: PORTAL_PATH.POLICY_COPAYMENT_DETAIL,
    title: "Chi tiết đồng chi trả hợp đồng BH",
  },
  {
    comp: ActivateInsuranceCertificate,
    path: PORTAL_PATH.ACTIVATE_INSURANCE_CERTIFICATE,
    permissions: ["policy_view"],
    title: "Kích hoạt thẻ bảo hiểm",
  },
  {
    comp: CertificateDetailScreen,
    path: PORTAL_PATH.CERTIFICATE_DETAIL,
    permissions: ["policy_view"],
    title: "Chi tiết chứng chỉ bảo hiểm",
  },
  {
    comp: PolicyDetailScreen,
    path: PORTAL_PATH.POLICY_CREATE_TYPE,
    permissions: ["policy_view"],
    title: "Tạo mới hợp đồng BH",
  },
  {
    comp: CreatePolicyScreen,
    path: PORTAL_PATH.POLICY_CREATE_TYPE_V2,
    permissions: ["policy_view"],
    title: "Tạo mới hợp đồng BH",
  },
  {
    comp: PolicyInfoScreen,
    path: PORTAL_PATH.POLICY_INFO,
    permissions: ["policy_view"],
    title: "Chi tiết hợp đồng",
  },
  {
    comp: PolicyScreen,
    path: PORTAL_PATH.POLICY_LIST,
    permissions: ["policy_view"],
    title: "",
  },
  {
    comp: CompanyCreateScreen,
    path: PORTAL_PATH.COMPANY_CREATE,
    permissions: ["company_edit"],
    title: "Tạo mới công ty",
  },
  {
    comp: CompanyInfoScreen,
    path: PORTAL_PATH.COMPANY_DETAIL,
    permissions: ["company_view"],
    routes: [
      {
        comp: PolicyScreen,
        path: "",
        title: "Policy",
      },
      {
        comp: PolicyScreen,
        path: COMPANY_PATH.COMPANY_POLICY,
        title: "Policy",
      },
      {
        comp: PlanScreen,
        path: COMPANY_PATH.COMPANY_PLAN,
        title: "Plan",
      },
    ],
    title: "Chi tiết công ty",
  },
  {
    comp: CompanyScreen,
    path: PORTAL_PATH.COMPANY_LIST,
    permissions: ["company_view"],
    title: "Công ty",
  },
  {
    comp: SampleScreen,
    path: PORTAL_PATH.SAMPLE_SCREEN,
    permissions: [],
    title: "Base Form",
  },
  {
    comp: PlanBenefitDetailScreen,
    path: PORTAL_PATH.PLAN_BENEFIT_DETAIL,
    permissions: ["plan_view"],
    title: "Chi tiết quyền lợi bảo hiểm",
  },
  {
    comp: PlanBenefitScreen,
    path: PORTAL_PATH.PLAN_BENEFIT,
    permissions: ["plan_view"],
    title: "Cập nhật quyền lợi",
  },
  {
    comp: PlanBalanceDetailScreen,
    path: PORTAL_PATH.PLAN_BALANCES_DETAIL,
    permissions: ["plan_view"],
    title: "Quyền lợi thuộc hạn mức",
  },
  {
    comp: PlanBalanceScreen,
    path: PORTAL_PATH.PLAN_BALANCES,
    permissions: ["plan_view"],
    title: "Quyền lợi thuộc hạn mức",
  },
  {
    comp: PlanInfoScreen,
    path: PORTAL_PATH.PLAN_DETAIL_INFO,
    permissions: ["plan_view"],
    title: "Thông tin quyền lợi chi tiết",
  },
  {
    comp: PlanDetailScreen,
    path: PORTAL_PATH.PLAN_DETAIL,
    permissions: ["plan_view"],
    title: "Thông tin sản phẩm",
  },
  {
    comp: PlanScreen,
    path: PORTAL_PATH.PLAN_LIST,
    permissions: ["plan_view"],
    title: "Sản phẩm",
  },
  {
    comp: PaperInfo,
    path: PORTAL_PATH.OCR_PAPER_INFO,
    permissions: ["qc_view"],
    title: "Thông tin giấy ra viện",
  },
  {
    comp: OcrQcList,
    path: PORTAL_PATH.OCR_QC_LIST,
    permissions: ["qc_view"],
    title: "",
  },
  {
    comp: MbalOcrPaperListScreen,
    path: PORTAL_PATH.MBAL_OCR_QC_LIST,
    permissions: ["qc_view"],
    title: "MBAL QC OCR",
  },
  {
    comp: MbalOcrPaperInfoScreen,
    path: PORTAL_PATH.MBAL_OCR_PAPER_INFO,
    permissions: ["qc_view"],
    title: "Thông tin giấy tờ",
  },
  {
    comp: FwdMrOcrQcScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_MR_OCR_QC_LIST,
    permissions: ["qc_view"],
    title: "FWD OCR QC",
  },
  {
    comp: ClaimDecisionPreviewScreen,
    extendProps: {
      insurerId: INSURER_COMPANY_IDS.FWD,
    },
    path: PORTAL_PATH.FWD_MR_OCR_QC_DETAIL,
    permissions: ["qc_view", "claim_case_view"],
    title: "QC chi tiết",
  },
  /**
   * CM Routes *
   *
   */
  {
    comp: EditUserScreen,
    path: PORTAL_PATH.CM.ADMIN_USER_EDIT,
    permissions: ["user_admin"],
    title: "Edit User",
  },
  {
    comp: UserScreen,
    path: PORTAL_PATH.CM.ADMIN_USERS_LIST,
    permissions: ["user_view"],
    title: "",
  },
  {
    comp: PermissionScreen,
    path: PORTAL_PATH.CM.PERMISSION,
    permissions: ["user_admin"],
    title: "",
  },
  {
    comp: InsuredCertificatesAndPersonsImportResultScreen,
    path: PORTAL_PATH.INSURED_CERTIFICATES_AND_PERSONS_IMPORT_RESULT,
    permissions: ["policy_admin"],
    title: "Kết quả nhập dữ liệu NĐBH và thẻ",
  },
  {
    comp: HealthcareReport,
    path: PORTAL_PATH.REPORT.CLAIM,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: AssessmentAnalytics,
    path: PORTAL_PATH.REPORT.ASSESSMENT_ANALYTICS,
    permissions: ["claim_case_view"],
    title: "Assessment Analytics",
  },
  {
    comp: Productivity,
    path: PORTAL_PATH.REPORT.PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: MonthlyProductivity,
    path: PORTAL_PATH.REPORT.MONTHLY_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: AllProductivity,
    path: PORTAL_PATH.REPORT.ALL_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "All Productivity",
  },
  {
    comp: Productivity,
    extendProps: {
      me: true,
      type: "healthcare",
    },
    path: PORTAL_PATH.REPORT.HC_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: Productivity,
    extendProps: {
      me: true,
      type: "mbal_hs",
    },
    path: PORTAL_PATH.REPORT.MBAL_HS_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: Productivity,
    extendProps: {
      me: true,
      type: "slv_hs",
    },
    path: PORTAL_PATH.REPORT.SLV_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: Productivity,
    extendProps: {
      me: true,
      type: "fwd_mr",
    },
    path: PORTAL_PATH.REPORT.FWD_MR_PRODUCTIVITY,
    permissions: ["claim_case_view"],
    title: "Productivity",
  },
  {
    comp: InsurerRefund,
    path: PORTAL_PATH.REPORT.INSURER_REFUND,
    permissions: ["claim_case_view"],
    title: "Insurer Refund",
  },
  {
    comp: TatPayoutReport,
    path: PORTAL_PATH.REPORT.TAT_PAYOUT,
    permissions: ["claim_case_view"],
    title: "TAT Report",
  },
  {
    comp: FwdHsTatPayoutReport,
    path: PORTAL_PATH.REPORT.TAT_PAYOUT_FWD_HS,
    permissions: ["claim_case_view"],
    title: "TAT Report FWD HS",
  },
  {
    comp: PolicyReport,
    path: PORTAL_PATH.REPORT.POLICY_REPORT,
    permissions: ["claim_case_view"],
    title: "Policy report",
  },
  {
    comp: Pending,
    path: PORTAL_PATH.REPORT.PENDING,
    permissions: ["claim_case_view"],
    title: "Pending Report",
  },
  {
    comp: AAAReportScreen,
    path: PORTAL_PATH.REPORT.AAA_REPORT,
    permissions: ["claim_case_view"],
    title: "AAA Report",
  },
  {
    comp: OTP,
    path: PORTAL_PATH.REPORT.OTP,
    permissions: ["claim_case_view"],
    title: "OTP Reconciliation",
  },
  {
    comp: StpFwdMrReport,
    path: PORTAL_PATH.REPORT.STP_FWD_MR,
    permissions: ["claim_case_view"],
    title: "STP FWD MR",
  },
  {
    comp: SlvTpaReport,
    path: PORTAL_PATH.REPORT.SLV_TPA,
    permissions: ["claim_case_view"],
    title: "SLV TPA REPORT",
  },
  {
    comp: CorrespondenceScreen,
    path: PORTAL_PATH.CORRESPONDENCE.LIST,
    permissions: ["correspondence_admin"],
    title: "Correspondences",
  },
  {
    comp: CreateNewTemplateScreen,
    extendProps: {
      overrideStyles: {
        padding: 8,
      },
    },
    path: PORTAL_PATH.CORRESPONDENCE.NEW,
    permissions: ["correspondence_admin"],
    title: "Create Correspondence",
  },
  {
    comp: CorrespondenceDetailScreen,
    path: PORTAL_PATH.CORRESPONDENCE.DETAIL,
    permissions: ["correspondence_admin"],
    title: "Correspondence Detail",
  },
  {
    comp: EditEmailTemplateScreen,
    path: PORTAL_PATH.CORRESPONDENCE.TEMPLATE_DETAIL,
    permissions: ["correspondence_admin"],
    title: "Template Detail",
  },
  {
    comp: OtherTemplateScreen,
    path: PORTAL_PATH.CORRESPONDENCE.OTHER_TEMPLATE_DETAIL,
    permissions: ["correspondence_admin"],
    title: "Template Detail",
  },
  {
    comp: TheGuildUserFragmentScreen,
    path: PORTAL_PATH.EXPERIMENT.THE_GUILD_USE_FRAGMENT,
    permissions: ["user_admin"],
    title: "The Guild - useFragment",
  },
  {
    comp: OCROverlayScreen,
    path: PORTAL_PATH.EXPERIMENT.OCR_OVERLAY,
    permissions: ["user_admin"],
    title: "OCR Overlay",
  },
  {
    comp: ApolloClientUserFragmentScreen,
    path: PORTAL_PATH.EXPERIMENT.APOLLO_CLIENT_USE_FRAGMENT,
    permissions: [],
    title: "Apollo Client - useFragment",
  },
  {
    comp: MrMcManualReportForRE,
    path: PORTAL_PATH.REPORT.MANUAL_PAYMENT_RE,
    permissions: ["claim_case_view"],
    title: "MR/MC MANUAL PAYMENT-RE",
  },
  {
    comp: MrMcManualReportForDB,
    path: PORTAL_PATH.REPORT.MANUAL_PAYMENT_DB,
    permissions: ["claim_case_view"],
    title: "MR/MC MANUAL PAYMENT-DB",
  },
  {
    comp: MonthlyFwdMrReport,
    path: PORTAL_PATH.REPORT.MONTHLY_FWD_MR,
    permissions: ["claim_case_view"],
    title: "MONTHLY FWD-MR",
  },
  {
    comp: ManualReportForHealthcare,
    path: PORTAL_PATH.REPORT.MANUAL_REPORT_HEALTHCARE,
    permissions: ["claim_case_view"],
    title: "Manual Report For Healthcare",
  },
  {
    comp: ManualPayoutReport,
    path: PORTAL_PATH.REPORT.MANUAL_PAYOUT,
    permissions: ["claim_case_view"],
    title: "Manual Payout",
  },
  {
    comp: PayoutReport,
    path: PORTAL_PATH.REPORT.PAYOUT_REPORT,
    permissions: ["claim_case_view"],
    title: "Payout Report",
  },
  {
    comp: TotalReport,
    path: PORTAL_PATH.REPORT.TOTAL,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: ReportByAssessedBenefits,
    path: PORTAL_PATH.REPORT.ASSESSED_BENEFITS,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: FwdHsReport,
    path: PORTAL_PATH.REPORT.FWD_HS,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: MbalCorrespondenceReport,
    path: PORTAL_PATH.REPORT.MBAL_CORRESPONDENCE,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: SlvTatReport,
    path: PORTAL_PATH.REPORT.SLV_TAT_REPORT,
    permissions: ["claim_case_view"],
    title: "Report",
  },
  {
    comp: Tools,
    path: PORTAL_PATH.REPORT.TOOLS,
    permissions: ["policy_admin"],
    title: "Tools",
  },
  {
    comp: ReimbursementPayout,
    extendProps: {
      overrideStyles: {
        padding: 0,
      },
    },
    path: PORTAL_PATH.PAYOUT.REIMBURSEMENT.BASE_PATH,
    routes: [
      {
        comp: PayoutCreationTab,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.REIMBURSEMENT.PAYOUT_CREATION_TAB,
      },
      {
        comp: PayoutErrorTab,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.REIMBURSEMENT.PAYOUT_ERROR_TAB,
      },
      {
        comp: PayoutHistoriesTab,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.REIMBURSEMENT.PAYOUT_HISTORIES_TAB,
      },
      {
        comp: PayoutBatchDetail,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.REIMBURSEMENT.PAYOUT_HISTORIES_DETAIL,
      },
    ],
    title: "Thanh toán chi trả sau",
  },
  {
    comp: DirectBillingPayout,
    path: PORTAL_PATH.PAYOUT.DIRECT_BILLING.BASE_PATH,
    routes: [
      {
        comp: DirectBillingPayoutCreation,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.DIRECT_BILLING.BATCH_PAYMENT_CREATION,
      },
      {
        comp: DirectBillingPayoutFailed,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.DIRECT_BILLING.BATCH_FAILED,
      },
      {
        comp: DirectBillingPayoutHistories,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.DIRECT_BILLING.BATCH_HISTORIES,
      },
      {
        comp: DirectBillingPayoutBatchDetail,
        extendProps: {
          overrideStyles: {
            height: CustomHeight,
          },
        },
        path: PORTAL_PATH.PAYOUT.DIRECT_BILLING.DIRECT_BILLING_BATCH_DETAIL,
      },
    ],
    title: "Thanh Toán BLVP",
  },
  {
    comp: LabelScreen,
    path: PORTAL_PATH.ADMIN.LABELS,
    permissions: ["policy_admin"],
    title: "Labels",
  },
  {
    comp: ConfirmClaimCaseBeneficiaries,
    path: PORTAL_PATH.PAYOUT.CONFIRM_BENEFICIARY,
    permissions: ["claim_case_edit"],
    title: "Xác nhận tài khoản thụ hưởng",
  },
  {
    comp: ContractsScreen,
    path: PORTAL_PATH.BOOKING.BOOKING_MANAGE,
    permissions: ["policy_admin"],
    title: "Quản lý lịch khám",
  },
  {
    comp: BookingDetail,
    path: PORTAL_PATH.BOOKING.BOOKING_DETAIL,
    permissions: ["policy_admin"],
    title: "Quản lý lịch khám",
  },
  {
    comp: ListBookingScreen,
    path: PORTAL_PATH.BOOKING.LIST_BOOKING,
    permissions: ["policy_admin"],
    title: "Danh sách khám",
  },
  {
    comp: ApprovalBookingsScreen,
    path: PORTAL_PATH.BOOKING.EDIT_PROFILE_BOOKING,
    permissions: ["policy_admin"],
    title: "Yêu cầu thay đổi thông tin",
  },
  {
    comp: MailboxScreen,
    extendProps: {
      overrideStyles: {
        padding: "0px",
      },
    },
    path: PORTAL_PATH.MAILBOX.BASE_PATH,
    permissions: ["policy_admin"],
    title: "Quản lý hộp mail",
  },
  {
    comp: HealthProfileUploadTool,
    path: PORTAL_PATH.BOOKING.HEALTH_PROFILE_UPLOAD_TOOL,
    permissions: ["policy_admin"],
    title: "Upload kết quả khám",
  },
  // Sale Portal
  {
    comp: InsuredLeads,
    path: PORTAL_PATH.SALE.INSURED_LEADS,
    permissions: ["policy_admin"],
    title: "Insured Leads",
  },
  // Insurance Request
  {
    comp: CreateInsuranceRequestScreen,
    path: PORTAL_PATH.INSURANCE_REQUESTS_CREATE,
    permissions: ["policy_admin"],
    title: "Tạo mới Yêu cầu bảo hiểm",
  },
  {
    comp: InsuranceRequestDetailScreen,
    path: PORTAL_PATH.INSURANCE_REQUESTS_DETAIL,
    permissions: ["policy_admin"],
    title: "Chi tiết Yêu cầu bảo hiểm",
  },
  {
    comp: InsuranceRequestsScreen,
    path: PORTAL_PATH.INSURANCE_REQUESTS_LIST,
    permissions: ["policy_admin"],
    title: "Danh sách Yêu cầu bảo hiểm",
  },

  {
    comp: PaymentRequests,
    path: PORTAL_PATH.SALE.PAYMENT_REQUESTS,
    permissions: ["policy_admin"],
    title: "Payment Requests",
  },

  {
    comp: GmailAddonScreen,
    path: PORTAL_PATH.DOCS.GMAIL_ADDON,
    permissions: ["claim_case_view"],
    title: "Gmail Addon",
  },
];
