import { Tabs } from "antd";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useMemo } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "./ClaimCaseScreen.module.less";
import TabConfig from "./topbar";

import { withTransaction } from "@elastic/apm-rum-react";

const ClaimCaseScreen = ({ setPageTitle }: { setPageTitle: (title) => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { claimCaseId } = useParams();
  const { claim, claimCaseRoutePath } = useClaim();
  const claimCaseRoute = claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "");
  const activeKey = useMemo(() => {
    const keyInPath = location.pathname.replace(claimCaseRoute ?? "", "");
    return TabConfig.find((top) => keyInPath.startsWith(top.subPath))?.key;
  }, [claimCaseRoute, location.pathname]);

  setPageTitle(claim?.code);

  return (
    <div className={styles.container}>
      <Tabs
        activeKey={activeKey}
        className={styles.topBar}
        items={TabConfig.map((tb) => ({
          disabled: tb.isLock,
          key: tb.key,
          label: (
            <Link
              onClick={(e) => {
                e.preventDefault();
                const path = `${claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "")}${tb.subPath}`;
                if (e.ctrlKey || e.metaKey) {
                  window.open(path, "_blank");
                } else {
                  navigate(path);
                }
              }}
              to={`${claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "")}${tb.subPath}`}
            >
              {tb.title}
            </Link>
          ),
        }))}
      />
      <Outlet />
    </div>
  );
};

const ClaimCaseScreenWithTransaction = withTransaction("ClaimCaseScreen", "component")(ClaimCaseScreen);
export default ClaimCaseScreenWithTransaction;
