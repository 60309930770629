import { CLAIM_ALERT_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/claimAlertQuery";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePQuery from "contexts/usePQuery";
import { compareDesc, endOfDay, startOfDay } from "date-fns";
import { useMemo } from "react";

const useAlert = () => {
  const { claim, claimDateRanges, claimToEffectiveDays, isLifeInsuranceRider } = useClaim();

  const icdMetadataId = (() => {
    const icdList = claim?.claim_case_assessed_diagnoses.map((i) => i.icd.id) ?? [];
    if (isLifeInsuranceRider) return icdList.splice(0, 1);
    return claim?.claim_case_assessed_diagnoses.map((i) => i.icd.id) ?? [];
  })().filter(Boolean);

  const planId = claimDateRanges?.claim_date_ranges.ranges[0]?.plan?.plan_id;

  const { data: alertClaimData } = usePQuery(
    CLAIM_ALERT_QUERY,
    claim?.medical_provider_id == null ||
      claim.start_date == null ||
      claim.claim_case_assessed_diagnoses.length === 0 ||
      claimToEffectiveDays == null ||
      claim.insured_certificate.policy.insurer_company?.company_id == null ||
      planId == null
      ? { skip: true }
      : {
          variables: {
            certificateId: claim.insured_certificate_id,
            // claimId: claim?.claim_case_id,
            claimStartDate: claim.start_date,
            claimToEffectiveDate: claimToEffectiveDays,
            icdMetadataId,
            insurerId: claim.insured_certificate.policy.insurer_company.company_id,
            medicalProviderId: claim.medical_provider_id,
            planId,
            policyId: claim.insured_certificate.policy.id,
          },
        },
  );

  const isInWaitingTime = useMemo(() => {
    if (alertClaimData == null) return null;

    return alertClaimData.waitingTimeEntityDiseaseGroups.length > 0;
  }, [alertClaimData]);

  const isExclusiveDisease = useMemo(() => {
    if (alertClaimData == null) return null;

    return alertClaimData.exclusionEntityDiseaseGroups.length > 0;
  }, [alertClaimData]);

  const sameStartDateClaimCasesByInsuredPerson = useMemo(() => {
    const startPointA = claim?.start_date;
    const endPointA = claim?.end_date;

    const pastClaimCases = [
      ...(claim?.insured_certificate.claim_cases ?? []),
      ...(claim?.insured_certificate.from_insured_certificate_courses.flatMap((i) => i.from_insured_certificate.claim_cases) ?? []),
      ...(claim?.insured_certificate.to_insured_certificate_courses.flatMap((i) => i.to_insured_certificate.claim_cases) ?? []),
    ].filter((claimCase) => claimCase.id !== claim?.id);

    return pastClaimCases.filter((claimCase) => {
      const startPointB = claimCase.start_date;
      const endPointB = claimCase.end_date;

      if (startPointA == null || startPointB == null || endPointA == null || endPointB == null) return false;

      return (
        (compareDesc(startOfDay(new Date(startPointB)), endOfDay(new Date(startPointA))) >= 0 &&
          compareDesc(startOfDay(new Date(startPointA)), endOfDay(new Date(endPointB))) >= 0) ||
        (compareDesc(startOfDay(new Date(startPointB)), endOfDay(new Date(endPointA))) >= 0 && compareDesc(startOfDay(new Date(endPointA)), endOfDay(new Date(endPointB))) >= 0)
      );
    });
  }, [
    claim?.start_date,
    claim?.end_date,
    claim?.insured_certificate.claim_cases,
    claim?.insured_certificate.from_insured_certificate_courses,
    claim?.insured_certificate.to_insured_certificate_courses,
    claim?.id,
  ]);

  return {
    alertClaimData,
    isExclusiveDisease,
    isInWaitingTime,
    sameStartDateClaimCasesByInsuredPerson,
  };
};

export default useAlert;
