import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useCallback } from "react";

const certificateIds = new Set(["2945afa4-f588-4e7b-beca-dd4bdfa7fd9d", "32a0d577-5abc-4054-9691-20ca03e94e58"]);

const useGroove = () => {
  const { claim } = useClaim();

  return useCallback(
    ({ code }: { code: string }) => {
      if (claim?.insured_certificate.policy.policy_id === "3dd0327c-178c-4136-9d3f-e06332126038" && certificateIds.has(claim.insured_certificate.id)) {
        return code.startsWith("GROOVE_");
      }
      return code.startsWith("GROOVE_") === false;
    },
    [claim],
  );
};

export default useGroove;
