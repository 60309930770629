import { useContext } from "react";

import { ClaimContext } from "./ClaimContext";

const useClaim = () => {
  const context = useContext(ClaimContext);
  if (context == null) {
    throw new Error("useClaim must be used within a ClaimProvider");
  }
  return context;
};

export default useClaim;
