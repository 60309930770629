import type { FormInstance} from "antd";

import { useToggle } from "ahooks";
import { Button, Flex, Form, Image, Input, Popover, Space } from "antd";
import WandIcon from "app/common/components/WandIcon";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useClaimAssistant from "app/portal/screens/ClaimPortal/Contexts/ClaimAssistantContext/useClaimAssitant";
import { sentenceCase } from "change-case";
import { SLACK } from "config";
import { useAuth } from "contexts/AuthContext";
import utils from "libs/utils";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;

const Suggestion = ({ onAccept }: { onAccept: (values: string) => void }) => {
  const { claim } = useClaim();
  const { suggestions } = useClaimAssistant();
  const { user } = useAuth();
  const [visible, { set, setRight }] = useToggle();

  const suggestion = suggestions?.find((s) => s.type === "diagnosis");

  if (suggestion?.value == null || suggestion.value.length === 0) return null;

  const diagnosis = sentenceCase(
    (() => {
      if (Array.isArray(suggestion.value)) return suggestion.value.join("; ");
      return suggestion.value;
    })(),
  );

  return (
    <Popover
      content={
        <Flex className="w-300" gap={30} style={{ textAlign: "center" }} vertical>
          <div className="color-papaya">{diagnosis}</div>
          <Button
            onClick={() => {
              onAccept(diagnosis);
              utils.sendMessageToSlack({
                channel: SLACK.CHANNEL_IDS.TRACK_USAGE,
                message: `<@${user?.slack_member_id}> accepted diagnosis suggestion for <${window.location.href}|${claim?.code}>`,
              });
            }}
            type="primary"
          >
            Áp dụng
          </Button>
        </Flex>
      }
      title={
        <span>
          Sọ Dừa Gợi Ý
          <Button disabled={false} onClick={setRight} type="link">
            File gốc
          </Button>
          {suggestion.originalDocument?.file?.url != null && (
            <Image
              className="display-none"
              preview={{
                onVisibleChange: (value) => set(value),
                scaleStep: 0.5,
                src: suggestion.originalDocument.file.url,
                visible,
              }}
              src={`${suggestion.originalDocument.file.url}?width=148`}
            />
          )}
        </span>
      }
    >
      <div>
        <WandIcon className="cursor-pointer" size={16} />
      </div>
    </Popover>
  );
};

const DiagnosisField = ({ form, name }: Props) => (
  <Form.Item
    label={
      <Space>
        <span>Chẩn đoán bệnh</span>
        <Suggestion
          onAccept={(value) => {
            form.setFieldValue(name, value);
          }}
        />
      </Space>
    }
    name={name}
  >
    <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Tự sinh ra khi nhập ICD" size="large" />
  </Form.Item>
);
export default DiagnosisField;
