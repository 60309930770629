import type { FormInstance } from "antd";

import { Form, Select } from "antd";
import Spin from "app/common/components/Spin";
import usePQuery from "contexts/usePQuery";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import { GetLifeInsuranceEventsDocument } from "../../DetailForm.generated";
import styles from "../../DetailForm.module.css";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;

const InsuranceEventField = ({ form, name }: Props) => {
  const { data: lifeInsuranceEventsData, loading: loadingLifeInsuranceEvents } = usePQuery(GetLifeInsuranceEventsDocument);

  return (
    <Form.Item label="Sự kiện bảo hiểm" name={name} required>
      <Select
        autoClearSearchValue
        className={styles.autoComplete}
        notFoundContent={loadingLifeInsuranceEvents && <Spin size="small" />}
        optionFilterProp="children"
        options={lifeInsuranceEventsData?.life_insurance_events.map((event) => ({
          key: event.value,
          label: event.comment,
          value: event.value,
        }))}
        placeholder="Chọn sự kiện bảo hiểm"
        showSearch
        size="large"
      />
    </Form.Item>
  );
};
export default InsuranceEventField;
