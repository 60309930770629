import { Form, Input } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvHospitalDepartmentField = ({ name }: Props) => (
  <Form.Item
    label="Khoa điều trị"
    name={name}
    rules={[
      {
        message: "Không được để trống",
        required: true,
      },
    ]}
  >
    <Input placeholder="" size="large" />
  </Form.Item>
);
export default SlvHospitalDepartmentField;
