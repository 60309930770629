import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation UpsertClaimCaseAssessedDiagnosis($input: [claim_case_assessed_diagnoses_insert_input!]!) {
    insert_claim_case_assessed_diagnoses(
      objects: $input
      on_conflict: { constraint: claim_case_assessed_diagnoses_claim_case_id_icd_metadata_id_key, update_columns: [deleted_at, order] }
    ) {
      returning {
        id
      }
    }
  }
`);
