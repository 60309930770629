import type { FC } from "react";
import type { ClaimDocumentsQuery } from "sdk/gql/graphql";

import { gql } from "@apollo/client";
import usePQuery from "contexts/usePQuery";
import useMedicalProviders from "hooks/useMedicalProviders";
import useMetadata from "hooks/useMetadata";
import { uniq } from "lodash";
import { createContext, useMemo } from "react";
import { ClaimDocumentsDocument } from "sdk/gql/graphql";
import { ClaimDocumentTypesEnum, MetadataTypesEnum } from "sdk/gql/types";

import useClaim from "../../ClaimContext/useClaim";

type Suggestions = {
  matchIds?: string | string[];
  matchObject?: any;
  matchObjects?: any | any[];
  originalDocument?: NonNullable<ClaimDocumentsQuery["claim_cases_by_pk"]>["claim_documents"][number];
  originalDocumentId?: string;
  type: "diagnosis" | "icd" | "medical_provider";
  value?: null | string | string[];
}[];

export type ClaimAssistantContextType = {
  loading: boolean;
  suggestions?: Suggestions;
};

export const ClaimAssistantContext = createContext<ClaimAssistantContextType>({ loading: false } as ClaimAssistantContextType);
ClaimAssistantContext.displayName = "ClaimContext";

gql`
  query ClaimDocuments($claimId: uuid!) {
    claim_cases_by_pk(claim_case_id: $claimId) {
      id
      claim_documents(where: { type: { _neq: CLAIM_PENDING_SAMPLE_FILE } }, order_by: { created_at: desc, display_order: asc_nulls_last }) {
        id
        file {
          id
          url
        }
        newest_ocr_paper {
          id
          invoice {
            id
            invoice_number
            issued_at
            seller_name
            seller_tax_code
            serial_number
            signed_at
            total_amount
          }
          prescription {
            id
            diagnoses
            icd_codes
            medical_provider_name
          }
        }
        type
      }
    }
  }
`;

const ClaimAssistantProvider: FC<Record<string, unknown>> = (props) => {
  const { claim } = useClaim();
  const { data } = usePQuery(ClaimDocumentsDocument, claim?.id == null ? { skip: true } : { variables: { claimId: claim.id } });

  const prescriptionPaper = data?.claim_cases_by_pk?.claim_documents.find(
    (doc) =>
      doc.type === ClaimDocumentTypesEnum.PrescriptionPaper &&
      ((doc.newest_ocr_paper?.prescription?.diagnoses?.length ?? 0) > 0 || doc.newest_ocr_paper?.prescription?.icd_codes?.length > 0),
  );
  const icdCodes: string[] = uniq(prescriptionPaper?.newest_ocr_paper?.prescription?.icd_codes);
  const diagnoses = prescriptionPaper?.newest_ocr_paper?.prescription?.diagnoses;

  const { data: diseaseData, loading: diseasesLoading } = useMetadata({
    limit: 20,
    skip: icdCodes.length === 0,
    types: ["diseases"],
    values: icdCodes,
  });

  const { data: medicalProviders, loading: loadingMedicalProviders } = useMedicalProviders({
    limit: 1,
    where: {
      name: { _ilike: `%${prescriptionPaper?.newest_ocr_paper?.prescription?.medical_provider_name}%` },
    },
  });

  const matchIcdCodes = icdCodes.map((code) => diseaseData?.metadata.find((x) => x.value === code)).filter(Boolean);

  const originalDocumentId = prescriptionPaper?.id;
  const originalDocument = data?.claim_cases_by_pk?.claim_documents.find(({ id }) => id === prescriptionPaper?.id);
  const suggestions: Suggestions = useMemo(
    () => [
      {
        matchIds: matchIcdCodes.map((d) => d.id),
        matchObjects: matchIcdCodes,
        originalDocument,
        originalDocumentId,
        type: "icd",
        value: icdCodes,
      },
      {
        matchIds: medicalProviders?.mp_medical_providers.map((m) => m.id),
        matchObject: medicalProviders?.mp_medical_providers[0],
        matchObjects: medicalProviders?.mp_medical_providers,
        originalDocument,
        originalDocumentId,
        type: "medical_provider",
        value: prescriptionPaper?.newest_ocr_paper?.prescription?.medical_provider_name,
      },
      {
        originalDocument,
        originalDocumentId,
        type: "diagnosis",
        value: diagnoses,
      },
    ],
    [
      diagnoses,
      icdCodes,
      matchIcdCodes,
      medicalProviders?.mp_medical_providers,
      originalDocument,
      originalDocumentId,
      prescriptionPaper?.newest_ocr_paper?.prescription?.medical_provider_name,
    ],
  );

  const value: ClaimAssistantContextType = useMemo(
    () => ({
      loading: diseasesLoading || loadingMedicalProviders,
      suggestions,
    }),
    [diseasesLoading, loadingMedicalProviders, suggestions],
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ClaimAssistantContext.Provider value={value} {...props} />;
};

export { ClaimAssistantProvider };
