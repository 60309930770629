import type { InternalRefetchQueriesInclude, MutationFunction } from "@apollo/client";
import type { UploadFileMetadatasDocument } from "hooks/useUploadMetadata";
import type { DocTypeEnum, FileTypeInputEnum, IdentityRolesEnum } from "sdk/gql/graphql";
import type { ResultOf, VariablesOf } from "sdk/v2/graphql";

import { message } from "antd";
import usePMutation from "contexts/usePMutation";
import { sha256 } from "crypto-hash";
import useUploadMetadata from "hooks/useUploadMetadata";
import { first } from "lodash";
import { useState } from "react";
import { graphql } from "sdk/v2/graphql";
import { v4 as uuid } from "uuid";

const InsertFileForUseUploadFileHookDocument = graphql(`
  mutation InsertFileForUseUploadFileHook($input: files_insert_input!) {
    insert_files_one(object: $input, on_conflict: { constraint: files_company_id_key, update_columns: [updated_at, url] }) {
      id
      name
      file_id
      url
      imported_files {
        id
      }
    }
  }
`);

const UpdateFileForUseUploadFileHookDocument = graphql(`
  mutation UpdateFileForUseUploadFileHook($id: uuid!, $input: files_set_input!) {
    update_files_by_pk(pk_columns: { file_id: $id }, _set: $input) {
      id
      file_id
    }
  }
`);

type UploadFileMetadatasQuery = ResultOf<typeof UploadFileMetadatasDocument>;
type FilesInsertInput = VariablesOf<typeof InsertFileForUseUploadFileHookDocument>["input"];

interface UseUploadFileParams {
  docType?: DocTypeEnum;
  file: File;
  fileType: FileTypeInputEnum;
  id: string;
  injectMutationVariables?: ({ importedFileId }: { importedFileId: null | string }) => FilesInsertInput | null | undefined;
  keepOriginalFileName?: boolean;
  mutationVariables?: FilesInsertInput;
  onUploadFinish?: (data: { fileData?: ResultOf<typeof InsertFileForUseUploadFileHookDocument> | null }) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

type uploader = [
  ({ file, fileType, id, mutationVariables, onUploadFinish, refetchQueries }: UseUploadFileParams) => Promise<void>,
  { loading: boolean },
  MutationFunction<ResultOf<typeof UpdateFileForUseUploadFileHookDocument>, VariablesOf<typeof UpdateFileForUseUploadFileHookDocument>>,
];

interface FunctionParam {
  displayResult?: boolean;
  refetchQueries?: InternalRefetchQueriesInclude;
  role?: IdentityRolesEnum;
}

const useUploadFile = ({ displayResult = true, refetchQueries, role }: FunctionParam = {}): uploader => {
  const [getUploadFileMetadata] = useUploadMetadata();
  const [insertFileForUseUploadFileHookMutation] = usePMutation(InsertFileForUseUploadFileHookDocument, {
    refetchQueries,
  });
  const [deleteFileForUseUploadFileHookMutation, { loading: deleteFileLoading }] = usePMutation(UpdateFileForUseUploadFileHookDocument, {
    refetchQueries,
  });
  const [loading, setLoading] = useState(false);

  return [
    async ({ docType, file, fileType, id, injectMutationVariables, keepOriginalFileName = false, mutationVariables, onUploadFinish, refetchQueries: refetchQueries2 }) => {
      setLoading(true);
      const processUploadFile = async (data: UploadFileMetadatasQuery) => {
        const fileMetadata = first(data.uploadFileMetadatas);

        if (!fileMetadata) {
          if (displayResult) message.error("Tải tập tin thất bại");
          return;
        }

        const insertFileInput: FilesInsertInput = {
          bucket_name: fileMetadata.bucketName,
          bucket_name_v2: fileMetadata.bucketNameV2,
          bucket_object_key: fileMetadata.bucketObjectKey,
          hash: await sha256(await file.arrayBuffer()),
          mime_type: file.type,
          name: keepOriginalFileName ? file.name : fileMetadata.fileName,
          url: fileMetadata.publicUrl,
          ...mutationVariables,
        };
        const response = await fetch(fileMetadata.uploadUrl, { body: file, headers: { "Content-Type": file.type }, method: "PUT" });
        if (response.status !== 200) {
          if (displayResult) message.error("Tải tập tin thất bại");
          return;
        }
        await insertFileForUseUploadFileHookMutation({
          onCompleted: (fileData) => {
            setLoading(false);
            if (displayResult) message.success("Tải tập tin thành công");
            onUploadFinish?.({ fileData });
          },
          refetchQueries: refetchQueries2,
          variables: {
            input: {
              ...insertFileInput,
              ...injectMutationVariables?.({ importedFileId: fileMetadata.extendedFileData.importedFileId }),
            },
          },
        });
      };
      const { data } = await getUploadFileMetadata({
        onCompleted: (_data) => {},
        variables: {
          fileType,
          files: [
            {
              docType,
              fileName: uuid(),
              mimeType: file.type,
            },
          ],
          id,
        },
      });

      if (data) {
        await processUploadFile(data);
      }
    },
    { loading: loading || deleteFileLoading },
    deleteFileForUseUploadFileHookMutation,
  ];
};
export default useUploadFile;
