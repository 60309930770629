import { Form, Input } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvMrnField = ({ name }: Props) => (
  <Form.Item label="Mã hồ sơ" name={name}>
    <Input placeholder="" size="large" />
  </Form.Item>
);
export default SlvMrnField;
