import { Descriptions, Space, Tag, Typography } from "antd";
import ClaimIcons from "app/common/components/ClaimIcons";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import utils from "libs/utils";
import { graphql, readFragment } from "sdk/v2/graphql";

const ClaimGroupFragment = graphql(`
  fragment ClaimGroup on claim_cases {
    claim_case_same_event_groups_claim_case {
      claim_case {
        code
        claim_case_status {
          comment
        }
      }
      claim_case_same_event_group {
        code
        handed_over_to_insurer_at
        created_at
        claim_case_same_event_groups_claim_cases {
          id
          exclude_from_correspondence
          claim_case {
            id
            code
            claim_case_status {
              comment
              value
            }
          }
        }
        slv_claim_case_same_event_group {
          id
          group_status {
            comment
          }
        }
      }
    }
  }
`);

const ClaimCaseSameEventGroup = () => {
  const { claim, claimType } = useClaim();
  const claimGroupData = (() => {
    if (claim == null) return null;
    // error is expected until claim is converted into gql.tada
    return readFragment(ClaimGroupFragment, claim);
  })();
  const claimGroup = claimGroupData?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group;

  if (claimGroup == null) return null;

  return (
    <Content title={`Claim cùng nhóm: ${claimGroup.code}`}>
      <Space direction="vertical" size="large">
        <If condition={claimType.slvHs === true}>
          <Descriptions
            bordered
            items={[
              {
                children: <Tag>{claimGroup.slv_claim_case_same_event_group?.group_status.comment}</Tag>,
                key: "1",
                label: "Trạng thái",
              },
              {
                children: utils.formatDate(claimGroup.created_at),
                key: "2",
                label: "Tạo lúc",
              },
              {
                children: claimGroup.handed_over_to_insurer_at == null ? "--" : utils.formatDate(claimGroup.handed_over_to_insurer_at),
                key: "3",
                label: "Bàn giao lại cho SLV lúc",
              },
            ]}
            labelStyle={{ fontWeight: "bold" }}
          />
        </If>
        <TableList
          dataSource={claimGroup.claim_case_same_event_groups_claim_cases}
          schema={[
            {
              dataIndex: ["claim_case", "code"],
              render: (code, record) => (
                <Space>
                  <ClaimIcons claim={record.claim_case} newTab showLALabels={false} />
                  {code === claim?.code ? "(Đang xem)" : null}
                </Space>
              ),
              title: "Mã Claim",
            },
            {
              key: "status",
              render: (record) => <ClaimStatus value={record.claim_case.claim_case_status} />,
              title: "Trạng thái",
            },
            {
              dataIndex: "exclude_from_correspondence",
              render: (excludeFromCorrespondence) =>
                excludeFromCorrespondence === false ? <Typography.Text type="success">Có</Typography.Text> : <Typography.Text type="danger">Không</Typography.Text>,
              title: "Hiển thị trong nội dung thư",
            },
          ]}
        />
      </Space>
    </Content>
  );
};

export default ClaimCaseSameEventGroup;
