import { Space, notification } from "antd";
import Content from "app/common/components/Content";
import Copyable from "app/common/components/Copyable";
import ExternalLink from "app/common/components/ExternalLink";
import TableList from "app/common/components/TableListV2";
import PaymentRequestForm from "app/portal/screens/SalePortal/PaymentRequests/PaymentRequestForm";
import usePQuery from "contexts/usePQuery";
import dedent from "dedent";
import utils from "libs/utils";
import { CopyIcon } from "lucide-react";
import { graphql } from "sdk/v2/graphql";

export const PaymentRequestsDocument = graphql(`
  query PaymentRequests {
    mango {
      health_care_orders(order_by: { created_at: desc }, where: { deleted_at: { _is_null: true } }) {
        id: health_care_order_id
        amount
        buyer_email
        buyer_name
        buyer_phone
        order_code
        status
        created_at
        insurance_company {
          id
          name
        }
        payment {
          paid_by
          request_result_payload
        }
      }
    }
  }
`);

const PaymentRequests = () => {
  const { data } = usePQuery(PaymentRequestsDocument);

  return (
    <Content>
      <PaymentRequestForm />
      <TableList
        columns={[
          {
            dataIndex: "order_code",
            title: "Order Code",
          },
          {
            dataIndex: "buyer_name",
            title: "Buyer Name",
          },
          {
            dataIndex: "buyer_email",
            title: "Buyer Email",
          },
          {
            dataIndex: "buyer_phone",
            title: "Buyer Phone",
          },
          {
            dataIndex: ["insurance_company", "name"],
            title: "Insurance Company",
          },
          {
            dataIndex: "amount",
            render: (value) => utils.formatCurrency(value),
            title: "Amount",
          },
          {
            dataIndex: ["payment", "request_result_payload", "ChaiCreatePaymentLink", "payment_link"],
            render: (value) => (
              <ExternalLink to={value}>
                <Copyable>{value}</Copyable>
              </ExternalLink>
            ),
            title: "Payment Link",
          },
          {
            dataIndex: ["payment", "request_result_payload", "EpayCreateDepositCode", "account_no"],
            render: (value) => (
              <Space>
                <Space direction="vertical">
                  <div>Số tài khoản: {value}</div>
                  <div>Ngân hàng: WOORIBANK</div>
                </Space>
                <CopyIcon
                  className="cursor-pointer link-color"
                  onClick={(e) => {
                    utils.saveToClipboard(
                      e,
                      dedent`
                        Số tài khoản: ${value}
                        Ngân hàng: WOORIBANK
                      `,
                      "",
                    );
                    notification.success({ message: "Đã copy" });
                  }}
                  size={16}
                />
              </Space>
            ),
            title: "Thông tin chuyển khoản",
          },
          {
            dataIndex: "status",
            title: "Status",
          },
          {
            dataIndex: ["payment", "paid_by"],
            title: "Paid by",
          },
          {
            dataIndex: "created_at",
            render: (value) => utils.formatDate(value),
            title: "Created At",
          },
        ]}
        dataSource={data?.mango?.health_care_orders}
        title="Payment Requests"
      />
    </Content>
  );
};

export default PaymentRequests;
