import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";

import { gql } from "@apollo/client";
import usePQuery from "contexts/usePQuery";
import { graphql } from "sdk/v2/graphql";

const GET_UNREAD_CLAIM_CASES_QUERY = graphql(`
  query UnreadClaimCases($where: notification_notifications_bool_exp!) {
    notification_notifications(where: $where) {
      id
      claim_case_id: origin_id
      company_id: receiver_id
      origin_claim_case {
        claim_case_id
      }
      origin_type
      receiver_type
    }
  }
`);

gql`
  mutation UpdateClaimCaseUnread($where: notification_notifications_bool_exp!, $input: notification_notifications_set_input) {
    update_notification_notifications(where: $where, _set: $input) {
      affected_rows
    }
  }
`;

const useClaimNotification = ({ companyId, status }: { companyId: string | undefined; status?: Array<ClaimCaseStatusesEnum> }) => {
  const { data } = usePQuery(GET_UNREAD_CLAIM_CASES_QUERY, {
    pollInterval: 10_000,
    skip: companyId == null || status == null,
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        first_seen_at: {
          _is_null: true,
        },
        origin_claim_case: {
          status: {
            _in: status,
          },
        },
        receiver_id: {
          _eq: companyId,
        },
      },
    },
  });
  return {
    claimCaseUnreadList: data?.notification_notifications.map((item) => item.origin_claim_case?.claim_case_id),
    total: data?.notification_notifications.length,
  };
};

export default useClaimNotification;
