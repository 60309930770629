import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { FC } from "react";

import { Badge, Tabs } from "antd";
import Content from "app/common/components/Content";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useSearchParams } from "react-router-dom";
import { graphql as codegenThisGraphqlTag, readFragment } from "sdk/v2/graphql";

import styles from "./SignOff.module.less";
import SignOffItem from "./SignOffItem";
import "./style.css";

interface Props {
  claimCaseId: string;
  data?: NonNullable<ClaimContextType["claim"]>["claim_notes"];
}

const SIGN_OFF_TYPE_KEYS = {
  MEDICAL_PROVIDER: "MEDICAL_PROVIDER",
  PAPAYA: "PAPAYA",
} as const;

export const ClaimNotesFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimNotes on claim_cases {
    sign_off_claim_notes: claim_notes(where: { type: { _in: [SignOff, PresentCaseNote, LaNote, MEDICAL_PROVIDER] } }, order_by: { created_at: asc }) {
      id
      content
      share_to_insurer
      type
      user_id
      created_at
      emails(order_by: { created_at: asc }) {
        id
        reply_text
      }
      user {
        id
        name
        avatar_url
        email
        roles {
          id
          role
        }
      }
    }
  }
`);

const SignOff: FC<Props> = ({ claimCaseId }) => {
  const { claim } = useClaim();
  const [searchParams, setSearchParams] = useSearchParams({ chatTabKey: SIGN_OFF_TYPE_KEYS.PAPAYA });
  const chatTabKey = (searchParams.get("chatTabKey") ?? SIGN_OFF_TYPE_KEYS.PAPAYA) as keyof typeof SIGN_OFF_TYPE_KEYS;
  const data = (() => {
    if (claim == null) return null;
    return readFragment(ClaimNotesFragment, claim).sign_off_claim_notes;
  })();

  return (
    <Content title="Ghi chú">
      <Tabs
        activeKey={chatTabKey}
        className={styles.signOffWrapper}
        items={[
          {
            children: <SignOffItem chatTabKey={chatTabKey} claimCaseId={claimCaseId} data={data} />,
            key: SIGN_OFF_TYPE_KEYS.PAPAYA,
            label: "Papaya",
          },
          claim?.is_direct_billing === true
            ? {
                children: <SignOffItem chatTabKey={chatTabKey} claimCaseId={claimCaseId} data={data} />,
                key: SIGN_OFF_TYPE_KEYS.MEDICAL_PROVIDER,
                label: (
                  <Badge count={data?.filter((i) => i.type === "MEDICAL_PROVIDER").length} showZero>
                    Cơ sở y tế
                  </Badge>
                ),
              }
            : null,
        ].filter(Boolean)}
        onChange={(key) => {
          setSearchParams((params) => {
            params.set("chatTabKey", key);
            return params;
          });
        }}
      />
    </Content>
  );
};

export default SignOff;
