import { graphql } from "sdk/v2/graphql";

const CERTIFICATE_FOR_LA_WITH_DEPENDANT_FRAGMENT = graphql(`
  fragment CertificateForLAWithDependant on insured_certificates {
    id
    insured_certificate_id
    insured_relationship_type {
      comment
      value
    }
    policy {
      id
      policy_id
      policy_number
      corporate_company {
        id
        name
      }
      insurer_company {
        id
        name
      }
    }
  }
`);

const CLAIM_CASE_AGGREGATE_FOR_LA_WITH_DEPENDANT_FRAGMENT = graphql(`
  fragment ClaimCaseAggregateForLAWithDependant on claim_cases_aggregate {
    aggregate {
      count
    }
    nodes {
      id
      code
      claim_case_payment {
        id
        actual_paid_amount
        requested_amount
      }
      claim_case_status {
        comment
        value
      }
      insured_benefit_type {
        comment
        value
      }
    }
  }
`);

const INSURED_PERSON_FOR_LA_WITH_DEPENDANT_FRAGMENT = graphql(
  `
    fragment InsuredPersonForLAWithDependant on insured_persons {
      id
      name
      age
      dob
      email
      gender
      phone
      claim_cases_aggregate {
        ...ClaimCaseAggregateForLAWithDependant
      }
    }
  `,
  [CLAIM_CASE_AGGREGATE_FOR_LA_WITH_DEPENDANT_FRAGMENT],
);

const LA_WITH_DEPENDANT_QUERY = graphql(
  `
    query LAWithDependant($where: insured_certificates_bool_exp!, $limit: Int!, $offset: Int!) {
      insured_certificates(where: $where, limit: $limit, offset: $offset, order_by: { insured_person: { claim_cases_aggregate: { count: desc } } }) {
        ...CertificateForLAWithDependant
        children_insured_certificates {
          ...CertificateForLAWithDependant
          insured_person {
            ...InsuredPersonForLAWithDependant
          }
          parent_insured_certificate {
            id
            insured_person {
              ...InsuredPersonForLAWithDependant
            }
          }
        }
        insured_person {
          ...InsuredPersonForLAWithDependant
        }
        parent_insured_certificate {
          id
          insured_person {
            ...InsuredPersonForLAWithDependant
          }
        }
      }
      insured_certificates_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  [CERTIFICATE_FOR_LA_WITH_DEPENDANT_FRAGMENT, INSURED_PERSON_FOR_LA_WITH_DEPENDANT_FRAGMENT],
);

const LA_WITH_EXPIRED_STATUS_QUERY = graphql(
  `
    query LAWithExpiredStatus($where: insured_certificates_bool_exp!, $limit: Int!, $offset: Int!) {
      insured_certificates(where: $where, limit: $limit, offset: $offset, order_by: { action_logs_aggregate: { max: { created_at: desc_nulls_last } } }) {
        ...CertificateForLAWithDependant
        dued_at
        action_logs(where: { new_value: { _eq: "EXPIRED" } }) {
          id
          created_at
        }
        insured_person {
          ...InsuredPersonForLAWithDependant
        }
      }
      insured_certificates_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  [CERTIFICATE_FOR_LA_WITH_DEPENDANT_FRAGMENT, INSURED_PERSON_FOR_LA_WITH_DEPENDANT_FRAGMENT],
);

export { CERTIFICATE_FOR_LA_WITH_DEPENDANT_FRAGMENT, INSURED_PERSON_FOR_LA_WITH_DEPENDANT_FRAGMENT, LA_WITH_DEPENDANT_QUERY, LA_WITH_EXPIRED_STATUS_QUERY };
