import type { VariablesOf } from "sdk/v2/graphql";

import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { graphql } from "sdk/v2/graphql";

export const InsertClaimCaseDeclineHistoryDocument = graphql(`
  mutation InsertClaimCaseDeclineHistory($data: claim_case_decline_histories_insert_input!) {
    insert_claim_case_decline_histories_one(
      object: $data
      on_conflict: { constraint: ccdh_claim_case_id_decline_code_deleted_at_uniq, update_columns: [decline_code, decline_description] }
    ) {
      id
    }
  }
`);

const GetPpyDeclineCodesDocument = graphql(`
  query PPYDeclineCodes($where: enum_localizations_bool_exp!) {
    decline_codes {
      enum_localizations(where: $where) {
        id
        enum_translation
        enum_value
      }
      insurer_decline_code {
        id
        insurer_id
        product_id
      }
    }
  }
`);

const usePpyDeclineCodes = () => {
  const where: VariablesOf<typeof GetPpyDeclineCodesDocument>["where"] = { _and: [{ enum_type: { _eq: "decline_codes" }, locale: { _eq: "vi" } }] };

  const { data } = usePQuery(GetPpyDeclineCodesDocument, {
    variables: {
      where,
    },
  });

  return data?.decline_codes ?? [];
};

const useInsertClaimCaseDeclineHistory = () => usePMutation(InsertClaimCaseDeclineHistoryDocument);

export { useInsertClaimCaseDeclineHistory, usePpyDeclineCodes };
