import { getOperationName } from "@apollo/client/utilities";
import { notification, Space } from "antd";
import BaseForm from "app/common/components/Form";
import { PaymentRequestsDocument } from "app/portal/screens/SalePortal/PaymentRequests";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import utils from "libs/utils";
import randomstring from "randomstring";
import { graphql } from "sdk/v2/graphql";

const createHealthcareOrderDocument = graphql(`
  mutation CreateHealthcareOrder($input: m_health_care_orders_insert_input!) {
    mango {
      insert_health_care_orders_one(object: $input) {
        id: health_care_order_id
      }
    }
  }
`);

const createPaymentRequestDocument = graphql(`
  mutation CreatePaymentRequest($orderId: String!) {
    mango {
      createHealthcarePayment(orderId: $orderId) {
        depositDetail {
          accountName
          accountNo
          bankCode
          bankName
        }
      }
    }
  }
`);

const checkOrderCodeDocument = graphql(`
  query CheckOrderCode($orderCode: String!) {
    mango {
      health_care_orders(where: { order_code: { _eq: $orderCode } }) {
        order_code
      }
    }
  }
`);

const PaymentRequestForm = () => {
  const [createHealthcareOrderMutation] = usePMutation(createHealthcareOrderDocument);
  const [createPaymentRequest, { loading }] = usePMutation(createPaymentRequestDocument, { refetchQueries: [getOperationName(PaymentRequestsDocument)].filter(Boolean) });
  const [checkOrderCode] = usePLazyQuery(checkOrderCodeDocument);
  const onFinish = async (values) => {
    let orderCode = randomstring.generate({ charset: "123456789", length: 6 });

    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const checkOrderCodeData = await checkOrderCode({ variables: { orderCode } });
      if (checkOrderCodeData.data?.mango?.health_care_orders.length === 0) {
        break;
      }
      orderCode = randomstring.generate({ charset: "123456789", length: 6 });
    }

    const { data } = await createHealthcareOrderMutation({
      variables: {
        input: {
          amount: values.amount,
          buyer_email: values.buyer_email.trim(),
          buyer_name: utils.capitalCase(values.buyer_name)?.trim(),
          buyer_phone: values.buyer_phone.trim(),
          confirm_answer_date: "now()",
          insurance_company_id: 6,
          order_code: orderCode,
          status: "AWAIT_PAYMENT",
        },
      },
    });
    const id = data?.mango?.insert_health_care_orders_one?.id as null | string;
    if (id == null) {
      notification.error({ message: "Failed to create order" });
      return;
    }

    const res = await createPaymentRequest({
      variables: {
        orderId: id,
      },
    });
    if (res.data?.mango?.createHealthcarePayment?.depositDetail?.accountName != null) {
      notification.success({ message: "Payment request created" });
    }
  };

  return (
    <Space>
      <BaseForm
        disabled={{
          order_code: true,
        }}
        loading={loading}
        onFinish={onFinish}
        schemas={[
          {
            component: "input",
            key: "buyer_name",
            label: "Buyer Name",
            required: true,
            type: "text",
          },

          {
            component: "input",
            key: "buyer_phone",
            label: "Buyer Phone",
            required: true,
            type: "text",
          },
          {
            component: "input",
            key: "buyer_email",
            label: "Buyer Email",
            required: true,
            type: "text",
          },
          {
            component: "inputNumber",
            key: "amount",
            label: "Amount",
            required: true,
          },
        ]}
      />
    </Space>
  );
};

export default PaymentRequestForm;
