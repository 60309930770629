import type { TableColumnsType } from "antd";
import type { BaseFormSchema } from "app/common/components/Form";

import { FORMATTER, TYPE } from "config/constants";
import { utils } from "libs/utils";

import type { InsuredPersonRemarksFragmentFragment } from "./screens/detail.generated";

import styles from "./InsuredPersonScreen.module.css";

const remarkListSchema: TableColumnsType<InsuredPersonRemarksFragmentFragment> = [
  {
    dataIndex: "description",
    render: (text) => <div className={styles.displayLinebreak}>{text}</div>,
    title: "Chi tiết",
  },
  {
    dataIndex: ["insured_person_remark_benefit_type", "comment"],
    title: "Quyền lợi áp dụng",
  },
  {
    dataIndex: "created_at",
    render: (text) => utils.formatDate(text, FORMATTER.DATE_FORMAT),
    title: "Ngày tạo",
  },
];

const formSchema: BaseFormSchema = [
  {
    component: "input",
    key: "name",
    label: "Họ và tên",
    placeholder: "Nguyễn Văn A",
    required: true,
    type: "text",
  },
  {
    component: "input",
    key: "paper_id",
    label: "CMND/CCCD",
    placeholder: "790123454344",
    required: true,
    type: "text",
  },
  {
    component: "date",
    format: FORMATTER.DATE_FORMAT,
    key: "paper_issued_at",
    label: "Ngày cấp CMND/CCCD",
    placeholder: "30/06/1994",
  },
  {
    component: "input",
    key: "paper_issued_place",
    label: "Nơi cấp CMND/CCCD",
    placeholder: "TP HCM",
    type: "text",
  },
  {
    component: "date",
    format: FORMATTER.DATE_FORMAT,
    key: "dob",
    label: "Ngày sinh",
    placeholder: "30/06/1994",
    required: true,
  },
  {
    component: "select",
    key: "gender",
    label: "Giới tính",
    placeholder: "Nam",
    required: true,
  },
  {
    component: "select",
    key: "vip_level",
    label: "Cấp VIP",
  },
  {
    component: "input",
    key: "email",
    label: "Email",
    type: "text",
  },
  {
    component: "input",
    key: "phone",
    label: "Phone",
    type: "text",
  },
];

const remarkFormSchema: BaseFormSchema = [
  {
    autoSize: { minRows: 10 },
    component: "textArea",
    key: "description",
    label: "Chi tiết",
    placeholder: `Copay: 30%
Bệnh có sẵn: ICD10
Thời gian chờ: 30 ngày`,
    required: true,
  },
  {
    component: "select",
    key: "insured_benefit_type",
    label: "Áp dụng cho quyền lợi",
    required: false,
  },
];

const option = {
  gender: [
    {
      name: TYPE.GENDER_TYPE[0],
      value: 0,
    },
    {
      name: TYPE.GENDER_TYPE[1],
      value: 1,
    },
  ],
};

export { formSchema, option, remarkFormSchema, remarkListSchema };
