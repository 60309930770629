import type { FormInstance} from "antd";

import { Form, Input } from "antd";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;
const DoctorField = ({ form, name }: Props) => (
  <Form.Item label="Bác sĩ điều trị" name={name}>
    <Input placeholder="Tên bác sĩ" size="large" />
  </Form.Item>
);
export default DoctorField;
