import type { InsuredCertificateStatusesEnum, PolicyStatusesEnum } from "sdk/gql/graphql";

import { Tag } from "antd";

const PolicyStatus = ({ status }: { status: InsuredCertificateStatusesEnum | PolicyStatusesEnum | null | undefined }) => {
  const color: Record<PolicyStatusesEnum, string> = {
    ACTIVE: "green",
    DORMANT: "black",
    LAPSED: "orange",
    REVERSED: "black",
    SUSPENDED: "red",
  };
  return status == null ? <>--</> : <Tag color={color[status] ?? "blue"}>{status}</Tag>;
};

export default PolicyStatus;
