import type { NotificationInstance } from "antd/es/notification/interface";
import type { FC, ReactNode } from "react";

import { useLocalStorageState } from "ahooks";
import { notification } from "antd";
import { isEmpty, omit } from "lodash";
import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { useLocation } from "react-router-dom";

import MenuConfig from "./components/MenuConfig";

const options = {
  defaultValue: { selectedMenuGroups: [], showMenu: true },
  logger: () => {},
  parser: (str) => JSON.parse(str),
  serializer: (obj) => JSON.stringify(obj),
  syncData: false,
};

interface ILayout {
  dispatchLayoutState?: ({ payload, type }: IThemeReducerAction) => void;
  notification?: NotificationInstance;
  selectedMenuGroups?: string[];
  showMenu?: Boolean;
  showRelevantEmailsInClaimDetail?: boolean;
}

interface IThemeReducerAction {
  payload: {
    selectedMenuGroups?: ILayout["selectedMenuGroups"];
    showMenu?: ILayout["showMenu"];
    showRelevantEmailsInClaimDetail?: ILayout["showRelevantEmailsInClaimDetail"];
  };
  type: "SELECT_CLAIM" | "SELECT_MENU_GROUPS" | "SELECT_MENU_ITEM" | "TOGGLE_MENU" | "TOGGLE_RELEVANT_EMAILS" | "TOGGLE_SEARCH_CLAIM_LIST";
}

type Props = { children: ReactNode } & Record<string, unknown>;

export const LayoutContext = createContext<ILayout>({
  dispatchLayoutState: () => {},
  selectedMenuGroups: [],
  showMenu: true,
});

const layoutReducer = (state: ILayout, action: IThemeReducerAction) => {
  switch (action.type) {
    case "TOGGLE_MENU": {
      return {
        ...state,
        showMenu: !state.showMenu,
      };
    }
    case "SELECT_MENU_GROUPS": {
      return {
        ...state,
        selectedMenuGroups: action.payload.selectedMenuGroups ?? [],
      };
    }
    case "TOGGLE_RELEVANT_EMAILS": {
      return {
        ...state,
        showRelevantEmailsInClaimDetail: action.payload.showRelevantEmailsInClaimDetail,
      };
    }

    default: {
      return state;
    }
  }
};

type LayoutLocalStorageState = Pick<ILayout, "selectedMenuGroups" | "showMenu">;
export const LayoutProvider: FC<Props> = ({ children }) => {
  const [layoutLocalStorageState, setLayoutLocalStorageState] = useLocalStorageState("localData", options);
  const [layoutState, dispatchLayoutState] = useReducer(layoutReducer, layoutLocalStorageState ?? options.defaultValue);
  const [notificationApi, contextHolder] = notification.useNotification();

  const location = useLocation();
  let currentRootMenuLabel = MenuConfig.find(
    (menuConfig) =>
      menuConfig.children?.some((subMenuConfig) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (typeof subMenuConfig.path === "string" && subMenuConfig.path.replaceAll != null) {
          return typeof subMenuConfig.path === "string" && location.pathname.includes(subMenuConfig.path.replaceAll(/\/:(?<=\/:)[\d/:a-z]+/gi, ""));
        }
        // eslint-disable-next-line unicorn/prefer-string-replace-all
        return typeof subMenuConfig.path === "string" && location.pathname.includes(subMenuConfig.path.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, ""));
      }) === true,
  )?.label;
  if (location.pathname.includes("/portal/claimCase")) {
    currentRootMenuLabel = "Claim Group";
  }

  const value = useMemo(() => {
    const selectedMenuGroups = [currentRootMenuLabel];
    return {
      ...layoutState,
      notification: notificationApi,
      selectedMenuGroups: isEmpty(layoutState.selectedMenuGroups) ? selectedMenuGroups : layoutState.selectedMenuGroups,
    };
  }, [layoutState, currentRootMenuLabel, notificationApi]);

  useEffect(() => {
    setLayoutLocalStorageState(omit(value, "dispatchLayoutState"));
  }, [value, setLayoutLocalStorageState]);

  const persistedValues = useMemo(
    () => ({
      ...layoutLocalStorageState,
      dispatchLayoutState,
    }),
    [dispatchLayoutState, layoutLocalStorageState],
  );

  return (
    <LayoutContext.Provider value={persistedValues}>
      <>
        {children}
        {contextHolder}
      </>
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
