import type BalanceDetailFragment from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/BalanceDetailFragment";
import type { FragmentOf } from "sdk/v2/graphql";

import { useToggle } from "ahooks";
import { Card, Modal, Space, Statistic, Tooltip, Typography } from "antd";
import Copyable from "app/common/components/Copyable";
import If from "app/common/components/If";
import { PORTAL_PATH } from "app/portal/config/paths";
import dedent from "dedent";
import utils from "libs/utils";
import { sortBy, uniq } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, generatePath } from "react-router-dom";

import { getValueWithCorrectUnit } from "../../helpers";
import styles from "../ClaimBenefitVer3.module.css";

type BenefitError = {
  balanceId: string;
  benefitId: string;
};
type BalanceDetailFormats = {
  default: FragmentOf<typeof BalanceDetailFragment>;
  percentageInNumber: FragmentOf<typeof BalanceDetailFragment>;
};

const CoverageBalanceCard = ({
  balanceDetail: currentBalanceDetail,
  error,
  planInsuredBenefitKey,
  policySumAssured,
}: {
  balanceDetail: FragmentOf<typeof BalanceDetailFragment>;
  error?: BenefitError | null;
  planInsuredBenefitKey: string;
  policySumAssured?: null | number;
}) => {
  const [showUsedClaimDetailBalance, { toggle }] = useToggle(false);
  useHotkeys("`", () => {
    toggle();
  });

  const convertPercentageToNumberFormat = (value: number) =>
    currentBalanceDetail.type === "SUM_ASSURED_PERCENTAGE_PER_POLICY" && policySumAssured != null ? (value * policySumAssured) / 100 : value;

  const balanceDetailFormats: BalanceDetailFormats = {
    default: currentBalanceDetail,
    percentageInNumber: {
      ...currentBalanceDetail,
      balance: convertPercentageToNumberFormat(currentBalanceDetail.balance),
      balanceRemaining: convertPercentageToNumberFormat(currentBalanceDetail.balanceRemaining),
      balanceRemainingBuffer: convertPercentageToNumberFormat(currentBalanceDetail.balanceRemainingBuffer),
      balanceUsed: convertPercentageToNumberFormat(currentBalanceDetail.balanceUsed),
      currentBalanceUseBuffer: convertPercentageToNumberFormat(currentBalanceDetail.currentBalanceUseBuffer),
      exclusiveBalanceRemainingBuffer: convertPercentageToNumberFormat(currentBalanceDetail.exclusiveBalanceRemainingBuffer),
      otherBalanceUsedBuffer: convertPercentageToNumberFormat(currentBalanceDetail.otherBalanceUsedBuffer),
    },
  };

  const balanceDetail = currentBalanceDetail.type === "SUM_ASSURED_PERCENTAGE_PER_POLICY" ? balanceDetailFormats.percentageInNumber : balanceDetailFormats.default;

  const unitName = balanceDetail.type === "SUM_ASSURED_PERCENTAGE_PER_POLICY" ? "đ" : balanceDetail.plan_balance_type?.comment?.replace("Tiền", "đ");

  return (
    <Card className={[error?.benefitId === planInsuredBenefitKey && error.balanceId === balanceDetail.id ? styles.error : ""].join(" ")} size="small">
      <Space direction="vertical" size={4}>
        <Typography.Paragraph
          onClick={(e) => {
            if (e.metaKey) {
              Modal.info({
                content: <pre style={{ fontSize: "12px", whiteSpace: "pre-wrap" }}>{JSON.stringify(balanceDetail, null, 2)}</pre>,
                title: "",
                width: "60%",
              });
            }
          }}
          style={{ fontSize: 18 }}
        >
          <Tooltip
            title={
              <Space direction="vertical">
                <div>tech: cmd + click to view detail.</div>
                <div>
                  planBalanceId: <Copyable copyableText={balanceDetail.planBalanceId} show style={{ color: "white" }} />
                </div>
              </Space>
            }
          >
            {balanceDetail.name}
          </Tooltip>
        </Typography.Paragraph>
        <Statistic
          suffix={import.meta.env.DEV ? `${unitName} (${balanceDetail.type})` : unitName}
          title="Tổng hạn mức"
          value={utils.formatNumber(balanceDetail.balance)}
          valueStyle={{ fontSize: "1rem" }}
        />
        <Statistic
          prefix={
            <Tooltip
              open={showUsedClaimDetailBalance ? true : undefined}
              title={
                <div>
                  Đã chính thức sử dụng: {utils.formatNumber(balanceDetail.balanceUsed)} {unitName} - {balanceDetail.balanceUsedClaimDetails?.length ?? 0} QL -{" "}
                  {uniq(balanceDetail.balanceUsedClaimDetails?.map((c) => c?.claimCaseDetail?.claim_case.code)).length} cases
                  <ul>
                    {sortBy(balanceDetail.balanceUsedClaimDetails, (o) => o?.claimCaseDetail?.claim_case.code).map(
                      (c) =>
                        c?.claimCaseDetail?.claim_case != null && (
                          <li key={c.claimCaseDetail.id}>
                            <Link
                              to={`${generatePath(PORTAL_PATH.CLAIM_CASE, {
                                claimCaseId: c.claimCaseDetail.claim_case.id,
                              })}?ccd_id=${c.claimCaseDetail.id}#assessment`}
                            >
                              {dedent`
                                ${c.claimCaseDetail.claim_case.code}: ${utils.formatNumber(
                                  getValueWithCorrectUnit({
                                    claimCaseDetail: c.claimCaseDetail,
                                    type: balanceDetail.type,
                                  }),
                                )}
                                ${unitName}
                              `}
                            </Link>
                            <If condition={import.meta.env.DEV}>
                              <br />
                              ID: {c.claimCaseDetail.id}
                            </If>
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              }
              trigger="hover"
            >
              {utils.formatNumber(balanceDetail.balanceRemaining)}
            </Tooltip>
          }
          title={
            <div>
              <Tooltip title="Là hạn mức tiền/lần/vụ còn lại trên tất cả claim case đã duyệt.">Còn lại (Chính thức)</Tooltip>
            </div>
          }
          value={import.meta.env.DEV ? `${unitName} (${balanceDetail.type})` : unitName}
          valueStyle={{ fontSize: "1rem" }}
        />
        <Statistic
          prefix={
            <Tooltip
              title={
                <div>
                  <div>
                    - Đang dự chi ở các QL khác (trừ QL này): {utils.formatNumber(balanceDetail.otherBalanceUsedBuffer)} {unitName}
                    <div>
                      <If condition={balanceDetail.exclusiveBalanceRemainingBufferClaimDetails == null || balanceDetail.exclusiveBalanceRemainingBufferClaimDetails.length === 0}>
                        N/A
                      </If>
                      <ul>
                        {sortBy(balanceDetail.exclusiveBalanceRemainingBufferClaimDetails, (o) => o?.claimCaseDetail?.claim_case.code).map(
                          (c) =>
                            c?.claimCaseDetail?.id != null && (
                              <li key={c.claimCaseDetail.id}>
                                <Link
                                  to={`${generatePath(PORTAL_PATH.CLAIM_CASE, {
                                    claimCaseId: c.claimCaseDetail.claim_case.id,
                                  })}?ccd_id=${c.claimCaseDetail.id}#assessment`}
                                >
                                  {dedent`
                                    ${c.claimCaseDetail.claim_case.code}: ${utils.formatNumber(
                                      getValueWithCorrectUnit({
                                        claimCaseDetail: c.claimCaseDetail,
                                        type: balanceDetail.type,
                                      }),
                                    )}
                                    ${unitName}
                                  `}
                                </Link>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                  <div>
                    - Đang dự chi ở tất cả các QL khác (bao gồm QL này): {utils.formatNumber(balanceDetail.currentBalanceUseBuffer)} {unitName}
                  </div>
                  <div>
                    - QL này có thể sử dụng: {balanceDetail.exclusiveBalanceRemainingBuffer > 0 ? utils.formatNumber(balanceDetail.exclusiveBalanceRemainingBuffer) : 0} {unitName}
                  </div>
                </div>
              }
            >
              {utils.formatNumber(balanceDetail.balance - (balanceDetail.balanceUsed + balanceDetail.currentBalanceUseBuffer + balanceDetail.otherBalanceUsedBuffer))}
            </Tooltip>
          }
          title={<Tooltip title="Là hạn mức tiền/lần/vụ còn lại trên tất cả claim case đã duyệt và đang thẩm định bao gồm claim case này.">Còn lại (dự chi)</Tooltip>}
          value={import.meta.env.DEV ? `${unitName} (${balanceDetail.type})` : unitName}
          valueStyle={{ fontSize: "1rem" }}
        />
      </Space>
    </Card>
  );
};

export default CoverageBalanceCard;
