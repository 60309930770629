import type { LinkProps } from "react-router-dom";

import { ExternalLink as ELink } from "lucide-react";
import { Link } from "react-router-dom";

const ExternalLink = ({ children, size = 14, to, ...props }: { size?: number } & LinkProps) => (
  <Link target="_blank" to={to} {...props} style={{ display: "-webkit-inline-flex" }}>
    {children}
    <ELink className="cursor-pointer margin-left-5 mt-[3px]" size={size} />
  </Link>
);

export default ExternalLink;
