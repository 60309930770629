import { Button, Descriptions, Modal, Space } from "antd";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useSummary from "app/portal/screens/ClaimPortal/ClaimContext/useSummary";
import { FORMATTER, NON_LIFE_INSURER_IDS, TYPE } from "config/constants";
import utils from "libs/utils";
import mustache from "mustache";

import styles from "./Summary.module.less";
import pjicoPresentCaseTemplate from "./pjico_present_case.html?raw";

const Summary = () => {
  const { items } = useSummary(false);
  const { claim } = useClaim();

  if (claim == null) return null;

  return (
    <Space direction="vertical">
      <Descriptions
        className={styles.container}
        column={1}
        contentStyle={{ paddingBottom: 0 }}
        items={items}
        labelStyle={{ color: "rgba(0, 0, 0, 0.88)" }}
        style={{ maxHeight: "100vh", overflow: "auto" }}
      />
      <If condition={claim.insured_certificate.policy.insurer_company?.parent_company?.id === NON_LIFE_INSURER_IDS.PJICO}>
        <Content>
          <Button
            onClick={() => {
              const html = mustache.render(pjicoPresentCaseTemplate, {
                ...claim,
                admission_date: utils.formatDate(claim.admission_date, FORMATTER.DATE_FORMAT),
                claim_case_payment: {
                  actual_paid_amount: utils.formatNumber(claim.claim_case_payment?.actual_paid_amount),
                  requested_amount: utils.formatNumber(claim.request_amount),
                },
                discharge_date: utils.formatDate(claim.discharge_date, FORMATTER.DATE_FORMAT),
                event_date: utils.formatDate(claim.event_date, FORMATTER.DATE_FORMAT),
                exclusionInfo: claim.assessment_summary[0]?.content,
                laDob: utils.formatDate(claim.insured_certificate.insured_person.dob, FORMATTER.DATE_FORMAT),
                laGender: TYPE.GENDER_TYPE[claim.insured_certificate.insured_person.gender ?? ""],
                physical_examination_date: utils.formatDate(claim.physical_examination_date, FORMATTER.DATE_FORMAT),
                policyEffectiveDates: `${utils.formatDate(claim.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT)} - ${utils.formatDate(claim.insured_certificate.policy.dued_at, FORMATTER.DATE_FORMAT)}`,
              });
              Modal.info({
                cancelText: "Copy",
                content: (
                  <Space align="center" direction="vertical">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: html,
                      }}
                    />
                    <Button
                      onClick={() => {
                        navigator.clipboard.write([
                          new ClipboardItem({
                            "text/html": new Blob([html], {
                              type: "text/html",
                            }),
                          }),
                        ]);
                      }}
                      type="primary"
                    >
                      Copy
                    </Button>
                  </Space>
                ),
                maskClosable: true,
                okText: "Tắt",
                title: "Nội dung trình case cho PJICO",
                width: 1000,
              });
            }}
          >
            Lấy nội dung trình case cho PJICO
          </Button>
        </Content>
      </If>
    </Space>
  );
};

export default Summary;
