import { PORTAL_PATH } from "app/portal/config/paths";
import { INSURER_COMPANY_IDS } from "config/constants";

export enum INSURER_CLAIM_TYPE {
  FWD = "FWD",
  GENERAL = "GENERAL",
  MBAL = "MBAL",
  PTI = "PTI",
  SLV_HS = "SLV_HS",
}

export const CLAIM_LIST_PATH = {
  [INSURER_CLAIM_TYPE.FWD]: PORTAL_PATH.FWD_MR_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.GENERAL]: PORTAL_PATH.HC_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.MBAL]: PORTAL_PATH.MBAL_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.SLV_HS]: PORTAL_PATH.SLV_HS_CLAIM_LIST,
} as const;

export const CLAIM_DETAIL_PATH = {
  [INSURER_CLAIM_TYPE.FWD]: PORTAL_PATH.FWD_MR_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.GENERAL]: PORTAL_PATH.HC_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.MBAL]: PORTAL_PATH.MBAL_HS_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.PTI]: PORTAL_PATH.HC_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.SLV_HS]: PORTAL_PATH.SLV_HS_CLAIM_CASE,
} as const;

export const getInsurerIdByClaimType = (claimType?: INSURER_CLAIM_TYPE) => {
  switch (claimType) {
    case INSURER_CLAIM_TYPE.FWD: {
      return INSURER_COMPANY_IDS.FWD;
    }
    case INSURER_CLAIM_TYPE.MBAL: {
      return INSURER_COMPANY_IDS.MBAges;
    }
    case INSURER_CLAIM_TYPE.PTI: {
      return INSURER_COMPANY_IDS.PTI;
    }
    case INSURER_CLAIM_TYPE.SLV_HS: {
      return INSURER_COMPANY_IDS.SLV;
    }
    default: {
      return null;
    }
  }
};

export const getInsurerClaimType = (
  claim?: {
    insured_certificate?: {
      policy: {
        insurer_company: {
          company_id: string;
        } | null;
      };
    };
  } | null,
) => {
  switch (claim?.insured_certificate?.policy.insurer_company?.company_id) {
    case INSURER_COMPANY_IDS.PTI: {
      return INSURER_CLAIM_TYPE.PTI;
    }
    case INSURER_COMPANY_IDS.FWD: {
      return INSURER_CLAIM_TYPE.FWD;
    }
    case INSURER_COMPANY_IDS.MBAges: {
      return INSURER_CLAIM_TYPE.MBAL;
    }
    case INSURER_COMPANY_IDS.SLV: {
      return INSURER_CLAIM_TYPE.SLV_HS;
    }
    default: {
      return INSURER_CLAIM_TYPE.GENERAL;
    }
  }
};

export const getClaimType = (
  claim?: {
    insured_certificate: {
      policy: {
        insurer_company: {
          company_id: string;
        } | null;
      };
    };
  } | null,
) =>
  claim == null
    ? {
        fwdMr: false,
        healthCare: false,
        mbalHs: false,
        PTI: false,
        slvHs: false,
      }
    : {
        fwdMr: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.FWD,
        healthCare: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.GENERAL,
        mbalHs: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.MBAL,
        PTI: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.PTI,
        slvHs: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.SLV_HS,
      };
