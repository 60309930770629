import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";

import { createContext } from "react";

export const ClaimContext = createContext<ClaimContextType>({
  claimType: { fwdMr: false },
  loading: false,
  status: {},
} as ClaimContextType);
ClaimContext.displayName = "ClaimContext";
