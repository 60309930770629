import type {
  AssessmentClaimCaseDetail,
  BalanceDetail,
  BenefitLimit,
  PaidBenefitInput,
  PayableBenefitOutput,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/types";
import type { ClaimCaseDetails } from "sdk/gql/types";

import { LOCAL_STORAGE_KEYS, PLAN_BALANCE_TIME_UNITS } from "config/constants";
import { getZeroOrNumber } from "libs/hidash";
import utils from "libs/utils";
import { min, minBy } from "lodash";
import { PlanBalanceTypesEnum } from "sdk/gql/types";

const isDebuggingClaimAssessment = localStorage.getItem(LOCAL_STORAGE_KEYS.DEBUG_CLAIM_ASSESSMENT) === "true";

const calcPayableBenefit = ({
  claimCaseDetail,
  currencyPerTimeBalanceRemaining,
  currencyPerTimeBalanceRemainingBuffer,
  currencyPerYearBalanceRemaining,
  currencyPerYearBalanceRemainingBuffer,
  daysPerTimeBalanceRemaining,
  daysPerTimeBalanceRemainingBuffer,
  daysPerYearBalanceRemaining,
  daysPerYearBalanceRemainingBuffer,
  planBenefitId,
  requestTime,
  timeBenefitRemaining,
  timeBenefitRemainingBuffer,
  totalRequestAmount,
}: {
  claimCaseDetail?: AssessmentClaimCaseDetail;
  planBenefitId: string;
  requestTime: number;
  totalRequestAmount: number;
} & BenefitLimit) => {
  const maxTime = timeBenefitRemainingBuffer;
  const maxTotalPaidAmountPerYear = maxTime === 0 ? 0 : currencyPerYearBalanceRemainingBuffer;
  const maxPaidTime = maxTime === 0 ? 0 : min([daysPerYearBalanceRemainingBuffer, daysPerTimeBalanceRemainingBuffer]);
  const maxPaidAmountPerTime = maxTime === 0 ? 0 : currencyPerTimeBalanceRemainingBuffer;

  const paidTime = Math.min(Number(maxPaidTime ?? requestTime), Number(requestTime));
  const maxTotalPaidAmountByTime = maxPaidAmountPerTime == null ? null : maxPaidAmountPerTime * paidTime;
  const maxTotalPaidAmount = min([maxTotalPaidAmountPerYear, maxTotalPaidAmountByTime]) ?? 0;

  const totalPayableAmount = (() =>
    // if (claimCaseDetail?.requestPercentage != null && maxTotalPaidAmountPerYear != null) {
    //   const payableAmount =
    //     (claimCaseDetail.requestPercentage / 100) *
    //     (claimCaseDetail.balanceDetails?.find((bd) => bd.plan_balance_type?.value === PlanBalanceTypesEnum.CurrencyPerYear)?.balance_detail?.value ?? 1);
    //   return payableAmount > maxTotalPaidAmountPerYear ? maxTotalPaidAmountPerYear : payableAmount;
    // }
    totalRequestAmount > maxTotalPaidAmount && (maxTotalPaidAmountPerYear !== null || maxPaidAmountPerTime !== null) ? maxTotalPaidAmount : totalRequestAmount)();

  if (isDebuggingClaimAssessment) {
    // eslint-disable-next-line no-console
    console.table({
      currencyPerTimeBalanceRemaining,
      currencyPerTimeBalanceRemainingBuffer,
      currencyPerYearBalanceRemaining,
      currencyPerYearBalanceRemainingBuffer,
      daysPerTimeBalanceRemaining,
      daysPerTimeBalanceRemainingBuffer,
      daysPerYearBalanceRemaining,
      daysPerYearBalanceRemainingBuffer,
      maxPaidAmountPerTime,
      maxPaidTime,
      maxTotalPaidAmountByTime,
      maxTotalPaidAmountPerYear,
      paidTime,
      planBenefitId,
      requestTime,
      timeBenefitRemaining,
      timeBenefitRemainingBuffer,
      totalPayableAmount,
      totalRequestAmount,
    });
  }

  return {
    currencyPerTimeBalanceRemaining,
    currencyPerTimeBalanceRemainingBuffer,
    currencyPerYearBalanceRemaining,
    currencyPerYearBalanceRemainingBuffer,
    daysPerTimeBalanceRemaining,
    daysPerTimeBalanceRemainingBuffer,
    daysPerYearBalanceRemaining,
    daysPerYearBalanceRemainingBuffer,
    paidTime,
    planBenefitId,
    timeBenefitRemaining,
    timeBenefitRemainingBuffer,
    totalPayableAmount,
  };
};
const getLimitBenefit = ({
  amountPerDay,
  balanceDetails,
  claimCaseDetailId,
  policySumAssured,
}: {
  amountPerDay?: null | number;
  balanceDetails?: BalanceDetail[];
  claimCaseDetailId?: string;
  policySumAssured?: null | number;
}): BenefitLimit => {
  // Tính toán số tiền có thể chi trả cho claim case detail
  // Trường hợp: Loại Hạn mức là tiền/YCBT
  // các claim detail sẽ share hạn mức này
  // do đó không dùng field exclusiveBalanceRemainingBuffer(số tiền mà QL lợi có thể sử dụng) để tính toán.
  // mà dùng field balanceRemainBuffer(số tiền còn lại của hạn mức) để tính toán.
  const balanceBufferField: keyof BalanceDetail =
    balanceDetails?.flatMap((bd) => bd.currentBalanceUseBufferClaimDetails?.map((c) => c?.claimCaseDetail)).every((ccd) => ccd?.id !== claimCaseDetailId) === true
      ? "balanceRemainBuffer"
      : "exclusiveBalanceRemainingBuffer";

  const timeBenefits = balanceDetails?.filter((item) => [PlanBalanceTypesEnum.TimesPerCertificate, PlanBalanceTypesEnum.TimesPerYear].includes(item.typeValue ?? item.type));
  const timeBenefitRemaining = minBy(timeBenefits, (ba) => ba.balanceRemain)?.balanceRemain ?? null;
  const timeBenefitRemainingBuffer = minBy(timeBenefits, (ba) => ba[balanceBufferField])?.[balanceBufferField] ?? null;

  const daysPerYearBenefits = balanceDetails?.filter((item) => [PlanBalanceTypesEnum.CasesPerYear, PlanBalanceTypesEnum.DaysPerYear].includes(item.typeValue ?? item.type));
  const daysPerYearBalanceRemaining = minBy(daysPerYearBenefits, (ba) => ba.balanceRemain)?.balanceRemain ?? null;
  const daysPerYearBalanceRemainingBuffer = minBy(daysPerYearBenefits, (ba) => ba[balanceBufferField])?.[balanceBufferField] ?? null;

  const daysPerTimeBenefits = balanceDetails?.filter((item) =>
    [PlanBalanceTypesEnum.DaysPerMbHospitalConfinementTime, PlanBalanceTypesEnum.DaysPerTime].includes(item.typeValue ?? item.type),
  );
  const daysPerTimeBalanceRemaining = minBy(daysPerTimeBenefits, (ba) => ba.balanceRemain)?.balanceRemain ?? null;
  const daysPerTimeBalanceRemainingBuffer = minBy(daysPerTimeBenefits, (ba) => ba[balanceBufferField])?.[balanceBufferField] ?? null;
  const daysPerTimeBalanceRemainingByPolicySumAssured = (() => {
    if (policySumAssured == null || amountPerDay == null) return null;
    return minBy(
      balanceDetails?.filter((bd) => bd.type === PlanBalanceTypesEnum.SumAssuredPercentagePerPolicy).map((bd) => (bd.balanceRemain * policySumAssured) / (100 * amountPerDay)),
    );
  })();
  const daysPerTimeBalanceRemainingBufferByPolicySumAssured = (() => {
    if (policySumAssured == null || amountPerDay == null) return null;
    return minBy(
      balanceDetails
        ?.filter((bd) => bd.type === PlanBalanceTypesEnum.SumAssuredPercentagePerPolicy)
        .map((bd) => (bd.balanceRemainBuffer * policySumAssured) / (100 * amountPerDay)),
    );
  })();
  const daysPerTimeRemainingLimit = min([daysPerTimeBalanceRemaining, daysPerTimeBalanceRemainingByPolicySumAssured]);
  const daysPerTimeRemainingBufferLimit = min([daysPerTimeBalanceRemainingBuffer, daysPerTimeBalanceRemainingBufferByPolicySumAssured]);

  const currencyPerYearBenefits = balanceDetails?.filter((item) => [PlanBalanceTypesEnum.CurrencyPerYear].includes(item.typeValue ?? item.type));
  const currencyPerYearBalanceRemaining = minBy(currencyPerYearBenefits, (ba) => ba.balanceRemain)?.balanceRemain ?? null;
  const currencyPerYearBalanceRemainingBuffer = minBy(currencyPerYearBenefits, (ba) => ba[balanceBufferField])?.[balanceBufferField] ?? null;

  const currencyPerTimeTypes = new Set([PlanBalanceTypesEnum.CurrencyPerDay, PlanBalanceTypesEnum.CurrencyPerTime, PlanBalanceTypesEnum.CurrencyPerCase]);
  const currencyPerTimeBenefits = balanceDetails?.filter((item) => currencyPerTimeTypes.has(item.typeValue ?? item.type));
  const currencyPerTimeBalanceRemaining = minBy(currencyPerTimeBenefits, (ba) => ba.balanceRemain)?.balanceRemain ?? null;
  const currencyPerTimeBalanceRemainingBuffer = minBy(currencyPerTimeBenefits, (ba) => ba[balanceBufferField])?.[balanceBufferField] ?? null;

  return {
    currencyPerTimeBalanceRemaining: utils.getPositiveNumber(currencyPerTimeBalanceRemaining),
    currencyPerTimeBalanceRemainingBuffer: utils.getPositiveNumber(currencyPerTimeBalanceRemainingBuffer),
    currencyPerYearBalanceRemaining: utils.getPositiveNumber(currencyPerYearBalanceRemaining),
    currencyPerYearBalanceRemainingBuffer: utils.getPositiveNumber(currencyPerYearBalanceRemainingBuffer),
    daysPerTimeBalanceRemaining: utils.getPositiveNumber(daysPerTimeRemainingLimit ?? null),
    daysPerTimeBalanceRemainingBuffer: utils.getPositiveNumber(daysPerTimeRemainingBufferLimit ?? null),
    daysPerYearBalanceRemaining: utils.getPositiveNumber(daysPerYearBalanceRemaining),
    daysPerYearBalanceRemainingBuffer: utils.getPositiveNumber(daysPerYearBalanceRemainingBuffer),
    timeBenefitRemaining: utils.getPositiveNumber(timeBenefitRemaining),
    timeBenefitRemainingBuffer: utils.getPositiveNumber(timeBenefitRemainingBuffer),
  };
};

const getPayableBenefits = ({
  amountPerDay,
  claimCaseDetail,
  claimCaseDetailId,
  input: payableBenefitInput,
  policySumAssured,
}: {
  amountPerDay?: null | number;
  claimCaseDetail?: AssessmentClaimCaseDetail;
  claimCaseDetailId?: string;
  input: PaidBenefitInput[];
  policySumAssured?: null | number;
}): PayableBenefitOutput[] =>
  payableBenefitInput.map(({ approvedPaid = {}, balanceDetails, planBenefitId, requestTime, totalRequestAmount }) => {
    const limit = getLimitBenefit({ amountPerDay, balanceDetails, claimCaseDetailId, policySumAssured });
    const calculatedPayableBenefit = calcPayableBenefit({
      claimCaseDetail,
      planBenefitId,
      requestTime,
      totalRequestAmount,
      ...limit,
    });

    const { totalPaidAmount: approvedTotalPaidAmount = 0 } = approvedPaid;

    const totalPayableAmount = (() => {
      const { totalPayableAmount: payableAmount } = calculatedPayableBenefit;
      const newTotalPaidAmount = payableAmount + approvedTotalPaidAmount;
      if (newTotalPaidAmount > payableAmount) {
        return payableAmount;
      }
      return newTotalPaidAmount;
    })();

    return {
      ...calculatedPayableBenefit,
      paidTime: calculatedPayableBenefit.paidTime,
      totalPayableAmount,
    };
  });

const getValueWithCorrectUnit = ({
  claimCaseDetail,
  type,
}: {
  claimCaseDetail?: Pick<ClaimCaseDetails, "paid_amount" | "paid_time" | "total_paid_amount"> | null;
  type: PlanBalanceTypesEnum | string;
}) => {
  if (PLAN_BALANCE_TIME_UNITS.includes(type)) return claimCaseDetail?.paid_time ?? 0;
  if (["CurrencyPerDay"].includes(type)) return getZeroOrNumber(claimCaseDetail?.paid_amount);
  return claimCaseDetail?.total_paid_amount ?? 0;
};

export { getLimitBenefit, getPayableBenefits, getValueWithCorrectUnit };

export { type BalanceDetail } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/types";
