import { parse } from "date-fns";

export const HOLIDAYS_2022 = [
  "2022-01-01",
  "2022-01-03",
  "2022-01-31",
  "2022-02-01",
  "2022-02-02",
  "2022-02-03",
  "2022-02-04",
  "2022-02-05",
  "2022-04-10",
  "2022-04-11",
  "2022-04-30",
  "2022-05-01",
  "2022-05-02",
  "2022-05-03",
  "2022-09-01",
  "2022-09-02",
].map((d) => parse(d, "yyyy-MM-dd", new Date()));

export const HOLIDAYS_2023 = [
  "2023-01-01",
  "2023-01-02",
  "2023-01-20",
  "2023-01-23",
  "2023-01-24",
  "2023-01-25",
  "2023-01-26",
  "2023-04-29",
  "2023-04-30",
  "2023-05-01",
  "2023-05-02",
  "2023-05-03",
  "2023-09-01",
  "2023-09-02",
  "2023-09-04",
  "2023-12-25",
].map((d) => parse(d, "yyyy-MM-dd", new Date()));

export const HOLIDAYS_2024 = [
  "2024-01-01",
  "2024-02-08",
  "2024-02-09",
  "2024-02-10",
  "2024-02-11",
  "2024-02-12",
  "2024-02-13",
  "2024-02-14",
  "2024-04-18",
  "2024-04-30",
  "2024-05-01",
  "2024-08-31",
  "2024-09-01",
  "2024-09-02",
  "2024-09-03",
].map((d) => parse(d, "yyyy-MM-dd", new Date()));

export const HOLIDAYS_TO_EXCLUDE_FROM_TAT = [...HOLIDAYS_2022, ...HOLIDAYS_2023, ...HOLIDAYS_2024];
