import { Button, Popconfirm, Space } from "antd";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useStatusAvailability from "app/portal/screens/ClaimPortal/ClaimContext/useStatusAvailability";
import useSummary from "app/portal/screens/ClaimPortal/ClaimContext/useSummary";
import { FORMATTER } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import { sub } from "date-fns";
import utils from "libs/utils";
import { Info } from "lucide-react";
import { useMemo } from "react";
import { graphql } from "sdk/v2/graphql";

const InsertFigClaimCaseAssignmentAndUpsertFigClaimCaseDocument = graphql(`
  mutation InsertFigClaimCaseAssignmentAndUpsertFigClaimCase($object: fig_claim_cases_insert_input!, $assignmentLogObject: fig_claim_case_assignments_insert_input!) {
    insert_fig_claim_cases_one(object: $object, on_conflict: { constraint: claim_cases_pkey, update_columns: [assignee_user_id, assigner_user_id, updated_at, to_do, note] }) {
      id
      assignee_user_id
      assigner_user_id
      to_do
      updated_at
    }
    # eslint-disable-next-line @graphql-eslint/alphabetize
    insert_fig_claim_case_assignments_one(
      object: $assignmentLogObject
      on_conflict: { constraint: claim_case_assignments_pkey, update_columns: [assignee_user_id, assigner_user_id, to_do, claim_case_id, note] }
    ) {
      id
      assignee_user_id
      assigner_user_id
      claim_case_id
      to_do
      created_at
    }
  }
`);

const useNotice = ({ showSuggestion = true }: { showSuggestion?: boolean } | undefined = {}) => {
  const { hasRole, user } = useAuth();
  const {
    claim,
    claimType,
    status: { saveStatus, status },
    statusesMap,
  } = useClaim();
  const { items: summaryItems } = useSummary();
  const [insertFigClaimCaseAssignmentAndUpsertFigClaimCaseMutation] = usePMutation(InsertFigClaimCaseAssignmentAndUpsertFigClaimCaseDocument, {
    refetchQueries: ["ClaimDetail"],
  });
  const buildStatusAvailability = useStatusAvailability();

  const notice = useMemo(() => {
    if (claim == null) return null;

    if (claim.source === "POST" && claim.status !== status && status === "ApprovedWaitingDoc") {
      return "Khách nộp claim qua đường bưu điện";
    }
    if (claim.meta_v2?.assessor?.name == null) {
      return (
        <span>
          Claim chưa được assign{" "}
          <If condition={hasRole(["ClaimAssessor", "ClaimAssessorManager"])}>
            <Button
              onClick={() => {
                insertFigClaimCaseAssignmentAndUpsertFigClaimCaseMutation({
                  variables: {
                    assignmentLogObject: {
                      assignee_user_id: user.id,
                      assigner_user_id: user.id,
                      claim_case_id: claim.claim_case_id,
                      original_assignee_user_id: user.id,
                    },
                    object: {
                      assignee_user_id: user.id,
                      assigner_user_id: user.id,
                      id: claim.claim_case_id,
                      original_assignee_user_id: user.id,
                    },
                  },
                });
              }}
            >
              Assign cho bản thân
            </Button>
          </If>
        </span>
      );
    }
    if (
      claimType.healthCare &&
      claim.insured_certificate.dued_at &&
      claim.start_date != null &&
      sub(new Date(claim.insured_certificate.dued_at), { days: 30 }) < new Date(claim.start_date)
    ) {
      return `HĐ sẽ hết hiệu lực vào ngày ${utils.formatDate(
        claim.insured_certificate.dued_at,
        FORMATTER.DATE_FORMAT,
      )}. Kiểm tra số lượng thuốc so với thời gian hiệu lực hợp đồng để chi trả cho đúng.`;
    }
    if (claim.claim_notes.some((note) => note.content.includes(String(summaryItems.find((item) => item.key === "cert_issued_at")?.label))) === false) {
      return "Chưa có sign off tổng kết claim";
    }
    if (claim.status === "Paid") return null;
    // begins status suggestion
    if (claim.is_direct_billing === true) {
      const statusAvailability = buildStatusAvailability("ApprovedWaitingDoc");

      if (showSuggestion && statusAvailability.disabled === false && claim.status !== "ApprovedReceivedDoc") {
        return (
          <span>
            Gợi ý:{" "}
            <Popconfirm
              onConfirm={() =>
                saveStatus({
                  variables: {
                    claimId: claim.id,
                    input: { status: "ApprovedWaitingDoc" },
                  },
                })
              }
              title={`Chuyển sang trạng thái [${statusesMap.ApprovedWaitingDoc}]?`}
            >
              <Button>{statusesMap.ApprovedWaitingDoc}</Button>
            </Popconfirm>
          </span>
        );
      }
    }
    let statusAvailability = buildStatusAvailability("RECEIVED_DOCS");

    if (showSuggestion && statusAvailability.disabled === false && claim.status === "ApprovedWaitingDoc") {
      return (
        <span>
          Gợi ý:{" "}
          <Popconfirm
            onConfirm={() =>
              saveStatus({
                variables: {
                  claimId: claim.id,
                  input: { status: "RECEIVED_DOCS" },
                },
              })
            }
            title={`Chuyển sang trạng thái [${statusesMap.RECEIVED_DOCS}]?`}
          >
            <Button>{statusesMap.RECEIVED_DOCS}</Button>
          </Popconfirm>
        </span>
      );
    }
    statusAvailability = buildStatusAvailability("ApprovedReceivedDoc");

    if (showSuggestion && statusAvailability.disabled === false && claim.status === "RECEIVED_DOCS") {
      return (
        <span>
          Gợi ý:{" "}
          <Popconfirm
            onConfirm={() =>
              saveStatus({
                variables: {
                  claimId: claim.id,
                  input: { status: "ApprovedReceivedDoc" },
                },
              })
            }
            title={`Chuyển sang trạng thái [${statusesMap.ApprovedReceivedDoc}]?`}
          >
            <Button>{statusesMap.ApprovedReceivedDoc}</Button>
          </Popconfirm>
        </span>
      );
    }

    statusAvailability = buildStatusAvailability("Approved");
    if (showSuggestion && statusAvailability.disabled === false && claim.claim_case_payment?.estimated_paid_amount === claim.claim_case_payment?.requested_amount) {
      return (
        <span>
          Gợi ý:{" "}
          <Popconfirm
            onConfirm={() =>
              saveStatus({
                variables: {
                  claimId: claim.id,
                  input: { status: "Approved" },
                },
              })
            }
            title={`Chuyển sang trạng thái [${statusesMap.Approved}]?`}
          >
            <Button type="primary">{statusesMap.Approved}</Button>
          </Popconfirm>
        </span>
      );
    }

    return null;
  }, [
    claim,
    status,
    claimType.healthCare,
    buildStatusAvailability,
    showSuggestion,
    hasRole,
    insertFigClaimCaseAssignmentAndUpsertFigClaimCaseMutation,
    user.id,
    summaryItems,
    statusesMap,
    saveStatus,
  ]);

  return {
    notice: (
      <If condition={notice != null}>
        <Space style={{ color: "orangered" }}>
          <Info size={14} />
          <div>{notice}</div>
        </Space>
      </If>
    ),
  };
};

export default useNotice;
