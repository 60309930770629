import type { TableColumnsType } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import type { SlvCaseInvestigatorReasonsEnum, SlvCaseReUwReasonsEnum } from "sdk/gql/types";
import type { ResultOf } from "sdk/v2/graphql";

import { Button, Input, message, Modal, notification, Popconfirm, Popover, Select, Space, Tag, Tooltip, Typography, Upload } from "antd";
import Avatar from "app/common/components/Avatar";
import Content from "app/common/components/Content";
import ExternalLink from "app/common/components/ExternalLink";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import {
  INSERT_PENDING_CODE_MUTATION,
  REMOVE_CLAIM_PENDING_SAMPLE_DOCUMENT_MUTATION,
  UPDATE_PENDING_CODE_MUTATION,
  UPDATE_SLV_CLAIM_CASE_GROUP_MUTATION,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/PendingForm/graphql/mutations";
import {
  CLAIM_PENDING_CODES,
  PENDING_CODES_QUERY,
  SLV_CASE_REASONS_QUERY,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/PendingForm/graphql/queries";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import BPromise from "bluebird";
import classNames from "classnames";
import { FORMATTER, INVESTIGATING_PENDING_CODE, TYPE } from "config/constants";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { isToday } from "date-fns";
import useBuildClaimLinksFromText from "hooks/useBuildClaimLinksFromText";
import useMetadata from "hooks/useMetadata";
import useUploadFile from "hooks/useUploadFile";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import { utils } from "libs/utils";
import { debounce, find, isEmpty, orderBy, partition } from "lodash";
import { File, Info, Paperclip, Trash2, UploadIcon, X } from "lucide-react";
import { useState } from "react";
import { ClaimCaseStatusesEnum, ClaimPendingCodeStatusesEnum, DocTypeEnum, MetadataTypesEnum } from "sdk/gql/types";

import styles from "./PendingForm.module.less";
import UpdatePendingCodeDocumentForm from "./UploadPendingDocumentForm";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const PendingForm = () => {
  const {
    claim,
    claimCaseRoutePath,
    claimType,
    permissions: { canAssess },
    status: { setStatus },
  } = useClaim();
  const { data } = usePQuery(CLAIM_PENDING_CODES, claim?.id == null ? { skip: true } : { variables: { where: { claim_case_id: { _eq: claim.id } } } });

  const [currentPendingId, setCurrentPendingId] = useState<string>("");
  const [currentPendingCode, setCurrentPendingCode] = useState<string>("");
  const [currentExtendedTexts, setCurrentExtendedTexts] = useState<string>("");
  const [slvCaseInvestigatorReason, setSlvCaseInvestigatorReason] = useState<null | SlvCaseInvestigatorReasonsEnum>(null);
  const [slvCaseReUwReason, setSlvCaseReUwReason] = useState<null | SlvCaseReUwReasonsEnum>(null);
  const [isShowModal, showModal] = useState<boolean>(false);
  const [pendingAttachments, setPendingAttachments] = useState<UploadFile<any>[]>([]);
  const { data: medicalExaminationType } = useMetadata({
    types: [MetadataTypesEnum.MedicalExaminationType],
  });
  const { data: pendingCodeData } = usePQuery(PENDING_CODES_QUERY);
  const { data: slvCaseReasons } = usePQuery(SLV_CASE_REASONS_QUERY, {
    skip: claimType.slvHs === false,
  });
  const [updateSlvClaimCaseGroup] = usePMutation(UPDATE_SLV_CLAIM_CASE_GROUP_MUTATION, {
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });

  const pendingCodes =
    claimType.fwdMr === true
      ? pendingCodeData?.fwd_pending_codes.map((item) => ({
          code: item.code,
          key: item.code,
          label: `${item.code} - ${item.name}`,
          name: item.name,
          value: item.ppy_pending_code,
        }))
      : pendingCodeData?.pending_codes.map((item) => ({
          code: item.code,
          key: item.code,
          label: `${item.code} - ${item.description}`,
          name: item.description,
          value: item.code,
        }));

  const [updatePendingCodeMutation, { loading: updatingPendingCode }] = usePMutation(UPDATE_PENDING_CODE_MUTATION, {
    onCompleted: () => message.success("Cập nhật thành công"),
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_PENDING_CODES]),
  });
  const [removeClaimPendingSampleFile] = usePMutation(REMOVE_CLAIM_PENDING_SAMPLE_DOCUMENT_MUTATION, {
    onCompleted: () => message.success("Xoá mẫu bổ sung thành công"),
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_PENDING_CODES]),
  });
  const [insertPendingCodeMutation] = usePMutation(INSERT_PENDING_CODE_MUTATION, {
    onCompleted: () => message.success("Tạo thành công"),
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_PENDING_CODES]),
  });
  const [uploadPendingSampleDocument, { loading: uploadingClaimDocument }] = useUploadFile({ refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]) });

  const handleAddPendingCode = async () => {
    if (
      claimType.slvHs === true &&
      ((currentPendingCode === "P17" && slvCaseReUwReason == null) || (["P11", "P19"].includes(currentPendingCode) && slvCaseInvestigatorReason == null))
    ) {
      notification.error({
        message: "Vui lòng chọn lý do pending",
      });
      return;
    }

    let targetClaimPendingCodeId: null | string = currentPendingId;
    if (isEmpty(targetClaimPendingCodeId)) {
      const { data: insertPendingCodeMutationData } = await insertPendingCodeMutation({
        variables: {
          input: {
            claim_case_id: claim?.claim_case_id,
            code: currentPendingCode,
            extended_text: currentExtendedTexts.trim(),
            status: "O",
          },
        },
      });
      targetClaimPendingCodeId = insertPendingCodeMutationData?.insert_claim_pending_codes_one?.claim_pending_code_id ?? null;
    } else {
      updatePendingCodeMutation({
        variables: {
          id: targetClaimPendingCodeId,
          input: {
            extended_text: currentExtendedTexts.trim(),
          },
        },
      });
    }
    if (pendingAttachments.length > 0 && claim?.id != null && targetClaimPendingCodeId != null) {
      await BPromise.mapSeries(pendingAttachments, async (uploadedFile) => {
        if (uploadedFile.originFileObj == null) {
          return;
        }
        await uploadPendingSampleDocument({
          docType: DocTypeEnum.ClaimPendingSampleFile,
          file: uploadedFile.originFileObj,
          fileType: "CLAIM",
          id: claim.id,
          keepOriginalFileName: true,
          mutationVariables: {
            claim_document: {
              data: {
                claim_case_id: claim.id,
                claim_pending_code_id: targetClaimPendingCodeId,
                is_supplemented: false,
                source: "Assessments",
                type: "CLAIM_PENDING_SAMPLE_FILE",
              },
            },
          },
        });
      });
    }
    const investigating =
      data?.claim_pending_codes.filter((pendingCode) => pendingCode.removed_at == null).some((pendingCode) => pendingCode.code !== INVESTIGATING_PENDING_CODE) === false &&
      currentPendingCode === INVESTIGATING_PENDING_CODE;

    const nextStatus = investigating ? ClaimCaseStatusesEnum.Investigating : "Pending";
    setStatus(nextStatus);
    setCurrentExtendedTexts("");
    setCurrentPendingCode("");
    setCurrentPendingId("");
    showModal(false);
    setPendingAttachments([]);

    if (claimType.slvHs && claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group != null) {
      await updateSlvClaimCaseGroup({
        variables: {
          input: {
            case_reason_investigator: slvCaseInvestigatorReason,
            case_reason_re_rw: slvCaseReUwReason,
          },
          where: {
            claim_case_group_id: {
              _eq: claim.claim_case_same_event_groups_claim_case.claim_case_group_id,
            },
          },
        },
      });
    }
  };

  const handleEdit = (record) => {
    setCurrentPendingCode(record.code);
    setCurrentExtendedTexts(record.extended_text);
    setCurrentPendingId(record.id);
    showModal(true);
  };

  const handleCancel = () => {
    showModal(false);
    setCurrentPendingId("");
    setCurrentPendingCode("");
    setCurrentExtendedTexts("");
    setPendingAttachments([]);
  };

  type PendingRecord = ResultOf<typeof CLAIM_PENDING_CODES>["claim_pending_codes"][number];

  const buildClaimLinksFromText = useBuildClaimLinksFromText({
    claimCaseRoutePath,
    texts: data?.claim_pending_codes.map((p) => p.extended_text),
  });

  const schema: TableColumnsType<PendingRecord> = [
    {
      render: (record) => (
        <Space>
          <Avatar src={record.created_by_user?.avatar_url} text={record.created_by_user?.name} />
          {record.created_by_user?.name}
        </Space>
      ),
      title: "Người tạo",
      width: 100,
    },
    {
      dataIndex: "code",
      render: (text: string) => <Tag color="geekblue">{find(pendingCodes, (p) => p.value === text)?.code}</Tag>,
      title: "Mã",
      width: 80,
    },
    {
      dataIndex: "extended_text",
      ellipsis: true,
      render: (text, record) => (
        <Space direction="vertical">
          <span style={{ whiteSpace: "pre-wrap" }}>{buildClaimLinksFromText(text)}</span>
          <If
            condition={
              record.code === "P17" &&
              claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.case_reason_re_uw_type?.comment != null
            }
          >
            Lý do: {claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.case_reason_re_uw_type?.comment}
          </If>
          <If
            condition={
              ["P11", "P19"].includes(record.code) &&
              claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.case_reason_investigator_type?.comment != null
            }
          >
            Lý do: {claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.case_reason_investigator_type?.comment}
          </If>
        </Space>
      ),
      title: "Nội dung",
      width: 300,
    },
    {
      dataIndex: "created_at",
      render: (text: null | string) => (text == null ? "" : utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT)),
      title: "Thời gian tạo",
      width: 120,
    },
    {
      dataIndex: "started_at",
      render: (text: null | string) => (text == null ? "" : utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)),
      title: "Thời gian TAT",
      width: 120,
    },
    {
      dataIndex: "removed_at",
      render: (text: null | string) => (text == null ? "" : utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC)),
      title: "Thời gian gỡ",
      width: 120,
    },

    {
      dataIndex: "status",
      render: (text) => TYPE.VN_PENDING_STATUS_NAME[text],
      title: "Trạng thái",
      width: 120,
    },
    {
      fixed: "right",
      key: "action",
      render: (_, record) => (
        <div onClick={(e) => e.stopPropagation()}>
          {isToday(new Date(record.created_at)) ? (
            <Button disabled={!canAssess} onClick={() => handleEdit(record)} type="link">
              Edit
            </Button>
          ) : null}

          {record.status in ClaimPendingCodeStatusesEnum && (record.status === "O" || record.removed_at != null) ? (
            <Popover
              content={
                <div className={styles.actionPopover}>
                  <Button
                    disabled={record.status === ClaimPendingCodeStatusesEnum.R}
                    onClick={() => {
                      updatePendingCodeMutation({
                        variables: {
                          id: record.id,
                          input: {
                            removed_at: "now()",
                            status: ClaimPendingCodeStatusesEnum.R,
                          },
                        },
                      });
                    }}
                    type="link"
                  >
                    Đã nộp
                  </Button>
                  <Button
                    disabled={record.status === ClaimPendingCodeStatusesEnum.W}
                    onClick={() => {
                      updatePendingCodeMutation({
                        variables: {
                          id: record.id,
                          input: {
                            removed_at: "now()",
                            status: ClaimPendingCodeStatusesEnum.W,
                          },
                        },
                      });
                    }}
                    type="link"
                  >
                    Miễn trừ
                  </Button>
                  <If condition={!claimType.fwdMr}>
                    <Button
                      disabled={record.status === "MISSING_DOCUMENT"}
                      onClick={() => {
                        updatePendingCodeMutation({
                          variables: {
                            id: record.id,
                            input: {
                              removed_at: "now()",
                              status: "MISSING_DOCUMENT",
                            },
                          },
                        });
                      }}
                      type="link"
                    >
                      Thiếu chứng từ
                    </Button>
                  </If>
                  <Button
                    disabled={record.status === ClaimPendingCodeStatusesEnum.S}
                    onClick={() => {
                      updatePendingCodeMutation({
                        variables: {
                          id: record.id,
                          input: {
                            removed_at: "now()",
                            status: ClaimPendingCodeStatusesEnum.S,
                          },
                        },
                      });
                    }}
                    type="link"
                  >
                    Hết hạn
                  </Button>
                </div>
              }
              placement="top"
              trigger="click"
            >
              <Button className={styles.removePending} disabled={!canAssess} size="large" type="link">
                Gỡ
              </Button>
            </Popover>
          ) : null}
        </div>
      ),
      title: "Thao tác",
      width: 120,
    },
  ];

  const expandedRowRender = (record: PendingRecord) => {
    const uploadedPendingAttachments = record.claim_pending_code_documents;
    const isPendingCodeCreatedSameDay = isToday(new Date(record.created_at));
    const [sampleFiles, updatedFiles] = partition(uploadedPendingAttachments, (item) => item.claim_document_type.value === "CLAIM_PENDING_SAMPLE_FILE");
    return (
      <Space direction="vertical" size={16}>
        <If condition={sampleFiles.length > 0}>
          <Space align="start" size={16}>
            <div className={classNames(styles.expandedIconCell)}>
              <Text>Mẫu đính kèm: </Text>
            </div>
            <Space wrap>
              {orderBy(sampleFiles, "created_at", "desc").map((document) => {
                if (document.file == null) {
                  return null;
                }
                const fileUrl = document.file.url;
                const fileId = document.file.file_id;
                if (fileUrl == null) {
                  return <Text key={fileId}>{document.file.name}</Text>;
                }

                return (
                  <div key={fileId}>
                    <Tag
                      closable={canAssess && isPendingCodeCreatedSameDay}
                      closeIcon={
                        <Popconfirm
                          onConfirm={() =>
                            removeClaimPendingSampleFile({
                              variables: {
                                claimDocumentId: document.claim_document_id,
                              },
                            })
                          }
                          title="Bạn có chắc chắn muốn xóa file này?"
                        >
                          <X size={16} />
                        </Popconfirm>
                      }
                      icon={<Paperclip size={14} style={{ marginRight: 3 }} />}
                      onClose={(e) => e.preventDefault()}
                    >
                      <ExternalLink className={styles.fileName} target="_blank" to={fileUrl}>
                        <Text className={styles.fileItemContainer}>{document.claim_document_type.comment}</Text>
                      </ExternalLink>
                    </Tag>
                  </div>
                );
              })}
            </Space>
          </Space>
        </If>
        <If condition={updatedFiles.length > 0}>
          <Space align="start" size={16}>
            <div className={styles.expandedIconCell}>
              <Space direction="vertical" size={0}>
                <Text>KH bổ sung: </Text>
                <Text className={styles.docUpdatedTime}>{utils.formatDate(updatedFiles[0]?.created_at)}</Text>
              </Space>
            </div>
            <Space wrap>
              {orderBy(updatedFiles, "created_at", "desc").map((document) => {
                if (document.file == null) {
                  return null;
                }
                const fileUrl = document.file.url;
                const fileId = document.file.file_id;
                if (fileUrl == null) {
                  return <Text key={fileId}>{document.file.name}</Text>;
                }

                return (
                  <div key={fileId}>
                    <Tag color="blue" icon={<File size={12} style={{ marginRight: 3 }} />}>
                      <ExternalLink target="_blank" to={`${claimCaseRoutePath?.replace(":claimCaseId", claim?.id ?? "")}/gallery?document_id=${document.claim_document_id}`}>
                        <Text className={styles.fileName}>{document.claim_document_type.comment}</Text>
                      </ExternalLink>
                    </Tag>
                  </div>
                );
              })}
            </Space>
          </Space>
        </If>
        <UpdatePendingCodeDocumentForm claimPendingItem={record}>
          <Button icon={<UploadIcon size={14} />} size="small" type="dashed">
            Tải lên bổ sung
          </Button>
        </UpdatePendingCodeDocumentForm>
      </Space>
    );
  };

  const rowHasPendingAttachments = data?.claim_pending_codes.some((pendingItem) => pendingItem.claim_pending_code_documents.length > 0);
  return (
    <Content title="Pending">
      <div>
        <div className={styles.pendingSelectContainer}>
          <Select
            className={styles.pendingSelect}
            disabled={!canAssess}
            mode="multiple"
            onSelect={(code) => {
              if (claim?.claim_pending_codes.some((pendingCode) => pendingCode.code === code) === true && claimType.fwdMr) {
                Modal.confirm({
                  content: <div>Bạn có muốn add thêm 1 code {code}?</div>,
                  okText: "Add thêm",
                  onOk: () => {
                    setCurrentPendingCode(code);
                    showModal(true);
                  },
                  title: `Pending với code ${code} đã tồn tại.`,
                });
              } else {
                setCurrentPendingCode(code);
                showModal(true);
              }
            }}
            options={pendingCodes}
            placeholder="Chọn Pending..."
            size="large"
            value={[]}
          />
        </div>

        <div className={styles.pendingContainer}>
          <TableList
            data={data?.claim_pending_codes}
            expandable={{
              defaultExpandAllRows: true,
              expandedRowClassName: () => styles.expandedRow,
              expandedRowRender,
              expandIcon: () => null,
              rowExpandable: () => true,
              showExpandColumn: rowHasPendingAttachments,
            }}
            hideWhenEmpty
            nativeScroll={{ x: true }}
            schema={schema}
            title="Danh sách pending"
          />
        </div>
      </div>
      <Modal
        className={styles.modelContainer}
        confirmLoading={updatingPendingCode || uploadingClaimDocument}
        onCancel={handleCancel}
        onOk={debounce(handleAddPendingCode, 1000)}
        open={isShowModal}
        style={{ minWidth: 600 }}
        title={`[${find(pendingCodes, (p) => p.value === currentPendingCode)?.code}] ${find(pendingCodes, (p) => p.value === currentPendingCode)?.name}`}
      >
        <Space direction="vertical">
          <label>Nội dung hướng dẫn cho NĐBH</label>
          {currentPendingCode === "P04" && (
            <Select
              onChange={setCurrentExtendedTexts}
              style={{
                float: "right",
                marginBottom: 16,
                textAlign: "center",
                width: "60%",
              }}
              value={currentExtendedTexts}
            >
              {medicalExaminationType?.metadata.map((item) => (
                <Option key={item.title} value={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          )}
          <TextArea
            autoSize={{ minRows: 4 }}
            onChange={(e) => {
              e.preventDefault();
              setCurrentExtendedTexts(e.target.value);
            }}
            placeholder="Extended Text..."
            size="large"
            title="Extended Text"
            value={currentExtendedTexts}
          />
          <Space>
            <Upload
              accept={`application/pdf,
                    image/*,
                    application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`}
              beforeUpload={() => false}
              fileList={pendingAttachments}
              maxCount={5}
              multiple
              onChange={(info) => setPendingAttachments(info.fileList)}
              showUploadList={false}
            >
              <Button icon={<UploadIcon size={14} />} loading={updatingPendingCode || uploadingClaimDocument}>
                Đính kèm tệp
              </Button>
            </Upload>
            <Tooltip title="Tệp tin đính kèm sẽ được gửi trong thư YCBS (dung lượng file <=8MB, tối đa 5 files)">
              <Info size={14} />
            </Tooltip>
          </Space>
          <Space direction="vertical">
            {pendingAttachments.map((pendingAttachment) => (
              <div key={pendingAttachment.uid}>
                <Space align="center">
                  <Text>{pendingAttachment.name}</Text>
                  <Trash2
                    className="cursor-pointer"
                    color="#ff4d4f"
                    onClick={() => {
                      const remainFiles = pendingAttachments.filter((file) => pendingAttachment.uid !== file.uid);
                      setPendingAttachments(remainFiles);
                    }}
                    size={14}
                  />
                </Space>
              </div>
            ))}
          </Space>
        </Space>

        <If condition={claimType.slvHs}>
          <If condition={currentPendingCode === "P17"}>
            <Typography.Text strong>[SLV] Lý do chọn pending code P17 </Typography.Text>
            <Select onSelect={(value) => setSlvCaseReUwReason(value)} placeholder="Lý do pending P17">
              {slvCaseReasons?.slv_case_re_uw_reasons.map(({ comment, value }) => (
                <Select.Option key={value} value={value}>
                  {comment}
                </Select.Option>
              ))}
            </Select>
          </If>
          <If condition={["P11", "P19"].includes(currentPendingCode)}>
            <Typography.Text strong>[SLV] Lý do chọn pending code {currentPendingCode} </Typography.Text>
            <Select
              onSelect={(value) => setSlvCaseInvestigatorReason(value)}
              placeholder={`Lý do pending ${currentPendingCode}`}
              style={{
                width: 200,
              }}
            >
              {slvCaseReasons?.slv_case_investigator_reasons.map(({ comment, value }) => (
                <Select.Option key={value} value={value}>
                  {comment}
                </Select.Option>
              ))}
            </Select>
          </If>
        </If>
      </Modal>
    </Content>
  );
};

export default PendingForm;
