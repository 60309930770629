import { graphql } from "sdk/v2/graphql";

export const GET_PLAN_QUERY = graphql(
  `
    query PlansForClaimCase($planIds: [uuid!]!, $insuredBenefitType: insured_benefit_types_enum) {
      plans(where: { plan_id: { _in: $planIds } }) {
        id
        name
        copay_mechanism
        plan_id
        plan_insured_benefits(
          where: { deleted_at: { _is_null: true }, plan_balances: {}, insured_benefit: { type: { _eq: $insuredBenefitType } } }
          order_by: { insured_benefit: { name: asc } }
        ) {
          id
          apply_copay
          insured_benefit_id
          is_direct_billing
          insured_benefit {
            id
            name
            code
            type
            insured_benefit_type {
              id: value
              comment
              value
            }
          }
          plan_balance_benefits {
            id
            plan_balance_id
            plan_balance {
              id
              name
            }
          }
        }
        plan_remarks(order_by: { created_at: desc }) {
          id
          description
          plan_id
          created_at
        }
      }
    }
  `,
  [],
);
