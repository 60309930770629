import type { JSX } from "react";

import NotFoundScreen from "./common/screens/404NotFoundScreen";
import Portal from "./portal";

export const ROUTES_PATH = {
  LOGIN_SCREEN: "/login",
  NOTFOUND: "/404",
  PORTAL: "/portal/*",
};

export interface IRoute {
  comp: (T) => JSX.Element;
  exact?: boolean;
  path: string;
  requireAuth?: boolean;
  roles?: string[];
  title: string;
}

const routes: IRoute[] = [
  {
    comp: Portal,
    path: ROUTES_PATH.PORTAL,
    requireAuth: true,
    roles: ["MedicalProviderEmployee", "ClaimAssessor", "ClaimAssessorManager"],
    title: "Dashboard",
  },
  {
    comp: Portal,
    path: "/*",
    requireAuth: true,
    roles: ["MedicalProviderEmployee", "ClaimAssessor", "ClaimAssessorManager"],
    title: "Dashboard",
  },
  {
    comp: NotFoundScreen,
    path: ROUTES_PATH.NOTFOUND,
    requireAuth: false,
    title: "404 Not Found",
  },
];

export default routes;
