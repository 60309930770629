import { Space } from "antd";
import { CLAIM_CASE_PATH } from "app/portal/config/paths";
import { BookMarked, DollarSign, FileQuestion, GanttChart, Images, Mails, Pointer, SearchCheck, Wrench } from "lucide-react";

const topbar = [
  {
    isLock: false,
    key: "claim",
    path: CLAIM_CASE_PATH.CLAIM_CASE_INFO,
    subPath: "/info",
    title: (
      <Space size={3}>
        <SearchCheck size={12} />
        Thẩm định
      </Space>
    ),
  },
  {
    isLock: false,
    key: "correspondence",
    path: CLAIM_CASE_PATH.CORRESPONDENCE,
    subPath: "/correspondence",
    title: (
      <Space size={3}>
        <Mails size={12} />
        Correspondences
      </Space>
    ),
  },
  {
    isLock: false,
    key: "checkCase",
    path: CLAIM_CASE_PATH.CHECK_CASE,
    subPath: "/presentCase",
    title: (
      <Space size={3}>
        <FileQuestion size={12} />
        Trình case
      </Space>
    ),
  },

  {
    isLock: false,
    key: "gallery",
    path: CLAIM_CASE_PATH.GALLERY,
    subPath: "/gallery",
    title: (
      <Space size={3}>
        <Images size={12} />
        Hình ảnh/chứng từ
      </Space>
    ),
  },
  {
    isLock: false,
    key: "payment",
    path: CLAIM_CASE_PATH.PAYMENT_CLAIM,
    subPath: "/payment",
    title: (
      <Space size={3}>
        <DollarSign size={12} />
        Thanh toán
      </Space>
    ),
  },
  {
    isLock: false,
    key: "history",
    path: CLAIM_CASE_PATH.ACTION_LOGS,
    subPath: "/logs",
    title: (
      <Space size={3}>
        <GanttChart size={12} />
        Lịch sử hoạt động
      </Space>
    ),
  },
  {
    isLock: false,
    key: "original",
    path: CLAIM_CASE_PATH.ORIGINAL,
    subPath: "/original",
    title: (
      <Space size={3}>
        <BookMarked size={12} />
        Claim gốc
      </Space>
    ),
  },
  {
    isLock: false,
    key: "tools",
    path: CLAIM_CASE_PATH.TOOLS,
    subPath: "/tools",
    title: (
      <Space size={3}>
        <Wrench size={12} />
        Tools
      </Space>
    ),
  },
  {
    isLock: false,
    key: "assign",
    path: CLAIM_CASE_PATH.ASSIGN,
    subPath: "/assign",
    title: (
      <Space size={3}>
        <Pointer size={12} />
        Assignment
      </Space>
    ),
  },
];

export default topbar;
