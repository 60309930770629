import type { ComponentRef } from "react";

import { Badge, Col, Divider, Form, Menu, Radio, Row, Steps, Typography } from "antd";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import { PORTAL_PATH } from "app/portal/config/paths";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { CLAIM_DECISION_STATUSES, FORMATTER } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { utils } from "libs/utils";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import type { AssessmentForm } from "../types";

import Copay from "../../Copay";
import CopayPercent from "../../Copay/CopayPercent";
import styles from "./ClaimBenefitVer3.module.css";
import ClaimCaseDetails from "./ClaimCaseDetails";
import CustomAmount from "./components/CustomAmount";
import { buildDateRangeTitle } from "./helpers";
import Summary from "./Summary";

const ClaimBenefitVer3 = ({ claimCaseId }: { claimCaseId: string }) => {
  const [selectedDateRangeKey, setSelectedDateRangeKey] = useState<string>();
  const [assessingPlanBalanceId, setAssessingPlanBalanceId] = useState<string>();
  const [_, setChangedCopayPercent] = useState<number>();

  const {
    claim,
    claimDateRanges,
    claimType,
    copayAndBonus,
    isDetailFormFilled,
    permissions: { canAssess },
  } = useClaim();

  const [assessmentForm] = Form.useForm<AssessmentForm>();

  const { data: claimBenefitDetailData } = usePQuery(ClaimBenefitDetailsDocument, {
    skip: !isDetailFormFilled && !CLAIM_DECISION_STATUSES.includes(claim?.status ?? ""),
    variables: { claimId: claimCaseId },
  });
  const claimBenefitDetails = claimBenefitDetailData?.claimBenefitDetails;

  const assessablePlanBalances = claimDateRanges?.claim_date_ranges.ranges[0]?.plan?.plan_balances.filter(({ plan_balance_benefits }) =>
    plan_balance_benefits.some(({ plan_insured_benefit }) => plan_insured_benefit.insured_benefit.type === claim?.insured_benefit_type.value),
  );

  const showAssessmentByBalanceId = claimType.fwdMr === false || assessingPlanBalanceId != null;

  const planStepItems =
    claimDateRanges?.claim_date_ranges.ranges.map((dateRange) => ({
      key: dateRange.key,
      subTitle: utils.formatDate(dateRange.startDate, FORMATTER.DATE_FORMAT),
      title: buildDateRangeTitle(dateRange),
    })) ?? [];

  useEffect(() => {
    if (selectedDateRangeKey == null && claimDateRanges?.claim_date_ranges.ranges.length === 1) {
      setSelectedDateRangeKey(claimDateRanges.claim_date_ranges.ranges[0]?.key);
    }
  }, [claimDateRanges?.claim_date_ranges.ranges, selectedDateRangeKey]);

  useEffect(() => {
    if (claim?.assessed_plan_balance_id != null) setAssessingPlanBalanceId(claim.assessed_plan_balance_id);
  }, [claim?.assessed_plan_balance_id]);

  type AssessmentFormRefType = ComponentRef<typeof ClaimCaseDetails>;
  const assessmentFormRef = useRef<AssessmentFormRefType>(null);
  useEffect(() => {
    copayAndBonus.registerBeforeSaveCopayFn((copayPercent) => setChangedCopayPercent(copayPercent));
    const applyCopay = (copayPercentage) =>
      assessmentFormRef.current?.saveClaimCaseDetails({
        copayPercentage,
      });
    copayAndBonus.registerAfterSaveCopayFn(applyCopay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimCaseDetailsFormItem = Form.useWatch("claimCaseDetails", assessmentForm);

  useEffect(() => {
    // set default selected date range key on page loaded
    const defaultKeyWithDetails = claimDateRanges?.claim_date_ranges.ranges.find(
      (dateRanges) =>
        (claimBenefitDetails?.claimCaseDetails
          .map((ccd) => ccd.key)
          .filter(Boolean)
          .filter((planInsuredBenefitKey) => planInsuredBenefitKey.startsWith(dateRanges.key)).length ?? 0) > 0,
    )?.key;

    if (selectedDateRangeKey == null && defaultKeyWithDetails != null) {
      setSelectedDateRangeKey(defaultKeyWithDetails);
    }
  }, [claimDateRanges?.claim_date_ranges.ranges, claimBenefitDetails?.claimCaseDetails, selectedDateRangeKey]);

  return (
    <Content title="Điền quyền lợi">
      <Row className="p-1" gutter={[16, 16]}>
        {/* Copay */}
        <Col span={24}>
          <Copay />
        </Col>

        {/* Copay Percent */}
        <If condition={copayAndBonus.doesPlanHaveCopay}>
          <Col span={24}>
            <CopayPercent />
          </Col>
        </If>

        {/* Custom Amount */}
        <If condition={claimType.mbalHs === true}>
          <Col span={24}>
            <CustomAmount
              onChangeCustomAmount={() => {
                assessmentFormRef.current?.saveClaimCaseDetails();
              }}
            />
          </Col>
          <If condition={claim?.claim_case_payment?.applied_premium_debt_amount != null}>
            <Col span={24}>
              <Typography.Text>Số tiền nợ phí áp dụng: {utils.formatCurrency(claim?.claim_case_payment?.applied_premium_debt_amount)}</Typography.Text>
            </Col>
          </If>
        </If>

        {/* Remarks */}
        <Col span={24}>
          <If condition={claim?.insured_certificate.policy.policy_setting?.assessment_remarks != null}>
            <div className={styles.remarks}>
              <b>Chú ý:</b>
              <p>{claim?.insured_certificate.policy.policy_setting?.assessment_remarks}</p>
            </div>
          </If>
        </Col>

        {/* Plan Steps */}
        <Col span={24}>
          <Steps current={planStepItems.findIndex((p) => p.key === selectedDateRangeKey)} items={planStepItems} size="small" />
        </Col>

        {/* Assessable Plan Balance */}
        <Col span={24}>
          {claimType.fwdMr === true ? (
            <div className={styles.selectBenefitContainer}>
              {assessablePlanBalances?.length === 0 ? (
                <Link to={PORTAL_PATH.PLAN_DETAIL.replace(":planId", claim?.insured_certificate.policy_plan?.plan_id ?? "")}>Chưa set up hạn mức có thể thẩm định</Link>
              ) : (
                <>
                  <div>Loại yêu cầu:</div>
                  <Radio.Group
                    disabled={claim?.assessed_plan_balance_id != null || !canAssess}
                    onChange={(e) => setAssessingPlanBalanceId(e.target.value)}
                    size="large"
                    value={assessingPlanBalanceId}
                  >
                    {assessablePlanBalances?.map((planBalance) => (
                      <Radio.Button key={planBalance.id} value={planBalance.id}>
                        {planBalance.name}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                  <Divider />
                </>
              )}
            </div>
          ) : null}
        </Col>

        {/* Assessment */}
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <If condition={showAssessmentByBalanceId}>
              <Col span={24}>
                <Menu
                  items={claimDateRanges?.claim_date_ranges.ranges.map((dateRanges) => ({
                    key: dateRanges.key,
                    label: (
                      <Badge
                        count={
                          claimCaseDetailsFormItem
                            ?.map((ccd) => ccd.key)
                            .filter(Boolean)
                            .filter((planInsuredBenefitKey) => planInsuredBenefitKey.startsWith(dateRanges.key)).length
                        }
                      >
                        <div className="m-8">{buildDateRangeTitle(dateRanges)}</div>
                      </Badge>
                    ),
                  }))}
                  mode="horizontal"
                  onClick={(e) => {
                    setSelectedDateRangeKey(e.key);
                    assessmentForm.resetFields();
                  }}
                  selectedKeys={selectedDateRangeKey == null ? [] : [selectedDateRangeKey]}
                />
              </Col>

              <Col span={24}>
                {selectedDateRangeKey != null && claimBenefitDetails != null && (
                  <ClaimCaseDetails
                    assessingPlanBalanceId={assessingPlanBalanceId}
                    claimBenefitDetails={claimBenefitDetails}
                    claimCaseId={claimCaseId}
                    form={assessmentForm}
                    ref={assessmentFormRef}
                    selectedDateRangeKey={selectedDateRangeKey}
                  />
                )}
              </Col>
            </If>
          </Row>
        </Col>

        {/* Assessment Summary */}
        <Col span={24}>{claimBenefitDetails == null ? null : <Summary claimBenefitDetails={claimBenefitDetails} />}</Col>
      </Row>
    </Content>
  );
};

export default ClaimBenefitVer3;
