import type { DateItem } from "config/common";

import { Col, Modal, Row, Space, Typography } from "antd";
import { FORMATTER } from "config/constants";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns";
import utils from "libs/utils";
import { AlertTriangle } from "lucide-react";

type ExtendedDateItem = {
  value?: Date | null;
} & DateItem;

const showModalInvalidDateRange = ({ dateItems, dued_at, issued_at }: { dateItems: ExtendedDateItem[]; dued_at?: string; issued_at?: string }): Promise<boolean> =>
  new Promise((resolve, reject) => {
    if (issued_at == null || dued_at == null) {
      resolve(true);
      return;
    }
    const duedAt = endOfDay(new Date(dued_at));
    const issuedAt = startOfDay(new Date(issued_at));
    const isOutside = dateItems.some((item) => item.value != null && (isAfter(item.value, duedAt) || isBefore(item.value, issuedAt)));
    if (!isOutside) {
      console.log({ isOutside });

      resolve(true);
      return;
    }
    Modal.confirm({
      cancelText: "Huỷ",
      content: (
        <Space direction="vertical">
          {dateItems.map((item) => (
            <Row align="bottom" gutter={16} key={item.key}>
              <Col flex="180px">
                <Typography.Text type="secondary">{item.label}</Typography.Text>
              </Col>
              <Col flex="auto">
                <Typography.Text strong>{item.value == null ? null : format(item.value, FORMATTER.DATE_FORMAT)}</Typography.Text>
              </Col>
            </Row>
          ))}
          <Row gutter={16}>
            <Col flex="180px">
              <Typography.Text type="secondary">Hiệu lực hợp đồng</Typography.Text>
            </Col>
            <Col flex="auto">
              <Typography.Text strong>
                {utils.formatDate(issued_at, FORMATTER.DATE_FORMAT)} - {utils.formatDate(dued_at, FORMATTER.DATE_FORMAT)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Typography.Paragraph>Vui lòng kiểm tra và điều chỉnh {dateItems.map((item) => item.label).join(", ")}</Typography.Paragraph>
            </Col>
          </Row>
        </Space>
      ),
      icon: false,
      okText: "Đã hiểu và tiếp tục lưu",
      onCancel: () => {
        resolve(false);
      },
      onOk: () => {
        resolve(true);
      },
      title: (
        <Typography.Title level={5}>
          <AlertTriangle color="red" size={16} /> {dateItems.map((item) => item.label).join(", ")} không nằm trong hiệu lực hợp đồng
        </Typography.Title>
      ),
      width: 600,
    });
  });

export default showModalInvalidDateRange;
