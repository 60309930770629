import type { DescriptionsProps } from "antd";
import type { DateItem } from "config/common";
import type { JSX } from "react";
import type { ClaimDetailQuery } from "sdk/gql/graphql";

import * as stylex from "@stylexjs/stylex";
import { useToggle } from "ahooks";
import { Descriptions, Modal, Popover, Space, Timeline } from "antd";
import Copyable from "app/common/components/Copyable";
import ExternalLink from "app/common/components/ExternalLink";
import If from "app/common/components/If";
import { PORTAL_PATH } from "app/portal/config/paths";
import { HistoryClaimCasesQuery } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimHistory";
import useAlert from "app/portal/screens/ClaimPortal/ClaimContext/useAlert";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { ADMISSION_DATE, DISCHARGE_DATE, PHYSICAL_EXAMINATION_DATE, claimCaseDates } from "config/common";
import { FORMATTER } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { isFuture } from "date-fns";
import { convert } from "html-to-text";
import { isGreaterThanZero } from "libs/hidash";
import { utils } from "libs/utils";
import { round, sum } from "lodash";
import { ChevronDown, ChevronUp, Clock4Icon, Info } from "lucide-react";
import { renderToStaticMarkup } from "react-dom/server";
import { Link, generatePath } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import "./useSummary.css";

const SLV_HISTORY_CLAIMS_QUERY = graphql(`
  query SlvHistoryClaims($insuredPersonId: uuid!) {
    slv_history_claim_cases(where: { insured_person_id: { _eq: $insuredPersonId } }, order_by: { created_at: asc }) {
      id
      code
      status
      history_claim_records {
        id
        claim_type
        decline_code
        diagnosis
        event_date
        hospitalization_from
        hospitalization_to
        icd_codes
        medical_provider_name
        paid_amount
        paid_hospitalization_duration
        policy_number
      }
    }
  }
`);

const MB_HISTORY_CLAIMS_QUERY = graphql(`
  query MbHistoryClaims($bpNumber: String!) {
    mbal {
      historyClaims(bpNumber: $bpNumber) {
        id: claimID
        claimID
        claimStatus
        claimtype
        eventDateFrom
        eventDateTo
        diagnosisDetail {
          diagnosis
          icdCode
        }
      }
    }
  }
`);

const ClaimDate = ({ claim, dateItem: { key_snake_case, label } }: { claim: NonNullable<ClaimDetailQuery["claim_cases_by_pk"]>; dateItem: DateItem }) => (
  <If condition={claim[key_snake_case] != null}>
    <div>
      - {label}: {utils.formatDate(claim[key_snake_case], FORMATTER.DATE_FORMAT)}
    </div>
  </If>
);

const styles = stylex.create({
  insuredCertificate: {
    marginTop: 30,
  },
});
//
type WarningStatus = "error" | "success" | "warning";

type SummaryItem = {
  check?: () => {
    clickable?: boolean;
    highlight?: boolean;
    message: JSX.Element;
    open?: () => void;
    status: WarningStatus;
  } | null;
  childrenNewLines?: number;
  hideInDisplay?: boolean;
  includeInCopy?: boolean;
  newLines?: number;
} & NonNullable<DescriptionsProps["items"]>[number];

const runCheck = (item: SummaryItem, forCopy: boolean) => {
  if (forCopy) {
    return item.includeInCopy === false ? null : item;
  }
  const res = item.check?.();
  if (res == null) return item;
  switch (res.status) {
    case "error": {
      return {
        ...item,
        children: (
          <Space>
            {item.children}
            <Popover content={res.message}>
              <Info color="yellow" size={14} />
            </Popover>
          </Space>
        ),
        contentStyle: { backgroundColor: "#ff4d4f" },
        labelStyle: { backgroundColor: "#ff4d4f" },
      };
    }
    case "warning": {
      return {
        ...item,
        children: (
          <Space>
            {item.children}
            <Popover content={res.message}>
              <Info color="red" size={14} />
            </Popover>
          </Space>
        ),
        contentStyle: { backgroundColor: "yellow" },
        labelStyle: { backgroundColor: "yellow" },
      };
    }
    case "success": {
      return {
        ...item,
        children: (
          <Space>
            {item.children}
            <Popover content={res.message}>
              <Info className={`anticon ${item.check?.()?.open && "cursor-pointer"}`} color="green" onClick={item.check?.()?.open} size={14} />
            </Popover>
          </Space>
        ),
        contentStyle: { color: "green" },
      };
    }
    default: {
      return item;
    }
  }
};

const useSummary = (forCopy = true) => {
  const { claim, claimToEffectiveDays, claimType, insuredCertificateTimeline, isClaimPeriodFullyEffective } = useClaim();
  const { isExclusiveDisease, isInWaitingTime } = useAlert();

  const [showHistory, { toggle: toggleShowHistory }] = useToggle();
  const [showClaimDetails, { toggle: toggleShowClaimDetails }] = useToggle();
  const [modal, contextHolder] = Modal.useModal();
  const { data } = usePQuery(
    MB_HISTORY_CLAIMS_QUERY,
    claimType.mbalHs === false || claim?.insured_certificate.insured_person.insured_number == null
      ? { skip: true }
      : {
          variables: {
            bpNumber: claim.insured_certificate.insured_person.insured_number,
          },
        },
  );
  const mbClaimHistory = data?.mbal?.historyClaims ?? [];
  const { data: slvHistoryClaims } = usePQuery(
    SLV_HISTORY_CLAIMS_QUERY,
    claimType.slvHs === false || claim?.insured_certificate.insured_person_id == null
      ? { skip: true }
      : {
          variables: {
            insuredPersonId: claim.insured_certificate.insured_person_id,
          },
        },
  );

  const { data: historyClaimCasesData } = usePQuery(
    HistoryClaimCasesQuery,
    claim?.id == null && claim?.insured_certificate.from_insured_certificate_courses[0]?.from_insured_certificate.id == null
      ? { skip: true }
      : {
          variables: {
            certificateId: claim.insured_certificate.from_insured_certificate_courses[0]?.from_insured_certificate.id,
            noCertificateCourse: claim.insured_certificate.from_insured_certificate_courses.length === 0,
            where: {
              claim_case_id: {
                _neq: claim.id,
              },
              insured_certificate: {
                insured_person: {
                  id: {
                    _eq: claim.insured_certificate.insured_person.id,
                  },
                },
              },
            },
          },
        },
  );

  if (claim == null) return { buildCopyableText: () => "", items: [], runCheck };

  const relationshipWithStaff = claim.insured_certificate.insured_person.insured_relationship_tos.find(
    (irt) => irt.insured_person_to_id === claim.insured_certificate.parent_insured_certificate?.insured_person.insured_person_id,
  );

  const labelStyle = { color: "rgba(0, 0, 0, 0.88)" };

  const outstandingPendingCodes = claim.claim_pending_codes.filter((i) => i.status === "O");

  const insuredTimelineModalConfig = {
    content: (
      <Timeline
        {...stylex.props(styles.insuredCertificate)}
        items={insuredCertificateTimeline.map((i) => ({
          children: (
            <Space split="|">
              <div>{utils.formatDate(i.issued_at, FORMATTER.DATE_FORMAT)}</div>
              {i.policy_plan == null ? null : (
                <>
                  <ExternalLink to={generatePath(PORTAL_PATH.POLICY_DETAIL, { id: i.policy_plan.policy.policy_id })}>{i.policy_plan.policy.policy_number}</ExternalLink>
                  <div>{i.policy_plan.policy.corporate_company?.name}</div>
                  <div>{i.policy_plan.policy.insurer_company?.name}</div>
                  <Link to={generatePath(PORTAL_PATH.PLAN_DETAILS, { planId: i.policy_plan.plan_id })}>{i.policy_plan.plan_name}</Link>
                </>
              )}
              <div>STT: {i.ordinal_number ?? "--"}</div>
            </Space>
          ),
          dot: isFuture(new Date(i.dued_at)) ? <Clock4Icon size={16} /> : undefined,
        }))}
      />
    ),
    maskClosable: true,
    title: "Quá trình tham gia",
    width: 1200,
  };

  const historyItems: DescriptionsProps["items"] = claim.insured_certificate.claim_cases.map((historiedCase) => {
    const totalPaidAmount = (() => {
      if (["Approved", "ApprovedReceivedDoc", "Paid"].includes(historiedCase.status)) {
        return historiedCase.source === "FWD_HISTORY" ? utils.formatCurrency(historiedCase.amount) : utils.formatCurrency(historiedCase.claim_case_payment?.actual_paid_amount);
      }
      return "0";
    })();
    return {
      children: (
        <Descriptions
          colon={false}
          column={1}
          items={[
            {
              children: `${historiedCase.code} - ${historiedCase.insured_benefit_type.comment} - ${historiedCase.claim_case_status.comment} - ${totalPaidAmount}`,
              key: "1",
              label: "",
            },
            ...claimCaseDates[historiedCase.benefit_type].map(({ key_snake_case, label }) => ({
              children: utils.formatDate(historiedCase[key_snake_case], FORMATTER.DATE_FORMAT),
              key: key_snake_case,
              label: `${label}: `,
            })),
            {
              children: historiedCase.medical_provider?.name ?? historiedCase.medical_provider_name,
              key: "2",
              label: "Bệnh viện điều trị: ",
            },
            {
              children: historiedCase.diagnosis ?? historiedCase.claim_case_assessed_diagnoses.map((diagnosis) => diagnosis.icd.title).join(", "),
              key: "3",
              label: "Chẩn đoán: ",
            },
            {
              children: historiedCase.claim_case_assessed_diagnoses.map((diagnosis) => diagnosis.icd.value).join(", "),
              key: "4",
              label: "ICD: ",
            },
          ]}
          labelStyle={labelStyle}
        />
      ),
      key: historiedCase.id,
      label: "•",
    };
  });

  const certificateIssuedAt = insuredCertificateTimeline[0]?.issued_at;

  const mbalHsHistoryItems: DescriptionsProps["items"] = mbClaimHistory
    .filter((claimCase) => claimCase.claimStatus !== "Cancelled")
    .map((historiedCase) => ({
      children: (
        <Descriptions
          colon={false}
          column={1}
          items={[
            {
              children: `${historiedCase.claimID} - ${historiedCase.claimStatus} - ${historiedCase.claimtype}`,
              key: "1",
              label: "",
            },
            {
              children: historiedCase.diagnosisDetail.length > 0 && historiedCase.diagnosisDetail.map((d) => d.icdCode).join(", "),
              key: "2",
              label: "Mã bệnh: ",
            },
            {
              children: utils.formatDate(historiedCase.eventDateFrom, FORMATTER.DATE_FORMAT),
              key: "3",
              label: "Ngày vào viện: ",
            },
            {
              children: utils.formatDate(historiedCase.eventDateTo, FORMATTER.DATE_FORMAT),
              key: "4",
              label: "Ngày ra viện: ",
            },
          ]
            .map((item) => runCheck(item, forCopy))
            .filter(Boolean)}
          labelStyle={labelStyle}
        />
      ),
      key: historiedCase.claimID,
      label: "",
    }));
  const slvHsHistoryItems: DescriptionsProps["items"] =
    slvHistoryClaims?.slv_history_claim_cases
      .filter((c) => claim.insured_certificate.claim_cases.map((historyCase) => historyCase.code).includes(c.code) === false)
      .map((historiedCase) => ({
        children: (
          <Descriptions
            colon
            column={1}
            items={[
              {
                children: `${historiedCase.code} - ${historiedCase.status}`,
                key: "1",
                label: "",
              },
              ...historiedCase.history_claim_records
                .flatMap((claimRecord) => [
                  {
                    children: `${claimRecord.policy_number} - ${claimRecord.claim_type}`,
                    key: "2",
                    label: "Mã HĐ",
                  },
                  {
                    children: `${utils.formatDate(claimRecord.event_date, FORMATTER.DATE_FORMAT)}`,
                    key: "4",
                    label: "Ngày xảy ra sự kiện bảo hiểm",
                  },
                  claimRecord.hospitalization_from == null
                    ? null
                    : {
                        children: `${utils.formatDate(claimRecord.hospitalization_from, FORMATTER.DATE_FORMAT)}`,
                        key: "3",
                        label: "Ngày vào viện",
                      },
                  claimRecord.hospitalization_to == null
                    ? null
                    : {
                        children: `${utils.formatDate(claimRecord.hospitalization_to, FORMATTER.DATE_FORMAT)}`,
                        key: "4",
                        label: "Ngày ra viện",
                      },
                  {
                    children: `${claimRecord.paid_hospitalization_duration}`,
                    key: "5",
                    label: "Số ngày chi trả",
                  },
                  {
                    children: `${utils.formatCurrency(claimRecord.paid_amount)}`,
                    key: "6",
                    label: "Số tiền chi trả",
                  },
                  {
                    children: `${claimRecord.medical_provider_name}`,
                    key: "7",
                    label: "Nơi điều trị",
                  },
                  {
                    children: `${claimRecord.diagnosis}`,
                    key: "8",
                    label: "Chẩn đoán",
                  },
                  {
                    children: `${(claimRecord.icd_codes ?? []).join(", ")}`,
                    key: "9",
                    label: "ICD",
                  },
                  claimRecord.decline_code != null && {
                    children: `${claimRecord.decline_code}`,
                    key: "10",
                    label: "Decline Code",
                  },
                ])
                .filter(Boolean),
            ]
              .filter(Boolean)
              .map((item) => runCheck(item, forCopy))
              .filter(Boolean)}
            labelStyle={labelStyle}
          />
        ),
        key: historiedCase.code,
        label: "•",
      })) ?? [];

  const claimDetailsItems: SummaryItem[] = claim.claim_case_details.flatMap((detail, idx) => [
    {
      children: "",
      key: detail.claim_case_detail_id,
      label: `${idx + 1}. ${
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        detail.plan_insured_benefit?.insured_benefit.name
      }`,
      newLines: 1,
    },
    {
      children: utils.formatCurrency(detail.total_request_amount),
      key: detail.claim_case_detail_id,
      label: "- Số tiền yêu cầu: ",
    },
    {
      children: utils.formatCurrency(detail.total_paid_amount),
      key: detail.claim_case_detail_id,
      label: "- Số tiền chi trả: ",
    },
  ]);

  const items: SummaryItem[] = [
    {
      children: claim.code,
      key: "code",
      label: "",
    },
    {
      children: claim.insured_certificate.insured_person.name,
      key: "la",
      label: "LA",
    },
    claimType.healthCare
      ? {
          children: `${utils.formatDate(claim.insured_certificate.insured_person.dob, FORMATTER.DATE_FORMAT)} (${claim.insured_certificate.insured_person.age} tuổi)`,
          key: "dob",
          label: "Ngày sinh",
        }
      : null,
    {
      children: claim.insured_certificate.policy.policy_number,
      key: "policy_number",
      label: "Mã HĐ",
    },
    {
      children: claim.insured_certificate.policy_plan?.plan_name,
      key: "plan_name",
      label: "Gói sản phẩm",
    },
    relationshipWithStaff != null && {
      children: relationshipWithStaff.insured_relationship_type.comment,
      key: "relationship_with_staff",
      label: "Mối quan hệ với nhân viên",
    },
    claim.insured_certificate.policy.types === "Corporate" && {
      children: claim.insured_certificate.policy.corporate_company?.name,
      key: "corporate_company_name",
      label: "Tên công ty",
    },
    claim.insured_certificate.policy.types === "Corporate" &&
      claim.insured_certificate.subsidiary_company_name != null && {
        children: claim.insured_certificate.subsidiary_company_name,
        key: "subsidiary_company_name",
        label: "Công ty thành viên",
      },
    claim.insured_certificate.policy.types === "Individual" && {
      children: claim.insured_certificate.policy.insured_person?.name,
      key: "policy_holder_name",
      label: "Chủ hợp đồng",
      newLines: 1,
    },
    {
      check: () => {
        if (insuredCertificateTimeline.length > 1) {
          return {
            clickable: true,
            message: (
              <Space>
                {contextHolder}
                <Info size={12} />
                <span>NĐBH có quá trình tham gia trước đó, click để xem chi tiết</span>
              </Space>
            ),
            open: () => {
              modal.info(insuredTimelineModalConfig);
            },
            status: "success" as WarningStatus,
          };
        }
        if (utils.formatDate(certificateIssuedAt, FORMATTER.DATE_FORMAT) !== utils.formatDate(claim.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT)) {
          return {
            message: (
              <Space>
                <Info size={12} />
                <span>
                  Hiệu lực thẻ {utils.formatDate(certificateIssuedAt, FORMATTER.DATE_FORMAT)} khác hiệu lực HĐ{" "}
                  {utils.formatDate(claim.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT)}
                </span>
              </Space>
            ),
            status: "error" as WarningStatus,
          };
        }
        return null;
      },
      children: utils.formatDate(certificateIssuedAt, FORMATTER.DATE_FORMAT),
      key: "cert_issued_at",
      label: "Ngày bắt đầu tham gia",
    },
    claim.insured_certificate.endorsement_code != null && {
      children: claim.insured_certificate.endorsement_code,
      key: "endorsement_code",
      label: "Endorsement code",
    },
    claim.insured_certificate.endorsement_reason_type != null && {
      children: claim.insured_certificate.endorsement_reason_type.comment,
      key: "endorsement_reason_type",
      label: "Endorsement Reason",
    },
    claim.insured_certificate.lapsed_date != null && {
      children: utils.formatDate(claim.insured_certificate.lapsed_date, FORMATTER.DATE_FORMAT),
      key: "lapsed_date",
      label: "Ngày mất hiệu lực sản phẩm",
    },
    claim.insured_certificate.reinstated_date != null && {
      children: utils.formatDate(claim.insured_certificate.reinstated_date, FORMATTER.DATE_FORMAT),
      key: "reinstated_date",
      label: "Ngày khôi phục hiệu lực sản phẩm",
    },
    claimToEffectiveDays != null && {
      check: () => {
        if (insuredCertificateTimeline.length > 1) {
          return {
            clickable: true,
            message: (
              <Space>
                {contextHolder}
                <Info size={12} />
                <span>NĐBH có quá trình tham gia trước đó, click để xem chi tiết</span>
              </Space>
            ),
            open: () => {
              modal.info(insuredTimelineModalConfig);
            },
            status: "success" as WarningStatus,
          };
        }
        return null;
      },
      children: `${claimToEffectiveDays} ngày`,
      key: "claim_to_effective_days",
      label: "Thời gian hiệu lực HĐ",
    },
    claimType.fwdMr &&
      claim.insured_certificate.insured_person.insured_person_remarks.length > 0 && {
        children: claim.insured_certificate.insured_person.insured_person_remarks.map((item) => item.description).join("\n"),
        key: "la_remarks",
        label: "Thông tin remark",
      },
    isGreaterThanZero(historyClaimCasesData?.claim_cases) && {
      children: (
        <Space direction="vertical">
          {historyClaimCasesData?.claim_cases
            .filter((c) => {
              if (forCopy) {
                return c.status !== "Cancelled";
              }
              return true;
            })
            .map((claimCase) => {
              const amount = (() => {
                if (["Approved", "ApprovedReceivedDoc", "ApprovedWaitingDoc", "Paid"].includes(claimCase.status)) {
                  if (claimCase.source === "FWD_HISTORY") {
                    return ` - ${utils.formatCurrency(claimCase.amount)}`;
                  }
                  return ` - ${utils.formatCurrency(claimCase.claim_case_payment?.actual_paid_amount)}`;
                }
                return "";
              })();
              return `${claimCase.code} - ${claimCase.insured_benefit_type.comment}${(() => {
                const dates = Object.values(claimCaseDates[claimCase.benefit_type]).map((i) => utils.formatDate(claimCase[(i as DateItem).key_snake_case], FORMATTER.DATE_FORMAT));
                if (dates.length === 0) return "";
                return ` - ${dates.join(" - ")}`;
              })()} - ${claimCase.claim_case_status.comment}${amount} - ${claimCase.claim_case_assessed_diagnoses.map((i) => i.icd.value).join(",")}`;
            })}
        </Space>
      ),
      childrenNewLines: 1,
      hideInDisplay: true,
      includeInCopy: claimType.fwdMr,
      key: "compact_history",
      label: "Lịch sử",
    },
    isClaimPeriodFullyEffective === false && {
      children: "Có",
      key: "isClaimPeriodFullyEffective",
      label: "Trong thời gian hợp đồng mất hiếu lực",
    },
    isInWaitingTime === true && {
      children: "Có",
      key: "isInWaitingTime",
      label: "Hợp đồng trong thời gian chờ",
    },
    isExclusiveDisease === true && {
      children: "Có",
      key: "isExclusiveDisease",
      label: "Claim thuộc nhóm bệnh loại trừ",
    },
    claimType.fwdMr &&
      claim.fwd_claim_case?.exclusion_info != null && {
        children: claim.fwd_claim_case.exclusion_info,
        key: "exclusion_info",
        label: "Điều khoản loại trừ",
      },
    claimType.fwdMr &&
      claim.fwd_claim_case?.policy_note != null && {
        children: claim.fwd_claim_case.policy_note,
        key: "policy_note",
        label: "Ghi chú hợp đồng",
      },
    {
      children: (
        <Space>
          <Copyable
            copyable={{
              onCopy: () => {
                const content = historyItems
                  .map((i) => ({ ...i, children: i.children != null && renderToStaticMarkup(i.children) }))
                  .map((i) => ({
                    ...i,
                    children: convert(i.children, { wordwrap: 250 }),
                  }))
                  .map((i) => {
                    const label = i.label !== "" && i.label != null ? `${i.label}` : "";
                    return `${label}: ${i.children}`;
                  })
                  .join("\n\n");
                navigator.clipboard.writeText(content);
              },
              tooltips: true,
            }}
            show
          >
            {claim.insured_certificate.claim_cases_aggregate.aggregate?.count}
          </Copyable>
          <If condition={showHistory} else={<ChevronDown className="cursor-pointer" onClick={toggleShowHistory} size={14} />}>
            <ChevronUp className="cursor-pointer" onClick={toggleShowHistory} size={14} />
          </If>
        </Space>
      ),
      includeInCopy: false,
      key: "history",
      label: "Lịch sử",
      labelStyle: { fontWeight: "bold" },
    },
    isGreaterThanZero(claim.insured_certificate.claim_cases_aggregate.aggregate?.count) &&
      showHistory && {
        children: <Descriptions colon={false} column={1} items={historyItems} labelStyle={labelStyle} />,
        key: "history_items",
        label: "",
      },
    claimType.mbalHs && {
      children: (
        <Space>
          <Copyable
            copyable={{
              onCopy: () => {
                const content = mbalHsHistoryItems
                  .map((i) => ({ ...i, children: i.children != null && renderToStaticMarkup(i.children) }))
                  .map((i) => ({
                    ...i,
                    children: convert(i.children, { wordwrap: 250 }),
                  }))
                  .map((i) => {
                    const label = i.label !== "" && i.label != null ? `${i.label}` : "";
                    return `${label}: ${i.children}`;
                  })
                  .join("\n\n");
                navigator.clipboard.writeText(content);
              },
              tooltips: true,
            }}
            show
          >
            {mbalHsHistoryItems.length}
          </Copyable>
          <If condition={showHistory} else={<ChevronDown className="cursor-pointer" onClick={toggleShowHistory} size={14} />}>
            <ChevronUp className="cursor-pointer" onClick={toggleShowHistory} size={14} />
          </If>
        </Space>
      ),
      includeInCopy: false,
      key: "mbal_history",
      label: "Lịch sử (Các SP khác)",
      labelStyle: { fontWeight: "bold" },
    },
    claimType.mbalHs &&
      isGreaterThanZero(claim.insured_certificate.claim_cases_aggregate.aggregate?.count) &&
      showHistory && {
        children: mbClaimHistory.length === 0 ? "--" : <Descriptions colon={false} column={1} items={mbalHsHistoryItems} labelStyle={labelStyle} />,
        key: "mbal_history_items",
        label: "",
      },
    claimType.slvHs && {
      children: (
        <Space>
          <Copyable
            copyable={{
              onCopy: () => {
                const content = slvHsHistoryItems
                  .map((i) => ({ ...i, children: i.children != null && renderToStaticMarkup(i.children) }))
                  .map((i) => ({
                    ...i,
                    children: convert(i.children, { wordwrap: 250 }),
                  }))
                  .map((i) => (i.label == null || i.label === "" ? i.children : `${i.label}: ${i.children}`))
                  .join("\n\n");
                navigator.clipboard.writeText(content);
              },
              tooltips: true,
            }}
            show
          >
            {slvHsHistoryItems.length}
          </Copyable>
          <If condition={showHistory} else={<ChevronDown className="cursor-pointer" onClick={toggleShowHistory} size={14} />}>
            <ChevronUp className="cursor-pointer" onClick={toggleShowHistory} size={14} />
          </If>
        </Space>
      ),
      includeInCopy: false,
      key: "slv_history",
      label: "Lịch sử các SP khác",
      labelStyle: { fontWeight: "bold" },
    },
    claimType.slvHs &&
      isGreaterThanZero(slvHsHistoryItems.length) &&
      showHistory && {
        children: slvHsHistoryItems.length === 0 ? "--" : <Descriptions colon={false} column={1} items={slvHsHistoryItems} labelStyle={labelStyle} />,
        key: "slv_history_items",
        label: "",
      },
    {
      children: "",
      contentStyle: { paddingBottom: 0 },
      key: "treatment_info",
      label: "Chi tiết lần điều trị",
      labelStyle: { fontWeight: "bold" },
      newLines: 1,
    },
    {
      children: (
        <Space direction="vertical" size={1}>
          <div>- Nơi điều trị: {claim.medical_provider?.name ?? "--"}</div>
          <If
            condition={claim.benefit_type === "Accident"}
            else={claimCaseDates[claim.benefit_type].map((dateItem) => (
              <ClaimDate claim={claim} dateItem={dateItem} key={dateItem.key_snake_case} />
            ))}
          >
            {[ADMISSION_DATE, DISCHARGE_DATE, PHYSICAL_EXAMINATION_DATE].map((dateItem) => (
              <ClaimDate claim={claim} dateItem={dateItem} key={dateItem.key_snake_case} />
            ))}
          </If>
          <div>- Chẩn đoán: {claim.diagnosis}</div>
          <div>- Phương pháp điều trị: {claim.treatment_method}</div>
        </Space>
      ),
      key: "treatment_info",
    },
    {
      children: (
        <Space>
          <Copyable
            copyable={{
              onCopy: () => {
                const content = claimDetailsItems
                  .map((i) => ({ ...i, children: i.children != null && renderToStaticMarkup(i.children) }))
                  .map((i) => ({
                    ...i,
                    children: convert(i.children, { wordwrap: 250 }),
                  }))
                  .map((i, index) => {
                    const label = i.label !== "" && i.label != null ? i.label : "";
                    const text = `${label}${i.children}`.replace("  ", " ");
                    if (index !== 0 && i.newLines != null) {
                      return `${"\n".repeat(i.newLines)}${text}`;
                    }
                    return text;
                  })
                  .join("\n");
                navigator.clipboard.writeText(content);
              },
              tooltips: true,
            }}
            show
          >
            {claim.claim_case_details.length}
          </Copyable>
          <If condition={showClaimDetails} else={<ChevronDown className="cursor-pointer" onClick={toggleShowClaimDetails} size={14} />}>
            <ChevronUp className="cursor-pointer" onClick={toggleShowClaimDetails} size={14} />
          </If>
        </Space>
      ),
      includeInCopy: false,
      key: "claim_details",
      label: "Quyền lợi",
      labelStyle: { fontWeight: "bold" },
    },
    showClaimDetails && {
      children: claim.claim_case_details.length === 0 ? <span>chưa thẩm định</span> : <Descriptions colon={false} column={1} items={claimDetailsItems} labelStyle={labelStyle} />,
      includeInCopy: false,
      key: "assessment_summary",
      label: "",
    },
    {
      children: "",
      key: "assessment_explanation",
      label: "Tổng kết thẩm định",
      labelStyle: { fontWeight: "bold" },
      newLines: 1,
    },
    {
      children: (
        <Descriptions
          colon={false}
          column={1}
          items={[
            {
              check: () => {
                if (claim.claim_case_payment?.requested_amount !== claim.request_amount) {
                  return {
                    highlight: true,
                    message: (
                      <Space>
                        <Info className="info" size={12} />
                        <span>
                          Tổng tiền yêu cầu {utils.formatCurrency(claim.claim_case_payment?.requested_amount)} chưa bằng số tiền yêu cầu{" "}
                          {utils.formatCurrency(claim.request_amount)}
                        </span>
                      </Space>
                    ),
                    status: "warning" as WarningStatus,
                  };
                }
                return null;
              },
              children: utils.formatCurrency(
                claim.claim_case_payment?.requested_amount != null && claim.claim_case_payment.requested_amount !== 0
                  ? claim.claim_case_payment.requested_amount
                  : sum(claim.claim_case_details.map(({ total_request_amount }) => total_request_amount)),
              ),
              key: "request_amount",
              label: "- Số tiền yêu cầu: ",
            },
            claimType.fwdMr || claimType.healthCare
              ? {
                  children: utils.formatCurrency(claim.claim_case_payment?.non_paid_amount),
                  key: "non_paid_amount",
                  label: "- Số tiền không chi trả (ngoài PVBH): ",
                }
              : null,
            claimType.fwdMr || claimType.healthCare
              ? {
                  children: utils.formatCurrency(claim.claim_case_payment?.shortfall_amount),
                  includeInCopy: claimType.fwdMr === false,
                  key: "shortfall_amount",
                  label: "- Số tiền không chi trả (vượt hạn mức): ",
                }
              : null,
            claimType.fwdMr || claimType.healthCare
              ? {
                  children: utils.formatCurrency(claim.claim_case_payment?.coverage_amount),
                  key: "coverage_amount",
                  label: "- Số tiền trong PVBH: ",
                }
              : null,
            claimType.fwdMr || claimType.healthCare
              ? isGreaterThanZero(claim.claim_case_payment?.co_payment_ratio) && {
                  children: utils.formatCurrency(claim.claim_case_payment?.co_payment_amount),
                  key: "copay_amount",
                  label: "- Số tiền Copay: ",
                }
              : null,
            isGreaterThanZero(claim.claim_case_payment?.deductible_amount) && {
              children: utils.formatCurrency(claim.claim_case_payment?.deductible_amount),
              key: "deductible_amount",
              label: "- Số tiền khấu trừ: ",
            },
            {
              children: utils.formatCurrency(
                claim.claim_case_payment?.actual_paid_amount != null && claim.claim_case_payment.actual_paid_amount !== 0
                  ? claim.claim_case_payment.actual_paid_amount
                  : round(sum(claim.claim_case_details.map(({ total_paid_amount }) => total_paid_amount))),
              ),
              key: "actual_paid_amount",
              label: "- Số tiền chi trả: ",
            },
          ]
            .filter(Boolean)
            .map((item) => runCheck(item, forCopy))
            .filter(Boolean)}
          labelStyle={labelStyle}
        />
      ),
      key: "assessment_summary",
      label: null,
    },
    claim.assessment_summary[0] != null && {
      children: "",
      key: "assessment_explanation2",
      label: "Lý do",
      labelStyle: { fontWeight: "bold" },
      newLines: 1,
    },
    claim.assessment_summary[0] != null && {
      children: (
        <Descriptions
          colon={false}
          column={1}
          items={claim.assessment_summary[0].content.split("\n").map((i) => ({
            children: i,
            key: i,
            label: "",
          }))}
          labelStyle={labelStyle}
        />
      ),
      key: "assessment_summary",
      label: "",
    },

    outstandingPendingCodes.length > 0 && {
      children: "",
      key: "pending",
      label: "YCBS",
      labelStyle: { fontWeight: "bold" },
      newLines: 1,
    },

    outstandingPendingCodes.length > 0 && {
      children: (
        <Descriptions
          colon={false}
          column={1}
          items={outstandingPendingCodes.map((i) => ({
            children: `- ${i.extended_text}`,
            key: i.id,
            label: "",
          }))}
        />
      ),
      key: "assessment_explanation",
      label: "",
    },
  ]
    .filter(Boolean)
    .map((item) => runCheck(item, forCopy))
    .filter(Boolean);

  const buildCopyableText = () => {
    const texts = items
      .filter(Boolean)
      .filter((i) => i.includeInCopy !== false)
      .map((i) => {
        if (typeof i.children === "string" || typeof i.children === "number" || typeof i.children === "boolean") {
          return i;
        }
        return { ...i, children: i.children != null && renderToStaticMarkup(i.children) };
      })
      .map((i) => ({ ...i, children: convert(i.children, { preserveNewlines: false, wordwrap: 250 }) }))
      .map((i) => ({ ...i, children: i.childrenNewLines == null ? i.children : `${"\n".repeat(i.childrenNewLines)}${i.children}` }))
      .map((i) => {
        const label = i.label !== "" && i.label != null ? `${i.label}: ` : "";
        const content = `${label}${i.children}`;
        if (i.newLines != null) {
          return `${"\n".repeat(i.newLines)}${content}`;
        }
        return content;
      });

    return texts.join("\n");
  };

  return { buildCopyableText, items: items.filter(Boolean).filter((i) => Boolean(i.hideInDisplay) === false), runCheck };
};

export default useSummary;
