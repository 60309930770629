import type { FromInsuredCertificateFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { ClaimContextType, ClaimStatus, CopayType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { FC } from "react";
import type { FragmentOf, VariablesOf } from "sdk/v2/graphql";

import { notification } from "antd";
import { GET_CUSTOM_SETTING, UPSERT_CUSTOM_SETTINGS } from "app/common/CommonQueries";
import { PORTAL_PATH } from "app/portal/config/paths";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import { ClaimContext } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext";
import { FromInsuredCertificateFragmentDoc, UpdateFwdClaimDocument, UpsertClaimCasePaymentDocument } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import ClaimDateRangesDocument from "app/portal/screens/ClaimPortal/ClaimContext/graphql/ClaimDateRangesDocument";
import { UPDATE_CLAIM_MUTATION } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/mutations";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import UpdateClaimCasePaymentOnClaimProviderDocument from "app/portal/screens/ClaimPortal/ClaimContext/graphql/UpdateClaimCasePaymentOnClaimProviderDocument";
import useUpdateClaimTat from "app/portal/screens/ClaimPortal/ClaimContext/useUpdateClaimTat";
import { SLACK } from "config";
import { CLAIM_DECISION_STATUSES } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { add, differenceInDays, endOfDay, isWithinInterval, startOfDay } from "date-fns";
import useCurrentPath from "hooks/useCurrentPath";
import { useStatusLogMutation } from "hooks/useLog";
import usePollInterval from "hooks/usePollInterval";
import { FigClaimCountersForSideMenuDocument } from "layouts/components/SideMenu";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import utils from "libs/utils";
import { orderBy, pick, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ClaimDetailDocument } from "sdk/gql/graphql";
import { IdentityRolesEnum, InsuredBenefitTypesEnum, InsuredCertificateRelatedEventTypesEnum, SettingModuleEnum, SettingNameEnum, SettingObjectTypeEnum } from "sdk/gql/types";
import { graphql } from "sdk/v2/graphql";

import confirmChangeStatusModal from "./confirmChangeStatusModal";
import useFwd from "./useFwd";
import { getClaimType, getInsurerClaimType, INSURER_CLAIM_TYPE } from "./utils";

const SLACK_CHANNELS = {
  [`${INSURER_CLAIM_TYPE.FWD}_DB`]: SLACK.FWD_BLVP_CHANNEL,
  [`${INSURER_CLAIM_TYPE.GENERAL}_DB`]: SLACK.HC_BLVP_CHANNEL,
  [INSURER_CLAIM_TYPE.FWD]: SLACK.FWD_MR_CLAIM_CHANNEL,
  [INSURER_CLAIM_TYPE.GENERAL]: SLACK.HC_CLAIM_CHANNEL,
  [INSURER_CLAIM_TYPE.MBAL]: SLACK.MBAL_HS_CLAIM_CHANNEL,
  [INSURER_CLAIM_TYPE.SLV_HS]: SLACK.SLV_HS_CLAIM_CHANNEL,
};

const useUnsavedChanged = () => {
  const hasUnsavedChanges = useRef(false);
  const setHasUnsavedChange = useCallback((value: boolean) => {
    hasUnsavedChanges.current = value;
  }, []);
  useEffect(() => {
    window.addEventListener("load", () => {
      window.addEventListener("beforeunload", (e) => {
        if (hasUnsavedChanges.current === false) {
          return null;
        }
        const confirmationMessage = "It looks like you have been editing something. If you leave before saving, your changes will be lost.";

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (e != null && window.event != null) {
          // @ts-expect-error
          window.event.returnValue = confirmationMessage;
        }
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
      });
    });
  }, []);

  return { hasUnsavedChanges: hasUnsavedChanges.current, setHasUnsavedChange };
};

const eventType = {
  Effective: "Effective",
  Expiration: "Expiration",
} as const;

const ClaimProvider: FC<{ children?: React.ReactNode } & Record<string, any>> = (props) => {
  const { globalData, hasRole } = useAuth();
  const { claimCaseId } = useParams<{ claimCaseId: string }>();
  const notificationKey = useMemo(() => `claim_${claimCaseId}`, [claimCaseId]);
  const [notificationApi, contextHolder] = notification.useNotification();

  const saveStatusCallbacks = useRef<((newStatus: ClaimStatus) => void)[] | undefined>(undefined);
  const registerSaveStatusCallback = useCallback((f) => {
    saveStatusCallbacks.current = saveStatusCallbacks.current ?? [];
    saveStatusCallbacks.current.push(f);
  }, []);

  const { setHasUnsavedChange } = useUnsavedChanged();

  const pollInterval = usePollInterval(10_000);

  const { data, refetch, stopPolling } = usePQuery(
    ClaimDetailDocument,
    claimCaseId == null
      ? { skip: true }
      : {
          pollInterval,
          skipPollAttempt: () => document.hidden,
          variables: { claimId: claimCaseId },
        },
  );

  const { data: ClaimDateRangeData } = usePQuery(
    ClaimDateRangesDocument,
    claimCaseId == null
      ? { skip: true }
      : {
          variables: {
            claimId: claimCaseId,
          },
        },
  );

  const [updateClaimStatusMutation, { loading: saveStatusLoading }] = usePMutation(UPDATE_CLAIM_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: (d) => {
      if (d.update_claim_cases_by_pk?.status == null) return;
      notificationApi.success({
        key: notificationKey,
        message: `Cập nhật trạng thái [${globalData?.claim_case_statuses.find(({ value }) => value === d.update_claim_cases_by_pk?.status)?.comment}] thành công`,
      });
      uniqBy(saveStatusCallbacks.current, (fn) => `${fn}`).forEach((f) => (d.update_claim_cases_by_pk?.status == null ? null : f(d.update_claim_cases_by_pk.status)));
    },
    refetchQueries: getRefetchOperationNames([FigClaimCountersForSideMenuDocument, CLAIM_DETAIL_QUERY, ClaimBenefitDetailsDocument]),
  });
  const [updateFwdClaimMutation, { loading: updatingFwdClaim }] = usePMutation(UpdateFwdClaimDocument, {
    refetchQueries: ["ClaimDetail"],
  });
  const [insertStatusLog] = useStatusLogMutation();

  const claim = data?.claim_cases_by_pk;
  const claimDateRanges = ClaimDateRangeData?.claim_cases_by_pk;

  const updateClaimTat = useUpdateClaimTat();

  if (CLAIM_DECISION_STATUSES.includes(claim?.status ?? "") === true || claimCaseId == null || claimCaseId === "") {
    stopPolling();
  }

  // COPAY
  const [copayRatio, setCopayRatio] = useState<null | number>(null);
  const [bonusRatio, setBonusRatio] = useState<null | number>(null);
  const [enableCopay, setEnableCopay] = useState<boolean>(false);

  useEffect(() => {
    if (claim?.claim_case_payment?.co_payment_ratio != null) setCopayRatio(claim.claim_case_payment.co_payment_ratio);
  }, [claim?.claim_case_payment?.co_payment_ratio]);

  const insurerClaimType = useMemo(() => getInsurerClaimType(claim), [claim]);

  const claimType = useMemo(() => getClaimType(claim), [claim]);

  const [status, setStatus] = useState<ClaimStatus>("Initialize");
  useEffect(() => {
    if (claim?.status != null) setStatus(claim.status);
  }, [claim?.claim_case_id, claim?.status]);

  const allowDetailInput = claim?.is_accessible === true && (CLAIM_DECISION_STATUSES.includes(claim.status) === false || hasRole([IdentityRolesEnum.DepartmentHead]));

  const validateEventDateByBenefitType = useCallback(() => {
    switch (claim?.benefit_type) {
      case "Accident": {
        return (
          claim.event_date != null &&
          ((claim.physical_examination_date != null && claim.admission_date == null && claim.discharge_date == null) ||
            (claim.physical_examination_date == null && claim.admission_date != null && claim.discharge_date != null))
        );
      }
      case InsuredBenefitTypesEnum.Dental:
      case InsuredBenefitTypesEnum.OutPatient: {
        return claim.physical_examination_date != null;
      }
      case InsuredBenefitTypesEnum.Maternity:
      case InsuredBenefitTypesEnum.InPatient: {
        return claim.admission_date != null && claim.discharge_date != null;
      }
      case InsuredBenefitTypesEnum.Life: {
        return claim.event_date != null;
      }
      default: {
        return false;
      }
    }
  }, [claim?.admission_date, claim?.benefit_type, claim?.discharge_date, claim?.event_date, claim?.physical_examination_date]);

  let isDetailFormFilled =
    (claim != null && claim.claim_case_assessed_diagnoses.length > 0 && validateEventDateByBenefitType()) || CLAIM_DECISION_STATUSES.includes(claim?.status ?? "");
  if (claimType.healthCare) {
    isDetailFormFilled = (isDetailFormFilled && claim?.treatment_method != null && claim.treatment_method !== "") || CLAIM_DECISION_STATUSES.includes(claim?.status ?? "");
  }

  const notAllowAssessmentStatuses = [claimType.slvHs ? undefined : "Initialize", "Paid", "Approved", "ApprovedWaitingDoc", "ApprovedReceivedDoc", "Declined", "Cancelled"].filter(
    Boolean,
  );
  const allowAssessment =
    (isDetailFormFilled || claim?.source === "BAOMINH_HISTORY") &&
    (!notAllowAssessmentStatuses.includes(status) || hasRole([IdentityRolesEnum.DepartmentHead])) &&
    claim?.is_accessible === true;

  const requireCopayAssessment = !(!enableCopay || copayRatio != null || bonusRatio != null);

  let claimCaseRoutePath: typeof PORTAL_PATH.FWD_MR_CLAIM_CASE | typeof PORTAL_PATH.HC_CLAIM_CASE | typeof PORTAL_PATH.MBAL_HS_CLAIM_CASE | typeof PORTAL_PATH.SLV_HS_CLAIM_CASE =
    PORTAL_PATH.HC_CLAIM_CASE;
  const currentPath = useCurrentPath();

  if (claim != null) {
    switch (insurerClaimType) {
      case INSURER_CLAIM_TYPE.FWD: {
        claimCaseRoutePath = PORTAL_PATH.FWD_MR_CLAIM_CASE;
        break;
      }
      case INSURER_CLAIM_TYPE.MBAL: {
        claimCaseRoutePath = PORTAL_PATH.MBAL_HS_CLAIM_CASE;
        break;
      }
      case INSURER_CLAIM_TYPE.SLV_HS: {
        claimCaseRoutePath = PORTAL_PATH.SLV_HS_CLAIM_CASE;
        break;
      }
      default: {
        claimCaseRoutePath = PORTAL_PATH.HC_CLAIM_CASE;
      }
    }
  } else if (currentPath?.includes("assignment") === true) {
    // when not in claim detail
    switch (currentPath) {
      case PORTAL_PATH.FWD_MR_CLAIM_CASE_ASSIGNMENT: {
        claimCaseRoutePath = PORTAL_PATH.FWD_MR_CLAIM_CASE;
        break;
      }
      case PORTAL_PATH.MBAL_HS_CLAIM_CASE_ASSIGNMENT: {
        claimCaseRoutePath = PORTAL_PATH.MBAL_HS_CLAIM_CASE;
        break;
      }
      case PORTAL_PATH.SLV_HS_CLAIM_CASE_ASSIGNMENT: {
        claimCaseRoutePath = PORTAL_PATH.SLV_HS_CLAIM_CASE;
        break;
      }
      default: {
        claimCaseRoutePath = PORTAL_PATH.HC_CLAIM_CASE;
      }
    }
  }

  const [toggleClaimAutoCorrespondence, { loading: updatingClaimAutoCorrespondence }] = usePMutation(UPSERT_CUSTOM_SETTINGS);
  const { data: enableAutoCorrespondenceData, loading: loadingCorrespondenceCustomSetting } = usePQuery(
    GET_CUSTOM_SETTING,
    claim?.claim_case_id == null
      ? { skip: true }
      : {
          variables: {
            getCustomSettingInput: {
              moduleName: SettingModuleEnum.Correspondence,
              settingApplicationObject: {
                objectId: claim.claim_case_id,
                objectType: SettingObjectTypeEnum.PublicClaimCases,
              },
              settingName: SettingNameEnum.EnableAutoCorrespondence,
            },
          },
        },
  );

  const claimDateRangeByBenefitType = useMemo(() => {
    switch (claim?.benefit_type) {
      case InsuredBenefitTypesEnum.InPatient:
      case InsuredBenefitTypesEnum.Maternity: {
        if (claim.admission_date == null || claim.discharge_date == null) return [];

        return [new Date(claim.admission_date), new Date(claim.discharge_date)];
      }
      case InsuredBenefitTypesEnum.OutPatient:
      case InsuredBenefitTypesEnum.Dental: {
        if (claim.physical_examination_date == null) return [];

        const physicalExaminationDate = new Date(claim.physical_examination_date);
        return [physicalExaminationDate, physicalExaminationDate];
      }
      default: {
        if (claim?.event_date == null) return [];

        const eventDate = new Date(claim.event_date);
        return [eventDate, eventDate];
      }
    }
  }, [claim?.admission_date, claim?.benefit_type, claim?.discharge_date, claim?.event_date, claim?.physical_examination_date]);

  const CertificateRelatedEventFragment = graphql(`
    fragment CertificateRelatedEvent on insured_certificate_related_events {
      effective_date
      event_type
      new_value
      old_value
    }
  `);

  const parseCertificateChangeStatusEvent = useCallback(
    (certificateRelatedEvent: FragmentOf<typeof CertificateRelatedEventFragment>) => {
      if (certificateRelatedEvent.event_type !== InsuredCertificateRelatedEventTypesEnum.StatusChanged) return null;

      switch (certificateRelatedEvent.new_value) {
        case "ACTIVE": {
          if (certificateRelatedEvent.old_value === "LAPSED") {
            return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Effective };
          }

          return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Effective };
        }
        case "LAPSED": {
          if (claimType.fwdMr) {
            return {
              effective_date: add(new Date(certificateRelatedEvent.effective_date), { days: 60 }).toISOString(),
              event_type: eventType.Expiration,
            };
          }

          return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Expiration };
        }
        case "REVERSED": {
          return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Expiration };
        }
        default: {
          return null;
        }
      }
    },
    [claimType.fwdMr],
  );

  const gracePeriodFrom = useMemo(() => {
    if (claim?.grace_period_start_date != null) return new Date(claim.grace_period_start_date);

    if (claim?.start_date == null || insurerClaimType !== INSURER_CLAIM_TYPE.FWD) return null;

    const claimStartDate = new Date(claim.start_date);
    const lapsedDate = claim.insured_certificate.lapsed_date == null ? null : new Date(claim.insured_certificate.lapsed_date);
    if (lapsedDate !== null && lapsedDate.getTime() <= claimStartDate.getTime() && add(lapsedDate, { days: 60 }).getTime() >= claimStartDate.getTime()) {
      return lapsedDate;
    }

    return null;
  }, [claim?.grace_period_start_date, claim?.insured_certificate.lapsed_date, claim?.start_date, insurerClaimType]);

  const certificateEffectivePeriods = useMemo(() => {
    if (claim?.start_date == null) return null;

    const [startDate, endDate] = claimDateRangeByBenefitType;
    if (startDate == null || endDate == null) return null;

    const statusChangeEvents = [
      { effective_date: claim.insured_certificate.issued_at, event_type: eventType.Effective },
      ...orderBy(
        claim.insured_certificate.insured_certificate_related_events.filter((event) => event.event_type === "STATUS_CHANGED"),
        (event) => event.effective_date,
      )
        .map((certificateRelatedEvent) => {
          if (certificateRelatedEvent.event_type !== InsuredCertificateRelatedEventTypesEnum.StatusChanged) return null;

          switch (certificateRelatedEvent.new_value) {
            case "ACTIVE": {
              if (certificateRelatedEvent.old_value === "LAPSED") {
                return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Effective };
              }

              return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Effective };
            }
            case "LAPSED": {
              if (claimType.fwdMr) {
                return {
                  effective_date: add(new Date(certificateRelatedEvent.effective_date), { days: 60 }).toISOString(),
                  event_type: eventType.Expiration,
                };
              }

              return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Expiration };
            }
            case "REVERSED": {
              return { effective_date: certificateRelatedEvent.effective_date, event_type: eventType.Expiration };
            }
            default: {
              return null;
            }
          }
        })
        .filter(Boolean),
      { effective_date: claim.insured_certificate.dued_at, event_type: eventType.Expiration },
    ];

    const certificateEffectivePeriod: { endDate: Date; startDate: Date }[] = [];
    statusChangeEvents.forEach((event, idx) => {
      if (event.event_type === eventType.Effective) {
        const expirationDate = statusChangeEvents.find((item, index) => index > idx && item.event_type === eventType.Expiration)?.effective_date;
        if (expirationDate != null) {
          certificateEffectivePeriod.push({
            endDate: endOfDay(new Date(expirationDate)),
            startDate: startOfDay(new Date(event.effective_date)),
          });
        }
      }
    });

    return certificateEffectivePeriod;
  }, [
    claim?.insured_certificate.dued_at,
    claim?.insured_certificate.insured_certificate_related_events,
    claim?.insured_certificate.issued_at,
    claim?.start_date,
    claimDateRangeByBenefitType,
    claimType.fwdMr,
  ]);

  const isClaimPeriodFullyEffective = useMemo(() => {
    const [startDate, endDate] = claimDateRangeByBenefitType;
    if (startDate == null || endDate == null) return null;

    return (
      certificateEffectivePeriods?.some(
        (effectivePeriod) => effectivePeriod.startDate.getTime() <= startDate.getTime() && effectivePeriod.endDate.getTime() >= endDate.getTime(),
      ) ?? null
    );
  }, [certificateEffectivePeriods, claimDateRangeByBenefitType]);

  const insuredCertificateTimeline: ({ ordinal_number?: null | string } & FromInsuredCertificateFragment)[] = useMemo(() => {
    if (claim == null) return [];
    // @ts-ignore
    const fromCertificateFragmentField = FromInsuredCertificateFragmentDoc.definitions[0]?.selectionSet?.selections.map((i) => i.alias?.value ?? i.name.value);
    // @ts-ignore
    return orderBy(
      [
        pick(claim.insured_certificate, fromCertificateFragmentField),
        ...claim.insured_certificate.from_insured_certificate_courses.map((i) => ({
          ...i.from_insured_certificate,
          ordinal_number: i.ordinal_number,
        })),
        ...claim.insured_certificate.from_insured_certificate_courses.flatMap((i) =>
          i.from_insured_certificate.from_insured_certificate_courses.map((j) => j.from_insured_certificate),
        ),
      ],
      "issued_at",
      "asc",
    ) as ({ ordinal_number?: null | string } & FromInsuredCertificateFragment)[];
  }, [claim]);

  const claimToEffectiveDays = useMemo(() => {
    if (claim?.start_date == null) return null;

    const claimDate = new Date(claim.start_date);

    if (certificateEffectivePeriods == null) return null;

    const effectivePeriodIncludeClaimDate = certificateEffectivePeriods.find((period) => isWithinInterval(claimDate, { end: period.endDate, start: period.startDate }));

    if (effectivePeriodIncludeClaimDate == null) return 0;
    const firstStartDate = insuredCertificateTimeline[0]?.issued_at;
    if (insuredCertificateTimeline.length > 1 && firstStartDate != null) {
      effectivePeriodIncludeClaimDate.startDate = new Date(firstStartDate);
    }
    return differenceInDays(startOfDay(claimDate), startOfDay(effectivePeriodIncludeClaimDate.startDate)) + 1;
  }, [certificateEffectivePeriods, claim?.start_date, insuredCertificateTimeline]);

  const isLifeInsuranceRider = useMemo(() => claimType.fwdMr || claimType.mbalHs || claimType.slvHs, [claimType]);

  const canUpdateSummary = useMemo(() => {
    if (claim?.is_accessible === false) {
      return false;
    }

    if (hasRole([IdentityRolesEnum.DepartmentHead])) return true;

    return ["Declined", "Paid"].includes(status) === false;
  }, [claim?.is_accessible, hasRole, status]);

  const canUpdateExplanation = useMemo(() => {
    if (claim?.is_accessible === false) {
      return false;
    }

    if (hasRole([IdentityRolesEnum.DepartmentHead])) return true;

    return ["Declined", "Paid"].includes(status) === false;
  }, [claim?.is_accessible, hasRole, status]);

  const beforeSaveCopay = useRef<((percentage: number) => void)[]>([]);
  const afterSaveCopay = useRef<((percentage: number) => void)[]>([]);

  const [upsertClaimCasePayment, { loading: upsertClaimCasePaymentLoading }] = usePMutation(UpsertClaimCasePaymentDocument, {
    awaitRefetchQueries: true,
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });

  const [updateClaimCasePayment, { loading: updateClaimCasePaymentLoading }] = usePMutation(UpdateClaimCasePaymentOnClaimProviderDocument, {
    awaitRefetchQueries: true,
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, ClaimBenefitDetailsDocument]),
  });

  let copayType: CopayType = "copay-after-balance";
  if (claimType.fwdMr) copayType = "copay-before-balance";

  if (claimType.healthCare) copayType = "copay-after-balance";

  const { autoDecisionStatus, decisionClaim } = useFwd();
  const saveStatus: (args?: { variables: VariablesOf<typeof UPDATE_CLAIM_MUTATION> }) => Promise<void> = useCallback(
    async (args) => {
      const newStatus = args?.variables.input.status;
      if (newStatus === claim?.status) {
        notificationApi.warning({ key: notificationKey, message: "Trạng thái không thay đổi" });
        return;
      }
      if (claim?.status === "Paid" && !hasRole([IdentityRolesEnum.DepartmentHead])) return;

      const doSaveStatus = () => {
        insertStatusLog({
          variables: {
            object: {
              new_value: newStatus,
              old_value: claim?.status,
              record_id: claimCaseId,
              table_name: "CLAIM_CASES",
            },
          },
        });
        return updateClaimStatusMutation(args);
      };
      if (
        claimType.fwdMr &&
        newStatus != null &&
        ["Approved", "ApprovedReceivedDoc", "ApprovedWaitingDoc", "Declined", "Suspension"].includes(newStatus) &&
        claim?.status !== "NEW_DOCUMENT_ON_SUSPENDED"
      ) {
        if (autoDecisionStatus.isDecidable === false && claim?.is_direct_billing === false) {
          autoDecisionStatus.reasons.forEach((reason) => notificationApi.error({ key: notificationKey, message: reason }));
          return;
        }
        const willChangeStatus = await confirmChangeStatusModal({
          claimCode: claim?.code,
          claimStatues: globalData?.claim_case_statuses,
          toChangeStatus: newStatus,
        });
        if (willChangeStatus) {
          await doSaveStatus();
        }
      } else {
        await doSaveStatus();
      }
      if (newStatus != null && claim?.id != null) {
        await (newStatus === "Pending"
          ? updateClaimTat({ claimId: claim.id, forFirstPending: true, overwriteCache: true, showNotification: false })
          : updateClaimTat({ claimId: claim.id, overwriteCache: true, showNotification: false }));
      }
    },
    [
      claim,
      hasRole,
      claimType.fwdMr,
      updateClaimTat,
      notificationApi,
      notificationKey,
      insertStatusLog,
      claimCaseId,
      updateClaimStatusMutation,
      autoDecisionStatus.isDecidable,
      autoDecisionStatus.reasons,
      globalData?.claim_case_statuses,
    ],
  );

  // unsafe in case globalData?.claim_case_statuses fails to load
  const statusesMap = {} as Record<ClaimStatus, string>;

  globalData?.claim_case_statuses.forEach(({ comment, value }) => {
    statusesMap[value] = comment;
  });

  const loading = false;

  const saveCopay: ClaimContextType["copayAndBonus"]["saveCopay"] = useCallback(
    (percentage, deductibleValue) => {
      if (claim == null || upsertClaimCasePaymentLoading) return null;

      if (["Approved", "Paid"].includes(claim.status)) return null;

      beforeSaveCopay.current.forEach((fn) => fn(percentage));
      if (claim.claim_case_payment == null) {
        return upsertClaimCasePayment({
          onCompleted: async () => {
            await refetch();
            notificationApi.success({
              message: "Cập nhật Phần trăm Copay thành công",
            });
            const uniqFns = uniqBy(afterSaveCopay.current, (fn) => `${fn}`);
            uniqFns.forEach((fn) => fn(percentage));
          },
          variables: {
            object: {
              claim_case_id: claim.id,
              co_payment_ratio: percentage,
              deductible_amount: deductibleValue,
            },
          },
        });
      }
      return updateClaimCasePayment({
        onCompleted: async () => {
          await refetch();
          notificationApi.success({
            message: "Cập nhật Phần trăm Copay thành công",
          });
          const uniqFns = uniqBy(afterSaveCopay.current, (fn) => `${fn}`);
          uniqFns.forEach((fn) => fn(percentage));
        },
        variables: {
          id: claim.id,
          object: {
            co_payment_ratio: percentage,
            deductible_amount: deductibleValue,
          },
        },
      });
    },
    [claim, notificationApi, refetch, updateClaimCasePayment, upsertClaimCasePayment, upsertClaimCasePaymentLoading],
  );

  const saveDeductible = useCallback(
    (deductibleValue: number) => {
      if (claim?.claim_case_payment == null) {
        upsertClaimCasePayment({
          onCompleted: async () => {
            await refetch();
            notificationApi.success({
              message: `Cập nhật deductible ${utils.formatCurrency(deductibleValue)} thành công`,
            });
          },
          variables: {
            object: {
              claim_case_id: claim?.id,
              deductible_amount: deductibleValue,
            },
          },
        });
      } else {
        updateClaimCasePayment({
          onCompleted: async () => {
            await refetch();
            notificationApi.success({
              message: `Cập nhật deductible ${utils.formatCurrency(deductibleValue)} thành công`,
            });
          },
          variables: {
            id: claim.id,
            object: {
              deductible_amount: deductibleValue,
            },
          },
        });
      }
    },
    [claim?.claim_case_payment, claim?.id, notificationApi, refetch, updateClaimCasePayment, upsertClaimCasePayment],
  );

  const singleShareToInsurerSignOffFeature = useMemo(() => claimType.slvHs === true, [claimType.slvHs]);

  const contextValue: ClaimContextType = useMemo(
    () => ({
      allowAssessment,
      allowDetailInput,
      claim,
      claimCaseRoutePath,
      claimDateRanges,
      claimToEffectiveDays,
      claimType,
      copayAndBonus: {
        bonusRatio,
        copayRatio: claim?.claim_case_payment?.co_payment_ratio,
        doesPlanHaveCopay: claimType.slvHs === false,
        enable: enableCopay,
        registerAfterSaveCopayFn: (fn) => {
          afterSaveCopay.current.push(fn);
        },
        registerBeforeSaveCopayFn: (fn) => {
          beforeSaveCopay.current.push(fn);
        },
        saveCopay,
        saveDeductible,
        setBonusRatio,
        setCopayRatio,
        setEnable: setEnableCopay,
        type: copayType,
      },
      correspondenceSettings: {
        enableAutoCorrespondence: enableAutoCorrespondenceData?.getCustomSetting?.booleanValue ?? null,
        loadingCorrespondenceCustomSetting: loadingCorrespondenceCustomSetting || updatingClaimAutoCorrespondence,
        toggleClaimAutoCorrespondence,
      },
      fwdClaim: {
        updateFwdClaim: updateFwdClaimMutation,
        updatingFwdClaim,
      },
      gracePeriodFrom,
      insuredCertificateTimeline,
      insurerClaimType,
      isClaimHistory: claim?.is_history === true,
      isClaimPeriodFullyEffective,
      isDetailFormFilled,
      isLifeInsuranceRider,
      loading,
      notification: { api: notificationApi, key: notificationKey },
      permissions: {
        canAssess: allowAssessment,
        canUpdateClaimInput: allowDetailInput,
        canUpdateExplanation,
        canUpdateSummary,
        requireCopayAssessment,
      },
      refetch,
      refetchQuery: {
        query: CLAIM_DETAIL_QUERY,
        variables: {
          claimId: claim?.claim_case_id,
        },
      },
      registerSaveStatusCallback,
      saveStatusCallbacks: saveStatusCallbacks.current,
      setHasUnsavedChange,
      singleShareToInsurerSignOffFeature,
      slackChannel: SLACK_CHANNELS[`${insurerClaimType}${claim?.is_direct_billing === true ? "_DB" : ""}`],
      status: { saveStatus, saveStatusLoading, setStatus, status },
      statusesMap,
      tat: claim?.tatInHourUpToNow,
      updateClaimMutation: updateClaimStatusMutation,
      validateEventDateByBenefitType,
    }),
    [
      allowAssessment,
      allowDetailInput,
      claim,
      claimCaseRoutePath,
      claimDateRanges,
      claimToEffectiveDays,
      claimType,
      bonusRatio,
      enableCopay,
      saveCopay,
      saveDeductible,
      copayType,
      enableAutoCorrespondenceData?.getCustomSetting?.booleanValue,
      loadingCorrespondenceCustomSetting,
      updatingClaimAutoCorrespondence,
      toggleClaimAutoCorrespondence,
      updateFwdClaimMutation,
      updatingFwdClaim,
      gracePeriodFrom,
      insuredCertificateTimeline,
      insurerClaimType,
      singleShareToInsurerSignOffFeature,
      isClaimPeriodFullyEffective,
      isDetailFormFilled,
      isLifeInsuranceRider,
      loading,
      notificationApi,
      notificationKey,
      canUpdateExplanation,
      canUpdateSummary,
      requireCopayAssessment,
      refetch,
      registerSaveStatusCallback,
      setHasUnsavedChange,
      saveStatus,
      saveStatusLoading,
      status,
      statusesMap,
      updateClaimStatusMutation,
      validateEventDateByBenefitType,
    ],
  );
  return (
    <ClaimContext.Provider value={contextValue} {...props}>
      {contextHolder}
      {/* eslint-disable-next-line unicorn/consistent-destructuring, react/destructuring-assignment */}
      {props.children}
    </ClaimContext.Provider>
  );
};

export { ClaimProvider };
export default ClaimProvider;
