import type ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import type { ResultOf } from "sdk/v2/graphql";

import { Col, Popconfirm, Row, Tooltip, Typography } from "antd";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { isGreaterThanZero } from "libs/hidash";
import utils from "libs/utils";
import { round } from "lodash";
import { Eraser, Info } from "lucide-react";

import AssessmentSummary from "../../AssessmentSummary";
import styles from "./ClaimBenefitVer3.module.css";

const { Text, Title } = Typography;

const Summary = ({ claimBenefitDetails, readOnly = false }: { claimBenefitDetails: ResultOf<typeof ClaimBenefitDetailsDocument>["claimBenefitDetails"]; readOnly?: boolean }) => {
  const { claim, copayAndBonus } = useClaim();

  const leftCol = 18;
  const rightCol = 6;

  if (copayAndBonus.type === "copay-before-balance") {
    return (
      <Row align="stretch" gutter={24}>
        <Col span={12}>
          <AssessmentSummary readOnly={readOnly} />
        </Col>
        <Col span={12}>
          <Row className={styles.stats}>
            <Col span={leftCol}>Tổng tiền yêu cầu</Col>
            <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.requestedAmount)}</Col>
          </Row>
          <Row className={styles.stats}>
            <Col span={leftCol}>Không chi trả (ngoài phạm vi BH)</Col>
            <If condition={claimBenefitDetails.nonPaidAmount != null} else={<Col span={6}>Chưa có kết quả</Col>}>
              <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.nonPaidAmount ?? 0)}</Col>
            </If>
          </Row>
          <Row className={styles.stats}>
            <Col span={leftCol}>Số tiền trong phạm vi bảo hiểm</Col>
            <Col span={rightCol}>{claimBenefitDetails.coverageAmount == null ? "Chưa có kết quả" : utils.formatCurrency(claimBenefitDetails.coverageAmount)}</Col>
          </Row>
          <Row className={styles.stats}>
            <Col span={leftCol}>Đồng chi trả</Col>
            <Col span={rightCol}>{claimBenefitDetails.coPaymentAmount == null ? "Chưa có kết quả" : utils.formatCurrency(0 - round(claimBenefitDetails.coPaymentAmount))}</Col>
          </Row>
          {claimBenefitDetails.deductibleAmount == null ? null : (
            <Row className={styles.stats}>
              <Col span={leftCol}>
                <If condition={isGreaterThanZero(claimBenefitDetails.deductibleAmount)}>
                  <Popconfirm
                    description="Nếu đã có số tiền chi trả, bạn cần lưu lại quyền lợi để hủy số tiền khấu trừ"
                    onConfirm={() => {
                      copayAndBonus.saveDeductible(0);
                    }}
                    title="Bạn có muốn bỏ số tiền khấu trừ?"
                  >
                    <Eraser className="cursor-pointer" size={12} />
                  </Popconfirm>
                </If>
                Số tiền khấu trừ
              </Col>
              <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.deductibleAmount)}</Col>
            </Row>
          )}
          <Row className={styles.stats}>
            <Col span={leftCol}>Tổng tiền dự kiến trả</Col>
            <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.estimatedPaidAmount)}</Col>
          </Row>
          <Row className={styles.stats}>
            <Col span={leftCol}>
              <Title className="mt-0 mb-0" level={4}>
                Tổng tiền thực trả
              </Title>
            </Col>
            <Col span={rightCol}>{claimBenefitDetails.actualPaidAmount == null ? "Chưa có kết quả" : utils.formatCurrency(round(claimBenefitDetails.actualPaidAmount))}</Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Row align="stretch" gutter={24}>
      <Col span={12}>
        <AssessmentSummary />
      </Col>
      <Col span={12}>
        <Row className={styles.stats}>
          <Col span={leftCol}>
            Tổng tiền yêu cầu
            <If condition={claimBenefitDetails.requestedAmount !== claim?.request_amount}>
              <Tooltip
                title={`Tổng tiền yêu cầu ${utils.formatCurrency(claimBenefitDetails.requestedAmount)} chưa bằng số tiền yêu cầu ${utils.formatCurrency(claim?.request_amount)}`}
              >
                <Info className="anticon margin-left-5" color="red" size={14} />
              </Tooltip>
            </If>
          </Col>
          <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.requestedAmount)}</Col>
        </Row>
        <Row className={styles.stats}>
          <Col span={leftCol}>Số tiền trong phạm vi bảo hiểm</Col>
          <Col span={rightCol}>{claimBenefitDetails.coverageAmount == null ? "Chưa có kết quả" : utils.formatCurrency(claimBenefitDetails.coverageAmount)}</Col>
        </Row>
        <Row className={styles.stats}>
          <Col span={leftCol}>Không chi trả - ngoài phạm vi BH</Col>
          <If
            condition={claimBenefitDetails.nonPaidAmount != null}
            else={
              <Col span={rightCol}>
                <Text>Chưa có kết quả</Text>
              </Col>
            }
          >
            <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.nonPaidAmount ?? 0)}</Col>
          </If>
        </Row>
        <Row className={styles.stats}>
          <Col span={leftCol}>Không chi trả - vượt hạn mức</Col>
          <If
            condition={claimBenefitDetails.shortfallAmount != null}
            else={
              <Col span={rightCol}>
                <Text>Chưa có kết quả</Text>
              </Col>
            }
          >
            <Col span={rightCol}>{utils.formatCurrency(0 - (claimBenefitDetails.shortfallAmount ?? 0))}</Col>
          </If>
        </Row>

        <Row className={styles.stats}>
          <Col span={leftCol}>Tổng tiền dự kiến trả</Col>
          <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.estimatedPaidAmount)}</Col>
        </Row>
        {claimBenefitDetails.deductibleAmount == null ? null : (
          <Row className={styles.stats}>
            <Col span={leftCol}>Số tiền khấu trừ</Col>
            <Col span={rightCol}>{utils.formatCurrency(claimBenefitDetails.deductibleAmount)}</Col>
          </Row>
        )}
        <If condition={copayAndBonus.doesPlanHaveCopay}>
          <Row className={styles.stats}>
            <Col span={leftCol}>Đồng chi trả</Col>
            <Col span={rightCol}>{utils.formatCurrency(0 - round(claimBenefitDetails.coPaymentAmount ?? 0))}</Col>
          </Row>
        </If>
        <If condition={claimBenefitDetails.customAmountReasonType != null && claimBenefitDetails.customAmount != null}>
          <Row className={styles.stats}>
            <Col span={leftCol}>{claimBenefitDetails.customAmountReasonType?.comment}</Col>
            <Col span={rightCol}>{utils.formatCurrency(0 - (claimBenefitDetails.customAmount ?? 0))}</Col>
          </Row>
        </If>
        <If condition={claim?.claim_case_payment?.applied_premium_debt_amount != null}>
          <Row className={styles.stats}>
            <Col span={leftCol}>Số tiền nợ phí áp dụng</Col>
            <Col span={rightCol}>{utils.formatCurrency(0 - (claim?.claim_case_payment?.applied_premium_debt_amount ?? 0))}</Col>
          </Row>
        </If>
        <Row className={styles.stats}>
          <Col span={leftCol}>
            <Title className="mt-0 mb-0" level={4}>
              Tổng tiền thực trả
            </Title>
          </Col>
          <Col span={rightCol}>
            <If condition={(claimBenefitDetails.actualPaidAmount ?? 0) - Math.floor(claim?.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount ?? 0) > 0}>
              <Tooltip
                title={`Số tiền tổng là ${utils.formatCurrency(claim?.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount)}, lẻ ${utils.formatCurrency(
                  (
                    (claim?.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount ?? 0) -
                    Math.floor(claim?.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount ?? 0)
                  ).toFixed(1),
                )}. Hệ thống làm tròn lên thành ${utils.formatCurrency(round(claimBenefitDetails.actualPaidAmount ?? 0))}`}
              >
                <Info size={12} />{" "}
              </Tooltip>
            </If>
            {claimBenefitDetails.actualPaidAmount == null ? "Chưa có kết quả" : utils.formatCurrency(round(claimBenefitDetails.actualPaidAmount))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Summary;
