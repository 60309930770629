import { gql } from "@apollo/client";
import usePQuery from "contexts/usePQuery";
import { IdentityRolesEnum } from "sdk/gql/types";

import { FwdClaimCaseStatusDocument, FwdDeclineCodesDocument, FwdMaritalStatusesDocument, FwdPendingCodesListDocument, FwdPolicyStatusesDocument } from "./usePublicData.generated";

gql`
  query fwdClaimCaseStatus {
    fwd_claim_case_statuses {
      comment
      value
    }
  }
  query fwdDeclineCodes {
    fwd_decline_codes {
      comment
      value
    }
  }
  query fwdMaritalStatuses {
    fwd_marital_statuses {
      comment: name
      value: code
    }
  }
  query fwdPendingCodesList {
    fwd_pending_codes {
      name
      code
      ppy_pending_code
    }
  }

  query fwdPolicyStatuses {
    fwd_policy_statuses {
      id: value
      comment
      value
    }
  }

  # query pendingCodeStatuses {
  #   claim_pending_code_statuses {
  #     comment
  #     value
  #   }
  # }
`;

const queryRole = { role: "User" };

type ArrayStruct = "array";
type ObjectStruct = "object";
type ReturnStructure = ArrayStruct | ObjectStruct | undefined;
type ParamType = ReturnStructure | undefined;
type ArrayReturn<Extension extends unknown = {}> = ({ comment: string; value: string } & Extension)[];
type NonNullableStructureRes<Structure extends ReturnStructure, Extension extends unknown = {}> = Structure extends ObjectStruct ? Record<string, string> : ArrayReturn<Extension>;
type StructRes<Structure extends ParamType, Extension extends unknown = {}> = Structure extends undefined
  ? ArrayReturn<Extension>
  : NonNullableStructureRes<Exclude<Structure, undefined>, Extension>;

const mapArrayToObject = (items: { comment: string; value: string }[] | undefined) =>
  items?.reduce((all, current) => ({ ...all, [current.value]: current.comment }), {} as Record<string, string>) || {};
const handleOutputData = <Struct extends ReturnStructure, ExtensionData extends unknown = {}>(
  data: ({ comment: string; value: string } & ExtensionData)[],
  structure: Struct,
): NonNullableStructureRes<Struct, ExtensionData> => {
  if (structure === "array") return data as NonNullableStructureRes<Struct, ExtensionData>;
  return mapArrayToObject(data) as NonNullableStructureRes<Struct, ExtensionData>;
};

export const useFWDPolicyStatus = <Structure extends ReturnStructure>(structure?: Structure): StructRes<Structure> | undefined => {
  const { data } = usePQuery(FwdPolicyStatusesDocument, queryRole);
  if (data === undefined) return data;
  if (structure === undefined) return handleOutputData(data.fwd_policy_statuses, "array") as StructRes<Structure>;
  return handleOutputData(data.fwd_policy_statuses, structure) as StructRes<Structure>;
};

export const useFwdClaimCaseStatus = <Structure extends ReturnStructure>(structure?: Structure): StructRes<Structure> | undefined => {
  const { data } = usePQuery(FwdClaimCaseStatusDocument, queryRole);
  if (data === undefined) return undefined;
  if (structure === undefined) return handleOutputData(data.fwd_claim_case_statuses, "array") as StructRes<Structure>;
  return handleOutputData(data.fwd_claim_case_statuses, structure === undefined ? "array" : structure) as StructRes<Structure>;
};
export const useFwdDeclineCodes = <Structure extends ReturnStructure>(structure?: Structure): StructRes<Structure> | undefined => {
  const { data } = usePQuery(FwdDeclineCodesDocument, queryRole);
  if (data === undefined) return undefined;
  if (structure === undefined) return handleOutputData(data.fwd_decline_codes, "array") as StructRes<Structure>;
  return handleOutputData(data.fwd_decline_codes, structure) as StructRes<Structure>;
};

export const useMaritalStatus = <Structure extends ReturnStructure>(structure?: Structure): StructRes<Structure> | undefined => {
  const { data } = usePQuery(FwdMaritalStatusesDocument, queryRole);
  if (data === undefined) return undefined;
  if (structure === undefined) return handleOutputData(data.fwd_marital_statuses, "array") as StructRes<Structure>;
  return handleOutputData(data.fwd_marital_statuses, structure) as StructRes<Structure>;
};

export const useFwdPendingCodes = <Structure extends ReturnStructure>(structure?: Structure): StructRes<Structure, { ppy_pending_code: string } | undefined> | undefined => {
  const { data } = usePQuery(FwdPendingCodesListDocument, queryRole);
  if (data === undefined) return undefined;
  return handleOutputData(
    data.fwd_pending_codes.map((item) => ({ comment: item.name, ppy_pending_code: item.ppy_pending_code, value: item.code })),
    structure,
  ) as StructRes<Structure, { ppy_pending_code: string } | undefined>;
};

// export const useFwdPendingCodeStatuses = (structure: StatusStructure = "array") => {
//   const { usePQuery } = useAuth();
//   const { data } = usePQuery(usePendingCodeStatusesQuery, queryRole);
//   return handleOutputData(data?.claim_pending_code_statuses, structure);
// };
