import type { Value } from "firebase/remote-config";
import type { FC, ReactNode } from "react";

import { fetchAndActivate, getAll } from "firebase/remote-config";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type EnableDirectBillingQRCodeMedicalProvider = {
  id: string;
  name: string;
};

type FirebaseValuesContext = {
  enableDirectBillingQRCodeMedicalProviders: EnableDirectBillingQRCodeMedicalProvider[];
  isUnderMaintenance: boolean | null;
};

const { VITE_STAGE } = import.meta.env;

type Props = { children: ReactNode } & Record<string, unknown>;

const FirebaseRemoteConfigValuesContext = createContext<FirebaseValuesContext | null>(null);

export const FirebaseRemoteConfigProvider: FC<Props> = ({ children }) => {
  const [firebaseValues, setFirebaseValues] = useState<Record<string, Value>>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (import.meta.env.PROD === false) return;
      const { remoteConfig } = await import("firebase");
      const fetchAndActivateFirebaseValues = () => {
        fetchAndActivate(remoteConfig)
          .then(() => {
            const allValues = getAll(remoteConfig);
            return {
              ...allValues,
            };
          })
          .then((remoteValues) => {
            setFirebaseValues((currentValues) => ({
              ...currentValues,
              ...remoteValues,
            }));
          })
          .catch((error) => console.error(error));
      };
      fetchAndActivateFirebaseValues();
    })();
  }, []);

  const value: FirebaseValuesContext = useMemo(() => {
    const data: FirebaseValuesContext = {
      enableDirectBillingQRCodeMedicalProviders: [],
      isUnderMaintenance: null,
    };

    if (firebaseValues?.HOSPITAL_PORTAL_FEATURE_FLAGS != null) {
      const source = firebaseValues.HOSPITAL_PORTAL_FEATURE_FLAGS.getSource();
      const val = JSON.parse(firebaseValues.HOSPITAL_PORTAL_FEATURE_FLAGS.asString()) as {
        enableDirectBillingQRCodeMedicalProviders: EnableDirectBillingQRCodeMedicalProvider[];
      };
      data.enableDirectBillingQRCodeMedicalProviders = source === "remote" ? val.enableDirectBillingQRCodeMedicalProviders : [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const KEY_MAINTENANCE = `IS_UNDER_MAINTENANCE${VITE_STAGE == null ? "" : `_${VITE_STAGE.toUpperCase()}`}`;
    const maintenanceValue = firebaseValues?.[KEY_MAINTENANCE];
    if (maintenanceValue != null) {
      const source = maintenanceValue.getSource();
      const val = maintenanceValue.asBoolean();
      data.isUnderMaintenance = source === "remote" ? val : false;
    }

    return data;
  }, [firebaseValues]);

  useEffect(() => {
    const { pathname } = location;
    if (value.isUnderMaintenance === true && pathname !== "/maintenance") {
      navigate("/maintenance");
    }
    if (value.isUnderMaintenance !== true && pathname === "/maintenance") {
      navigate("/");
    }
  }, [value, navigate, location]);

  return <FirebaseRemoteConfigValuesContext.Provider value={value}>{children}</FirebaseRemoteConfigValuesContext.Provider>;
};

export const useFirebaseRemoteConfig = () => useContext(FirebaseRemoteConfigValuesContext);
