import type { FormInstance } from "antd";

import { Form, Input, Popover, Space } from "antd";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { ScrollIcon } from "lucide-react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;

const ITEMS = ["Nội khoa", "Thuốc", "Phẫu thuật", "Cạo vôi răng", "Trám răng", "Nhổ răng", "cần gì thêm báo lên #product-care nha"];

const TreatmentMethodField = ({ form, name }: Props) => {
  const { claimType } = useClaim();
  return (
    <Form.Item
      label={
        <Space>
          Phương thức điều trị
          <Popover
            content={
              <div>
                <ul>
                  {ITEMS.map((i) => (
                    <li className="cursor-pointer" key={i} onClick={() => form.setFieldValue(name, i)}>
                      {i}
                    </li>
                  ))}
                </ul>
              </div>
            }
            title="Phương thức điều trị"
          >
            <ScrollIcon size={14} />
          </Popover>
        </Space>
      }
      name={name}
      rules={[
        {
          message: "Vui lòng nhập phương thức điều trị",
          required: claimType.healthCare,
        },
      ]}
    >
      <Input placeholder="Nhập phương thức điều trị" size="large" />
    </Form.Item>
  );
};
export default TreatmentMethodField;
