import useScrollToAnchor from "app/common/hooks/useScrollToAnchor";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { ClipboardSignature, History, Newspaper, Pencil, PersonStanding, Pill, Wallet2 } from "lucide-react";
import { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

const useClaimShortcuts = () => {
  const { claimType } = useClaim();

  const insuredInfoRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);
  const allProductsHistoryRef = useRef<HTMLDivElement>(null);
  const detailFormRef = useRef<HTMLDivElement>(null);
  const certificateBalanceRef = useRef<HTMLDivElement>(null);
  const assessmentRef = useRef<HTMLDivElement>(null);
  const pendingRef = useRef<HTMLDivElement>(null);
  const signOffRef = useRef<HTMLDivElement>(null);

  const icons = [
    {
      hotkey: "alt+q",
      icon: <PersonStanding className="cursor-pointer" size={20} />,
      link: "#insured_info",
      ref: insuredInfoRef,
      tooltip: "Thông tin người được bảo hiểm",
    },
    {
      hotkey: "alt+w",
      icon: <History className="cursor-pointer" size={20} />,
      link: "#history",
      ref: historyRef,
      tooltip: "Lịch sử bồi thường",
    },
    {
      hotkey: "alt+e",
      icon: <Pill className="cursor-pointer" size={20} />,
      link: "#detail_form",
      ref: detailFormRef,
      tooltip: "Thông tin điều trị",
    },
    claimType.healthCare
      ? {
          hotkey: "alt+r",
          icon: <Wallet2 className="cursor-pointer" size={20} />,
          link: "#certificate_balance",
          ref: certificateBalanceRef,
          tooltip: "Hạn mức",
        }
      : null,
    {
      hotkey: "alt+a",
      icon: <Pencil className="cursor-pointer" size={20} />,
      link: "#assessment",
      ref: assessmentRef,
      tooltip: "Điền quyền lợi",
    },
    {
      hotkey: "alt+s",
      icon: <Newspaper className="cursor-pointer" size={20} />,
      link: "#pending",
      ref: pendingRef,
      tooltip: "Pending",
    },
    {
      hotkey: "alt+d",
      icon: <ClipboardSignature className="cursor-pointer" size={20} />,
      link: "#sign_off",
      ref: signOffRef,
      tooltip: "Sign off",
    },
  ].filter(Boolean);

  useScrollToAnchor();

  useHotkeys(icons.map((i) => i.hotkey).filter(Boolean), (a, { alt, keys }) => {
    if (alt === true && keys != null) {
      const item = icons.find((i) => i.hotkey === `alt+${keys[0]}`);
      item?.ref.current?.scrollIntoView({ behavior: "instant" });
    }
  });

  return {
    icons,
    refs: {
      allProductsHistoryRef,
      assessmentRef,
      certificateBalanceRef,
      detailFormRef,
      historyRef,
      insuredInfoRef,
      pendingRef,
      signOffRef,
    },
  };
};

export default useClaimShortcuts;
