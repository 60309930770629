import type { FormInstance } from "antd";
import type { ClaimNotesFragment } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/SignOff";

import { Button, Checkbox, Col, Empty, Form, Input, List, message, notification, Popconfirm, Row, Spin, Tag, Tooltip, Typography } from "antd";
import { SEND_EMAIL } from "app/common/CommonQueries";
import Avatar from "app/common/components/Avatar";
import ExternalLink from "app/common/components/ExternalLink";
import If from "app/common/components/If";
import { PORTAL_PATH } from "app/portal/config/paths";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useSummary from "app/portal/screens/ClaimPortal/ClaimContext/useSummary";
import { papaya } from "config/colors";
import { useAuth } from "contexts/AuthContext";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import useBuildClaimLinksFromText from "hooks/useBuildClaimLinksFromText";
import findEmailCodeFromText from "libs/findEmailCodeFromText";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import { isGreaterThanZero } from "libs/hidash";
import { utils } from "libs/utils";
import { isEmpty, sumBy, uniq, uniqBy } from "lodash";
import { Mail, Navigation, NavigationOff, Trash, Wand2 } from "lucide-react";
import { type FC, memo } from "react";
import { generatePath, useSearchParams } from "react-router-dom";
import { type FragmentOf, graphql, type ResultOf } from "sdk/v2/graphql";

import { INSERT_CLAIM_NOTE_MUTATION, UPDATE_SIGN_OFF_MUTATION } from "./graphql/mutations";
import { NEW_SIGN_OFF_CORRESPONDENCE_FOR_MEDICAL_PROVIDER } from "./graphql/queries";
import styles from "./SignOff.module.less";

const PreviewCorrespondenceDocument = graphql(`
  query SignOffPreviewCorrespondence($previewCorrespondenceInput: PreviewCorrespondenceInput!) {
    berry {
      previewCorrespondence(previewCorrespondenceInput: $previewCorrespondenceInput) {
        apiType
        content
        correspondenceId
        correspondenceLevel
        messageType
        phones
        subject
        templateByLocaleId
        templateId
        templateName
        type
        attachmentTemplates {
          id
          content
          locale
          subject
          type
        }
        bcc {
          name
          email
        }
        cc {
          name
          email
        }
        from {
          name
          email
        }
        replyTo {
          name
          email
        }
        template {
          id
          template_receivers {
            id
            string_value
          }
        }
        to {
          name
          email
        }
        uploadedAttachments {
          fileId
          fileName
          path
        }
      }
    }
  }
`);

const { TextArea } = Input;

type SignOffItemProps = {
  chatTabKey: keyof typeof SIGN_OFF_TYPE;
  claimCaseId: string;
  data?: FragmentOf<typeof ClaimNotesFragment>["sign_off_claim_notes"] | null;
};
const SIGN_OFF_TYPE = {
  MEDICAL_PROVIDER: ["MEDICAL_PROVIDER"],
  PAPAYA: ["SignOff", "LaNote"],
} as const;
const SLV_LIMIT_CHARACTER_SIGN_OFF = 2000;

const EmailObjectsFromEmailCodesDocument = graphql(`
  query EmailObjectsFromEmailCodes($where: correspondence_emails_bool_exp!) {
    correspondence_emails(where: $where) {
      id
      code
      subject
    }
  }
`);

const AttachedEmails = memo(({ form }: { form: FormInstance }) => {
  const watchEmails = Form.useWatch<null | ResultOf<typeof EmailObjectsFromEmailCodesDocument>["correspondence_emails"]>("emails", form) ?? [];

  return (
    <Form.Item name="emails">
      <If condition={watchEmails.length > 0}>
        <List
          dataSource={watchEmails}
          header="Các emails đính kèm:"
          renderItem={(email) => (
            <List.Item
              extra={
                <Button
                  icon={<Trash size={14} />}
                  onClick={() => {
                    form.setFieldValue(
                      "emails",
                      watchEmails.filter((e) => e.id !== email.id),
                    );
                  }}
                  type="text"
                />
              }
            >
              <ExternalLink to={generatePath(PORTAL_PATH.MAILBOX.EMAIL_DETAIL_DEFAULT, { emailId: email.id })}>
                {email.code} - {email.subject}
              </ExternalLink>
            </List.Item>
          )}
        />
      </If>
    </Form.Item>
  );
});

type SendSignOffFormValues = {
  content: string;
  emails?: ResultOf<typeof EmailObjectsFromEmailCodesDocument>["correspondence_emails"];
  share_to_insurer: boolean;
};

const SignOffItem: FC<SignOffItemProps> = ({ chatTabKey, claimCaseId, data: fragmentData }) => {
  const [searchParams] = useSearchParams({ email_id: "" });
  const emailId = searchParams.get("email_id");
  const { hasRole, user } = useAuth();
  const [signOffForm] = Form.useForm();
  const shareToInsurerCheck = Form.useWatch("share_to_insurer", signOffForm);

  const data = fragmentData;
  const { buildCopyableText } = useSummary();
  const { claim, claimCaseRoutePath, claimType, singleShareToInsurerSignOffFeature: insurerSignOffFeature } = useClaim();
  const claimNotes = data
    ?.filter((item) => SIGN_OFF_TYPE[chatTabKey].includes(item.type) === true)
    .filter((item) => {
      if (insurerSignOffFeature === false) {
        return true;
      }
      if (item.user?.roles.map((r) => r.role).includes("ClaimAssessor") === false) {
        return true;
      }
      return item.share_to_insurer === false;
    });

  const [getNewSignOffCorrespondenceForMedicalProvider] = usePLazyQuery(NEW_SIGN_OFF_CORRESPONDENCE_FOR_MEDICAL_PROVIDER);
  const [deleteSignOffMutation] = usePMutation(UPDATE_SIGN_OFF_MUTATION, {
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });
  const [sendEmailMutation] = usePMutation(SEND_EMAIL);
  const [previewCorrespondence] = usePLazyQuery(PreviewCorrespondenceDocument);

  const [insertSignOffMutation, { loading: insertLoading }] = usePMutation(INSERT_CLAIM_NOTE_MUTATION, {
    onCompleted: () => {
      message.success("Gửi Sign Off message thành công");
    },
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });
  const [updateSignOffMutation, { loading: updateLoading }] = usePMutation(UPDATE_SIGN_OFF_MUTATION, {
    onCompleted: () => {
      message.success("Cập nhật Sign Off message thành công");
    },
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });
  usePQuery(
    EmailObjectsFromEmailCodesDocument,
    emailId == null || isEmpty(emailId) === true
      ? { skip: true }
      : {
          onCompleted: (emailObject) => {
            signOffForm.setFieldValue("emails", emailObject.correspondence_emails);
          },
          variables: {
            where: {
              id: { _eq: emailId },
            },
          },
        },
  );
  const [getEmailObjects] = usePLazyQuery(EmailObjectsFromEmailCodesDocument);

  const sendSignOff = async (values: SendSignOffFormValues) => {
    if (isEmpty(values.content) && isEmpty(values.emails)) {
      notification.error({ description: "Nội dung không được để trống", message: "Có lỗi" });
      return;
    }

    const { data: insertSignOffData, errors } = await insertSignOffMutation({
      onCompleted: () => {
        signOffForm.resetFields();
      },
      variables: {
        input: {
          claim_case_id: claimCaseId,
          content: values.content,
          email_ids: values.emails?.map((e) => e.id),
          share_to_insurer: values.share_to_insurer === true,
          type: chatTabKey === "PAPAYA" ? "SignOff" : "MEDICAL_PROVIDER",
          username: user.name,
        },
      },
    });

    if (insertSignOffData == null || isEmpty(errors) === false) {
      notification.error({ description: "Gửi Sign Off message thất bại", message: "Có lỗi" });
      return;
    }
    const tpaCompanyId = claim?.insured_certificate.policy.tpa_id;
    if (tpaCompanyId == null || chatTabKey !== "MEDICAL_PROVIDER") return;

    const { data: newSignOffCorrespondenceForMedicalProviderData } = await getNewSignOffCorrespondenceForMedicalProvider({
      variables: {
        tpaCompanyId,
      },
    });

    const newSignOffCorrespondenceForMedicalProvider = newSignOffCorrespondenceForMedicalProviderData?.company_correspondences[0];
    if (newSignOffCorrespondenceForMedicalProvider == null) {
      notification.warning({
        description: "Mẫu thư thông báo cho cơ sở y tế khi thêm sign-off chưa được cài đặt, vui lòng kiểm tra lại",
        message: "Chưa gửi thông báo tới cơ sở y tế",
      });
      return;
    }

    const { data: previewCorrespondenceData } = await previewCorrespondence({
      variables: {
        previewCorrespondenceInput: {
          claimCaseId,
          correspondenceId: newSignOffCorrespondenceForMedicalProvider.id,
        },
      },
    });

    const template = previewCorrespondenceData?.berry?.previewCorrespondence?.[0];
    if (template == null || (template.to == null && template.cc == null && template.bcc == null) || template.from == null || template.subject == null || template.content == null) {
      notification.error({
        description: `Thông tin gửi thư cho cơ sở y tế chưa phù hợp, chi tiết: ${JSON.stringify({
          bcc: template?.bcc,
          cc: template?.cc,
          from: template?.from,
          isContentNull: template?.content == null,
          subject: template?.subject,
          to: template?.to,
        })}`,
        message: "Lỗi gửi thư",
      });
      return;
    }

    sendEmailMutation({
      variables: {
        emailInput: {
          bcc: template.bcc?.filter(Boolean),
          cc: template.cc?.filter(Boolean),
          content: template.content,
          correspondenceObjects: {
            claimCaseId,
            companyCorrespondenceId: newSignOffCorrespondenceForMedicalProvider.id,
          },
          from: template.from,
          replyTo: template.replyTo,
          subject: template.subject,
          to: template.to?.filter(Boolean),
        },
      },
    });
  };

  const buildClaimLinksFromText = useBuildClaimLinksFromText({
    claimCaseRoutePath,
    texts: claimNotes?.map(({ content }) => content),
  });

  if (claimNotes == null) {
    return null;
  }

  const remainingCharacterCount =
    claimType.slvHs === true && chatTabKey === "PAPAYA" && shareToInsurerCheck === true
      ? SLV_LIMIT_CHARACTER_SIGN_OFF -
        sumBy(
          claimNotes.filter((note) => note.share_to_insurer === true && note.user?.roles.some((r) => ["ClaimAssessor", "ClaimAssessorManager"].includes(r.role)) === true),
          (note) => note.content.length,
        )
      : null;

  return (
    <>
      <Spin spinning={updateLoading}>
        <If condition={claimNotes.length > 0} else={<Empty className={styles.empty} description={false} />}>
          <List
            dataSource={claimNotes}
            itemLayout="vertical"
            renderItem={(note) => (
              <List.Item
                actions={[
                  <If condition={insurerSignOffFeature !== true}>
                    <Popconfirm
                      onConfirm={() => {
                        const canToggleShareToInsurer =
                          remainingCharacterCount == null || note.share_to_insurer === true ? true : remainingCharacterCount - note.content.length >= 0;
                        if (canToggleShareToInsurer === false) {
                          notification.error({
                            description: `Tổng nội dung sign off (${sumBy(
                              claimNotes.filter(
                                (n) =>
                                  (n.share_to_insurer === true || n.id === note.id) &&
                                  n.user?.roles.some((r) => ["ClaimAssessor", "ClaimAssessorManager"].includes(r.role)) === true,
                              ),
                              (n) => n.content.length,
                            )} ký tự) vượt quá giới hạn ký tự cho phép (${SLV_LIMIT_CHARACTER_SIGN_OFF} ký tự).`,
                            message: "Vượt quá giới hạn ký tự",
                          });
                          return;
                        }
                        updateSignOffMutation({
                          variables: {
                            id: note.id,
                            object: {
                              share_to_insurer: note.share_to_insurer !== true,
                            },
                          },
                        });
                      }}
                      title={note.share_to_insurer === true ? "Bạn có chắc muốn bỏ chia sẻ?" : "Bạn có chắc muốn chia sẻ?"}
                    >
                      <Tooltip title={note.share_to_insurer === true ? "Tắt chia sẻ với CTBH" : "Bật chia sẻ với CTBH"}>
                        {note.share_to_insurer === true ? <Navigation className="cursor-pointer" size={14} /> : <NavigationOff className="cursor-pointer" size={14} />}
                      </Tooltip>
                    </Popconfirm>
                  </If>,
                  <If condition={hasRole(["DEPARTMENT_HEAD"])}>
                    <Popconfirm
                      onConfirm={() => {
                        deleteSignOffMutation({
                          onCompleted: () => {
                            notification.success({ message: "Xóa Sign Off message thành công" });
                          },
                          variables: {
                            id: note.id,
                            object: {
                              deleted_at: "now()",
                            },
                          },
                        });
                      }}
                      title="Bạn có chắc muốn xóa?"
                    >
                      <Trash className="cursor-pointer" size={14} />
                    </Popconfirm>
                  </If>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar className={styles.avatar} src={note.user?.avatar_url} text={note.user?.name} />}
                  description={utils.formatDate(note.created_at)}
                  title={
                    <Tooltip title={note.user?.email}>
                      {note.user?.name}
                      {note.type === "LaNote" && (
                        <Tag className={styles.laTag} color="blue">
                          NĐBH
                        </Tag>
                      )}
                      {note.user?.roles.some((r) => r.role === "InsurerEmployee") === true && (
                        <Tag className={styles.laTag} color="blue">
                          CTBH
                        </Tag>
                      )}
                    </Tooltip>
                  }
                />
                <p className="whitespace-pre-wrap">{buildClaimLinksFromText(note.content)}</p>
                <If condition={isGreaterThanZero(note.emails)}>
                  <List
                    dataSource={note.emails ?? []}
                    header={<Typography.Title level={5}>Các emails đính kèm:</Typography.Title>}
                    itemLayout="vertical"
                    renderItem={(email) => (
                      <List.Item
                        extra={
                          <Tooltip title="Xem nội dung email">
                            <ExternalLink to={generatePath(PORTAL_PATH.MAILBOX.EMAIL_DETAIL_DEFAULT, { emailId: email.id })}>
                              <Mail />
                            </ExternalLink>
                          </Tooltip>
                        }
                      >
                        <p className="p-[15px] bg-slate-200 whitespace-pre-wrap rounded-md">{email.reply_text}</p>
                      </List.Item>
                    )}
                  />
                </If>
              </List.Item>
            )}
          />
        </If>
      </Spin>
      <If condition={remainingCharacterCount != null && remainingCharacterCount < 0}>
        <Row className={styles.signOffContainer}>
          <Col span={24}>
            <Typography.Text type="danger">
              Signoff vượt quá giới hạn:{" "}
              {sumBy(
                claimNotes.filter((note) => note.share_to_insurer === true),
                (note) => note.content.length,
              )}{" "}
              ký tự
            </Typography.Text>
          </Col>
        </Row>
      </If>
      <Form disabled={claim?.is_history === true} form={signOffForm} initialValues={{ share_to_insurer: insurerSignOffFeature !== true }} onFinish={sendSignOff}>
        <Row className={styles.signOffContainer}>
          <Col span={24}>
            <Form.Item
              name="content"
              rules={[
                remainingCharacterCount == null
                  ? null
                  : {
                      max: remainingCharacterCount,
                      message: "Tổng ký tự sign off không được vượt quá 2000.",
                    },
              ].filter(Boolean)}
            >
              <TextArea
                autoSize={{ minRows: 5 }}
                className={styles.textArea}
                count={
                  remainingCharacterCount == null
                    ? undefined
                    : {
                        max: remainingCharacterCount,
                        show: true,
                      }
                }
                onChange={async (e) => {
                  const { data: emailObjectsData } = await getEmailObjects({
                    variables: {
                      where: {
                        code: { _in: uniq(findEmailCodeFromText(e.target.value)) },
                      },
                    },
                  });
                  if (emailObjectsData?.correspondence_emails == null || isGreaterThanZero(emailObjectsData.correspondence_emails) === false) return;
                  signOffForm.setFieldValue("emails", uniqBy([...(signOffForm.getFieldValue("emails") ?? []), ...emailObjectsData.correspondence_emails], "id"));
                }}
                placeholder="Nhập nội dung ghi chú..."
              />
            </Form.Item>
          </Col>
          <Col>
            <AttachedEmails form={signOffForm} />
          </Col>
          <Col span={24}>
            <Form.Item hidden={insurerSignOffFeature} name="share_to_insurer" valuePropName="checked">
              <Checkbox>Chia sẻ với CTBH</Checkbox>
            </Form.Item>
          </Col>
          <Col className={styles.textRight} span={24}>
            <Button className={styles.btnSignOff} htmlType="submit" loading={insertLoading} size="small" type="primary">
              Gửi đi
            </Button>
            <Button
              className={styles.btnSignOff}
              onClick={() => {
                signOffForm.setFieldValue("content", buildCopyableText());
                setTimeout(() => {
                  document.querySelector("#sign_off")?.scrollIntoView();
                }, 300);
              }}
              size="small"
              type="dashed"
            >
              <Wand2 color={papaya} size={24} />
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SignOffItem;
