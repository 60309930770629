import type { CustomAmountReasonsEnum } from "sdk/gql/graphql";

import { getOperationName } from "@apollo/client/utilities";
import { Button, Form, InputNumber, Select, notification } from "antd";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import UpsertClaimCasePaymentDocument from "app/portal/screens/ClaimPortal/ClaimContext/graphql/UpsertClaimCasePaymentDocument";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import utils from "libs/utils";
import { graphql } from "sdk/v2/graphql";

const CustomAmountReasonsDocument = graphql(`
  query CustomAmountReasons {
    custom_amount_reasons {
      comment
      value
    }
  }
`);

type FormValues = {
  custom_amount: number;
  custom_amount_reason: CustomAmountReasonsEnum;
};

const CustomAmount = ({ onChangeCustomAmount }: { onChangeCustomAmount: () => void }) => {
  const {
    claim,
    permissions: { canAssess },
  } = useClaim();
  const [form] = Form.useForm<FormValues>();
  const { data: customAmountReasons } = usePQuery(CustomAmountReasonsDocument);
  const [updateClaimCasePayment] = usePMutation(UpsertClaimCasePaymentDocument, {
    awaitRefetchQueries: true,
    refetchQueries: ["ClaimDetail", getOperationName(ClaimBenefitDetailsDocument)].filter(Boolean),
  });

  if (claim == null || customAmountReasons == null) {
    return null;
  }

  return (
    <Form
      disabled={!canAssess}
      form={form}
      initialValues={{
        custom_amount: claim.claim_case_payment?.custom_amount ?? 0,
        custom_amount_reason: claim.claim_case_payment?.custom_amount_reason ?? customAmountReasons.custom_amount_reasons[0]?.value,
      }}
      layout="inline"
      onFinish={(values) => {
        updateClaimCasePayment({
          onCompleted: () => {
            notification.success({
              message: "Cập nhật thành công",
            });
            onChangeCustomAmount();
          },
          variables: {
            object: {
              claim_case_id: claim.id,
              co_payment_ratio: claim.claim_case_payment?.co_payment_ratio,
              custom_amount: values.custom_amount,
              custom_amount_reason: values.custom_amount_reason,
              deductible_amount: claim.claim_case_payment?.deductible_amount,
            },
          },
        });
      }}
    >
      <Form.Item label="Số tiền đặc biệt" name="custom_amount_reason">
        <Select
          options={customAmountReasons.custom_amount_reasons.map((reason) => ({
            label: reason.comment,
            value: reason.value,
          }))}
          placeholder="Chọn loại tiền"
        />
      </Form.Item>
      <Form.Item label="Số Tiền" name="custom_amount">
        <InputNumber formatter={(value) => utils.formatNumber(value)} min={0} parser={utils.parseNumber} step={1} wheel="false" />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Lưu</Button>
      </Form.Item>
    </Form>
  );
};
export default CustomAmount;
