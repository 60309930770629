import type { FormInstance } from "antd";
import type { DateItem } from "config/common";

import { Form } from "antd";
import DatePicker from "app/common/components/CustomAntdTimePickers/DatePicker";
import { FORMATTER } from "config/constants";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { AlertTriangle } from "lucide-react";
import { useEffect } from "react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import styles from "../../DetailForm.module.css";

type Props = {
  claimDates: [DateItem, DateItem] | DateItem;
  disabledDate?: (date: Date) => boolean;
  form: FormInstance<ClaimCaseDetailForm>;
  required?: boolean;
  validRangeDate?: [string, string];
} & ClaimCaseDetailFormItem;

const ClaimDateRangeField = ({ claimDates, disabledDate, form, name, required = true, validRangeDate }: Props) => {
  const [startDate, endDate] = claimDates;
  const watchField = Form.useWatch(name, form);

  useEffect(() => {
    if (startDate != null && endDate != null) {
      const dates = watchField;
      form.setFieldsValue({
        [endDate.key]: dates?.[1],
        [startDate.key]: dates?.[0],
      });
    }
  }, [endDate, form, startDate, watchField]);

  if (startDate === undefined || endDate === undefined) {
    return null;
  }

  return (
    <Form.Item
      label={`${startDate.label} và ${endDate.label}`}
      name={name}
      rules={[
        ...(required
          ? [
              {
                message: "Vui lòng nhập ngày",
                required: true,
              },
            ]
          : []),
        () => ({
          message: (
            <>
              <AlertTriangle size={14} />
              Không nằm trong hiệu lực hợp đồng
            </>
          ),
          validator: (_, values) => {
            if (values != null && validRangeDate != null) {
              const [start, end] = values;
              const validStart = startOfDay(new Date(validRangeDate[0]));
              const validEnd = endOfDay(new Date(validRangeDate[1]));
              if (isBefore(start, validStart) || isAfter(end, validEnd)) {
                return Promise.reject();
              }
            }

            return Promise.resolve();
          },
          warningOnly: true,
        }),
      ]}
      validateTrigger="onBlur"
    >
      <DatePicker.RangePicker
        className={styles.datePicker}
        disabledDate={disabledDate}
        format={FORMATTER.DATE_FORMAT}
        placeholder={[startDate.label, endDate.label]}
        size="large"
      />
    </Form.Item>
  );
};
export default ClaimDateRangeField;
