import { Alert, Flex, Space, Spin } from "antd";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER } from "config/constants";
import utils from "libs/utils";
import { AlertTriangle } from "lucide-react";

const CriticalAlerts = () => {
  const { claim } = useClaim();

  if (claim == null) return <Spin spinning />;

  return (
    <Flex justify="center">
      <If condition={claim.insured_certificate.insured_person.died_at != null}>
        <Alert
          banner
          icon={<AlertTriangle color="red" size={20} />}
          message={<Space>NĐBH đã qua đời ngày {utils.formatDate(claim.insured_certificate.insured_person.died_at, FORMATTER.DATE_FORMAT)}</Space>}
          showIcon
          type="error"
        />
      </If>
    </Flex>
  );
};

export default CriticalAlerts;
