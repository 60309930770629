import { graphql } from "sdk/v2/graphql";

const UPDATE_CLAIM_MUTATION = graphql(`
  mutation UpdateClaim($claimId: uuid!, $input: claim_cases_set_input!) {
    update_claim_cases_by_pk(pk_columns: { claim_case_id: $claimId }, _set: $input) {
      id
      __typename
      first_pending_tat_in_seconds
      status
      tat_in_seconds
    }
  }
`);

export { UPDATE_CLAIM_MUTATION };
