/* eslint-disable react/jsx-props-no-spreading */
import type { SpinProps } from "antd";

import { Spin } from "antd";

const PPYSpin = ({ center, children, ...props }: { center?: boolean } & SpinProps) => {
  if (props.spinning === false) {
    return children;
  }
  if (center === true) {
    return (
      <div style={{ left: "50%", position: "relative", top: "20%" }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Spin {...props} delay={props.delay ?? 500}>
          {children}
        </Spin>
      </div>
    );
  }
  return (
    <Spin {...props} delay={props.delay ?? 500}>
      {children}
    </Spin>
  );
};

export default PPYSpin;
