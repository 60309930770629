import type { InsertClaimCaseDeclineHistoryDocument } from "hooks/useDecline";
import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";
import type { VariablesOf } from "sdk/v2/graphql";

import { Button, Form, Select, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { SPECIAL_DECLINE_CODE } from "config/constants";
import { usePpyDeclineCodes } from "hooks/useDecline";
import { isEmpty } from "lodash";

import styles from "./DeclineInfoForm.module.css";

interface Props {
  onSaveDeclineStatus: (claimCaseStatus: ClaimCaseStatusesEnum, claimCaseDeclineHistory: VariablesOf<typeof InsertClaimCaseDeclineHistoryDocument>["data"]) => void;
}

const DeclineInfoForm = (props: Props) => {
  const { onSaveDeclineStatus } = props;
  const {
    claim,
    status: { status },
  } = useClaim();

  const insurerId = claim?.insured_certificate.policy.insurer_company?.company_id;

  const [declineForm] = Form.useForm();
  const declineCode = Form.useWatch("decline_code", declineForm);
  const ppyDeclineCodes = usePpyDeclineCodes();

  const insurerDeclineCodes = ppyDeclineCodes.filter((n) => !isEmpty(n.insurer_decline_code) && !isEmpty(n.insurer_decline_code.filter((v) => v.insurer_id === insurerId)));
  const declineCodes = isEmpty(insurerDeclineCodes) ? ppyDeclineCodes : insurerDeclineCodes;

  const onDeclineFormFinish = (values) => {
    if (!isEmpty(claim) && status === "Declined") {
      const claimCaseDeclineHistory: VariablesOf<typeof InsertClaimCaseDeclineHistoryDocument>["data"] = {
        claim_case_id: claim.claim_case_id,
        decline_code: declineCode,
        decline_description: values.decline_description,
      };

      if (isEmpty(declineCode)) {
        notification.error({ message: "Vui lòng chọn 1 lý do từ chối" });
        return;
      }
      if (declineCode === SPECIAL_DECLINE_CODE && isEmpty(values.decline_description)) {
        notification.error({ message: "Vui lòng ghi chi tiết từ chối cho lý do này" });
        return;
      }

      onSaveDeclineStatus(status, claimCaseDeclineHistory);
    }
  };

  return (
    <Form form={declineForm} onFinish={onDeclineFormFinish}>
      <Form.Item label="Lý do từ chối" labelCol={{ span: 24 }} name="decline_code" required wrapperCol={{ span: 24 }}>
        <Select
          options={[...declineCodes]
            .sort((a, b) => a.enum_localizations[0]?.enum_translation?.localeCompare(b.enum_localizations[0]?.enum_translation ?? "") ?? 0)
            .map((item) => ({
              label: item.enum_localizations[0]?.enum_translation,
              value: item.enum_localizations[0]?.enum_value,
            }))}
        />
      </Form.Item>

      {declineCode === SPECIAL_DECLINE_CODE ? (
        <Form.Item label="Mô tả lý do từ chối" labelCol={{ span: 24 }} name="decline_description" required wrapperCol={{ span: 24 }}>
          <TextArea autoSize={{ minRows: 5 }} />
        </Form.Item>
      ) : null}

      <div className={styles.buttonDeclineContainer}>
        <Button htmlType="submit" type="primary">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
};

export default DeclineInfoForm;
