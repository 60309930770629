import type { ClaimCaseStatusesEnum } from "sdk/gql/types";

import { Tag } from "antd";
import { TYPE } from "config/constants";

const ClaimStatus = ({
  className = "",
  onClick,
  status,
  text,
}: {
  className?: string;
  onClick?: VoidFunction;
  status: ClaimCaseStatusesEnum | string;
  text: string;
}) => (
  <Tag className={[className].join(" ")} color={TYPE.CLAIM_STATUS_TO_COLOR[status]} key={status} onClick={onClick}>
    {text}
  </Tag>
);

export default ClaimStatus;
