import type { TableColumnsType } from "antd";
import type { FragmentOf } from "sdk/v2/graphql";

import Content from "app/common/components/Content";
import TableList from "app/common/components/TableListV2";
import usePQuery from "contexts/usePQuery";
import utils from "libs/utils";
import { graphql } from "sdk/v2/graphql";

const fragment2 = graphql(`
  fragment PlanBenefitDetailWithoutChildren on PlanBenefitDetails {
    displayedBalanceName
    planBalance
    planBalanceRemaining
  }
`);

const fragment = graphql(
  `
    fragment PlanBenefitDetail on PlanBenefitDetails {
      id
      ...PlanBenefitDetailWithoutChildren
      childrenBalances {
        id: planBalanceId
        ...PlanBenefitDetailWithoutChildren
      }
    }
  `,
  [fragment2],
);

const CertificatePlanBenefitDetailDocument = graphql(
  `
    query CertificatePlanBenefitDetail($certificateId: ID!) {
      planBenefitDetails(certificateId: $certificateId) {
        planBenefitTypes {
          id
          ...PlanBenefitDetail
        }
      }
    }
  `,
  [fragment],
);

const InsuredCertificateBalance = ({ certificateId }: { certificateId?: string }) => {
  const { data, loading } = usePQuery(CertificatePlanBenefitDetailDocument, certificateId == null ? { skip: true } : { variables: { certificateId } });
  if (data?.planBenefitDetails == null) return null;
  const columns: TableColumnsType<FragmentOf<typeof fragment>> = [
    {
      dataIndex: "displayedBalanceName",
    },
    {
      align: "center",
      dataIndex: "planBalance",
      render: (_, record) => (Number(record.planBalance) === 0 ? "" : `${utils.formatNumber(record.planBalanceRemaining)}/${utils.formatNumber(record.planBalance)}`),
      title: "Còn lại/tổng",
    },
  ];

  const expandedRowRender = (record: FragmentOf<typeof fragment>) => {
    const childColumns: TableColumnsType<FragmentOf<typeof fragment2>> = [
      {
        dataIndex: "displayedBalanceName",
      },
      {
        align: "center",
        dataIndex: "planBalance",
        render: (_, childRecord) =>
          Number(childRecord.planBalance) === 0 ? "" : `${utils.formatNumber(childRecord.planBalanceRemaining)}/${utils.formatNumber(childRecord.planBalance)}`,
        title: "Còn lại/tổng",
      },
    ];
    return <TableList data={record.childrenBalances ?? []} hideWhenEmpty loading={loading} schema={childColumns} />;
  };

  return (
    <Content title="Hạn mức">
      <TableList
        data={data.planBenefitDetails.planBenefitTypes?.filter(Boolean) ?? []}
        expandable={{ defaultExpandAllRows: true, expandedRowRender, rowExpandable: (record) => (record.childrenBalances?.length ?? 0) > 0 }}
        schema={columns}
      />
    </Content>
  );
};

export default InsuredCertificateBalance;
