import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation UpsertMbalExtendedData($claimId: ID!, $mbalDiseaseGroupId: ID!) {
    upsertMbalExtendedClaim(claimId: $claimId, mbalDiseaseGroupId: $mbalDiseaseGroupId) {
      id: claimId
      mbal_claim_case {
        id
        disease_group_id
      }
    }
  }
`);
