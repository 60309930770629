import { graphql } from "sdk/v2/graphql";

const ClaimDetailOnBalanceFragment = graphql(`
  fragment ClaimDetailOnBalance on claim_case_details {
    id
    paid_amount
    paid_percentage
    paid_time
    request_percentage
    total_paid_amount
    claim_case {
      id
      code
    }
  }
`);

export default graphql(
  `
    fragment BalanceDetail on BalanceDetail {
      id
      name
      balance
      balanceRemaining
      balanceRemainingBuffer
      balanceUsed
      currentBalanceUseBuffer
      exclusiveBalanceRemainingBuffer
      otherBalanceUsedBuffer
      planBalanceId
      type
      balance_detail {
        id
        name
        type
        value
      }
      balanceRemainingBufferClaimDetails {
        claimCaseDetail {
          ...ClaimDetailOnBalance
        }
      }
      balanceUsedClaimDetails {
        claimCaseDetail {
          ...ClaimDetailOnBalance
        }
      }
      currentBalanceUseBufferClaimDetails {
        claimCaseDetail {
          ...ClaimDetailOnBalance
        }
      }
      exclusiveBalanceRemainingBufferClaimDetails {
        claimCaseDetail {
          ...ClaimDetailOnBalance
        }
      }
      otherBalanceUsedBufferClaimDetails {
        claimCaseDetail {
          ...ClaimDetailOnBalance
        }
      }
      plan_balance_type {
        comment
        value
      }
    }
  `,
  [ClaimDetailOnBalanceFragment],
);
