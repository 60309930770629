const downloadFile = ({ buffer, name, type }: { buffer: BlobPart; name: string; type: string }) => {
  const blob = new Blob([buffer], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  document.body.append(link);
  link.dispatchEvent(new MouseEvent("click"));
  link.remove();
  window.URL.revokeObjectURL(url);
};

export default downloadFile;
