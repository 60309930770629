import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation UpdateClaimCasePaymentOnClaimProvider($id: uuid!, $object: claim_case_payments_set_input!) {
    update_claim_case_payments_by_pk(_set: $object, pk_columns: { claim_case_id: $id }) {
      id
      claim_case_id
      co_payment_ratio
      deductible_amount
    }
  }
`);
