import { Col, FloatButton, Modal, Row, Space, Switch, Tabs, Typography } from "antd";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import InsuredCertificateBalance from "app/common/components/InsuredCertificateBalance";
import Spin from "app/common/components/Spin";
import ClaimAlert from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimAlert";
import ClaimCaseSameEventGroup from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimCaseSameEventGroup";
import InsurerSignOff from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/InsurerSignOff";
import PendingFormV2 from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/PendingFormV2";
import useClaimShortcuts from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/useClaimShortcuts";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { papaya } from "config/colors";
import { useAuth } from "contexts/AuthContext";
import dedent from "dedent";
import ClaimCaseActionsBar from "layouts/components/ClaimCaseActionsBar";
import { isGreaterThanZero } from "libs/hidash";
import utils from "libs/utils";
import { Wrench } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router";
import { Outlet } from "react-router-dom";

import styles from "./ClaimCaseInfoScreen.module.less";
import AssessmentExplanationForm from "./components/AssessmentExplanation";
import ClaimBenefitVer3 from "./components/ClaimBenefit/ClaimBenefitVer3";
import ClaimGroup from "./components/ClaimGroup";
import ClaimHistory from "./components/ClaimHistory";
import DeclineInfo from "./components/DeclineInfo";
import DetailForm from "./components/DetailForm";
import FwdClaimRightColumn from "./components/FwdClaimRightColumn";
import InsuredInfo from "./components/InsuredInfo";
import InsuredPersonInfo from "./components/InsuredPersonInfo";
import PendingForm from "./components/PendingForm";
import PolicyOwnerSection from "./components/PolicyOwnerSection";
import RemarkCopay from "./components/RemarkCopay";
import SignOff from "./components/SignOff";
import Summary from "./components/Summary";
import WaitingTime from "./components/WaitingTime";

const ClaimCaseInfoScreen = () => {
  const { selectedRole, user } = useAuth();

  const { claim, claimType, fwdClaim, isClaimHistory, isLifeInsuranceRider, loading, permissions, singleShareToInsurerSignOffFeature, updateClaimMutation } = useClaim();

  const { claimCaseId } = useParams<{ claimCaseId: string }>();
  const [showClaimHistory, setShowClaimHistory] = useState(false);

  const { icons, refs } = useClaimShortcuts();
  const canChangeIsJet = claim?.is_jet === true && permissions.canUpdateClaimInput;

  if (claimCaseId == null || claim == null) {
    return null;
  }

  return (
    <Spin spinning={loading || fwdClaim?.updatingFwdClaim}>
      <div className={styles.container}>
        <FloatButton.Group
          icon={<Wrench color={papaya} size={20} />}
          style={{ bottom: 75, left: 220 }}
          tooltip={<div>Dùng các icons để di chuyển đến các mục nhanh hơn. Có thể dùng phím tắt để điều khiển, ví dụ Ctrl+d sẽ đến ngay phần sign off.</div>}
          trigger="click"
        >
          {icons.map((item) => (
            <FloatButton href={item.link} icon={item.icon} key={item.hotkey} tooltip={`${item.tooltip} - ${item.hotkey}`} />
          ))}
        </FloatButton.Group>
        <Row gutter={[8, 16]} wrap={false}>
          <Col span={18}>
            <Space className={styles.collapseContainer} direction="vertical" size={18}>
              <section id="insured_info" ref={refs.insuredInfoRef}>
                <Space direction="vertical" size={18}>
                  <InsuredInfo />
                  <If condition={isLifeInsuranceRider}>
                    <PolicyOwnerSection claim={claim} loading={loading} />
                  </If>
                  <InsuredPersonInfo claim={claim} loading={loading} />
                </Space>
              </section>
              <section id="history" ref={refs.historyRef}>
                <Content title="Lịch sử bồi thường">
                  <ClaimHistory />
                </Content>
              </section>
              <section>
                <ClaimCaseSameEventGroup />
              </section>
              <If condition={claim.claim_case_group_claim_cases.length > 0}>
                <section id="claim_group">
                  <Content
                    title={dedent`
                      Claim cùng nhóm: ${claim.claim_case_group_claim_cases.length}.
                      Loại nhóm: ${claim.claim_case_group_claim_cases[0]?.claim_case_group?.claim_case_group_type.comment}.
                    `}
                  >
                    {claim.claim_case_group_claim_cases.map((ccgcc) => (
                      <ClaimGroup claimGroup={ccgcc.claim_case_group} claimId={claimCaseId} key={ccgcc.id} />
                    ))}
                  </Content>
                </section>
              </If>
              <section id="detail_form" ref={refs.detailFormRef}>
                <DetailForm readonlyItem={selectedRole === "InsurerEmployee"} />
              </section>

              <If condition={claimType.healthCare}>
                <section id="certificate_balance" ref={refs.certificateBalanceRef}>
                  <InsuredCertificateBalance certificateId={claim.insured_certificate_id} />
                </section>
              </If>
              <section id="assessment" ref={refs.assessmentRef}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <ClaimBenefitVer3 claimCaseId={claimCaseId} />
                  </Col>
                </Row>
              </section>
              <AssessmentExplanationForm />
              <section id="pending" ref={refs.pendingRef}>
                <Tabs
                  defaultActiveKey={user.user_setting?.use_pending_form_v2 === true ? "pending_v2" : "pending"}
                  items={[
                    {
                      children: <PendingForm />,
                      key: "pending",
                      label: "Pending",
                    },
                    {
                      children: <PendingFormV2 />,
                      key: "pending_v2",
                      label: "Pending v2",
                    },
                  ]}
                />
              </section>

              <If condition={singleShareToInsurerSignOffFeature}>
                <InsurerSignOff />
              </If>

              <SignOff claimCaseId={claimCaseId} />
              <section id="sign_off" ref={refs.signOffRef}>
                {/* a trick to get to bottom of sign off */}
              </section>
              <DeclineInfo />
              <If condition={isGreaterThanZero(claim.claim_quality_codes)}>
                <Content title="Claim Quality">
                  <Space direction="vertical" size={5}>
                    <Typography.Text>Các code được đánh giá:</Typography.Text>
                    <ul>{claim.claim_quality_codes?.map((item) => <li key={item.value}>{item.comment}</li>)}</ul>
                    Chi tiết: <Typography.Text className="whitespace-pre">{claim.quality_detail ?? "--"}</Typography.Text>
                    <Space>Người đánh giá: {claim.quality_assessed_by_user?.name}</Space>
                    <Space>Đánh giá lúc: {utils.formatDate(claim.quality_assessed_at)}</Space>
                  </Space>
                </Content>
              </If>
              <div />
            </Space>
          </Col>
          <Col span={6}>
            <If condition={claimType.mbalHs}>
              <Content title="Lưu ý">
                <Space direction="vertical" size={5} style={{ width: "100%" }}>
                  {(
                    [
                      { key: "is_jet", label: "Jet" },
                      { key: "challenge_abuse", label: "Challenge" },
                    ] as {
                      key: "challenge_abuse" | "is_jet";
                      label: string;
                    }[]
                  ).map((item) => (
                    <Row justify="space-between" key={item.key}>
                      <Col>
                        <label htmlFor={item.key}>
                          <strong>{item.label}</strong>
                        </label>
                      </Col>
                      <Col>
                        <Switch
                          checked={claim[item.key] ?? false}
                          disabled={loading || (item.key === "is_jet" && !canChangeIsJet) || isClaimHistory}
                          id={item.key}
                          onChange={() => {
                            const currentValue = claim[item.key];
                            Modal.confirm({
                              content: `Bạn có chắc muốn đổi thành ${currentValue === true ? "Non " : ""}${item.label}?`,
                              maskClosable: false,
                              okButtonProps: {
                                disabled: loading,
                                loading,
                              },
                              onOk: () => {
                                updateClaimMutation({
                                  variables: {
                                    claimId: claimCaseId,
                                    input: { [item.key]: currentValue !== true },
                                  },
                                });
                              },
                            });
                          }}
                          title={item.label}
                        />
                      </Col>
                    </Row>
                  ))}
                </Space>
              </Content>
            </If>
            <If condition={!claimType.fwdMr}>
              <Space className={styles.moreDetailContainer} direction="vertical">
                <ClaimAlert />
                <Summary />
                <WaitingTime claimCase={claim} waitingType={claim.insured_certificate.policy.policy_waiting_times} />
                <RemarkCopay policyId={claim.insured_certificate.policy.policy_id} remarkCopay={claim.insured_certificate.policy.remark_copay} />
                <Modal okText="OK" onCancel={() => setShowClaimHistory(false)} open={showClaimHistory} title="Yêu cầu bồi thường">
                  <Outlet />
                </Modal>
              </Space>
            </If>
            <If condition={claimType.fwdMr}>
              <FwdClaimRightColumn claimCaseId={claimCaseId} closeClaimHistory={() => setShowClaimHistory(false)} showClaimHistory={showClaimHistory} />
            </If>
          </Col>
        </Row>
        <ClaimCaseActionsBar />
      </div>
    </Spin>
  );
};

export default ClaimCaseInfoScreen;
