import type { DefaultContext, DocumentNode, MutationHookOptions, MutationTuple, TypedDocumentNode } from "@apollo/client";
import type { IdentityRolesEnum } from "sdk/gql/graphql";

import { useMutation } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

type PMutationHookOptions<TData, TVariables> = {
  role?: IdentityRolesEnum;
} & MutationHookOptions<TData, TVariables>;

function usePMutation<TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: PMutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const { selectedRole } = useAuth();
  // eslint-disable-next-line deprecate/function
  return useMutation(mutation, {
    ...options,
    context: { headers: { "x-hasura-role": options?.role ?? selectedRole } } as DefaultContext,
    refetchQueries:
      options?.refetchQueries?.map((query) => {
        if (typeof query === "string") return query;
        return {
          context: { headers: { "x-hasura-role": options.role ?? selectedRole } },
          query: query.query,
          variables: query.variables,
        };
      }) ?? [],
  });
}

export default usePMutation;
