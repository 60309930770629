import InsuredCertificateFragment from "app/portal/screens/ClaimPortal/ClaimContext/graphql/InsuredCertificateFragment";
import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

export default codegenThisGraphqlTag(
  /* GraphQL */ `
    fragment ClaimInfo on claim_cases {
      action_logs(order_by: { created_at: desc }) {
        id
        new_value
        old_value
        created_at
        user {
          id
          name
          user_credentials {
            id
            email
          }
        }
      }
      agent {
        id
        name
        code
        email
        group_code
        phone
        watch_by {
          id
          insurer_id
        }
      }
      assessment_explanation: claim_notes(where: { type: { _eq: ASSESSMENT_EXPLANATION } }, order_by: { created_at: desc }, limit: 1) {
        id
        content
      }
      assessment_summary: claim_notes(where: { type: { _eq: ASSESSMENT_SUMMARY } }, order_by: { created_at: desc }, limit: 1) {
        id
        content
        share_to_insurer
        type
        user_id
        created_at
        user {
          id
          name
          avatar_url
          roles {
            id
            role
          }
        }
      }
      correspondence_emails {
        id
        email: string_value
      }
      created_by_user {
        id
        name
      }
      fwd_claim_case {
        id
        decline_code_value
        decline_description
        exclusion_info
        policy_note
        related_to_accident
      }
      genesis_claim_case {
        id
        code
      }
      insured_benefit_type {
        id: value
        comment
        value
      }

      mbal_claim_case {
        id
        disease_group_id
        is_fatca_us_citizen
        is_fatca_us_related
        is_fatca_us_tax_payer
      }
      medical_provider {
        id
        name
        email
        medical_provider_id
        aliases {
          id
          name
          normalized_name
        }
        company_role {
          id
          company_id
        }
        medical_provider_mappings {
          id
          code
          company_id
          watch_by {
            id
            insurer_id
          }
        }
        watch_by {
          id
          insurer_id
        }
      }
      meta_v2 {
        assessor {
          id
          name
          avatar_url
        }
      }
      original_claim_case {
        id
        admission_date
        discharge_date
        event_date
        is_direct_billing
        is_jet
        medical_provider_name
        physical_examination_date
        requested_amount
        updated_at
        created_at
        claim_case_beneficiary {
          id
          bank_account_number
          bank_id
          beneficiary_name
          paper_id
          payment_method
          premium_policy_number
        }
        claim_source {
          id: value
          comment
          value
        }
        fwd_original_claim_case {
          id
          exclusion_info
          policy_note
          related_to_accident
        }
        insured_benefit_type {
          id: value
          comment
          value
        }
        insured_certificate {
          id
          dued_at
          effective_date
          expiry_date
          insured_certificate_id
          issued_at
          settle_date
          status
          subsidiary_company_name
          insured_certificate_related_events(order_by: { effective_date: desc }) {
            id
            effective_date
            event_type
            new_value
            old_value
          }
          insured_certificate_underwriting_notes {
            id
            note
          }
          insured_person {
            id
            name
            died_at
            insured_person_id
            tpd_date
            vip_level
            insured_relationship_tos {
              id
              insured_person_to_id
              insured_relationship_type {
                id: value
                comment
                value
              }
            }
            watch_by {
              id
              insurer_id
            }
          }
          parent_insured_certificate {
            id
            insured_person {
              id
              name
              insured_person_id
            }
            policy {
              id
            }
          }
          policy {
            id
            due_date
            dued_at
            effective_date
            expiry_date
            issued_at
            lapsed_date
            policy_id
            policy_number
            reinstated_date
            status
            surrender_date
            types
            corporate_company {
              id
              name
            }
            insured_person {
              id
              name
              insured_person_id
            }
            insurer_company {
              id
              name
            }
            policy_documents {
              id
              file {
                id
                name
                url
              }
            }
            policy_kind {
              id: value
              comment
              value
            }
          }
          policy_plan {
            id
            policy {
              id
              policy_id
            }
          }
        }
        medical_provider {
          id
          name
          medical_provider_id
          medical_provider_mappings {
            id
            watch_by {
              id
              insurer_id
            }
          }
        }
      }
      presentCaseCorrespondenceHistories: correspondence_histories(
        where: {
          _or: [{ company_correspondence: { template: { send_when: { _eq: CLAIM_WAITING } } } }, { policy_correspondence: { template: { send_when: { _eq: CLAIM_WAITING } } } }]
        }
      ) {
        id
        content
        destination
        message
        status
        subject
        created_at
        company_correspondence {
          id
          template {
            id
            medium
          }
        }
        policy_correspondence {
          id
          template {
            id
            medium
          }
        }
      }
      pti_claim_case {
        id
        benefit_type
        disease_group
      }
      slv_claim_case {
        id
        disease_group_id
        related_to_accident
      }
      stp_executions(order_by: { created_at: desc }, limit: 1) {
        id
        execution_arn
        task_token
      }
      updated_by_user {
        id
        name
      }
    }
  `,
  [InsuredCertificateFragment],
);
