import { Button, Form, Space } from "antd";
import DatePicker from "app/common/components/CustomAntdTimePickers/DatePicker";
import usePLazyQuery from "contexts/usePLazyQuery";
import { endOfMonth, startOfMonth } from "date-fns";
import ExcelJS from "exceljs";
import utils from "libs/utils";
import { graphql } from "sdk/v2/graphql";

const OTP_RECONCILIATION_REPORT_QUERY = graphql(`
  query OTPReconciliationReport($from: timestamptz!, $to: timestamptz!) {
    identity_otps_aggregate(where: { recipient: { _ilike: "0%" }, created_at: { _gt: $from, _lt: $to } }) {
      aggregate {
        count
      }
      nodes {
        otp
        recipient
        created_at
        user_credential {
          id
          user {
            id
            insured_persons {
              id
              insured_certificates {
                id
                policy {
                  id
                  insurer_company {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    mbalCorrespondenceHistories: correspondence_histories_aggregate(
      where: { company_correspondence: { company_id: { _eq: "704c0593-c812-47e9-9bf5-653a9b105923" } }, phone: { _like: "0%" }, created_at: { _gt: $from, _lt: $to } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        message
        phone
        status
        created_at
        claim_case {
          id
          code
        }
        company_correspondence {
          id
          template {
            id
            name
          }
        }
      }
    }
    slvCorrespondenceHistories: correspondence_histories_aggregate(
      where: { company_correspondence: { company_id: { _eq: "feb7bf23-fac7-4c99-a487-c5d4c9ffcb2e" } }, phone: { _like: "0%" }, created_at: { _gt: $from, _lt: $to } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        message
        phone
        status
        created_at
        claim_case {
          id
          code
        }
        company_correspondence {
          id
          template {
            id
            name
          }
        }
      }
    }
  }
`);

const OTP = () => {
  const [getOtp, { loading }] = usePLazyQuery(OTP_RECONCILIATION_REPORT_QUERY);
  const onFinish = async (values: { date: Date }) => {
    const { data } = await getOtp({
      variables: {
        from: startOfMonth(values.date).toISOString(),
        to: endOfMonth(values.date).toISOString(),
      },
    });
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Healthcare OTP");
    worksheet.columns = [
      { header: "OTP", key: "otp", width: 10 },
      { header: "Created At", key: "created_at", width: 20 },
      { header: "Recipient", key: "recipient", width: 20 },
      { header: "Insurer", key: "insurer", width: 20 },
    ];
    data?.identity_otps_aggregate.nodes.forEach((node) => {
      worksheet.addRow({
        created_at: utils.formatDate(node.created_at),
        insurer: node.user_credential?.user.insured_persons[0]?.insured_certificates[0]?.policy.insurer_company?.name,
        otp: node.otp,
        recipient: node.recipient,
      });
    });

    const mbalHsWorksheet = workbook.addWorksheet("MBAL HS");
    mbalHsWorksheet.columns = [
      { header: "Message", key: "message", width: 200 },
      { header: "Created At", key: "created_at", width: 20 },
      { header: "Status", key: "status", width: 20 },
      { header: "Claim Code", key: "code", width: 50 },
      { header: "Phone", key: "phone", width: 50 },
      { header: "Template Name", key: "template", width: 50 },
    ];
    data?.mbalCorrespondenceHistories.nodes.forEach((node) => {
      mbalHsWorksheet.addRow({
        code: node.claim_case?.code,
        created_at: utils.formatDate(node.created_at),
        message: node.message,
        phone: node.phone,
        status: node.status,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        template: node.company_correspondence?.template?.name,
      });
    });

    const slvHsWorksheet = workbook.addWorksheet("SLV HS");
    slvHsWorksheet.columns = [
      { header: "Message", key: "message", width: 200 },
      { header: "Created At", key: "created_at", width: 20 },
      { header: "Status", key: "status", width: 20 },
      { header: "Claim Code", key: "code", width: 50 },
      { header: "Phone", key: "phone", width: 50 },
      { header: "Template Name", key: "template", width: 50 },
    ];
    data?.slvCorrespondenceHistories.nodes.forEach((node) => {
      slvHsWorksheet.addRow({
        code: node.claim_case?.code,
        created_at: utils.formatDate(node.created_at),
        message: node.message,
        phone: node.phone,
        status: node.status,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        template: node.company_correspondence?.template?.name,
      });
    });

    utils.downloadWorkbook({ fileName: "OTP.xlsx", workbook });
  };

  return (
    <Space>
      <Form onFinish={onFinish}>
        <Form.Item name="date">
          <DatePicker disabledDate={(date) => date.getMonth() >= new Date().getMonth() && date.getFullYear() >= new Date().getFullYear()} picker="month" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loading}>
            Tải
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};

export default OTP;
