/* eslint-disable */
import * as Types from '../../sdk/gql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetClaimCaseStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClaimCaseStatusesQuery = (
  { claim_case_statuses: Array<(
    { value: string, comment: string | null, id: string, name: string | null }
    & { __typename?: 'claim_case_statuses' }
  )> }
  & { __typename?: 'query_root' }
);

export type GetCompanyRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyRolesQuery = (
  { company_role_types: Array<(
    { value: string, comment: string | null, id: string, name: string | null }
    & { __typename?: 'company_role_types' }
  )> }
  & { __typename?: 'query_root' }
);

export type CommonQueryGetInsuredBenefitTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonQueryGetInsuredBenefitTypesQuery = (
  { insured_benefit_types: Array<(
    { value: string, comment: string, id: string, name: string }
    & { __typename?: 'insured_benefit_types' }
  )> }
  & { __typename?: 'query_root' }
);

export type CommonQueryGetInsuredRelationshipTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonQueryGetInsuredRelationshipTypesQuery = (
  { insured_relationship_types: Array<(
    { value: string, comment: string, id: string, name: string }
    & { __typename?: 'insured_relationship_types' }
  )> }
  & { __typename?: 'query_root' }
);

export type CommonQueryGetPlanBalanceTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonQueryGetPlanBalanceTypesQuery = (
  { plan_balance_types: Array<(
    { value: string, comment: string | null, id: string, name: string | null }
    & { __typename?: 'plan_balance_types' }
  )> }
  & { __typename?: 'query_root' }
);

export type CommonQueryGetPolicyCopaymentTargetTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonQueryGetPolicyCopaymentTargetTypesQuery = (
  { policy_co_payment_targets: Array<(
    { value: string, comment: string, id: string, name: string }
    & { __typename?: 'policy_co_payment_targets' }
  )> }
  & { __typename?: 'query_root' }
);

export type CommonQueryMedicalProviderTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonQueryMedicalProviderTypesQuery = (
  { mp_medical_provider_types: Array<(
    { value: string, comment: string | null, id: string, name: string | null }
    & { __typename?: 'mp_medical_provider_types' }
  )> }
  & { __typename?: 'query_root' }
);

export type SendEmailMutationVariables = Types.Exact<{
  emailInput: Types.EmailInput;
}>;


export type SendEmailMutation = (
  { berry: (
    { sendEmail: boolean | null }
    & { __typename?: 'berrymutation_root' }
  ) | null }
  & { __typename?: 'mutation_root' }
);

export type FillMbalMedicalProviderNotesMutationVariables = Types.Exact<{
  claimCaseId: Types.Scalars['UUID']['input'];
  nextStatus: Types.Scalars['String']['input'];
}>;


export type FillMbalMedicalProviderNotesMutation = (
  { fillMbalMedicalProviderNotes: (
    { success: boolean, message: string }
    & { __typename?: 'FillMbalMedicalProviderNotesOutput' }
  ) }
  & { __typename?: 'mutation_root' }
);

export type GetPresentCaseStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPresentCaseStatusesQuery = (
  { present_case_statuses: Array<(
    { value: string, comment: string | null, id: string, name: string | null }
    & { __typename?: 'present_case_statuses' }
  )> }
  & { __typename?: 'query_root' }
);

export type UpsertCustomsettingsMutationVariables = Types.Exact<{
  upsertCustomSettingInput: Types.UpsertCustomSettingInput;
}>;


export type UpsertCustomsettingsMutation = (
  { upsertCustomSetting: boolean }
  & { __typename?: 'mutation_root' }
);

export type GetCustomSettingQueryVariables = Types.Exact<{
  getCustomSettingInput: Types.GetCustomSettingInput;
}>;


export type GetCustomSettingQuery = (
  { getCustomSetting: (
    { numericValue: number | null, booleanValue: boolean | null, jsonbValue: any | null, textValue: string | null, timestamptzValue: Date | string | null }
    & { __typename?: 'GetCustomSettingOutput' }
  ) | null }
  & { __typename?: 'query_root' }
);

export type CommonGlobalCountersMutationVariables = Types.Exact<{
  type: Types.Scalars['String']['input'];
}>;


export type CommonGlobalCountersMutation = (
  { globalCounters: (
    { counter: number }
    & { __typename?: 'global_counters' }
  ) | null }
  & { __typename?: 'mutation_root' }
);

export type CreateNotificationMutationVariables = Types.Exact<{
  object: Types.NotificationNotificationsInsertInput;
}>;


export type CreateNotificationMutation = (
  { insert_notification_notifications_one: (
    { id: string }
    & { __typename?: 'notification_notifications' }
  ) | null }
  & { __typename?: 'mutation_root' }
);


export const GetClaimCaseStatusesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getClaimCaseStatuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"claim_case_statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<GetClaimCaseStatusesQuery, GetClaimCaseStatusesQueryVariables>;
export const GetCompanyRolesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCompanyRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company_role_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<GetCompanyRolesQuery, GetCompanyRolesQueryVariables>;
export const CommonQueryGetInsuredBenefitTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"commonQueryGetInsuredBenefitTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insured_benefit_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<CommonQueryGetInsuredBenefitTypesQuery, CommonQueryGetInsuredBenefitTypesQueryVariables>;
export const CommonQueryGetInsuredRelationshipTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"commonQueryGetInsuredRelationshipTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insured_relationship_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<CommonQueryGetInsuredRelationshipTypesQuery, CommonQueryGetInsuredRelationshipTypesQueryVariables>;
export const CommonQueryGetPlanBalanceTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"commonQueryGetPlanBalanceTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plan_balance_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<CommonQueryGetPlanBalanceTypesQuery, CommonQueryGetPlanBalanceTypesQueryVariables>;
export const CommonQueryGetPolicyCopaymentTargetTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"commonQueryGetPolicyCopaymentTargetTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policy_co_payment_targets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<CommonQueryGetPolicyCopaymentTargetTypesQuery, CommonQueryGetPolicyCopaymentTargetTypesQueryVariables>;
export const CommonQueryMedicalProviderTypesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"commonQueryMedicalProviderTypes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mp_medical_provider_types"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<CommonQueryMedicalProviderTypesQuery, CommonQueryMedicalProviderTypesQueryVariables>;
export const SendEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"berry"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailInput"}}}]}]}}]}}]} as unknown as DocumentNode<SendEmailMutation, SendEmailMutationVariables>;
export const FillMbalMedicalProviderNotesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"fillMbalMedicalProviderNotes"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"claimCaseId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextStatus"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fillMbalMedicalProviderNotes"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"claimCaseId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"claimCaseId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextStatus"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextStatus"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<FillMbalMedicalProviderNotesMutation, FillMbalMedicalProviderNotesMutationVariables>;
export const GetPresentCaseStatusesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPresentCaseStatuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"present_case_statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","alias":{"kind":"Name","value":"name"},"name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<GetPresentCaseStatusesQuery, GetPresentCaseStatusesQueryVariables>;
export const UpsertCustomsettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"upsertCustomsettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"upsertCustomSettingInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpsertCustomSettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upsertCustomSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"upsertCustomSettingInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"upsertCustomSettingInput"}}}]}]}}]} as unknown as DocumentNode<UpsertCustomsettingsMutation, UpsertCustomsettingsMutationVariables>;
export const GetCustomSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getCustomSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"getCustomSettingInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GetCustomSettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCustomSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"getCustomSettingInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"getCustomSettingInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"numericValue"}},{"kind":"Field","name":{"kind":"Name","value":"booleanValue"}},{"kind":"Field","name":{"kind":"Name","value":"jsonbValue"}},{"kind":"Field","name":{"kind":"Name","value":"textValue"}},{"kind":"Field","name":{"kind":"Name","value":"timestamptzValue"}}]}}]}}]} as unknown as DocumentNode<GetCustomSettingQuery, GetCustomSettingQueryVariables>;
export const CommonGlobalCountersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"commonGlobalCounters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"type"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"globalCounters"},"name":{"kind":"Name","value":"update_global_counters_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"_inc"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"counter"},"value":{"kind":"IntValue","value":"1"}}]}},{"kind":"Argument","name":{"kind":"Name","value":"pk_columns"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"type"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"counter"}}]}}]}}]} as unknown as DocumentNode<CommonGlobalCountersMutation, CommonGlobalCountersMutationVariables>;
export const CreateNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"object"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"notification_notifications_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_notification_notifications_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"object"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateNotificationMutation, CreateNotificationMutationVariables>;