import type { ReactNode } from "react";

import { Tag } from "antd";
import { TYPE } from "config/constants";
import { utils } from "libs/utils";

const BenefitTag = (props: { className?: string; isSmall?: boolean; onClick?: () => void; style?: any; text?: string; textReplace?: ReactNode | string }) => {
  const { className, isSmall, onClick, style, text, textReplace } = props;
  if (text === undefined) return null;
  return (
    <Tag
      className={[(!isSmall && "") || "", className || ""].join(" ")}
      color={
        TYPE.BENEFIT_TYPE_NAME[text] === 1
          ? "volcano"
          : TYPE.BENEFIT_TYPE_NAME[text] === 2
          ? "cyan"
          : TYPE.BENEFIT_TYPE_NAME[text] === 5
          ? "default"
          : TYPE.BENEFIT_TYPE_NAME[text] === 6
          ? "pink"
          : TYPE.BENEFIT_TYPE_NAME[text] === 7
          ? "purple"
          : TYPE.BENEFIT_TYPE_NAME[text] === 4
          ? "red"
          : "gold"
      }
      onClick={onClick}
      style={style}
    >
      {textReplace || (text ? utils.pascalToSentence(text) : "")}
    </Tag>
  );
};

export default BenefitTag;
