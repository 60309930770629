import ClaimIcons from "app/common/components/ClaimIcons";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import TableList from "app/common/components/TableListV2";
import { FORMATTER, INSURER_COMPANY_IDS } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { differenceInCalendarDays, differenceInYears, subDays } from "date-fns";
import utils from "libs/utils";
import { graphql } from "sdk/v2/graphql";

const query = graphql(`
  query ClaimCasesForSlvHsTat($where: claim_cases_bool_exp!) {
    claim_cases(where: $where) {
      id
      created_at
      claim_case_status {
        id: value
        comment
        value
      }
    }
  }
`);

const now = new Date();

const SlvHsTATList = () => {
  const { data } = usePQuery(query, {
    variables: {
      where: {
        created_at: {
          _gte: new Date(2024, 3).toISOString(),
          _lte: subDays(now, 28).toISOString(),
        },
        insured_certificate: {
          policy: {
            insurer_id: { _eq: INSURER_COMPANY_IDS.SLV },
          },
        },
        source: { _neq: "SLV_HISTORY" },
        status: {
          _nin: ["Approved", "Declined", "Suspension", "Halted", "Paid", "Cancelled"],
        },
      },
    },
  });

  return (
    <TableList
      columns={[
        {
          key: "code",
          render: (claim) => <ClaimIcons claim={claim} />,
        },
        {
          key: "slv_tat",
          render: (claim) => differenceInCalendarDays(new Date(), new Date(claim.created_at)),
          title: "Process TAT",
          width: 120,
        },
        {
          dataIndex: "created_at",
          render: (text: string) => {
            if (differenceInYears(new Date(text), new Date()) === 0) {
              return utils.formatDate(text, FORMATTER.DATE_TIME_WITHOUT_SEC_AND_YEAR);
            }
            return utils.formatDate(text, FORMATTER.DATE_TIME_WITHOUT_SEC);
          },
          title: "Tạo lúc",
        },
        {
          dataIndex: "claim_case_status",
          render: (value) => <ClaimStatus value={value} />,
          title: "Trạng thái",
        },
      ]}
      dataSource={[...(data?.claim_cases ?? [])].sort(
        (a, b) => differenceInCalendarDays(new Date(), new Date(b.created_at)) - differenceInCalendarDays(new Date(), new Date(a.created_at)),
      )}
      title="Danh sách HSBT sắp đến hạn 30 ngày"
    />
  );
};

export default SlvHsTATList;
