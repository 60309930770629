import type { TableRowSelection } from "antd/es/table/interface";
import type { PolicyTermsQuery } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { Key } from "react";
import type { PolicyTermsBoolExp } from "sdk/gql/types";

import { Button, Input, Space, Tooltip } from "antd";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import { PolicyTermsDocument } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER } from "config/constants";
import usePQuery from "contexts/usePQuery";
import utils from "libs/utils";
import { cloneDeep, debounce } from "lodash";
import { useMemo, useRef, useState } from "react";

import { parsePlaceholderTextsToValue } from "./parsePlaceholderTextsToValue";

const PolicyTerms = ({ onSubmit }: { onSubmit: (texts) => void }) => {
  const { claim } = useClaim();
  const searchText = useRef("");
  const defaultWhere: PolicyTermsBoolExp = useMemo(() => {
    if (claim == null) return {};
    return {
      _and: [
        {
          _or: [
            { policy_id: { _eq: claim.insured_certificate.policy.id } },
            {
              plan_ids: { _contains: [claim.insured_certificate.plan_id] },
            },
          ],
        },
        {
          _or: [{ benefit_type: { _eq: claim.benefit_type } }, { benefit_type: { _is_null: true } }],
        },
      ],
      parent_id: { _is_null: true },
    };
  }, [claim]);
  const defaultSearchWhere: PolicyTermsBoolExp = {
    _and: [
      {
        _or: [{ benefit_type: { _eq: claim?.benefit_type } }, { benefit_type: { _is_null: true } }],
      },
    ],
  };
  const { data, loading, refetch } = usePQuery(PolicyTermsDocument, {
    variables: {
      searchWhere: defaultSearchWhere,
      where: defaultWhere,
    },
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const rowSelection: TableRowSelection<PolicyTermsQuery["policy_terms"][number]> = {
    columnWidth: 40,
    hideSelectAll: true,
    onChange: (tableSelectedRowKeys) => {
      setSelectedRowKeys(tableSelectedRowKeys);
    },
    renderCell: (_, record, _index, originNode) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if ((record.children?.length ?? 0) > 0) return null;
      return originNode;
    },
    selectedRowKeys,
  };
  if (data == null) return null;
  const onFinish = () => {
    let content = "";

    selectedRowKeys.forEach((key) => {
      data.policy_terms.forEach(({ children, content: parentContent, id }) => {
        if (id !== key && children.some((t) => t.id === key) === false && children.some((t) => t.children.some((t3) => t3.id === key)) === false) {
          return;
        }
        if (parentContent != null) {
          content += `${parentContent}\n`;
        }
        children.forEach((term2) => {
          if (term2.children.some((term3) => term3.id === key) === false && term2.id !== key) {
            return;
          }
          if (parentContent != null) {
            content = `${content}\t`;
          }
          content += `${term2.content}\n`;
          content = parsePlaceholderTextsToValue(content, {
            actual_paid_amount: utils.formatNumber(claim?.claim_case_payment?.actual_paid_amount),
            co_payment_amount: utils.formatNumber(claim?.claim_case_payment?.co_payment_amount),
            coverage_amount: utils.formatNumber(claim?.claim_case_payment?.coverage_amount),
            diagnosis: claim?.diagnosis,
            effective_date: utils.formatDate(claim?.insured_certificate.effective_date, FORMATTER.DATE_FORMAT),
            // out_patient_per_case_limit: "0",
            // dental_per_case_limit: "0",
            // per_case_limit: "0",
            // annual_limit: "0",
            event_date:
              claim?.physical_examination_date == null
                ? `${utils.formatDate(claim?.admission_date, FORMATTER.DATE_FORMAT)} - ${utils.formatDate(claim?.discharge_date, FORMATTER.DATE_FORMAT)}`
                : utils.formatDate(claim.physical_examination_date, FORMATTER.DATE_FORMAT),
            // out_patient_annual_limit: "0",
            medical_provider_name: claim?.medical_provider?.name,
            plan_name: claim?.insured_certificate.policy_plan?.plan_name,
          });
          term2.children.forEach((term3) => {
            if (term3.id === key) {
              content += `\t\t${term3.content
                ?.split("\n")
                .map((c, index) => {
                  if (index > 0) return `\t\t${c}`;
                  return c;
                })
                .join("\n")}\n`;
            }
          });
        });
      });
    });

    onSubmit(content);
  };

  let terms = cloneDeep(data.policy_terms);
  // filter 2nd level
  // can't apply query filtering, has to filter manually by mutation the first level children
  terms.forEach((paramTerm) => {
    const term = paramTerm;
    term.children = term.children.filter((t) => t.insured_benefit_type == null || t.insured_benefit_type.value === claim?.benefit_type);
  });

  terms.forEach((paramTerm) => {
    if (paramTerm.children.some((t) => t.children.length > 0)) return;
    const term = paramTerm;
    term.children = term.children.filter((t) => t.content?.toLowerCase().includes(searchText.current.toLowerCase()));
  });

  terms = terms.filter((i) => i.children.length > 0);

  const totalTerms = terms
    .flatMap((i) => [...i.children.map((c) => (c.children.length === 0 ? c.id : null)), ...i.children.flatMap((j) => j.children.map((c) => c.id))])
    .filter(Boolean).length;

  const columns = [
    {
      key: "content",
      render: (record) => {
        if (record.children?.length > 0) {
          return (
            <Tooltip title={import.meta.env.DEV ? record.id : undefined}>
              <span style={{ fontWeight: "bold" }}>
                <div>
                  <If condition={record.note != null}>{record.note}:</If>
                </div>
                {record.content}
              </span>
            </Tooltip>
          );
        }

        return (
          <Tooltip title={import.meta.env.DEV ? record.id : undefined}>
            <If condition={record.note != null}>
              <span style={{ fontWeight: "bold" }}>{record.note}</span>
            </If>
            <span style={{ display: "flex", whiteSpace: "pre-wrap" }}>{record.content}</span>
          </Tooltip>
        );
      },
      title: () => (
        <Input.Search
          allowClear
          onChange={debounce((e) => {
            searchText.current = e.target.value;
            refetch({
              searchWhere: {
                ...defaultSearchWhere,
                _or: [{ content: { _ilike: `%${e.target.value}%` } }],
              },
              where: {
                ...defaultWhere,
                _or: [
                  {
                    children: {
                      children: {
                        content: { _ilike: `%${e.target.value}%` },
                      },
                    },
                  },
                  {
                    children: {
                      content: { _ilike: `%${e.target.value}%` },
                    },
                  },
                ],
              },
            });
          }, 500)}
          placeholder={`Tìm kiếm ${totalTerms} điều khoản`}
          style={{ width: 300 }}
        />
      ),
    },
  ];

  return (
    <Space align="center" direction="vertical">
      <TableList
        dataSource={terms}
        expandable={{ defaultExpandAllRows: true }}
        loading={loading}
        nativeScroll={{ y: "50vh" }}
        onRow={(record) => ({
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (record.children?.length > 0) return;
            if (selectedRowKeys.includes(record.id)) {
              setSelectedRowKeys(selectedRowKeys.filter((key) => key !== record.id));
            } else {
              setSelectedRowKeys([...selectedRowKeys, record.id]);
            }
          },
        })}
        pagination={{ pageSize: 100 }}
        rowSelection={rowSelection}
        schema={columns}
        style={{ width: "80vw" }}
      />
      <Button onClick={onFinish} type="primary">
        Chọn {selectedRowKeys.length > 0 ? `(${selectedRowKeys.length})` : ""}
      </Button>
    </Space>
  );
};

export default PolicyTerms;
