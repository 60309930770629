export const sendLogToBigQuery = async ({ data, topic }) => {
  if (import.meta.env.DEV) {
    //
  } else {
    fetch("https://pub-sub-logs-sw4ckqn23a-as.a.run.app", {
      body: JSON.stringify({
        message: JSON.stringify(data),
        topic,
      }),
      headers: {
        Authorization: "RW1Md1pKRUJjMlJLeEMtVmFZS1k6c1FVNHhPZXJTN2l6WTZPazFLNjZ4dw==",
        "Content-Type": "application/json",
      },
      method: "POST",
    }).catch();
  }
};
