import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation CreateUpdateClaimDetail($input: CreateUpdateClaimDetailInput!, $options: CreateUpdateClaimDetailOptions) {
    createUpdateClaimDetail(input: $input, options: $options) {
      claimCaseDetailId
      claimCasePaymentId
      error {
        code
        message
        planBalanceId
      }
    }
  }
`);
