import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { ReactNode } from "react";

import { Alert, Badge, Button, Modal, Space, Tag } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import BenefitTag from "app/common/components/BenefitTagV3";
import Copyable from "app/common/components/Copyable";
import ExternalLink from "app/common/components/ExternalLink";
import If from "app/common/components/If";
import LabelDisplay from "app/common/components/LabelDisplay";
import VIPLevel from "app/common/components/VIPLevel";
import { PORTAL_PATH } from "app/portal/config/paths";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import { FORMATTER } from "config/constants";
import usePMutation from "contexts/usePMutation";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import { isGreaterThanZero } from "libs/hidash";
import { utils } from "libs/utils";
import { difference, orderBy } from "lodash";
import { AlertTriangle, CircleCheck, FilesIcon, Info } from "lucide-react";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import PolicyStatus from "./components/PolicyStatus";

interface InsuranceInfoItem {
  label: string;
  render: (claim: ClaimContextType["claim"] | NonNullable<ClaimContextType["claim"]>["original_claim_case"], claimDateRanges: ClaimContextType["claimDateRanges"]) => ReactNode;
  span?: number;
  visible?: boolean;
}

const WarningTarget = () => (
  <Space size={3}>
    <AlertTriangle color="#ffcc00" size={14} /> <span>Có</span>
  </Space>
);

const PolicyDocument = ({ claim }: { claim: ClaimContextType["claim"] }) => {
  const [convertFile] = usePMutation(
    graphql(`
      mutation Convert($fileId: UUID!) {
        convertFileType(fileId: $fileId)
      }
    `),
    {
      refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
    },
  );

  return (
    <ul>
      {claim?.insured_certificate.policy.policy_documents.map((item) => {
        const pdfAlternative = item.file?.alternative_format_files.find((file) => file.mime_type === "application/pdf");
        return (
          <li key={item.file?.url}>
            <If
              condition={new Set([
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
              ]).has(item.file?.mime_type ?? "")}
              else={
                <a href={`${item.file?.url}`} target="policy_file">
                  {item.file?.name}
                </a>
              }
            >
              <If
                condition={pdfAlternative == null}
                else={
                  <a href={`${pdfAlternative?.url}`} target="policy_file">
                    {item.file?.name}
                  </a>
                }
              >
                <a
                  href={`https://docs.google.com/gview?url=${item.file?.url}`}
                  onClick={() => item.file?.id != null && convertFile({ variables: { fileId: item.file.id } })}
                  target="policy_file"
                >
                  {item.file?.name}
                </a>
              </If>
            </If>
          </li>
        );
      })}
    </ul>
  );
};

const dateRender = (date: Date | null | string | undefined, format?: (typeof FORMATTER)[keyof typeof FORMATTER]) =>
  date == null ? "--" : utils.formatDate(date, format ?? FORMATTER.DATE_FORMAT);

export const useCommonClaimInsuranceInfo = (): InsuranceInfoItem[] => {
  const [modal, contextHolder] = Modal.useModal();
  return [
    {
      label: "Công ty bảo hiểm",
      render: (claim) => {
        const insurer = claim?.insured_certificate.policy.insurer_company;
        if (insurer == null) {
          return "--";
        }
        return <Link to={generatePath(PORTAL_PATH.COMPANY_DETAIL, { companyId: insurer.id })}>{insurer.name}</Link>;
      },
    },
    {
      label: "Nhân viên",
      render: (claim) => {
        const insuredPersonInfoOfEmployee = claim?.insured_certificate.parent_insured_certificate?.insured_person ?? claim?.insured_certificate.insured_person;
        const insuredCertificateOfEmployee = claim?.insured_certificate.parent_insured_certificate ?? claim?.insured_certificate;

        if (insuredPersonInfoOfEmployee == null) {
          return "--";
        }
        return (
          <VIPLevel
            level={claim?.insured_certificate.insured_person.vip_level}
            text={
              <Space>
                {insuredCertificateOfEmployee != null && (
                  <Link
                    target="insured_person_detail"
                    to={generatePath(PORTAL_PATH.CERTIFICATE_DETAIL, {
                      certificateId: insuredCertificateOfEmployee.id,
                      policyId: insuredCertificateOfEmployee.policy.id,
                    })}
                  >
                    <Copyable>{insuredPersonInfoOfEmployee.name}</Copyable>
                  </Link>
                )}
              </Space>
            }
          />
        );
      },
    },
    {
      label: "Người được BH",
      render: (claim, claimDateRanges) => {
        const insuredPerson = claim?.insured_certificate.insured_person;
        const claimPlans = claimDateRanges?.claim_date_ranges.ranges ?? [];

        if (claim == null || insuredPerson == null) {
          return "--";
        }

        const insuredPersonInfoOfEmployee = claim.insured_certificate.parent_insured_certificate?.insured_person ?? claim.insured_certificate.insured_person;
        const relationshipToEmployee = insuredPerson.insured_relationship_tos.find((item) => item.insured_person_to_id === insuredPersonInfoOfEmployee.insured_person_id);

        return (
          <>
            <VIPLevel
              level={claim.insured_certificate.insured_person.vip_level}
              text={
                <Space>
                  <Link
                    target="insured_person_detail"
                    to={generatePath(PORTAL_PATH.CERTIFICATE_DETAIL, {
                      certificateId: claim.insured_certificate.insured_certificate_id,
                      policyId: claim.insured_certificate.policy.policy_id,
                    })}
                  >
                    <Copyable>{insuredPerson.name}</Copyable>
                  </Link>
                </Space>
              }
            />
            <Space direction="vertical">
              <If condition={claimPlans.length > 0}>
                Gói:{" "}
                {claimPlans.map((claimPlan) => (
                  <ExternalLink key={claimPlan.planId} to={PORTAL_PATH.PLAN_DETAIL.replace(":planId", claimPlan.plan?.plan_id ?? "")}>
                    {claimPlan.policy_plan?.plan_name ?? ""}
                  </ExternalLink>
                ))}
              </If>
            </Space>
            <If condition={relationshipToEmployee != null}>
              Mối quan hệ với nhân viên: <p>{relationshipToEmployee?.insured_relationship_type.comment}</p>
            </If>
          </>
        );
      },
    },
    {
      label: "Hiệu lực / kết thúc",
      render: (claim) => {
        const isDiff =
          difference(
            [utils.formatDate(claim?.insured_certificate.issued_at, FORMATTER.DATE_FORMAT), utils.formatDate(claim?.insured_certificate.dued_at, FORMATTER.DATE_FORMAT)],
            [
              utils.formatDate(claim?.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT),
              utils.formatDate(claim?.insured_certificate.policy.dued_at, FORMATTER.DATE_FORMAT),
            ],
          ).length > 0;
        const showHightLight = isDiff || claim?.insured_certificate.status === "CANCELLED";
        const Content = () => (
          <Space direction="vertical" style={{ backgroundColor: showHightLight ? "#ffe590" : undefined }}>
            <div>Thẻ: {[claim?.insured_certificate.issued_at, claim?.insured_certificate.dued_at].map((item) => utils.formatDate(item, FORMATTER.DATE_FORMAT)).join(" - ")}</div>
            <div>
              HĐ:{" "}
              {[claim?.insured_certificate.policy.issued_at, claim?.insured_certificate.policy.dued_at].map((item) => utils.formatDate(item, FORMATTER.DATE_FORMAT)).join(" - ")}
            </div>
            <If condition={claim?.insured_certificate.status === "CANCELLED"}>
              <div>
                <Tag color="error" icon={<Info size={12} />}>
                  Thẻ đã hủy: cần từ chối claim
                </Tag>
              </div>
            </If>
            <If condition={isDiff}>
              <div>
                <Tag color="error" icon={<Info size={12} />}>
                  Hiệu lực thẻ khác hiệu lực HĐ
                </Tag>
              </div>
            </If>
          </Space>
        );
        if (showHightLight) {
          return (
            <Badge.Ribbon color="magenta" text="Chú ý">
              <Content />
            </Badge.Ribbon>
          );
        }
        return <Content />;
      },
    },
    {
      label: "Thời gian tạo",
      render: (claim) => (
        <div>
          <div>{utils.formatDate(claim?.created_at, FORMATTER.DATE_TIME_FORMAT)}</div>
          <div>
            Người tạo:{" "}
            {claim?.created_by_user == null ? "--" : <Link to={PORTAL_PATH.CM.ADMIN_USER_EDIT.replace(":userId", claim.created_by_user.id)}>{claim.created_by_user.name}</Link>}
          </div>
        </div>
      ),
    },
    {
      label: "Chủ hợp đồng",
      render: (claim) => (
        <Space direction="vertical">
          <div>
            Mã HĐ:{" "}
            <ExternalLink target="company_detail" to={PORTAL_PATH.POLICY_INFO.replace(":id", claim?.insured_certificate.policy.id ?? "")}>
              {claim?.insured_certificate.policy.policy_number}
            </ExternalLink>
          </div>
          <div>Chủ hợp đồng: {claim?.insured_certificate.policy.corporate_company?.name}</div>
          <If condition={claim?.insured_certificate.subsidiary_company_name != null}>
            <div>Cty thành viên: {claim?.insured_certificate.subsidiary_company_name}</div>
          </If>
          <LabelDisplay objectId={claim?.insured_certificate.policy_plan?.policy.policy_id} />
        </Space>
      ),
    },
    {
      label: "Số tiền yêu cầu",
      render: (claim) => {
        if (claim?.__typename === "original_claim_cases") {
          return claim.requested_amount == null ? "--" : utils.formatCurrency(claim.requested_amount);
        }
        // @ts-ignore
        return claim?.original_claim_case?.requested_amount == null ? "--" : utils.formatCurrency(claim.original_claim_case?.requested_amount ?? 0);
      },
    },
    {
      label: "Loại quyền lợi",
      render: (claim) => <BenefitTag value={claim?.insured_benefit_type} />,
    },
    {
      label: "Cập nhật lần cuối",
      render: (claim) => utils.formatDate(claim?.updated_at, FORMATTER.DATE_TIME_FORMAT),
    },
    {
      label: "Loại hợp đồng",
      render: (claim) => <Tag color="#3b5999">{claim?.insured_certificate.policy.policy_kind.comment}</Tag>,
    },
    {
      label: "File hợp đồng",
      render: (claim) => (
        <If condition={(claim?.insured_certificate.policy.policy_documents.length ?? 0) > 0} else="--">
          <Button
            onClick={() =>
              modal.info({
                content: <PolicyDocument claim={claim} />,
                maskClosable: true,
                title: "File hợp đồng",
                width: 500,
              })
            }
            type="text"
          >
            <FilesIcon color="#1890ff" />
          </Button>
          {contextHolder}
        </If>
      ),
    },
  ];
};

export const lifeInsuranceInfo: InsuranceInfoItem[] = [
  {
    label: "Công ty bảo hiểm",
    render: (claim) => {
      const insurer = claim?.insured_certificate.policy.insurer_company;
      if (insurer == null || insurer.id == null) {
        return "--";
      }
      return <Link to={PORTAL_PATH.COMPANY_DETAIL.replace(":companyId", insurer.id)}>{insurer.name}</Link>;
    },
  },
  {
    label: "Chủ hợp đồng",
    render: (claim) => {
      if (claim?.insured_certificate.policy.types === "Individual") {
        return (
          <ExternalLink to={PORTAL_PATH.INSURED_PERSON_DETAIL.replace(":id", claim.insured_certificate.policy.insured_person?.insured_person_id ?? "")}>
            <Copyable show={false}>{claim.insured_certificate.policy.insured_person?.name}</Copyable>
          </ExternalLink>
        );
      }
      return (
        <ExternalLink to={PORTAL_PATH.COMPANY_DETAIL.replace(":companyId", claim?.insured_certificate.policy.corporate_company?.id ?? "")}>
          {claim?.insured_certificate.policy.corporate_company?.name}
        </ExternalLink>
      );
    },
  },
  {
    label: "Người được BH",
    render: (claim) =>
      claim?.insured_certificate.policy.id != null && (
        <ExternalLink to={generatePath(PORTAL_PATH.CERTIFICATE_DETAIL, { certificateId: claim.insured_certificate.id, policyId: claim.insured_certificate.policy.id })}>
          <Copyable show={false}>{claim.insured_certificate.insured_person.name}</Copyable>
        </ExternalLink>
      ),
  },
  {
    label: "Claim được tạo",
    render: (claim) => claim?.claim_source.comment,
  },
  {
    label: "Thời gian tạo",
    render: (claim) => (
      <div>
        <div>{utils.formatDate(claim?.created_at, FORMATTER.DATE_TIME_FORMAT)}</div>
        <div>
          Người tạo:{" "}
          {claim?.created_by_user == null ? "--" : <Link to={PORTAL_PATH.CM.ADMIN_USER_EDIT.replace(":userId", claim.created_by_user.id)}>{claim.created_by_user.name}</Link>}
        </div>
      </div>
    ),
  },
  {
    label: "Cập nhật lần cuối",
    render: (claim) => dateRender(claim?.updated_at, FORMATTER.DATE_TIME_FORMAT),
  },
  {
    label: "Loại chủ hđ",
    render: (claim) => <Tag color={claim?.insured_certificate.policy.types === "Corporate" ? "red" : "cyan"}>{claim?.insured_certificate.policy.types}</Tag>,
  },
  {
    label: "Tình trạng hđ chính",
    render: (claim) => <PolicyStatus status={claim?.insured_certificate.policy.status} />,
  },
  {
    label: "Ngày hiệu lực hđ chính",
    render: (claim) => dateRender(claim?.insured_certificate.policy.effective_date),
  },
  {
    label: "Ngày mất hiệu lực hđ chính",
    render: (claim) => dateRender(claim?.insured_certificate.policy.lapsed_date),
  },
  {
    label: "Ngày khôi phục hiệu lực hđ chính",
    render: (claim) => dateRender(claim?.insured_certificate.policy.reinstated_date),
  },
  {
    label: "Ngày hết hạn hđ chính",
    render: (claim) => dateRender(claim?.insured_certificate.policy.expiry_date),
  },
  {
    label: "Ngày hiêu lực hđ rider",
    render: (claim) => dateRender(claim?.insured_certificate.effective_date),
  },

  {
    label: "Ngày mất hiệu lực hđ rider",
    render: (claim) => {
      const certEvents = orderBy(
        claim?.insured_certificate.insured_certificate_related_events.filter(
          (event) => event.event_type === "STATUS_CHANGED" && event.old_value === "ACTIVE" && event.new_value === "LAPSED",
        ),
        (event) => new Date(event.effective_date),
        "desc",
      );
      if (certEvents.length === 0) return "--";
      return (
        <div>
          {certEvents.map((event) => (
            <div key={event.effective_date}>{dateRender(event.effective_date)}</div>
          ))}
        </div>
      );
    },
  },
  {
    label: "Ngày khôi phục hiệu lực hđ rider",
    render: (claim) => {
      const certEvents = orderBy(
        claim?.insured_certificate.insured_certificate_related_events.filter(
          (event) => event.event_type === "STATUS_CHANGED" && event.old_value === "LAPSED" && event.new_value === "ACTIVE",
        ),
        (event) => new Date(event.effective_date),
        "desc",
      );
      if (certEvents.length === 0) return "--";
      return (
        <div>
          {certEvents.map((event) => (
            <div key={event.effective_date}>{dateRender(event.effective_date)}</div>
          ))}
        </div>
      );
    },
  },
  {
    label: "Ngày hết hạn hđ rider",
    render: (claim) => dateRender(claim?.insured_certificate.expiry_date),
  },
  {
    label: "Mã sản phẩm rider",
    render: (claim, claimDateRanges) => {
      const claimPlans = claimDateRanges?.claim_date_ranges.ranges ?? [];
      return (
        <div>
          {claimPlans.map((claimPlan) => (
            <p key={claimPlan.planId}>
              <Link to={PORTAL_PATH.PLAN_DETAIL.replace(":planId", claimPlan.plan?.plan_id ?? "")}>{claimPlan.policy_plan?.plan_name ?? ""}</Link>
            </p>
          ))}
        </div>
      );
    },
  },
  {
    label: "Ngày HĐ rider bị terminate",
    render: (claim) =>
      dateRender(
        claim?.insured_certificate.insured_certificate_related_events.find((item) => item.event_type === "STATUS_CHANGED" && item.new_value === "REVERSED")?.effective_date,
      ),
  },
  {
    label: "Ngày hủy rider (Surrender)",
    render: (claim) => dateRender(claim?.insured_certificate.policy.surrender_date),
  },
  {
    label: "Ngày đến hạn đóng phí",
    render: (claim) => dateRender(claim?.insured_certificate.policy.due_date),
  },
  {
    label: "Ngày LA qua đời",
    render: (claim) => dateRender(claim?.insured_certificate.insured_person.died_at),
  },
  {
    label: "Ngày LA xảy ra thương tật toàn bộ vĩnh viễn",
    render: (claim) => dateRender(claim?.insured_certificate.insured_person.tpd_date),
  },
  { label: "Loại quyền lợi", render: (claim) => <BenefitTag value={claim?.insured_benefit_type} /> },

  {
    label: "Tình trạng hđ rider",
    render: (claim) => <PolicyStatus status={claim?.insured_certificate.status} />,
  },
  // {
  //   label: "Điều khoản loại trừ",
  //   render: (claim) => claim?.fwd_claim_case?.exclusion_info ?? "--",
  // },
  // {
  //   label: "Ghi chú hợp đồng",
  //   render: (claim) => claim?.fwd_claim_case?.policy_note ?? "--",
  // },
  {
    label: "Ngày ký hợp đồng",
    render: (claim) => dateRender(claim?.insured_certificate.settle_date),
  },
  {
    label: "Đại lý bị theo dõi",
    render: (claim) => (claim?.agent?.watch_by == null ? "Không" : <WarningTarget />),
  },
  {
    label: "Cơ sở y tế bị theo dõi",
    render: (claim) =>
      isGreaterThanZero(
        claim?.medical_provider?.medical_provider_mappings.filter((mpm) => mpm.watch_by != null && mpm.watch_by.insurer_id === claim.insured_certificate.policy.insurer_company?.id)
          .length,
      ) ? (
        <WarningTarget />
      ) : (
        <span>Không</span>
      ),
  },
  {
    label: "Khách hàng bị theo dõi",
    render: (claim) => {
      if (claim?.__typename !== "claim_cases") {
        return null;
      }

      return claim.insured_certificate.insured_person.watch_by.some((wb) => wb.insurer_id === claim.insured_certificate.policy.insurer_company?.id) === true ||
        claim.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.client_black_list === true ? (
        <WarningTarget />
      ) : (
        "Không"
      );
    },
  },
  {
    label: "Bảo lãnh viện phí",
    render: (claim) =>
      claim?.is_direct_billing === true ? (
        <Space>
          <CircleCheck color="#52c41a" size={14} />
          <span>Có</span>
        </Space>
      ) : (
        "Không"
      ),
  },
  {
    label: "Auto Reins",
    render: (claim) =>
      claim?.insured_certificate.insured_certificate_related_events.find((item) => item.new_value === "LAPSED" || item.event_type === "AUTOMATIC_REINSTATEMENT")?.event_type ===
      "AUTOMATIC_REINSTATEMENT"
        ? "Có"
        : "Không",
    span: 3,
  },
  {
    label: "Underwriting notes",
    render: (claim) => (
      <If condition={(claim?.insured_certificate.insured_certificate_underwriting_notes.length ?? 0) > 0} else={<div>---</div>}>
        <Alert
          description={
            <Paragraph copyable={{ text: claim?.insured_certificate.insured_certificate_underwriting_notes.map((item) => item.note).join("\n") }}>
              <ul>{claim?.insured_certificate.insured_certificate_underwriting_notes.map((item) => <li key={item.note}>{item.note}</li>)} </ul>
            </Paragraph>
          }
          showIcon
          type="warning"
        />
      </If>
    ),
    span: 4,
  },
];
