import { INSURER_COMPANY_IDS } from "./constants";

export const APP_HOSTNAME = import.meta.env.DEV ? "http://localhost:3000" : "https://portal.care.papaya.asia";
export const GOOGLE_CLIENT_ID = "88786060412-1dcgng8pd1b8trton00aq8be57pp82io.apps.googleusercontent.com";
export const FIREBASE_VAPIKEY = "BI2qsS8WzVl7fz_KK5pEHvOMud9XXm707CVAabPqkJDLfFBdS11GfAeUGXtK9I9E5hdgl6U1YEHdhTRIGU0td2o";

export const FIREBASE_CONFIG_PROD = {
  apiKey: "AIzaSyDCaVG5azeuwanL2onC0iIIrhL2LU_f3qc",
  appId: "1:88786060412:web:7a1dc5aef133e0df09f5f9",
  authDomain: "careportal-357509.firebaseapp.com",
  measurementId: "G-M60HT8ZLYS",
  messagingSenderId: "88786060412",
  projectId: "careportal-357509",
  storageBucket: "careportal-357509.appspot.com",
};
export const FIREBASE_CONFIG_DEV = {
  apiKey: "AIzaSyCbzzPuUrfFWeDcfhimrTViOedknK-u6Hk",
  appId: "1:179458186542:web:5a490c589b56e4b0d2eebf",
  authDomain: "careportal-dev.firebaseapp.com",
  measurementId: "G-YLFH4CNBPM",
  messagingSenderId: "179458186542",
  projectId: "careportal-dev",
  storageBucket: "careportal-dev.appspot.com",
};
export const BCRYPT_HASH_NUMBER = 10;
export const USER_TO_EXCLUDE_FROM_CLAIMS_LIST = [
  // Production
  "1e6e8ca1-e547-4732-8775-4be2d275e96d", // "Trương Hữu Lộc"
];

export { HOLIDAYS_TO_EXCLUDE_FROM_TAT } from "./holidays";

const DEV_NOTIFY_CHANNELS = "https://hooks.slack.com/services/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";

const _SLACK =
  import.meta.env.VITE_STAGE === "prod"
    ? ({
        ERROR_REPORT_CHANNEL: "https://hooks.slack.com/services/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",
        FWD_BLVP_CHANNEL: "https://hooks.slack.com/services/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",
        FWD_MR_CLAIM_CHANNEL: "https://hooks.slack.com/services/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",
        HC_BLVP_CHANNEL: "https://hooks.slack.com/services/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",
        HC_CLAIM_CHANNEL: "https://hooks.slack.com/services/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",
        MBAL_HS_CLAIM_CHANNEL: "https://hooks.slack.com/services/THMHBQQQ6/B05D7PW55SL/zMry3lPeK2DeMoEbBTV3sfbc",
        SLV_HS_CLAIM_CHANNEL: "https://hooks.slack.com/services/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",
        TECH_TRACK_CLICKS: "https://hooks.slack.com/services/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",
        TOKEN: "xoxb-599589840822-2178398155636-1TBER4mzC6k1yPwarBNDYAG8",
      } as const)
    : ({
        CLAIM_HC_CHANNEL: DEV_NOTIFY_CHANNELS,
        ERROR_REPORT_CHANNEL: DEV_NOTIFY_CHANNELS,
        FWD_BLVP_CHANNEL: DEV_NOTIFY_CHANNELS,
        FWD_MR_CLAIM_CHANNEL: DEV_NOTIFY_CHANNELS,
        HC_BLVP_CHANNEL: DEV_NOTIFY_CHANNELS,
        MBAL_HS_CLAIM_CHANNEL: DEV_NOTIFY_CHANNELS,
        SLV_HS_CLAIM_CHANNEL: DEV_NOTIFY_CHANNELS,
        TECH_TRACK_CLICKS: DEV_NOTIFY_CHANNELS,
        TOKEN: "xoxb-599589840822-2178398155636-1TBER4mzC6k1yPwarBNDYAG8",
      } as const);

const _SLACK_CHANNEL_IDS = {
  // [INSURER.FWD]: "C04JKC8C6J2", // #claim_mr
  // [INSURER.MBAL]: "C052210KHEF", // #claim_mbal
  // SALES_OPS: "C03LG5PQR70", // #sales_ops
  DEV: "C0555MBMZ6F", // #dev-notify-channels
  HAI: "U040LKERG31", // @hai
  LOC: "U021GMATR2L", // @loc
  PHUC: "U048468CP7T", // @phuc

  TRACK_ALL_USAGE: "C07FQHEF7JL", // #tech-track-usage
  // PAYOUT_NOTIFICATION: "C05HEN33AGM", // #payout_notification
  TRACK_USAGE: "C05KW9QBL7M", // #track-paper-copy
};

if (import.meta.env.VITE_STAGE !== "prod") {
  Object.keys(_SLACK_CHANNEL_IDS).forEach((key) => {
    _SLACK_CHANNEL_IDS[key as keyof typeof _SLACK_CHANNEL_IDS] = _SLACK_CHANNEL_IDS.DEV;
  });
}

export const SLACK = {
  ..._SLACK,
  CHANNEL_IDS: _SLACK_CHANNEL_IDS,
} as const;

export const BLVP_CHANNELS_MAPPING_BY_INSURER_ID = {
  [INSURER_COMPANY_IDS.FWD]: SLACK.FWD_BLVP_CHANNEL,
};

export const ASSIGNMENT = {
  ACTIONS: [
    "năn nỉ",
    "nhờ",
    "mong chờ",
    "sẽ đợi",
    "chờ đợi",
    "thỉnh cầu",
    "🙇🏻‍♀️",
    "👉🏻",
    "sẽ khao trà sữa nếu",
    "dí",
    "sẽ khao cafe nếu",
    "sẽ không về cho đến khi",
    "sẽ thấy vui khi",
    "sẽ cho kẹo khi",
    "sẽ hát một bài khi",
    "giãy đành đạch chờ",
    "làm mình làm mẩy chờ",
    "thật sự chân thành mong muốn một cách rất thành khẩn",
  ],
  ACTS: {
    APPROVAL: "đã duyệt phân cấp",
    CONTACT_CLIENT: "liên hệ khách hàng",
    SUPPLY_INFORMATION: "bổ sung thông tin",
    WAIT_FOR_MANAGER_APPROVE: "phê duyệt",
  },
};

export const TINY_MCE_CDN_URL = "https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.6.0/tinymce.min.js";

export const EDITOR_CONFIG = {
  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; width: 210mm; margin: 0 auto; padding-top: 16px }",
  height: "100%",
  menubar: false,
  plugins: ["lists", "link", "charmap", "anchor", "searchreplace", "visualblocks", "code", "table", "preview", "wordcount"],
  toolbar: "undo redo |bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | print | preview",
  visual: false,
  width: "100%",
};
