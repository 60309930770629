import { Form, Select } from "antd";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePQuery from "contexts/usePQuery";

import type { ClaimCaseDetailFormItem } from "../../schema";

import { GetPtiExtendedDataDocument } from "../../DetailForm.generated";
import styles from "../../DetailForm.module.css";

type Props = {} & ClaimCaseDetailFormItem;

const PtiDiseaseGroupField = ({ name }: Props) => {
  const { claim } = useClaim();
  const { data: ptiExtendedData } = usePQuery(GetPtiExtendedDataDocument, {
    skip: claim?.claim_case_id === undefined,
    variables: { claimId: claim?.claim_case_id ?? "" },
  });

  return (
    <Form.Item label="Nhóm bệnh (PTI)" name={name}>
      <Select
        className={styles.fullWidth}
        options={ptiExtendedData?.pti_disease_groups.map((disease) => ({
          label: disease.comment,
          value: disease.value,
        }))}
        size="large"
      />
    </Form.Item>
  );
};
export default PtiDiseaseGroupField;
