import type { ResultOf } from "@graphql-typed-document-node/core";
import type { ITableListProps } from "app/common/components/TableListV2";
import type { ClaimCaseForExport } from "app/portal/screens/TotalReport/SlvTpaReport/useColumns";
import type { VariablesOf } from "sdk/v2/graphql";

import { Col, Input, Modal, Progress, Row, Space, Typography } from "antd";
import { DatePicker } from "app/common/components/CustomAntdTimePickers";
import Spin from "app/common/components/Spin";
import TableList from "app/common/components/TableListV2";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import { useColumns } from "app/portal/screens/TotalReport/SlvTpaReport/useColumns";
import BPromise from "bluebird";
import { CLAIM_DECISION_STATUSES, FORMATTER, INSURER_COMPANY_IDS } from "config/constants";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePQuery from "contexts/usePQuery";
import { addYears, endOfDay, isAfter, isWithinInterval, startOfDay } from "date-fns";
import downloadFile from "libs/downloadFile";
import utils from "libs/utils";
import { get, isNil, sumBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlanBalanceTypesEnum } from "sdk/gql/types";
import * as XLSX from "xlsx";

import {
  CLAIM_CASES_FOR_SLV_TPA_REPORT_AGGREGATE_QUERY,
  CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY,
  SAME_INSURED_CERTIFICATE_CLAIM_CASES_QUERY,
  SLV_PLAN_INSURED_BENEFITS_QUERY,
} from "./graphql/queries";

const SEPARATOR = ",";

const DEFAULT_SEARCH_PARAMS = {
  page: "1",
  size: "10",
};

const useAllClaimCaseFetch = (slvPlanInsuredBenefits?: ResultOf<typeof SLV_PLAN_INSURED_BENEFITS_QUERY>) => {
  const [getAllClaimCases, { loading }] = usePLazyQuery(CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY, { fetchPolicy: "no-cache" });
  const LIMIT = 50;

  const getClaimCasesUntilEnough = async ({
    exportTime,
    offset,
    total,
    updateProgress,
    where,
  }: {
    exportTime: string;
    offset: number;
    total: number;
    updateProgress: (countFetchedItems) => void;
    where: VariablesOf<typeof CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY>["where"];
  }) => {
    const res = await getAllClaimCases({
      variables: {
        insurerId: INSURER_COMPANY_IDS.SLV,
        limit: LIMIT,
        offset,
        where: {
          ...where,
          created_at: { ...where.created_at, ...(where.created_at?._lte != null && where.created_at._lte < exportTime ? undefined : { _lte: exportTime }) },
        },
        withAggregate: false,
      },
    });
    const claimCases = res.data?.claim_cases ?? [];
    const countFetchItems = offset + claimCases.length;
    updateProgress(countFetchItems);
    if (claimCases.length === 0) {
      return claimCases;
    }

    const allClaims: ClaimCaseForExport[] = [
      ...claimCases,
      ...(await getClaimCasesUntilEnough({
        exportTime,
        offset: countFetchItems,
        total,
        updateProgress,
        where,
      })),
    ];
    return allClaims;
  };

  return [getClaimCasesUntilEnough, loading] as const;
};

const getRiderYearByClaimCaseEventDate = (issuedDate: Date, eventDate: Date) => {
  const oneYearLaterIssuedDate = addYears(issuedDate, 1);
  const isInRiderYear = isWithinInterval(eventDate, {
    end: oneYearLaterIssuedDate,
    start: issuedDate,
  });
  if (isInRiderYear === true) {
    return {
      end: oneYearLaterIssuedDate,
      start: issuedDate,
    };
  }
  return getRiderYearByClaimCaseEventDate(oneYearLaterIssuedDate, eventDate);
};

const SlvTpaReport = () => {
  const [searchParams, setSearchParams] = useSearchParams(DEFAULT_SEARCH_PARAMS);
  const pageSize = Number(searchParams.get("size"));
  const page = Number(searchParams.get("page"));
  const claimCode = searchParams.get("claim_code");

  const [skipExport, setSkipExport] = useState(true);
  const createdAtRange = searchParams.get("created_at_range")?.split(SEPARATOR);
  const [startDate, endDate] = createdAtRange ?? [];
  const { data: slvPlanInsuredBenefits } = usePQuery(SLV_PLAN_INSURED_BENEFITS_QUERY, {
    variables: {
      where: {
        plan: {
          insurer_id: { _eq: INSURER_COMPANY_IDS.SLV },
        },
      },
    },
  });

  const [getClaimCaseAggregate, { data: claimCaseAggregate, loading: loadingClaimAggregate }] = usePLazyQuery(CLAIM_CASES_FOR_SLV_TPA_REPORT_AGGREGATE_QUERY);
  const totalClaimCases = claimCaseAggregate?.claim_cases_aggregate.aggregate?.count ?? 0;
  const where: VariablesOf<typeof CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY>["where"] = useMemo(
    () => ({
      _or: claimCode === "" || claimCode == null ? undefined : claimCode.split(SEPARATOR).map((code) => ({ code: { _ilike: `%${code.trim()}%` } })),
      created_at:
        startDate != null && !Number.isNaN(Number(new Date(startDate))) && endDate != null && !Number.isNaN(Number(new Date(endDate)))
          ? {
              _gte: new Date(startDate).toISOString(),
              _lte: new Date(endDate).toISOString(),
            }
          : undefined,
      source: { _eq: "SLV_SYSTEM" },
    }),
    [startDate, endDate, claimCode],
  );

  const { data, loading } = usePQuery(CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY, {
    variables: {
      insurerId: INSURER_COMPANY_IDS.SLV,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      where,
      withAggregate: true,
    },
  });

  const [getClaimCases] = useAllClaimCaseFetch(slvPlanInsuredBenefits);
  const [getClaimBenefitDetails] = usePLazyQuery(ClaimBenefitDetailsDocument);
  const [getSameInsuredCertificateClaimCases] = usePLazyQuery(SAME_INSURED_CERTIFICATE_CLAIM_CASES_QUERY);
  const [claimsWithBenefitInfo, setClaimWithBenefitInfo] = useState<ClaimCaseForExport[]>();

  const getBenefitInfoForClaimCases = useCallback(
    async (claims: ResultOf<typeof CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY>["claim_cases"], setDataForTableView = false) => {
      const benefitDataForClaims = await BPromise.map(
        claims,
        async (claimCase) => {
          if (CLAIM_DECISION_STATUSES.includes(claimCase.status)) {
            const claimBenefitDetails = await getClaimBenefitDetails({
              variables: {
                claimId: claimCase.claim_case_id,
              },
            });
            const sameInsuredCertificateFinalStatusesClaimCases = await (async () => {
              if (claimCase.start_date == null) {
                return [];
              }
              const riderYear = getRiderYearByClaimCaseEventDate(new Date(claimCase.insured_certificate.issued_at), new Date(claimCase.start_date));
              const sameInsuredCertificateClaimCases = await getSameInsuredCertificateClaimCases({
                variables: {
                  where: {
                    insured_certificate_id: { _eq: claimCase.insured_certificate.id },
                    start_date: {
                      _gte: riderYear.start.toISOString(),
                      _lte: riderYear.end.toISOString(),
                    },
                    status: {
                      _in: [...CLAIM_DECISION_STATUSES],
                    },
                  },
                },
              });
              return sameInsuredCertificateClaimCases.data?.claim_cases ?? [];
            })();
            const hospitalClaimDetail = claimBenefitDetails.data?.claimBenefitDetails.claimCaseDetails.find((ccd) =>
              slvPlanInsuredBenefits?.plan_insured_benefits.some((pib) => pib.id === ccd.planInsuredBenefitId && pib.insured_benefit.code === "SL_IP_1"),
            );
            const hospitalBenefitUsed = hospitalClaimDetail?.balanceDetails.find((bd) => bd.type === PlanBalanceTypesEnum.DaysPerYear)?.balanceUsed;
            const hospitalBenefitUsedInClaim = hospitalClaimDetail?.paidTime;
            const icuClaimDetail = claimBenefitDetails.data?.claimBenefitDetails.claimCaseDetails.find((ccd) =>
              slvPlanInsuredBenefits?.plan_insured_benefits.some((pib) => pib.id === ccd.planInsuredBenefitId && pib.insured_benefit.code === "SL_IP_2"),
            );
            const icuBenefitUsed = icuClaimDetail?.balanceDetails.find((bd) => bd.type === PlanBalanceTypesEnum.DaysPerYear)?.balanceUsed;
            const icuBenefitUsedInClaim = icuClaimDetail?.paidTime;
            const surgeryClaimDetail = claimBenefitDetails.data?.claimBenefitDetails.claimCaseDetails.find((ccd) =>
              slvPlanInsuredBenefits?.plan_insured_benefits.some((pib) => pib.id === ccd.planInsuredBenefitId && pib.insured_benefit.code === "SL_SG_1"),
            );
            const surgeryBenefitUsed = surgeryClaimDetail?.balanceDetails.find((bd) => bd.type === PlanBalanceTypesEnum.TimesPerCertificate)?.balanceUsed;
            const surgeryBenefitUsedInClaim = surgeryClaimDetail?.paidTime;

            return {
              amountUsed: sumBy(sameInsuredCertificateFinalStatusesClaimCases, (cc) => cc.claim_case_payment?.actual_paid_amount ?? 0),
              amountUsedInClaim: claimCase.claim_case_payment?.actual_paid_amount,
              claimId: claimCase.claim_case_id,
              hospitalBenefitUsed,
              hospitalBenefitUsedInClaim,
              icuBenefitUsed,
              icuBenefitUsedInClaim,
              insuredCertificateId: claimCase.insured_certificate.id,
              insuredPersonId: claimCase.insured_certificate.insured_person.id,
              startDate: claimCase.start_date,
              surgeryBenefitUsed,
              surgeryBenefitUsedInClaim,
            };
          }
          return {
            claimId: claimCase.claim_case_id,
          };
        },
        {
          concurrency: 5,
        },
      );

      const claimsWithBenefit = claims.map((claimCase) => {
        const benefit = benefitDataForClaims.find(({ claimId }) => claimId === claimCase.claim_case_id);
        const sameInsuredPersonClaims = benefitDataForClaims.filter(
          ({ insuredCertificateId, insuredPersonId }) =>
            insuredPersonId === claimCase.insured_certificate.insured_person.id && insuredCertificateId === claimCase.insured_certificate.id,
        );
        const sameRiderYearClaims = sameInsuredPersonClaims.filter((claim) => {
          if (claim.startDate == null || claimCase.start_date == null) {
            return false;
          }
          const riderYear = getRiderYearByClaimCaseEventDate(new Date(claimCase.insured_certificate.issued_at), new Date(claimCase.start_date));
          return isWithinInterval(new Date(claim.startDate), riderYear);
        });
        const indexClaim = sameRiderYearClaims.findIndex(({ claimId }) => claimId === claimCase.claim_case_id);
        const laterClaims = sameRiderYearClaims.slice(indexClaim + 1);
        const hospitalBenefitUsedInOrder =
          benefit?.hospitalBenefitUsed == null || benefit.hospitalBenefitUsedInClaim == null
            ? null
            : benefit.hospitalBenefitUsed - sumBy(laterClaims, (c) => c.hospitalBenefitUsedInClaim ?? 0);
        const icuBenefitUsedInOrder =
          benefit?.icuBenefitUsed == null || benefit.icuBenefitUsedInClaim == null ? null : benefit.icuBenefitUsed - sumBy(laterClaims, (c) => c.icuBenefitUsedInClaim ?? 0);
        const surgeryBenefitUsedInOrder =
          benefit?.surgeryBenefitUsed == null || benefit.surgeryBenefitUsedInClaim == null
            ? null
            : benefit.surgeryBenefitUsed - sumBy(laterClaims, (c) => c.surgeryBenefitUsedInClaim ?? 0);
        const amountBenefitUsedInOrder = benefit?.amountUsed == null ? null : benefit.amountUsed - sumBy(laterClaims, (c) => c.amountUsedInClaim ?? 0);

        return {
          ...claimCase,
          amountBenefitUsedInOrder: amountBenefitUsedInOrder == null || amountBenefitUsedInOrder < 0 ? null : amountBenefitUsedInOrder,
          hospitalBenefitUsedInOrder: hospitalBenefitUsedInOrder == null || hospitalBenefitUsedInOrder < 0 ? null : hospitalBenefitUsedInOrder,
          icuBenefitUsedInOrder: icuBenefitUsedInOrder == null || icuBenefitUsedInOrder < 0 ? null : icuBenefitUsedInOrder,
          surgeryBenefitUsedInOrder: surgeryBenefitUsedInOrder == null || surgeryBenefitUsedInOrder < 0 ? null : surgeryBenefitUsedInOrder,
        };
      });
      if (setDataForTableView === true) {
        setClaimWithBenefitInfo(claimsWithBenefit);
      }
      return claimsWithBenefit;
    },
    [getClaimBenefitDetails, getSameInsuredCertificateClaimCases, slvPlanInsuredBenefits?.plan_insured_benefits],
  );

  const schema = useColumns(page, pageSize);
  const schemaForExport = useColumns();
  const countFetchItems = useRef(0);
  const onExport: ITableListProps<ClaimCaseForExport>["onExport"] = async () => {
    if (!skipExport) {
      return;
    }
    const time = new Date().toISOString();

    const aggregateData = await getClaimCaseAggregate({
      variables: {
        where: {
          ...where,
          created_at: { ...where.created_at, ...(where.created_at?._lte != null && where.created_at._lte < time ? undefined : { _lte: time }) },
        },
      },
    });
    setSkipExport(false);

    const allClaims = await getClaimCases({
      exportTime: time,
      offset: 0,
      total: aggregateData.data?.claim_cases_aggregate.aggregate?.count ?? 0,
      updateProgress: (value) => {
        countFetchItems.current = value;
      },
      where,
    });

    const allClaimsWithBenefit = await getBenefitInfoForClaimCases(allClaims);

    writeReportFile(allClaimsWithBenefit);
  };

  const writeReportFile = async (listExportedData: ClaimCaseForExport[] | null) => {
    if (listExportedData == null) {
      return;
    }

    const handleMapDataToExcel = (dataItem: (typeof listExportedData)[number], dataItemIdx: number) =>
      Object.fromEntries(
        schemaForExport.map((schemaItem) => {
          const dataIndex = "dataIndex" in schemaItem ? schemaItem.dataIndex : undefined;
          const { render, title } = schemaItem;
          const columnValue = (() => {
            const value = dataIndex == null ? null : get(dataItem, dataIndex);

            if (value != null && render != null) {
              return render(value, dataItem, dataItemIdx);
            }

            if (value != null) {
              return value;
            }

            if (render !== undefined) {
              return render(dataIndex === undefined ? dataItem : value, dataItem, dataItemIdx);
            }

            return "";
          })();
          return [title, columnValue];
        }),
      );

    const exportData = listExportedData.map((item, index) => handleMapDataToExcel(item, index));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const formattedStart = utils.formatDate(startDate, FORMATTER.DATE_FORMAT);
    const formattedEnd = utils.formatDate(endDate, FORMATTER.DATE_FORMAT);
    const formattedNow = utils.formatDate(new Date(), FORMATTER.DATE_FORMAT);
    const fileName = ["Slv_Tpa_Report", startDate == null ? "" : `_from${formattedStart}`, endDate == null ? "" : `_to${formattedEnd}`, `_updated_${formattedNow}.xlsx`].join("");

    const buffer = XLSX.write(workbook, { type: "buffer" });
    await downloadFile({
      buffer,
      name: fileName,
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    setSkipExport(true);
  };

  useEffect(() => {
    if (data?.claim_cases == null) {
      return;
    }

    getBenefitInfoForClaimCases(data.claim_cases, true);
  }, [data?.claim_cases, getBenefitInfoForClaimCases]);

  return (
    <Spin spinning={loading || loadingClaimAggregate}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space direction="vertical">
          <Typography.Title level={5}>Lọc yêu cầu bồi thường</Typography.Title>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <div>Thời điểm tạo HSBT:</div>
              <DatePicker.RangePicker
                disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
                format={FORMATTER.DATE_FORMAT}
                onChange={(values) => {
                  if (values == null) return;
                  const [start, end] = values;

                  setSearchParams((params) => {
                    if (isNil(start) || isNil(end)) return params;
                    params.set("created_at_range", [startOfDay(start).toISOString(), endOfDay(end).toISOString()].join(SEPARATOR));
                    return params;
                  });
                }}
                value={[createdAtRange?.[0] == null ? null : new Date(createdAtRange[0]), createdAtRange?.[1] == null ? null : new Date(createdAtRange[1])]}
              />
            </Col>
          </Row>
        </Space>
        <Space direction="vertical">
          <Typography.Title level={5}>Claim Code</Typography.Title>
          <Col span={24}>
            <Input
              defaultValue={claimCode ?? ""}
              onChange={(value) => {
                setSearchParams((params) => {
                  params.set("claim_code", value.target.value);
                  return params;
                });
              }}
            />
          </Col>
        </Space>
        <TableList
          data={claimsWithBenefitInfo}
          onExport={onExport}
          pagination={{
            current: page,
            onChange: (newPage, newPageSize) => {
              setSearchParams((params) => {
                params.set("page", String(newPage));
                params.set("size", String(newPageSize));
                return params;
              });
            },
            pageSize,
            total: data?.claim_cases_aggregate?.aggregate?.count,
          }}
          rowKey="claim_case_id"
          schema={schema}
          scroll={{ x: "300", y: "240" }}
          title="SLV TPA REPORT"
        />
      </Space>
      <Modal closable={false} maskClosable={false} okButtonProps={{ hidden: true }} open={!skipExport}>
        <Progress percent={Math.round((countFetchItems.current / totalClaimCases) * 100)} status="active" type="line" />
      </Modal>
    </Spin>
  );
};

export default SlvTpaReport;
