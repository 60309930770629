import { Checkbox, Form } from "antd";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";

import type { ClaimCaseDetailFormItem } from "../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const RelatedToAccidentField = ({ name }: Props) => {
  const { claim } = useClaim();

  return (
    <Form.Item label="Tai nạn" name={name} valuePropName="checked">
      <Checkbox>Có liên quan tới tai nạn</Checkbox>
    </Form.Item>
  );
};
export default RelatedToAccidentField;
