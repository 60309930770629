import { Form, Select } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvHospitalDepartmentTypeField = ({ name }: Props) => (
  <Form.Item
    label="Loại khoa điều trị"
    name={name}
    rules={[
      {
        message: "Không được để trống",
        required: true,
      },
    ]}
  >
    <Select>
      <Select.Option value="WM">Western Medicine</Select.Option>
      <Select.Option value="TM">Traditional Medicine</Select.Option>
      <Select.Option value="UK">Unknown</Select.Option>
    </Select>
  </Form.Item>
);
export default SlvHospitalDepartmentTypeField;
