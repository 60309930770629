import { graphql } from "sdk/v2/graphql";

export const GET_SLV_CLAIM_GROUP_LOGS_QUERY = graphql(`
  query SlvClaimGroupLogs($groupId: uuid!) {
    audit_logs_record_status_changes(where: { table_name: { _eq: SLV_CLAIM_CASE_SAME_EVENT_GROUPS }, record_id: { _eq: $groupId } }, order_by: { created_at: desc }) {
      id
      new_value
      old_value
      user {
        id
        name
      }
      created_at
    }
  }
`);

export const GET_SLV_SYNC_CLAIM_HISTORY_QUERY = graphql(`
  query SlvSyncClaimHistory($groupId: uuid!) {
    slv_sync_logs(where: { parent_sync_log_id: { _is_null: true }, claim_case_same_event_group_id: { _eq: $groupId } }, order_by: { created_at: desc }) {
      id
      children_sync_logs {
        id
        ended_at
        status
        sync_data
        sync_files {
          fileName
          mimeType
          url
        }
        created_at
      }
      ended_at
      status
      sync_data
      sync_files {
        fileName
        mimeType
        url
      }
      created_at
    }
  }
`);
