import styles from "./NoPermissionScreen.module.css";

const NoPermissionScreen = () => (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.logo} />
        <div>
          <span>403.&nbsp;</span>
          <span className={styles.blur}>No Permission.</span>
          <br />
          <span>You don't have permission to access {window.location.pathname} on this server.&nbsp;</span>
        </div>
      </div>
      <div className={styles.errorImg} />
    </div>
  );
export default NoPermissionScreen;
