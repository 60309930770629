import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

export default codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimInfo2 on claim_cases {
    claim_case_assessed_diagnoses(order_by: { order: asc_nulls_last }) {
      id
      icd {
        id
        title
        value
      }
    }
    claim_case_beneficiary {
      id
      bank_account_number
      bank_id
      beneficiary_name
      confirmed_at
      paper_id
      payment_method
      premium_policy_number
      warning_code
      updated_at
      confirmed_by_user {
        id
        name
      }
    }
    claim_case_decline_histories(limit: 1, order_by: { created_at: desc }) {
      id
      decline_code
      decline_description
      deleted_at
      deleted_reason
    }
    claim_case_details {
      id
      claim_case_detail_id
      non_paid_amount
      note
      paid_amount
      paid_time
      request_amount
      request_time
      total_paid_amount
      total_paid_amount_before_copay
      total_request_amount
      updated_at
      plan_balance_claim_case_details {
        id
        plan_balance {
          id
          name
          balanceRemain: value
          balanceUsed: value
          value
          plan_balance_benefits {
            id
          }
          plan_balance_type {
            id: value
            comment
          }
        }
      }
      plan_insured_benefit {
        id
        apply_copay
        insured_benefit_id
        is_direct_billing
        insured_benefit {
          id
          name
          code
          type
          insured_benefit_type {
            id: value
            comment
            value
          }
        }
        plan_balance_benefits {
          id
          plan_balance_id
          plan_balance {
            id
            name
          }
        }
      }
    }
    claim_case_details_aggregate {
      aggregate {
        sum {
          total_paid_amount
          total_paid_amount_before_copay
        }
      }
    }
    claim_case_group_claim_cases {
      id
      type
      claim_case {
        id
        code
      }
      claim_case_group {
        id
        type
        created_at
        claim_case_group_claim_cases(where: { claim_case: {} }) {
          id
          claim_case {
            id
            admission_date
            benefit_type
            claim_case_id
            code
            discharge_date
            event_date
            insured_certificate_id
            is_direct_billing
            physical_examination_date
            status
            created_at
            claim_case_group_claim_cases {
              id
              claim_case_group_claim_case_type {
                id: value
                comment
                value
              }
            }
            claim_case_status {
              id: value
              comment
              value
            }
            insured_benefit_type {
              id: value
              comment
              value
            }
            insured_person {
              id
              name
            }
          }
          claim_case_group_claim_case_type {
            id: value
            comment
            value
          }
        }
        claim_case_group_type {
          id: value
          comment
        }
        created_by_user {
          id
          name
        }
      }
    }
    claim_case_input_diagnoses {
      id
      icd {
        id
        title
        value
      }
    }
    claim_case_payment {
      id
      actual_paid_amount
      applied_premium_debt_amount
      co_payment_amount
      co_payment_ratio
      coverage_amount
      custom_amount
      custom_amount_reason
      deductible_amount
      estimated_paid_amount
      initial_premium_debt_amount
      is_partial_paid
      non_paid_amount
      remaining_premium_debt_amount
      requested_amount
      shortfall_amount
    }
    claim_case_same_event_groups_claim_case {
      id
      claim_case_group_id
      claim_case_same_event_group {
        id
        code
        handed_over_to_insurer_at
        created_at
        claim_case_same_event_groups_claim_cases {
          id
          exclude_from_correspondence
          claim_case {
            id
            code
            grace_period_start_date
            status
            claim_case_status {
              id: value
              comment
              value
            }
            claim_pending_codes {
              id
              code
              status
              pending_code {
                id
                internal
              }
            }
          }
        }
        slv_claim_case_same_event_group {
          id
          claimant_name
          claimant_phone
          has_surgery_doc
          hospital_department
          hospital_department_type
          hospital_stamp
          last_synced_at
          mrn
          patient_id
          treatment_methods
          case_reason_investigator_type {
            id: value
            comment
            value
          }
          case_reason_re_uw_type {
            id: value
            comment
            value
          }
          group_status {
            id: value
            comment
            value
          }
        }
      }
    }
    claim_case_status {
      id: value
      comment
      value
    }
    claim_notes(where: { type: { _in: [SignOff, PresentCaseNote, LaNote, MEDICAL_PROVIDER] } }, order_by: { created_at: asc }) {
      id
      content
      share_to_insurer
      type
      user_id
      created_at
      user {
        id
        name
        avatar_url
        roles {
          id
          role
        }
      }
    }
    claim_pending_codes(order_by: { created_at: asc }) {
      id
      code
      extended_text
      removed_at
      started_at
      status
      updated_at
      created_at
      claim_pending_code_documents {
        id
        claim_document_id
        created_at
        claim_document_type {
          comment
          value
        }
        file {
          id
          name
          file_id
          url
        }
      }
      fwd_pending_code {
        code
        ppy_pending_code
      }
      pending_code {
        id
        description
        document_type
        internal
      }
    }
    claim_source {
      id: value
      comment
      value
    }
    claim_treatment_info_item {
      id
      dental_tartar_removal_fee
      examination_fee
      medicine_fee
      num_of_in_hospital_days
      other_fee
      room_fee
      surgery_fee
      teeth_treatment_fee
      test_fee
      time_in_hospital
      total_room_fee
      unit_of_time_in_hospital
    }
  }
`);
