const placeHolderTextsMapping = [
  {
    key_name: "effective_date",
    text: "<Ngày hiệu lực HĐ rider>",
  },
  {
    key_name: "event_date",
    text: "<Event date>",
  },
  {
    key_name: "diagnosis",
    text: "<Chẩn đoán bệnh>",
  },
  {
    key_name: "plan_name",
    text: "<Gói sản phẩm>",
  },
  {
    key_name: "out_patient_per_case_limit",
    text: "<Quyền lợi tối đa mỗi lần khám ngoại trú>",
  },
  {
    key_name: "actual_paid_amount",
    text: "<Tổng tiền thực trả>",
  },
  {
    key_name: "dental_per_case_limit",
    text: "<Quyền lợi nha khoa>",
  },
  {
    key_name: "per_case_limit",
    text: "<Quyền lợi tối đa mỗi Đợt điều trị>",
  },
  {
    key_name: "annual_limit",
    text: "<Phạm vi bảo hiểm tối đa mỗi năm hợp đồng>",
  },
  {
    key_name: "out_patient_annual_limit",
    text: "<Quyền lợi ngoại trú tối đa mỗi năm hợp đồng>",
  },
  {
    key_name: "medical_provider_name",
    text: "<CSYT>",
  },
  {
    key_name: "coverage_amount",
    text: "<Số tiền trong PVBH>",
  },
  {
    key_name: "co_payment_amount",
    text: "<Số tiền copay>",
  },
  {
    key_name: "",
    text: "<Giá trị cần điền>",
  },
] as const;

const parsePlaceholderTextsToValue = (text: string, obj: Partial<Record<(typeof placeHolderTextsMapping)[number]["key_name"], null | string | undefined>>) => {
  let newText = text;
  placeHolderTextsMapping.forEach(({ key_name, text: templateText }) => {
    const value = obj[key_name];
    if (value == null) return;
    newText = newText.replace(templateText, value);
  });
  return newText;
};

export { parsePlaceholderTextsToValue, placeHolderTextsMapping };
