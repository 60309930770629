/* eslint-disable */
import * as Types from '../sdk/gql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FwdPolicyStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FwdPolicyStatusesQuery = (
  { fwd_policy_statuses: Array<(
    { comment: string, value: string, id: string }
    & { __typename?: 'fwd_policy_statuses' }
  )> }
  & { __typename?: 'query_root' }
);

export type FwdClaimCaseStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FwdClaimCaseStatusQuery = (
  { fwd_claim_case_statuses: Array<(
    { comment: string, value: string }
    & { __typename?: 'fwd_claim_case_statuses' }
  )> }
  & { __typename?: 'query_root' }
);

export type FwdDeclineCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FwdDeclineCodesQuery = (
  { fwd_decline_codes: Array<(
    { value: string, comment: string }
    & { __typename?: 'fwd_decline_codes' }
  )> }
  & { __typename?: 'query_root' }
);

export type FwdMaritalStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FwdMaritalStatusesQuery = (
  { fwd_marital_statuses: Array<(
    { value: string, comment: string }
    & { __typename?: 'fwd_marital_statuses' }
  )> }
  & { __typename?: 'query_root' }
);

export type FwdPendingCodesListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FwdPendingCodesListQuery = (
  { fwd_pending_codes: Array<(
    { code: string, name: string, ppy_pending_code: string }
    & { __typename?: 'fwd_pending_codes' }
  )> }
  & { __typename?: 'query_root' }
);


export const FwdPolicyStatusesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"fwdPolicyStatuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fwd_policy_statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<FwdPolicyStatusesQuery, FwdPolicyStatusesQueryVariables>;
export const FwdClaimCaseStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"fwdClaimCaseStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fwd_claim_case_statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<FwdClaimCaseStatusQuery, FwdClaimCaseStatusQueryVariables>;
export const FwdDeclineCodesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"fwdDeclineCodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fwd_decline_codes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}}]}}]}}]} as unknown as DocumentNode<FwdDeclineCodesQuery, FwdDeclineCodesQueryVariables>;
export const FwdMaritalStatusesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"fwdMaritalStatuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fwd_marital_statuses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"value"},"name":{"kind":"Name","value":"code"}},{"kind":"Field","alias":{"kind":"Name","value":"comment"},"name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<FwdMaritalStatusesQuery, FwdMaritalStatusesQueryVariables>;
export const FwdPendingCodesListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"fwdPendingCodesList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fwd_pending_codes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"ppy_pending_code"}}]}}]}}]} as unknown as DocumentNode<FwdPendingCodesListQuery, FwdPendingCodesListQueryVariables>;