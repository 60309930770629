import type { UploadFile } from "antd";
import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { FC, ReactNode } from "react";
import type { DocTypeEnum } from "sdk/gql/types";

import { CheckOutlined, FileOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Tag, Tooltip, Typography, Upload, notification } from "antd";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import BPromise from "bluebird";
import useUploadFile from "hooks/useUploadFile";
import { debounce, isEmpty } from "lodash";
import { useState } from "react";
import { ClaimSourcesEnum, FileTypeInputEnum } from "sdk/gql/types";

import styles from "./PendingForm.module.less";

const { Text } = Typography;

type ClaimPendingCodesOnGetClaimDetailFragment = NonNullable<ClaimContextType["claim"]>["claim_pending_codes"][number];
interface UpdatePendingCodeDocumentFormProps {
  children: ReactNode;
  claimPendingItem: ClaimPendingCodesOnGetClaimDetailFragment;
}
const UpdatePendingCodeDocumentForm: FC<UpdatePendingCodeDocumentFormProps> = ({ children, claimPendingItem }) => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [uploadPendingDocument, { loading: uploadingClaimDocument }] = useUploadFile({ refetchQueries: ["ClaimDetail"] });
  const {
    claim,
    status: { saveStatus },
  } = useClaim();
  const onChange = (info) => {
    setFiles(info.fileList);
  };

  const onConfirmUpload = async () => {
    if (isEmpty(files)) {
      notification.error({
        message: "Vui lòng chọn files",
      });
      return;
    }
    if (claim == null) {
      notification.error({
        message: "Không có thông tin claim, vui lòng refresh lại trang",
      });
      return;
    }
    await BPromise.mapSeries(files, async (uploadedFile) => {
      if (uploadedFile.originFileObj == null) {
        return;
      }

      await uploadPendingDocument({
        docType: claimPendingItem.pending_code.document_type as unknown as DocTypeEnum,
        file: uploadedFile.originFileObj,
        fileType: "CLAIM",
        id: claim.id,
        keepOriginalFileName: true,
        mutationVariables: {
          claim_document: {
            data: {
              claim_case_id: claim.id,
              claim_pending_code_id: claimPendingItem.id,
              is_supplemented: false,
              source: "Assessments",
              type: claimPendingItem.pending_code.document_type,
            },
          },
        },
      });
    });
    setFiles([]);
    notification.success({
      message: "Cập nhật chứng từ thành công",
    });
    if (claim.status === "Pending") {
      Modal.confirm({
        content: "Đồng ý sẽ cập nhật trạng thái claim sang [Đã bổ sung]",
        onCancel: () => {},
        onOk: () => {
          saveStatus({
            variables: {
              claimId: claim.id,
              input: { status: "Updated" },
            },
          });
        },
        title: "Bạn vừa cập nhật tài liệu mới, bạn có muốn đổi trạng thái sang đã bổ sung hồ sơ?",
      });
    }
  };

  return (
    <If
      condition={files.length === 0}
      else={
        <Space wrap>
          {files.map((file) => {
            const fileId = file.uid;
            return (
              <div key={fileId}>
                <Tag color="blue" icon={<FileOutlined />}>
                  <Tooltip title={file.name}>
                    <Text className={styles.fileItemContainer}>
                      <Text className={styles.fileName} ellipsis={{ tooltip: file.name }}>
                        {file.name}
                      </Text>
                    </Text>
                  </Tooltip>
                </Tag>
              </div>
            );
          })}
          <Button danger loading={uploadingClaimDocument} onClick={() => setFiles([])} size="small">
            Huỷ
          </Button>
          <Button icon={<CheckOutlined />} loading={uploadingClaimDocument} onClick={debounce(onConfirmUpload, 250)} size="small" type="primary">
            Xác nhận
          </Button>
        </Space>
      }
    >
      <Upload beforeUpload={() => false} fileList={files} multiple name="file" onChange={onChange}>
        {children}
      </Upload>
    </If>
  );
};

export default UpdatePendingCodeDocumentForm;
