import { useLocalStorageState } from "ahooks";
import { Alert } from "antd";
import ErrorPage from "app/common/components/ErrorBoundary/ErrorPage";
import { SLACK } from "config";
import { LOCAL_STORAGE_KEYS } from "config/constants";
import utils from "libs/utils";
import { useRouteError } from "react-router-dom";

const ErrorThrower = ({ error }: { error: Error }) => {
  const [user] = useLocalStorageState(LOCAL_STORAGE_KEYS.USER_INFO, {
    deserializer: (value) => JSON.parse(value),
  });
  if (error.message.includes("Failed to execute 'removeChild' on 'Node'") === true) {
    return (
      <div
        style={{
          border: "3px solid green",
          margin: "auto",
          marginTop: 200,
          padding: 10,
          width: "50%",
        }}
      >
        Chúng tôi nhận thấy rằng loại lỗi này xuất hiện khi tính năng <b>Dịch Trang Web</b> của Google Chrome được sử dụng. Bạn nên thử một trình duyệt khác, một công cụ dịch khác
        hoặc tắt tính năng dịch.
      </div>
    );
  }
  const obj = {
    cause: error.cause,
    message: error.message,
    name: error.name,
    stack: error.stack,
  };
  utils.sendMessageToSlack({
    channel: SLACK.CHANNEL_IDS.LOC,
    message: `${window.location.href} ${window.location.href.replace("https://portal.care.papaya.asia", "https://prod.cassava.care:3000")}, ${user?.name}: ${JSON.stringify(obj, null, 2)}`,
  });
  if (import.meta.env.PROD) {
    return <ErrorPage />;
  }

  throw error;
};

const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <Alert.ErrorBoundary>
      <ErrorThrower error={error} />
    </Alert.ErrorBoundary>
  );
};

export default ErrorBoundary;
