import type { FunctionComponent } from "react";
import type { InsuredPersonVipLevelEnum } from "sdk/gql/graphql";

interface VIPLevelProps {
  level?: InsuredPersonVipLevelEnum;
  text?: React.ReactNode | string;
}

const VIPLevel: FunctionComponent<VIPLevelProps> = ({ level = "", text = "" }) => (
  <div style={{ display: "inline-flex" }}>
    {text}
    {level === "NORMAL" ? null : <div style={{ color: "#e91e63", marginLeft: 5, minWidth: 30 }}> - {level}</div>}
  </div>
);

export default VIPLevel;
