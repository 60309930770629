import type { FormInstance } from "antd";

import { useToggle } from "ahooks";
import { Button, Flex, Form, Image, Popover, Select, Space, Tooltip, Typography } from "antd";
import If from "app/common/components/If";
import MedicalProviderSelectOption from "app/common/components/MedicalProviderSelectOption";
import Spin from "app/common/components/Spin";
import WandIcon from "app/common/components/WandIcon";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { getInsurerClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/utils";
import useClaimAssistant from "app/portal/screens/ClaimPortal/Contexts/ClaimAssistantContext/useClaimAssitant";
import { SLACK } from "config";
import { useAuth } from "contexts/AuthContext";
import useMedicalProviders from "hooks/useMedicalProviders";
import utils from "libs/utils";
import { uniq } from "lodash";
import { AlertCircle } from "lucide-react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import styles from "../../DetailForm.module.css";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;

const Suggestion = ({ onAccept }: { onAccept: (id: string) => void }) => {
  const { claim } = useClaim();
  const { suggestions } = useClaimAssistant();
  const { user } = useAuth();
  const [visible, { set, setRight }] = useToggle();

  const suggestion = suggestions?.find((s) => s.type === "medical_provider");

  if (suggestion?.matchObject == null) return null;

  return (
    <Popover
      content={
        <Flex align="center" gap={30} style={{ height: 150, textAlign: "center" }} vertical>
          <MedicalProviderSelectOption mp={suggestion.matchObject} />
          <Button
            onClick={async () => {
              onAccept(suggestion.matchObject?.id);
              utils.sendMessageToSlack({
                channel: SLACK.CHANNEL_IDS.TRACK_USAGE,
                message: `<@${user.slack_member_id}> accepted Medical Provider suggestion for <${window.location.href}|${claim?.code}>`,
              });
            }}
            type="primary"
          >
            Áp dụng
          </Button>
        </Flex>
      }
      title={
        <span>
          Sọ Dừa Gợi ý{" "}
          <Button disabled={false} onClick={setRight} type="link">
            File gốc
          </Button>
          {suggestion.originalDocument?.file?.url != null && (
            <Image
              className="display-none"
              preview={{
                onVisibleChange: (value) => set(value),
                scaleStep: 0.5,
                src: suggestion.originalDocument.file.url,
                visible,
              }}
              src={`${suggestion.originalDocument.file.url}?width=148`}
            />
          )}
        </span>
      }
    >
      <div>
        <WandIcon className="cursor-pointer" size={16} />
      </div>
    </Popover>
  );
};

const MedicalProviderIdField = ({ form, name }: Props) => {
  const { claim } = useClaim();
  const medicalProviderIdHistories = uniq([...(claim?.insured_certificate.claim_cases.map((cc) => cc.medical_provider?.id) ?? []), claim?.medical_provider_id]).filter(Boolean);
  const {
    data: medicalProviders,
    loading: loadingMedicalProviders,
    searchMp,
  } = useMedicalProviders({
    where: {
      ...(claim?.medical_provider_id == null
        ? {}
        : {
            _or: [
              {
                medical_provider_id: {
                  _in: medicalProviderIdHistories,
                },
              },
            ],
          }),
    },
  });

  const watchSelectedMpId = Form.useWatch(name, form);
  const isMpInBlackList = Boolean(
    claim?.insured_certificate.policy.policy_setting?.blacklist_medial_provider_group_histories.some(
      (m) => m.medical_provider_group_medical_providers.some((mp) => mp.medical_provider.id === watchSelectedMpId) === true && m.medical_provider_group.type === "REJECTION",
    ),
  );

  return (
    <Form.Item
      help={isMpInBlackList === true ? "CSYT thuộc danh sách không chi trả theo quy định của HĐ (không ảnh hưởng việc lưu thông tin)" : undefined}
      label={
        <Space>
          <Typography.Text>Bệnh viện:</Typography.Text>
          <Suggestion
            onAccept={(id) => {
              form.setFieldValue(name, id);
              searchMp(id);
            }}
          />
        </Space>
      }
      name={name}
      rules={[
        {
          message: "Vui lòng chọn bệnh viện",
          required: true,
        },
      ]}
      validateStatus={isMpInBlackList === true ? "error" : undefined}
    >
      <Select
        className={styles.autoComplete}
        filterOption={false}
        notFoundContent={
          <If condition={loadingMedicalProviders === true}>
            <Spin size="small" />
          </If>
        }
        onSearch={searchMp}
        optionFilterProp="children"
        optionLabelProp="selectedLabel"
        options={medicalProviders?.mp_medical_providers.map((mp) => ({
          disabled: claim?.is_direct_billing === true && mp.is_direct_billing === false,
          label: (
            <MedicalProviderSelectOption
              claimType={getInsurerClaimType(claim)}
              directBillingOnly={claim?.is_direct_billing === true}
              key={mp.id}
              mp={mp}
              policy_id={claim?.insured_certificate.policy.id}
            />
          ),
          selectedLabel: (
            <MedicalProviderSelectOption
              claimType={getInsurerClaimType(claim)}
              directBillingOnly={claim?.is_direct_billing === true}
              displayAddress={false}
              key={mp.id}
              mp={mp}
              policy_id={claim?.insured_certificate.policy.id}
            />
          ),
          value: mp.id,
        }))}
        placeholder="Tìm với tên hoặc địa chỉ"
        showSearch
        size="large"
        suffixIcon={
          <If condition={isMpInBlackList}>
            <Tooltip title="CSYT trong blacklist">
              <AlertCircle color="red" size={18} />
            </Tooltip>
          </If>
        }
      />
    </Form.Item>
  );
};

export default MedicalProviderIdField;
