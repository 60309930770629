import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

const CLAIM_DETAIL_FOR_TAT_FRAGMENT = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimDetailForTat on claim_cases {
    id
    is_direct_billing
    status
    tat_in_seconds
    created_at
    action_logs(order_by: { created_at: desc }) {
      id
      new_value
      old_value
      created_at
    }
    insured_certificate {
      id
      policy {
        id
        insurer_company {
          id
          company_id
        }
      }
    }
  }
`);

export { CLAIM_DETAIL_FOR_TAT_FRAGMENT };
