import type { ProgressProps, TableColumnsType, TablePaginationConfig } from "antd";
import type { SorterResult, SortOrder } from "antd/lib/table/interface";
import type { CLAIM_CASE_ON_CLAIM_LIST_FRAGMENT } from "app/portal/screens/ClaimPortal/ClaimListScreen/graphql/queries";
import type { AlignType, FixedType } from "rc-table/lib/interface";
import type { ReactNode } from "react";
import type { ClaimCaseStatusesEnum, PresentCaseStatusesEnum } from "sdk/gql/graphql";
import type { FragmentOf, VariablesOf } from "sdk/v2/graphql";

import { withTransaction } from "@elastic/apm-rum-react";
import { useSize, useToggle } from "ahooks";
import { Flex, Progress, Space, Table, Tag, Tooltip, Typography } from "antd";
import BenefitTag from "app/common/components/BenefitTag";
import BenefitTagV3 from "app/common/components/BenefitTagV3";
import ClaimIcons from "app/common/components/ClaimIcons";
import ClaimStatus from "app/common/components/ClaimStatus";
import If from "app/common/components/If";
import LabelDisplay from "app/common/components/LabelDisplay";
import TableList from "app/common/components/TableListV2";
import "app/common/components/TableListV2/animation.less";
import VIPLevel from "app/common/components/VIPLevel";
import InstantPayoutIcon from "app/common/icons/InstantPayoutIcon";
import ManualPayoutIcon from "app/common/icons/ManualPayoutIcon";
import { PORTAL_PATH } from "app/portal/config/paths";
import SlvHsTATList from "app/portal/screens/ClaimPortal/ClaimListScreen/components/SlvHsTATList";
import { ALL_CLAIM_CASE_COUNT_QUERY, ALL_CLAIM_CASES_QUERY } from "app/portal/screens/ClaimPortal/ClaimListScreen/graphql/queries";
import { papaya } from "config/colors";
import { CLAIM_DECISION_STATUSES, FORMATTER, INSURER_COMPANY_IDS } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { differenceInCalendarDays, differenceInYears } from "date-fns";
import useInsuredBenefitTypes from "hooks/useInsuredBenefitTypes";
import usePollInterval from "hooks/usePollInterval";
import { getZeroOrNumber } from "libs/hidash";
import { utils } from "libs/utils";
import { isEmpty } from "lodash";
import find from "lodash/find";
import { Bot, CakeSlice, CircleX, HeartHandshake, Rocket } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { generatePath, Link, useNavigate, useSearchParams } from "react-router-dom";
import { validate } from "uuid";

import { CLAIM_LIST_PATH, getInsurerClaimType } from "../ClaimContext/utils";
import "./ClaimListScreen.css";
import styles from "./ClaimListScreen.module.css";
import SearchSection from "./SearchSection";

type ClaimCaseOnClaimList = FragmentOf<typeof CLAIM_CASE_ON_CLAIM_LIST_FRAGMENT>;

const ClaimListScreen = ({ insurerId: insurerIdProps, setPageTitle }: { insurerId?: string; setPageTitle: (title: string) => {} }) => {
  setPageTitle("Danh sách Yêu cầu bồi thường");
  const navigate = useNavigate();
  const windowSize = useSize(document.querySelector("body"));
  const [showAdditionalData, { toggle }] = useToggle(false);

  useHotkeys("`", toggle);

  const benefitTypeOptions = useInsuredBenefitTypes();
  const [searchParams, setSearchParams] = useSearchParams({ keyword: "", page: "1", size: "30" });
  const size = Number(searchParams.get("size") ?? 30);
  const page = Number(searchParams.get("page") ?? 1);
  const keyword = searchParams.get("keyword")?.trim().toUpperCase();
  const statuses = searchParams.getAll("status").filter((status) => status !== "") as ClaimCaseStatusesEnum[];
  const benefit = String(searchParams.get("benefit") ?? "");
  const paymentMethod = String(searchParams.get("paymentMethod") ?? "");
  const presentCaseStatus = String(searchParams.get("presentCaseStatus") ?? "");
  const medicalProviderId = String(searchParams.get("medical-provider") ?? "");
  const corporateId = String(searchParams.get("corporate-id") ?? "");
  const insurerId = searchParams.get("insurer-id") ?? insurerIdProps;
  const planBalanceId = String(searchParams.get("plan-balance-id") ?? "");
  const insuredBenefitCode = String(searchParams.get("insured-benefit-code") ?? "");
  const assignee = String(searchParams.get("assignee") ?? "");
  const orderBy = String(searchParams.get("order_by") ?? "created_at");
  const orderDirectionParam = String(searchParams.get("order_direction") ?? "");
  const orderDirection = orderDirectionParam === "ascend" ? "asc" : "desc";
  const goToDetail = searchParams.get("go_to_detail") === "true";
  const directBilling = searchParams.get("direct_billing");
  const fromDateParam = searchParams.get("from");
  const toDateParam = searchParams.get("to");
  const isJet = searchParams.get("is_jet");
  const isStp = searchParams.get("is_stp");

  // Claim code starts with CL won't works by searching for exact code as SLV code can be either CL2024011151 or CL2024011151-5000162706
  const isKeywordClaimCode =
    keyword?.startsWith("MBAL-") === true ||
    keyword?.startsWith("MDR-") === true ||
    keyword?.startsWith("RE-") === true ||
    keyword?.startsWith("DB-") === true ||
    keyword?.startsWith("CL") === true;
  const isKeywordPolicyNumber = Number(keyword) > 0 && keyword?.length === 8;

  let where: VariablesOf<typeof ALL_CLAIM_CASES_QUERY>["where"] = useMemo(() => {
    let dateCondition: VariablesOf<typeof ALL_CLAIM_CASES_QUERY>["where"] = {};
    if (fromDateParam != null && toDateParam != null) {
      dateCondition = {
        created_at: {
          _gte: fromDateParam,
          _lte: toDateParam,
        },
      };
    }
    return {
      ...dateCondition,
      is_history: { _eq: false },
    };
  }, [fromDateParam, toDateParam]);

  useEffect(() => {
    if (keyword != null && validate(keyword)) {
      navigate(generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: keyword.toLowerCase() }));
    }
  }, [keyword, navigate]);

  if (directBilling != null && directBilling !== "") {
    where = {
      ...where,
      is_direct_billing: { _eq: directBilling === "true" },
    };
  }

  if (keyword != null && keyword !== "") {
    if (isKeywordPolicyNumber) {
      where = {
        insured_certificate: {
          policy: {
            policy_number: { _eq: keyword },
          },
        },
      };
    } else if (isKeywordClaimCode === true) {
      where =
        keyword.startsWith("CL") === true
          ? {
              _or: [
                { code: { _eq: keyword } },
                {
                  claim_case_same_event_groups_claim_case: {
                    claim_case_same_event_group: {
                      code: { _eq: keyword },
                    },
                  },
                },
              ],
            }
          : {
              code: { _ilike: keyword },
            };
    } else {
      where = {
        ...where,
        _and: [
          {
            _or: [
              {
                code: { _ilike: `%${keyword}%` },
              },
              {
                agent: {
                  _or: [
                    {
                      name: { _ilike: `%${keyword}%` },
                    },
                    {
                      email: { _ilike: `%${keyword}%` },
                    },
                  ],
                },
              },
              {
                insured_certificate: {
                  _or: [
                    { certificate_code: { _eq: keyword } },
                    {
                      insured_person: {
                        _or: [
                          {
                            paper_id: { _eq: keyword },
                          },
                          {
                            normalized_name_v2: { _ilike: keyword },
                          },
                        ],
                      },
                    },
                    {
                      policy: {
                        _or: [
                          {
                            insured_person: {
                              _or: [
                                {
                                  normalized_name_v2: {
                                    _ilike: `%${keyword}%`,
                                  },
                                },
                                {
                                  paper_id: {
                                    _ilike: `%${keyword}%`,
                                  },
                                },
                              ],
                            },
                          },
                          {
                            corporate_company: {
                              name: {
                                _ilike: `%${keyword}%`,
                              },
                            },
                          },
                          {
                            insurer_company: {
                              name: {
                                _ilike: `%${keyword}%`,
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                claim_number: {
                  _ilike: `%${keyword}%`,
                },
              },
            ],
          },
        ],
      };
    }
  }
  if (benefit !== "") {
    where = {
      ...where,
      insured_benefit_type: {
        value: { _eq: benefit },
      },
    };
  }
  if (statuses.length > 0) {
    where = {
      ...where,
      status: {
        _in: statuses,
      },
    };
  }
  if (assignee !== "" && validate(assignee)) {
    where = {
      ...where,
      fig_claim_case: {
        assignee_user_id: { _eq: assignee },
      },
    };
  }
  if (isJet !== "" && isJet != null) {
    where = {
      ...where,
      is_jet: { _eq: isJet === "true" },
    };
  }
  if (isStp !== "" && isStp != null) {
    where = {
      ...where,
      is_stp: { _eq: isStp === "true" },
    };
  }
  if (paymentMethod !== "") {
    switch (paymentMethod) {
      case "MANUAL": {
        where = {
          ...where,
          _or: [
            { _not: { payment_request_item: {} } },
            {
              _not: {
                payment_request_item: {
                  payment_request: {
                    transactions: {},
                  },
                },
              },
            },
            {
              payment_request_item: {
                payout_type: { _eq: paymentMethod },
              },
            },
          ],
        };
        break;
      }
      case "AUTOMATION": {
        where = {
          ...where,
          payment_request_item: {
            payout_type: { _eq: paymentMethod },
          },
        };
        break;
      }
      default: {
        break;
      }
    }
  }
  if (validate(medicalProviderId)) {
    where = {
      ...where,
      medical_provider_id: { _eq: medicalProviderId },
    };
  }

  if (validate(corporateId)) {
    where = {
      ...where,
      insured_certificate: {
        _and: [
          {
            policy: {
              corporate_id: { _eq: corporateId },
            },
          },
        ],
      },
    };
  }

  if (insurerId != null && validate(insurerId)) {
    where = {
      ...where,
      insured_certificate: {
        _and: [
          where.insured_certificate ?? undefined,
          ...(where.insured_certificate?._and ?? []),
          {
            policy: {
              insurer_id: { _eq: insurerId },
            },
          },
        ].filter(Boolean),
      },
    };
  }

  if (insurerId == null) {
    where = {
      ...where,
      _and: [
        ...(where._and ?? []),
        {
          _not: {
            fwd_claim_case: {},
          },
        },
        {
          _not: {
            mbal_claim_case: {},
          },
        },
        {
          _not: {
            slv_claim_case: {},
          },
        },
      ],
    };
  }

  if (validate(planBalanceId) && insuredBenefitCode.length > 0) {
    where = {
      ...where,
      claim_case_details: {
        plan_balances: {
          plan_balance_id: { _eq: planBalanceId },
        },
        plan_insured_benefit: {
          insured_benefit: {
            code: { _eq: insuredBenefitCode },
          },
        },
      },
    };
  }

  if (!isEmpty(presentCaseStatus)) {
    switch (presentCaseStatus as PresentCaseStatusesEnum) {
      case "DONE": {
        where = {
          ...where,
          _and: [
            { present_cases: {} },
            {
              present_cases_aggregate: {
                count: {
                  filter: {
                    status: { _in: ["IN_PROGRESS", "INSURER_ASSESSED"] },
                  },
                  predicate: { _eq: 0 },
                },
              },
            },
          ],
        };
        break;
      }
      case "IN_PROGRESS": {
        where = {
          ...where,
          _and: [
            { present_cases: {} },
            {
              present_cases_aggregate: {
                count: {
                  filter: {
                    status: { _in: ["IN_PROGRESS"] },
                  },
                  predicate: { _gt: 0 },
                },
              },
            },
          ],
        };
        break;
      }
      case "INSURER_ASSESSED": {
        where = {
          ...where,
          _and: [
            { present_cases: {} },
            {
              present_cases_aggregate: {
                count: {
                  filter: {
                    status: { _in: ["INSURER_ASSESSED"] },
                  },
                  predicate: { _gt: 0 },
                },
              },
            },
          ],
        };
        break;
      }
      default:
    }
  }

  let orderByExp: NonNullable<VariablesOf<typeof ALL_CLAIM_CASES_QUERY>["orderBy"]> = useMemo(
    () => [
      {
        created_at: "desc",
      },
    ],
    [],
  );

  if (orderBy !== "") {
    orderByExp =
      orderBy === "claim_case_payment,actual_paid_amount" && orderDirection === "desc"
        ? [
            {
              actual_paid_amount: "desc_nulls_last",
            },
          ]
        : [
            {
              [orderBy]: orderDirection,
            },
          ];
  }
  const {
    data: claimCasesData,
    loading,
    refetch,
    startPolling,
  } = usePQuery(ALL_CLAIM_CASES_QUERY, {
    nextFetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (isKeywordClaimCode === true && result.claim_cases.length === 1 && goToDetail && result.claim_cases[0]?.id != null) {
        navigate(generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: result.claim_cases[0]?.id }));
      }
    },
    pollInterval: usePollInterval(60_000),
    skipPollAttempt: () => document.hidden,
    variables: {
      excludeTestUsersWhere: where,
      limit: size,
      offset: (page - 1) * size,
      orderBy: orderByExp,
      where,
    },
  });

  const stpCases = claimCasesData?.claim_cases.filter((c) => c.is_jet === true && c.is_accessible === false).map((c) => c.id);
  if ((stpCases?.length ?? 0) > 0) {
    startPolling(2000);
  } else {
    startPolling(60_000);
  }
  const { data: claimCasesCountData } = usePQuery(ALL_CLAIM_CASE_COUNT_QUERY, {
    variables: {
      excludeTestUsersWhere: where,
      where,
    },
  });
  const claimCases = claimCasesData?.claim_cases;

  const claimListColumns: TableColumnsType<ClaimCaseOnClaimList> = [
    {
      dataIndex: "code",
      fixed: "left" as FixedType,
      render: (_, record) => <ClaimIcons claim={record} />,
      title: (
        <Space>
          <span>Mã HSBT</span>
          <Tooltip title="Bảo lãnh viện phí">
            <HeartHandshake
              className="anticon cursor-pointer"
              color={papaya}
              onClick={() =>
                setSearchParams((params) => {
                  params.delete("is_jet");
                  params.delete("is_stp");
                  switch (directBilling) {
                    case "true": {
                      params.set("direct_billing", "false");
                      break;
                    }
                    case "false": {
                      params.delete("direct_billing");
                      break;
                    }
                    default: {
                      params.set("direct_billing", "true");
                    }
                  }
                  return params;
                })
              }
              size={15}
            />
          </Tooltip>
          <Tooltip title="JET - xử lý trong 30 phút">
            <Rocket
              className="anticon cursor-pointer"
              color={papaya}
              onClick={() =>
                setSearchParams((params) => {
                  params.delete("direct_billing");
                  params.delete("is_stp");
                  switch (isJet) {
                    case "true": {
                      params.set("is_jet", "false");
                      break;
                    }
                    case "false": {
                      params.delete("is_jet");
                      break;
                    }
                    default: {
                      params.set("is_jet", "true");
                    }
                  }
                  return params;
                })
              }
              size={20}
            />
          </Tooltip>
          <Tooltip title="STP (Straight Through Process) - xử lý tự động">
            <Bot
              className="anticon cursor-pointer"
              color={papaya}
              onClick={() =>
                setSearchParams((params) => {
                  params.delete("direct_billing");
                  params.delete("is_jet");
                  switch (isStp) {
                    case "true": {
                      params.set("is_stp", "false");
                      break;
                    }
                    case "false": {
                      params.delete("is_stp");
                      break;
                    }
                    default: {
                      params.set("is_stp", "true");
                    }
                  }
                  return params;
                })
              }
              size={20}
            />
          </Tooltip>
        </Space>
      ),
      width: 200,
    },
    {
      dataIndex: ["insured_certificate", "policy", "corporate_company", "name"],
      ellipsis: {
        showTitle: false,
      },
      onCell: (r) => ({
        onClick: (e) => {
          if (e.altKey && r.insured_certificate.policy.corporate_company?.id != null) {
            setSearchParams((params) => {
              if (r.insured_certificate.policy.corporate_company?.id != null) {
                params.set("corporate-id", r.insured_certificate.policy.corporate_company.id);
              }
              return params;
            });
          }
        },
      }),
      render: (value: string, record) => {
        if (record.insured_certificate.policy.corporate_company?.name != null) {
          return (
            <Tooltip
              placement="topLeft"
              title={value.replace("CÔNG TY CỔ PHẦN", "CTCP").replace("CÔNG TY", "CTY").replace("TRÁCH NHIỆM HỮU HẠN", "TNHH").replace("MỘT THÀNH VIÊN", "MTV")}
            >
              {value.replace("CÔNG TY CỔ PHẦN", "CTCP").replace("CÔNG TY", "CTY").replace("TRÁCH NHIỆM HỮU HẠN", "TNHH").replace("MỘT THÀNH VIÊN", "MTV")}
              <If condition={showAdditionalData}>- {record.claim_sequence}</If>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="topLeft" title={record.insured_certificate.policy.insured_person?.name}>
            {record.insured_certificate.policy.insured_person?.name}
            <If condition={showAdditionalData}>- {record.claim_sequence}</If>
          </Tooltip>
        );
      },
      title: "Chủ hợp đồng",
      width: 120,
    },
    {
      align: "left" as AlignType,
      dataIndex: ["insured_certificate", "insured_person", "name"],
      ellipsis: {
        showTitle: false,
      },
      onCell: (r) => ({
        onClick: (e) => {
          if (e.altKey) {
            setSearchParams((params) => {
              params.set("keyword", r.insured_certificate.insured_person.name);
              return params;
            });
          }
        },
      }),
      render: (text: string, record) => (
        <Space>
          <VIPLevel level={record.insured_certificate.insured_person.vip_level} text={text} />
          <LabelDisplay objectId={record.insured_certificate.insured_person.id} />
        </Space>
      ),
      title: "Tên người được BH",
      width: 155,
    },
    {
      dataIndex: ["fig_claim_case", "assignee_user", "user", "short_name"],
      ellipsis: true,
      onCell: (r) => ({
        onClick: (e) => {
          if (e.altKey && r.fig_claim_case?.assignee_user?.user?.user_id != null) {
            setSearchParams((params) => {
              params.set("assignee", r.fig_claim_case?.assignee_user?.user?.user_id ?? "");
              return params;
            });
          }
        },
      }),
      title: "Đang xử lý",
      width: 100,
    },
    {
      align: "center" as AlignType,
      dataIndex: "created_at",
      render: (text: string) => {
        if (differenceInYears(new Date(text), new Date()) === 0) {
          return utils.formatDate(text, FORMATTER.DATE_TIME_WITHOUT_SEC_AND_YEAR);
        }
        return utils.formatDate(text, FORMATTER.DATE_TIME_WITHOUT_SEC);
      },
      title: "Tạo lúc",
      width: 100,
    },
    {
      align: "center" as AlignType,
      key: "tat",
      render: (_, claim) => {
        const tat = claim.tatDayHourMin;
        const tat1 = claim.tatInHourUpToNow;
        const tatCommitment = (() => {
          if (claim.is_jet === true) {
            return 0.5;
          }
          if (claim.is_direct_billing === true) {
            if (claim.benefit_type === "InPatient") {
              return 6;
            }
            return 0.5;
          }
          return claim.insured_certificate.policy.policy_setting?.tat_max ?? 24;
        })();
        const tatCommitmentMinus4Hour = claim.is_jet === true ? 0.5 : (claim.insured_certificate.policy.policy_setting?.tat_max ?? 24) - 4;
        const meetTat = tat1 < tatCommitment;
        let percentage = (tat1 / tatCommitment) * 100;
        let status: ProgressProps["status"] = "active";
        percentage = CLAIM_DECISION_STATUSES.includes(claim.status) && meetTat ? 100 : (tat1 / tatCommitmentMinus4Hour) * 100;
        if (percentage === 100) {
          status = "success";
        } else if (percentage > 100) {
          status = "exception";
        }
        if (tat1 > tatCommitmentMinus4Hour && status !== "success") {
          status = "exception";
        }
        return (
          <Tooltip style={{}} title={`TAT quy định: ${tatCommitment}h`}>
            <Flex justify="center" style={{ textAlign: "center" }}>
              <Progress format={() => tat} percent={percentage} size="small" status={status} style={{ width: 80 }} />
            </Flex>
          </Tooltip>
        );
      },
      title: "TAT",
      width: 120,
    },
    insurerId === INSURER_COMPANY_IDS.SLV
      ? {
          key: "slv_tat",
          render: (claim) => {
            if (CLAIM_DECISION_STATUSES.includes(claim.status) === true) {
              return "-";
            }
            return differenceInCalendarDays(new Date(), new Date(claim.created_at));
          },
          title: "Process TAT",
          width: 120,
        }
      : {
          dataIndex: ["request_amount"],
          render: (value: number) => {
            const formattedValue = utils.formatCurrency(value);

            if (value > 40_000_000) {
              return (
                <Typography.Text mark strong>
                  {formattedValue}
                </Typography.Text>
              );
            }
            if (value > 20_000_000) {
              return <Typography.Text mark>{formattedValue}</Typography.Text>;
            }
            if (value > 10_000_000) {
              return <Typography.Text type="warning">{formattedValue}</Typography.Text>;
            }
            return formattedValue;
          },
          sortDirections: ["descend"],
          sorter: true,
          title: "Yêu cầu",
          width: 120,
        },
    {
      dataIndex: ["claim_case_payment", "actual_paid_amount"],
      render: (value: number, record) => (
        <Space>
          {utils.formatCurrency(value)}
          <If condition={(record.claim_case_payment?.actual_paid_amount ?? 0) > 0 && record.claim_case_payment?.is_partial_paid === true}>
            <Tooltip title="Chi trả một phần">
              <CakeSlice color="green" size={14} />
            </Tooltip>
          </If>
        </Space>
      ),
      sortDirections: ["descend"] as SortOrder[],
      sorter: true,
      title: "Chi trả",
      width: 120,
    },
    {
      dataIndex: ["insured_benefit_type", "value"],
      render: (text: string) => <BenefitTag text={text} textReplace={benefitTypeOptions ? find(benefitTypeOptions, (b: { value: string }) => b.value === text)?.name : ""} />,
      title: "Quyền lợi",
      width: 100,
    },
    {
      dataIndex: "claim_case_status",
      fixed: "right" as FixedType,
      render: (claimCaseStatus, record) => {
        const havePaymentRequest = Boolean(record.payment_request_item);
        const isAutoPayment =
          record.payment_request_item?.payout_type === "AUTOMATION" ||
          (isEmpty(record.payment_request_item?.transactions) === false && (record.payment_request_item?.transactions.every((t) => t.payment_provider !== "MANUAL") ?? false));
        const isManualPayment =
          record.payment_request_item?.payout_type === "MANUAL" || (record.payment_request_item?.transactions.some((t) => t.payment_provider === "MANUAL") ?? false);

        let icon: null | ReactNode = null;
        let title: null | string = null;
        if (isAutoPayment) {
          icon = <InstantPayoutIcon fill="#08c" height={16} width={16} />;
          title = "Thanh toán tự động";
        }
        if (isManualPayment) {
          icon = <ManualPayoutIcon fill="#08c" height={16} width={16} />;
          title = "Thanh toán thủ công";
        }
        const lastRegisterSuccessLog = record.fwd_claim_case?.last_register_success_log[0];
        const lastRegisterLog = record.fwd_claim_case?.last_register_log[0];
        const lastAdjustSuccessLog = record.fwd_claim_case?.last_adjust_success_log[0];
        const lastAdjustLog = record.fwd_claim_case?.last_adjust_log[0];
        const lastDecisionSuccessLog = record.fwd_claim_case?.last_decision_success_log[0];
        const lastDecisionLog = record.fwd_claim_case?.last_decision_log[0];
        const isNotOK =
          (lastRegisterSuccessLog == null && lastRegisterLog != null) ||
          (lastAdjustSuccessLog == null && lastAdjustLog != null) ||
          (lastDecisionSuccessLog == null && lastDecisionLog != null);
        let notOkReason;
        if (lastDecisionSuccessLog == null && lastDecisionLog != null) {
          notOkReason = lastDecisionLog.response_data.description;
        } else if (lastAdjustSuccessLog == null && lastAdjustLog != null) {
          notOkReason = lastAdjustLog.response_data.description;
        } else if (lastRegisterSuccessLog == null && lastRegisterLog != null) {
          notOkReason = lastRegisterLog.response_data.description;
        }
        return (
          <Space size="small">
            <ClaimStatus status={claimCaseStatus.value} text={claimCaseStatus.comment} />
            <If condition={havePaymentRequest && ["Approved", "ApprovedReceivedDoc", "Paid"].includes(String(claimCaseStatus.value) as ClaimCaseStatusesEnum)}>
              <Tooltip placement="top" title={title}>
                <div>{icon}</div>
              </Tooltip>
            </If>
            <If condition={isNotOK}>
              <Tooltip title={notOkReason}>
                <Tag color="error" icon={<CircleX size={14} />}>
                  Có lỗi
                </Tag>
              </Tooltip>
            </If>
          </Space>
        );
      },
      title: "Trạng thái",
      width: 100,
    },
  ];

  if (getZeroOrNumber(windowSize?.width) > 2000) {
    claimListColumns.splice(1, 0, {
      dataIndex: ["medical_provider", "name"],
      title: "Cơ sở y tế",
    });
  }

  if (getZeroOrNumber(windowSize?.width) > 2200) {
    claimListColumns.splice(1, 0, {
      dataIndex: ["insured_certificate", "policy", "insurer_company", "name"],
      onCell: (r) => ({
        onClick: (e) => {
          if (e.altKey && r.insured_certificate.policy.insurer_company?.id != null) {
            setSearchParams((params) => {
              if (r.insured_certificate.policy.insurer_company?.id != null) {
                params.set("insurer-id", r.insured_certificate.policy.insurer_company.id);
              }
              return params;
            });
          }
        },
      }),
      title: "Công ty bảo hiểm",
    });
  }

  if (getZeroOrNumber(windowSize?.width) > 2200) {
    claimListColumns.splice(1, 0, {
      dataIndex: ["claim_case_assessed_diagnoses"],
      render: (_, record) => record.claim_case_assessed_diagnoses.map((diagnosis) => diagnosis.icd.value).join(", "),
      title: "ICD",
    });
  }

  if (insurerId === INSURER_COMPANY_IDS.SLV) {
    claimListColumns.splice(1, 0, {
      key: "claim_group",
      render: (record) => {
        const insurerClaimType = getInsurerClaimType(record);
        const claimGroup = record.claim_case_same_event_groups_claim_case?.claim_case_same_event_group;
        const claimGroupPath = `${CLAIM_LIST_PATH[insurerClaimType]}?keyword=${claimGroup?.code}`;

        return (
          <Space direction="vertical">
            <span>
              <Link to={claimGroupPath}>{claimGroup?.code}</Link>
              <If condition={(claimGroup?.claim_case_same_event_groups_claim_cases_aggregate.aggregate?.count ?? 0) > 1}>
                {" "}
                <Tooltip title="Số lượng claim case có trong group">[{claimGroup?.claim_case_same_event_groups_claim_cases_aggregate.aggregate?.count}]</Tooltip>
              </If>
            </span>
            {claimGroup?.slv_claim_case_same_event_group?.group_status == null ? null : <BenefitTagV3 value={claimGroup.slv_claim_case_same_event_group.group_status} />}
          </Space>
        );
      },
      title: "Claim Group",
      width: 200,
    });
  }

  const paginationConfig: TablePaginationConfig = {
    current: page,
    onChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    onShowSizeChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    pageSize: size,
    total: claimCasesCountData?.total.aggregate?.count,
  };
  return (
    <Space direction="vertical">
      <SearchSection />
      {insurerId === INSURER_COMPANY_IDS.SLV && <SlvHsTATList />}
      <TableList
        className={styles.tableClaimList}
        // components={{
        //   body: { wrapper: MotionBody },
        // }}
        data={claimCases}
        loading={loading}
        nativeScroll={{ x: "max-content" }}
        onChange={(pagination, filters, sorter) => {
          setSearchParams((params) => {
            params.set("order_by", String((sorter as SorterResult<{}>).field ?? ""));
            params.set("order_direction", (sorter as SorterResult<{}>).order ?? "");
            return params;
          });
        }}
        pagination={paginationConfig}
        rowClassName={(record) => (record.is_jet === true ? "tableRowJetStyle" : "")}
        schema={claimListColumns}
        sticky
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={8} index={2}>
                <Typography.Title level={5}>
                  Tổng cộng: {utils.formatNumber(claimCasesCountData?.total.aggregate?.count ?? 0)} - App:
                  {utils.formatNumber(claimCasesCountData?.app_total.aggregate?.count ?? 0)} - Email: {utils.formatNumber(claimCasesCountData?.email_total.aggregate?.count ?? 0)} -
                  Thư tay: {utils.formatNumber(claimCasesCountData?.post_total.aggregate?.count ?? 0)} - MP:{" "}
                  {utils.formatNumber(claimCasesCountData?.mp_total.aggregate?.count ?? 0)}
                </Typography.Title>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Space>
  );
};

const ClaimListScreenWithTransaction = withTransaction("ClaimListScreen", "component")(ClaimListScreen);
export default ClaimListScreenWithTransaction;
