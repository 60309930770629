import type { FormInstance } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import { Form, Tag } from "antd";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import styles from "../../DetailForm.module.css";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
  readonlyItem?: boolean;
} & ClaimCaseDetailFormItem;

const InputDiseasesField = ({ form, name, readonlyItem = true }: Props) => {
  const inputIcds = form.getFieldValue(name);

  const tagRender = (tagProps: CustomTagProps) => {
    const { label, value } = tagProps;
    const isMainICD = inputIcds?.[0]?.value === value;
    return (
      <Tag className={styles.tag} color={isMainICD ? "red" : "blue"} key={value}>
        {label}
      </Tag>
    );
  };

  return (
    <Form.Item label="ICD Codes người dùng nhập">
      {inputIcds == null || inputIcds.length === 0
        ? "---"
        : inputIcds.map((icd: { label: string; value: string }) => tagRender({ closable: false, disabled: false, label: icd.label, onClose: () => {}, value: icd.value }))}
    </Form.Item>
  );
};
export default InputDiseasesField;
