import type { ReactNode } from "react";

import { Button, Flex, Space, Typography } from "antd";
import ExternalLink from "app/common/components/ExternalLink";
import If from "app/common/components/If";
import { SLACK } from "config";
import { useAuth } from "contexts/AuthContext";
import utils from "libs/utils";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { useState } from "react";

const stepImages = {
  step1: "/images/docs/gmail-addon/step1.png",
  step1bis: "/images/docs/gmail-addon/step1bis.png",
  step2: "/images/docs/gmail-addon/step2.png",
  step3: "/images/docs/gmail-addon/step3.png",
  step4: "/images/docs/gmail-addon/step4.png",
  step5: "/images/docs/gmail-addon/step5.png",
  step6: "/images/docs/gmail-addon/step6.png",
  step7: "/images/docs/gmail-addon/step7.png",
  step8: "/images/docs/gmail-addon/step8.png",
  step9: "/images/docs/gmail-addon/step9.png",
  step10: "/images/docs/gmail-addon/step10.png",
};

const GmailAddonScreen = () => {
  const { user } = useAuth();
  const [effect, setEffect] = useState(false);
  const [likeIcon, setLikeIcon] = useState<ReactNode | string>(<ThumbsUp />);
  const [dislikeIcon, setDislikeIcon] = useState<ReactNode | string>(<ThumbsDown />);

  return (
    <Flex align="center" vertical>
      <Typography.Title level={1}>Hướng dẫn sử dụng Papaya Gmail Addon</Typography.Title>
      <Flex align="center" className="w-full" vertical>
        <Typography.Title level={2}>Bước 1: Cài đặt Gmail Addon</Typography.Title>
        <Typography.Title level={3}>Addon đã được cài sẵn trong tất cả hộp mail của Papaya, bao gồm cá nhân và các hộp mail dùng chung</Typography.Title>
        <Typography.Title level={3}>Addon luôn nằm ở thanh bên phải của hộp mail. Khi sử dụng lần đầu tiên, cần cấp quyền cho addon (bấm vào số 2)</Typography.Title>
        <img alt="Step 1" src={stepImages.step1} width={800} />
        <Typography.Title level={3}>Trường hợp bạn không thấy thanh bên phải, hay làm theo bước này</Typography.Title>
        <img alt="Step 1bis" src={stepImages.step1bis} width={800} />
        <Typography.Title level={2}>Bước 2: Cấp quyền</Typography.Title>
        <Typography.Title level={3}>Khi ấn vào nút cấp quyền truy cập (Authorize access), Google sẽ hiện thị danh sách tài khoản để chọn</Typography.Title>
        <Typography.Title level={3}>Bạn chọn tài khoản tương ứng với hộp mail đang dùng</Typography.Title>
        <img alt="Step 2" src={stepImages.step2} width={800} />
        <Typography.Title level={2}>Bước 3: Cấp quyền</Typography.Title>
        <Typography.Title level={3} mark>
          Addon chỉ đọc được các email đã được gửi vào các hộp mail chung của Papaya như healthcare@papaya.asia, mr@papaya.asia dạng to, cc, hoặc bcc
        </Typography.Title>
        <Typography.Title level={3}>Nếu email cá nhân của bạn có trong danh sách to, cc, hoặc bcc, bạn có thể mở email đã nhận và addon sẽ gợi ý được.</Typography.Title>
        <img alt="Step 3" src={stepImages.step3} width={800} />
        <Typography.Title level={2}>Bước 4: Bắt đầu sử dụng</Typography.Title>
        <img alt="Step 4" src={stepImages.step4} width={800} />
        <Typography.Title level={2}>Bước 5: Sọ Dừa gợi ý theo nội dung email</Typography.Title>
        <Typography.Title level={3}>Khi mở email có nội dung liên quan đến yêu cầu bồi thường, Sọ Dừa phân tích và đưa ra gợi ý các thao tác</Typography.Title>
        <Typography.Title level={3}>1: Xem nội dung email tương ứng trên Care Portal</Typography.Title>
        <Typography.Title level={3}>2: Xem yêu cầu bồi thường trên Care Portal</Typography.Title>
        <Typography.Title level={3}>3: Đưa nội dung email này vào sign off</Typography.Title>
        <Typography.Title level={3}>4: Đính kèm nội dung email này thành chứng từ của yêu cầu bồi thường.</Typography.Title>
        <img alt="Step 5" src={stepImages.step5} width={800} />
        <Typography.Title level={2}>3.1 Đưa nội dung email này vào sign off</Typography.Title>
        <img alt="Step 6" src={stepImages.step6} width={800} />
        <img alt="Step 7" src={stepImages.step7} width={800} />
        <Typography.Title level={2}>4.1: Đính kèm nội dung email này thành chứng từ của yêu cầu bồi thường.</Typography.Title>
        <Typography.Title level={3}>Khi chọn "Tải email vào chứng từ" ở bước 5, Care Portal sẽ hiển thị xác nhận tải email vào chừng từ của YCBT</Typography.Title>
        <Typography.Title level={3}>Thao tác này thay thế cho việc thông thường mọi người phải in email thành file PDF, mở YCBT tương ứng và tải lên</Typography.Title>
        <img alt="Step 8" src={stepImages.step8} width={800} />
        <Typography.Title level={3}>Khi tải thành công, sẽ có 1 chứng từ với loại là "Email" xuất hiện trong bộ chứng từ</Typography.Title>
        <img alt="Step 9" src={stepImages.step9} width={800} />
        <Typography.Title level={3}>với nội dung như khi in pdf từ Gmail</Typography.Title>
        <img alt="Step 10" src={stepImages.step10} width={800} />
        <Typography.Title level={3}>
          <Space>
            Bạn nghĩ sao về addon này?
            <If
              condition={likeIcon === "😍" && dislikeIcon === "😢"}
              else={
                <>
                  <Button
                    className={`${effect && "animate-ping"}`}
                    icon={likeIcon}
                    onAnimationEnd={() => {
                      setEffect(false);
                      setLikeIcon("😍");
                    }}
                    onClick={() => {
                      setEffect(true);
                      utils.sendMessageToSlack({
                        channel: SLACK.CHANNEL_IDS.LOC,
                        message: `${user.name} liked Gmail Addon`,
                      });
                    }}
                    style={{
                      animationIterationCount: 1,
                    }}
                    type="link"
                  />
                  <Button
                    icon={dislikeIcon}
                    onClick={() => {
                      setDislikeIcon("😢");
                      utils.sendMessageToSlack({
                        channel: SLACK.CHANNEL_IDS.LOC,
                        message: `${user.name} disliked Gmail Addon`,
                      });
                    }}
                    type="link"
                  />
                </>
              }
            >
              Ủa...vừa thích vừa không thích là sao? 🙃
            </If>
          </Space>
        </Typography.Title>
        <Typography.Title level={3}>
          Hãy góp ý và cung cấp ý tưởng tại{" "}
          <ExternalLink target="_blank" to="https://papayainsurtech.slack.com/archives/C067Z8QEDMZ">
            #product-papaya
          </ExternalLink>
        </Typography.Title>
      </Flex>
    </Flex>
  );
};

export default GmailAddonScreen;
