import PolicyFragment from "app/portal/screens/ClaimPortal/ClaimContext/graphql/PolicyFragment";
import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

const InsuredPersonFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment InsuredPerson on insured_certificates {
    insured_person {
      id
      name
      age
      died_at
      dob
      email
      gender
      insured_number
      insured_person_id
      marital_status
      paper_id
      paper_issued_at
      paper_issued_place
      phone
      subsidiary_company_name
      tpd_date
      vip_level
      individual_policies {
        id
        policy_number
      }
      insured_person_remarks(order_by: { created_at: desc }) {
        id
        description
        created_at
        insured_person_remark_benefit_type {
          id: value
          comment
        }
      }
      insured_relationship_tos {
        id: insured_person_to_id
        insured_person_to_id
        type
        insured_relationship_type {
          id: value
          comment
        }
      }
      labels {
        id
        label {
          id
          name
          color
          description
        }
      }
      marital_status_info {
        comment
        value
      }
      mbal_insured_person {
        id
        address
        job
        occupation_class
      }
      watch_by {
        id
        insurer_id
      }
    }
  }
`);

export const ClaimCaseHistoryFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimCaseHistory on claim_cases {
    id
    admission_date
    amount
    benefit_type
    code
    diagnosis
    discharge_date
    end_date
    medical_provider_name
    physical_examination_date
    source
    start_date
    status
    claim_case_assessed_diagnoses {
      id
      icd {
        id
        title
        value
      }
    }
    claim_case_payment {
      id
      actual_paid_amount
    }
    claim_case_status {
      id: value
      comment
      value
    }
    insured_benefit_type {
      id: value
      comment
      value
    }
    medical_provider {
      id
      name
    }
  }
`);

export default codegenThisGraphqlTag(
  /* GraphQL */ `
    fragment InsuredCertificate on claim_cases {
      insured_certificate {
        id
        certificate_code
        dued_at
        effective_date
        email
        endorsement_code
        endorsement_reason
        expiry_date
        insured_certificate_id
        insured_person_id
        ...InsuredPerson
        issued_at
        lapsed_date
        phone
        plan_id
        ...Policy
        reinstated_date
        settle_date
        status
        subsidiary_company_name
        sum_assured
        claim_cases(where: { claim_case_id: { _neq: $claimId } }, order_by: { start_date: desc }) {
          ...ClaimCaseHistory
        }
        claim_cases_aggregate(where: { claim_case_id: { _neq: $claimId } }) {
          aggregate {
            count
          }
        }
        endorsement_reason_type {
          id: value
          comment
        }
        from_insured_certificate_courses {
          id
          ordinal_number
          from_insured_certificate {
            id
            dued_at
            insured_certificate_id
            issued_at
            claim_cases(where: { claim_case_id: { _neq: $claimId } }, order_by: { start_date: desc }) {
              ...ClaimCaseHistory
            }
            from_insured_certificate_courses {
              id
              ordinal_number
              from_insured_certificate {
                id
                dued_at
                issued_at
                policy_plan {
                  id
                  plan_id
                  plan_name
                  policy {
                    id
                    policy_id
                    policy_number
                    corporate_company {
                      id
                      name
                    }
                    insurer_company {
                      id
                      name
                    }
                  }
                }
              }
            }
            policy_plan {
              id
              plan_id
              plan_name
              policy {
                id
                policy_id
                policy_number
                corporate_company {
                  id
                  name
                }
                insurer_company {
                  id
                  name
                }
              }
            }
          }
        }
        insured_certificate_agents(order_by: { created_at: desc }) {
          id
          type
          agent {
            id
            name
            code
            email
            group_code
            phone
            claim_cases {
              id
              code
            }
            watch_by {
              id
              insurer_id
            }
          }
        }
        insured_certificate_related_events(order_by: { effective_date: desc }) {
          id
          effective_date
          event_type
          new_value
          old_value
        }
        insured_certificate_underwriting_notes {
          id
          note
        }
        mbal_insured_certificate_beneficiaries(limit: 1, order_by: { created_at: desc }) {
          id
          bank_account
          bank_code
          bank_name
          beneficiary_name
        }
        parent_insured_certificate {
          id
          insured_certificate_id
          insured_person {
            id
            name
            email
            insured_person_id
          }
          policy {
            id
            policy_id
          }
        }
        plan {
          id
          computed_policy_terms(limit: 1) {
            id
          }
          policy_terms_aggregate {
            aggregate {
              count
            }
          }
        }
        policy_plan {
          id
          plan_code
          plan_id
          plan_name
          plan {
            id
            plan_balances(where: { allow_assessment: { _eq: true } }) {
              id
              name
              balanceRemain: value
              balanceUsed: value
              value
              plan_balance_benefits {
                id
                plan_insured_benefit {
                  id
                  insured_benefit {
                    id
                    type
                  }
                }
              }
              plan_balance_type {
                id: value
                comment
              }
            }
          }
          policy {
            id
            policy_id
            policy_number
            corporate_company {
              id
              name
            }
            insurer_company {
              id
              name
            }
          }
        }
        to_insured_certificate_courses {
          id
          to_insured_certificate {
            id
            claim_cases(where: { claim_case_id: { _neq: $claimId } }, order_by: { start_date: desc }) {
              ...ClaimCaseHistory
            }
          }
        }
      }
    }
  `,
  [PolicyFragment, InsuredPersonFragment, ClaimCaseHistoryFragment],
);
