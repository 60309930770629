import type { FormInstance, RefSelectProps } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import type { ClaimCaseStatusesEnum, InsuredBenefitTypesEnum } from "sdk/gql/graphql";

import { useToggle } from "ahooks";
import { Button, Col, Collapse, Form, notification, Radio, Row, Select, Space, Spin, Tag, Tooltip } from "antd";
import BenefitTag from "app/common/components/BenefitTag";
import Copyable from "app/common/components/Copyable";
import If from "app/common/components/If";
import WandIcon from "app/common/components/WandIcon";
import useGroove from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/ClaimBenefitVer3/InsuredBenefitsFilters/Groove";
import useIsQsrVig from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/ClaimBenefitVer3/InsuredBenefitsFilters/QsrVig";
import ClaimBenefitDetailsDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimBenefitDetailsDocument";
import ClaimInsuredBenefitDetailDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimInsuredBenefitDetailDocument";
import CreateUpdateClaimDetailDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/CreateUpdateClaimDetailDocument";
import { GET_PLAN_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/getPlanQuery";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import BPromise from "bluebird";
import { papaya } from "config/colors";
import { CLAIM_DECISION_STATUSES, FWD_MR_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS_V2, HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS } from "config/constants";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import utils from "libs/utils";
import { difference, isEmpty, merge, round, sum, uniq } from "lodash";
import { BadgeDollarSign, LockKeyhole, UnlockKeyhole, Wand2 } from "lucide-react";
import { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { graphql, type ResultOf, type VariablesOf } from "sdk/v2/graphql";

import type { AssessmentClaimCaseDetail, AssessmentForm } from "../types";

import styles from "./ClaimBenefitVer3.module.css";
import ClaimCaseDetail from "./ClaimCaseDetail";
import { calcCopayAmount, calcPayableAmount, calculatePayableAmountForClaimCaseDetail, getDisplayFields, getInitialClaimDetailValue, parseIdFromKey } from "./helpers";

const { Option } = Select;

const DeleteClaimDetailDocument = graphql(`
  mutation DeleteClaimDetail($id: UUID!) {
    deleteClaimDetail(claimDetailId: $id)
  }
`);

const useUpdateClaimCaseDetailsForm = (form: FormInstance<AssessmentForm>, status?: ClaimCaseStatusesEnum) => {
  useEffect(() => {
    form.resetFields();
  }, [status, form.resetFields, form]);
};

const BenefitTagWrapper = ({ closeable, onClick, onClose, tagProps }: { closeable?: boolean; onClick?: () => void; onClose?: (value) => void; tagProps: CustomTagProps }) => {
  const { label, value } = tagProps;
  return (
    <Tag className={`${styles.tag} cursor-pointer`} closable={closeable} color="blue" onClick={onClick} onClose={() => onClose?.(value)}>
      {label}
    </Tag>
  );
};

type Props = {
  assessingPlanBalanceId?: string;
  claimBenefitDetails: ResultOf<typeof ClaimBenefitDetailsDocument>["claimBenefitDetails"];
  claimCaseId: string;
  form: FormInstance<AssessmentForm>;
  selectedDateRangeKey: string;
};

type RefType = {
  saveClaimCaseDetails: (inputs?: { copayPercentage?: number }) => Promise<void>;
};

const ClaimCaseDetails = forwardRef<RefType, Props>(({ assessingPlanBalanceId, claimBenefitDetails, claimCaseId, form, selectedDateRangeKey }, ref) => {
  const {
    claim,
    claimDateRanges,
    claimType,
    copayAndBonus: { copayRatio, type: copayType },
    permissions: { canAssess, requireCopayAssessment },
    updateClaimMutation,
  } = useClaim();
  const [searchParams] = useSearchParams({ ccd_id: "" });
  const navigate = useNavigate();
  const [open, { set, toggle }] = useToggle();

  const benefitSelection = createRef<RefSelectProps>();
  const [currentPlanBenefitKeys, setCurrentPlanBenefitKeys] = useState<{ [key: string]: string }>({});
  const [disableAddBenefitDateRangeKeys, setDisableAddBenefitDateRangeKeys] = useState<string[]>([]);
  const [claimCaseDetailActiveKeys, setClaimCaseDetailActiveKeys] = useState<number[]>([]);
  const [getClaimInsuredBenefitDetail, { loading: getClaimInsuredBenefitDetailLoading }] = usePLazyQuery(ClaimInsuredBenefitDetailDocument);
  const { data: planData } = usePQuery(GET_PLAN_QUERY, {
    skip: claimDateRanges?.claim_date_ranges == null,
    variables: {
      insuredBenefitType: claim?.benefit_type as InsuredBenefitTypesEnum,
      planIds: claimDateRanges?.claim_date_ranges.ranges.map((claimPlan) => claimPlan.planId) ?? [],
    },
  });

  const [error, setError] = useState<{
    balanceId: string;
    benefitId: string;
  } | null>(null);

  useUpdateClaimCaseDetailsForm(form, claim?.status);

  const [saveClaimDetail, { loading: saveClaimDetailLoading }] = usePMutation(CreateUpdateClaimDetailDocument, {
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.createUpdateClaimDetail.error == null) {
        form.resetFields();
      }
    },
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, ClaimBenefitDetailsDocument]),
  });
  const [deleteClaimDetail, { loading: deleteClaimDetailLoading }] = usePMutation(DeleteClaimDetailDocument, {
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, ClaimBenefitDetailsDocument]),
  });

  const editingClaimCaseDetails = Form.useWatch("claimCaseDetails", form) ?? [];

  const initialValues: AssessmentForm = useMemo(
    () => ({
      claimCaseDetails: claimBenefitDetails.claimCaseDetails.map((ccd) => ({
        ...ccd,
        assessedTime: ccd.assessedTime,
        balanceDetails: ccd.balanceDetails,
        claimCaseDetailId: ccd.id,
        coefficient: ccd.plan_insured_benefit?.coefficient ?? undefined,
        copayAmount: ccd.copayAmount ?? ccd.totalPaidAmountBeforeCopay - ccd.totalPaidAmount,
        coPaymentAmount: ccd.coPaymentAmount,
        coverageAmount: (ccd.totalRequestAmount ?? 0) - (ccd.nonPaidAmount ?? 0),
        deductibleAmount: ccd.deductibleAmount,
        formulaType: ccd.plan_insured_benefit?.formula_type ?? undefined,
        fullDetail: ccd.claim_case_detail,
        isMagic: ccd.isMagic ?? false,
        nonPaidAmount: ccd.nonPaidAmount ?? 0,
        note: ccd.note ?? "",
        paidAmount: ccd.paidAmount,
        paidDateRange: ccd.paidFromDate != null && ccd.paidToDate != null ? [new Date(ccd.paidFromDate), new Date(ccd.paidToDate)] : undefined,
        paidFromDate: ccd.paidFromDate == null ? undefined : new Date(ccd.paidFromDate),
        paidPercentage: ccd.claim_case_detail?.paid_percentage,
        paidTime: ccd.paidTime,
        paidToDate: ccd.paidToDate == null ? undefined : new Date(ccd.paidToDate),
        planInsuredBenefitId: ccd.planInsuredBenefitId,
        requestAmount: ccd.requestAmount,
        requestDateRange: ccd.requestFromDate != null && ccd.requestToDate != null ? [new Date(ccd.requestFromDate), new Date(ccd.requestToDate)] : undefined,
        requestFromDate: ccd.requestFromDate == null ? undefined : new Date(ccd.requestFromDate),
        requestPercentage: ccd.claim_case_detail?.request_percentage,
        requestTime: ccd.requestTime,
        requestToDate: ccd.requestToDate == null ? undefined : new Date(ccd.requestToDate),
        shortfallAmount: ccd.shortfallAmount,
        totalPaidAmount: ccd.totalPaidAmount,
        totalPaidAmountBeforeCopay: ccd.totalPaidAmountBeforeCopay,
        totalRequestAmount: ccd.totalRequestAmount ?? 0,
        updatedAt: ccd.claim_case_detail?.updated_at,
      })),
    }),
    [claimBenefitDetails],
  );

  const checkQsrVig = useIsQsrVig();
  const checkGroove = useGroove();
  const planInsuredBenefits: { [key: string]: ResultOf<typeof GET_PLAN_QUERY>["plans"][number]["plan_insured_benefits"] } = useMemo(
    () =>
      Object.fromEntries(
        planData?.plans.map((plan) => [
          plan.plan_id,
          plan.plan_insured_benefits
            .filter(({ insured_benefit }) => insured_benefit.insured_benefit_type.value === claim?.benefit_type)
            .filter((benefit) => benefit.plan_balance_benefits.some(({ plan_balance_id }) => assessingPlanBalanceId === plan_balance_id) === true || claimType.fwdMr === false)
            .filter((benefit) => checkQsrVig(benefit.insured_benefit))
            .filter((benefit) => checkGroove(benefit.insured_benefit)),
        ]) ?? [],
      ),
    [assessingPlanBalanceId, checkQsrVig, checkGroove, claim?.benefit_type, claimType.fwdMr, planData?.plans],
  );

  const rebuiltClaimDateRanges = Object.fromEntries(claimDateRanges?.claim_date_ranges.ranges.map((cur) => [cur.key, cur]) ?? []);

  const selectedPlan = isEmpty(rebuiltClaimDateRanges[selectedDateRangeKey]) ? undefined : rebuiltClaimDateRanges[selectedDateRangeKey];

  const saveClaimCaseDetail = useCallback(
    async (claimCaseDetail: AssessmentClaimCaseDetail, overrideClaimCaseDetail?: Partial<AssessmentClaimCaseDetail>) => {
      const { planInsuredBenefitId } = parseIdFromKey(claimCaseDetail.key);
      if (planInsuredBenefitId == null) {
        return;
      }
      const {
        copayAmount = 0,
        coverageAmount,
        deductibleAmount,
        nonPaidAmount = 0,
        requestTime,
        shortfallAmount,
        totalPaidAmount = 0,
        totalRequestAmount = 0,
      } = merge(claimCaseDetail, overrideClaimCaseDetail);

      const paidTime = claimCaseDetail.paidTime != null && claimCaseDetail.paidTime > 0 ? claimCaseDetail.paidTime : 1;
      const input: VariablesOf<typeof CreateUpdateClaimDetailDocument>["input"] = {
        assessedTime: claimCaseDetail.assessedTime ?? 1,
        claimCaseDetailId: claimCaseDetail.claimCaseDetailId,
        claimCaseId,
        copayAmount: round(copayAmount, 2),
        coverageAmount,
        deductibleAmount,
        gracePeriodStartDate: rebuiltClaimDateRanges[selectedDateRangeKey]?.gracePeriodStartDate,
        insuredCertificateHistoryId: rebuiltClaimDateRanges[selectedDateRangeKey]?.certificateHistoryId,
        isMagic: claimCaseDetail.isMagic,
        nonPaidAmount,
        paidAmount: round(totalPaidAmount / paidTime, 2),
        paidFromDate: claimCaseDetail.paidFromDate?.toISOString(),

        paidPercentage: claimCaseDetail.paidPercentage,
        paidTime: claimCaseDetail.paidTime ?? 1,
        paidToDate: claimCaseDetail.paidToDate?.toISOString(),
        planInsuredBenefitId,
        requestAmount: totalRequestAmount / (requestTime != null && requestTime > 0 ? requestTime : 1),
        requestFromDate: claimCaseDetail.requestFromDate?.toISOString(),
        requestPercentage: claimCaseDetail.requestPercentage,
        requestTime: requestTime ?? 1,
        requestToDate: claimCaseDetail.requestToDate?.toISOString(),
        shortfallAmount,
        totalPaidAmount,
        totalPaidAmountBeforeCopay: round(claimType.fwdMr === true ? totalPaidAmount : totalPaidAmount + copayAmount, 2),
        totalRequestAmount,
      };
      const createUpdateRes = await saveClaimDetail({
        variables: {
          input,
          options: {
            copayDeductType: copayType === "copay-before-balance" ? "BeforeBalance" : "AfterBalance",
            validateTotalPaidAmountBeforeCopay: claimType.fwdMr,
          },
        },
      });
      if (createUpdateRes.data?.createUpdateClaimDetail.error == null) {
        setDisableAddBenefitDateRangeKeys((prev) => prev.filter((id) => id !== selectedDateRangeKey));
        setError(null);
        if (claim?.id != null && claim.assessed_plan_balance_id == null && claimType.fwdMr === true) {
          updateClaimMutation({
            variables: {
              claimId: claim.id,
              input: {
                assessed_plan_balance_id: assessingPlanBalanceId,
              },
            },
          });
        }
        notification.success({ message: "Cập nhật quyền lợi thành công" });
        isAutomationRunRef.current = "NOT_RUN";
      } else {
        const { error: upsertError } = createUpdateRes.data.createUpdateClaimDetail;
        setError({
          balanceId: upsertError.planBalanceId,
          benefitId: planInsuredBenefitId,
        });
        const denominatorName = getDisplayFields(claimCaseDetail, claimType).paid.displayUnitName;
        switch (upsertError.code) {
          case "InvalidCurrencyPerTime": {
            notification.error({
              description: "Hạn mức tiền vượt quá trên lần YCBT",
              message: "Sai hạn mức tiền trên lần YCBT",
            });
            break;
          }
          case "InvalidCurrencyPerYear": {
            notification.error({
              description: "Hạn mức tổng vượt quá giới hạn",
              message: "Sai hạn mức tổng",
            });
            break;
          }
          case "InvalidTimePerYear":
          case "InvalidPerCaseAndDay": {
            notification.error({
              description: `Hạn mức tiền vượt quá trên ${denominatorName}`,
              message: `Sai hạn mức tiền trên ${denominatorName}`,
            });
            break;
          }
          case "InvalidPaidTimePerYear":
          case "InvalidSumAssuredPercentage": {
            notification.error({
              description: `Hạn mức ${denominatorName} vượt quá giới hạn`,
              message: `Sai hạn mức ${denominatorName}`,
            });
            break;
          }
          default: {
            notification.error({ message: `Cập nhật quyền lợi không thành công. (Code: ${upsertError.code})` });
          }
        }
      }
    },
    [
      claimCaseId,
      rebuiltClaimDateRanges,
      selectedDateRangeKey,
      claimType,
      saveClaimDetail,
      copayType,
      claim?.id,
      claim?.assessed_plan_balance_id,
      updateClaimMutation,
      assessingPlanBalanceId,
    ],
  );

  const recalculateClaimDetails = useCallback(
    async ({ claimDetails, deductibleAmount }: { claimDetails: AssessmentClaimCaseDetail[]; deductibleAmount: number }) => {
      const shouldRecalculateAmountForClaimCaseDetail = deductibleAmount > 0;
      if (shouldRecalculateAmountForClaimCaseDetail === false) {
        return;
      }
      await BPromise.mapSeries(
        claimDetails.filter((ccd) => ccd.id != null),
        async (claimCaseDetail, index) => {
          const paidBenefit = calculatePayableAmountForClaimCaseDetail({ claimCaseDetail, claimStatus: claim?.status });
          const { nonPaidAmount = 0, totalRequestAmount = 0 } = claimCaseDetail;
          const { copayAmount, coverageAmount, expectedPayableAmount, shortfallAmount, totalPayableAmount } = calcPayableAmount({
            caller: "recalculateClaimDetails",
            claimType,
            copayRatio,
            deductibleAmount,
            nonPaidAmount,
            payableBenefit: paidBenefit,
            totalRequestAmount,
          });

          form.setFieldValue(["claimCaseDetails", index, "totalPaidAmountBeforeCopay"], expectedPayableAmount);
          form.setFieldValue(["claimCaseDetails", index, "coverageAmount"], coverageAmount);
          form.setFieldValue(["claimCaseDetails", index, "copayAmount"], copayAmount);
          form.setFieldValue(["claimCaseDetails", index, "totalPaidAmount"], totalPayableAmount > 0 ? totalPayableAmount : 0);
          form.setFieldValue(["claimCaseDetails", index, "shortfallAmount"], shortfallAmount);
          form.setFieldValue(["claimCaseDetails", index, "deductibleAmount"], deductibleAmount);
          await saveClaimCaseDetail(claimCaseDetail, {
            copayAmount,
            coverageAmount,
            deductibleAmount,
            shortfallAmount,
            totalPaidAmount: totalPayableAmount,
          });
        },
      );
    },
    [claim?.status, claimType, copayRatio, form, saveClaimCaseDetail],
  );

  const modifySelectedBenefits = useCallback(
    async (
      changedPlanInsuredBenefitKeys: string[],
      add: (defaultValue?: AssessmentClaimCaseDetail, insertIndex?: number) => void,
      remove: (index: number | number[]) => void,
      onAdded?: () => void,
    ) => {
      const curPlanInsuredBenefitKeys = editingClaimCaseDetails.map((ccd) => ccd.key).filter((key) => key?.startsWith(selectedDateRangeKey));

      const newKey = difference(changedPlanInsuredBenefitKeys, curPlanInsuredBenefitKeys)[0];
      const oldKey = difference(curPlanInsuredBenefitKeys, changedPlanInsuredBenefitKeys)[0];

      if (newKey != null) {
        const deductibleAmount = (claim?.claim_case_payment?.deductible_amount ?? 0) / (editingClaimCaseDetails.length + 1);
        await recalculateClaimDetails({ claimDetails: editingClaimCaseDetails, deductibleAmount });

        const { certificateHistoryId, planInsuredBenefitId } = parseIdFromKey(newKey);
        const data = await getClaimInsuredBenefitDetail({
          variables: {
            claimCaseId,
            gracePeriodStartDate: rebuiltClaimDateRanges[selectedDateRangeKey]?.gracePeriodStartDate,
            insuredCertificateHistoryId: certificateHistoryId ?? "",
            planInsuredBenefitId: planInsuredBenefitId ?? "",
          },
        });
        if (data.error != null || data.data == null) {
          notification.error({ message: "Lấy thông tin chi tiết quyền lợi thất bại" });
          return;
        }

        const newClaimCaseDetail = getInitialClaimDetailValue({
          claim,
          claimDetailList: editingClaimCaseDetails,
          claimInsuredBenefitDetail: data.data.claimInsuredBenefitDetail,
          claimType,
          copayRatio,
          key: newKey,
          planInsuredBenefits,
          selectedDateRangeKey,
          selectedPlan,
        });

        setClaimCaseDetailActiveKeys((prev) => [...prev, editingClaimCaseDetails.length]);
        add(newClaimCaseDetail);
        setDisableAddBenefitDateRangeKeys((prev) => uniq([...prev, selectedDateRangeKey]));
        setCurrentPlanBenefitKeys((prev) => ({ ...prev, [selectedDateRangeKey]: newKey }));
      }

      if (oldKey != null && editingClaimCaseDetails.find((ccd) => ccd.key === oldKey)?.claimCaseDetailId == null) {
        const indexOldKey = editingClaimCaseDetails.findIndex((ccd) => ccd.key === oldKey);
        if (indexOldKey === -1) return;
        remove(indexOldKey);
        form.setFieldValue("benefit_suggestions", null);
        setDisableAddBenefitDateRangeKeys((prev) => prev.filter((key) => key !== selectedDateRangeKey));
        const deductibleAmount = (claim?.claim_case_payment?.deductible_amount ?? 0) / (editingClaimCaseDetails.length - 1);
        await recalculateClaimDetails({
          claimDetails: editingClaimCaseDetails.filter((ccd) => ccd.key !== oldKey),
          deductibleAmount,
        });
      }
      onAdded?.();
    },
    [
      editingClaimCaseDetails,
      selectedDateRangeKey,
      claim,
      recalculateClaimDetails,
      getClaimInsuredBenefitDetail,
      claimCaseId,
      rebuiltClaimDateRanges,
      selectedPlan,
      claimType,
      copayRatio,
      planInsuredBenefits,
      form,
    ],
  );
  type automationState = "FAILED" | "NOT_RUN" | "SUCCESS";
  const isAutomationRunRef = useRef<automationState>("NOT_RUN");

  useEffect(() => {
    if (Object.keys(planInsuredBenefits).length === 0) return;
    if (isAutomationRunRef.current !== "NOT_RUN") return;
    if (!claimType.fwdMr) {
      isAutomationRunRef.current = "FAILED";
      return;
    }
    if (CLAIM_DECISION_STATUSES.includes(claim?.status ?? "")) {
      isAutomationRunRef.current = "SUCCESS";
      return;
    }

    const fwdMrAutomationRunner = async () => {
      const planId = Object.keys(planInsuredBenefits)[0];
      if (assessingPlanBalanceId == null || claim?.benefit_type == null || planId == null) {
        isAutomationRunRef.current = "SUCCESS";
        return;
      }
      const autoSelectedBenefit = planInsuredBenefits[planId]?.find((i) =>
        i.plan_balance_benefits.some(
          ({ plan_balance }) => FWD_MR_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS_V2[`${claim.benefit_type}_${plan_balance.name}`] === i.insured_benefit.code,
        ),
      );

      if (claimBenefitDetails.claimCaseDetails.some((ccd) => ccd.planInsuredBenefitId === autoSelectedBenefit?.id)) {
        isAutomationRunRef.current = "FAILED";
      }
      if (autoSelectedBenefit == null) return;
      const key = `${selectedDateRangeKey}_${autoSelectedBenefit.id}`;
      const add = (defaultValue?: AssessmentClaimCaseDetail) => {
        form.setFieldValue(["claimCaseDetails"], [{ ...defaultValue, isMagic: true, nonPaidAmount: 0, totalRequestAmount: claim.request_amount }]);
      };
      await modifySelectedBenefits([key], add, () => {});
      isAutomationRunRef.current = "SUCCESS";
    };
    fwdMrAutomationRunner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claimType.fwdMr,
    assessingPlanBalanceId,
    claim?.benefit_type,
    claim?.request_amount,
    form,
    // modifySelectedBenefits,
    planInsuredBenefits,
    selectedDateRangeKey,
    claim?.status,
    claimBenefitDetails.claimCaseDetails,
  ]);

  const claimingBenefitsWithinDateRange = useMemo(() => {
    const planIdWithinDateRange = rebuiltClaimDateRanges[selectedDateRangeKey]?.planId;
    return planInsuredBenefits[planIdWithinDateRange ?? ""] ?? [];
  }, [rebuiltClaimDateRanges, planInsuredBenefits, selectedDateRangeKey]);

  const deductibleAmountOnClaimDetail = useMemo(
    () => (claim?.claim_case_payment?.deductible_amount ?? 0) / editingClaimCaseDetails.length,
    [claim?.claim_case_payment?.deductible_amount, editingClaimCaseDetails.length],
  );

  const unlockFocusAndLockAll = (benefitId: string) => {
    setCurrentPlanBenefitKeys((prev) => ({ ...prev, [selectedDateRangeKey]: benefitId }));
  };

  const deleteClaimCaseDetail = async (claimCaseDetail: AssessmentClaimCaseDetail) => {
    const { claimCaseDetailId } = claimCaseDetail;
    if (claimCaseDetailId == null) {
      setDisableAddBenefitDateRangeKeys([]);
      return;
    }
    const remainClaimDetails = editingClaimCaseDetails.filter((ccd) => ccd.claimCaseDetailId !== claimCaseDetailId);
    await deleteClaimDetail({
      variables: {
        id: claimCaseDetailId,
      },
    });
    const deductibleAmount = (claim?.claim_case_payment?.deductible_amount ?? 0) / remainClaimDetails.length;
    await recalculateClaimDetails({ claimDetails: remainClaimDetails, deductibleAmount });
  };

  const formRef = useRef<FormInstance<AssessmentForm>>(null);
  useImperativeHandle(
    ref,
    () => ({
      saveClaimCaseDetails: async (inputs) => {
        const copayPercentage = inputs?.copayPercentage ?? claim?.claim_case_payment?.co_payment_ratio ?? 0;
        await BPromise.mapSeries(editingClaimCaseDetails, async (claimCaseDetail, index) => {
          const copayAmount = calcCopayAmount({
            claimCaseDetail,
            claimCaseDetailId: claimCaseDetail.claimCaseDetailId,
            claimType,
            copayRatio: copayPercentage,
            deductibleAmount: deductibleAmountOnClaimDetail,
          });
          form.setFieldValue(["claimCaseDetails", index, "copayAmount"], copayAmount);
          await saveClaimCaseDetail(claimCaseDetail, {
            copayAmount,
          });
        });
      },
    }),
    [editingClaimCaseDetails, claimType, claim?.claim_case_payment?.co_payment_ratio, deductibleAmountOnClaimDetail, form, saveClaimCaseDetail],
  );

  useEffect(() => {
    setClaimCaseDetailActiveKeys(claimBenefitDetails.claimCaseDetails.map((_, index) => index));
  }, [claimBenefitDetails]);

  const BenefitSuggestions = useCallback(() => {
    if (claim?.benefit_type == null) return null;
    if (claimType.fwdMr || HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type] == null) {
      return null;
    }

    return (
      <Space>
        <WandIcon color={papaya} name="wand-2" size={18} />
        <Form.Item name="benefit_suggestions" style={{ marginBottom: 5 }}>
          <Radio.Group
            buttonStyle="outline"
            disabled={!canAssess || requireCopayAssessment}
            onChange={async (e) => {
              const autoSelectedBenefit = (() => {
                if (selectedPlan != null && HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type] != null) {
                  const selectedInsuredBenefitCode = planInsuredBenefits[selectedPlan.planId]?.find((b) => b.id === e.target.value)?.insured_benefit.code;
                  if (HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type].includes(selectedInsuredBenefitCode) === false) {
                    return null;
                  }
                  return planInsuredBenefits[selectedPlan.planId]?.find((i) => i.insured_benefit.code === selectedInsuredBenefitCode);
                }
                return null;
              })();
              if (autoSelectedBenefit == null) return;
              const key = `${selectedDateRangeKey}_${autoSelectedBenefit.id}`;
              const add = (defaultValue?: AssessmentClaimCaseDetail) => {
                // calculate remaining request amount based on used request amount
                const totalRequestAmountRemaining = (claim.request_amount ?? 0) - sum(claimBenefitDetails.claimCaseDetails.map((ccd) => ccd.requestAmount));
                form.setFieldValue(
                  ["claimCaseDetails"],
                  [
                    ...(initialValues.claimCaseDetails ?? []),
                    {
                      ...defaultValue,
                      isMagic: true,
                      nonPaidAmount: 0,
                      totalRequestAmount: totalRequestAmountRemaining,
                    },
                  ],
                );
              };
              await modifySelectedBenefits([key], add, () => {});
              isAutomationRunRef.current = "SUCCESS";
            }}
            options={HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type]
              .map((benefitCode) => {
                if (selectedPlan == null) return null;
                const suggestingBenefit = planInsuredBenefits[selectedPlan.planId]?.find((b) => b.insured_benefit.code === benefitCode);
                if (suggestingBenefit == null) return null;
                return {
                  label: (
                    <span style={{ color: papaya }}>
                      {suggestingBenefit.insured_benefit.insured_benefit_type.value} - {suggestingBenefit.insured_benefit.code} - {suggestingBenefit.insured_benefit.name}
                    </span>
                  ),
                  value: suggestingBenefit.id,
                };
              })
              .filter(Boolean)}
            optionType="button"
          />
        </Form.Item>
      </Space>
    );
  }, [
    initialValues.claimCaseDetails,
    claim?.benefit_type,
    claim?.request_amount,
    claimType.fwdMr,
    selectedPlan,
    planInsuredBenefits,
    selectedDateRangeKey,
    modifySelectedBenefits,
    claimBenefitDetails.claimCaseDetails,
    form,
    canAssess,
    requireCopayAssessment,
  ]);

  return (
    <Spin spinning={getClaimInsuredBenefitDetailLoading}>
      <Space direction="vertical">
        <Form form={form} initialValues={initialValues} layout="vertical" ref={formRef}>
          <BenefitSuggestions />
          <Form.List name="claimCaseDetails">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Select
                      className="w-100"
                      disabled={disableAddBenefitDateRangeKeys.includes(selectedDateRangeKey) || !canAssess || requireCopayAssessment}
                      filterOption={(input, option) => {
                        const planInsuredBenefit = claimingBenefitsWithinDateRange.find((benefit) => benefit.id === option?.id);
                        if (planInsuredBenefit?.insured_benefit == null) return false;
                        return (
                          planInsuredBenefit.insured_benefit.name.toLowerCase().includes(input.toLowerCase()) ||
                          planInsuredBenefit.insured_benefit.code.toLowerCase().includes(input.toLowerCase())
                        );
                      }}
                      mode="multiple"
                      onChange={async (values) => {
                        const selectedInsuredBenefits = values.filter(Boolean);
                        const selectedBenefit = selectedInsuredBenefits[0];
                        const { planInsuredBenefitId } = parseIdFromKey(selectedBenefit);
                        const autoSelectedBenefit = (() => {
                          if (claim != null && selectedPlan != null && HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type] != null) {
                            const selectedInsuredBenefitCode = planInsuredBenefits[selectedPlan.planId]?.find((b) => b.id === planInsuredBenefitId)?.insured_benefit.code;
                            if (HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS[claim.benefit_type].includes(selectedInsuredBenefitCode) === false) {
                              return null;
                            }
                            return planInsuredBenefits[selectedPlan.planId]?.find((i) => i.insured_benefit.code === selectedInsuredBenefitCode);
                          }
                          return null;
                        })();
                        if (
                          selectedInsuredBenefits.length === 1 &&
                          planInsuredBenefitId === autoSelectedBenefit?.id &&
                          claim?.benefit_type != null &&
                          autoSelectedBenefit != null
                        ) {
                          // conjure magic
                          const key = `${selectedDateRangeKey}_${autoSelectedBenefit.id}`;

                          const autoAdd = (defaultValue?: AssessmentClaimCaseDetail) => {
                            form.setFieldValue(
                              ["claimCaseDetails"],
                              [
                                {
                                  ...defaultValue,
                                  isMagic: true,
                                  nonPaidAmount: 0,
                                  totalRequestAmount: claim.request_amount,
                                },
                              ],
                            );
                          };
                          await modifySelectedBenefits([key], autoAdd, remove);
                          isAutomationRunRef.current = "SUCCESS";
                        } else {
                          await modifySelectedBenefits(selectedInsuredBenefits, add, remove);
                        }
                      }}
                      onDropdownVisibleChange={set}
                      onSelect={() => benefitSelection.current?.blur()}
                      open={open}
                      placeholder="Chọn quyền lợi"
                      ref={benefitSelection}
                      showSearch
                      size="large"
                      tagRender={(tagProps) => (
                        <BenefitTagWrapper
                          closeable={editingClaimCaseDetails.find((ccd) => ccd.key === tagProps.value)?.claimCaseDetailId == null}
                          onClick={() => {
                            set(false);
                            navigate(`#claim_case_detail_${tagProps.value.split("_")[2]}`);
                          }}
                          onClose={() => {
                            const selectedInsuredBenefits = editingClaimCaseDetails
                              .map((ccd) => ccd.key)
                              .filter((b) => b !== tagProps.value)
                              .filter(Boolean);

                            modifySelectedBenefits(selectedInsuredBenefits, add, remove);
                          }}
                          tagProps={tagProps}
                        />
                      )}
                      value={editingClaimCaseDetails.map((ccd) => ccd.key).filter((key) => key?.startsWith(selectedDateRangeKey))}
                    >
                      {selectedPlan == null
                        ? null
                        : planInsuredBenefits[selectedPlan.planId]?.map((benefit) => (
                            <Option id={benefit.id} key={`${selectedDateRangeKey}_${benefit.id}`} value={`${selectedDateRangeKey}_${benefit.id}`}>
                              <BenefitTag isSmall text={benefit.insured_benefit.insured_benefit_type.value} />
                              <Tag color="geekblue">{benefit.insured_benefit.code}</Tag>
                              {benefit.is_direct_billing === true ? <Tag color="geekblue">Có bảo lãnh</Tag> : ""}
                              {benefit.insured_benefit.name}
                            </Option>
                          ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Collapse
                      activeKey={claimCaseDetailActiveKeys}
                      className="w-100 mt-8"
                      items={fields
                        .reverse()
                        .map(({ name }) => {
                          const planInsuredBenefit =
                            selectedPlan != null && editingClaimCaseDetails[name]?.key != null
                              ? planInsuredBenefits[selectedPlan.planId]?.find((b) => b.id === parseIdFromKey(editingClaimCaseDetails[name]?.key).planInsuredBenefitId)
                              : null;
                          const benefitName = planInsuredBenefit?.insured_benefit.name;
                          if (benefitName == null) return null;
                          return {
                            children: (
                              <Col key={name} span={24}>
                                <ClaimCaseDetail
                                  claimCaseDetail={editingClaimCaseDetails[name]}
                                  claimCaseDetailActions={{
                                    delete: async () => {
                                      const claimCaseDetail = editingClaimCaseDetails[name];
                                      if (claimCaseDetail != null) {
                                        await deleteClaimCaseDetail(claimCaseDetail);
                                        remove(name);
                                        notification.success({ message: "Xóa thành công" });
                                      }
                                    },
                                    save: () => {
                                      const claimCaseDetail = editingClaimCaseDetails[name];
                                      if (claimCaseDetail != null) {
                                        form
                                          .validateFields()
                                          .then(() => saveClaimCaseDetail(claimCaseDetail))
                                          .catch(() => {
                                            notification.error({ message: "Cập nhật không thành công" });
                                          });
                                      }
                                    },
                                  }}
                                  claimCaseDetailActionsLoading={{
                                    delete: deleteClaimDetailLoading,
                                    save: saveClaimDetailLoading,
                                  }}
                                  claimCaseDetailOrder={name}
                                  currentPlanBenefitIds={currentPlanBenefitKeys}
                                  deductibleAmountOnClaimDetail={deductibleAmountOnClaimDetail}
                                  error={error}
                                  form={form}
                                  lockButton={
                                    <Button
                                      disabled={canAssess === false}
                                      icon={currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? <UnlockKeyhole /> : <LockKeyhole />}
                                      onClick={(e) => {
                                        unlockFocusAndLockAll(
                                          (currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? "" : editingClaimCaseDetails[name]?.key) ?? "",
                                        );
                                        e.stopPropagation();
                                      }}
                                      shape="circle"
                                      size="middle"
                                      type={currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? "default" : "text"}
                                    />
                                  }
                                  planInsuredBenefits={planInsuredBenefits}
                                  selectedDateRangeKey={selectedDateRangeKey}
                                  selectedPlan={selectedPlan}
                                />
                              </Col>
                            ),
                            key: name,
                            label: (
                              <section id={`claim_case_detail_${editingClaimCaseDetails[name]?.planInsuredBenefitId}`}>
                                <div className={styles.childPanelHeader}>
                                  <div style={{ color: editingClaimCaseDetails[name]?.id === searchParams.get("ccd_id") ? "blue" : "unset" }}>
                                    <Space>
                                      <Tooltip
                                        title={
                                          <Space direction="vertical">
                                            <div>
                                              - Tạo lúc: {utils.formatDate(editingClaimCaseDetails[name]?.fullDetail?.created_at)} -{" "}
                                              {editingClaimCaseDetails[name]?.fullDetail?.created_user?.name ?? "-"}
                                            </div>
                                            <div>
                                              - Cập nhật lúc: {utils.formatDate(editingClaimCaseDetails[name]?.updatedAt)} -{" "}
                                              {editingClaimCaseDetails[name]?.fullDetail?.updated_user?.name}
                                            </div>
                                            <div>
                                              - ID:{" "}
                                              <Copyable show style={{ color: "white" }}>
                                                {editingClaimCaseDetails[name]?.id}
                                              </Copyable>
                                            </div>
                                          </Space>
                                        }
                                      >
                                        <Space>
                                          <BenefitTagWrapper
                                            tagProps={{
                                              closable: false,
                                              disabled: true,
                                              label: (
                                                <Space>
                                                  <BenefitTag isSmall text={planInsuredBenefit?.insured_benefit.insured_benefit_type.value} />
                                                  <Tag color="geekblue">{planInsuredBenefit?.insured_benefit.code}</Tag>
                                                  {planInsuredBenefit?.is_direct_billing === true ? <Tag color="geekblue">Có bảo lãnh</Tag> : ""}
                                                  {planInsuredBenefit?.insured_benefit.name}
                                                </Space>
                                              ),
                                              onClose: () => {},
                                              value: editingClaimCaseDetails[name]?.key,
                                            }}
                                          />
                                          <Tooltip title={planInsuredBenefit?.apply_copay === true ? "Có copay" : "Không copay"}>
                                            <BadgeDollarSign color={planInsuredBenefit?.apply_copay === true ? papaya : "grey"} size={18} />
                                          </Tooltip>
                                        </Space>
                                      </Tooltip>
                                      <If condition={editingClaimCaseDetails[name]?.isMagic === true}>
                                        <Tooltip title="">
                                          <div>
                                            <Wand2 color={papaya} size={16} />
                                          </div>
                                        </Tooltip>
                                      </If>
                                    </Space>
                                    {import.meta.env.DEV ? <Copyable>{editingClaimCaseDetails[name]?.id}</Copyable> : null}
                                  </div>
                                  <div>
                                    <If condition={canAssess}>
                                      <Button
                                        icon={currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? <UnlockKeyhole /> : <LockKeyhole />}
                                        onClick={(e) => {
                                          unlockFocusAndLockAll(
                                            (currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? "" : editingClaimCaseDetails[name]?.key) ?? "",
                                          );
                                          e.stopPropagation();
                                        }}
                                        shape="circle"
                                        size="middle"
                                        type={currentPlanBenefitKeys[selectedDateRangeKey] === editingClaimCaseDetails[name]?.key ? "default" : "text"}
                                      />
                                    </If>
                                  </div>
                                </div>
                              </section>
                            ),
                            onItemClick: () => {
                              setClaimCaseDetailActiveKeys((prev) => (prev.includes(name) ? prev.filter((key) => key !== name) : [...prev, name]));
                            },
                          };
                        })
                        .filter(Boolean)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Space>
    </Spin>
  );
});

export default ClaimCaseDetails;
