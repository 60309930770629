import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation UpsertClaimCasePayment($object: claim_case_payments_insert_input!) {
    insert_claim_case_payments_one(
      object: $object
      on_conflict: { constraint: claim_case_payments_pkey, update_columns: [co_payment_ratio, deductible_amount, custom_amount, custom_amount_reason] }
    ) {
      id
      claim_case_id
      co_payment_ratio
      deductible_amount
    }
  }
`);
