import type { FormInstance } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import { useToggle } from "ahooks";
import { Button, Flex, Form, Image, Popover, Select, Space, Tag, Tooltip } from "antd";
import If from "app/common/components/If";
import Spin from "app/common/components/Spin";
import WandIcon from "app/common/components/WandIcon";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useClaimAssistant from "app/portal/screens/ClaimPortal/Contexts/ClaimAssistantContext/useClaimAssitant";
import { SLACK } from "config";
import { useAuth } from "contexts/AuthContext";
import useMetadata from "hooks/useMetadata";
import utils from "libs/utils";
import { uniqBy } from "lodash";
import { CircleHelp } from "lucide-react";
import { useMemo } from "react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import styles from "../../DetailForm.module.css";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
  readonlyItem: boolean;
} & ClaimCaseDetailFormItem;

const Suggestion = ({ onAccept }: { onAccept: (values: { key: string; label: string; value: string }[]) => void }) => {
  const { claim } = useClaim();
  const { suggestions } = useClaimAssistant();
  const { user } = useAuth();
  const [visible, { set, setRight }] = useToggle();

  const suggestion = suggestions?.find((s) => s.type === "icd");

  if (suggestion?.matchObjects == null || suggestion.matchObjects.length === 0) return null;

  return (
    <Popover
      content={
        <Flex gap={30} style={{ height: 80, textAlign: "center" }} vertical>
          <div className="color-papaya">{suggestion.matchObjects.map((i) => i.value).join("; ")}</div>
          <Button
            onClick={() => {
              onAccept(suggestion.matchObjects.map(({ id, title, value }) => ({ key: id, label: `${value} - ${title}`, value })));
              utils.sendMessageToSlack({
                channel: SLACK.CHANNEL_IDS.TRACK_USAGE,
                message: `<@${user.slack_member_id}> accepted ICD suggestion for <${window.location.href}|${claim?.code}>`,
              });
            }}
            type="primary"
          >
            Áp dụng
          </Button>
        </Flex>
      }
      title={
        <span>
          Sọ Dừa Gợi Ý{" "}
          <Button disabled={false} onClick={setRight} type="link">
            File gốc
          </Button>
          {suggestion.originalDocument?.file?.url != null && (
            <Image
              className="display-none"
              preview={{
                onVisibleChange: (value) => set(value),
                scaleStep: 0.5,
                src: suggestion.originalDocument.file.url,
                visible,
              }}
              src={`${suggestion.originalDocument.file.url}?width=148`}
            />
          )}
        </span>
      }
    >
      <div>
        <WandIcon className="cursor-pointer" size={16} />
      </div>
    </Popover>
  );
};

const AssessedDiseasesField = ({ form, name, readonlyItem }: Props) => {
  const { isLifeInsuranceRider } = useClaim();
  const { data: selectedDiseaseData, refetch } = useMetadata(
    { limit: 10, types: ["diseases"] },
    {
      variables: {
        where: {
          metadata_id: {
            _in: form.getFieldValue(name)?.map((item: any) => item.key),
          },
        },
      },
    },
  );

  const { data: diseaseData, loading: loadingDiseases, searchMetadata } = useMetadata({ limit: 10, types: ["diseases"] });
  const fieldAssessedDiseasesValue = Form.useWatch(name, form);

  const tagRender = (tagProps: CustomTagProps) => {
    const { label, onClose, value } = tagProps;
    const isMainICD = fieldAssessedDiseasesValue?.[0]?.value === value;
    return (
      <Tag className={styles.tag} closable color={isMainICD ? "red" : "blue"} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const options = useMemo(
    () =>
      uniqBy([...(diseaseData?.metadata ?? []), ...(selectedDiseaseData?.metadata ?? [])], "value").map((disease) => ({
        key: disease.metadata_id,
        label: `${disease.value} - ${disease.name}`,
        title: disease.name,
        value: disease.value,
      })),
    [diseaseData?.metadata, selectedDiseaseData?.metadata],
  );

  return (
    <Form.Item
      label={
        <If
          condition={!readonlyItem}
          else={
            <label className="cursor-pointer">
              ICD Code thẩm định <CircleHelp color="#eb2f96" size={16} />
            </label>
          }
        >
          <Space className="cursor-pointer">
            <span>ICD Code thẩm định </span>
            <Tooltip mouseEnterDelay={0.5} mouseLeaveDelay={0.5} placement="topLeft" title="Lưu ý: Code đầu tiên áp dụng cho Bệnh chính. Các code còn lại sẽ là bệnh kèm theo">
              <CircleHelp color="#eb2f96" size={16} />
            </Tooltip>
            <Suggestion
              onAccept={(values) => {
                form.setFieldValue(name, values);
                refetch({
                  where: {
                    metadata_id: {
                      _in: values.map((item) => item.key),
                    },
                  },
                });
              }}
            />
          </Space>
        </If>
      }
      name={name}
      rules={[
        {
          message: "Vui lòng nhập ICD code",
          required: true,
        },
      ]}
    >
      <Select
        autoClearSearchValue
        className={styles.autoComplete}
        filterOption={false}
        labelInValue
        mode="multiple"
        notFoundContent={loadingDiseases ? <Spin size="small" /> : null}
        onChange={(selectedOptions) => {
          if (isLifeInsuranceRider) {
            form.setFieldValue("diagnosis", selectedOptions.map((item) => item.title).join("; "));
          }
        }}
        onSearch={searchMetadata}
        options={options}
        placeholder="Nhập ICD code/tên bệnh để tìm kiếm"
        size="large"
        tagRender={tagRender}
      />
    </Form.Item>
  );
};
export default AssessedDiseasesField;
