import type { TagProps } from "antd/lib";
import type { CSSProperties } from "react";

import { Tag } from "antd";

const className: CSSProperties = {
  fontSize: "14px",
  margin: "5px 4px",
  padding: "5px",
  whiteSpace: "normal",
};

const IcdTag = ({
  isMain = false,
  value,
  ...props
}: {
  isMain?: boolean;
  value?: {
    description?: null | string;
    title?: null | string;
    value?: null | string;
  };
} & TagProps) => {
  if (value == null) return null;
  return (
    <Tag color={isMain ? "red" : "blue"} style={className} {...props}>
      {`${value.value} - ${value.title}`}
    </Tag>
  );
};

export default IcdTag;
