import { Button, Col, Form, Row, Select } from "antd";
import DatePicker from "app/common/components/CustomAntdTimePickers/DatePicker";
import MedicalProviderSelectOption from "app/common/components/MedicalProviderSelectOption";
import Spin from "app/common/components/Spin";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useFwd from "app/portal/screens/ClaimPortal/ClaimContext/useFwd";
import { FORMATTER, INSURER_COMPANY_IDS } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { debounce } from "lodash";

import { GetFwdMedicalProviderDocument } from "./FwdClaimRightColumn.generated";
import styles from "./FwdClaimRightColumn.module.css";

const RegisterForm = () => {
  const { claim } = useClaim();
  const { loading, registerClaim } = useFwd();
  const { data: medicalProvidersData, loading: loadingMedicalProviders } = usePQuery(GetFwdMedicalProviderDocument, {
    variables: {
      fwdInsurerId: INSURER_COMPANY_IDS.FWD,
    },
  });

  const [registerForm] = Form.useForm();

  return (
    <Form
      form={registerForm}
      initialValues={{
        eventDate: claim?.start_date == null ? undefined : new Date(claim.start_date),
        isGenNewClaimNumber: false,
        medicalProviderId: claim?.medical_provider_id,
      }}
      onFinish={debounce(registerClaim, 1000)}
    >
      <Spin spinning={loading}>
        <Row justify="end">
          <Col span={24}>
            <Form.Item label="Chọn cơ sở y tế" labelCol={{ span: 6 }} name="medicalProviderId">
              <Select
                autoClearSearchValue
                className={styles.autoComplete}
                filterOption={(input, option) => (option?.name ?? "").toString().toLowerCase().includes(input.toLowerCase())}
                loading={loadingMedicalProviders}
                notFoundContent={loading ? <Spin size="small" /> : null}
                optionFilterProp="children"
                optionLabelProp="selectedLabel"
                options={
                  medicalProvidersData?.mp_medical_providers.map((med) => ({
                    disabled: claim?.is_direct_billing === true && med.is_direct_billing === false,
                    label: <MedicalProviderSelectOption directBillingOnly={claim?.is_direct_billing === true} key={med.id} mp={med} />,
                    name: med.name,
                    selectedLabel: <MedicalProviderSelectOption directBillingOnly={claim?.is_direct_billing === true} displayAddress={false} key={med.id} mp={med} />,
                    value: med.id,
                  })) ?? []
                }
                placeholder="Nhập bệnh viện"
                showSearch
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Chọn ngày xảy ra sự kiện" labelCol={{ span: 6 }} name="eventDate">
              <DatePicker format={FORMATTER.DATE_FORMAT} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" loading={loading} type="primary">
                Register
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default RegisterForm;
