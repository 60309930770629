import { PercentageOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber } from "antd";
import If from "app/common/components/If";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useEffect, useState } from "react";

type BenefitFormValues = {
  copay_percent: number;
};
const CopayPercent = () => {
  const {
    claim,
    copayAndBonus,
    permissions: { canAssess },
  } = useClaim();
  const [form] = Form.useForm<BenefitFormValues>();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    form.resetFields();
  }, [form, claim?.claim_case_payment?.co_payment_ratio]);

  useEffect(() => {
    copayAndBonus.registerAfterSaveCopayFn(() => {
      setDisabled(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (claim == null) {
    return null;
  }

  if (!canAssess) {
    return (
      <Form form={form}>
        <div>Phần trăm Copay: {claim.claim_case_payment?.co_payment_ratio ?? 0}%</div>
      </Form>
    );
  }

  return (
    <Form
      form={form}
      initialValues={{
        copay_percent: claim.claim_case_payment?.co_payment_ratio ?? 0,
      }}
      layout="inline"
      onFinish={(values) => {
        copayAndBonus.saveCopay(values.copay_percent);
      }}
    >
      <Form.Item label="Phần trăm Copay" name="copay_percent">
        <InputNumber disabled={disabled} max={100} min={0} placeholder="Phần trăm Copay" prefix={<PercentageOutlined />} step={1} wheel="false" />
      </Form.Item>
      <If
        condition={!disabled}
        else={
          <Button
            onClick={() => {
              setDisabled(false);
            }}
          >
            Thay đổi
          </Button>
        }
      >
        <Form.Item>
          <Button htmlType="submit">Lưu</Button>
        </Form.Item>
      </If>
    </Form>
  );
};
export default CopayPercent;
