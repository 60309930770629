import type { SpinProps, TableColumnsType, TableProps } from "antd";
import type { ColumnType, FilterDropdownProps, GetRowKey, TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import type { ChangeEvent, JSX } from "react";

import { ExportOutlined, PlusOutlined, PrinterOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Space, Table, Tooltip, message } from "antd";
import { TABLE_CONFIG } from "config/constants";
import { utils } from "libs/utils";
import { useCallback, useEffect, useState } from "react";

import styles from "./TableList.module.css";
import calculateColumnsWidth from "./utils";

type HTMLInputChangeEvent = ChangeEvent<HTMLInputElement>;

export interface ITableListProps<RecordType extends object = any> extends Omit<TableProps<RecordType>, "schema" | "title"> {
  action?: {
    onClick: () => void;
    title: JSX.Element | string;
  };
  actionMore?: JSX.Element;
  className?: string;
  columns?: TableColumnsType<RecordType>;
  create?: {
    onCreate: () => void;
    title?: string;
  };
  data?: TableProps<RecordType>["dataSource"];
  dataSource?: TableProps<RecordType>["dataSource"];
  emptyDescription?: string;
  hideWhenEmpty?: boolean;
  importButtonComponent?: JSX.Element;
  loading?: SpinProps | boolean;
  nativeScroll?: {
    scrollToFirstRowOnChange?: boolean;
  } & TableProps<RecordType>["scroll"];
  /**
   * @deprecated use action: {}
   */
  onAction?: () => void;
  /**
   * @deprecated use action: {}
   */
  onActionTitle?: string;
  onExport?: (data: TableProps<RecordType>["dataSource"]) => void;
  onImport?: (e: HTMLInputChangeEvent) => void;
  onPrint?: (data: TableProps<RecordType>["dataSource"]) => void;
  onPrints?: Array<{ label: string; print?: (data: TableProps<RecordType>["dataSource"]) => void }>;
  pagination?: TablePaginationConfig | false;
  percent?: number;
  rowKey?: GetRowKey<RecordType> | string;
  rowSelection?: TableRowSelection<RecordType>;
  schema?: TableColumnsType<RecordType>;
  scroll?: {
    scrollToFirstRowOnChange?: boolean;
  } & TableProps<RecordType>["scroll"];
  search?: string[];
  title?: JSX.Element | string;
  visible?: boolean;
}
let progress;
let tempPercent;

const TableList = <RecordType extends object = any>({
  action,
  actionMore,
  className,
  columns,
  create,
  data,
  dataSource,
  hideWhenEmpty = false,
  importButtonComponent,
  loading = false,
  nativeScroll,
  onAction: oldOnAction,
  onActionTitle: oldOnActionTitle,
  onExport,
  onImport,
  onPrint,
  onPrints,
  pagination,
  percent,
  rowKey,
  rowSelection,
  schema,
  scroll,
  search,
  title,
  visible = true,
  ...rest
}: ITableListProps<RecordType>) => {
  let searchInput;

  const finalColumns = columns ?? schema;

  const onActionTitle = action?.title ?? oldOnActionTitle;
  const onAction = action?.onClick ?? oldOnAction;

  const [uploadDone, setUploadDone] = useState<boolean>(false);
  const [progressPercent, setPercent] = useState<number>(percent ?? 0);
  const [, setSearchText] = useState<string>("");
  const [, setSearchedColumn] = useState<string>("");

  const dataOrDataSource = data ?? dataSource;

  useEffect(() => {
    if (percent != null) {
      setPercent(percent);
      tempPercent = percent;
    }
  }, [percent]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText("");
  }, []);

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({ clearFilters, confirm, selectedKeys, setSelectedKeys }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            placeholder={`Tìm kiếm ${dataIndex}`}
            ref={(node) => {
              searchInput = node;
            }}
            style={{
              display: "block",
              marginBottom: 8,
            }}
            value={selectedKeys[0]}
          />
          <Space>
            <Button icon={<SearchOutlined />} onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size="small" style={{ width: 90 }} type="primary">
              Tìm kiếm
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered === true ? "#1890ff" : undefined }} />,
      onFilter: (value: boolean | number | string, record: RecordType) =>
        record[dataIndex] == null ? "" : record[dataIndex].toString().toLowerCase().includes(String(value).toLowerCase()),
      onFilterDropdownOpenChange: (_visible) => {
        if (_visible === true) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
    }),
    [],
  );

  const handleUpload = useCallback(
    (e: HTMLInputChangeEvent) => {
      onImport?.(e);
      message
        .loading({
          content: "importing...",
          key: "upload",
        })
        .then();
      tempPercent = percent ?? progressPercent;
      progress = setInterval(async () => {
        if (tempPercent >= 100) {
          message.destroy("upload");
          e.target.value = "";
          setUploadDone(true);
          await utils.sleep(2000);
          setPercent(0);
          await utils.sleep(600);
          setUploadDone(false);
          clearInterval(progress);
        } else {
          tempPercent += 20;
          setPercent(tempPercent);
        }
      }, 200);
    },
    [onImport, percent, progressPercent],
  );

  if (!visible || (hideWhenEmpty && (dataOrDataSource?.length === 0 || dataOrDataSource == null))) return null;

  const showTitleSection =
    onAction != null || importButtonComponent != null || onExport != null || onPrint != null || (onPrints?.length ?? 0) > 0 || create != null || actionMore != null;

  const renderTitle = () => (
    <div className={styles.titleHeader}>
      <Space align="start" className={styles.actionBar} direction="horizontal" size={12} wrap>
        <span className={styles.titleHeader}>{title}</span>
        {showTitleSection && (
          <Space>
            {onAction != null && (
              <Button className={styles.createButton} onClick={() => onAction()} size="middle" type="primary">
                {onActionTitle ?? ""}
              </Button>
            )}
            {importButtonComponent == null && onImport != null ? (
              <Tooltip title={`Import ${title}`}>
                <Button
                  className={styles.createButton}
                  disabled={progressPercent !== 0}
                  icon={<UploadOutlined />}
                  onClick={() => {
                    document.querySelector(`#import_${title?.replaceAll(" ", "_")}`)?.click();
                  }}
                  size="middle"
                  type="primary"
                >
                  Import
                </Button>
                <input accept=".xlsx" hidden id={`import_${title?.replaceAll(" ", "_")}`} onChange={handleUpload} type="file" />
              </Tooltip>
            ) : (
              importButtonComponent
            )}
            {onExport != null && (
              <Tooltip title={`Export ${title}`}>
                <Button className={styles.createButton} disabled={progressPercent !== 0} icon={<ExportOutlined />} onClick={() => onExport(data)} size="middle" type="primary">
                  Export
                </Button>
              </Tooltip>
            )}
            {onPrint != null && (
              <Tooltip title={`In chứng từ ${title}`}>
                <Button className={styles.createButton} disabled={progressPercent !== 0} icon={<PrinterOutlined />} onClick={() => onPrint(data)} size="middle" type="primary">
                  Print
                </Button>
              </Tooltip>
            )}
            {onPrints?.map(
              ({ label, print }) =>
                print != null && (
                  <Tooltip key={label} title={`In chứng từ ${title}`}>
                    <Button className={styles.createButton} disabled={progressPercent !== 0} icon={<PrinterOutlined />} onClick={() => print(data)} size="middle" type="primary">
                      {label}
                    </Button>
                  </Tooltip>
                ),
            )}
            {create != null && (
              <Tooltip title={`Thêm mới ${title}`}>
                <Button className={styles.createButton} icon={<PlusOutlined />} onClick={create.onCreate} size="middle" type="primary">
                  {create.title ?? ""}
                </Button>
              </Tooltip>
            )}
            {actionMore != null && <span>{actionMore}</span>}
          </Space>
        )}
      </Space>
    </div>
  );

  const paginationConfig = { ...TABLE_CONFIG.PAGINATION, ...pagination };

  let _scroll:
    | ({
        scrollToFirstRowOnChange?: boolean;
      } & TableProps<RecordType>["scroll"])
    | undefined;
  if (nativeScroll == null) {
    const dataTable = scroll ? calculateColumnsWidth(finalColumns, data, Number(scroll.x)) : undefined;
    _scroll = dataTable ? { x: dataTable.tableWidth, y: scroll?.y } : undefined;
  } else {
    _scroll = nativeScroll;
  }

  return (
    <div className={[styles.tableListContainer, className].join(" ")}>
      <div className={[onImport ? styles.progress : "", uploadDone ? styles.done : ""].join(" ")} style={{ width: `${progressPercent < 100 ? progressPercent : 99.2}%` }} />
      <Table<RecordType>
        {...rest}
        className={styles.container}
        columns={
          search
            ? finalColumns?.map((s: ColumnType<RecordType>) => {
                if (s.dataIndex != null) {
                  return {
                    ...s,
                    ...(search.includes(String(s.dataIndex)) ? getColumnSearchProps(s.dataIndex) : {}),
                  };
                }
                return s;
              })
            : finalColumns
        }
        dataSource={dataOrDataSource}
        loading={typeof loading === "boolean" ? { delay: 500, spinning: loading } : loading}
        locale={{
          emptyText: <Empty description="Không có dữ liệu" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
        pagination={paginationConfig}
        rowKey={rowKey ?? "id"}
        rowSelection={rowSelection}
        scroll={_scroll}
        title={title == null ? undefined : renderTitle}
      />
    </div>
  );
};

export default TableList;
