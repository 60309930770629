import type { FormInstance } from "antd";
import type { DateItem } from "config/common";

import { Form } from "antd";
import DisabledContext from "antd/es/config-provider/DisabledContext";
import DatePicker from "app/common/components/CustomAntdTimePickers/DatePicker";
import { FORMATTER } from "config/constants";
import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { AlertTriangle } from "lucide-react";
import { useContext } from "react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import styles from "../../DetailForm.module.css";

type Props = {
  claimDate: DateItem;
  disabledDate?: (date: Date) => boolean;
  form: FormInstance<ClaimCaseDetailForm>;
  validRangeDate?: [string, string];
} & ClaimCaseDetailFormItem;

const ClaimDateField = ({ claimDate, disabledDate, name, validRangeDate }: Props) => {
  const disabled = useContext(DisabledContext);

  return (
    <Form.Item
      label={claimDate.label}
      name={name}
      rules={[
        () => ({
          message: (
            <>
              <AlertTriangle size={14} /> Không nằm trong hiệu lực hợp đồng
            </>
          ),
          validator: (_, value) => {
            if (validRangeDate != null) {
              const start = startOfDay(new Date(validRangeDate[0]));
              const end = endOfDay(new Date(validRangeDate[1]));
              if (isBefore(value, start) || isAfter(value, end)) {
                return Promise.reject();
              }
            }

            return Promise.resolve();
          },
          warningOnly: true,
        }),
      ]}
      validateTrigger="onBlur"
    >
      <DatePicker className={styles.datePicker} disabled={disabled} disabledDate={disabledDate} format={FORMATTER.DATE_FORMAT} placeholder={claimDate.label} size="large" />
    </Form.Item>
  );
};
export default ClaimDateField;
