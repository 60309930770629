/**
 * benefit filter rule: https://papayainsurtech.slack.com/archives/C067Z8QEDMZ/p1714012291410989
 */

import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useCallback } from "react";

const CONFIG = [
  {
    id: "514764c8-4792-4064-abb8-2bf24d56ecf0",
    policy_number: "1201-0800-060402-24-000074",
  },
  {
    id: "523b93eb-e73c-432e-9592-18e5222b9e76",
    policy_number: "1201-0800-060402-24-000076",
  },
  {
    id: "be58216f-c41a-4d58-9b8b-756cc109104f",
    policy_number: "1201-0800-060402-24-000077",
  },
  {
    id: "f4c80e81-d176-4623-b921-2c93da8764a1",
    policy_number: "1201-0800-060402-24-000078",
  },
  {
    id: "1dc1806e-f7b5-4487-b29b-4a1cf2a2ae86",
    policy_number: "1201-0800-060402-24-000079",
  },
  {
    id: "ec7d5b81-2448-4579-8f93-e40aea667dd1",
    policy_number: "1201-0800-060402-24-000080",
  },
  {
    id: "78edc0d2-098c-4134-af4b-cc316cba6dfd",
    policy_number: "1201-0800-060402-24-000081",
  },
  {
    id: "cf23cb40-7153-4b31-b523-a6759ed6de2e",
    policy_number: "1201-0800-060402-24-000082",
  },
  {
    id: "f24fd473-48ef-4935-8f6a-5369a387a99c",
    policy_number: "1201-0800-060402-24-000083",
  },
  {
    id: "63b1cd62-f5e0-4427-999f-45633efe00e7",
    policy_number: "1201-0800-060402-24-000084",
  },
  {
    id: "cf1bc2e4-77c7-4f16-9480-e5e92b7f1095",
    policy_number: "1201-0800-060402-24-000085",
  },
  {
    id: "73e1b92c-dfbb-4180-8de0-f1d000b33821",
    policy_number: "1201-0800-060402-24-000086",
  },
  {
    id: "66c30d63-9317-4274-9ecc-611206170d44",
    policy_number: "1201-0800-060402-24-000087",
  },
  {
    id: "12b225a1-8f01-41e9-854f-1ab69a86fc0d",
    policy_number: "1201-0800-060402-24-000096",
  },
  {
    id: "d496d7a3-45a1-4564-b32b-558ed78d869f",
    policy_number: "1201-0800-060402-24-000097",
  },
  {
    id: "672fcffb-adf3-47ce-94b2-e5b6a5a5468e",
    policy_number: "1201-0800-060402-24-000098",
  },
  {
    id: "a17292c8-e98a-4969-a7ee-0701c98ffcd1",
    policy_number: "1201-0800-060402-24-000099",
  },
  {
    id: "12f7320a-1695-4333-8468-64b738e51ce7",
    policy_number: "1201-0800-060402-24-000100",
  },
  {
    id: "d3813bdf-daba-4847-aa9d-d163c604f47f",
    policy_number: "1201-0800-060402-24-000109",
  },
];

const MP = {
  id: "d14993f1-c24f-49b9-a613-f341e6452f58",
  name: "BỆNH VIỆN UNG BƯỚU HƯNG VIỆT",
};

const useIsQsrVig = () => {
  const { claim } = useClaim();

  return useCallback(
    ({ code }: { code: string }) => {
      if (
        claim?.insured_certificate.policy.policy_id != null &&
        CONFIG.some((i) => i.id === claim.insured_certificate.policy.policy_id) === true &&
        claim.medical_provider?.id === MP.id &&
        claim.benefit_type === "OutPatient"
      ) {
        return code.startsWith("QSR_VIG_");
      }
      return code.startsWith("QSR_VIG_") === false;
    },
    [claim],
  );
};

export default useIsQsrVig;
