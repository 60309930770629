import type { FC, ReactNode } from "react";

import { Layout } from "antd";
import { NotificationProvider } from "contexts/NotificationContext";
import styles from "layouts/PortalLayout.module.less";

import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import { LayoutProvider } from "./LayoutContext";

interface MainLayoutProps {
  children: ReactNode;
}

const { Content } = Layout;

const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <NotificationProvider>
    <LayoutProvider>
      <Layout className={styles.mainLayout} hasSider>
        <SideMenu />
        <Layout>
          <Header />
          <Content className={styles.contentContainer}>{children}</Content>
        </Layout>
      </Layout>
    </LayoutProvider>
  </NotificationProvider>
);

export default MainLayout;
