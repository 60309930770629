import type { VariablesOf } from "sdk/v2/graphql";

import { Button, Form, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePMutation from "contexts/usePMutation";
import { isEmpty } from "lodash";
import { graphql } from "sdk/v2/graphql";

import ClaimCaseStatusSelect from "../../ClaimCaseStatusSelect";

interface Props {
  resetDeclineInfoForm: () => void;
}

const UpdateClaimCaseDeclineHistoryDocument = graphql(`
  mutation UpdateClaimCaseDeclineHistory($id: uuid!, $data: claim_case_decline_histories_set_input!) {
    update_claim_case_decline_histories_by_pk(_set: $data, pk_columns: { id: $id }) {
      id
    }
  }
`);

const DeclineReverseForm = (props: Props) => {
  const { resetDeclineInfoForm } = props;

  const {
    claim,
    status: { saveStatus },
  } = useClaim();

  const declineInfo = claim?.claim_case_decline_histories[0];

  const [declineReverseForm] = Form.useForm();

  const [updateClaimCaseDeclineHistory] = usePMutation(UpdateClaimCaseDeclineHistoryDocument);

  const onDeclineReverseFormFinish = async (values) => {
    const claimCaseStatus = values.claim_case_statuses ?? "Declined";
    const deletedReason = values.deleted_reason;
    if (claim?.status === claimCaseStatus) {
      notification.error({ message: "Vui lòng đổi trạng thái" });
      return;
    }

    if (isEmpty(deletedReason)) {
      notification.error({ message: "Vui lòng nêu lý do xóa" });
      return;
    }

    if (claim?.status == null || !["Declined", "InsurerAssessed"].includes(claim.status)) {
      notification.error({ message: "Trạng thái không cho phép xóa lý do từ chối" });
      return;
    }
    const claimCaseDeclineHistory: VariablesOf<typeof UpdateClaimCaseDeclineHistoryDocument>["data"] = {
      deleted_at: "now()",
      deleted_reason: deletedReason,
    };
    await saveStatus({
      variables: {
        claimId: claim.id,
        input: { status: claimCaseStatus },
      },
    });
    if (declineInfo?.id == null) return;
    updateClaimCaseDeclineHistory({
      onCompleted: () => {
        resetDeclineInfoForm();
      },
      refetchQueries: ["ClaimDetail"],
      variables: {
        data: claimCaseDeclineHistory,
        id: declineInfo.id,
      },
    });
  };

  return (
    <Form form={declineReverseForm} onFinish={onDeclineReverseFormFinish}>
      <Form.Item label="Trạng thái" labelCol={{ span: 24 }} name="claim_case_statuses" required wrapperCol={{ span: 24 }}>
        <ClaimCaseStatusSelect defaultSelected={false} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label="Mô tả lý do xóa" labelCol={{ span: 24 }} name="deleted_reason" required wrapperCol={{ span: 24 }}>
        <TextArea autoSize={{ minRows: 5 }} />
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button htmlType="submit" type="primary">
          Lưu lại
        </Button>
      </div>
    </Form>
  );
};

export default DeclineReverseForm;
