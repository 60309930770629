import type { CredentialResponse } from "@react-oauth/google";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GoogleLogin } from "@react-oauth/google";
import { Button, Flex, Form, Input, message, Tooltip } from "antd";
import Spin from "app/common/components/Spin";
import { loginRequest } from "config/auth/azureAuthConfig";
import { useAuth } from "contexts/AuthContext";
import { OAUTH_TYPES } from "contexts/types";
import useIP from "hooks/useIP";
import { jwtDecode } from "jwt-decode";
import { Lock, User } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./LoginScreen.module.css";

interface LocationState {
  state: { from: Location };
}

const onGoogleLoginFailed = () => {
  message.error("Đăng nhập không thành công");
};

const LoginScreen = () => {
  const { oauthSignIn, signIn, signInLoading } = useAuth();
  const location = useLocation() as LocationState;
  const [generalError, setGeneralError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginModes, setLoginModes] = useState<("email" | "oauth")[]>(["email", "oauth"]);
  const navigate = useNavigate();
  const { checkUserInWhitelistToUseOAuth, ip } = useIP();
  const isLocalOrPapayaHostname = window.location.hostname.includes("localhost") || window.location.hostname.includes("papaya.asia");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const from = location.state?.from.pathname ?? "/";

  const loginCallback = ({ isLoggedIn }) => {
    if (isLoggedIn === true) navigate(from, { replace: true });
    setGeneralError("Sai tên đăng nhập hoặc mật khẩu");
    setLoading(false);
  };

  const doLogin = async (values) => {
    setLoading(true);
    signIn({
      callback: loginCallback,
      email: values.email,
      password: values.password,
    });
  };

  const onGoogleLoginSuccess = (credentialResponse: CredentialResponse) => {
    setLoading(true);
    if (credentialResponse.credential == null || credentialResponse.select_by == null) {
      onGoogleLoginFailed();
      return;
    }
    const { email, picture } = jwtDecode(credentialResponse.credential) as Record<string, string | undefined>;
    oauthSignIn({
      callback: loginCallback,
      extras: {
        clientId: credentialResponse.clientId,
        email,
        picture,
        select_by: credentialResponse.select_by,
      },
      token: credentialResponse.credential,
      type: OAUTH_TYPES.GOOGLE,
    });
  };

  useEffect(() => {
    if (ip !== null && checkUserInWhitelistToUseOAuth(ip) && isLocalOrPapayaHostname) {
      // setLoginModes(["oauth"]);
    }
  }, [ip, checkUserInWhitelistToUseOAuth, isLocalOrPapayaHostname]);

  // ms
  const { accounts, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated && accounts[0] != null) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          oauthSignIn({
            callback: loginCallback,
            extras: {},
            token: response.idToken,
            type: OAUTH_TYPES.MICROSOFT,
          });
        });
    }
  }, [accounts, instance, isAuthenticated, loginCallback, oauthSignIn]);

  return (
    <Spin spinning={loading || signInLoading}>
      <div className={styles.container}>
        <div className={styles.loginPart}>
          <div className={styles.logo} />
          <div className={styles.loginForm}>
            <h1>Đăng nhập</h1>
            <span style={{ color: "red" }}>{generalError}</span>

            {loginModes.includes("email") && (
              <Form name="loginForm" onFinish={doLogin}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      message: "Email là trường bắt buộc",
                      required: true,
                    },
                  ]}
                  validateStatus={generalError == null ? "error" : undefined}
                >
                  <Input autoComplete="current-password" placeholder="Email" prefix={<User size={16} />} size="large" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      message: "Mật khẩu là trường bắt buộc",
                      required: true,
                    },
                  ]}
                  validateStatus={generalError == null ? "error" : undefined}
                >
                  <Input.Password autoComplete="current-password" placeholder="Mật khẩu" prefix={<Lock size={16} />} size="large" />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" loading={loading} size="large" style={{ width: "100%" }} type="primary">
                    Đăng nhập
                  </Button>
                </Form.Item>
              </Form>
            )}

            {loginModes.includes("oauth") && (
              <Flex align="center" gap="small" justify="center" vertical>
                <Tooltip title="Đăng nhập bằng tài khoản Microsoft doanh nghiệp">
                  <div
                    style={{
                      marginTop: 15,
                    }}
                  >
                    <button
                      className={styles.bskBtn}
                      onClick={() => {
                        instance.loginPopup(loginRequest).catch(console.error);
                      }}
                      type="button"
                    >
                      <img
                        alt="Đăng nhập bằng tài khoản Microsoft doanh nghiệp"
                        src="/images/ms-pictogram.svg"
                        style={{
                          height: "1em",
                          position: "relative",
                          top: ".125em",
                          width: "1em",
                        }}
                      />
                      <span style={{ marginLeft: 5 }}>Đăng nhập bằng Microsoft</span>
                    </button>
                  </div>
                </Tooltip>
                <GoogleLogin
                  cancel_on_tap_outside={false}
                  containerProps={{ allow: "identity-credentials-get" }}
                  onError={onGoogleLoginFailed}
                  onSuccess={onGoogleLoginSuccess}
                  use_fedcm_for_prompt
                  useOneTap
                />
              </Flex>
            )}
          </div>
        </div>
        <div className={styles.testimonial}>
          <div />
        </div>
      </div>
    </Spin>
  );
};

export default LoginScreen;
