import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import Icon from "@ant-design/icons";

const InstantPayoutSVG = (props: Partial<CustomIconComponentProps>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M14.2222 2C12.7684 2 11.3702 2.31737 10.0662 2.94362C9.45568 3.23787 9.19253 3.98125 9.47951 4.60625C9.76661 5.2325 10.4947 5.49975 11.1058 5.20687C12.0815 4.738 13.13 4.5 14.2222 4.5C18.2661 4.5 21.5556 7.86425 21.5556 12C21.5556 16.1357 18.2661 19.5 14.2222 19.5C13.3575 19.5 12.5118 19.3486 11.708 19.0508C11.0748 18.8151 10.3723 19.1496 10.142 19.7979C9.91157 20.4473 10.2392 21.1639 10.8736 21.3995C11.9455 21.7986 13.0722 22 14.2222 22C19.6136 22 24 17.5139 24 12C24 6.48613 19.6136 2 14.2222 2ZM3.22222 10.75C2.54731 10.75 2 11.3091 2 12C2 12.6909 2.54731 13.25 3.22222 13.25H8.11111C8.78602 13.25 9.33333 12.6909 9.33333 12C9.33333 11.3091 8.78602 10.75 8.11111 10.75H3.22222Z"
      fill={props.fill}
    />
    <path
      d="M11 7.2C11 6.53676 10.4527 6 9.77778 6H6.11111C5.4362 6 4.88889 6.53676 4.88889 7.2C4.88889 7.86324 5.4362 8.4 6.11111 8.4H9.77778C10.4527 8.4 11 7.86324 11 7.2ZM1.22222 18H9.77778C10.4527 18 11 17.4632 11 16.8C11 16.1368 10.4527 15.6 9.77778 15.6H1.22222C0.547311 15.6 0 16.1368 0 16.8C0 17.4632 0.547311 18 1.22222 18Z"
      fill={props.fill}
    />
    <path
      clipRule="evenodd"
      d="M15.417 8.66667C15.417 8.43655 15.2305 8.25 15.0003 8.25C14.7702 8.25 14.5837 8.43655 14.5837 8.66667V9.08333C13.6632 9.08333 12.917 9.82952 12.917 10.75C12.917 11.6705 13.6632 12.4167 14.5837 12.4167V14.0833C14.2215 14.0833 13.9122 13.852 13.7976 13.5278C13.721 13.3109 13.4829 13.1971 13.2659 13.2738C13.049 13.3505 12.9352 13.5886 13.0119 13.8055C13.2405 14.4523 13.8573 14.9167 14.5837 14.9167V15.3333C14.5837 15.5635 14.7702 15.75 15.0003 15.75C15.2305 15.75 15.417 15.5635 15.417 15.3333V14.9167C16.3375 14.9167 17.0837 14.1705 17.0837 13.25C17.0837 12.3295 16.3375 11.5833 15.417 11.5833V9.91667C15.7792 9.91667 16.0884 10.148 16.203 10.4722C16.2797 10.6891 16.5177 10.8029 16.7347 10.7262C16.9517 10.6495 17.0654 10.4114 16.9887 10.1945C16.7601 9.54771 16.1433 9.08333 15.417 9.08333V8.66667ZM14.5837 9.91667C14.1234 9.91667 13.7503 10.2898 13.7503 10.75C13.7503 11.2102 14.1234 11.5833 14.5837 11.5833V9.91667ZM15.417 12.4167V14.0833C15.8772 14.0833 16.2503 13.7102 16.2503 13.25C16.2503 12.7898 15.8772 12.4167 15.417 12.4167Z"
      fill={props.fill}
      fillRule="evenodd"
      stroke={props.fill}
      strokeWidth="0.5"
    />
  </svg>
);

const InstantPayoutIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={() => <InstantPayoutSVG {...props} />} />;
export default InstantPayoutIcon;
