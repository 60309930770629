import { Tag } from "antd";
import { InsuredBenefitTypesEnum } from "sdk/gql/types";

const BenefitTag = (props: { className?: string; isSmall?: boolean; onClick?: () => void; style?: any; text: string | undefined; value?: InsuredBenefitTypesEnum | string }) => {
  const { className, isSmall, onClick, style, text, value } = props;
  let color = "";
  switch (value) {
    case InsuredBenefitTypesEnum.Life: {
      color = "gold";
      break;
    }
    case InsuredBenefitTypesEnum.InPatient: {
      color = "volcano";
      break;
    }
    case InsuredBenefitTypesEnum.OutPatient: {
      color = "cyan";
      break;
    }
    case "Accident": {
      color = "red";
      break;
    }
    case InsuredBenefitTypesEnum.Others: {
      color = "default";
      break;
    }
    case InsuredBenefitTypesEnum.Maternity: {
      color = "pink";
      break;
    }
    case InsuredBenefitTypesEnum.Dental: {
      color = "purple";
      break;
    }
    default: {
      color = "default";
    }
  }
  return (
    <Tag className={[isSmall === true ? "" : "", className ?? ""].join(" ")} color={color} onClick={onClick} style={style}>
      {text}
    </Tag>
  );
};

export default BenefitTag;
