import { Form, Select } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvTreatmentMethodsField = ({ name }: Props) => (
  <Form.Item
    label="Loại phương thức điều trị"
    name={name}
    rules={[
      {
        message: "Không được để trống",
        required: true,
      },
    ]}
  >
    <Select mode="multiple">
      <Select.Option value="WES">Western</Select.Option>
      <Select.Option value="TRA">Traditional</Select.Option>
      <Select.Option value="SURGR">Surgery</Select.Option>
      <Select.Option value="UNK">Unknown</Select.Option>
    </Select>
  </Form.Item>
);
export default SlvTreatmentMethodsField;
