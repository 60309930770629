import type { FC } from "react";

import { Badge, Button, Popover, Segmented, Space, Tooltip } from "antd";
import { papaya } from "config/colors";
import { useAuth } from "contexts/AuthContext";
import { useNotificationContext } from "contexts/NotificationContext";
import usePMutation from "contexts/usePMutation";
import { Bell, Mail, MailCheck, Menu } from "lucide-react";
import { useState } from "react";
import { graphql } from "sdk/v2/graphql";

import FailedCorrespondences from "./FailedCorrespondences";
import All from "./all";

const CONTENT_MAPPING = {
  all: <All />,
  failedCorrespondence: <FailedCorrespondences />,
};

const UPDATE_NOTIFICATIONS_AS_SEEN_V2_MUTATION = graphql(`
  mutation UpdateNotificationsAsSeenV2($where: notification_notifications_bool_exp!, $input: notification_notifications_set_input!) {
    update_notification_notifications(where: $where, _set: $input) {
      affected_rows
    }
  }
`);

const NotificationButton: FC = () => {
  const [selectedNotificationType, setSelectedNotificationType] = useState<"all" | "failedCorrespondence">("failedCorrespondence");
  const { failedCorrespondenceHistoriesAggregate, notifications } = useNotificationContext();

  const [markAllReadMutation] = usePMutation(UPDATE_NOTIFICATIONS_AS_SEEN_V2_MUTATION, { refetchQueries: ["GetFailedCorrespondenceHistories"] });
  const { user } = useAuth();

  const hasNotification = notifications.some((i) => i.is_read === false);

  return (
    <Popover
      content={
        <div
          style={{
            maxHeight: "70vh",
            maxWidth: 500,
            overflowY: "auto",
            width: 400,
          }}
        >
          {(() => {
            if (selectedNotificationType === "all") return <All />;
            return <FailedCorrespondences />;
          })()}
        </div>
      }
      placement="bottom"
      title={
        <Space>
          <Segmented
            onChange={(value) => setSelectedNotificationType(value as keyof typeof CONTENT_MAPPING)}
            options={[
              {
                icon: <Mail size={14} />,
                label: (
                  <Badge count={failedCorrespondenceHistoriesAggregate?.aggregate?.count} overflowCount={9} size="small">
                    Lỗi gửi thư
                  </Badge>
                ),
                value: "failedCorrespondence",
              },
              {
                icon: <Menu size={14} />,
                label: (
                  <Badge count={notifications.filter((i) => i.is_read === false).filter(({ reason }) => reason != null).length} overflowCount={9} size="small">
                    Thông báo khác
                  </Badge>
                ),
                value: "all",
              },
            ]}
            value={selectedNotificationType}
          />
          <Tooltip title="Đánh dấu tất cả đã đọc">
            <Button
              icon={<MailCheck size={14} />}
              onClick={() => {
                markAllReadMutation({
                  variables: {
                    input: {
                      first_seen_at: "now()",
                    },
                    where: {
                      receiver_id: { _eq: user.id },
                    },
                  },
                });
              }}
              type="dashed"
            />
          </Tooltip>
        </Space>
      }
      trigger="click"
    >
      <Badge count={notifications.filter((i) => i.is_read === false).length} offset={[4, 4]}>
        <Button icon={<Bell color={hasNotification === true ? papaya : undefined} size={14} />}>Cần kiểm tra</Button>
      </Badge>
    </Popover>
  );
};

export default NotificationButton;
