import { Checkbox, Form } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvHospitalStampField = ({ name }: Props) => (
  <Form.Item label="Có dấu mộc trên Giấy ra viện" name={name} valuePropName="checked">
    <Checkbox>Có dấu mộc trên Giấy ra viện</Checkbox>
  </Form.Item>
);
export default SlvHospitalStampField;
