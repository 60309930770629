import type { Gutter } from "antd/lib/grid/row";
import type { DateItem } from "config/common";
import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";

import { Button, Col, Divider, Form, notification, Row, Space, Typography } from "antd";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import Spin from "app/common/components/Spin";
import ClaimAlert from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimAlert";
import ClaimInsuredBenefitDetailDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimInsuredBenefitDetailDocument";
import UpdateClaimCaseDetailDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/graphql/UpdateClaimCaseDetailDocument";
import UpdateSlvClaimCaseDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/graphql/UpdateSlvClaimCaseDocument";
import UpsertClaimCaseAssessedDiagnosisDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/graphql/UpsertClaimCaseAssessedDiagnosisDocument";
import UpsertMbalExtendedDataDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/graphql/UpsertMbalExtendedDataDocument";
import UpsertPtiClaimCaseDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/graphql/UpsertPtiClaimCaseDocument";
import { CLAIM_ALERT_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/claimAlertQuery";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useNotice from "app/portal/screens/ClaimPortal/ClaimContext/hooks/useNotice";
import useAlert from "app/portal/screens/ClaimPortal/ClaimContext/useAlert";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { ADMISSION_DATE, DateItems, DISCHARGE_DATE, EVENT_DATE, FwdInsuranceEventDatesBasedOnBenefitType, PHYSICAL_EXAMINATION_DATE } from "config/common";
import usePMutation from "contexts/usePMutation";
import { endOfDay, isAfter } from "date-fns";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import utils from "libs/utils";
import { clone, difference, pick } from "lodash";
import { CircleCheck, Info } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { InsuredBenefitTypesEnum } from "sdk/gql/types";

import type { ClaimCaseDetailForm, KeyOfClaimCaseDetailForm } from "./schema";

import ClaimCasesByDateRange from "./components/ClaimCasesByDateRange";
import AssessedDiseasesField from "./components/formFields/AssessedDiseasesField";
import ClaimDateField from "./components/formFields/ClaimDateField";
import ClaimDateRangeField from "./components/formFields/ClaimDateRangeField";
import DiagnosisField from "./components/formFields/DiagnosisField";
import DiseaseGroupIdField from "./components/formFields/DiseaseGroupIdField";
import DoctorField from "./components/formFields/DoctorField";
import InputDiseasesField from "./components/formFields/InputDiseasesField";
import InsuranceEventField from "./components/formFields/InsuranceEventField";
import MedicalProviderIdField from "./components/formFields/MedicalProviderIdField";
import PtiBenefitTypeField from "./components/formFields/PtiBenefitTypeField";
import PtiDiseaseGroupField from "./components/formFields/PtiDiseaseGroupField";
import RelatedToAccidentField from "./components/formFields/RelatedToAccidentField";
import RequestAmountField from "./components/formFields/RequestAmount";
import SlvHasSurgeryDocField from "./components/formFields/slv/HasSurgeryDocField";
import SlvHospitalDepartmentField from "./components/formFields/slv/HospitalDepartmentField";
import SlvHospitalDepartmentTypeField from "./components/formFields/slv/HospitalDepartmentTypeField";
import SlvHospitalStampField from "./components/formFields/slv/HospitalStampField";
import SlvMrnField from "./components/formFields/slv/MrnField";
import SlvPatientIdField from "./components/formFields/slv/PatientIdField";
import SlvTreatmentMethodsField from "./components/formFields/slv/TreatmentMethodsField";
import TreatmentMethodField from "./components/formFields/TreatmentMethodField";
import WaitingRemarksField from "./components/formFields/WaitingRemarksField";
import showModalInvalidDateRange from "./components/showModalInvalidDateRange";
import styles from "./DetailForm.module.css";

const ROW_GUTTER: [Gutter, Gutter] = [18, 12];

type Props = {
  readonlyItem: boolean;
};

const DetailForm = (props: Props) => {
  const { readonlyItem } = props;
  const {
    claim,
    claimType,
    isDetailFormFilled,
    loading: claimLoading,
    permissions: { canUpdateClaimInput },
  } = useClaim();
  const { notice } = useNotice({ showSuggestion: false });
  const [upsertMbalExtendedClaimCase, { loading: upsertMbalLoading }] = usePMutation(UpsertMbalExtendedDataDocument);
  const [updateSlvClaimCase, { loading: upsertSlvLoading }] = usePMutation(UpdateSlvClaimCaseDocument);
  const [upsertPtiClaimCase, { loading: upsertPtiLoading }] = usePMutation(UpsertPtiClaimCaseDocument);
  const [updateClaimCaseMutation, { loading: updatingClaim }] = usePMutation(UpdateClaimCaseDetailDocument, {
    onCompleted: () => notification.success({ message: "Cập nhật thông tin thành công" }),
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, ClaimInsuredBenefitDetailDocument]),
  });
  const [upsertAssessedDiagnosisMutation, { loading: upsertAssessedDiagnosisLoading }] = usePMutation(UpsertClaimCaseAssessedDiagnosisDocument);

  const [form] = Form.useForm<ClaimCaseDetailForm>();

  const { sameStartDateClaimCasesByInsuredPerson } = useAlert();

  const [showDuplicatedClaimCaseList, setShowDuplicatedClaimCaseList] = useState(false);
  const displayAccidentField = claimType.slvHs;
  const displaySlvFields = claimType.slvHs;

  const initialValues: ClaimCaseDetailForm | null | undefined = useMemo(() => {
    if (claim == null) {
      return null;
    }
    const slvClaimGroup = claim.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group;

    return {
      admissionDate: claim.admission_date == null ? undefined : new Date(claim.admission_date),
      assessedDiseases:
        claim.claim_case_assessed_diagnoses.length > 0
          ? claim.claim_case_assessed_diagnoses.map((item) => ({
              key: item.icd.id,
              label: `${item.icd.value} - ${item.icd.title}`,
              title: item.icd.title,
              value: item.icd.value,
            }))
          : claim.claim_case_input_diagnoses.map((item) => ({
              key: item.icd.id,
              label: `${item.icd.value} - ${item.icd.title}`,
              title: item.icd.value,
              value: item.icd.value,
            })),
      benefitType: claim.benefit_type,
      dateRange: claim.admission_date != null && claim.discharge_date != null ? [new Date(claim.admission_date), new Date(claim.discharge_date)] : null,
      diagnosis: claim.diagnosis ?? claim.claim_case_input_diagnoses.map((item) => item.icd.title).join("; "),
      dischargeDate: claim.discharge_date == null ? undefined : new Date(claim.discharge_date),
      diseaseGroupId: (() => {
        if (claimType.mbalHs) return claim.mbal_claim_case?.disease_group_id;
        if (claimType.slvHs) return claim.slv_claim_case?.disease_group_id;
        return null;
      })(),
      doctorName: claim.doctor_name,
      eventDate: claim.event_date == null ? undefined : new Date(claim.event_date),
      inputDiseases: claim.claim_case_input_diagnoses.map((item) => ({
        key: item.icd.id,
        label: `${item.icd.value} - ${item.icd.title}`,
        title: item.icd.value,
        value: item.icd.value,
      })),
      insuranceEvent: claim.insurance_event,
      medicalProviderId: claim.medical_provider_id,
      physicalExaminationDate: claim.physical_examination_date == null ? undefined : new Date(claim.physical_examination_date),
      ptiBenefitType: claim.pti_claim_case?.benefit_type,
      ptiDiseaseGroup: claim.pti_claim_case?.disease_group,
      relatedToAccident: claimType.slvHs ? claim.slv_claim_case?.related_to_accident : false,
      requestAmount: claim.request_amount,
      // slv
      slvHasSurgeryDoc: slvClaimGroup?.has_surgery_doc ?? false,
      slvHospitalDepartment: slvClaimGroup?.hospital_department,
      slvHospitalDepartmentType: slvClaimGroup?.hospital_department_type,
      slvHospitalStamp: slvClaimGroup?.hospital_stamp ?? false,
      slvMrn: slvClaimGroup?.mrn,
      slvPatientId: slvClaimGroup?.patient_id,
      slvTreatmentMethods: slvClaimGroup?.treatment_methods ?? [],
      status: claim.status as ClaimCaseStatusesEnum,
      treatmentMethod: claim.treatment_method,
      waitingRemarks: claim.waiting_remarks,
    };
  }, [claim, claimType]);

  const fwdClaimDates = useMemo(() => {
    if (claim?.benefit_type == null || FwdInsuranceEventDatesBasedOnBenefitType[claim.benefit_type] == null) return null;

    return Object.values(FwdInsuranceEventDatesBasedOnBenefitType[claim.benefit_type]) as [DateItem, DateItem] | [DateItem];
  }, [claim]);

  const validateDateFields = useCallback(
    async (formValues: ClaimCaseDetailForm): Promise<boolean> => {
      const dateWarningFields: KeyOfClaimCaseDetailForm[] = ["physicalExaminationDate", "admissionDate", "dischargeDate", "eventDate", "dateRange"];
      const dateWarnings = form.getFieldsError(dateWarningFields).filter((err) => err.warnings.length > 0);
      if (dateWarnings.length === 0) return true;

      const dateWarningsName = dateWarnings.flatMap((w) => w.name);
      const warningValues = pick(formValues, dateWarningsName);
      const dateItems = DateItems.filter((item) => dateWarningsName.includes(item.key)).map((item) => ({
        ...item,
        value: warningValues[item.key],
      }));
      if (dateWarningsName.includes("dateRange") && warningValues.dateRange != null) {
        const [admissionDate, dischargeDate] = warningValues.dateRange;
        dateItems.push(
          { key: "admissionDate", key_snake_case: "admission_date", label: "Ngày vào viện", value: admissionDate },
          { key: "dischargeDate", key_snake_case: "discharge_date", label: "Ngày ra viện", value: dischargeDate },
        );
      }

      return showModalInvalidDateRange({
        dateItems,
        dued_at: claim?.insured_certificate.dued_at,
        issued_at: claim?.insured_certificate.issued_at,
      });
    },
    [claim?.insured_certificate.dued_at, claim?.insured_certificate.issued_at, form],
  );

  const onFinish = useCallback(
    async (formValues: ClaimCaseDetailForm) => {
      if (claim == null) return;

      const shouldContinueSaveForm = await validateDateFields(formValues);
      if (!shouldContinueSaveForm) return;

      const values = clone(formValues);
      const isFirstAssessment = claim.medical_provider_id == null || claim.claim_case_assessed_diagnoses.length === 0;
      const { claim_case_assessed_diagnoses, claim_case_id } = claim;
      const removedDiseases = difference(
        claim_case_assessed_diagnoses.map((disease) => disease.icd.id),
        (values.assessedDiseases ?? []).flatMap((disease) => (disease.key == null ? [] : [disease.key])),
      );
      const updatingDiseases = values.assessedDiseases?.flatMap((disease, idx) => (disease.key == null ? [] : [{ claim_case_id, icd_metadata_id: disease.key, order: idx }])) ?? [];
      if (values.dateRange != null) {
        const [admissionDate, dischargeDate] = values.dateRange;
        values.admissionDate = admissionDate;
        values.dischargeDate = dischargeDate;
      }

      await upsertAssessedDiagnosisMutation({
        variables: {
          input: [
            ...removedDiseases.map((icdId) => ({
              claim_case_id,
              deleted_at: "now()",
              icd_metadata_id: icdId,
              order: null,
            })),
            ...updatingDiseases,
          ],
        },
      });

      const res = await updateClaimCaseMutation({
        variables: {
          id: claim.id,
          input: {
            admission_date: values.admissionDate == null ? null : values.admissionDate.toISOString(),
            assessment_started_at: isFirstAssessment ? "now()" : undefined,
            diagnosis: values.diagnosis,
            discharge_date: values.dischargeDate == null ? null : values.dischargeDate.toISOString(),
            doctor_name: values.doctorName,
            event_date: values.eventDate == null ? null : values.eventDate.toISOString(),
            insurance_event: values.insuranceEvent,
            medical_provider_id: values.medicalProviderId,
            physical_examination_date: values.physicalExaminationDate == null ? null : values.physicalExaminationDate.toISOString(),
            request_amount: values.requestAmount,
            treatment_method: values.treatmentMethod,
            waiting_remarks: values.waitingRemarks,
          },
        },
      });
      if (res.data?.update_claim_cases_by_pk != null) {
        if (claimType.PTI) {
          await upsertPtiClaimCase({
            refetchQueries: ["getPTIExtendedData"],
            variables: {
              input: {
                benefit_type: values.ptiBenefitType,
                claim_case_id,
                disease_group: values.ptiDiseaseGroup,
              },
            },
          });
        } else if (claimType.mbalHs) {
          await upsertMbalExtendedClaimCase({
            refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_ALERT_QUERY]),
            variables: {
              claimId: claim.id,
              mbalDiseaseGroupId: values.diseaseGroupId ?? "",
            },
          });
        } else if (claimType.slvHs && values.slvTreatmentMethods != null) {
          await updateSlvClaimCase({
            refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_ALERT_QUERY]),
            variables: {
              claimId: claim.id,
              slvClaimGroupInput: {
                hasSurgeryDoc: values.slvHasSurgeryDoc,
                hospitalDepartment: values.slvHospitalDepartment,
                hospitalDepartmentType: values.slvHospitalDepartmentType,
                hospitalStamp: values.slvHospitalStamp,
                mrn: values.slvMrn,
                patientId: values.slvPatientId,
                treatmentMethods: values.slvTreatmentMethods,
              },
              slvClaimInput: {
                diseaseGroupId: values.diseaseGroupId ?? "",
                relatedToAccident: values.relatedToAccident ?? false,
              },
            },
          });
        }

        notification.success({ message: "Cập nhật thông tin thành công" });
      }
    },
    [
      claim,
      claimType.PTI,
      claimType.mbalHs,
      claimType.slvHs,
      updateClaimCaseMutation,
      upsertAssessedDiagnosisMutation,
      upsertMbalExtendedClaimCase,
      upsertPtiClaimCase,
      validateDateFields,
      updateSlvClaimCase,
    ],
  );

  if (claim == null || initialValues == null) return null;

  return (
    <Content title="Thông tin điều trị">
      <Form disabled={!canUpdateClaimInput} form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Spin spinning={upsertSlvLoading || upsertMbalLoading || updatingClaim || claimLoading || upsertAssessedDiagnosisLoading}>
          <Row className={styles.infoInputContainer}>
            <Space className={styles.fullWidth} direction="vertical" size={[0, 0]}>
              {/* field medical provider id */}
              <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                <Col span={24}>
                  <MedicalProviderIdField form={form} name="medicalProviderId" />
                </Col>
              </Row>

              {/* field date */}
              <Row align="bottom" className={styles.fullWidth} gutter={ROW_GUTTER}>
                <If condition={!claimType.fwdMr}>
                  <If condition={["Accident", InsuredBenefitTypesEnum.InPatient, InsuredBenefitTypesEnum.Maternity].includes(claim.benefit_type)}>
                    <Col span={16}>
                      <ClaimDateRangeField
                        claimDates={[ADMISSION_DATE, DISCHARGE_DATE]}
                        disabledDate={(date) => isAfter(date, endOfDay(new Date())) && claim.is_direct_billing === false}
                        form={form}
                        name="dateRange"
                        required={claim.benefit_type !== "Accident"}
                        validRangeDate={[claim.insured_certificate.issued_at, claim.insured_certificate.dued_at]}
                      />
                    </Col>
                  </If>
                  <If condition={["Accident", InsuredBenefitTypesEnum.Dental, InsuredBenefitTypesEnum.OutPatient].includes(claim.benefit_type)}>
                    <Col span={8}>
                      <ClaimDateField
                        claimDate={PHYSICAL_EXAMINATION_DATE}
                        disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
                        form={form}
                        name={PHYSICAL_EXAMINATION_DATE.key}
                        validRangeDate={[claim.insured_certificate.issued_at, claim.insured_certificate.dued_at]}
                      />
                    </Col>
                  </If>
                  <If condition={["Accident", InsuredBenefitTypesEnum.Life].includes(claim.benefit_type)}>
                    <Col span={8}>
                      <ClaimDateField
                        claimDate={EVENT_DATE}
                        disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
                        form={form}
                        name={EVENT_DATE.key}
                        validRangeDate={[claim.insured_certificate.issued_at, claim.insured_certificate.dued_at]}
                      />
                    </Col>
                  </If>
                </If>

                <If condition={claimType.fwdMr}>
                  <Col span={8}>
                    {fwdClaimDates?.[0] !== undefined && fwdClaimDates.length === 1 ? (
                      <ClaimDateField
                        claimDate={fwdClaimDates[0]}
                        disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
                        form={form}
                        name={fwdClaimDates[0].key}
                        validRangeDate={[claim.insured_certificate.issued_at, claim.insured_certificate.dued_at]}
                      />
                    ) : null}
                    {fwdClaimDates != null && fwdClaimDates.length === 2 ? <ClaimDateRangeField claimDates={fwdClaimDates} form={form} name="dateRange" /> : null}
                  </Col>
                </If>

                <If condition={claim.benefit_type === InsuredBenefitTypesEnum.Life}>
                  <Col span={8}>
                    <InsuranceEventField form={form} name="insuranceEvent" />
                  </Col>
                </If>

                <Col>
                  <Button
                    aria-valuenow={sameStartDateClaimCasesByInsuredPerson.length}
                    className={styles.duplicatedClaimCaseWarningButton}
                    icon={sameStartDateClaimCasesByInsuredPerson.length > 0 ? <Info color="#DA8300" size={14} /> : <CircleCheck color="#20CC69" size={14} />}
                    onClick={() => {
                      setShowDuplicatedClaimCaseList((oldStatus) => !oldStatus);
                    }}
                    size="middle"
                  >
                    {`Có ${sameStartDateClaimCasesByInsuredPerson.length} claim case trùng ngày`}
                    {/* <If condition={claimType.fwdMr}>{` ${fwdClaimDates[0]?.label}`}</If> */}
                  </Button>
                </Col>
              </Row>

              {/* table: same start date claim cases */}
              <If condition={sameStartDateClaimCasesByInsuredPerson.length > 0 && showDuplicatedClaimCaseList === true}>
                <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                  <Col span={24}>
                    <ClaimCasesByDateRange claimCases={sameStartDateClaimCasesByInsuredPerson} />
                  </Col>
                </Row>
              </If>

              {/* field diagnosis, treatmentMethod */}
              <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                <Col span={8}>
                  <DiagnosisField form={form} name="diagnosis" />
                </Col>
                <Col span={8}>
                  <TreatmentMethodField form={form} name="treatmentMethod" />
                </Col>
                <Col span={8}>
                  <RequestAmountField name="requestAmount" />
                </Col>
              </Row>

              {/* field input diseases, assessed diseases, diseaseGroupId */}
              <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                <Col span={8}>
                  <InputDiseasesField form={form} name="inputDiseases" readonlyItem={readonlyItem} />
                </Col>
                <Col span={8}>
                  <AssessedDiseasesField form={form} name="assessedDiseases" readonlyItem={readonlyItem} />
                </Col>
                <If condition={claimType.mbalHs || claimType.slvHs}>
                  <Col span={8}>
                    <DiseaseGroupIdField form={form} name="diseaseGroupId" />
                  </Col>
                </If>
                <Col span={8}>
                  <DoctorField form={form} name="doctorName" />
                </Col>
                <If condition={claimType.healthCare}>
                  <Col span={24}>
                    <WaitingRemarksField name="waitingRemarks" />
                  </Col>
                </If>
                <If condition={displayAccidentField}>
                  <Col span={8}>
                    <RelatedToAccidentField name="relatedToAccident" />
                  </Col>
                </If>
              </Row>

              {/* slv fields */}
              <If condition={displaySlvFields}>
                <Divider />
                <Typography.Title level={5}>SLV HS</Typography.Title>
                <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                  <Col span={8}>
                    <SlvHospitalDepartmentField name="slvHospitalDepartment" />
                  </Col>
                  <Col span={8}>
                    <SlvHospitalDepartmentTypeField name="slvHospitalDepartmentType" />
                  </Col>
                  <Col span={8}>
                    <SlvTreatmentMethodsField name="slvTreatmentMethods" />
                  </Col>
                  <Col span={8}>
                    <SlvMrnField name="slvMrn" />
                  </Col>
                  <Col span={8}>
                    <SlvPatientIdField name="slvPatientId" />
                  </Col>
                  <Col span={8}>
                    <SlvHospitalStampField name="slvHospitalStamp" />
                  </Col>
                  <Col span={8}>
                    <SlvHasSurgeryDocField name="slvHasSurgeryDoc" />
                  </Col>
                </Row>
              </If>

              {/* request_amount */}
              <Row className={[styles.fullWidth, styles.bottomTreatmentInfo].join(" ")} justify="space-between">
                <Col className={styles.totalAmount} span={24}>
                  Số tiền yêu cầu: {utils.formatCurrency(claim.request_amount)}
                </Col>
              </Row>

              {/* field ptiDiseaseGroup, ptiBenefitType */}
              <If condition={claimType.PTI}>
                <Divider />
                <Typography.Title level={5}>Thông tin dành cho công ty PTI</Typography.Title>
                <Row className={styles.fullWidth} gutter={ROW_GUTTER}>
                  <Col span={8}>
                    <PtiDiseaseGroupField name="ptiDiseaseGroup" />
                  </Col>

                  <Col span={8}>
                    <PtiBenefitTypeField name="ptiBenefitType" />
                  </Col>
                </Row>
              </If>

              {/* save button */}
              <If condition={canUpdateClaimInput}>
                <Space direction="vertical">
                  <ClaimAlert />
                  <Space className={styles.saveBtn}>
                    {notice}
                    <If condition={!isDetailFormFilled && !claimType.healthCare}>
                      <span style={{ color: "yellowgreen" }}>Nhập bệnh viện, thời gian, ICD, thời gian chờ để tiến hành thẩm định</span>
                    </If>
                    <If condition={!isDetailFormFilled && claimType.healthCare}>
                      <span style={{ color: "yellowgreen" }}>Nhập bệnh viện, thời gian, phương thức điều trị, ICD, thời gian chờ để tiến hành thẩm định</span>
                    </If>
                    <Button htmlType="submit" loading={updatingClaim || upsertPtiLoading || upsertMbalLoading} type="primary">
                      Lưu
                    </Button>
                  </Space>
                </Space>
              </If>
            </Space>
          </Row>
        </Spin>
      </Form>
    </Content>
  );
};

export default DetailForm;
