import type { DocumentNode, LazyQueryHookOptions, OperationVariables, TypedDocumentNode } from "@apollo/client";
import type { IdentityRolesEnum } from "sdk/gql/graphql";

import { useLazyQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

type PLazyQueryHookOptions<TData, TVariables extends OperationVariables = OperationVariables> = {
  role?: IdentityRolesEnum;
} & LazyQueryHookOptions<TData, TVariables>;

export function usePLazyQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: PLazyQueryHookOptions<TData, TVariables>,
) {
  const { selectedRole } = useAuth();
  // eslint-disable-next-line deprecate/function
  return useLazyQuery(query, {
    ...options,
    context: { headers: { "x-hasura-role": options?.role ?? selectedRole } },
  });
}

export default usePLazyQuery;
