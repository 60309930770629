import type { BaseFormProps } from "app/common/components/Form";
import type { InsuredPersonRemarksOnGetClaimDetailFragment, PlanRemarkOnGetClaimDetailFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { FC } from "react";
import type { InsuredBenefitTypesEnum } from "sdk/gql/graphql";

import { Button, Col, Descriptions, Modal, notification, Row, Space } from "antd";
import Content from "app/common/components/Content";
import BaseForm from "app/common/components/Form";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import { remarkFormSchema } from "app/portal/screens/AdminPortal/InsuredPersonScreen/schema";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER } from "config/constants";
import usePMutation from "contexts/usePMutation";
import useInsuredBenefitTypes from "hooks/useInsuredBenefitTypes";
import { utils } from "libs/utils";
import { isEmpty } from "lodash";
import { Check, Square } from "lucide-react";
import { graphql } from "sdk/v2/graphql";

import styles from "./InsuredInfo.module.css";
import { lifeInsuranceInfo, useCommonClaimInsuranceInfo } from "./schema";

export interface ICertificate {
  insuredPersonName: string;
  insuredPersonParentName: string;
  insurer: string;
}

const InsertInsuredPersonRemarkDocument = graphql(`
  mutation InsertInsuredPersonRemark($input: insured_person_remarks_insert_input!) {
    insert_insured_person_remarks_one(object: $input) {
      id
    }
  }
`);

export interface InsuredInfoData {
  duedAt: string;
  insuredPersonId?: string;
  insuredPersonName: string;
  insuredPersonParentId?: string;
  insuredPersonParentName: string;
  insuredPersonRemarks?: InsuredPersonRemarksOnGetClaimDetailFragment[];
  insurer: string;
  insurerId: string;
  issuedAt: string;
  planRemarks?: PlanRemarkOnGetClaimDetailFragment[];
}

const InsuredInfo: FC<{ original?: boolean }> = ({ original = false }) => {
  const { claim, claimDateRanges, gracePeriodFrom, isClaimPeriodFullyEffective, isLifeInsuranceRider, loading, refetchQuery } = useClaim();
  const benefitTypeOptions = useInsuredBenefitTypes();
  const nonLifeInsuranceInfo = useCommonClaimInsuranceInfo();

  const laInfo = {
    duedAt: claim?.insured_certificate.dued_at ?? "",
    insuredPersonId: claim?.insured_certificate.insured_person_id,
    insuredPersonName: claim?.insured_certificate.insured_person.name ?? "",
    insuredPersonParentId: claim?.insured_certificate.parent_insured_certificate?.insured_person.insured_person_id ?? "",
    insuredPersonParentName: claim?.insured_certificate.parent_insured_certificate?.insured_person.name ?? "",
    insuredPersonRemarks: claim?.insured_certificate.insured_person.insured_person_remarks,
    insurer: claim?.insured_certificate.policy.insurer.name ?? "",
    insurerId: claim?.insured_certificate.policy.insurer.company_id ?? "",
    issuedAt: claim?.insured_certificate.issued_at ?? "",
    planRemarks: claimDateRanges?.claim_date_ranges.ranges[0]?.plan?.plan_remarks,
  };

  const [insertInsuredPersonRemarkMutation, { loading: insertInsuredPersonRemarkMutationLoading }] = usePMutation(InsertInsuredPersonRemarkDocument, {
    refetchQueries: [refetchQuery],
  });

  const initialValues: {
    description: string;
    insured_benefit_type: InsuredBenefitTypesEnum | null;
  } = {
    description: "",
    insured_benefit_type: null,
  };

  const onRemarkFinish: BaseFormProps<typeof initialValues>["onFinish"] = (values) => {
    const insuredPersonId = claim?.insured_certificate.insured_person.insured_person_id;
    insertInsuredPersonRemarkMutation({
      onCompleted: () => {
        Modal.destroyAll();
        notification.success({ message: "Thêm remark thành công" });
      },
      variables: {
        input: { ...values, insured_person_id: insuredPersonId },
      },
    });
  };

  const addRemark = () => {
    Modal.info({
      content: (
        <BaseForm
          initialValues={initialValues}
          loading={insertInsuredPersonRemarkMutationLoading}
          onCancel={() => {
            Modal.destroyAll();
          }}
          onFinish={onRemarkFinish}
          option={{
            insured_benefit_type: benefitTypeOptions,
          }}
          schemas={remarkFormSchema}
          title="Nhập thông tin remark"
        />
      ),
      maskClosable: true,
      onCancel: () => {},
      title: "Thông tin Remark",
      width: 900,
    });
  };

  return (
    <Content>
      <Descriptions
        bordered
        column={{ lg: 2, md: 2, sm: 2, xl: 4, xs: 2, xxl: 4 }}
        items={(isLifeInsuranceRider ? lifeInsuranceInfo : nonLifeInsuranceInfo).map((item) => ({
          children: item.render(original ? claim?.original_claim_case : claim, claimDateRanges),
          key: item.label,
          label: item.label,
          span: item.span,
        }))}
        labelStyle={{ fontWeight: "bold" }}
        title="Thông tin hợp đồng bảo hiểm"
      />
      <Row className={styles.box}>
        <If condition={isLifeInsuranceRider}>
          <Col span={24}>
            <Row gutter={[0, 8]}>
              <Col span={24}>Thông tin lưu ý</Col>
              <Col span={24}>
                <Space size={20}>
                  <If condition={isClaimPeriodFullyEffective === false} else={<Square />}>
                    <Check color="blue" />
                  </If>
                  HĐ mất hiệu lực
                  <If condition={gracePeriodFrom != null} else={<Square />}>
                    <Check color="blue" />
                  </If>
                  Trong thời gian ân hạn <If condition={gracePeriodFrom != null}>(bắt đầu ân hạn từ {utils.formatDate(gracePeriodFrom, "dd/MM/yyyy")})</If>
                </Space>
              </Col>
            </Row>
          </Col>
        </If>

        <If
          condition={!isEmpty(laInfo.insuredPersonRemarks)}
          else={
            <Col>
              <Space direction="vertical">
                <Button onClick={() => addRemark()} type="primary">
                  Thêm Remark cho NĐBH
                </Button>
              </Space>
            </Col>
          }
        >
          <TableList
            create={{
              onCreate: () => {
                addRemark();
              },
              title: "Thêm Remark",
            }}
            data={laInfo.insuredPersonRemarks}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 5,
            }}
            schema={[
              {
                dataIndex: "description",
                render: (text) => <div className={styles.displayLinebreak}>{text}</div>,
                title: "Chi tiết",
              },
              {
                dataIndex: ["insured_person_remark_benefit_type", "comment"],
                title: "Quyền lợi áp dụng",
                width: 200,
              },
              {
                align: "left",
                dataIndex: "created_at",
                render: (text) => utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
                title: "Ngày tạo",
                width: 200,
              },
            ]}
            title="Lưu ý cá nhân"
          />
        </If>
      </Row>
    </Content>
  );
};

export default InsuredInfo;
