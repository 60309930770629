import { Affix, Button, Card, Flex, List, Modal, notification, Popover, Space, Tooltip, Typography } from "antd";
import If from "app/common/components/If";
import PendingCodeTag from "app/common/components/PendingCodeTag";
import PendingCodeTagAssessed from "app/common/components/PendingCodeTagAssessed";
import RibbonedClaimDocument from "app/common/components/RibbonedClaimDocument";
import { UPDATE_PENDING_CODE_MUTATION } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/PendingFormV2/graphql/mutations";
import { CLAIM_PENDING_CODES } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/PendingFormV2/graphql/queries";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER } from "config/constants";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import buildFileThumbnail from "libs/buildFileThumbnail";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import { isEqualZero } from "libs/hidash";
import utils from "libs/utils";
import { uniq } from "lodash";
import { Minus, Plus, View } from "lucide-react";
import ProgressiveImage from "react-progressive-graceful-image";
import { graphql } from "sdk/v2/graphql";

const ClaimDocumentsDocument = graphql(`
  query ClaimDocuments($where: claim_documents_bool_exp!) {
    claim_documents(where: $where) {
      id
      is_supplemented
      created_at
      assessed_claim_pending_codes {
        id
        code
        extended_text
      }
      claim_document_type {
        comment
      }
      claim_pending_code {
        id
        code
        extended_text
      }
      file {
        id
        mime_type
        url
      }
    }
  }
`);

const AddClaimDocumentToPendingCodeDocument = graphql(`
  mutation AddClaimDocumentToPendingCode($claimDocumentId: uuid!, $object: claim_documents_set_input!) {
    update_claim_documents_by_pk(pk_columns: { claim_document_id: $claimDocumentId }, _set: $object) {
      id
      assessed_claim_pending_codes {
        id
        code
        extended_text
      }
    }
  }
`);

const PendingAssessment = ({ pendingCodeId }: { pendingCodeId?: string }) => {
  const { claim } = useClaim();
  const { data } = usePQuery(
    ClaimDocumentsDocument,
    claim?.id == null
      ? { skip: true }
      : {
          variables: {
            where: {
              claim_case_id: {
                _eq: claim.id,
              },
              type: {
                _nin: ["CLAIM_PENDING_SAMPLE_FILE"],
              },
            },
          },
        },
  );
  const { data: pendingCodeData } = usePQuery(
    CLAIM_PENDING_CODES,
    pendingCodeId == null
      ? { skip: true }
      : {
          variables: {
            where: {
              id: {
                _eq: pendingCodeId,
              },
            },
          },
        },
  );
  const pendingCode = pendingCodeData?.claim_pending_codes[0];

  const [assessClaimDocument] = usePMutation(AddClaimDocumentToPendingCodeDocument, {
    refetchQueries: getRefetchOperationNames([CLAIM_PENDING_CODES]),
  });
  const [updatePendingCodeMutation, { loading: updatingPendingCode }] = usePMutation(UPDATE_PENDING_CODE_MUTATION, {
    onCompleted: () => {
      notification.success({ message: "Thành công" });
      Modal.destroyAll();
    },
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY, CLAIM_PENDING_CODES]),
  });

  const claimDocuments = [...(data?.claim_documents ?? [])].sort((a, b) => {
    if (a.is_supplemented === true) return -1;
    return 0;
  });

  return (
    <Space direction="vertical">
      <Typography.Text>
        Để đánh dấu Pending <Typography.Text mark>Đã Nộp</Typography.Text>, vui lòng xác định chừng từ (ít nhất 1) bằng cách{" "}
        <Button ghost icon={<Plus size={16} />} type="primary">
          Chọn
        </Button>{" "}
        chứng từ tương ứng.
      </Typography.Text>
      <Typography.Text type="secondary">
        Khách hàng hay claim admin có thể tải chứng từ sai vào claim case, TĐV cần xác nhận lại. Cách làm này đảm bảo thông tin Pending Code và chứng từ liên quan được thể hiện rõ
        ràng.
      </Typography.Text>
      <Typography.Text>
        <Space>
          <PendingCodeTag>PXX</PendingCodeTag> là KH chọn
        </Space>
        <Space>
          <PendingCodeTagAssessed>PYY</PendingCodeTagAssessed> là đã thẩm định
        </Space>
      </Typography.Text>
      <Space>
        <PendingCodeTag>{pendingCode?.code}</PendingCodeTag>
        <Typography.Text strong>{pendingCode?.extended_text}</Typography.Text>
      </Space>
      <List
        dataSource={claimDocuments}
        grid={{
          gutter: 24,
        }}
        renderItem={(doc) => {
          const { fullImg, image } = buildFileThumbnail({ fullSize: 600, mime_type: doc.file?.mime_type, size: 200, url: doc.file?.url });
          return (
            <List.Item key={doc.id}>
              <Card
                cover={
                  <RibbonedClaimDocument claimDocument={doc}>
                    <div className="text-center">
                      <img src={image} style={{ border: "1px solid #cad1d8", height: 190, objectFit: "cover" }} width={200} />
                    </div>
                  </RibbonedClaimDocument>
                }
                style={{ width: 220 }}
              >
                <Card.Meta
                  description={
                    <Flex align="center" gap="small" vertical>
                      <Popover
                        title={
                          <ProgressiveImage placeholder="/images/placeholder-image-w400.jpeg" src={`${fullImg}`}>
                            {(src, loading) => <img className={`image${loading === true ? " loading" : " loaded"}`} src={src} width="600" />}
                          </ProgressiveImage>
                        }
                      >
                        <div>
                          <View color="#1677ff" size={20} />
                        </div>
                      </Popover>
                      <Tooltip>
                        <div>Tải lúc: {utils.formatDate(doc.created_at, FORMATTER.DATE_TIME_FORMAT)}</div>
                      </Tooltip>
                      <If
                        condition={doc.assessed_claim_pending_codes?.some((x) => x.id === pendingCode?.id) === true}
                        else={
                          <Button
                            ghost
                            icon={<Plus size={16} />}
                            onClick={async () =>
                              assessClaimDocument({
                                variables: {
                                  claimDocumentId: doc.id,
                                  object: {
                                    assessed_claim_pending_code_ids: uniq([pendingCode?.id, ...(doc.assessed_claim_pending_codes?.map((x) => x.id) ?? []), pendingCode?.id]).filter(
                                      Boolean,
                                    ),
                                  },
                                },
                              })
                            }
                            type="primary"
                          >
                            Chọn
                          </Button>
                        }
                      >
                        <Button
                          danger
                          ghost
                          icon={<Minus size={16} />}
                          onClick={async () =>
                            assessClaimDocument({
                              variables: {
                                claimDocumentId: doc.id,
                                object: {
                                  assessed_claim_pending_code_ids: doc.assessed_claim_pending_codes?.map((x) => x.id).filter((x) => x !== pendingCode?.id),
                                },
                              },
                            })
                          }
                          type="primary"
                        >
                          Bỏ ra
                        </Button>
                      </If>
                    </Flex>
                  }
                  title={
                    <Tooltip title={import.meta.env.DEV && <div>{doc.id}</div>}>
                      <div className="text-center w-full">{doc.claim_document_type.comment}</div>
                    </Tooltip>
                  }
                />
              </Card>
            </List.Item>
          );
        }}
      />
      <Affix className="absolute left-[50%]" offsetBottom={25}>
        <Button
          disabled={isEqualZero(pendingCode?.assessed_claim_documents)}
          onClick={() => {
            if (pendingCode?.id == null) return;
            updatePendingCodeMutation({
              variables: {
                id: pendingCode.id,
                input: {
                  removed_at: "now()",
                  status: "R",
                },
              },
            });
          }}
          type="primary"
        >
          Xác nhận đã nộp <PendingCodeTag>{pendingCode?.code}</PendingCodeTag>
        </Button>
      </Affix>
    </Space>
  );
};

export default PendingAssessment;
