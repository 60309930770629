import type {
  QueryHookOptions as ApolloQueryHookOptions,
  DocumentNode,
  OperationVariables,
  QueryResult,
  TypedDocumentNode} from "@apollo/client";

import {
  useQuery as useApolloQuery,
} from "@apollo/client";
import { useMemo } from "react";

export type QueryHookOptions<TData = any, TVariables = OperationVariables> = {
  suspense?: boolean;
} & ApolloQueryHookOptions<TData, TVariables>;
const useSuspenseQuery = <TData, TVariables>(
  { data, error, loading, observable, ...res }: QueryResult<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  const suspense = options?.suspense;
  const errorPolicy = options?.errorPolicy || "none";
  const promise = useMemo(
    () =>
      suspense &&
      new Promise((resolve) => {
        const resolver = () => {
          resolve(true);
          subscription.unsubscribe();
        };
        const subscription = observable.subscribe(({ data, loading }) => {
          if (data && !loading) resolver();
        });
      }),
    [observable, suspense],
  );
  const proxy = useMemo(
    () =>
      suspense &&
      new Proxy((data || {}) as any, {
        get: (target, prop) => {
          if (Object.keys(target).length === 0 && loading) {
            throw promise;
          } else if (errorPolicy === "none" && error) {
            throw error;
          }
          return target[prop as keyof typeof target];
        },
      }),
    [data, error, errorPolicy, loading, promise, suspense],
  );
  return { data: suspense ? proxy : data, error, loading, observable, ...res };
};
export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => useSuspenseQuery(useApolloQuery(query, options), options);
