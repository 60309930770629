/* eslint-disable */
import * as Types from '../../../../../../../../../sdk/gql/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WriteAssessmentSummaryMutationVariables = Types.Exact<{
  input: Types.ClaimNotesInsertInput;
}>;


export type WriteAssessmentSummaryMutation = (
  { insert_claim_notes_one: { __typename: 'claim_notes' } | null }
  & { __typename?: 'mutation_root' }
);

export type UpdateAssessmentSummaryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  input: Types.ClaimNotesSetInput;
}>;


export type UpdateAssessmentSummaryMutation = (
  { update_claim_notes_by_pk: { __typename: 'claim_notes' } | null }
  & { __typename?: 'mutation_root' }
);

export type OpenAiTranslateMutationVariables = Types.Exact<{
  input: Types.CreateChatCompletionRequestInput;
}>;


export type OpenAiTranslateMutation = (
  { createChatCompletion: (
    { choices: Array<(
      { message: (
        { content: string }
        & { __typename?: 'ChatCompletionResponseMessage' }
      ) }
      & { __typename?: 'ChoicesListItem' }
    ) | null> }
    & { __typename?: 'CreateChatCompletionResponse' }
  ) | null }
  & { __typename?: 'mutation_root' }
);


export const WriteAssessmentSummaryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"writeAssessmentSummary"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"claim_notes_insert_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"insert_claim_notes_one"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"object"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"on_conflict"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"constraint"},"value":{"kind":"EnumValue","value":"claim_notes_pkey"}},{"kind":"ObjectField","name":{"kind":"Name","value":"update_columns"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"content"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<WriteAssessmentSummaryMutation, WriteAssessmentSummaryMutationVariables>;
export const UpdateAssessmentSummaryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateAssessmentSummary"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"uuid"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"claim_notes_set_input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"update_claim_notes_by_pk"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"pk_columns"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"_set"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]}}]} as unknown as DocumentNode<UpdateAssessmentSummaryMutation, UpdateAssessmentSummaryMutationVariables>;
export const OpenAiTranslateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"openAITranslate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateChatCompletionRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createChatCompletion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"createChatCompletionRequestInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"choices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"content"}}]}}]}}]}}]}}]} as unknown as DocumentNode<OpenAiTranslateMutation, OpenAiTranslateMutationVariables>;