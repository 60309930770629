import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { ClaimDetailQuery } from "sdk/gql/graphql";

import { Col, Divider, Row, Typography } from "antd";
import If from "app/common/components/If";
import { utils } from "libs/utils";
import { min } from "lodash";

import type { AssessmentClaimCaseDetail, PayableBenefitOutput } from "../../types";
import type { CalcPayableAmount } from "../helpers";

const TotalPaidAmountDetail = ({
  calculatedPaidAmounts,
  claim,
  claimCaseDetail,
  claimType,
  paidBenefit,
}: {
  calculatedPaidAmounts: CalcPayableAmount;
  claim?: ClaimDetailQuery["claim_cases_by_pk"];
  claimCaseDetail?: AssessmentClaimCaseDetail;
  claimType: ClaimContextType["claimType"];
  paidBenefit?: PayableBenefitOutput;
}) => {
  const remainingBalance =
    min([paidBenefit?.currencyPerTimeBalanceRemainingBuffer, paidBenefit?.currencyPerYearBalanceRemainingBuffer, paidBenefit?.daysPerYearBalanceRemainingBuffer]) ?? 0;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row>
                <Col flex="auto">Số tiền yêu cầu:</Col>
                <Col>{utils.formatCurrency(claimCaseDetail?.totalRequestAmount ?? 0)}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col flex="auto">Số tiền ngoài phạm vi bảo hiểm:</Col>
                <Col>{utils.formatCurrency(0 - (claimCaseDetail?.nonPaidAmount ?? 0))}</Col>
              </Row>
            </Col>
            <Divider className="my-8" />
            <Col span={24}>
              <Row>
                <Col flex="auto">Số tiền trong phạm vi bảo hiểm:</Col>
                <Col>{utils.formatCurrency(claimCaseDetail?.coverageAmount ?? 0)}</Col>
              </Row>
            </Col>
            <If condition={claimType.fwdMr}>
              <Col span={24}>
                <Row>
                  <Col flex="auto">Số tiền copay: </Col>
                  <Col>{utils.formatCurrency(0 - (claimCaseDetail?.copayAmount ?? 0))}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col flex="auto">Số tiền deduct: </Col>
                  <Col>{utils.formatCurrency(0 - (claimCaseDetail?.deductibleAmount ?? 0))}</Col>
                </Row>
              </Col>
              <Divider className="my-8" />
              <Col span={24}>
                <Row>
                  <Col flex="auto">Số tiền trong phạm vi bảo hiểm sau copay/deduct:</Col>
                  <Col>{utils.formatCurrency(calculatedPaidAmounts.coverageAmountAfterCopay)}</Col>
                </Row>
              </Col>
            </If>
            <If
              condition={claimType.fwdMr}
              else={
                <Col span={24}>
                  <Typography.Text type="secondary">
                    Vì số tiền này {calculatedPaidAmounts.coverageAmount > remainingBalance ? "vượt quá" : "nằm trong"} hạn mức còn lại ({utils.formatCurrency(remainingBalance)}),
                    nên:
                  </Typography.Text>
                </Col>
              }
            >
              <Col span={24}>
                <Typography.Text type="secondary">
                  Vì số tiền này {(calculatedPaidAmounts.coverageAmountAfterCopay ?? 0) > remainingBalance ? "vượt quá" : "nằm trong"} hạn mức còn lại (
                  {utils.formatCurrency(remainingBalance)}), nên:
                </Typography.Text>
              </Col>
            </If>
          </Row>
        </Col>
      </Row>
      <Divider className="my-8" />
      <Row>
        <Col flex="auto">Số tiền chi trả sau hạn mức:</Col>
        <Col>{utils.formatCurrency(calculatedPaidAmounts.expectedPayableAmount)}</Col>
      </Row>
      <If condition={!claimType.fwdMr}>
        <Row>
          <Col flex="auto">Số tiền copay:</Col>
          <Col>{utils.formatCurrency(0 - (claimCaseDetail?.copayAmount ?? 0))}</Col>
        </Row>
        <Divider className="my-8" />
      </If>
      <Row>
        <Col flex="auto">Số tiền thực trả:</Col>
        <Col>{utils.formatCurrency(claimCaseDetail?.totalPaidAmount ?? 0)}</Col>
      </Row>
    </>
  );
};
export default TotalPaidAmountDetail;
