import type { TagProps } from "antd";
import type { FC } from "react";

import { Tag, Tooltip } from "antd";

export type LabelValue = {
  color: string;
  description: null | string;
  id: string;
  name: string;
};

type Props = {
  onClick?: () => void;
  value: LabelValue;
} & TagProps;

const Label: FC<Props> = ({ onClick, value: { color, description, name }, ...props }) => (
  <Tooltip title={description}>
    <Tag
      {...props}
      color={color}
      onClick={onClick}
      style={{
        border: "none",
        borderRadius: 4,
        boxSizing: "border-box",
        cursor: onClick == null ? "default" : "pointer",
        height: "100%",
        padding: "0 4px",
        ...props.style,
      }}
    >
      {name}
    </Tag>
  </Tooltip>
);

export default Label;
