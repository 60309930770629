import { Modal } from "antd";
import { useAuth } from "contexts/AuthContext";
import usePQuery from "contexts/usePQuery";
import { first } from "lodash";
import { useEffect } from "react";
import { graphql } from "sdk/v2/graphql";

const MedicalProviderForHospitalClaimDocument = graphql(`
  query MedicalProviderForHospitalClaim($companyId: uuid!) {
    mp_medical_providers(where: { company_role: { company_id: { _eq: $companyId } } }) {
      id
      name
      medical_provider_id
    }
  }
`);
const useMedicalProviderForHospital = () => {
  const { hasRole, signOut, user } = useAuth();
  const medicalProviderCompanyId = user.roles.find((item) => item.role === "MedicalProviderEmployee" || item.role === "InheritedUserMedicalProviderEmployee")?.company_role
    ?.company_id;
  const { data: medicalProvider, loading: medicalProviderLoading } = usePQuery(
    MedicalProviderForHospitalClaimDocument,
    medicalProviderCompanyId == null
      ? { skip: true }
      : {
          variables: { companyId: medicalProviderCompanyId },
        },
  );

  const medicalProviderData = first(medicalProvider?.mp_medical_providers);
  useEffect(() => {
    // account has medical provider role but not have medical provider data
    if (medicalProviderCompanyId != null && medicalProvider !== undefined && medicalProvider.mp_medical_providers.length === 0) {
      Modal.confirm({
        cancelText: "Đóng",
        closable: true,
        content: "Vui lòng đăng nhập lại hoặc liên hệ với quản trị viên để được hỗ trợ.",
        maskClosable: true,
        okText: "Đăng xuất",
        onOk: signOut,
        title: "Dữ liệu bệnh viện không hợp lệ.",
      });
    }
  }, [medicalProviderCompanyId, medicalProvider, signOut, medicalProviderData?.medical_provider_id, hasRole]);

  return {
    medicalProviderCompanyId,
    medicalProviderData,
    medicalProviderLoading,
  };
};

export default useMedicalProviderForHospital;
