import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { FC } from "react";

import { Descriptions, Space } from "antd";
import Content from "app/common/components/Content";
import LabelDisplay from "app/common/components/LabelDisplay";
import { PORTAL_PATH } from "app/portal/config/paths";
import { TYPE } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import utils from "libs/utils";
import { Link, generatePath } from "react-router-dom";

type PolicyOwner = {
  address?: null | string;
  dob?: null | string;
  email?: null | string;
  gender?: null | string;
  id?: string;
  is_watched: boolean;
  job?: null | string;
  maritalStatus?: null | string;
  name?: null | string;
  occupation_class?: null | string;
  paper_id?: null | string;
  phone?: null | string;
  poNumber?: string;
  tax_code?: null | string;
};

const PolicyOwnerSection: FC<{ claim: ClaimContextType["claim"]; loading: boolean }> = ({ claim, loading }) => {
  const { globalData } = useAuth();
  const maritalStatuses = globalData?.maritalStatuses;

  const poOwner: PolicyOwner = {
    address: claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.insured_person.mbal_insured_person?.address : null,
    dob: claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.policy.insured_person?.dob : null,
    email:
      claim?.insured_certificate.policy.types === "Individual"
        ? claim.insured_certificate.policy.email ?? claim.insured_certificate.policy.insured_person?.email
        : claim?.insured_certificate.policy.corporate_company?.email,
    gender: claim?.insured_certificate.policy.types === "Individual" ? TYPE.GENDER_TYPE[claim.insured_certificate.policy.insured_person?.gender ?? ""] : null,
    id: claim?.insured_certificate.policy.id,
    is_watched: claim?.insured_certificate.policy.watch_by != null,
    job: claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.policy.insured_person?.mbal_insured_person?.job : null,
    maritalStatus:
      claim?.insured_certificate.policy.types === "Individual" && claim.insured_certificate.policy.insured_person?.marital_status != null
        ? maritalStatuses?.[claim.insured_certificate.policy.insured_person.marital_status]
        : null,
    name:
      claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.policy.insured_person?.name : claim?.insured_certificate.policy.corporate_company?.name,
    occupation_class: claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.insured_person.mbal_insured_person?.occupation_class : null,
    paper_id: claim?.insured_certificate.policy.types === "Individual" ? claim.insured_certificate.policy.insured_person?.paper_id : null,
    phone:
      claim?.insured_certificate.policy.types === "Individual"
        ? claim.insured_certificate.policy.phone ?? claim.insured_certificate.policy.insured_person?.phone
        : claim?.insured_certificate.policy.corporate_company?.phone,
    poNumber: claim?.insured_certificate.policy.policy_number,
    tax_code: claim?.insured_certificate.policy.types === "Corporate" ? claim.insured_certificate.policy.corporate_company?.tax_code : null,
  };

  const schema = [
    claim?.insured_certificate.policy.types === "Individual" && {
      description: (
        <Space>
          {claim.insured_certificate.policy.insured_person?.id != null && (
            <Link to={generatePath(PORTAL_PATH.INSURED_PERSON_DETAIL, { id: claim.insured_certificate.policy.insured_person.id })}>{poOwner.name ?? "--"}</Link>
          )}
          <LabelDisplay editable objectId={poOwner.id} />
        </Space>
      ),
      id: 0,
      label: <span>Tên</span>,
    },
    claim?.insured_certificate.policy.types === "Corporate" && {
      description: (
        <Space>
          {claim.insured_certificate.policy.corporate_company?.id != null && (
            <Link to={generatePath(PORTAL_PATH.COMPANY_DETAIL, { companyId: claim.insured_certificate.policy.corporate_company.id })}>{poOwner.name ?? "--"}</Link>
          )}
          <LabelDisplay editable objectId={poOwner.id} />
        </Space>
      ),
      id: 11,
      label: <span>Tên</span>,
    },
    {
      description: poOwner.id != null && <Link to={generatePath(PORTAL_PATH.POLICY_DETAIL, { id: poOwner.id })}>{poOwner.poNumber ?? "--"}</Link>,
      id: 1,
      label: <span>Mã HĐ</span>,
    },
    {
      description: <p>{poOwner.email ?? "--"}</p>,
      id: 2,
      label: <span>Email</span>,
    },
    {
      description: <p>{poOwner.phone ?? "--"}</p>,
      id: 3,
      label: <span>Phone number</span>,
    },
    {
      description: <p>{poOwner.paper_id ?? "--"}</p>,
      id: 4,
      label: <span>CMND/CCCD/HC</span>,
    },
    {
      description: <p>{poOwner.dob == null ? "--" : utils.formatDate(new Date(poOwner.dob), "dd/MM/yyyy")}</p>,
      id: 5,
      label: <span>Date of birth</span>,
    },
    {
      description: <p>{poOwner.gender ?? "--"}</p>,
      id: 6,
      label: <span>Giới tính</span>,
    },
    {
      description: <p>{poOwner.maritalStatus ?? "--"}</p>,
      id: 61,
      label: <span>Tình trạng hôn nhân</span>,
    },
    {
      description: <p>{poOwner.job ?? "--"}</p>,
      id: 7,
      label: <span>Nghề nghiệp</span>,
    },
    {
      description: <p>{poOwner.occupation_class ?? "--"}</p>,
      id: 8,
      label: <span>Phân loại nghề nghiệp</span>,
    },
    {
      description: <p>{poOwner.address ?? "--"}</p>,
      id: 81,
      label: <span>Địa chỉ</span>,
    },
    {
      description: <p>{poOwner.tax_code ?? "--"}</p>,
      id: 9,
      label: <span>Tax Code</span>,
    },
    {
      description: <p>{poOwner.is_watched ? "Có" : "Không"}</p>,
      id: 10,
      label: <span>Hợp đồng bị theo dõi</span>,
    },
  ].filter(Boolean);

  return (
    <Content>
      <Descriptions
        bordered
        items={schema.map((item) => ({
          children: item.description,
          key: item.id,
          label: item.label,
        }))}
        labelStyle={{ fontWeight: "bold" }}
        title="Chủ hợp đồng"
      />
    </Content>
  );
};

export default PolicyOwnerSection;
