import type { FormInstance } from "antd";

import { Form, Select } from "antd";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePQuery from "contexts/usePQuery";
import { useEffect } from "react";

import type { ClaimCaseDetailForm, ClaimCaseDetailFormItem } from "../../schema";

import { GetDiseaseGroupsDocument } from "../../DetailForm.generated";
import styles from "../../DetailForm.module.css";

type Props = {
  form: FormInstance<ClaimCaseDetailForm>;
} & ClaimCaseDetailFormItem;

const DiseaseGroupIdField = ({ form, name }: Props) => {
  const fieldAssessedDiseasesValue = Form.useWatch("assessedDiseases", form);
  const { claim } = useClaim();
  const { data: diseaseGroups } = usePQuery(GetDiseaseGroupsDocument, {
    variables: {
      where: {
        insurer_id: { _eq: claim?.insured_certificate.policy.insurer_company?.company_id },
        plan_balances: {
          disease_group_id: {
            _is_null: false,
          },
        },
      },
    },
  });
  const { data: diseaseGroupsByIcdCode } = usePQuery(GetDiseaseGroupsDocument, {
    skip: fieldAssessedDiseasesValue == null,
    variables: {
      where: {
        disease_group_icds: {
          icd_metadata: {
            value: {
              _eq: fieldAssessedDiseasesValue?.[0]?.value,
            },
          },
        },
        insurer_id: { _eq: claim?.insured_certificate.policy.insurer_company?.company_id },
        plan_balances: {
          disease_group_id: {
            _is_null: false,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (fieldAssessedDiseasesValue != null && fieldAssessedDiseasesValue.length === 0) {
      form.setFieldValue("diseaseGroupId", null);
    }
    if ((fieldAssessedDiseasesValue ?? []).length > 0 && diseaseGroupsByIcdCode?.disease_groups[0]?.id != null && form.getFieldValue("diseaseGroupId") == null) {
      form.setFieldValue("diseaseGroupId", diseaseGroupsByIcdCode.disease_groups[0].id);
    }
  }, [diseaseGroupsByIcdCode, form, name, fieldAssessedDiseasesValue]);

  return (
    <Form.Item
      label={<label>Nhóm bệnh</label>}
      name={name}
      rules={[
        {
          message: "Vui lòng nhập nhóm bệnh",
          required: true,
        },
      ]}
    >
      <Select
        className={styles.autoComplete}
        options={
          diseaseGroups?.disease_groups.map((diseaseGroup) => ({
            key: diseaseGroup.id,
            label: diseaseGroup.name,
            value: diseaseGroup.id,
          })) ?? []
        }
        placeholder="Nhóm bệnh"
        size="large"
      />
    </Form.Item>
  );
};
export default DiseaseGroupIdField;
