import { graphql } from "sdk/v2/graphql";

export const INSERT_CLAIM_NOTE_MUTATION = graphql(`
  mutation InsertClaimNote($input: claim_notes_insert_input!) {
    insert_claim_notes_one(object: $input, on_conflict: { constraint: claim_notes_pkey, update_columns: [content] }) {
      id
    }
  }
`);

export const UPDATE_SIGN_OFF_MUTATION = graphql(`
  mutation UpdateSignOff($object: claim_notes_set_input!, $id: uuid!) {
    update_claim_notes_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`);
