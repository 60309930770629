import { uniq } from "lodash";

const findClaimCodesInText = (text?: null | string) => {
  if (text == null) return [];
  /**
   * HC Code: DB-23-068950, RE-23-068947
   * FWD MR Code: MDR-37678528-3001
   * MBAL HS Code: MBAL-034940
   * SLV HS Code: CL2024010456
   */
  const claimCodeRegex = /((RE|DB|MDR|MBAL|CL)[\d-]+)/gm;
  const claimCodes = text.match(claimCodeRegex);
  return uniq(claimCodes?.map((claimCode) => claimCode.trim()) ?? []);
};

export default findClaimCodesInText;
