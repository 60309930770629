import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";

import InsuredLeadsByDependant from "./byDependant";
import InsuredLeadsByExpiredStatus from "./byExpiredStatus";

const InsuredLeads = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Tabs
      defaultValue={searchParams.get("tab") ?? "by-dependant"}
      items={[
        { children: <InsuredLeadsByDependant />, key: "by-dependant", label: "Leads theo người phụ thuộc" },
        { children: <InsuredLeadsByExpiredStatus />, key: "by-expired", label: "Leads theo báo giảm" },
      ]}
      onChange={(key) =>
        setSearchParams((params) => {
          params.set("tab", key);
          return params;
        })
      }
    />
  );
};

export default InsuredLeads;
