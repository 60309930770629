import { Button, Col, Form, Input, Popover, Row, Select, Space, Typography } from "antd";
import { GetPresentCaseStatusesDocument } from "app/common/CommonQueries.generated";
import BenefitTag from "app/common/components/BenefitTagV2";
import ClaimStatus from "app/common/components/ClaimStatus";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import { useAuth } from "contexts/AuthContext";
import usePQuery from "contexts/usePQuery";
import useInsuredBenefitTypes from "hooks/useInsuredBenefitTypes";
import { debounce } from "lodash";
import { Eraser } from "lucide-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ClaimCaseStatusesEnum, InsuredBenefitTypesEnum } from "sdk/gql/types";

import styles from "./ClaimListScreen.module.css";

const { Option } = Select;
const { Text } = Typography;

export type PaymentMode = "AUTOMATION" | "MANUAL";
export type PaymentModeOption = {
  id: PaymentMode;
  name: string;
  value: PaymentMode;
};

const SearchSection = () => {
  const benefitTypeOptions = useInsuredBenefitTypes();
  const [searchParams, setSearchParams] = useSearchParams({ keyword: "", page: "1", size: "30" });
  const statuses = searchParams.getAll("status").filter((status) => status !== "") as ClaimCaseStatusesEnum[];

  const presentCaseStatus = String(searchParams.get("presentCaseStatus") ?? "");
  const benefit = String(searchParams.get("benefit") ?? "");
  const { globalData } = useAuth();

  const { data: dataPresentCaseStatuses } = usePQuery(GetPresentCaseStatusesDocument);
  const claimCaseStatusTypeOption = globalData?.claim_case_statuses;
  const presentCaseStatusOptions = dataPresentCaseStatuses?.present_case_statuses;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("keyword", searchParams.get("keyword"));
  }, [form, searchParams]);

  return (
    <div className={styles.filterContainer}>
      <Content className={styles.filterContainer}>
        <Space direction="vertical" size="middle">
          <Row gutter={8}>
            <Col span={24}>
              <Text className={styles.filterTitle}>Tìm kiếm Claim</Text>
            </Col>
            <Col span={24}>
              <Form className="form-without-validation w-100" form={form} initialValues={{ keyword: searchParams.get("keyword") ?? "" }}>
                <Form.Item name="keyword">
                  <Input.Search
                    allowClear
                    className={styles.searchBar}
                    onChange={debounce((e) => {
                      setSearchParams((params) => {
                        params.set("keyword", e.target.value ?? "");
                        params.set("is_jet", "");
                        params.set("page", "1");
                        return params;
                      });
                    }, 1500)}
                    placeholder="code/tên người được BH/CMND/CCCD/HC/GKS/mã thẻ bảo hiểm"
                    size="large"
                    width="100%"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <div className={styles.filter}>
            <div>
              <label>Lọc theo trạng thái</label>
              <Popover
                content={
                  <div className={styles.benefitFilter} style={{ width: "50vw" }}>
                    {claimCaseStatusTypeOption
                      ?.flatMap((i) => (i.comment == null ? [] : [{ name: i.comment, value: i.value }]))
                      .sort((a, b) => new Intl.Collator("vi").compare(a.name, b.name))
                      .map((b) => (
                        <ClaimStatus
                          className={statuses.includes(b.value) ? styles.active : ""}
                          key={b.value}
                          onClick={() => {
                            setSearchParams((params) => {
                              if (statuses.includes(b.value)) {
                                params.delete("status");
                                statuses
                                  .filter((s) => s !== b.value)
                                  .forEach((s) => {
                                    params.append("status", s);
                                  });
                              } else {
                                params.append("status", b.value);
                              }
                              return params;
                            });
                          }}
                          status={b.value}
                          text={b.name}
                        />
                      ))}
                    <If condition={statuses.length > 0}>
                      <Button
                        icon={<Eraser size={16} />}
                        onClick={() => {
                          setSearchParams((params) => {
                            params.delete("status");
                            return params;
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </If>
                  </div>
                }
                trigger="hover"
              >
                <Select
                  allowClear
                  className={styles.statusSelection}
                  disabled
                  onChange={(newStatus) =>
                    setSearchParams((params) => {
                      // antd type is incorrect here for onChange
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      if (newStatus != null) {
                        newStatus.forEach((status) => {
                          params.set("status", status);
                        });
                      }
                      params.set("is_jet", "");
                      return params;
                    })
                  }
                  onClear={() =>
                    setSearchParams((params) => {
                      params.delete("status");
                      return params;
                    })
                  }
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Chọn trạng thái"
                  size="large"
                  value={statuses}
                >
                  <Option value="">Chọn trạng thái...</Option>
                  {claimCaseStatusTypeOption
                    ?.flatMap((i) => (i.comment == null ? [] : [{ name: i.comment, value: i.value }]))
                    .sort((a, b) => new Intl.Collator("vi").compare(a.name, b.name))
                    .map((sts) => (
                      <Option key={sts.value} value={sts.value}>
                        {sts.name}
                      </Option>
                    ))}
                </Select>
              </Popover>
            </div>
            <div>
              <label>Lọc nhanh</label>
              <div className={styles.benefitFilter}>
                {claimCaseStatusTypeOption
                  ?.flatMap((i) => (i.comment == null ? [] : [{ name: i.comment, value: i.value }]))
                  .sort((a, b) => new Intl.Collator("vi").compare(a.name, b.name))
                  .filter((s) => ["Approved", "Declined", "InProgress", "InsurerAssessed", "Paid", "Pending", "Updated"].includes(s.value))
                  .map((b) => (
                    <ClaimStatus
                      className={statuses.includes(b.value) ? styles.active : ""}
                      key={b.value}
                      onClick={() => {
                        setSearchParams((params) => {
                          if (statuses.includes(b.value)) {
                            params.delete("status");
                            statuses
                              .filter((s) => s !== b.value)
                              .forEach((s) => {
                                params.append("status", s);
                              });
                          } else {
                            params.append("status", b.value);
                          }
                          return params;
                        });
                      }}
                      status={b.value}
                      text={b.name}
                    />
                  ))}
              </div>
            </div>
            <div>
              <label>Lọc theo quyền lợi</label>
              <div className={styles.benefitFilter}>
                {benefitTypeOptions
                  ?.filter((b) => b.value === InsuredBenefitTypesEnum.OutPatient)
                  .concat(benefitTypeOptions.filter((b) => b.value === InsuredBenefitTypesEnum.Dental))
                  .concat(benefitTypeOptions.filter((b) => b.value === InsuredBenefitTypesEnum.InPatient))
                  .concat(benefitTypeOptions.filter((b) => b.value === InsuredBenefitTypesEnum.Maternity))
                  .concat(benefitTypeOptions.filter((b) => b.value === "Accident"))
                  .concat(benefitTypeOptions.filter((b) => b.value === InsuredBenefitTypesEnum.Life))
                  .map((b) => (
                    <BenefitTag
                      className={benefit === b.value ? styles.active : ""}
                      key={b.value}
                      onClick={() => {
                        setSearchParams((params) => {
                          params.set("benefit", b.value === benefit ? "" : b.value);
                          params.set("is_jet", "");
                          return params;
                        });
                      }}
                      text={b.name}
                      value={b.value}
                    />
                  ))}
              </div>
            </div>
            <div>
              <label>Lọc theo trình case</label>
              <div className={styles.benefitFilter}>
                {presentCaseStatusOptions
                  ?.flatMap((i) => (i.name == null ? [] : [{ name: i.name, value: i.value }]))
                  .sort((a, b) => new Intl.Collator("vi").compare(a.name, b.name))
                  .map((b) => (
                    <BenefitTag
                      className={presentCaseStatus === b.value ? styles.active : ""}
                      key={b.value}
                      onClick={() =>
                        setSearchParams((params) => {
                          params.set("presentCaseStatus", presentCaseStatus === b.value ? "" : b.value);
                          params.set("status", "");
                          params.set("is_jet", "");
                          return params;
                        })
                      }
                      text={b.name}
                      value={b.value}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Space>
      </Content>
    </div>
  );
};

export default SearchSection;
