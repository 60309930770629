import { Button, Form, Input, Modal, Space, Tooltip, Typography, notification } from "antd";
import Content from "app/common/components/Content";
import If from "app/common/components/If";
import { PORTAL_PATH } from "app/portal/config/paths";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import { Edit, Save, ScrollText, Trash2 } from "lucide-react";
import { useEffect } from "react";
import { Link, generatePath, useParams } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import styles from "./AssessmentExplanationForm.module.css";
import PolicyTerms from "./PolicyTerms";
import { placeHolderTextsMapping } from "./parsePlaceholderTextsToValue";

const { TextArea } = Input;

const UpdateAssessmentExplanationDocument = graphql(`
  mutation UpdateAssessmentExplanation($id: uuid!, $input: claim_notes_set_input!) {
    update_claim_notes_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`);

const WriteAssessmentExplanationDocument = graphql(`
  mutation WriteAssessmentExplanation($input: claim_notes_insert_input!) {
    insert_claim_notes_one(object: $input, on_conflict: { constraint: claim_notes_pkey, update_columns: [content] }) {
      id
    }
  }
`);

const AssessmentExplanationForm = ({ disabled }: { disabled?: boolean }) => {
  const {
    claim,
    permissions: { canUpdateExplanation },
  } = useClaim();
  const { user } = useAuth();
  const { claimCaseId } = useParams();

  const [writeAssessmentExplanationMutation] = usePMutation(WriteAssessmentExplanationDocument, {
    onCompleted: () => notification.success({ message: "Lưu thành công" }),
    refetchQueries: ["ClaimDetail"],
  });

  const [updateAssessmentExplanationMutation] = usePMutation(UpdateAssessmentExplanationDocument, {
    onCompleted: () => notification.success({ message: "Xóa thành công" }),
    refetchQueries: ["ClaimDetail"],
  });

  const [assessmentExplanationForm] = Form.useForm();
  const reactiveExplanationNote = Form.useWatch<string | undefined>("content", assessmentExplanationForm);

  const hasPlaceholderTextNeedFilling = [...(reactiveExplanationNote?.matchAll(/(?<=)(<.*?>)(?=\s*)/gi) ?? [])][0]?.some((word) =>
    placeHolderTextsMapping.map((item) => item.text).includes(word),
  );

  const assessmentExplanation = claim?.assessment_explanation[0];
  useEffect(() => {
    assessmentExplanationForm.setFieldValue("content", assessmentExplanation?.content);
  }, [assessmentExplanation, assessmentExplanationForm]);

  const [modal, contextHolder] = Modal.useModal();
  const openTerms = async (e) => {
    modal.info({
      content: (
        <PolicyTerms
          onSubmit={(texts) => {
            assessmentExplanationForm.setFieldValue("content", texts);
            Modal.destroyAll();
          }}
        />
      ),
      footer: null,
      maskClosable: true,
      title: (
        <span>
          Danh sách các điều khoản đặc biệt/loại trừ/mở rộng{" "}
          <Link to={`${generatePath(PORTAL_PATH.POLICY_INFO, { id: claim?.insured_certificate.policy.id })}?tab=terms`}>
            <Edit size={16} />
          </Link>
        </span>
      ),
      width: "90%",
    });
    e.stopPropagation();
  };

  return (
    <Content title="Trích dẫn các điều khoản liên quan">
      <Form
        disabled={disabled ?? canUpdateExplanation === false}
        form={assessmentExplanationForm}
        onFinish={(values) => {
          if (values.content == null || values.content.trim() === "") {
            notification.error({ message: "Nội dung không được để trống" });
            return;
          }
          writeAssessmentExplanationMutation({
            variables: {
              input: {
                claim_case_id: claimCaseId,
                content: values.content,
                id: assessmentExplanation?.id,
                type: "ASSESSMENT_EXPLANATION",
                username: user.name,
              },
            },
          });
        }}
      >
        <div className={styles.content}>
          <Form.Item name="content">
            <TextArea autoSize={{ minRows: 5 }} className={styles.textArea} spellCheck status={hasPlaceholderTextNeedFilling === true ? "error" : ""} variant="outlined" />
          </Form.Item>
        </div>
        <div className={styles.btnSave}>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <If condition={hasPlaceholderTextNeedFilling === true}>
              <span className="color-red">Có một hoặc nhiều nội dung trong dấu &lt;&gt; cần điền</span>
            </If>
            {reactiveExplanationNote != null && reactiveExplanationNote.trim() !== "" && (
              <Typography.Paragraph
                copyable={{
                  onCopy: () => navigator.clipboard.writeText(reactiveExplanationNote),
                  tooltips: false,
                }}
              />
            )}
            <If
              condition={
                (claim?.insured_certificate.policy.policy_terms_aggregate.aggregate?.count ?? 0) > 0 ||
                (claim?.insured_certificate.plan.policy_terms_aggregate.aggregate?.count ?? 0) > 0 ||
                (claim?.insured_certificate.plan.computed_policy_terms?.length ?? 0) > 0
              }
            >
              {contextHolder}
              <Tooltip title="Danh sách các điều khoản đặc biệt/loại trừ/mở rộng">
                <ScrollText className="anticon cursor-pointer" onClick={openTerms} size={16} />
              </Tooltip>
            </If>
            <Button
              disabled={reactiveExplanationNote == null || reactiveExplanationNote.trim() === "" || (disabled ?? canUpdateExplanation === false)}
              htmlType="submit"
              icon={<Save size={16} />}
              size="large"
              type="primary"
            >
              Lưu
            </Button>
            <Button
              danger
              disabled={assessmentExplanation?.id == null || (disabled ?? canUpdateExplanation === false)}
              icon={<Trash2 size={16} />}
              onClick={() =>
                Modal.confirm({
                  cancelText: "Không xóa",
                  okText: "Xóa",
                  onOk: () => {
                    if (assessmentExplanation?.id != null) {
                      updateAssessmentExplanationMutation({
                        variables: {
                          id: assessmentExplanation.id,
                          input: { deleted_at: "now()" },
                        },
                      });
                    }
                  },
                  title: "Vui lòng xác nhận xóa",
                })
              }
              size="large"
              type="primary"
            >
              Xóa
            </Button>
          </Space>
        </div>
      </Form>
    </Content>
  );
};

export default AssessmentExplanationForm;
