import type { FC } from "react";

import { Avatar as AntAvatar, Tooltip } from "antd";

type AvatarProps = {
  src?: null | string;
  text?: null | string;
} & React.ComponentProps<typeof AntAvatar>;

const Avatar: FC<AvatarProps> = ({ src, text, ...reset }) => (
  <Tooltip title={src != null && <img src={src} style={{ maxHeight: 100 }} />}>
    <AntAvatar src={src} {...reset}>
      {text?.charAt(0)}
    </AntAvatar>
  </Tooltip>
);

export default Avatar;
