import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef("");

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) lastHash.current = location.hash.slice(1); // safe hash for further use after navigation

    setTimeout(() => {
      if (lastHash.current) {
        const ele = document.querySelector(`#${lastHash.current}`);
        if (ele) {
          ele.scrollIntoView({ behavior: "instant", block: "start" });
          lastHash.current = "";
        } else {
          setTimeout(() => {
            if (lastHash.current) {
              const ele1 = document.querySelector(`#${lastHash.current}`);
              if (ele1) {
                ele1.scrollIntoView({ behavior: "instant", block: "start" });
                lastHash.current = "";
              } else {
                setTimeout(() => {
                  if (lastHash.current) {
                    const ele3 = document.querySelector(`#${lastHash.current}`);
                    if (ele3) {
                      ele3.scrollIntoView({ behavior: "instant", block: "start" });
                      lastHash.current = "";
                    }
                  }
                }, 500);
              }
            }
          }, 500);
        }
      }
    }, 1);
  }, [location]);
};

export default useScrollToAnchor;
