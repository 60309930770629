import type { IdentityAccessPermissionsEnum } from "sdk/gql/graphql";

import { MedicineBoxOutlined, ProjectFilled } from "@ant-design/icons";
import { Image } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import Folder from "assets/images/Folder.png";
import styles from "layouts/PortalLayout.module.less";
import {
  BadgeDollarSign,
  BarChart4,
  Briefcase,
  Building,
  Building2,
  Calendar,
  ClipboardEdit,
  ClipboardList,
  CreditCard,
  DollarSign,
  FileBarChart2,
  FileClock,
  FileHeart,
  FileSearch,
  FileSpreadsheet,
  FileText,
  LayoutDashboard,
  LineChart,
  ListChecks,
  ListChecksIcon,
  Image as LucideImage,
  Mail,
  Mailbox,
  NetworkIcon,
  PackageOpen,
  PlusSquare,
  Settings,
  ShoppingBag,
  Table2,
  Tags,
  Target,
  Upload,
  User,
  UserCog,
  Users,
  Wrench,
} from "lucide-react";

interface MenuConfig {
  children?: MenuConfig[];
  features?: string[];
  icon?: null | React.ReactNode;
  key?: null | string;
  label: string;
  path?: null | string;
  permissions?: IdentityAccessPermissionsEnum[];
}

const MENU_CONFIG: MenuConfig[] = [
  {
    icon: <LayoutDashboard color="#ed1b55" size={14} />,
    label: "Dashboard",
    path: PORTAL_PATH.DASHBOARD,
    permissions: ["claim_case_view"],
  },
  // Header
  {
    children: [
      {
        icon: <User size={14} />,
        label: "Người được bảo hiểm",
        path: PORTAL_PATH.INSURED_PERSON_LIST,
        permissions: ["insured_person_view"],
      },
      {
        icon: <MedicineBoxOutlined />,
        label: "Quyền lợi bảo hiểm",
        path: PORTAL_PATH.INSURED_BENEFIT_LIST,
        permissions: ["insured_person_view"],
      },
      {
        icon: <Building size={14} />,
        label: "Công ty",
        path: PORTAL_PATH.COMPANY_LIST,
        permissions: ["company_view"],
      },
      {
        icon: <FileText size={14} />,
        label: "Hợp đồng",
        path: PORTAL_PATH.POLICY_LIST,
        permissions: ["policy_view"],
      },
      {
        icon: <PackageOpen size={14} />,
        label: "Sản phẩm BH",
        path: PORTAL_PATH.PLAN_LIST,
        permissions: ["plan_view"],
      },
      {
        icon: <CreditCard size={14} />,
        label: "Mẫu thẻ",
        path: PORTAL_PATH.DYNAMIC_CARD_LIST,
        permissions: ["card_view"],
      },
      {
        icon: <Building2 size={14} />,
        label: "Cơ sở y tế",
        path: PORTAL_PATH.MEDICAL_PROVIDER_LIST,
        permissions: ["medical_provider_view"],
      },
      {
        icon: <Mail size={14} />,
        label: "Correspondences",
        path: PORTAL_PATH.CORRESPONDENCE.LIST,
        permissions: ["correspondence_admin"],
      },
      {
        icon: <Tags size={14} />,
        label: "Labels",
        path: PORTAL_PATH.ADMIN.LABELS,
        permissions: ["policy_admin"],
      },
    ],
    icon: <ClipboardList color="#ed1b55" size={14} />,
    label: "Admin Group",
    path: null,
    permissions: ["insured_person_view", "company_view", "policy_view", "plan_view", "card_view", "medical_provider_view"],
  },
  {
    children: [
      {
        icon: <Mailbox size={14} />,
        key: PORTAL_PATH.MAILBOX.BASE_PATH,
        label: "Tất cả hộp mail",
        path: PORTAL_PATH.MAILBOX.EMAIL_LIST_DEFAULT,
      },
    ],
    icon: <Mailbox color="#ed1b55" size={14} />,
    label: "Quản lý hộp mail",
    path: null,
    permissions: ["insured_person_view", "company_view", "policy_view", "plan_view", "card_view", "medical_provider_view"],
  },
  {
    children: [
      {
        icon: <PlusSquare size={14} />,
        label: "Tạo YCBT",
        path: PORTAL_PATH.HC_CREATE_CLAIM_CASE,
        permissions: ["claim_case_edit"],
      },
      {
        icon: <FileHeart size={14} />,
        label: "Claims",
        path: PORTAL_PATH.HC_CLAIM_LIST,
        permissions: ["claim_case_view"],
      },
      {
        icon: <NetworkIcon size={14} />,
        label: "Assignment",
        path: PORTAL_PATH.HC_CLAIM_CASE_ASSIGNMENT,
        permissions: ["claim_case_view"],
      },
      {
        icon: <FileSearch size={14} />,
        label: "QA",
        path: PORTAL_PATH.CLAIM_CASE_QA,
        permissions: ["claim_case_view"],
      },
      {
        icon: <Target size={14} />,
        label: "My Productivity",
        path: PORTAL_PATH.REPORT.HC_PRODUCTIVITY,
        permissions: ["claim_case_view"],
      },
    ],
    icon: <Briefcase color="#ed1b55" size={14} />,
    label: "HC - Claim Group",
    path: null,
    permissions: ["claim_case_view"],
  },
  {
    children: [
      {
        icon: <PlusSquare size={14} />,
        label: "Tạo YCBT",
        path: PORTAL_PATH.FWD_MR_CREATE_CLAIM_CASE,
        permissions: ["claim_case_edit"],
      },
      {
        icon: <FileHeart size={14} />,
        label: "Claims",
        path: PORTAL_PATH.FWD_MR_CLAIM_LIST,
        permissions: ["claim_case_view"],
      },

      {
        icon: <ListChecks size={14} />,
        label: "Decision",
        path: PORTAL_PATH.DECISION,
        permissions: ["claim_case_view"],
      },
      {
        icon: <NetworkIcon size={14} />,
        label: "Assignment",
        path: PORTAL_PATH.FWD_MR_CLAIM_CASE_ASSIGNMENT,
        permissions: ["claim_case_view"],
      },
      {
        icon: <Target size={14} />,
        label: "My Productivity",
        path: PORTAL_PATH.REPORT.FWD_MR_PRODUCTIVITY,
        permissions: ["claim_case_view"],
      },
    ],
    icon: <Briefcase color="#ed1b55" size={14} />,
    label: "FWD MR - Claim Group",
    path: null,
    permissions: ["fwd_mr_view"],
  },
  {
    children: [
      // {
      //   icon: <PlusSquare size={14} />,
      //   label: "Tạo YCBT",
      //   path: PORTAL_PATH.FWD_MR_CREATE_CLAIM_CASE,
      //   permissions: ["claim_case_edit"],
      // },
      {
        icon: <FileHeart size={14} />,
        label: "Claims",
        path: PORTAL_PATH.FWD_HS_CLAIM_LIST,
        permissions: ["claim_case_view"],
      },
      // {
      //   icon: <ListChecks size={14} />,
      //   label: "Decision",
      //   path: PORTAL_PATH.DECISION,
      //   permissions: ["claim_case_view"],
      // },
      // {
      //   icon: <NetworkIcon size={14} />,
      //   label: "Assignment",
      //   path: PORTAL_PATH.FWD_MR_CLAIM_CASE_ASSIGNMENT,
      //   permissions: ["claim_case_view"],
      // },
      // {
      //   icon: <Target size={14} />,
      //   label: "My Productivity",
      //   path: PORTAL_PATH.REPORT.FWD_MR_PRODUCTIVITY,
      //   permissions: ["claim_case_view"],
      // },
    ],
    icon: <Briefcase color="#ed1b55" size={14} />,
    label: "FWD HS - Claim Group",
    path: null,
    permissions: ["fwd_hs_assessor"],
  },
  {
    children: [
      {
        icon: <PlusSquare size={14} />,
        label: "Tạo YCBT",
        path: PORTAL_PATH.MBAL_HS_CREATE_CLAIM_CASE,
        permissions: ["mb_view", "claim_case_edit"],
      },
      {
        icon: <FileHeart size={14} />,
        label: "Claims",
        path: PORTAL_PATH.MBAL_CLAIM_LIST,
        permissions: ["mb_view", "claim_case_view"],
      },
      {
        icon: <FileClock size={14} />,
        label: "Ân Hạn",
        path: PORTAL_PATH.GRACE_PERIOD,
        permissions: ["mb_view", "claim_case_view"],
      },
      {
        icon: <NetworkIcon size={14} />,
        label: "Assignment",
        path: PORTAL_PATH.MBAL_HS_CLAIM_CASE_ASSIGNMENT,
        permissions: ["mb_view", "claim_case_view"],
      },
      {
        icon: <Target size={14} />,
        label: "My Productivity",
        path: PORTAL_PATH.REPORT.MBAL_HS_PRODUCTIVITY,
        permissions: ["claim_case_view"],
      },
    ],
    icon: <Briefcase color="#ed1b55" size={14} />,
    label: "MBAL - Claim Group",
    path: null,
    permissions: ["mb_view"],
  },
  {
    children: [
      {
        icon: <FileHeart size={14} />,
        label: "Claims",
        path: PORTAL_PATH.SLV_HS_CLAIM_LIST,
        permissions: ["slv_view", "claim_case_view"],
      },
      {
        icon: <NetworkIcon size={14} />,
        label: "Assignment",
        path: PORTAL_PATH.SLV_HS_CLAIM_CASE_ASSIGNMENT,
        permissions: ["slv_view", "claim_case_view"],
      },
      {
        icon: <Target size={14} />,
        label: "My Productivity",
        path: PORTAL_PATH.REPORT.SLV_PRODUCTIVITY,
        permissions: ["claim_case_view"],
      },
    ],
    icon: <Briefcase color="#ed1b55" size={14} />,
    label: "SLV - Claim Group",
    path: null,
    permissions: ["slv_view"],
  },
  {
    children: [
      {
        label: "HS - QC OCR",
        path: PORTAL_PATH.OCR_QC_LIST,
        permissions: ["qc_view"],
      },
      {
        label: "MBAL - QC OCR",
        path: PORTAL_PATH.MBAL_OCR_QC_LIST,
        permissions: ["qc_view"],
      },
      {
        label: "MR - QC OCR",
        path: PORTAL_PATH.FWD_MR_OCR_QC_LIST,
        permissions: ["qc_view"],
      },
    ],
    icon: <LucideImage color="#ed1b55" size={14} />,
    label: "OCR & Fraud",
    path: null,
    permissions: ["qc_view"],
  },

  // Hospital Group
  {
    children: [
      {
        icon: <PlusSquare size={14} />,
        label: "Tạo yêu cầu BLVP",
        path: PORTAL_PATH.HOSPITAL_CERTIFICATES_SEARCH,
        permissions: ["direct_billing_edit"],
      },
      {
        icon: <Image rootClassName={styles.iconFolder} src={Folder} width={14} />,
        label: "Chưa xử lý",
        path: PORTAL_PATH.HOSPITAL_CLAIM_UNPROCESSED,
        permissions: ["direct_billing_view"],
      },
      {
        icon: <Image rootClassName={styles.iconFolder} src={Folder} width={14} />,
        label: "Đã gửi",
        path: PORTAL_PATH.HOSPITAL_CLAIM_SENT,
        permissions: ["direct_billing_view"],
      },
      {
        icon: <Image rootClassName={styles.iconFolder} src={Folder} width={14} />,
        label: "Cần bổ sung hồ sơ",
        path: PORTAL_PATH.HOSPITAL_CLAIM_PENDING,
        permissions: ["direct_billing_view"],
      },
      {
        icon: <Image rootClassName={styles.iconFolder} src={Folder} width={14} />,
        label: "Đã có kết quả",
        path: PORTAL_PATH.HOSPITAL_CLAIM_RESULTED,
        permissions: ["direct_billing_view"],
      },
    ],
    icon: <Building2 color="#ed1b55" size={14} />,
    label: "Hospital Group",
    path: null,
    permissions: ["direct_billing_view"],
  },
  {
    children: [
      {
        icon: <FileBarChart2 size={14} />,
        label: "Báo cáo",
        path: PORTAL_PATH.HOSPITAL_MANAGEMENT_REPORT,
        permissions: ["direct_billing_view"],
      },
    ],
    icon: <Table2 color="#ed1b55" size={14} />,
    label: "Quản lý",
    path: null,
    permissions: ["direct_billing_view"],
  },
  {
    children: [
      {
        icon: <Users size={14} />,
        label: "Người dùng Portal",
        path: PORTAL_PATH.CM.ADMIN_USERS_LIST,
        permissions: ["user_view"],
      },
      {
        icon: <UserCog size={14} />,
        label: "Permission Management",
        path: PORTAL_PATH.CM.PERMISSION,
        permissions: ["user_admin"],
      },
    ],
    icon: <Settings color="#ed1b55" size={14} />,
    label: "CM",
    path: null,
    permissions: ["user_admin"],
  },
  {
    children: [
      {
        icon: <Building2 size={14} />,
        key: PORTAL_PATH.PAYOUT.DIRECT_BILLING.BASE_PATH,
        label: "Thanh toán BLVP",
        path: `${PORTAL_PATH.PAYOUT.DIRECT_BILLING.BASE_PATH}/${PORTAL_PATH.PAYOUT.DIRECT_BILLING.BATCH_PAYMENT_CREATION}`,
        permissions: ["user_view"],
      },
      {
        icon: <User size={14} />,
        key: PORTAL_PATH.PAYOUT.REIMBURSEMENT.BASE_PATH,
        label: "Thanh toán chi trả sau",
        path: `${PORTAL_PATH.PAYOUT.REIMBURSEMENT.BASE_PATH}/${PORTAL_PATH.PAYOUT.REIMBURSEMENT.PAYOUT_CREATION_TAB}`,
        permissions: ["user_view"],
      },
      {
        icon: <Building size={14} />,
        label: "Hoàn quỹ",
        path: PORTAL_PATH.PAYOUT.REFUND,
        permissions: ["user_view"],
      },
      {
        icon: <ListChecksIcon size={14} />,
        label: "Xác nhận tài khoản thụ hưởng",
        path: PORTAL_PATH.PAYOUT.CONFIRM_BENEFICIARY,
        permissions: ["claim_case_edit"],
      },
    ],
    icon: <Settings color="#ed1b55" size={14} />,
    label: "Thanh toán",
    path: null,
    permissions: ["user_view"],
  },
  {
    children: [
      {
        icon: <BarChart4 size={14} />,
        label: "Charts",
        path: PORTAL_PATH.REPORT.ASSESSMENT_ANALYTICS,
        permissions: ["claim_case_view"],
      },
      {
        icon: <LineChart size={14} />,
        label: "Productivity",
        path: PORTAL_PATH.REPORT.ALL_PRODUCTIVITY,
        permissions: ["claim_case_view"],
      },
      {
        icon: <DollarSign size={14} />,
        label: "Hoàn quỹ",
        path: PORTAL_PATH.REPORT.INSURER_REFUND,
        permissions: ["claim_case_view"],
      },
      {
        icon: <Wrench size={14} />,
        label: "Tools",
        path: PORTAL_PATH.REPORT.TOOLS,
        permissions: ["policy_admin"],
      },
      {
        icon: <FileBarChart2 size={14} />,
        label: "Tổng hợp các loại báo cáo",
        path: PORTAL_PATH.REPORT.TOTAL,
        permissions: ["claim_case_view"],
      },
      {
        icon: <ProjectFilled size={14} />,
        label: "Quản lý report",
        path: PORTAL_PATH.REPORT_MANAGEMENT,
        permissions: ["report_management_view"],
      },
    ],
    icon: <FileSpreadsheet color="#ed1b55" size={14} />,
    label: "Reports & Tools",
    path: null,
    permissions: ["claim_case_view"],
  },
  {
    children: [
      {
        icon: <Settings size={14} />,
        label: "Quản lý lịch khám",
        path: PORTAL_PATH.BOOKING.BOOKING_MANAGE,
        permissions: ["claim_case_view"],
      },
      {
        icon: <ClipboardList size={14} />,
        label: "Danh sách khám",
        path: PORTAL_PATH.BOOKING.LIST_BOOKING,
        permissions: ["claim_case_view"],
      },
      {
        icon: <ClipboardEdit size={14} />,
        label: "Yêu cầu thay đổi thông tin",
        path: PORTAL_PATH.BOOKING.EDIT_PROFILE_BOOKING,
        permissions: ["claim_case_view"],
      },
      {
        icon: <Upload size={14} />,
        label: "Upload kết quả khám",
        path: PORTAL_PATH.BOOKING.HEALTH_PROFILE_UPLOAD_TOOL,
        permissions: ["claim_case_view"],
      },
    ],
    icon: <Calendar color="#ed1b55" size={14} />,
    label: "Lịch khám định kỳ",
    path: null,
    permissions: ["claim_case_view"],
  },
  {
    children: [
      {
        icon: <BadgeDollarSign size={14} />,
        label: "Leads",
        path: PORTAL_PATH.SALE.INSURED_LEADS,
        permissions: ["policy_admin"],
      },
      {
        icon: <CreditCard size={14} />,
        label: "Payment Requests",
        path: PORTAL_PATH.SALE.PAYMENT_REQUESTS,
        permissions: ["policy_admin"],
      },
      {
        icon: <Settings size={14} />,
        label: "Quản lý YCBH",
        path: PORTAL_PATH.INSURANCE_REQUESTS_LIST,
        permissions: ["policy_admin"],
      },
    ],
    icon: <ShoppingBag color="#ed1b55" size={14} />,
    label: "Sale bảo hiểm",
    path: null,
    permissions: ["policy_admin"],
  },
];
export default MENU_CONFIG;
