import type { ItemType } from "antd/lib/menu/hooks/useItems";
import type { FC } from "react";

import { useLocalStorageState } from "ahooks";
import { Badge, Button, Dropdown, Form, Input, message, Modal, Radio, Space, Switch, Tooltip, Typography } from "antd";
import { FORMATTER, LOCAL_STORAGE_KEYS, THEMES } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { MeDocument } from "contexts/graphql/queries";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import utils from "libs/utils";
import { ChevronRight } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import Avatar from "../Avatar";
import If from "../If";
import NotificationButton from "../Notification/NotificationButton";
import FwdStatusDetail from "./components/FwdStatusDetail";
import styles from "./TopBar.module.css";

const { Text } = Typography;

const UserSettingsFragment = graphql(`
  fragment UserSettings on identity_user_settings {
    id
    use_gpt_in_claim
    use_pending_form_v2
  }
`);

const UserSettingsDocument = graphql(
  `
    query UserSettings($userId: uuid!) {
      user_settings: identity_user_settings_by_pk(user_id: $userId) {
        id
        ...UserSettings
      }
    }
  `,
  [UserSettingsFragment],
);

const UpdateUserSettingDocument = graphql(
  `
    mutation UpdateUserSetting($userId: uuid!, $settings: identity_user_settings_set_input!) {
      update_identity_user_settings_by_pk(pk_columns: { user_id: $userId }, _set: $settings) {
        id
        ...UserSettings
      }
    }
  `,
  [UserSettingsFragment],
);

const LoginAsAnyUserDocument = graphql(`
  query LoginAsAnyUser($email: String, $phone: String) {
    loginAsUser(email: $email, phone: $phone)
  }
`);

export const FwdHealthLogsDocument = graphql(`
  query FwdHealthLogs {
    fwd_health_logs(order_by: { checked_at: desc }, limit: 1) {
      id
      checked_at
      is_running
      response_content
      response_status
    }
  }
`);

const TopBar: FC = () => {
  const { hasAccessPermissions, hasRole, selectedRole, signOut, user } = useAuth();
  const { data } = usePQuery(UserSettingsDocument, { variables: { userId: user.id } });
  const [updateUserSettingMutation, { loading }] = usePMutation(UpdateUserSettingDocument, {
    onCompleted: () => message.success("Cập nhật thành công"),
    refetchQueries: getRefetchOperationNames([MeDocument]),
  });
  const location = useLocation();
  const [theme, setTheme] = useState<string>("");
  const [profileDropdown, setProfileDropdown] = useState<boolean>(false);
  const [showFwdStatusDetail, setShowFwdStatusDetail] = useState(false);
  const healthLogsData = usePQuery(FwdHealthLogsDocument, { pollInterval: 3_600_000, skip: selectedRole == null });
  const lastHealthLog = healthLogsData.data?.fwd_health_logs[0];
  const [modal, contextHolder] = Modal.useModal();
  const [isDebuggingClaimAssessment, setDebuggingClaimAssessment] = useLocalStorageState<boolean>(LOCAL_STORAGE_KEYS.DEBUG_CLAIM_ASSESSMENT, { defaultValue: false });

  const [loginAsAnyUser, { loading: loginAsAnyUserLoading }] = usePLazyQuery(LoginAsAnyUserDocument);
  const [superAdminToken, setSuperAdminToken] = useLocalStorageState<string | undefined>(LOCAL_STORAGE_KEYS.SUPER_ADMIN_TOKEN, { defaultValue: undefined });

  const changeTheme = (value: null | string) => {
    if (value == null) {
      document.documentElement.dataset.theme = THEMES.LIGHT_THEME;
    } else {
      if (value === THEMES.DARK_THEME) {
        document.documentElement.dataset.theme = THEMES.DARK_THEME;
      }
      if (value === THEMES.LIGHT_THEME) {
        document.documentElement.dataset.theme = THEMES.LIGHT_THEME;
      }
      if (value === THEMES.BLUE_THEME) {
        document.documentElement.dataset.theme = THEMES.BLUE_THEME;
      }
    }
    localStorage.setItem("theme", value ?? THEMES.LIGHT_THEME);
    setTheme(value ?? THEMES.LIGHT_THEME);
  };

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    changeTheme(localTheme);
    setTheme(localTheme ?? "");
  }, []);

  const handleVisibleChange = (flag: boolean) => {
    setProfileDropdown(flag);
  };

  const tailPathname = location.pathname.split("/portal/")[1];

  const menuItems = useMemo(() => {
    const thisMenuItems: ItemType[] = [
      {
        key: 0,
        label: "Allow Notification",
        onClick: () => window.Notification.requestPermission(),
      },
      {
        key: 1,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Debug Mode</span>
            <Switch
              defaultChecked={localStorage.getItem(LOCAL_STORAGE_KEYS.DEBUG) === "true"}
              onChange={(value) => localStorage.setItem(LOCAL_STORAGE_KEYS.DEBUG, value ? "true" : "false")}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            />
          </div>
        ),
      },
      // {
      //   key: 2,
      //   label: (
      //     <div style={{ display: "flex", justifyContent: "space-between" }}>
      //       <span>Enable ChatGPT</span>
      //       <Switch
      //         checked={data?.user_settings?.use_gpt_in_claim === true}
      //         loading={loading}
      //         onChange={(value) => {
      //           updateUserSettingMutation({
      //             variables: {
      //               settings: {
      //                 use_gpt_in_claim: value,
      //               },
      //               userId: user.id,
      //             },
      //           });
      //         }}
      //         style={{ marginBottom: "auto", marginTop: "auto" }}
      //       />
      //     </div>
      //   ),
      // },
      {
        key: 3,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Debug Claim Assessment</span>
            <Switch
              defaultChecked={isDebuggingClaimAssessment}
              onChange={(value) => {
                setDebuggingClaimAssessment(value);
              }}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            />
          </div>
        ),
      },
      {
        key: 4,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Use Pending Form V2</span>
            <Switch
              defaultChecked={data?.user_settings?.use_pending_form_v2 === true}
              onChange={(value, e) => {
                updateUserSettingMutation({
                  variables: {
                    settings: {
                      use_pending_form_v2: value,
                    },
                    userId: user.id,
                  },
                });
                e.stopPropagation();
              }}
              style={{ marginBottom: "auto", marginTop: "auto" }}
            />
          </div>
        ),
      },
      {
        key: 10,
        label: (
          <>
            Switch theme <br />
            <Radio.Group buttonStyle="solid" defaultValue={theme} onChange={(e) => changeTheme(e.target.value)}>
              <Radio.Button value="light">Default</Radio.Button>
              <Radio.Button value="dark">Quiet Dark</Radio.Button>
              <Radio.Button value="blue">Blue Sea</Radio.Button>
            </Radio.Group>
          </>
        ),
      },
      {
        key: 30,
        type: "divider",
      },
      {
        key: 40,
        label: <div className={styles.logout}>Logout</div>,
        onClick: () => {
          signOut();
        },
      },
    ];
    if (user.can_login_to_any_user === true) {
      thisMenuItems.push({
        key: "login_as_user",
        label: "Login as other user",
      });
    }
    if (superAdminToken != null) {
      thisMenuItems.push({
        key: "SWITCH_TO_SUPER_ADMIN",
        label: "Quay lại tài khoản super admin",
      });
    }
    return thisMenuItems;
  }, [
    data?.user_settings?.use_gpt_in_claim,
    data?.user_settings?.use_pending_form_v2,
    loading,
    isDebuggingClaimAssessment,
    theme,
    user.can_login_to_any_user,
    user.id,
    superAdminToken,
    updateUserSettingMutation,
    setDebuggingClaimAssessment,
    signOut,
  ]);
  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <div className={styles.breadcrumb}>
          <div>
            Trang chủ
            {tailPathname != null &&
              tailPathname.split("/").map((tail, index) => (
                <span key={`tail${tail}`}>
                  {tail.includes("Breadcrumb") ? (
                    " "
                  ) : (
                    <>
                      &nbsp;
                      <ChevronRight
                        size={10}
                        style={{
                          color: "var(--bread-crumb-color-active)",
                        }}
                      />
                      <Link
                        to={`/portal/${tailPathname
                          .split("/")
                          .slice(0, index + 1)
                          .join("/")}`}
                      >
                        &nbsp;{tail}
                      </Link>
                    </>
                  )}
                </span>
              ))}
          </div>
        </div>
        <div className={styles.info}>
          <If condition={hasAccessPermissions(["fwd_mr_view"])}>
            <Tooltip title={`Cập nhật lần cuối: ${lastHealthLog == null ? null : utils.formatDate(lastHealthLog.checked_at, FORMATTER.DATE_TIME_FORMAT)}`}>
              <Button onClick={() => setShowFwdStatusDetail(true)} type="text">
                <Space>
                  {lastHealthLog == null ? null : (
                    <If condition={lastHealthLog.is_running === true} else={<Badge status="error" />}>
                      <Badge status="success" />
                    </If>
                  )}
                  <Text>FWD Server Health</Text>
                </Space>
              </Button>
            </Tooltip>
          </If>
          <If condition={hasRole(["TenantAdmin"])}>
            <NotificationButton />
          </If>
          <Dropdown
            menu={{
              items: menuItems,
              onClick: (e) => {
                if (e.key === "login_as_user") {
                  modal.info({
                    content: (
                      <Form
                        onFinish={async (values) => {
                          const { data: loggedInAsUserData } = await loginAsAnyUser({ variables: { email: values.email, phone: undefined } });
                          if (loggedInAsUserData?.loginAsUser != null) {
                            const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
                            if (superAdminToken == null && token != null) {
                              setSuperAdminToken(token);
                            }
                            localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, loggedInAsUserData.loginAsUser);
                            window.location.reload();
                          }
                        }}
                      >
                        <Form.Item name="email">
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType="submit" loading={loginAsAnyUserLoading} type="primary">
                            Login
                          </Button>
                        </Form.Item>
                      </Form>
                    ),
                    footer: null,
                    title: "Đăng nhập vào tài khoản khác",
                  });
                }
                if (e.key === "SWITCH_TO_SUPER_ADMIN" && superAdminToken != null) {
                  localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, superAdminToken);
                  localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_ADMIN_TOKEN);
                  window.location.reload();
                }
              },
            }}
            onOpenChange={(flag) => handleVisibleChange(flag)}
            open={profileDropdown}
            trigger={["click"]}
          >
            <Button type="text">
              <Space>
                <Avatar
                  size={24}
                  src={user.avatar_url}
                  style={{
                    backgroundColor: "var(--background-avatar-color)",
                  }}
                  text={user.name}
                />
                <Text>{user.name}</Text>
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
      <FwdStatusDetail healthLogs={healthLogsData.data?.fwd_health_logs ?? []} onClose={() => setShowFwdStatusDetail(false)} open={showFwdStatusDetail} />
    </>
  );
};

export default TopBar;
