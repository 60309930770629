import { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import { graphql } from "sdk/v2/graphql";

const CLAIM_CASE_ON_CLAIM_LIST_FRAGMENT = graphql(
  `
    fragment ClaimCaseOnClaimList on claim_cases {
      id
      benefit_type
      claim_sequence
      ...ClaimDetailForTat
      code
      is_accessible
      is_direct_billing
      is_jet
      request_amount
      status
      stp_status
      tat_in_seconds
      tatDayHourMin @client
      tatInHourUpToNow @client
      updated_at
      created_at
      claim_case_assessed_diagnoses {
        id
        icd {
          id
          value
        }
      }
      claim_case_payment {
        id
        actual_paid_amount
        is_partial_paid
      }
      claim_case_same_event_groups_claim_case {
        id
        claim_case_same_event_group {
          id
          code
          claim_case_same_event_groups_claim_cases_aggregate(where: { claim_case: {} }) {
            aggregate {
              count
            }
          }
          slv_claim_case_same_event_group {
            group_status {
              comment
              value
            }
          }
        }
      }
      claim_case_status {
        comment
        value
      }
      claim_source {
        comment
        value
      }
      fig_claim_case {
        id
        assignee_user {
          id
          user {
            id
            short_name
            user_id
          }
        }
      }
      fwd_claim_case {
        id
        last_adjust_log: sync_logs(where: { direction: { _eq: SENT }, type: { _eq: ADJUST } }, limit: 1, order_by: { created_at: desc }) {
          is_success
          response_data
        }
        last_adjust_success_log: sync_logs(where: { is_success: { _eq: true }, type: { _eq: ADJUST } }, limit: 1, order_by: { created_at: desc }) {
          is_success
        }
        last_decision_log: sync_logs(where: { direction: { _eq: SENT }, type: { _eq: DECISION } }, limit: 1, order_by: { created_at: desc }) {
          is_success
          response_data
        }
        last_decision_success_log: sync_logs(where: { is_success: { _eq: true }, type: { _eq: DECISION } }, limit: 1, order_by: { created_at: desc }) {
          is_success
        }
        last_register_log: sync_logs(where: { direction: { _eq: SENT }, type: { _eq: REGISTER } }, limit: 1, order_by: { created_at: desc }) {
          is_success
          response_data
        }
        last_register_success_log: sync_logs(where: { is_success: { _eq: true }, type: { _eq: REGISTER } }, limit: 1, order_by: { created_at: desc }) {
          is_success
        }
      }
      insured_benefit_type {
        comment
        value
      }
      insured_certificate {
        id
        insured_person {
          id
          name
          vip_level
          labels {
            label {
              id
              name
              color
              description
              # icon
            }
          }
        }
        policy {
          id
          corporate_company {
            id
            name
          }
          insured_person {
            id
            name
          }
          insurer_company {
            id
            name
          }
          policy_setting {
            id
            tat_max
            turn_around_time_commitment
          }
        }
      }
      medical_provider {
        id
        name
      }
      payment_request_item {
        id
        payout_type
        status
        transactions {
          id
          payment_provider
        }
      }
    }
  `,
  [CLAIM_DETAIL_FOR_TAT_FRAGMENT],
);

const ALL_CLAIM_CASES_QUERY = graphql(
  `
    query AllClaimCases($where: claim_cases_bool_exp!, $orderBy: [claim_cases_order_by!], $limit: Int = 30, $offset: Int = 0) {
      claim_cases(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
        id
        ...ClaimCaseOnClaimList
      }
    }
  `,
  [CLAIM_CASE_ON_CLAIM_LIST_FRAGMENT],
);

const ALL_CLAIM_CASE_COUNT_QUERY = graphql(`
  query AllClaimCasesCount($excludeTestUsersWhere: claim_cases_bool_exp!) {
    app_total: claim_cases_aggregate(where: { _and: [$excludeTestUsersWhere, { source: { _in: [CARE_APP, InsuredPersons, FWD_WEB, MBAL_APP] } }] }) {
      aggregate {
        count
      }
    }
    email_total: claim_cases_aggregate(where: { _and: [$excludeTestUsersWhere, { source: { _in: [EMAIL, Assessments] } }] }) {
      aggregate {
        count
      }
    }
    mp_total: claim_cases_aggregate(where: { _and: [$excludeTestUsersWhere, { source: { _in: [MedicalProviders] } }] }) {
      aggregate {
        count
      }
    }
    post_total: claim_cases_aggregate(where: { _and: [$excludeTestUsersWhere, { source: { _eq: POST } }] }) {
      aggregate {
        count
      }
    }
    total: claim_cases_aggregate(where: { _and: [$excludeTestUsersWhere, { is_history: { _eq: false } }] }) {
      aggregate {
        count
      }
    }
  }
`);

const CLAIM_EXCLUSION_LIST_QUERY = graphql(`
  query ClaimExclusionList {
    claim_exclusions {
      id
      email
    }
  }
`);

export { ALL_CLAIM_CASE_COUNT_QUERY, ALL_CLAIM_CASES_QUERY, CLAIM_CASE_ON_CLAIM_LIST_FRAGMENT, CLAIM_EXCLUSION_LIST_QUERY };
