import type { ModalFuncProps } from "antd";
import type { ModalFunc } from "antd/lib/modal/confirm";
import type { InsertClaimCaseDeclineHistoryDocument } from "hooks/useDecline";

import { Button, Form, Input, Modal, Select, notification } from "antd";
import Content from "app/common/components/Content";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { SPECIAL_DECLINE_CODE } from "config/constants";
import usePMutation from "contexts/usePMutation";
import { useInsertClaimCaseDeclineHistory, usePpyDeclineCodes } from "hooks/useDecline";
import { debounce, isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { type VariablesOf, graphql } from "sdk/v2/graphql";

import styles from "./DeclineInfo.module.css";
import DeclineReverseForm from "./components/DeclineReverseForm";

const { TextArea } = Input;

export const UpdateClaimCaseDeclineHistoryDocument = graphql(`
  mutation UpdateClaimCaseDeclineHistory($id: uuid!, $data: claim_case_decline_histories_set_input!) {
    update_claim_case_decline_histories_by_pk(_set: $data, pk_columns: { id: $id }) {
      id
    }
  }
`);

const DeclineInfo = () => {
  const {
    claim,
    status: { status },
  } = useClaim();

  const declineInfo = claim?.claim_case_decline_histories[0];
  const ppyDeclineCodes = usePpyDeclineCodes();
  const [declineForm] = Form.useForm();
  const declineCode = Form.useWatch("decline_code", declineForm);

  const declineReverseModalRef = useRef<ReturnType<ModalFunc>>();
  const [declineReverseModal, contextHolder] = Modal.useModal();

  const [insertClaimCaseDeclineHistory] = useInsertClaimCaseDeclineHistory();
  const [updateClaimCaseDeclineHistory] = usePMutation(UpdateClaimCaseDeclineHistoryDocument);

  const insurerDeclineCodes = ppyDeclineCodes.filter(
    (n) => !isEmpty(n.insurer_decline_code) && !isEmpty(n.insurer_decline_code.filter((v) => v.insurer_id === claim?.insured_certificate.policy.insurer_company?.company_id)),
  );
  const declineCodes = isEmpty(insurerDeclineCodes) ? ppyDeclineCodes : insurerDeclineCodes;

  const onDeclineFormUpdate = (values) => {
    if (status === "Declined") {
      let declineDescription = values.decline_description;

      if (declineCode !== SPECIAL_DECLINE_CODE) {
        declineDescription = undefined;
        declineForm.setFieldValue("decline_description", undefined);
      }

      const claimCaseDeclineHistory: VariablesOf<typeof InsertClaimCaseDeclineHistoryDocument>["data"] = {
        claim_case_id: claim?.claim_case_id,
        decline_code: declineCode,
        decline_description: declineDescription,
      };

      if (isEmpty(declineCode)) {
        notification.error({ message: "Vui lòng chọn 1 lý do từ chối" });
        return;
      }
      if (declineCode === SPECIAL_DECLINE_CODE && isEmpty(declineDescription)) {
        notification.error({ message: "Vui lòng ghi chi tiết từ chối cho lý do này" });
        return;
      }

      if (values.id == null) {
        insertClaimCaseDeclineHistory({
          onCompleted: () => {
            declineForm.setFieldValue("deleted_reason", undefined);
            notification.success({ message: "Cập nhật lý do từ chối thành công" });
          },
          refetchQueries: ["ClaimDetail"],
          variables: { data: claimCaseDeclineHistory },
        });
      } else {
        updateClaimCaseDeclineHistory({
          onCompleted: () => {
            declineForm.setFieldValue("deleted_reason", undefined);
            notification.success({ message: "Cập nhật lý do từ chối thành công" });
          },
          refetchQueries: ["ClaimDetail"],
          variables: { data: claimCaseDeclineHistory, id: values.id },
        });
      }
    }
  };

  const declineReverseModalConfig: ModalFuncProps = {
    bodyStyle: { paddingBottom: "0px" },
    content: <DeclineReverseForm resetDeclineInfoForm={() => declineForm.resetFields()} />,
    footer: null,
    maskClosable: true,
    title: "Đảo ngược trạng thái từ chối",
    type: "info",
    width: "50%",
  };

  useEffect(() => {
    if (!isEmpty(declineInfo)) {
      declineForm.setFieldValue("id", declineInfo.id);
      declineForm.setFieldValue("decline_code", declineInfo.decline_code);
      declineForm.setFieldValue("decline_description", declineInfo.decline_description);
    }
  }, [declineForm, declineInfo]);

  if (declineInfo == null) {
    return null;
  }

  return (
    <>
      {contextHolder}
      <Content title="Thông tin từ chối">
        <Form className={styles.declineForm} form={declineForm} onFinish={debounce(onDeclineFormUpdate, 1000)}>
          <Form.Item name="id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item label="Lý do từ chối" labelCol={{ span: 24 }} name="decline_code" required wrapperCol={{ span: 24 }}>
            <Select
              className={styles.declineFormSelect}
              options={[...declineCodes]
                .sort((a, b) => a.enum_localizations[0]?.enum_translation?.localeCompare(b.enum_localizations[0]?.enum_translation ?? "") ?? 0)
                .map((item) => ({
                  label: item.enum_localizations[0]?.enum_translation,
                  value: item.enum_localizations[0]?.enum_value,
                }))}
              placeholder="Chọn lý do từ chối"
            />
          </Form.Item>

          {declineCode === SPECIAL_DECLINE_CODE ? (
            <Form.Item label="Mô tả lý do từ chối" labelCol={{ span: 24 }} name="decline_description" required wrapperCol={{ span: 24 }}>
              <TextArea autoSize={{ minRows: 5 }} />
            </Form.Item>
          ) : null}

          <div className={styles.buttonDeclineContainer}>
            <Button
              id="Delete"
              onClick={() => {
                declineReverseModalRef.current = declineReverseModal.info(declineReverseModalConfig);
              }}
              style={{ marginRight: "10px" }}
              type="default"
            >
              Xóa
            </Button>
            <Button htmlType="submit" id="Update" type="primary">
              Lưu lại
            </Button>
          </div>
        </Form>
      </Content>
    </>
  );
};
export default DeclineInfo;
