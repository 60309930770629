import type { FileTypeResult } from "file-type/core";

const buildFileThumbnail = ({ fullSize, mime_type, size, url }: { fullSize?: number; mime_type?: FileTypeResult["mime"] | string; size: number; url?: null | string }) => {
  const isPdf = mime_type?.includes("pdf") === true;
  const isImage = mime_type?.includes("image") === true;
  const isExcel = mime_type?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") === true || mime_type?.includes("application/vnd.ms-excel");
  const isDocs = mime_type?.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") === true || mime_type?.includes("application/msword");

  const fullImg = (() => {
    if (isPdf) return "/icons/pdf.png";
    if (isExcel === true) return "/icons/xls.png";
    if (isDocs === true) return "/icons/word.png";
    if (url == null) return "/icons/png";
    if (isImage) {
      if (fullSize == null) {
        return url;
      }
      if (url.includes("?") === true) {
        return `${url}&width=${fullSize}`;
      }
      return `${url}?width=${fullSize}`;
    }
    return "/icons/png";
  })();

  const image = (() => {
    if (isPdf) return "/icons/pdf.png";
    if (isExcel === true) return "/icons/xls.png";
    if (isDocs === true) return "/icons/word.png";
    if (isImage) return url?.includes("?") === true ? `${url}&width=${size}` : `${url}?width=${size}`;
    return "/icons/png";
  })();

  return { fullImg, image, isDocs, isExcel, isImage, isPdf };
};

export default buildFileThumbnail;
