export const getNullableNumber = (value: null | number | undefined) => value ?? 0;
export const getNullableArrayLength = (array: any[] | null | undefined) => (array == null ? 0 : array.length);
export const getZeroOrNumber = (value: any | any[] | null | undefined) => {
  if (value == null) return 0;
  if (Array.isArray(value)) return value.length;
  return Number(value);
};
/**
 * Used to check for array length or number. Before this:
 * (claim.insured_certificate.claim_cases_aggregate.aggregate?.count ?? 0) > 0
 * (node.props.record.children?.length ?? 0) > 0
 *
 * @param any
 * @returns number
 */
export const isGreaterThanZero = (value: any[] | null | number | undefined) => getZeroOrNumber(value) > 0;
/**
 * Used to check for array length or number. Before this:
 * (claim.insured_certificate.claim_cases_aggregate.aggregate?.count ?? 0) === 0
 * (node.props.record.children?.length ?? 0) === 0
 *
 * @param any
 * @returns number
 */
export const isEqualZero = (value: any[] | null | number | undefined) => getZeroOrNumber(value) === 0;

export const isNull = (value: any | null | undefined) => value == null;
