import { graphql } from "sdk/v2/graphql";

export const COMMON_GLOBAL_COUNTERS = graphql(`
  mutation CommonGlobalCounters($type: String!) {
    globalCounters: update_global_counters_by_pk(_inc: { counter: 1 }, pk_columns: { type: $type }) {
      counter
    }
  }
`);

export const COMMON_QUERY_GET_INSURED_BENEFIT_TYPES = graphql(`
  query CommonQueryGetInsuredBenefitTypes {
    insured_benefit_types {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const COMMON_QUERY_GET_INSURED_RELATIONSHIP_TYPES = graphql(`
  query CommonQueryGetInsuredRelationshipTypes {
    insured_relationship_types {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const COMMON_QUERY_GET_PLAN_BALANCE_TYPES = graphql(`
  query CommonQueryGetPlanBalanceTypes {
    plan_balance_types {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const COMMON_QUERY_GET_POLICY_COPAYMENT_TARGET_TYPES = graphql(`
  query CommonQueryGetPolicyCopaymentTargetTypes {
    policy_co_payment_targets {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const COMMON_QUERY_MEDICAL_PROVIDER_TYPES = graphql(`
  query CommonQueryMedicalProviderTypes {
    mp_medical_provider_types {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const CREATE_NOTIFICATION = graphql(`
  mutation CreateNotification($object: notification_notifications_insert_input!) {
    insert_notification_notifications_one(object: $object) {
      id
    }
  }
`);

export const FILL_MBAL_MEDICAL_PROVIDER_NOTES = graphql(`
  mutation FillMbalMedicalProviderNotes($claimCaseId: UUID!, $nextStatus: String!) {
    fillMbalMedicalProviderNotes(claimCaseId: $claimCaseId, nextStatus: $nextStatus) {
      message
      success
    }
  }
`);

export const GetCompanyRolesDocument = graphql(`
  query CompanyRoles {
    company_role_types {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const GET_CUSTOM_SETTING = graphql(`
  query CustomSetting($getCustomSettingInput: GetCustomSettingInput!) {
    getCustomSetting(getCustomSettingInput: $getCustomSettingInput) {
      booleanValue
      jsonbValue
      numericValue
      textValue
      timestamptzValue
    }
  }
`);

export const GET_PRESENT_CASE_STATUSES = graphql(`
  query PresentCaseStatuses {
    present_case_statuses {
      id: value
      name: comment
      comment
      value
    }
  }
`);

export const SEND_EMAIL = graphql(`
  mutation SendEmail($emailInput: EmailInput!) {
    berry {
      sendEmail(emailInput: $emailInput)
    }
  }
`);

export const UPSERT_CUSTOM_SETTINGS = graphql(`
  mutation UpsertCustomsettings($upsertCustomSettingInput: UpsertCustomSettingInput!) {
    upsertCustomSetting(upsertCustomSettingInput: $upsertCustomSettingInput)
  }
`);
