import type { MeQuery } from "contexts/AuthContext.generated";

import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, makeVar, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
// import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { Modal } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import { getClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/utils";
import { SLACK } from "config";
import { FORMATTER, LOCAL_STORAGE_KEYS } from "config/constants";
import { secondsToMilliseconds } from "date-fns";
import dedent from "dedent";
import { print } from "graphql";
import { createClient } from "graphql-ws";
import { sendLogToBigQuery } from "libs/track";
import utils from "libs/utils";
import { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import { isEmpty, throttle } from "lodash";
import { generatePath } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

const SKIP_ERROR_REPORT = new Set(["life assured not found", "Không tìm thấy chủ hợp đồng"]);
const SKIPPED_OPERATIONS = new Set(["GenerateStructureText"]);

const notifyPostgresDownToSlackWithThrottle = throttle((message) => {
  utils.sendMessageToSlack({
    channel: SLACK.CHANNEL_IDS.HAI,
    message,
  });
  utils.sendMessageToSlack({
    channel: SLACK.CHANNEL_IDS.LOC,
    message,
  });
}, secondsToMilliseconds(5));

const createApolloClient = () => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Record<string, string> }) => {
      const authToken = localStorage.getItem("token");
      const authHeaders = authToken == null ? {} : { Authorization: `Bearer ${authToken}` };
      return {
        headers: {
          "x-accept-language": "vi",
          ...authHeaders,
          ...headers,
        },
      };
    });
    return forward(operation);
  });
  const logLink = new ApolloLink((operation, forward) =>
    forward(operation).map((result) => {
      if (new Set(["FigClaimCountersForSideMenu", "FailedCorrespondenceHistories", "ClaimCountersInAssignmentScreen2"]).has(operation.operationName)) {
        return result;
      }
      sendLogToBigQuery({
        data: {
          email: (JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) ?? "{}") as MeQuery["identity_users_by_pk"])?.email,
          ip: localStorage.getItem(LOCAL_STORAGE_KEYS.IP) ?? "",
          query: print(operation.query),
          response_data: JSON.stringify(result.data),
          time: utils.formatDate(new Date(), FORMATTER.API_DATE_FORMAT),
          url: `${window.location.origin}/${window.location.pathname}/${window.location.search}${window.location.hash}`,
          user: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO),
          user_id: (JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) ?? "{}") as MeQuery["identity_users_by_pk"])?.id,
          variables: JSON.stringify(operation.variables),
        },
        topic: "api-logs",
      });
      return result;
    }),
  );
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors?.[0]?.message === "Could not verify JWT: JWTExpired") {
      localStorage.clear();
      return;
    }
    if (SKIPPED_OPERATIONS.has(operation.operationName)) {
      return;
    }
    if (import.meta.env.PROD && localStorage.getItem(LOCAL_STORAGE_KEYS.DEBUG) === "false") {
      // Sentry.captureException(`[GraphQL error]: ${operation.operationName}, role: ${operation.getContext().headers["x-hasura-role"]}, message: ${JSON.stringify(graphQLErrors)}`);
    } else {
      const errorMessages = {
        ACCOUNT_NUMBER_INCORRECT: "Số tài khoản không đúng",
        ECONNABORTED: "Không thể kết nối đến server đối tác",
        "FWD ERROR!": "Lỗi không kết nối đến server FWD được. Xin thử lại thao tác.",
        "Policy not found": "Không tìm thấy hợp đồng",
      };
      if (graphQLErrors?.[0]?.message === "database query error") {
        notifyPostgresDownToSlackWithThrottle("database query error");
      }
      if (graphQLErrors?.[0]?.extensions?.code === "postgres-error") {
        Modal.destroyAll();
        notifyPostgresDownToSlackWithThrottle("Postgres down nữa rồi");
        Modal.error({
          content: <div style={{ whiteSpace: "pre" }}>{graphQLErrors[0].message}</div>,
          title: "Có lỗi rồi, Sọ Dừa đã báo cho team IT xử lý. Bạn hãy nghỉ ngơi uống trà sữa.",
        });
      } else if (
        (typeof graphQLErrors?.[0]?.extensions?.code === "string" && graphQLErrors[0].extensions.code in errorMessages) ||
        (graphQLErrors?.[0]?.message != null && graphQLErrors[0].message in errorMessages)
      ) {
        Modal.error({
          closable: true,
          content: <div style={{ whiteSpace: "pre" }}>{errorMessages[graphQLErrors[0]?.message] ?? errorMessages[graphQLErrors[0].extensions.code]}</div>,
          keyboard: true,
          maskClosable: true,
          okCancel: true,
          okText: "Tắt",
          onCancel: Modal.destroyAll,
          title: "Có lỗi",
          width: "50%",
        });
      } else if (graphQLErrors && isEmpty(graphQLErrors[0]?.extensions?.customData)) {
        Modal.error({
          closable: true,
          content: (
            <div style={{ whiteSpace: "pre" }}>
              <ul>
                <li>
                  <b>Role</b>: {operation.getContext().headers["x-hasura-role"]}
                </li>
                <li>
                  <b>Operation</b>: {operation.operationName}
                </li>
                <li>
                  <b>Variables</b>: {JSON.stringify({ ...operation.variables, password: operation.variables.password == null ? undefined : "********" })}
                </li>
                <li>
                  <b>GraphQLErrors</b>:<pre style={{ fontSize: "12px", whiteSpace: "pre-wrap" }}>{JSON.stringify(graphQLErrors, undefined, 2)}</pre>
                </li>
              </ul>
            </div>
          ),
          keyboard: true,
          maskClosable: true,
          okCancel: true,
          okText: "Tắt",
          onCancel: Modal.destroyAll,
          title: "GraphQL Error",
          width: "50%",
        });
        const userInfo = (JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) ?? "{}") ?? {}) as MeQuery["identity_users_by_pk"];
        if (SKIP_ERROR_REPORT.has(graphQLErrors[0]?.message ?? "") === false) {
          utils.sendSlackMessage({
            blocks: [
              {
                text: {
                  text: dedent`
                    :ladybug: GraphQL Errors on \`Cassava\` :ladybug:
                    *URL*: ${window.location.href}
                    *User*: <${window.location.origin}${generatePath(PORTAL_PATH.CM.ADMIN_USER_EDIT, {
                      userId: userInfo?.id ?? "",
                    })}|${userInfo?.name}>
                    *Operation*: ${operation.operationName}
                    *Variables*: \`${JSON.stringify({ ...operation.variables, password: operation.variables.password == null ? undefined : "********" })}\`
                    *Role*: ${operation.getContext().headers["x-hasura-role"]}
                  `,
                  type: "mrkdwn",
                },
                type: "section",
              },
              {
                text: {
                  text: `\`\`\`${JSON.stringify(graphQLErrors, undefined, 2)}\`\`\``,
                  type: "mrkdwn",
                },
                type: "section",
              },
            ],
            channel: SLACK.ERROR_REPORT_CHANNEL,
          });
        }
      }

      if (networkError) {
        // if (networkError.message !== "Failed to fetch") {
        //   Modal.error({
        //     title: "Network error",
        //     content: networkError.message,
        //   });
        // }
      }
    }
  });
  const subscriptionLink = new GraphQLWsLink(
    createClient({
      connectionParams: () => {
        const authToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (authToken === null) {
          return {};
        }
        return {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "x-hasura-role": "TenantAdmin",
          },
        };
      },
      url: import.meta.env.VITE_APPLE_WS_ENDPOINT,
    }),
  );

  const memoryCache = new InMemoryCache({
    typePolicies: {
      berryquery_root: {
        merge: true,
      },
      claim_cases: {
        fields: {
          tatDayHourMin: {
            read: (_, { cache, readField, storage }) => {
              if (storage.var == null) {
                // eslint-disable-next-line no-param-reassign
                storage.var = makeVar("");
              }
              if (storage.var() !== "") {
                return storage.var();
              }
              (async () => {
                const claim = cache.readFragment({
                  fragment: CLAIM_DETAIL_FOR_TAT_FRAGMENT,
                  id: cache.identify({ __typename: "claim_cases", id: readField("id") }),
                });
                if (isEmpty(claim)) {
                  return;
                }

                const res = await utils.calculateTatAsync({
                  claim,
                  claimType: getClaimType(claim),
                  unit: "day-hour-minute",
                  upToNow: true,
                });
                storage.var(res);
              })();
              return storage.var();
            },
          },
          tatInHourUpToNow: {
            read: (_, { cache, readField, storage }) => {
              if (storage.var == null) {
                // eslint-disable-next-line no-param-reassign
                storage.var = makeVar(0);
              }
              if (storage.var() !== 0) {
                return storage.var();
              }
              (async () => {
                const claim = cache.readFragment({
                  fragment: CLAIM_DETAIL_FOR_TAT_FRAGMENT,
                  id: cache.identify({ __typename: "claim_cases", id: readField("id") }),
                });
                if (isEmpty(claim)) {
                  return;
                }
                const res = Number(
                  await utils.calculateTatAsync({
                    claim,
                    claimType: getClaimType(claim),
                    unit: "hour",
                    upToNow: true,
                  }),
                );

                if (Number.isNaN(res)) {
                  return;
                }
                storage.var(res);
              })();
              return storage.var();
            },
          },
        },
      },
      mbalquery_root: {
        merge: true,
      },
      mrquery_root: {
        merge: true,
      },
      policies: {
        fields: {
          poName: {
            read: (_, { cache, readField, storage }) => {
              if (storage.var == null) {
                // eslint-disable-next-line no-param-reassign
                storage.var = makeVar("");
              }
              const policy = cache.readFragment({
                fragment: graphql(`
                  fragment PolicyOwnerName on policies {
                    id
                    corporate_company {
                      name
                    }
                    insured_person {
                      name
                    }
                  }
                `),
                id: cache.identify({ __typename: "policies", id: readField("id") }),
              });
              if (policy != null) {
                storage.var(policy.corporate_company?.name ?? policy.insured_person?.name ?? "--");
              }
              return storage.var();
            },
          },
        },
      },
    },
  });
  // await persistCache({
  //   cache,
  //   storage: new LocalStorageWrapper(window.localStorage),
  //   debug: true,
  //   trigger: "write",
  // });
  const removeTypenameLink = removeTypenameFromVariables();

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    subscriptionLink,
    authLink,
  );
  const links = [removeTypenameLink, authLink, errorLink, splitLink, logLink];
  links.push(
    // new BatchHttpLink({
    //   uri: import.meta.env.VITE_APPLE_ENDPOINT,
    //   batchMax: 5, // No more than 5 operations per batch
    //   batchInterval: 20, // Wait no more than 20ms after first batched operation
    // }),
    new HttpLink({
      uri: import.meta.env.VITE_APPLE_ENDPOINT,
    }),
  );
  return new ApolloClient({
    cache: memoryCache,
    connectToDevTools: import.meta.env.DEV,
    link: from(links),
    name: "cassava",
    version: "0.1",
  });
};

export default createApolloClient;
