import { graphql } from "sdk/v2/graphql";

export const DELETE_CLAIM_DETAIL = graphql(`
  mutation DeleteClaimDetail($id: UUID!) {
    deleteClaimDetail(claimDetailId: $id)
  }
`);

export const SYNC_LOG_FRAGMENT = graphql(`
  fragment SyncLog on fwd_sync_logs {
    id
    data
    direction
    is_success
    request_id
    type
    will_auto_decision
    created_at
    user {
      id
      name
      user_id
    }
  }
`);

export const FWD_CLAIM_CASE_SYNC_LOGS = graphql(
  `
    query FwdClaimCaseSyncLogs($claimId: uuid!) {
      fwd_claim_cases_by_pk(claim_case_id: $claimId) {
        id
        adjustCallbackLogs: sync_logs(where: { type: { _eq: ADJUST }, direction: { _eq: RECEIVED } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
        adjustLogs: sync_logs(where: { type: { _eq: ADJUST }, direction: { _eq: SENT } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
        decisionCallbackLogs: sync_logs(where: { type: { _eq: DECISION }, direction: { _eq: RECEIVED } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
        decisionLogs: sync_logs(where: { type: { _eq: DECISION }, direction: { _eq: SENT } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
        registerCallbackLogs: sync_logs(where: { type: { _eq: REGISTER }, direction: { _eq: RECEIVED } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
        registerLogs: sync_logs(where: { type: { _eq: REGISTER }, direction: { _eq: SENT } }, order_by: { created_at: desc }) {
          id
          ...SyncLog
        }
      }
    }
  `,
  [SYNC_LOG_FRAGMENT],
);

export const FWD_SYNC_LOGS = graphql(`
  query FwdSyncLogs($where: fwd_sync_logs_bool_exp) {
    fwd_sync_logs(where: $where, order_by: { created_at: desc }) {
      id
      data
      direction
      is_success
      request_id
      response_data
      will_auto_decision
      created_at
      user {
        id
        name
      }
    }
  }
`);

export const TERMS_FRAGMENT = graphql(`
  fragment Terms on policy_terms {
    id
    content
    note
    parent_id
    insured_benefit_type {
      comment
      value
    }
    parent {
      id
      parent {
        id
      }
    }
  }
`);
export const POLICY_TERMS = graphql(
  `
    query PolicyTerms($where: policy_terms_bool_exp!, $searchWhere: policy_terms_bool_exp) {
      policy_terms(where: $where, order_by: { display_order: asc }) {
        id
        ...Terms
        children(order_by: { display_order: asc }) {
          id
          ...Terms
          children(where: $searchWhere, order_by: { display_order: asc }) {
            id
            ...Terms
          }
        }
      }
    }
  `,
  [TERMS_FRAGMENT],
);

export const UPDATE_FWD_CLAIM = graphql(`
  mutation UpdateFwdClaim($claimId: uuid!, $input: fwd_claim_cases_set_input) {
    update_fwd_claim_cases_by_pk(pk_columns: { claim_case_id: $claimId }, _set: $input) {
      id
      __typename
    }
  }
`);
