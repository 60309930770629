import type { TableColumnsType, TablePaginationConfig } from "antd";
import type { ResultOf } from "sdk/v2/graphql";

import TableList from "app/common/components/TableListV2";
import { PORTAL_PATH } from "app/portal/config/paths";
import { LA_WITH_EXPIRED_STATUS_QUERY } from "app/portal/screens/SalePortal/InsuredLeads/queries";
import { FORMATTER, TYPE } from "config/constants";
import usePQuery from "contexts/usePQuery";
import utils from "libs/utils";
import { generatePath, Link, useSearchParams } from "react-router-dom";

const ByExpiredStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const size = Number(searchParams.get("size") ?? 30);
  const page = Number(searchParams.get("page") ?? 1);
  const { data, loading } = usePQuery(LA_WITH_EXPIRED_STATUS_QUERY, {
    variables: {
      limit: size,
      offset: (page - 1) * size,
      where: {
        children_insured_certificates: {},
        status: { _eq: "EXPIRED" },
      },
    },
  });
  const columns: TableColumnsType<ResultOf<typeof LA_WITH_EXPIRED_STATUS_QUERY>["insured_certificates"][number]> = [
    {
      dataIndex: ["insured_person", "name"],
      render: (name, record) => <Link to={generatePath(PORTAL_PATH.INSURED_PERSON_DETAIL, { id: record.insured_person.id })}>{name}</Link>,
      title: "Tên",
    },
    {
      dataIndex: ["action_logs", "0", "created_at"],
      render: (expiredAt) => utils.formatDate(expiredAt),
      title: "Báo giảm lúc",
    },
    {
      dataIndex: ["insured_person", "phone"],
      title: "Phone",
    },
    {
      dataIndex: ["insured_person", "email"],
      title: "Email",
    },
    {
      dataIndex: ["insured_person", "dob"],
      render: (dob) => utils.formatDate(dob, FORMATTER.DATE_FORMAT),
      title: "DoB",
    },
    {
      dataIndex: ["insured_person", "age"],
      title: "Tuổi",
    },
    {
      dataIndex: ["insured_person", "gender"],
      render: (gender) => TYPE.GENDER_TYPE[gender],
      title: "Giới tính",
    },
    {
      dataIndex: ["policy", "corporate_company", "name"],
      title: "CTY",
    },
    {
      dataIndex: ["policy", "policy_number"],
      render: (number, record) => (
        <div>
          <Link to={generatePath(PORTAL_PATH.POLICY_DETAIL, { id: record.policy.policy_id })}>{number}</Link>
          <div>{record.policy.insurer_company?.name}</div>
        </div>
      ),
      title: "CTBH",
    },
    {
      dataIndex: ["insured_person", "claim_cases_aggregate", "aggregate", "count"],
      title: "# Claims",
    },
  ];

  const paginationConfig: TablePaginationConfig = {
    current: page,
    onChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    onShowSizeChange: (p, s) =>
      setSearchParams((params) => {
        params.set("page", String(p));
        params.set("size", String(s));
        return params;
      }),
    pageSize: size,
    total: data?.insured_certificates_aggregate.aggregate?.count,
  };

  const leads = data?.insured_certificates;

  return <TableList dataSource={leads} loading={loading} pagination={paginationConfig} schema={columns} sticky />;
};

export default ByExpiredStatus;
