import { Checkbox, Form } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvHasSurgeryDocField = ({ name }: Props) => (
  <Form.Item label="Có giấy chứng nhận phẫu thuật" name={name} valuePropName="checked">
    <Checkbox>Có giấy chứng nhận phẫu thuật</Checkbox>
  </Form.Item>
);
export default SlvHasSurgeryDocField;
