import { graphql } from "sdk/v2/graphql";

export default graphql(`
  mutation UpsertPTIClaimCase($input: pti_claim_cases_insert_input!) {
    insert_pti_claim_cases_one(object: $input, on_conflict: { update_columns: [benefit_type, disease_group], constraint: claim_cases_pkey }) {
      id
      benefit_type
      disease_group
    }
  }
`);
