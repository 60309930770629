import styles from "./NotFoundScreen.module.css";

const NotFoundScreen = () => (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.logo} />
        <div>
          <span>404.&nbsp;</span>
          <span className={styles.blur}>That's an error.</span>
          <br />
          <span>The requested URL {window.location.pathname} was not found on this server.&nbsp;</span>
          <span className={styles.blur}>That's all we know</span>
        </div>
      </div>
      <div className={styles.errorImg} />
    </div>
  );
export default NotFoundScreen;
