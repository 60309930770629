import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";

import { WarningTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Typography, notification } from "antd";

const confirmChangeStatusModal = ({
  claimCode,
  claimStatues,
  toChangeStatus,
}: {
  claimCode?: string;
  claimStatues?: { comment: null | string; value: string }[];
  toChangeStatus: ClaimCaseStatusesEnum;
}): Promise<boolean> => {
  const toChangeStatusText = claimStatues?.find((s) => s.value === toChangeStatus)?.comment;

  return new Promise((resolve) => {
    Modal.warning({
      cancelButtonProps: { hidden: true },
      content: (
        <Space direction="vertical">
          <span>
            Bạn có chắc sẽ đổi trạng thái của claim case {claimCode} thành {toChangeStatusText}?
          </span>
          <i>Hệ thống sẽ tự động decision qua FWD</i>
          <span>
            Để xác nhận, vui lòng nhập <i>{toChangeStatusText}</i>
          </span>
          <Form
            onFinish={(values) => {
              if (values.confirmText === toChangeStatusText) {
                resolve(true);
                Modal.destroyAll();
              } else {
                notification.error({ message: "Sai xác nhận" });
              }
            }}
          >
            <Form.Item name="confirmText">
              <Input autoComplete="off" placeholder={toChangeStatusText ?? ""} />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button onClick={Modal.destroyAll} type="text">
                  Không đổi trạng thái
                </Button>
                <Button htmlType="submit" type="primary">
                  Đổi trạng thái
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Space>
      ),
      footer: null,
      icon: false,
      maskClosable: true,
      okText: "Huỷ",
      onOk: () => {
        resolve(false);
      },
      title: (
        <Typography.Title level={5}>
          <WarningTwoTone twoToneColor="orange" /> Đổi trạng thái claim case
        </Typography.Title>
      ),
      width: 600,
    });
  });
};

export default confirmChangeStatusModal;
