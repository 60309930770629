import { CopyOutlined } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "layouts/LayoutContext";
import { useState } from "react";

import styles from "./Debugger.module.less";

const DebuggerScreen = () => {
  const { accessPermissions, allowedRoles, token, userId } = useAuth();
  const [showDebugger, setShowDebugger] = useState(false);
  const { showMenu } = useLayout();

  return (
    <div>
      <div className={styles.debugger} id="debugger">
        <Space align="center" direction={showMenu ? "horizontal" : "vertical"} size={0}>
          {import.meta.env.PROD ? null : (
            <button accessKey="`" onClick={() => setShowDebugger(!showDebugger)} style={{ height: 33, width: showMenu ? "unset" : 80 }} type="button">
              🐛
            </button>
          )}
        </Space>
      </div>
      <Modal centered footer={[]} onCancel={() => setShowDebugger(false)} open={showDebugger} title="Debugger">
        <div>
          User ID: {userId}
          <Button icon={<CopyOutlined size={5} />} onClick={() => (userId == null ? null : navigator.clipboard.writeText(userId))} type="default" />
        </div>
        <div style={{ display: "flex" }}>
          Token: <div className={styles.token}> {token}</div>{" "}
          <Button icon={<CopyOutlined size={5} />} onClick={() => (token == null ? null : navigator.clipboard.writeText(token))} type="default" />
        </div>
        <div>Roles</div>
        <ul>
          {allowedRoles?.map((role) => (
            <li key={role}>{role}</li>
          ))}
        </ul>
        <div>Access Permissions</div>
        <ul>
          {accessPermissions.map((ac) => (
            <li key={ac}>{ac}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

export default DebuggerScreen;
