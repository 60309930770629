import { Form, Input } from "antd";

import type { ClaimCaseDetailFormItem } from "../../../schema";

type Props = {} & ClaimCaseDetailFormItem;

const SlvPatientIdField = ({ name }: Props) => (
  <Form.Item label="Patient Id" name={name}>
    <Input placeholder="Nhập Patient Id" size="large" />
  </Form.Item>
);
export default SlvPatientIdField;
