import { uniq } from "lodash";

const parseEmailCodeFromText = (text?: null | string) => {
  if (text == null) return [];
  /**
   * Email Code: MAIL-11223344
   */
  const emailCodeRegex = /((MAIL)[\d-]+)/gm;
  const emailCodeMatches = text.match(emailCodeRegex);
  return uniq(emailCodeMatches?.map((emailCode) => emailCode.trim()) ?? []);
};

export default parseEmailCodeFromText;
