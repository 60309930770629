import { routes } from "app/portal/config/routes";
import { matchRoutes, useLocation } from "react-router-dom";

const useCurrentPath = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  if (matchedRoutes == null) return null;

  const [firstRoute] = matchedRoutes;
  if (firstRoute == null) return null;
  const { route } = firstRoute;
  return route.path;
};

export default useCurrentPath;
