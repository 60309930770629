import { useContext } from "react";

import { ClaimAssistantContext } from "./ClaimAssistantContext";

const useClaimAssistant = () => {
  const context = useContext(ClaimAssistantContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (context == null) {
    throw new Error("useClaim must be used within a ClaimProvider");
  }
  return context;
};

export default useClaimAssistant;
