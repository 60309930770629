import { gql } from "@apollo/client";
import usePMutation from "contexts/usePMutation";
import { InsertManyStatusLogsDocument, InsertStatusLogDocument } from "sdk/gql/graphql";

gql`
  mutation insertManyStatusLogs($objects: [audit_logs_record_status_changes_insert_input!]!) {
    insert_audit_logs_record_status_changes(objects: $objects) {
      __typename
    }
  }
  mutation insertStatusLog($object: audit_logs_record_status_changes_insert_input!) {
    insert_audit_logs_record_status_changes_one(object: $object) {
      __typename
    }
  }
`;

export const useStatusLogMutation = () => usePMutation(InsertStatusLogDocument);

export const useManyStatusLogsMutation = () => usePMutation(InsertManyStatusLogsDocument);
