import type { ClaimCaseStatusesEnum } from "sdk/gql/graphql";

import { Button, Modal, Select, Tooltip, Typography } from "antd";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useStatusAvailability from "app/portal/screens/ClaimPortal/ClaimContext/useStatusAvailability";
import { useAuth } from "contexts/AuthContext";
import { useMemo } from "react";

interface Props {
  defaultSelected: boolean;
  onChange?: (claimCaseStatus) => void;
  style: {};
}

const ClaimCaseStatusSelect = (props: Props) => {
  const [modal, contextHolder] = Modal.useModal();
  const { defaultSelected = true, onChange, style } = props;

  const {
    claim,
    claimType,
    isClaimHistory,
    status: { status },
  } = useClaim();
  const { globalData } = useAuth();

  const buildStatusAvailability = useStatusAvailability();

  const statusOptions = useMemo(
    () => [
      ...(globalData?.claim_case_statuses
        .map((claimCaseStatus) => {
          const { disabled: _disabled, toolTip } = buildStatusAvailability(claimCaseStatus.value as ClaimCaseStatusesEnum);
          return { ...claimCaseStatus, disabled: _disabled, toolTip };
        })
        .filter((s) => s.toolTip !== "NA")
        .sort((a, b) => new Intl.Collator("vi").compare(a.comment ?? "", b.comment ?? ""))
        .sort((a, b) => Number(a.disabled) - Number(b.disabled))
        .filter((s) => {
          const excluded = new Set<ClaimCaseStatusesEnum>();
          if (claim?.is_direct_billing === false) {
            excluded.add("Estimated");
          }
          if (claimType.healthCare) {
            excluded.add("AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING");
            excluded.add("APPROVED_WAITING_FOR_GRADE_PERIOD_END");
            excluded.add("SUPPLEMENTED_VERIFIED");
            excluded.add("Halted");
            excluded.add("INVESTIGATING");
          }
          if (claimType.mbalHs) {
            excluded.add("AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING");
          }
          if (claimType.slvHs) {
            excluded.add("AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING");
          }
          if (claimType.fwdMr) {
            excluded.add("APPROVED_WAITING_FOR_GRADE_PERIOD_END");
            excluded.add("AWAITING_CONTRACT_COMPLETION");
            excluded.add("Halted");
            excluded.add("CANCELLED_BY_LA_REQUEST");
            excluded.add("RECEIVED_DOCS");
            excluded.add("SUPPLEMENTED_VERIFIED");
            excluded.add("DOCS_OVERDUE");
            if (claim?.is_direct_billing === false) {
              excluded.add("Estimated");
              excluded.add("ApprovedWaitingDoc");
              excluded.add("ApprovedReceivedDoc");
            }
          }
          return !excluded.has(s.value);
        })
        .map((claimCaseStatus) => ({
          disabled: claimCaseStatus.disabled,
          key: claimCaseStatus.value,
          label: (
            <Tooltip title={claimCaseStatus.toolTip}>
              {claimCaseStatus.comment}
              {import.meta.env.DEV ? ` (${claimCaseStatus.value})` : undefined}
            </Tooltip>
          ),
          statusText: claimCaseStatus.comment,
          tip: claimCaseStatus.toolTip,
          value: claimCaseStatus.value,
        })) ?? []),
      {
        disabled: false,
        key: "all",
        label: (
          <Button
            onClick={() => {
              modal.info({
                content: (
                  <TableList
                    columns={[
                      {
                        key: "statusText",
                        render: (record) => <ClaimStatus value={{ ...record, comment: record.statusText }} />,
                        title: "Trạng thái",
                      },
                      {
                        dataIndex: "tip",
                        key: "tip",
                        render: (tip, record) => (
                          <If condition={record.disabled === true} else={<Typography.Text type="success">được chuyển</Typography.Text>}>
                            <Typography.Text type="secondary">{tip ?? "Không được chuyển"}</Typography.Text>
                          </If>
                        ),
                        title: "Lý do không được chuyển",
                      },
                    ]}
                    dataSource={statusOptions.slice(0, -1)}
                    pagination={false}
                  />
                ),
                title: "Trạng thái",
                width: 800,
              });
            }}
            type="primary"
          >
            Xem đầy đủ
          </Button>
        ),
        statusText: "Xem all",
        tip: undefined,
        value: "all",
      },
    ],
    [buildStatusAvailability, claim?.is_direct_billing, claimType.fwdMr, claimType.healthCare, globalData?.claim_case_statuses, modal],
  );

  return (
    <If condition={claim != null}>
      {contextHolder}
      <Select
        disabled={isClaimHistory}
        filterOption={(input, option) => option?.statusText?.toLowerCase().includes(input.toLowerCase()) ?? false}
        listHeight={300}
        onChange={(value) => {
          if (value === "all") {
            return;
          }
          onChange?.(value);
        }}
        optionFilterProp="children"
        options={statusOptions}
        placeholder="Đổi trạng thái"
        showSearch
        size="large"
        style={style}
        value={defaultSelected ? status : undefined}
      />
    </If>
  );
};

export default ClaimCaseStatusSelect;
