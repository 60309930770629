import type { CheckboxOptionType, FormInstance, SpaceProps } from "antd";
import type { Rule } from "antd/lib/form";
import type { TextAreaProps } from "antd/lib/input";
import type { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import type { FilterFunc } from "rc-select/lib/Select";
import type { FocusEventHandler, Key, MouseEventHandler, ReactNode } from "react";
import type { Maybe } from "sdk/gql/types";

import { UploadOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Checkbox, Col, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, Space, Spin, Switch, Upload } from "antd";
import { FORMATTER } from "config/constants";
import { isValid } from "date-fns";
import { utils } from "libs/utils";
import { useCallback } from "react";

import DatePicker from "../CustomAntdTimePickers/DatePicker";
import If from "../If";
import styles from "./BaseForm.module.css";

const { RangePicker } = DatePicker;

const { Option } = Select;
const { TextArea } = Input;

interface ISchemaItem<OptionType> {
  afterPrefix?: any;
  allowedValues?: (number | string)[] | { values: (number | string)[]; warningOnly: boolean };
  autoSize?: TextAreaProps["autoSize"];
  className?: string;
  component: "autocomplete" | "button" | "checkbox" | "date" | "date-range" | "dynamic-items" | "input" | "inputNumber" | "radio" | "select" | "switch" | "textArea" | "upload";
  customValidateRules?: Rule[];
  dependencies?: string[];
  disabled?: boolean;
  filterOption?: boolean | FilterFunc<OptionType>;
  format?: string;
  help?: ReactNode | string;
  isDuplicated?: boolean;
  isDuplicatedWarning?: boolean;
  itemsDirection?: SpaceProps["direction"];
  key: string;
  label?: string;
  layout?: number | string;
  maxUploadCount?: number;
  multiple?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (value: any) => void;
  onCheckDuplicated?: (value: any) => void;
  onClear?: () => void;
  onClick?: MouseEventHandler<HTMLElement>;
  onSearch?: (keyword: string) => void;
  placeholder?: string | string[];
  radioOptions?: CheckboxOptionType[];
  required?: boolean;
  rules?: Rule[];
  selectOptions?: DefaultOptionType[];
  showTime?: boolean;
  type?: "number" | "text" | string;
}

export type BaseFormSchema<OptionType extends BaseOptionType = DefaultOptionType> = ISchemaItem<OptionType>[];

export interface BaseFormProps<RecordType> {
  className?: string;
  /**
   * @deprecated use initialValues instead
   */
  data?: null | RecordType;
  disabled?: { [key: string]: boolean };
  error?: { [key: string]: { errorMsg: string; isError: boolean } };
  finishText?: string;

  form?: FormInstance<RecordType>;
  formDisabled?: boolean;
  headerInfo?: ReactNode | string;
  initialValues?: null | Partial<RecordType>;
  loading?: boolean;
  name?: string;
  needToConfirm?: boolean;
  noAction?: boolean;
  onCancel?: () => void;
  onChange?: (key: BaseFormSchema[number]["key"], value: any) => void;
  onFinish?: (values: null | Partial<RecordType> | undefined) => void;
  onFinishFailed?: ({ errorFields, outOfDate, values }) => void;
  /**
   * @deprecated use onFinish
   */
  onSave?: (values) => void;
  onValuesChange?: (changedValues: any, allValues) => void;
  option?: {
    [key: string]: (BaseOptionType | DefaultOptionType)[] | { name?: string; value: boolean | null | number | string }[] | undefined;
  };
  schemas: BaseFormSchema;
  title?: ReactNode | string;
}

const BaseForm = <RecordType extends object = any>(props: BaseFormProps<RecordType>) => {
  const [defaultForm] = Form.useForm();
  const {
    className,
    data,
    disabled,
    error,
    finishText,
    form = defaultForm,
    formDisabled = false,
    headerInfo,
    initialValues,
    loading = false,
    name,
    needToConfirm = false,
    noAction,
    onCancel,
    onChange,
    onFinish,
    onFinishFailed,
    onSave,
    onValuesChange,
    option,
    schemas,
    title,
  } = props;

  const dataOrInitialValues = data ?? initialValues;
  const convertDateFieldsToDate = useCallback(
    (_data: Maybe<RecordType> | undefined) => {
      if (!_data) return {};
      const convertedData = { ..._data };
      schemas.forEach(({ component, key }) => {
        if (component === "date") {
          convertedData[key] = _data[key] == null ? null : new Date(_data[key]);
        }
      });
      return convertedData;
    },
    [schemas],
  );

  const normFile = useCallback((e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  }, []);

  const handleChange = useCallback(
    (key: string, value: any, component?: string) => {
      if (component === "upload") {
        onChange?.(key, value.fileList);
        form.setFieldsValue({
          [key]: value.fileList,
        });
      } else {
        form.setFieldsValue({
          [key]: value,
        });
        onChange?.(key, value);
      }
    },
    [form, onChange],
  );

  return (
    <div className={[styles.container, className ?? ""].join(" ")}>
      <If condition={title != null && headerInfo != null}>
        <Row className={styles.formHeader}>
          <Col md={12} sm={24}>
            {title === undefined ? null : <div className={styles.formHeaderTitle}>{title}</div>}
          </Col>
          <Col md={12} sm={24}>
            <Row className={styles.formHeaderInfo} justify="end">
              {headerInfo}
            </Row>
          </Col>
        </Row>
      </If>

      <div className={styles.formContainer}>
        <Spin spinning={loading}>
          <Form<typeof initialValues>
            disabled={formDisabled}
            form={form}
            initialValues={convertDateFieldsToDate(dataOrInitialValues)}
            layout="vertical"
            name={name ?? "BaseForm"}
            onFinish={onSave ?? onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            requiredMark
          >
            <Row gutter={[16, 16]}>
              {schemas.map((schema) => (
                <Col key={schema.key} span={schema.layout ?? 12}>
                  <Form.Item
                    className={styles.formItem}
                    help={schema.help != null || (error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined)}
                    label={
                      schema.component === "button" ? (
                        " "
                      ) : (
                        <label>
                          {schema.label} {schema.required === true ? <span style={{ color: "red" }}>*</span> : ""}
                        </label>
                      )
                    }
                    rules={schema.rules}
                    validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                  >
                    {schema.component === "input" ? (
                      <Form.Item
                        help={schema.help != null || (error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined)}
                        hidden={schema.type === "hidden"}
                        name={schema.key}
                        noStyle
                        rules={
                          [
                            schema.required === true
                              ? {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                }
                              : undefined,
                            schema.allowedValues == null
                              ? undefined
                              : {
                                  enum: Array.isArray(schema.allowedValues) ? schema.allowedValues : schema.allowedValues.values,
                                  message: (
                                    <span>
                                      Thông tin không khớp với danh sách: {(Array.isArray(schema.allowedValues) ? schema.allowedValues : schema.allowedValues.values).join(", ")}
                                    </span>
                                  ),
                                  type: "enum",
                                  warningOnly: Array.isArray(schema.allowedValues) ? false : schema.allowedValues.warningOnly,
                                },
                            () => ({
                              validator: () => {
                                if (schema.isDuplicated === true || schema.isDuplicatedWarning === true) {
                                  return Promise.reject(new Error("Giá trị đã tồn tại"));
                                }
                                return Promise.resolve();
                              },
                              warningOnly: schema.isDuplicatedWarning === true,
                            }),
                            ...(schema.customValidateRules ?? []),
                          ].filter(Boolean) as Rule[]
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <Input
                          addonAfter={schema.afterPrefix}
                          allowClear
                          disabled={disabled?.[schema.key]}
                          onBlur={schema.onBlur}
                          onChange={(e) => {
                            if (schema.onChange) {
                              schema.onChange(e.target.value);
                            }
                            handleChange(schema.key, e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (schema.type === "number") {
                              const invalidChars = ["-", "+", "e"];
                              if (invalidChars.includes(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          placeholder={schema.placeholder as string}
                          size="large"
                          type={schema.type}
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "autocomplete" ? (
                      <Form.Item
                        help={schema.help != null || (error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined)}
                        hidden={schema.type === "hidden"}
                        name={schema.key}
                        noStyle
                        rules={
                          schema.required === true
                            ? [
                                {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                },
                              ]
                            : undefined
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <AutoComplete
                          allowClear
                          disabled={disabled?.[schema.key]}
                          filterOption={schema.filterOption ?? ((input: string, _option) => String(_option?.value).toLowerCase().includes(input.toLowerCase()))}
                          onChange={(value) => handleChange(schema.key, value)}
                          onKeyDown={(e) => {
                            if (schema.type === "number") {
                              const invalidChars = ["-", "+", "e"];
                              if (invalidChars.includes(e.key)) {
                                e.preventDefault();
                              }
                            }
                          }}
                          options={option?.[schema.key]?.flatMap((i) => (i === undefined ? [] : [i]))}
                          placeholder={schema.placeholder as string}
                          size="large"
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "textArea" ? (
                      <Form.Item
                        help={error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined}
                        name={schema.key}
                        noStyle
                        rules={[
                          schema.required === true
                            ? {
                                message: (
                                  <span>
                                    <strong>{schema.label}</strong> là field bắt buộc
                                  </span>
                                ),
                                required: true,
                              }
                            : {},
                        ]}
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <TextArea
                          autoSize={{ minRows: 3 }}
                          disabled={disabled?.[schema.key]}
                          onChange={(e) => handleChange(schema.key, e.target.value)}
                          placeholder={schema.placeholder as string}
                          size="large"
                          value={dataOrInitialValues?.[schema.key] ?? ""}
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "inputNumber" ? (
                      <Form.Item
                        help={error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined}
                        name={schema.key}
                        noStyle
                        rules={
                          schema.required === true
                            ? [
                                {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                },
                              ]
                            : []
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <InputNumber
                          disabled={disabled?.[schema.key]}
                          formatter={(value) => (schema.type === "currency" ? utils.formatNumber(value) : value)}
                          onChange={(value) => handleChange(schema.key, value)}
                          parser={(value) => utils.parseNumber(value)}
                          placeholder={schema.placeholder as string}
                          size="large"
                          style={{ width: "100%" }}
                          type={schema.type}
                          value={dataOrInitialValues?.[schema.key] ?? ""}
                          wheel="false"
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "switch" ? (
                      <Form.Item name={schema.key} valuePropName="checked">
                        <Switch
                          // }}
                          checked={dataOrInitialValues?.[schema.key]}
                          // onChange={(checked) => {
                          //   handleChange(schema.key, checked);
                          style={{ marginLeft: 100 }}
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "upload" ? (
                      <Form.Item
                        getValueFromEvent={normFile}
                        label={schema.label}
                        name={schema.key}
                        noStyle
                        rules={[
                          ...(schema.required === true
                            ? [
                                {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  validator: (rule, value) => {
                                    if (value === undefined || value.length === 0) {
                                      return Promise.reject();
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]
                            : []),
                        ]}
                        valuePropName="fileList"
                      >
                        <Upload
                          beforeUpload={() => false}
                          maxCount={schema.maxUploadCount}
                          name="file"
                          onChange={(e) => {
                            handleChange(schema.key, e, schema.component);
                          }}
                        >
                          <Button icon={<UploadOutlined />} size="large">
                            {schema.placeholder}
                          </Button>
                        </Upload>
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {schema.component === "date" && (
                      <Form.Item
                        help={error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined}
                        name={schema.key}
                        noStyle
                        rules={
                          schema.required === true
                            ? [
                                {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                },
                              ]
                            : []
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <DatePicker
                          disabled={disabled?.[schema.key]}
                          format={schema.format == null ? FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC : schema.format}
                          onChange={(date) => {
                            handleChange(schema.key, date);
                          }}
                          placeholder={schema.placeholder as string}
                          showTime={schema.showTime == null ? true : schema.showTime}
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            dataOrInitialValues?.[schema.key] != null && isValid(new Date(dataOrInitialValues[schema.key])) === true
                              ? new Date(utils.parseLocalTime(dataOrInitialValues[schema.key]))
                              : null
                          }
                        />
                      </Form.Item>
                    )}

                    {schema.component === "date-range" ? (
                      <Form.Item
                        help={error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined}
                        name={schema.key}
                        noStyle
                        rules={
                          schema.required === true
                            ? [
                                {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                },
                              ]
                            : []
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <RangePicker
                          disabled={disabled?.[schema.key]}
                          format={FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC}
                          onChange={(value) => {
                            handleChange(schema.key, [new Date(value && value[0]), new Date(value && value[1])]);
                          }}
                          placeholder={schema.placeholder as [string, string] | undefined}
                          showTime
                          size="large"
                          style={{ width: "100%" }}
                          value={
                            dataOrInitialValues?.[schema.key] != null &&
                            isValid(new Date(dataOrInitialValues[schema.key][0])) === true &&
                            isValid(new Date(dataOrInitialValues[schema.key][1])) === true
                              ? [new Date(utils.parseLocalTime(dataOrInitialValues[schema.key][0])), new Date(utils.parseLocalTime(dataOrInitialValues[schema.key][1]))]
                              : undefined
                          }
                        />
                      </Form.Item>
                    ) : null}

                    {schema.component === "button" ? (
                      <Form.Item name={schema.key} noStyle>
                        <Button
                          className={[styles.button, schema.className ?? ""].join(" ")}
                          onClick={schema.onClick}
                          size="large"
                          type={schema.type == null ? "default" : (schema.type as "dashed" | "default" | "link" | "primary" | "text")}
                        >
                          {schema.label}
                        </Button>
                      </Form.Item>
                    ) : null}

                    {schema.component === "checkbox" ? (
                      <Form.Item
                        dependencies={schema.dependencies ?? []}
                        name={schema.key}
                        noStyle
                        rules={[...(schema.rules === undefined ? [] : [...schema.rules])]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          checked={dataOrInitialValues[schema.key] ?? false}
                          disabled={disabled?.[schema.key]}
                          onChange={(e) => {
                            handleChange(schema.key, e.target.checked);
                          }}
                        >
                          {schema.placeholder}
                        </Checkbox>
                      </Form.Item>
                    ) : null}

                    {schema.component === "select" ? (
                      <Form.Item
                        help={error?.[schema.key]?.isError === true ? error[schema.key]?.errorMsg : undefined}
                        name={schema.key}
                        noStyle
                        rules={
                          [
                            schema.required === true
                              ? {
                                  message: (
                                    <span>
                                      <strong>{schema.label}</strong> là field bắt buộc
                                    </span>
                                  ),
                                  required: true,
                                }
                              : undefined,
                          ].filter(Boolean) as Rule[]
                        }
                        validateStatus={error?.[schema.key]?.isError === true ? "error" : undefined}
                      >
                        <Select
                          allowClear
                          disabled={disabled?.[schema.key] === true || schema.disabled === true}
                          filterOption={
                            schema.onSearch === undefined &&
                            (schema.filterOption ??
                              ((input: string, _option) =>
                                String(_option?.children).toLowerCase().includes(input.toLowerCase()) || String(_option?.name).toLowerCase().includes(input.toLowerCase())))
                          }
                          mode={schema.multiple === true ? "multiple" : undefined}
                          onChange={(value) => {
                            handleChange(schema.key, value);
                            if (schema.onChange) {
                              schema.onChange(value);
                            }
                          }}
                          onClear={schema.onClear}
                          onClick={schema.onClick}
                          onSearch={schema.onSearch}
                          options={schema.selectOptions}
                          placeholder={schema.placeholder}
                          showSearch
                          size="large"
                          value={dataOrInitialValues ? dataOrInitialValues[schema.key] : {}}
                        >
                          {option?.[schema.key]?.map((o) => (
                            <Option key={`${o.value as Key}-${o.name}`} value={o.value}>
                              {o.name}
                            </Option>
                          )) || null}
                        </Select>
                      </Form.Item>
                    ) : null}
                    {schema.component === "radio" ? (
                      <Form.Item name={schema.key} rules={[...(schema.customValidateRules ?? [])]}>
                        <Radio.Group onChange={schema.onChange} value={dataOrInitialValues ? dataOrInitialValues[schema.key] : {}}>
                          <Space direction={schema.itemsDirection}>
                            {schema.radioOptions?.map((_option) => (
                              <Radio key={String(_option.value)} value={_option.value}>
                                {_option.label}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    ) : null}

                    {schema.component === "dynamic-items" ? (
                      <Form.List name={schema.key}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name: fieldName }) => (
                              <Row gutter="16" key={key}>
                                <Col flex="auto">
                                  <Form.Item
                                    name={[fieldName]}
                                    rules={
                                      [
                                        schema.required === true
                                          ? {
                                              message: <span>Không được để trống field này</span>,
                                              required: true,
                                            }
                                          : undefined,
                                        ({ getFieldValue }) => ({
                                          validator: () => {
                                            if (schema.onCheckDuplicated) {
                                              return schema.onCheckDuplicated(getFieldValue([schema.key, fieldName]));
                                            }
                                            if (schema.isDuplicated === true || schema.isDuplicatedWarning === true) {
                                              return Promise.reject(new Error("Giá trị đã tồn tại"));
                                            }
                                            return Promise.resolve();
                                          },
                                          warningOnly: schema.isDuplicatedWarning === true,
                                        }),
                                      ].filter(Boolean) as Rule[]
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    <Input placeholder="" />
                                  </Form.Item>
                                </Col>

                                <Button onClick={() => remove(fieldName)} type="primary">
                                  Xóa
                                </Button>
                              </Row>
                            ))}
                            <Form.Item>
                              <Button block onClick={() => add()} type="dashed">
                                Thêm tên
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    ) : null}
                  </Form.Item>
                </Col>
              ))}

              {noAction == null || noAction === false ? (
                <Col className={styles.actionContainer} span="24">
                  <Form.Item>
                    <Space size={24}>
                      <If
                        condition={needToConfirm}
                        else={
                          <Button className={styles.actionButton} htmlType="submit" size="large" type="primary">
                            {finishText ?? "Lưu lại"}
                          </Button>
                        }
                      >
                        <Popconfirm cancelText="Hủy" okText="Đồng ý" onConfirm={() => form.submit()} title="Bạn có chắc muốn điều này?">
                          <Button className={styles.actionButton} size="large" type="primary">
                            {finishText ?? "Lưu lại"}
                          </Button>
                        </Popconfirm>
                      </If>
                      <Button className={[styles.actionButton, styles.cancel].join(" ")} onClick={onCancel} size="large" type="default">
                        Hủy bỏ
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

BaseForm.useForm = () => Form.useForm();

export default BaseForm;
