import type { FwdHealthLogsDocument } from "app/common/components/TopBar";
import type { ResultOf } from "sdk/v2/graphql";

import { List, Modal, Space, Typography, message } from "antd";
import { FORMATTER } from "config/constants";
import usePMutation from "contexts/usePMutation";
import utils from "libs/utils";
import { graphql } from "sdk/v2/graphql";

import styles from "../TopBar.module.css";

const CheckHealthDocument = graphql(`
  mutation CheckHealth {
    mr {
      checkHealth
    }
  }
`);

const LogItem = ({ log }: { log: ResultOf<typeof FwdHealthLogsDocument>["fwd_health_logs"][number] | undefined }) => (
  <Space direction="vertical">
    <Space>
      {log?.is_running === true ? (
        <>
          <span className={[styles.fwdStatus, styles.activeStatus].join(" ")} /> <span>Đang hoạt động</span>
        </>
      ) : (
        <>
          <span className={[styles.fwdStatus, styles.inactiveStatus].join(" ")} /> <span>Không hoạt động</span>
        </>
      )}
    </Space>
    <Space>
      <span className={styles.label}>Cập nhật lúc:</span>
      <span>{log?.checked_at === undefined ? null : utils.formatDate(log.checked_at, FORMATTER.DATE_TIME_FORMAT)}</span>
    </Space>
    <Space>
      <span className={styles.label}>Trạng thái phản hồi:</span>
      <span>{log?.response_status}</span>
    </Space>
    <Space direction="vertical">
      <span className={styles.label}>Dữ liệu phản hồi:</span>
      {log?.response_content == null ? null : (
        <ul>
          {Object.entries(log.response_content).map((entry) => (
            <li key={entry[0]}>
              {entry[0]}: {String(entry[1])}
            </li>
          ))}
        </ul>
      )}
    </Space>
    <Space />
  </Space>
);

const MenuContent = ({ logs }: { logs?: ResultOf<typeof FwdHealthLogsDocument>["fwd_health_logs"] }) => {
  const latestLog = logs?.[0];

  return <LogItem log={latestLog} />;
};
const FwdStatusDetail = ({
  healthLogs,
  onClose,
  open,
}: {
  healthLogs: ResultOf<typeof FwdHealthLogsDocument>["fwd_health_logs"] | undefined;
  onClose: () => void;
  open: boolean;
}) => {
  const [checkHealth, { loading: checking }] = usePMutation(CheckHealthDocument, {
    onCompleted: (data) => {
      if (data.mr?.checkHealth === true) {
        message.success("Hệ thống FWD đang hoạt động tốt");
        return;
      }
      message.error("Hệ thống FWD đang không hoạt động");
    },
    refetchQueries: ["fwdHealthLogs"],
  });

  if (!open) return null;
  return (
    <Modal
      cancelButtonProps={{ disabled: checking }}
      cancelText="Đóng"
      okButtonProps={{ disabled: checking, loading: checking }}
      okText="Kiểm tra trạng thái"
      onCancel={onClose}
      onOk={() => checkHealth()}
      open={open}
      styles={{ body: { maxHeight: 500, overflow: "auto" } }}
      title="Trạng thái các hệ thống đối tác"
    >
      <List
        dataSource={[{ component: <MenuContent logs={healthLogs} />, title: "CÔNG TY TNHH BẢO HIỂM NHÂN THỌ FWD VIỆT NAM" }]}
        itemLayout="horizontal"
        renderItem={(item) => (
          <Space direction="vertical" key={item.title}>
            <Typography.Title level={5}>{item.title}</Typography.Title>
            {item.component}
          </Space>
        )}
      />
    </Modal>
  );
};

export default FwdStatusDetail;
