import type { PolicyWaitingTimesOnClaimDetailFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { ClaimDetailQuery } from "sdk/gql/graphql";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import { addDays, endOfDay, isBefore } from "date-fns";
import { Link } from "react-router-dom";

import styles from "./WaitingTime.module.css";

const WaitingTime = (props: { claimCase?: ClaimDetailQuery["claim_cases_by_pk"] | null; waitingType?: PolicyWaitingTimesOnClaimDetailFragment[] }) => {
  const { claimCase, waitingType } = props;
  if (!waitingType || !claimCase) return null;
  return waitingType.length > 0 ? (
    <Space className={styles.waitingTime} direction="vertical" size={15}>
      <h3>
        <Link to={PORTAL_PATH.POLICY_INFO.replace(":id", claimCase.insured_certificate.policy.policy_id)}>Thời gian chờ</Link>
      </h3>
      <div className={styles.waitingTimeContainer}>
        {waitingType.map((waitingTime) => {
          const containBenefitType = waitingTime.insured_benefit_types === claimCase.benefit_type;
          const compareDate = addDays(new Date(claimCase.insured_certificate.issued_at), waitingTime.value);
          const isInWaitingTime = containBenefitType && claimCase.event_date != null && isBefore(new Date(claimCase.event_date), endOfDay(compareDate));
          return (
            <Space className={styles.waitingTimeItem} direction="vertical" key={waitingTime.description}>
              <Row>
                <Col className={styles.description} span={24}>
                  {waitingTime.description}
                </Col>
              </Row>

              <Row className={styles.contentContainer}>
                <Col span={24}>
                  <label>Thời gian: </label>
                </Col>
                <span>{waitingTime.value} ngày</span>
              </Row>
              <Row className={styles.contentContainer}>
                <Col span={24}>
                  <label>Trong thời gian chờ :</label>
                </Col>
                <Col span={24}>
                  {(isInWaitingTime && (
                    <>
                      <CheckCircleTwoTone twoToneColor="#52c41a" /> <span style={{ color: "#52c41a", fontWeight: "bold" }}>Có</span>
                    </>
                  )) || (
                    <>
                      <CloseCircleTwoTone twoToneColor="#db1d1d" />{" "}
                      <span
                        style={{
                          color: "var(--red-alert)",
                          fontWeight: "bold",
                        }}
                      >
                        Không
                      </span>
                    </>
                  )}
                </Col>
              </Row>
            </Space>
          );
        })}
      </div>
    </Space>
  ) : (
    <div />
  );
};

export default WaitingTime;
