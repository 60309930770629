import type { FC } from "react";

import { Layout } from "antd";
import DeprecatedHeader from "app/common/components/TopBar/index";
import styles from "layouts/PortalLayout.module.less";

const { Header: AntHeader } = Layout;

const Header: FC = () => (
  <AntHeader className={styles.header}>
    <DeprecatedHeader />
  </AntHeader>
);

export default Header;
