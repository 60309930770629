import type { FWD_CLAIM_CASE_SYNC_LOGS, SYNC_LOG_FRAGMENT } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.gql";
import type { FwdSyncTypesEnum } from "sdk/gql/graphql";
import type { FragmentOf, ResultOf } from "sdk/v2/graphql";

import { Button, Col, Collapse, Modal, Popconfirm, Row, Space, Switch, Tooltip } from "antd";
import If from "app/common/components/If";
import ClaimAlert from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimAlert";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useFwd from "app/portal/screens/ClaimPortal/ClaimContext/useFwd";
import { FwdAdjustCallBackLogFieldOrder, FwdClaimCaseFieldMapping } from "config/common";
import { TYPE } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { useFwdPendingCodes } from "hooks/usePublicData";
import utils from "libs/utils";
import { AlertCircle, CircleCheck, CircleX, ClockArrowDown, Info } from "lucide-react";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import RemarkCopay from "../RemarkCopay";
import Summary from "../Summary";
import SyncLogHistory from "../SyncLogHistory";
import WaitingTime from "../WaitingTime";
import styles from "./FwdClaimRightColumn.module.css";
import RegisterForm from "./RegisterForm";

const FwdDecisionStatus = ({
  decisionBy,
  lastDecisionAt,
  logs,
}: {
  decisionBy?: FragmentOf<typeof SYNC_LOG_FRAGMENT>["user"];
  lastDecisionAt?: string;
  logs: null | ResultOf<typeof FWD_CLAIM_CASE_SYNC_LOGS>["fwd_claim_cases_by_pk"] | undefined;
}) => {
  const decisionLogs = logs?.decisionLogs;
  const decisionCallbacks = logs?.decisionCallbackLogs;
  const decisionCallbackSuccess = decisionCallbacks?.find((log) => log.is_success === true);

  const LastUpdate =
    lastDecisionAt === undefined ? null : (
      <Space>
        <strong>Decision lúc:</strong>
        <span>{utils.formatDate(lastDecisionAt)}</span>
      </Space>
    );
  const DecisionBy =
    decisionBy === undefined ? null : (
      <Space>
        <strong>Decision bởi:</strong>
        <If condition={decisionLogs?.[0]?.will_auto_decision === true} else={decisionBy?.name}>
          Tự động
        </If>
      </Space>
    );
  const confirmDecisionBy =
    decisionCallbackSuccess?.user == null ? null : (
      <Space>
        <strong>Xác nhận lưu trên LAS thành công bởi:</strong>
        <span>{decisionCallbackSuccess.user.name}</span>
      </Space>
    );

  if (decisionLogs === undefined || decisionCallbacks === undefined || decisionLogs.length === 0) return null;

  if (decisionLogs[0]?.is_success === true && decisionCallbacks.length === 0) {
    return (
      <Space direction="vertical">
        <Space>
          <ClockArrowDown size={16} />
          <span>Đang chờ phản hồi</span>
        </Space>
        {LastUpdate}
        {DecisionBy}
      </Space>
    );
  }
  if (decisionCallbackSuccess != null) {
    return (
      <Space direction="vertical">
        <Space>
          <CircleCheck color="#52c41a" size={14} />
          <span>Decision thành công</span>
        </Space>
        {LastUpdate}
        {DecisionBy}
        {confirmDecisionBy}
      </Space>
    );
  }
  return (
    <Space direction="vertical">
      <Space>
        <CircleX color="#db1d1d" size={14} />
        <span>Decision thất bại</span>
      </Space>
      {LastUpdate}
      {DecisionBy}
    </Space>
  );
};

const FwdClaimCaseRightColumn = ({ claimCaseId, closeClaimHistory, showClaimHistory }: { claimCaseId: string; closeClaimHistory: () => void; showClaimHistory: boolean }) => {
  const { user } = useAuth();
  const { claim, fwdClaim, isClaimHistory, updateClaimMutation } = useClaim();
  const {
    adjustClaim,
    confirmDecisionSuccess,
    decisionClaim,
    decisionStatus,
    getAdjustDisabledText,
    getDecisionDisabledText,
    isAdjustable,
    isAnyDecisionCallbackSuccess,
    isAnyDecisionSuccess,
    latestAdjustCallbackLog,
    latestAdjustLog,
    latestDecisionLog,
    latestRegisterLog,
    loading,
    syncLogs,
  } = useFwd();

  const fwdPendingCodes = useFwdPendingCodes("object");
  const [syncType, setSyncType] = useState<FwdSyncTypesEnum>();
  const [modal, contextHolder] = Modal.useModal();

  const registerCallbackLogs = syncLogs?.fwd_claim_cases_by_pk?.registerCallbackLogs;
  const latestRegisterCallbackLog = registerCallbackLogs?.[0];

  if (claim == null || claim.fwd_claim_case === null || isClaimHistory) return null;

  return (
    <Space className={styles.moreDetailContainer} direction="vertical">
      {contextHolder}
      <ClaimAlert />
      <Collapse
        defaultActiveKey={["note", "adjust", "decision"]}
        items={[
          {
            children: (
              <Space direction="vertical" size={5} style={{ width: "100%" }}>
                {(
                  [
                    { key: "related_to_accident", label: "Tai nạn" },
                    { key: "is_jet", label: "Jet" },
                    { key: "challenge_abuse", label: "Challenge" },
                  ] as {
                    key: "challenge_abuse" | "is_jet" | "related_to_accident";
                    label: string;
                  }[]
                ).map((item) => (
                  <Row justify="space-between" key={item.key}>
                    <Col>
                      <label htmlFor={item.key}>
                        <strong>{item.label}</strong>
                      </label>
                    </Col>
                    <Col>
                      <Switch
                        checked={item.key === "related_to_accident" && claim.fwd_claim_case != null ? claim.fwd_claim_case[item.key] : claim[item.key]}
                        disabled={
                          fwdClaim?.updatingFwdClaim === true ||
                          loading ||
                          (item.key === "is_jet" && claim[item.key] === false) ||
                          (item.key === "challenge_abuse" && claim[item.key] === true) ||
                          isClaimHistory
                        }
                        id={item.key}
                        onChange={() => {
                          const currentValue = claim[item.key];
                          if (item.key === "related_to_accident") {
                            Modal.confirm({
                              content: `Bạn có chắc muốn đổi thành ${currentValue === true ? "không " : ""}${item.label}?`,
                              maskClosable: false,
                              okButtonProps: {
                                disabled: loading,
                                loading,
                              },
                              onOk: () => {
                                fwdClaim?.updateFwdClaim({
                                  variables: {
                                    claimId: claimCaseId,
                                    input: {
                                      [item.key]: claim.fwd_claim_case?.[item.key] !== true,
                                    },
                                  },
                                });
                              },
                            });
                            return;
                          }
                          Modal.confirm({
                            content: `Bạn có chắc muốn đổi thành ${currentValue === true ? "Non " : ""}${item.label}?`,
                            maskClosable: false,
                            okButtonProps: {
                              disabled: loading,
                              loading,
                            },
                            onOk: () => {
                              updateClaimMutation({
                                variables: { claimId: claimCaseId, input: { [item.key]: currentValue !== true } },
                              });
                            },
                          });
                        }}
                        title={item.label}
                      />
                    </Col>
                  </Row>
                ))}
              </Space>
            ),
            key: "note",
            label: "Lưu ý",
          },
          {
            children: (
              <div className={styles.moreDetailContainer}>
                <div>
                  <Summary />
                  <WaitingTime claimCase={claim} waitingType={claim.insured_certificate.policy.policy_waiting_times} />
                  <RemarkCopay policyId={claim.insured_certificate.policy.policy_id} remarkCopay={claim.insured_certificate.policy.remark_copay} />
                </div>
                <Modal className={styles.modalContainer} okText="OK" onCancel={closeClaimHistory} open={showClaimHistory} title="Yêu cầu bồi thường">
                  <Outlet />
                </Modal>
              </div>
            ),
            key: "signOff",
            label: (
              <Row align="middle" justify="space-between">
                <Col>Sign Off</Col>
              </Row>
            ),
          },
          {
            children: (
              <>
                <Space size={2}>
                  {latestRegisterLog?.is_success === true ? (
                    <>
                      <CircleCheck color="#52c41a" size={14} /> <span>Register thành công</span>
                    </>
                  ) : (
                    <>
                      <CircleX color="#db1d1d" size={14} /> <span>Register thất bại</span>
                    </>
                  )}
                </Space>
                <Space>
                  <Button
                    disabled={latestRegisterCallbackLog?.is_success === true}
                    onClick={() =>
                      modal.info({
                        closable: true,
                        content: <RegisterForm />,
                        footer: null,
                        icon: <AlertCircle size={16} />,
                        maskClosable: true,
                        styles: {
                          mask: {
                            zIndex: 1000,
                          },
                        },
                        title: "Đăng ký lại claim",
                        width: 800,
                        wrapClassName: "z-1000-important",
                      })
                    }
                    type="primary"
                  >
                    Register
                  </Button>
                  <Button icon={<Info size={16} />} onClick={() => setSyncType("REGISTER")} type="link" />
                </Space>
              </>
            ),
            key: "register",
            label: (
              <div>
                Register Info{" "}
                <If condition={latestRegisterLog?.is_success === true} else={<CircleX color="#db1d1d" size={14} />}>
                  <CircleCheck color="#52c41a" size={14} />
                </If>
              </div>
            ),
          },
          {
            children: (
              <>
                {latestAdjustLog?.is_success === true && (
                  <Space size={2}>
                    <CircleCheck color="#52c41a" size={14} /> <span>Đã gửi adjust</span>
                    <If condition={latestAdjustLog.will_auto_decision}>(sẽ tự decision)</If>
                  </Space>
                )}
                {latestAdjustCallbackLog === undefined ? null : (
                  <Row>
                    {FwdAdjustCallBackLogFieldOrder.map((key) => {
                      if (Array.isArray(latestAdjustCallbackLog.data[key])) {
                        return (
                          <Col key={key} span={24}>
                            <strong>{FwdClaimCaseFieldMapping[key]}</strong>:{" "}
                            <ul>
                              {latestAdjustCallbackLog.data[key].map((item) => (
                                <li key={`${item.followUpCode}-${item.followUpStatus}`}>
                                  {fwdPendingCodes?.[item.followUpCode] ?? item.followUpCode}: {TYPE.VN_PENDING_STATUS_NAME[item.followUpStatus] ?? item.followUpStatus}
                                </li>
                              ))}
                            </ul>
                          </Col>
                        );
                      }

                      return (
                        <Col key={key} span={24}>
                          <strong>{FwdClaimCaseFieldMapping[key]}</strong>:{" "}
                          {Number.isInteger(Number(latestAdjustCallbackLog.data[key]))
                            ? utils.formatCurrency(latestAdjustCallbackLog.data[key])
                            : latestAdjustCallbackLog.data[key]}
                        </Col>
                      );
                    })}
                  </Row>
                )}
                <Space>
                  <Tooltip title={getAdjustDisabledText()}>
                    <Space>
                      <Button disabled={!isAdjustable} loading={loading} onClick={() => adjustClaim()} type="primary">
                        Adjust
                      </Button>
                      <If condition={user.id === "1e6e8ca1-e547-4732-8775-4be2d275e96d"}>
                        <Button disabled={!isAdjustable} loading={loading} onClick={() => adjustClaim(true)} type="primary">
                          Adjust & auto decision
                        </Button>
                      </If>
                    </Space>
                  </Tooltip>
                  <If condition={latestAdjustLog != null}>
                    <Button icon={<Info size={16} />} onClick={() => setSyncType("ADJUST")} type="link" />
                  </If>
                </Space>
              </>
            ),
            key: "adjust",
            label: (
              <div>
                Adjust Info{" "}
                <If condition={latestAdjustLog?.is_success === true} else={<CircleX color="#db1d1d" size={14} />}>
                  <CircleCheck color="#52c41a" size={14} />
                </If>
              </div>
            ),
          },
          {
            children: (
              <>
                <FwdDecisionStatus decisionBy={latestDecisionLog?.user} lastDecisionAt={latestDecisionLog?.created_at} logs={syncLogs?.fwd_claim_cases_by_pk} />
                <ul>
                  {decisionStatus.reasons.map((reason) => (
                    <li key={reason}>{reason}</li>
                  ))}
                </ul>
                <Tooltip title={getDecisionDisabledText()}>
                  <Button disabled={!decisionStatus.isDecidable} loading={loading} onClick={decisionClaim} type="primary">
                    Decision
                  </Button>
                </Tooltip>
                <If condition={isAnyDecisionSuccess && !isAnyDecisionCallbackSuccess}>
                  <Popconfirm onConfirm={() => confirmDecisionSuccess()} title="Bạn xác nhận rằng trạng thái decision của claim này đã được ghi nhận trên LAS?">
                    <Button type="default">
                      <CircleCheck color="#52c41a" size={14} />
                      Kết quả Decision thành công
                    </Button>
                  </Popconfirm>
                </If>
                <If condition={latestDecisionLog != null}>
                  <Button icon={<Info size={16} />} onClick={() => setSyncType("DECISION")} type="link" />
                </If>
              </>
            ),
            key: "decision",
            label: "Decision",
          },
        ]}
      />
      <SyncLogHistory claimCaseId={claimCaseId} onCancel={() => setSyncType(undefined)} open={syncType !== undefined} type={syncType} />
    </Space>
  );
};

export default FwdClaimCaseRightColumn;
