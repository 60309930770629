import { graphql } from "sdk/v2/graphql";

export const UPDATE_PENDING_CODE_MUTATION = graphql(`
  mutation UpdatePendingCode($id: uuid!, $input: claim_pending_codes_set_input!) {
    update_claim_pending_codes_by_pk(pk_columns: { claim_pending_code_id: $id }, _set: $input) {
      id
      __typename
    }
  }
`);
export const REMOVE_CLAIM_PENDING_SAMPLE_DOCUMENT_MUTATION = graphql(`
  mutation RemoveClaimPendingSampleDocument($claimDocumentId: uuid!) {
    update_claim_documents_by_pk(pk_columns: { claim_document_id: $claimDocumentId }, _set: { claim_pending_code_id: null }) {
      id
      claim_document_id
    }
  }
`);
export const INSERT_PENDING_CODE_MUTATION = graphql(`
  mutation InsertPendingCode($input: claim_pending_codes_insert_input!) {
    insert_claim_pending_codes_one(object: $input) {
      id
      claim_pending_code_id
    }
  }
`);
export const UPDATE_SLV_CLAIM_CASE_GROUP_MUTATION = graphql(`
  mutation UpdateSlvClaimCaseGroup($where: slv_claim_case_same_event_groups_bool_exp!, $input: slv_claim_case_same_event_groups_set_input!) {
    update_slv_claim_case_same_event_groups(where: $where, _set: $input) {
      __typename
    }
  }
`);
