import type { Gutter } from "antd/es/grid/row";
import type { AssessmentClaimCaseDetail } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/types";
import type { DateRangeForClaimFragment } from "sdk/gql/graphql";

import { Col, Form, InputNumber, Row } from "antd";
import { utils } from "libs/utils";

const GUTTER: [Gutter, Gutter] = [16, 16];

const SumAssured = ({ claimCaseDetail, selectedPlan }: { claimCaseDetail?: AssessmentClaimCaseDetail; selectedPlan?: DateRangeForClaimFragment }) => (
  <Row gutter={GUTTER}>
    <Col span={12} />
    <Col span={12}>
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item label="Số tiền bảo hiểm">
            <InputNumber
              className="w-100"
              disabled
              formatter={(value) => utils.formatNumber(value)}
              min={0}
              parser={utils.parseNumber}
              size="large"
              value={selectedPlan?.sumAssured ?? 0}
              wheel="false"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Hệ số chi trả">
            <InputNumber
              addonAfter="%"
              className="w-100"
              disabled
              formatter={(value) => utils.formatNumber((value ?? 0) * 100)}
              min={0}
              parser={utils.parseNumber}
              size="large"
              value={claimCaseDetail?.coefficient}
              wheel="false"
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  </Row>
);
export default SumAssured;
