import type {
  InsuranceDeductible,
  InsuranceDeductibleProps,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/InsuranceDeductible";

const INPATIENT_BENEFIT_CODE = "SL_IP_1";
const SURGERY_BENEFIT_CODE = "SL_SG_1";

const slvHiDeductible = ({
  claim,
  claimDetailList,
  currentClaimDetail,
  planInsuredBenefits,
  selectedDateRangeKey,
  selectedPlan,
}: InsuranceDeductibleProps): InsuranceDeductible | null => {
  const relatedToAccident = claim?.slv_claim_case?.related_to_accident ?? false;
  if (relatedToAccident === true) {
    // no limit
    return null;
  }

  const inpatientClaimDetail = claimDetailList?.find(({ key: ccdKey }) => {
    const benefit = planInsuredBenefits?.[selectedPlan?.planId ?? ""]?.find((b) => b.insured_benefit.code === INPATIENT_BENEFIT_CODE);
    return ccdKey === `${selectedDateRangeKey}_${benefit?.id}`;
  });

  const surgeryClaimDetail = claimDetailList?.find(({ key: ccdKey }) => {
    const benefit = planInsuredBenefits?.[selectedPlan?.planId ?? ""]?.find((b) => b.insured_benefit.code === SURGERY_BENEFIT_CODE);
    return ccdKey === `${selectedDateRangeKey}_${benefit?.id}`;
  });
  const currentIsSurgery = currentClaimDetail?.key === surgeryClaimDetail?.key;

  const inpatientAssessedDays = Number(inpatientClaimDetail?.assessedTime);

  const limitPaidTime = inpatientAssessedDays >= 3 || currentIsSurgery ? null : 0;

  return {
    limitPaidTime,
  };
};
export default slvHiDeductible;
