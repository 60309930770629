import type { FormInstance } from "antd";
import type { Gutter } from "antd/es/grid/row";
import type { PlanInsuredBenefitOnClaimDetailFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { JSX } from "react";
import type { DateRangeForClaimFragment } from "sdk/gql/graphql";

import { Button, Col, Divider, Form, InputNumber, Modal, Popconfirm, Row, Space, Tag, Tooltip, Typography } from "antd";
import { DatePicker } from "app/common/components/CustomAntdTimePickers";
import If from "app/common/components/If";
import WandIcon from "app/common/components/WandIcon";
import SumAssured from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/ClaimBenefitVer3/components/SumAssured";
import getInsuranceDeductible from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/InsuranceDeductible";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { CLAIM_DECISION_STATUSES, FORMATTER } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { addDays, differenceInDays, endOfDay, isAfter, isBefore, startOfDay, subDays } from "date-fns";
import dedent from "dedent";
import { getZeroOrNumber } from "libs/hidash";
import utils from "libs/utils";
import { min } from "lodash";
import sum from "lodash/sum";
import { CircleHelp, Info, Save, Trash2 } from "lucide-react";
import { useCallback, useEffect } from "react";

import type { AssessmentClaimCaseDetail, AssessmentForm } from "../types";

import styles from "./ClaimBenefitVer3.module.css";
import CoverageBalanceCard from "./components/CoverageBalanceCard";
import TotalPaidAmountDetail from "./components/TotalPaidAmountDetail";
import { calcPayableAmount, calculatePaidTime, calculatePayableAmountForClaimCaseDetail, getDisplayFields } from "./helpers";

const GUTTER: [Gutter, Gutter] = [16, 16];
const NoResultText = "Chưa có kết quả";

type Props = {
  claimCaseDetail?: AssessmentClaimCaseDetail;
  claimCaseDetailActions: {
    delete: () => void;
    save: () => void;
  };
  claimCaseDetailActionsLoading: {
    delete: boolean;
    save: boolean;
  };
  claimCaseDetailOrder: number;
  currentPlanBenefitIds: Record<string, string>;
  deductibleAmountOnClaimDetail?: number;
  error: {
    balanceId: string;
    benefitId: string;
  } | null;
  form: FormInstance<AssessmentForm>;
  lockButton: JSX.Element;
  planInsuredBenefits?: { [key: string]: PlanInsuredBenefitOnClaimDetailFragment[] } | null;
  selectedDateRangeKey: string;
  selectedPlan?: DateRangeForClaimFragment;
};

const ClaimCaseDetail = ({
  claimCaseDetail,
  claimCaseDetailActions,
  claimCaseDetailActionsLoading,
  claimCaseDetailOrder,
  currentPlanBenefitIds,
  deductibleAmountOnClaimDetail,
  error,
  form,
  lockButton,
  planInsuredBenefits,
  selectedDateRangeKey,
  selectedPlan,
}: Props) => {
  const { hasRole } = useAuth();
  const {
    claim,
    claimDateRanges,
    claimType,
    copayAndBonus: { copayRatio, doesPlanHaveCopay },
  } = useClaim();
  const amountPerDay = (() => {
    const formulaType = claimCaseDetail?.formulaType;
    const coefficient = claimCaseDetail?.coefficient;
    if (formulaType == null) {
      // không có số tiền theo ngày
      return null;
    }

    return (selectedPlan?.sumAssured ?? 0) * (coefficient ?? 1);
  })();
  const payableBenefit = calculatePayableAmountForClaimCaseDetail({
    amountPerDay,
    claimCaseDetail,
    claimStatus: claim?.status,
    policySumAssured: claim?.insured_certificate.policy.sum_assured,
  });

  const showDeleteButton = () => hasRole(["ClaimAssessorManager"]) || CLAIM_DECISION_STATUSES.includes(claim?.status ?? "") === false;
  const canEdit = currentPlanBenefitIds[selectedDateRangeKey] === claimCaseDetail?.key;
  const displayFields = getDisplayFields(claimCaseDetail, claimType, claim?.benefit_type);
  const showAssessedTime = claimType.slvHs === true;
  const selectedDateRanges = claimDateRanges?.claim_date_ranges.ranges.find((p) => p.key === selectedDateRangeKey);
  const watchTotalRequestAmount = Form.useWatch(["claimCaseDetails", claimCaseDetailOrder, "totalRequestAmount"], form);
  const watchClaimDetails = Form.useWatch(["claimCaseDetails"], form);

  const disabledDateCheck = (current: Date) =>
    (selectedDateRanges?.endDate != null && isAfter(startOfDay(current), addDays(new Date(selectedDateRanges.endDate), 1))) ||
    (claim?.discharge_date != null && isAfter(startOfDay(current), addDays(new Date(claim.discharge_date), 1))) ||
    (selectedDateRanges?.startDate != null && isBefore(endOfDay(current), subDays(new Date(selectedDateRanges.startDate), 1))) ||
    (claim?.admission_date != null && isBefore(endOfDay(current), subDays(new Date(claim.admission_date), 1)));
  const calculatedPaidAmounts = calcPayableAmount({
    caller: "ClaimCaseDetail",
    claimCaseDetail,
    claimType,
    copayRatio,
    deductibleAmount: deductibleAmountOnClaimDetail,
    nonPaidAmount: claimCaseDetail?.nonPaidAmount ?? 0,
    payableBenefit,
    totalRequestAmount: claimCaseDetail?.totalRequestAmount ?? 0,
  });
  const calculatePaidAmounts = useCallback(
    (manualInputClaimCaseDetail?: { paidTime?: null | number }) => {
      if (claimCaseDetail == null) {
        return;
      }
      const { copayAmount, coverageAmount, deductibleAmount, expectedPayableAmount, shortfallAmount, totalPayableAmount: totalPaidAmount } = calculatedPaidAmounts;
      const filledTotalPaidAmount = (() => {
        if (amountPerDay == null) {
          return totalPaidAmount > 0 ? totalPaidAmount : 0;
        }
        return amountPerDay * (manualInputClaimCaseDetail?.paidTime ?? claimCaseDetail.paidTime ?? 0);
      })();

      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalPaidAmountBeforeCopay"], expectedPayableAmount);
      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "coverageAmount"], coverageAmount);
      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "copayAmount"], copayAmount);
      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalPaidAmount"], filledTotalPaidAmount);
      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "shortfallAmount"], shortfallAmount);
      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "deductibleAmount"], deductibleAmount);
    },
    [calculatedPaidAmounts, claimCaseDetail, claimCaseDetailOrder, form, amountPerDay],
  );

  useEffect(() => {
    const totalRequestAmount = claimCaseDetail?.totalRequestAmount;
    if (
      claimCaseDetail?.isMagic === true &&
      totalRequestAmount != null &&
      totalRequestAmount > 0 &&
      claim?.status != null &&
      CLAIM_DECISION_STATUSES.includes(claim.status) === false
    ) {
      calculatePaidAmounts();
    }
  }, [calculatePaidAmounts, claimCaseDetail?.totalRequestAmount, claimCaseDetail?.isMagic, claim?.status]);

  const hasDecimal = (claimCaseDetail?.totalPaidAmount ?? 0) - Math.floor(claimCaseDetail?.totalPaidAmount ?? 0) > 0;

  useEffect(() => {
    if (watchTotalRequestAmount > 0 && claimCaseDetail?.isMagic === true && claimCaseDetail.claimCaseDetailId == null) {
      form.getFieldInstance(["claimCaseDetails", claimCaseDetailOrder, "nonPaidAmount"])?.focus();
    }
  }, [watchTotalRequestAmount, claimCaseDetail?.isMagic, claimCaseDetail?.claimCaseDetailId, form, claimCaseDetailOrder]);

  const totalRequestAmountRemaining = (() => {
    if (claim?.request_amount == null || watchClaimDetails == null) return 0;
    return claim.request_amount - sum(watchClaimDetails.map((ccd) => ccd.requestAmount ?? 0));
  })();

  const insuranceDeductibleLimit = getInsuranceDeductible({
    claim,
    claimDetailList: watchClaimDetails,
    currentClaimDetail: claimCaseDetail,
    planInsuredBenefits,
    selectedDateRangeKey,
    selectedPlan,
  });
  const paidTimePreference = calculatePaidTime({
    insuranceDeductibleLimit,
  });

  return (
    <Row gutter={GUTTER}>
      {claimCaseDetail?.balanceDetails?.map((balanceDetail) => (
        <Col key={balanceDetail.id} span={6}>
          <CoverageBalanceCard
            balanceDetail={balanceDetail}
            error={error}
            planInsuredBenefitKey={claimCaseDetail.planInsuredBenefitId}
            policySumAssured={claim?.insured_certificate.policy.sum_assured}
          />
        </Col>
      ))}
      <Col span={24}>
        <If condition={amountPerDay != null}>
          <SumAssured claimCaseDetail={claimCaseDetail} selectedPlan={selectedPlan} />
        </If>

        {/* BEGINS percentage */}
        <If condition={displayFields.request.displayPercentage}>
          <Divider />

          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item label="Phần trăm yêu cầu" name={[claimCaseDetailOrder, "requestPercentage"]}>
                <InputNumber className="w-100" max={100} min={0} size="large" suffix="%" wheel="false" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phần trăm chi trả" name={[claimCaseDetailOrder, "paidPercentage"]}>
                <InputNumber className="w-100" max={100} min={0} size="large" suffix="%" wheel="false" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <Space>
                        <div>
                          Tổng số tiền yêu cầu <span className="color-red">*</span>
                        </div>
                      </Space>
                    }
                    name={[claimCaseDetailOrder, "totalRequestAmount"]}
                  >
                    <InputNumber
                      autoFocus
                      className="w-100"
                      formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                      min={0}
                      // disabled
                      onBlur={() => calculatePaidAmounts()}
                      onInput={() => form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "isMagic"], false)}
                      parser={utils.parseNumber}
                      placeholder="Nhập số tiền yêu cầu"
                      size="large"
                      wheel="false"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item label={<div>Số tiền thực trả </div>} name={[claimCaseDetailOrder, "totalPaidAmount"]}>
                    <InputNumber
                      className="w-100"
                      formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                      min={0}
                      parser={utils.parseNumber}
                      placeholder="Nhập số tiền chi trả"
                      size="large"
                      wheel="false"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </If>
        {/* ENDS percentage */}
        {/* BEGINS amount */}
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item
                  hidden={displayFields.request.displayAmount === false}
                  label={
                    <Space>
                      <div>
                        Tổng số tiền yêu cầu <span className="color-red">*</span>
                      </div>
                      {claimCaseDetail?.claimCaseDetailId == null ? (
                        <Tooltip
                          title={dedent`
                            Số tiền yêu cầu còn lại (= tổng tiền yêu cầu - số tiền yêu cầu đã nhập vào tất cả QL khác).
                            ${utils.formatCurrency(totalRequestAmountRemaining)} = ${utils.formatCurrency(claim?.request_amount)} - ${utils.formatCurrency(
                              sum(watchClaimDetails?.map((ccd) => ccd.requestAmount ?? 0)),
                            )}
                          `}
                        >
                          <Tag
                            bordered={false}
                            className="cursor-pointer"
                            color="success"
                            icon={<WandIcon size={12} />}
                            onClick={() => {
                              form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalRequestAmount"], totalRequestAmountRemaining);
                              setTimeout(() => {
                                form.getFieldInstance(["claimCaseDetails", claimCaseDetailOrder, "nonPaidAmount"])?.focus();
                              }, 500);
                            }}
                          >
                            {utils.formatCurrency(totalRequestAmountRemaining)}
                          </Tag>
                        </Tooltip>
                      ) : null}
                    </Space>
                  }
                  name={[claimCaseDetailOrder, "totalRequestAmount"]}
                >
                  <InputNumber
                    autoFocus
                    className="w-100"
                    disabled={!canEdit}
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    onBlur={() => calculatePaidAmounts()}
                    onInput={() => form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "isMagic"], false)}
                    parser={utils.parseNumber}
                    placeholder="Nhập số tiền yêu cầu"
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hidden={displayFields.request.displayAmount === false}
                  label={
                    <div>
                      Số tiền không chi trả (Số tiền ngoài phạm vi bảo hiểm) <span className="color-red">*</span>{" "}
                    </div>
                  }
                  name={[claimCaseDetailOrder, "nonPaidAmount"]}
                >
                  <InputNumber
                    className="w-100"
                    disabled={!canEdit}
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    onBlur={() => calculatePaidAmounts()}
                    onChange={() => {
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "isMagic"], false);
                    }}
                    parser={utils.parseNumber}
                    placeholder="Nhập số tiền không chi trả (nằm ngoài phạm vi bảo hiểm)"
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24} />
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item hidden={displayFields.paid.displayAmount === false} label="Số tiền trong phạm vi bảo hiểm" name={[claimCaseDetailOrder, "coverageAmount"]}>
                  <InputNumber
                    className="w-100"
                    disabled={!canEdit}
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    onBlur={() => calculatePaidAmounts()}
                    onChange={(value) => {
                      if (value == null) {
                        return;
                      }
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "nonPaidAmount"], (claimCaseDetail?.totalRequestAmount ?? 0) - value);
                    }}
                    parser={utils.parseNumber}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hidden={displayFields.request.displayAmount === false} label={<div>Số tiền vượt hạn mức</div>} name={[claimCaseDetailOrder, "shortfallAmount"]}>
                  <InputNumber
                    className="w-100"
                    disabled
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    parser={utils.parseNumber}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24} />
            </Row>
          </Col>
        </Row>
        {/* ENDS amount */}
        {/* BEGINS date range */}
        <If condition={displayFields.request.displayDateRange}>
          <Divider />

          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item label="Khoảng ngày yêu cầu" name={[claimCaseDetailOrder, "requestDateRange"]}>
                <DatePicker.RangePicker
                  className="w-100"
                  disabled={!canEdit}
                  disabledDate={disabledDateCheck}
                  format={FORMATTER.DATE_FORMAT}
                  onChange={(values) => {
                    const from = values[0];
                    const to = values[1];
                    if (from != null && to != null) {
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "requestFromDate"], from);
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "requestToDate"], to);
                      const diffDateRange = differenceInDays(to, from);
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "requestTime"], diffDateRange);
                      if (amountPerDay != null) {
                        form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalRequestAmount"], amountPerDay * diffDateRange);
                      }
                    }
                  }}
                  picker="date"
                  placeholder={["Từ ngày", "Đến ngày"]}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Khoảng ngày chi trả" name={[claimCaseDetailOrder, "paidDateRange"]}>
                <DatePicker.RangePicker
                  className="w-100"
                  disabled={!canEdit}
                  disabledDate={disabledDateCheck}
                  format={FORMATTER.DATE_FORMAT}
                  onChange={(values) => {
                    const from = values[0];
                    const to = values[1];
                    if (from != null && to != null) {
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "paidFromDate"], from);
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "paidToDate"], to);
                      const diffDateRange = differenceInDays(to, from);
                      const filledPaidTime = min([diffDateRange, paidTimePreference.paidTime]);
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "paidTime"], filledPaidTime);
                      if (amountPerDay != null) {
                        form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalPaidAmount"], amountPerDay * diffDateRange);
                      }
                    }
                  }}
                  picker="date"
                  placeholder={["Từ ngày", "Đến ngày"]}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
        </If>
        {/* ENDS date range */}
        {/* BEGINS date */}
        <If condition={displayFields.request.displayDateRange || displayFields.request.hasDayBenefit || displayFields.request.hasTimeBenefit}>
          <Divider />
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                label={
                  <div>
                    Số {displayFields.request.displayUnitName} yêu cầu <span className="color-red">*</span>
                  </div>
                }
                name={[claimCaseDetailOrder, "requestTime"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (claimType.slvHs === true && Number.isInteger(value) === false) {
                        return Promise.reject(new Error("Số ngày phải là số nguyên cho claim của Sun Life"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  className="w-100"
                  disabled={!canEdit}
                  min={0}
                  onBlur={() => {
                    form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "assessedTime"], payableBenefit?.paidTime);
                    form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "paidTime"], payableBenefit?.paidTime);
                    calculatePaidAmounts({
                      paidTime: payableBenefit?.paidTime,
                    });
                  }}
                  onChange={(value) => {
                    if (amountPerDay != null && value != null) {
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalRequestAmount"], amountPerDay * value);
                    }
                  }}
                  placeholder={`Nhập số ${displayFields.request.displayUnitName} yêu cầu`}
                  size="large"
                  wheel="false"
                />
              </Form.Item>
            </Col>
            <If condition={showAssessedTime}>
              <Col span={12}>
                <Form.Item
                  hidden={showAssessedTime === false}
                  label={<div>Số {displayFields.paid.displayUnitName} dự chi</div>}
                  name={[claimCaseDetailOrder, "assessedTime"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (error?.benefitId === claimCaseDetail?.key) {
                          return Promise.reject(new Error(`Sai số ${displayFields.paid.displayUnitName} chi trả hãy kiểm tra lại`));
                        }
                        const dayLimit = min([
                          payableBenefit?.daysPerYearBalanceRemaining,
                          payableBenefit?.daysPerYearBalanceRemainingBuffer,
                          payableBenefit?.daysPerTimeBalanceRemaining,
                          payableBenefit?.daysPerTimeBalanceRemainingBuffer,
                        ]);
                        if (value != null && dayLimit != null && value > dayLimit) {
                          return Promise.reject(new Error(`Số ${displayFields.paid.displayUnitName} chi trả lớn hơn hạn mức còn lại.`));
                        }
                        if (claimType.slvHs === true && Number.isInteger(value) === false) {
                          return Promise.reject(new Error("Số ngày phải là số nguyên cho claim của Sun Life"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    className={styles.inputNumber}
                    disabled={!canEdit}
                    min={0}
                    name="assessedTime"
                    onBlur={() => {
                      const filledPaidTime = payableBenefit?.paidTime == null ? null : min([payableBenefit.paidTime, paidTimePreference.paidTime, claimCaseDetail?.assessedTime]);
                      if (filledPaidTime != null && amountPerDay != null) {
                        form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "paidTime"], filledPaidTime);
                        form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalPaidAmount"], amountPerDay * filledPaidTime);
                      }
                    }}
                    placeholder={`Nhập số ${displayFields.paid.displayUnitName} dự chi`}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
            </If>
            <If condition={showAssessedTime}>
              <Col span={12} />
            </If>
            <Col span={12}>
              <Form.Item
                label={<div>Số {displayFields.paid.displayUnitName} chi trả</div>}
                name={[claimCaseDetailOrder, "paidTime"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (error?.benefitId === claimCaseDetail?.key) {
                        return Promise.reject(new Error(`Sai số ${displayFields.paid.displayUnitName} chi trả hãy kiểm tra lại`));
                      }
                      const dayLimit = min([
                        payableBenefit?.daysPerYearBalanceRemaining,
                        payableBenefit?.daysPerYearBalanceRemainingBuffer,
                        payableBenefit?.daysPerTimeBalanceRemaining,
                        payableBenefit?.daysPerTimeBalanceRemainingBuffer,
                      ]);
                      if (value != null && dayLimit != null && value > dayLimit) {
                        return Promise.reject(new Error(`Số ${displayFields.paid.displayUnitName} chi trả lớn hơn hạn mức còn lại.`));
                      }
                      if (claimType.slvHs === true && Number.isInteger(value) === false) {
                        return Promise.reject(new Error("Số ngày phải là số nguyên cho claim của Sun Life"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  className={styles.inputNumber}
                  disabled={!canEdit}
                  min={0}
                  name="paidTime"
                  onChange={(value) => {
                    if (amountPerDay != null && value != null) {
                      form.setFieldValue(["claimCaseDetails", claimCaseDetailOrder, "totalPaidAmount"], amountPerDay * value);
                    }
                  }}
                  placeholder={`Nhập số ${displayFields.paid.displayUnitName} chi trả`}
                  size="large"
                  wheel="false"
                />
              </Form.Item>
            </Col>
          </Row>
        </If>
        {/* ENDS date */}
        {/* BEGINS per date */}
        <If condition={displayFields.request.displayDateRange || displayFields.request.hasDayBenefit || displayFields.request.hasTimeBenefit}>
          <Row gutter={GUTTER}>
            {claimCaseDetail?.totalRequestAmount != null && (
              <Col span={12}>
                <Space direction="vertical">
                  <Typography.Paragraph onClick={() => console.info(claimCaseDetail)} type="secondary">
                    Số tiền yêu cầu theo {displayFields.request.displayUnitName}
                  </Typography.Paragraph>
                  {claimCaseDetail.formulaType == null ? (
                    <Typography.Title className="mt-0" level={5}>
                      {claimCaseDetail.requestTime != null && claimCaseDetail.requestTime >= 1 && (
                        <Tooltip title={`claimCaseDetail.totalRequestAmount = ${claimCaseDetail.totalRequestAmount}/${claimCaseDetail.requestTime}`}>
                          {utils.formatNumber(claimCaseDetail.totalRequestAmount / claimCaseDetail.requestTime, false)} đ/{displayFields.request.displayUnitName}
                        </Tooltip>
                      )}
                      {claimCaseDetail.requestTime != null && claimCaseDetail.requestTime > 0 && claimCaseDetail.requestTime < 1 && (
                        <Tooltip title={`claimCaseDetail.totalRequestAmount = ${claimCaseDetail.totalRequestAmount}/${claimCaseDetail.requestTime}`}>
                          {utils.formatNumber(claimCaseDetail.totalRequestAmount * claimCaseDetail.requestTime, false)} đ/{displayFields.request.displayUnitName}
                        </Tooltip>
                      )}
                    </Typography.Title>
                  ) : (
                    <Typography.Title className="mt-0" level={5}>
                      {utils.formatNumber((selectedPlan?.sumAssured ?? 0) * (claimCaseDetail.coefficient ?? 1), false)} đ/{displayFields.request.displayUnitName}
                    </Typography.Title>
                  )}
                </Space>
              </Col>
            )}
            {claimCaseDetail?.coverageAmount != null && (
              <Col span={12}>
                <Space direction="vertical">
                  <Typography.Paragraph type="secondary">Số tiền chi trả theo {displayFields.paid.displayUnitName}</Typography.Paragraph>
                  {claimCaseDetail.formulaType == null ? (
                    <Typography.Title className="mt-0" level={5}>
                      {claimCaseDetail.paidTime != null && claimCaseDetail.paidTime >= 1 && (
                        <Tooltip title={`claimCaseDetail.totalPaidAmount = ${claimCaseDetail.totalPaidAmount}/${claimCaseDetail.paidTime}`}>
                          {utils.formatNumber(getZeroOrNumber(claimCaseDetail.totalPaidAmount) / claimCaseDetail.paidTime, true)} đ/{displayFields.paid.displayUnitName}
                        </Tooltip>
                      )}
                      {claimCaseDetail.paidTime != null && claimCaseDetail.paidTime > 0 && claimCaseDetail.paidTime < 1 && (
                        <Tooltip title={`claimCaseDetail.totalPaidAmount = ${claimCaseDetail.totalPaidAmount}/${claimCaseDetail.paidTime}`}>
                          {utils.formatNumber(claimCaseDetail.coverageAmount * claimCaseDetail.paidTime, true)} đ/{displayFields.paid.displayUnitName}
                        </Tooltip>
                      )}
                    </Typography.Title>
                  ) : (
                    <Typography.Title className="mt-0" level={5}>
                      {utils.formatNumber((selectedPlan?.sumAssured ?? 0) * (claimCaseDetail.coefficient ?? 1), true)} đ/{displayFields.paid.displayUnitName}
                    </Typography.Title>
                  )}
                </Space>
              </Col>
            )}
          </Row>
        </If>
        {/* ENDS per date */}
        <Divider />
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Row>
              <Col span={24} />
              <Col span={24} />
              <Col span={24} />
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Form.Item hidden={displayFields.paid.displayAmount === false} label="Số tiền trước copay" name={[claimCaseDetailOrder, "totalPaidAmountBeforeCopay"]}>
                  <InputNumber
                    className="w-100"
                    disabled
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    parser={utils.parseNumber}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hidden={displayFields.paid.displayAmount === false} label="Số tiền copay" name={[claimCaseDetailOrder, "copayAmount"]}>
                  <InputNumber
                    className="w-100"
                    disabled
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    parser={utils.parseNumber}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hidden={displayFields.request.displayAmount === false} label={<div>Số tiền khấu trừ</div>} name={[claimCaseDetailOrder, "deductibleAmount"]}>
                  <InputNumber
                    className="w-100"
                    disabled
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    parser={utils.parseNumber}
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hidden={displayFields.paid.displayAmount === false}
                  label={
                    <div>
                      Số tiền thực trả{" "}
                      <Tooltip title="Chi tiết tính toán">
                        <CircleHelp
                          cursor="pointer"
                          onClick={() => {
                            Modal.info({
                              content: (
                                <TotalPaidAmountDetail
                                  calculatedPaidAmounts={calculatedPaidAmounts}
                                  claim={claim}
                                  claimCaseDetail={claimCaseDetail}
                                  claimType={claimType}
                                  paidBenefit={payableBenefit}
                                />
                              ),
                              maskClosable: true,
                              title: "Số tiền thực trả",
                              width: 500,
                            });
                          }}
                          size={14}
                        />
                      </Tooltip>{" "}
                    </div>
                  }
                  name={[claimCaseDetailOrder, "totalPaidAmount"]}
                >
                  <InputNumber
                    className="w-100"
                    disabled={!canEdit}
                    formatter={(value) => (value == null ? "" : utils.formatNumber(value))}
                    min={0}
                    parser={utils.parseNumber}
                    placeholder="Nhập số tiền chi trả"
                    size="large"
                    wheel="false"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* BEGINS summary & buttons */}
        <Row className="mt-24" gutter={GUTTER} justify="end">
          <Col span={12} />
          {/* summary */}
          <Col span={12}>
            {claimCaseDetail?.formulaType == null && (
              <Row className={styles.stats}>
                <Col span={18}>Tổng số tiền yêu cầu</Col>
                <Col span={6}>{claimCaseDetail?.totalRequestAmount == null ? NoResultText : utils.formatCurrency(claimCaseDetail.totalRequestAmount)}</Col>
              </Row>
            )}
            {claimCaseDetail?.formulaType == null && (
              <Row className={styles.stats}>
                <Col span={18}>Không chi trả - ngoài phạm vi BH</Col>
                <Col span={6}>{claimCaseDetail?.nonPaidAmount == null ? NoResultText : utils.formatCurrency(0 - claimCaseDetail.nonPaidAmount)}</Col>
              </Row>
            )}
            <Row className={styles.stats}>
              <Col span={18}>Không chi trả - vượt hạn mức</Col>
              <Col span={6}>{claimCaseDetail?.shortfallAmount == null ? NoResultText : utils.formatCurrency(0 - claimCaseDetail.shortfallAmount)}</Col>
            </Row>
            <Row className={styles.stats}>
              <Col span={18}>Số tiền trong phạm vi bảo hiểm</Col>
              <Col span={6}>{claimCaseDetail?.coverageAmount == null ? NoResultText : utils.formatCurrency(claimCaseDetail.coverageAmount)}</Col>
            </Row>
            <If condition={doesPlanHaveCopay}>
              <Row className={styles.stats}>
                <Col span={18}>Đồng chi trả</Col>
                <Col span={6}>{claimCaseDetail?.copayAmount === undefined ? NoResultText : utils.formatCurrency(0 - claimCaseDetail.copayAmount)}</Col>
              </Row>
            </If>
            <If condition={claimCaseDetail?.deductibleAmount != null}>
              <Row className={styles.stats}>
                <Col span={18}>Khấu trừ</Col>
                <Col span={6}>{claimCaseDetail?.deductibleAmount == null ? NoResultText : utils.formatCurrency(0 - claimCaseDetail.deductibleAmount)}</Col>
              </Row>
            </If>
            <Row className={styles.stats}>
              <Col span={18}>Số tiền thực trả </Col>
              <Col span={6}>
                <If condition={hasDecimal}>
                  <Tooltip title="Số tiền chi trả lẻ ở quyền lợi sẽ không được làm tròn. Số tiền này chỉ được làm tròn khi tính tổng tiền chi trả để bảo đảm chỉ làm tròn lên 1đ.">
                    <Info size={12} />{" "}
                  </Tooltip>
                </If>
                {claimCaseDetail?.totalPaidAmount == null ? NoResultText : utils.formatCurrency(claimCaseDetail.totalPaidAmount)}
              </Col>
            </Row>
          </Col>
          {/* buttons */}
          <Col span={24}>
            <Row gutter={GUTTER}>
              <Col span={24}>
                <Row gutter={GUTTER} justify="end">
                  <Col>{lockButton}</Col>
                  <Col>
                    <Button
                      disabled={!canEdit || claimCaseDetailActionsLoading.save}
                      icon={<Save size={14} />}
                      loading={claimCaseDetailActionsLoading.save}
                      onClick={claimCaseDetailActions.save}
                      type="primary"
                    >
                      Lưu
                    </Button>
                  </Col>
                  <Col>
                    <If condition={showDeleteButton()}>
                      <Popconfirm onConfirm={claimCaseDetailActions.delete} title="Bạn có chắc muốn xóa quyền lợi?">
                        <Button danger icon={<Trash2 size={14} />} loading={claimCaseDetailActionsLoading.delete} type="primary">
                          Xóa
                        </Button>
                      </Popconfirm>
                    </If>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ENDS summary & buttons */}
      </Col>
    </Row>
  );
};

export default ClaimCaseDetail;
