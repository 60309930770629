import { Badge, Empty, Space, Tooltip } from "antd";
import BenefitTag from "app/common/components/BenefitTagV3";
import ClaimIcons from "app/common/components/ClaimIcons";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import If from "app/common/components/If";
import TableList from "app/common/components/TableListV2";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { utils } from "libs/utils";
import { uniqBy } from "lodash";
import { graphql } from "sdk/v2/graphql";

import styles from "./ClaimHistory.module.css";
import treatmentDays from "./treatmentDays";

const HistoryClaimCaseFragment = graphql(`
  fragment HistoryClaimCase on claim_cases {
    id
    admission_date
    amount
    benefit_type
    challenge_abuse
    claim_case_id
    code
    diagnosis
    discharge_date
    event_date
    is_accessible
    is_direct_billing
    is_jet
    medical_provider_name
    physical_examination_date
    source
    start_date
    status
    stp_status
    tat_in_seconds
    created_at
    action_logs(order_by: { created_at: desc }) {
      id
      new_value
      old_value
      created_at
    }
    claim_case_assessed_diagnoses(order_by: { order: asc_nulls_last }) {
      id
      icd {
        id
        title
        value
      }
    }
    claim_case_details_aggregate {
      aggregate {
        sum {
          total_paid_amount
        }
      }
    }
    claim_case_payment {
      id
      actual_paid_amount
      applied_premium_debt_amount
    }
    claim_case_status {
      id: value
      comment
      value
    }
    claim_documents(order_by: { created_at: desc }) {
      id
      type
      created_at
      claim_document_type {
        id: value
        value
      }
    }
    claim_source {
      id: value
      comment
      value
    }
    insured_benefit_type {
      id: value
      comment
      value
    }
    insured_certificate {
      id
      insured_person {
        id
        labels {
          id
          label {
            id
            name
            color
            description
          }
        }
      }
      policy {
        id
        dued_at
        issued_at
        policy_number
        insurer_company {
          id
          company_id
        }
        policy_setting {
          id
          tat_max
          turn_around_time_commitment
        }
      }
    }
    labels {
      id
      label {
        id
        name
        color
        description
      }
    }
    medical_provider {
      id
      name
    }
    source_emails(where: { email: { delivery_type: { _neq: ASSESSMENT_CORRESPONDENCE } } }) {
      id
      email_id
    }
  }
`);

export const HistoryClaimCasesQuery = graphql(
  `
    query HistoryClaimCases($where: claim_cases_bool_exp!, $certificateId: uuid, $noCertificateCourse: Boolean!) {
      claim_cases(where: $where, order_by: { start_date: desc }) {
        id
        ...HistoryClaimCase
      }
      insured_certificate_courses(where: { from_insured_certificate: { insured_certificate_id: { _eq: $certificateId } } }) @skip(if: $noCertificateCourse) {
        id
        ordinal_number
        from_insured_certificate {
          id
          dued_at
          claim_cases(order_by: { start_date: desc }) {
            id
            ...HistoryClaimCase
          }
        }
      }
    }
  `,
  [HistoryClaimCaseFragment],
);

const ClaimHistory = () => {
  const { claim } = useClaim();

  const { data } = usePQuery(
    HistoryClaimCasesQuery,
    claim?.id == null && claim?.insured_certificate.from_insured_certificate_courses[0]?.from_insured_certificate.id == null
      ? { skip: true }
      : {
          variables: {
            certificateId: claim.insured_certificate.from_insured_certificate_courses[0]?.from_insured_certificate.id,
            noCertificateCourse: claim.insured_certificate.from_insured_certificate_courses.length === 0,
            where: {
              claim_case_id: {
                _neq: claim.id,
              },
              insured_certificate: {
                insured_person: {
                  id: {
                    _eq: claim.insured_certificate.insured_person.id,
                  },
                },
              },
            },
          },
        },
  );

  const claimFromOldPolicies = data?.insured_certificate_courses?.flatMap((c) => c.from_insured_certificate.claim_cases.map((c2) => ({ ...c2, isOldPolicy: true }))) ?? [];

  const claimCases = uniqBy(
    [...(data?.claim_cases ?? []), ...claimFromOldPolicies].map((c) => ({ ...c, isOldPolicy: claimFromOldPolicies.some((c2) => c.id === c2.id) })),
    "id",
  );
  if (claim == null) return null;
  if (claimCases.length === 0) return <Empty description="Không có dữ liệu" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  return (
    <TableList
      data={claimCases}
      hideWhenEmpty
      nativeScroll={{ x: 1000 }}
      schema={[
        {
          dataIndex: "code",
          fixed: "left",
          render: (text, record) => {
            const policyIssuedAt = utils.formatDate(record.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT);
            const policyDuedAt = utils.formatDate(record.insured_certificate.policy.dued_at, FORMATTER.DATE_FORMAT);
            const eventDates = treatmentDays(record);
            return (
              <If
                condition={eventDates >= policyIssuedAt || eventDates <= policyDuedAt}
                else={
                  <Space>
                    <Tooltip
                      color="white"
                      overlayInnerStyle={{ borderRadius: 8, color: "black" }}
                      overlayStyle={{ whiteSpace: "pre-line" }}
                      placement="bottomLeft"
                      title={`Hợp đồng đã hết hiệu lực \n ${utils.formatDate(record.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT)} -  ${utils.formatDate(
                        record.insured_certificate.policy.dued_at,
                        FORMATTER.DATE_FORMAT,
                      )}`}
                    >
                      <div className={styles.coverageLapsedDate} />
                    </Tooltip>
                    <ClaimIcons claim={record} newTab showLALabels={false} />
                  </Space>
                }
              >
                <Space>
                  <Tooltip
                    color="white"
                    overlayInnerStyle={{ borderRadius: 8, color: "black" }}
                    overlayStyle={{ whiteSpace: "pre-line" }}
                    placement="bottomLeft"
                    title={`Hợp đồng đang hiệu lực \n ${utils.formatDate(record.insured_certificate.policy.issued_at, FORMATTER.DATE_FORMAT)} -  ${utils.formatDate(
                      record.insured_certificate.policy.dued_at,
                      FORMATTER.DATE_FORMAT,
                    )}`}
                  >
                    <div className={styles.coverageEffectiveDate} />
                  </Tooltip>
                  <ClaimIcons claim={record} newTab showLALabels={false} />
                </Space>
              </If>
            );
          },
          title: "Mã Claim",
          width: 200,
        },
        {
          dataIndex: ["insured_certificate", "policy", "policy_number"],
          render: (value, record) => {
            if (record.isOldPolicy === true) {
              return (
                <Badge.Ribbon color="pink" text="hđ cũ">
                  <div>{value}</div>
                </Badge.Ribbon>
              );
            }
            return <div>{value}</div>;
          },
          title: "Hợp đồng",
          width: 180,
        },
        {
          key: "place_of_treatment",
          render: (record) => record.medical_provider?.name ?? record.medical_provider_name,
          title: "CSYT",
          width: 180,
        },

        {
          key: "event_date",
          render: (_, record) => treatmentDays(record),
          title: "Ngày xảy ra sự kiện",
          width: 180,
        },
        {
          render: (_, record) => record.claim_case_assessed_diagnoses.map((item) => item.icd.value).join("|"),
          title: "ICD",
          width: 100,
        },
        {
          dataIndex: ["diagnosis"],
          title: "Chẩn đoán",
          width: 230,
        },
        {
          dataIndex: ["claim_case_details"],
          render: (_, record) => {
            if (!["Approved", "ApprovedReceivedDoc", "ApprovedWaitingDoc", "Paid"].includes(record.status)) {
              return "--";
            }
            if (record.source === "FWD_HISTORY") {
              return utils.formatCurrency(record.amount);
            }
            // TODO: remove this condition after all claim cases contain record.claim_case_payment?.actual_paid_amount
            if (record.claim_case_payment?.actual_paid_amount !== record.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount) {
              return utils.formatCurrency(record.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount);
            }
            return utils.formatCurrency(record.claim_case_payment?.actual_paid_amount ?? record.claim_case_details_aggregate.aggregate?.sum?.total_paid_amount);
          },
          title: "Số tiền chi trả",
          width: 130,
        },
        {
          dataIndex: "created_at",
          render: (text: string) => utils.formatDate(text, FORMATTER.DATE_TIME_FORMAT_WITHOUT_SEC),
          title: "Tạo lúc",
          width: 140,
        },
        {
          dataIndex: "insured_benefit_type",
          render: (record) => <BenefitTag value={record} />,
          title: "Quyền lợi",
          width: 140,
        },

        {
          dataIndex: "claim_case_status",
          fixed: "right",
          render: (claim_case_status) => <ClaimStatus value={claim_case_status} />,
          title: "Trạng thái",
          width: 180,
        },
      ]}
    />
  );
};

export default ClaimHistory;
