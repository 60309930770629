import type { IdentityRolesEnum } from "sdk/gql/graphql";

import { type DocumentNode, type OperationVariables, type QueryHookOptions, type TypedDocumentNode, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

type PQueryHookOptions<TData, TVariables extends OperationVariables = OperationVariables> = {
  role?: IdentityRolesEnum;
} & QueryHookOptions<TData, TVariables>;

/**
 * A wrapper around `useQuery` that adds the `x-hasura-role` header to the request. All authenticated queries should use this hook.
 */
function usePQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: PQueryHookOptions<TData, TVariables>,
) {
  const { selectedRole } = useAuth();
  const role = options?.role ?? selectedRole;

  // eslint-disable-next-line deprecate/function
  return useQuery<TData, TVariables>(
    query,
    role == null
      ? { skip: true }
      : {
          ...options,
          context: { headers: { "x-hasura-role": role } },
        },
  );
}

export default usePQuery;
