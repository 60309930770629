import type { SlvClaimCaseGroupFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";

import { Button, Descriptions, Modal, Space, Tooltip } from "antd";
import Content from "app/common/components/Content";
import ExternalLink from "app/common/components/ExternalLink";
import LabelDisplay from "app/common/components/LabelDisplay";
import TableList from "app/common/components/TableListV2";
import { PORTAL_PATH } from "app/portal/config/paths";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { FORMATTER, TYPE } from "config/constants";
import { utils } from "libs/utils";
import { BookText } from "lucide-react";
import { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { InsuredCertificateAgentTypesEnum } from "sdk/gql/types";

import type { InfoItem } from "../../types";

interface Props {
  claim: ClaimContextType["claim"];
  loading: boolean;
}

const renderData = <T extends unknown>(data: T | null | undefined, handledData?): ReturnType<InfoItem<Props["claim"]>["render"]> =>
  data === "" || data === "null" || data == null ? "--" : handledData ?? data;

const InsuredPersonInfo = ({ claim, loading }: Props) => {
  const { claimType, isLifeInsuranceRider } = useClaim();
  const [modal, contextHolder] = Modal.useModal();
  const schema = useMemo(() => {
    const schematic: InfoItem<{
      agent: NonNullable<Props["claim"]>["agent"] | null | undefined;
      insuredCertificate: NonNullable<Props["claim"]>["insured_certificate"] | undefined;
      policy: NonNullable<Props["claim"]>["insured_certificate"]["policy"] | undefined;
      slvClaimGroup: SlvClaimCaseGroupFragment | null | undefined;
    }>[] = [
      {
        label: "TÊN NĐBH",
        render: ({ insuredCertificate }) => (
          <Space>
            {renderData(insuredCertificate?.insured_person.name)} <LabelDisplay editable objectId={claim?.insured_person_id} />
          </Space>
        ),
      },
      {
        label: "Mã NĐBH",
        render: ({ insuredCertificate }) => insuredCertificate?.insured_person.insured_number,
      },
      {
        label: "SỐ CMND/CCCD",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.paper_id),
      },
      {
        label: "NGÀY CẤP CMND",
        render: ({ insuredCertificate }) =>
          renderData(insuredCertificate?.insured_person.paper_issued_at, utils.formatDate(insuredCertificate?.insured_person.paper_issued_at, FORMATTER.DATE_FORMAT)),
      },
      {
        label: "NƠI CẤP CMND",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.paper_issued_place),
      },
      {
        label: "NGÀY SINH",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.dob, utils.formatDate(insuredCertificate?.insured_person.dob, FORMATTER.DATE_FORMAT)),
      },
      {
        label: "EMAIL",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.email ?? insuredCertificate?.insured_person.email),
      },
      {
        label: "SĐT",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.phone ?? insuredCertificate?.insured_person.phone),
      },
      {
        label: "GIỚI TÍNH",
        render: ({ insuredCertificate }) =>
          renderData(
            insuredCertificate?.insured_person.gender,
            insuredCertificate?.insured_person.gender == null ? null : TYPE.GENDER_TYPE[insuredCertificate.insured_person.gender],
          ),
      },
      {
        label: "TÌNH TRẠNG HÔN NHÂN",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.marital_status_info?.comment),
      },
      {
        label: "NGHỀ NGHIỆP",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.mbal_insured_person?.job),
      },
      {
        label: "PHÂN LOẠI NGHỀ NGHIỆP",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.mbal_insured_person?.occupation_class),
      },
      {
        label: "Địa chỉ",
        render: ({ insuredCertificate }) => renderData(insuredCertificate?.insured_person.mbal_insured_person?.address),
      },
    ];
    if (claimType.slvHs) {
      schematic.push(
        {
          label: "TÊN CLAIMANT",
          render: ({ slvClaimGroup }) => renderData(slvClaimGroup?.claimant_name),
        },
        {
          label: "SĐT CLAIMANT",
          render: ({ slvClaimGroup }) => renderData(slvClaimGroup?.claimant_phone),
        },
      );
    }
    if (isLifeInsuranceRider) {
      schematic.push(
        {
          label: "MÃ TƯ VẤN TÀI CHÍNH",
          render: ({ agent }) => renderData(agent?.code),
        },
        {
          label: "TÊN TƯ VẤN TÀI CHÍNH",
          render: ({ agent }) => (
            <div data-id={agent?.id}>
              <Space>
                {renderData(agent?.name)}
                <Tooltip title="Xem tất cả claim case của TVTC">
                  <Button
                    icon={<BookText size={16} />}
                    onClick={() =>
                      modal.info({
                        content: (
                          <TableList
                            data={claim?.insured_certificate.insured_certificate_agents.flatMap((a) => a.agent.claim_cases)}
                            schema={[
                              {
                                key: "code",
                                render: (record) => <ExternalLink to={generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: record.id })}>{record.code}</ExternalLink>,
                                title: "Mã claim",
                              },
                            ]}
                          />
                        ),
                        title: "Danh sách claim case của TVTC",
                      })
                    }
                    type="link"
                  />
                </Tooltip>
              </Space>
              <LabelDisplay editable objectId={agent?.id} />
            </div>
          ),
        },
        {
          label: "Email TƯ VẤN TÀI CHÍNH",
          render: ({ agent }) => renderData(agent?.email),
        },
        {
          label: "SĐT TƯ VẤN TÀI CHÍNH",
          render: ({ agent }) => renderData(agent?.phone),
        },
        {
          label: "KÊNH TƯ VẤN TÀI CHÍNH",
          render: ({ agent }) => renderData(agent?.group_code),
        },
      );
    }
    return schematic;
  }, [claim?.insured_certificate.insured_certificate_agents, claim?.insured_person_id, claimType.slvHs, isLifeInsuranceRider, modal]);

  return (
    <Content>
      {contextHolder}
      <Descriptions
        bordered
        items={schema.map((item) => ({
          children: item.render({
            agent: claim?.agent ?? claim?.insured_certificate.insured_certificate_agents.find((agent) => agent.type === InsuredCertificateAgentTypesEnum.Current)?.agent,
            insuredCertificate: claim?.insured_certificate,
            policy: claim?.insured_certificate.policy,
            slvClaimGroup: claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group,
          }),
          key: item.label,
          label: item.label,
        }))}
        labelStyle={{ fontWeight: "bold" }}
        title="Thông tin NĐBH"
      />
    </Content>
  );
};

export default InsuredPersonInfo;
