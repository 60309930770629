import type { InsuranceDeductible } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/InsuranceDeductible";
import type BalanceDetailFragment from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/BalanceDetailFragment";
import type ClaimInsuredBenefitDetailDocument from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/graphql/ClaimInsuredBenefitDetailDocument";
import type { PlanInsuredBenefitOnClaimDetailFragment } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.generated";
import type { ClaimContextType, ClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { ClaimCaseStatusesEnum, ClaimDetailQuery, DateRangeForClaimFragment, InsuredBenefitTypesEnum } from "sdk/gql/graphql";
import type { FragmentOf, ResultOf } from "sdk/v2/graphql";

import getInsuranceDeductible from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimBenefit/InsuranceDeductible";
import { CLAIM_DECISION_STATUSES, LOCAL_STORAGE_KEYS } from "config/constants";
import { addDays, differenceInDays, startOfDay } from "date-fns";
import { utils } from "libs/utils";
import { min } from "lodash";
import { PlanBalanceTypesEnum, PlanInsuredBenefitFormulaTypesEnum } from "sdk/gql/types";

import type { AssessmentClaimCaseDetail, PayableBenefitOutput } from "../types";

import { getLimitBenefit, getPayableBenefits } from "../helpers";

export const parseIdFromKey = (key?: string) => {
  const [certificateHistoryId, _, planInsuredBenefitId] = (key ?? "").split("_");
  return { certificateHistoryId, planInsuredBenefitId };
};

export const buildDateRangeTitle = (dateRange: DateRangeForClaimFragment) => {
  const { gracePeriodStartDate, policy_plan, sumAssured } = dateRange;
  return [policy_plan?.plan_name ?? "", sumAssured == null ? "" : `- STBH: ${utils.formatNumber(sumAssured)} VND`, gracePeriodStartDate == null ? "" : "- Ân hạn"].join("");
};

type displayAssessmentFields = {
  displayAmount: boolean;
  displayDateRange: boolean;
  displayPercentage: boolean;
  displayUnitName: null | string;
  hasDayBenefit: boolean;
  hasTimeBenefit: boolean;
};

type DisplayFieldsType = {
  balanceDetails?: Pick<NonNullable<AssessmentClaimCaseDetail["balanceDetails"]>[number], "type">[];
  formulaType?: AssessmentClaimCaseDetail["formulaType"] | null;
};

export const getDisplayFields = (claimCaseDetail?: DisplayFieldsType, claimType?: ClaimType, insuredBenefitType?: InsuredBenefitTypesEnum) => {
  let displayRequest: displayAssessmentFields = {
    displayAmount: false,
    displayDateRange: false,
    displayPercentage: false,
    displayUnitName: null,
    hasDayBenefit: false,
    hasTimeBenefit: false,
  };
  let displayPaid: displayAssessmentFields = {
    displayAmount: false,
    displayDateRange: false,
    displayPercentage: false,
    displayUnitName: null,
    hasDayBenefit: false,
    hasTimeBenefit: false,
  };
  if (claimCaseDetail == null) {
    return { paid: displayPaid, request: displayRequest };
  }
  let displayAmount = false;
  let displayUnitName: null | string = null;
  let displayDateRange = false;
  let hasDayBenefit = false;
  let hasTimeBenefit = false;
  let displayPercentage = false;
  const { balanceDetails, formulaType } = claimCaseDetail;

  if (balanceDetails?.some(({ type }) => [PlanBalanceTypesEnum.HospitalConfinementIndemnity, PlanBalanceTypesEnum.TotalDisablement].includes(type)) !== true) {
    displayAmount = true;
  }
  switch (true) {
    case balanceDetails?.some(({ type }) => [PlanBalanceTypesEnum.TotalDisablement].includes(type)): {
      displayUnitName = "phần trăm";
      displayPercentage = true;

      break;
    }
    case balanceDetails?.some(({ type }) => [PlanBalanceTypesEnum.CasesPerYear].includes(type)): {
      displayUnitName = "vụ";
      hasTimeBenefit = true;

      break;
    }
    case balanceDetails?.some(({ type }) => [PlanBalanceTypesEnum.SalaryMonthPerYear].includes(type)): {
      displayUnitName = "tháng";
      hasDayBenefit = true;

      break;
    }
    case balanceDetails?.some(({ type }) =>
      [
        PlanBalanceTypesEnum.DaysPerCertificate,
        PlanBalanceTypesEnum.DaysPerMbHospitalConfinementTime,
        PlanBalanceTypesEnum.DaysPerTime,
        PlanBalanceTypesEnum.DaysPerYear,
        PlanBalanceTypesEnum.SalaryDayPerYear,
        PlanBalanceTypesEnum.SumAssuredPercentagePerCertificate,
      ].includes(type),
    ) === true ||
      (balanceDetails?.some(({ type }) => PlanBalanceTypesEnum.CurrencyPerDay === type) === true && insuredBenefitType === "InPatient"): {
      displayUnitName = "ngày";
      displayDateRange = true;
      hasDayBenefit = true;

      break;
    }
    default: {
      break;
    }
  }

  switch (formulaType) {
    case PlanInsuredBenefitFormulaTypesEnum.MbInpatient: {
      displayRequest = { ...displayRequest, displayDateRange, displayPercentage, displayUnitName, hasDayBenefit, hasTimeBenefit };
      displayPaid = { displayAmount, displayDateRange, displayPercentage, displayUnitName, hasDayBenefit, hasTimeBenefit };
      break;
    }
    case PlanInsuredBenefitFormulaTypesEnum.MbSurgery: {
      displayRequest = { ...displayRequest, displayDateRange, displayPercentage, displayUnitName, hasDayBenefit, hasTimeBenefit };
      displayPaid = { ...displayPaid, displayAmount, hasDayBenefit, hasTimeBenefit };
      break;
    }
    default: {
      displayRequest = { displayAmount, displayDateRange, displayPercentage, displayUnitName, hasDayBenefit, hasTimeBenefit };
      displayPaid = { displayAmount, displayDateRange, displayPercentage, displayUnitName, hasDayBenefit, hasTimeBenefit };
      break;
    }
  }

  if (claimType?.slvHs === true) {
    displayRequest = { ...displayRequest, displayAmount: false };
    displayPaid = { ...displayPaid, displayAmount: false };
  }

  return { paid: displayPaid, request: displayRequest };
};

export type CalcPayableAmount = {
  copayAmount: number;
  coverageAmount: number;
  coverageAmountAfterCopay?: number;
  deductibleAmount: number;
  expectedPayableAmount: number;
  limitAmount: number;
  shortfallAmount: number;
  totalPayableAmount: number;
};
export const calcPayableAmount = ({
  caller,
  claimCaseDetail,
  claimType,
  copayRatio,
  deductibleAmount = 0,
  nonPaidAmount,
  payableBenefit,
  totalRequestAmount,
}: {
  caller?: string;
  claimCaseDetail?: AssessmentClaimCaseDetail;
  claimType: ClaimContextType["claimType"];
  copayRatio?: null | number;
  deductibleAmount?: number;
  nonPaidAmount: number;
  payableBenefit?: PayableBenefitOutput;
  totalRequestAmount: number;
}): CalcPayableAmount => {
  const isDebuggingClaimAssessment = localStorage.getItem(LOCAL_STORAGE_KEYS.DEBUG_CLAIM_ASSESSMENT) === "true";
  const limitAmount = Number(payableBenefit?.totalPayableAmount) > 0 ? Number(payableBenefit?.totalPayableAmount) : 0;

  if (claimCaseDetail?.balanceDetails?.find((b) => b.balance_detail?.type === PlanBalanceTypesEnum.TotalDisablement) != null) {
    return {
      copayAmount: 0,
      coverageAmount: limitAmount,
      deductibleAmount: 0,
      expectedPayableAmount: payableBenefit?.totalPayableAmount ?? 0,
      limitAmount,
      shortfallAmount: 0,
      totalPayableAmount: limitAmount,
    };
  }

  const coverageAmount = totalRequestAmount - nonPaidAmount;
  if (claimType.fwdMr) {
    const shortfallAmount = (() => {
      if (limitAmount > coverageAmount) return 0;
      return coverageAmount - limitAmount;
    })();
    const copayAmount = (() => {
      if (shortfallAmount === coverageAmount) return 0;
      return (coverageAmount * (copayRatio ?? 0)) / 100;
    })();
    const coverageAmountAfterCopay = Math.max(0, coverageAmount - copayAmount - deductibleAmount);
    const expectedPayableAmount = (() => {
      if (limitAmount > coverageAmountAfterCopay) return coverageAmountAfterCopay;
      return limitAmount;
    })();
    const totalPaidAmount = expectedPayableAmount;

    return {
      copayAmount,
      coverageAmount,
      coverageAmountAfterCopay,
      deductibleAmount,
      expectedPayableAmount,
      limitAmount,
      shortfallAmount,
      totalPayableAmount: totalPaidAmount,
    };
  }

  const expectedPaidAmount = limitAmount > coverageAmount ? coverageAmount : limitAmount;
  const copayAmount = (() => {
    if (claimCaseDetail?.fullDetail?.plan_insured_benefit.apply_copay === false) return 0;
    return (expectedPaidAmount * (copayRatio ?? 0)) / 100;
  })();
  const totalPaidAmount = Math.max(0, expectedPaidAmount - copayAmount - deductibleAmount);
  const shortfallAmount = limitAmount > coverageAmount ? 0 : coverageAmount - limitAmount;

  if (isDebuggingClaimAssessment && claimCaseDetail?.updatedAt == null) {
    // eslint-disable-next-line no-console
    console.table([
      {
        calledBy: caller,
        copayAmount,
        coverageAmount,
        deductibleAmount,
        expectedPaidAmount,
        limitAmount,
        shortfallAmount,
        totalPaidAmount,
      },
    ]);
  }

  return {
    copayAmount,
    coverageAmount,
    deductibleAmount,
    expectedPayableAmount: expectedPaidAmount,
    limitAmount,
    shortfallAmount,
    totalPayableAmount: totalPaidAmount,
  };
};

const transformBalanceDetails = (
  balanceDetails?: Pick<
    FragmentOf<typeof BalanceDetailFragment>,
    | "balance"
    | "balanceRemaining"
    | "balanceRemainingBuffer"
    | "balanceUsed"
    | "currentBalanceUseBufferClaimDetails"
    | "exclusiveBalanceRemainingBuffer"
    | "id"
    | "name"
    | "planBalanceId"
    | "type"
  >[],
) =>
  balanceDetails?.map((bd) => ({
    balance: bd.balance,
    balanceRemain: bd.balanceRemaining,
    balanceRemainBuffer: bd.balanceRemainingBuffer,
    balanceUsed: bd.balanceUsed,
    currentBalanceUseBufferClaimDetails: bd.currentBalanceUseBufferClaimDetails,
    exclusiveBalanceRemainingBuffer: bd.exclusiveBalanceRemainingBuffer,
    name: bd.name,
    planBalanceId: bd.id,
    type: bd.type,
  })) ?? [];

export const calcCopayAmount = ({
  claimCaseDetail,
  claimCaseDetailId,
  claimType,
  copayRatio,
  deductibleAmount,
}: {
  claimCaseDetail: AssessmentClaimCaseDetail;
  claimCaseDetailId?: string;
  claimType: ClaimContextType["claimType"];
  copayRatio: number;
  deductibleAmount?: number;
}) => {
  const paidBenefit = getPayableBenefits({
    claimCaseDetailId,
    input: [
      {
        balanceDetails: transformBalanceDetails(claimCaseDetail.balanceDetails),
        planBenefitId: claimCaseDetail.planInsuredBenefitId,
        requestTime: claimCaseDetail.requestTime ?? 0,
        totalRequestAmount: claimCaseDetail.totalRequestAmount ?? 0,
      },
    ],
  })[0];

  const { copayAmount } = calcPayableAmount({
    claimType,
    copayRatio,
    deductibleAmount,
    nonPaidAmount: claimCaseDetail.nonPaidAmount ?? 0,
    payableBenefit: paidBenefit,
    totalRequestAmount: claimCaseDetail.totalRequestAmount ?? 0,
  });
  return copayAmount;
};

export const getInitialClaimDetailValue = ({
  claim,
  claimDetailList,
  claimInsuredBenefitDetail,
  claimType,
  copayRatio,
  key,
  planInsuredBenefits,
  selectedDateRangeKey,
  selectedPlan,
}: {
  claim?: ClaimDetailQuery["claim_cases_by_pk"];
  claimDetailList?: AssessmentClaimCaseDetail[];
  claimInsuredBenefitDetail: ResultOf<typeof ClaimInsuredBenefitDetailDocument>["claimInsuredBenefitDetail"];
  claimType: ClaimContextType["claimType"];
  copayRatio?: null | number;
  deductibleAmount?: number;
  key: string;
  planInsuredBenefits?: { [key: string]: PlanInsuredBenefitOnClaimDetailFragment[] } | null;
  selectedDateRangeKey: string;
  selectedPlan?: DateRangeForClaimFragment;
}): AssessmentClaimCaseDetail => {
  let requestFromDate: Date | undefined;
  let requestToDate: Date | undefined;
  let paidFromDate: Date | undefined;
  let paidToDate: Date | undefined;
  let requestTime = 1;
  let paidTime = 1;
  const assessedTime = 1;
  let totalPaidAmount = 0;
  let totalRequestAmount = 0;
  let paidAmount: null | number = null;
  let requestAmount: null | number = null;
  const requestPercentage = 0;
  const paidPercentage = 0;
  const nonPaidAmount = 0;
  const formulaType = claimInsuredBenefitDetail.plan_insured_benefit?.formula_type;
  const balanceDetails = transformBalanceDetails(claimInsuredBenefitDetail.balanceDetails);

  // const hasTimeUnit = balanceDetails.some((bd) => [PlanBalanceTypesEnum.CasesPerYear, PlanBalanceTypesEnum.DaysPerTime].includes(bd.type));
  const { request } = getDisplayFields({
    balanceDetails,
    formulaType,
  });
  const admissionDate = claim?.admission_date == null ? undefined : startOfDay(new Date(claim.admission_date));
  const dischargeDate = claim?.discharge_date == null ? undefined : startOfDay(new Date(claim.discharge_date));

  const shouldFillDate = [PlanInsuredBenefitFormulaTypesEnum.MbSurgery, PlanInsuredBenefitFormulaTypesEnum.SlvSurgery].includes(formulaType ?? "") === false;
  if (shouldFillDate) {
    requestFromDate = admissionDate;
    requestToDate = dischargeDate;
    requestTime = requestFromDate != null && requestToDate != null ? differenceInDays(requestToDate, requestFromDate) : requestTime;

    paidFromDate = admissionDate;
    paidToDate = dischargeDate;
    const paidRangeDiff = paidFromDate != null && paidToDate != null ? differenceInDays(paidToDate, paidFromDate) : paidTime;
    const { daysPerTimeBalanceRemaining, daysPerYearBalanceRemaining } = getLimitBenefit({
      balanceDetails,
    });

    const paidTimeLimit =
      daysPerTimeBalanceRemaining != null || daysPerYearBalanceRemaining != null
        ? Math.min(Number(daysPerTimeBalanceRemaining ?? daysPerYearBalanceRemaining), Number(daysPerYearBalanceRemaining ?? daysPerTimeBalanceRemaining))
        : null;
    paidTime = paidTimeLimit == null ? paidRangeDiff : Math.min(paidRangeDiff, paidTimeLimit);

    if (paidTime < paidRangeDiff && paidFromDate != null) {
      paidToDate = addDays(paidFromDate, paidTime);
    }
  }

  const amountPerDay = (() => {
    const coefficient = claimInsuredBenefitDetail.plan_insured_benefit?.coefficient;
    if (formulaType == null) {
      // không có số tiền theo ngày
      return null;
    }

    return (selectedPlan?.sumAssured ?? 0) * (coefficient ?? 1);
  })();

  const [paidBenefit] = getPayableBenefits({
    amountPerDay,
    input: [
      {
        balanceDetails,
        planBenefitId: claimInsuredBenefitDetail.planInsuredBenefitId,
        requestTime,
        totalRequestAmount,
      },
    ],
    policySumAssured: claim?.insured_certificate.policy.sum_assured,
  });

  // khi quyền lợi không có hạn mức nào liên quan đến ngày, mặc định số ngày yêu cầu là 1
  if (request.hasTimeBenefit === false && request.hasDayBenefit === false) {
    requestTime = 1;
  }

  if (formulaType != null) {
    const amount = (selectedPlan?.sumAssured ?? 0) * (claimInsuredBenefitDetail.plan_insured_benefit?.coefficient ?? 1);
    const usePaidTimeFormulaType = [PlanInsuredBenefitFormulaTypesEnum.MbInpatient, PlanInsuredBenefitFormulaTypesEnum.SlvInpatient].includes(formulaType);
    totalPaidAmount = amount * (usePaidTimeFormulaType ? paidTime : 1);
    totalRequestAmount = amount * requestTime;
    paidAmount = amount;
    requestAmount = amount;
  }

  const { copayAmount, coverageAmount, shortfallAmount } = calcPayableAmount({
    claimType,
    copayRatio,
    nonPaidAmount,
    payableBenefit: paidBenefit,
    totalRequestAmount,
  });

  const newClaimCaseDetail: AssessmentClaimCaseDetail = {
    assessedTime: min([paidTime, paidBenefit?.paidTime]),
    balanceDetails: claimInsuredBenefitDetail.balanceDetails,
    coefficient: claimInsuredBenefitDetail.plan_insured_benefit?.coefficient ?? undefined,
    copayAmount,
    coverageAmount,
    formulaType: claimInsuredBenefitDetail.plan_insured_benefit?.formula_type ?? undefined,
    fullDetail: { ...claimInsuredBenefitDetail, created_at: new Date().toISOString(), created_user: null, updated_at: new Date().toISOString(), updated_user: null },
    key,
    note: "",
    paidAmount: paidAmount ?? totalPaidAmount / paidTime,
    paidDateRange: paidFromDate != null && paidToDate != null ? [paidFromDate, paidToDate] : undefined,
    paidFromDate,
    paidPercentage,
    paidTime: min([paidTime, paidBenefit?.paidTime]),
    paidToDate,
    planId: claimInsuredBenefitDetail.plan_insured_benefit?.plan_id,
    planInsuredBenefitId: claimInsuredBenefitDetail.planInsuredBenefitId,
    requestAmount: requestAmount ?? totalRequestAmount / requestTime,
    requestDateRange: requestFromDate != null && requestToDate != null ? [requestFromDate, requestToDate] : undefined,
    requestFromDate,
    requestPercentage,
    requestTime,
    requestToDate,
    shortfallAmount,
    totalPaidAmount,
    totalRequestAmount,
  };

  // áp dụng miễn thường
  return (() => {
    const insuranceDeductibleLimit = getInsuranceDeductible({
      claim,
      claimDetailList: [...(claimDetailList ?? []), newClaimCaseDetail],
      currentClaimDetail: newClaimCaseDetail,
      planInsuredBenefits,
      selectedDateRangeKey,
      selectedPlan,
    });

    const paidTimePreference = calculatePaidTime({
      insuranceDeductibleLimit,
    });

    const filledPaidTime = newClaimCaseDetail.paidTime == null || paidTimePreference.paidTime == null ? null : min([newClaimCaseDetail.paidTime, paidTimePreference.paidTime]);

    const newPaidTime = filledPaidTime ?? newClaimCaseDetail.paidTime;
    newClaimCaseDetail.paidTime = newPaidTime;
    newClaimCaseDetail.totalPaidAmount = (newClaimCaseDetail.paidAmount ?? 1) * (newPaidTime ?? 1);

    return newClaimCaseDetail;
  })();
};

export const calculatePayableAmountForClaimCaseDetail = ({
  amountPerDay,
  claimCaseDetail,
  claimStatus,
  policySumAssured,
}: {
  amountPerDay?: null | number;
  claimCaseDetail?: AssessmentClaimCaseDetail;
  claimStatus?: ClaimCaseStatusesEnum;
  policySumAssured?: null | number;
}) => {
  const isClaimApproved = claimStatus != null && CLAIM_DECISION_STATUSES.includes(claimStatus);
  const balanceDetails = transformBalanceDetails(claimCaseDetail?.balanceDetails);

  return getPayableBenefits({
    amountPerDay,
    claimCaseDetail,
    claimCaseDetailId: claimCaseDetail?.id,
    input: [
      {
        approvedPaid: isClaimApproved
          ? {
              paidTime: claimCaseDetail?.paidTime ?? 0,
              totalPaidAmount: claimCaseDetail?.totalPaidAmount ?? 0,
            }
          : undefined,
        balanceDetails,
        planBenefitId: claimCaseDetail?.planInsuredBenefitId ?? "",
        requestTime: claimCaseDetail?.requestTime ?? 0,
        totalRequestAmount: claimCaseDetail?.totalRequestAmount ?? 0,
      },
    ],
    policySumAssured,
  })[0];
};

export const calculatePaidTime = ({ insuranceDeductibleLimit }: { insuranceDeductibleLimit: InsuranceDeductible | null }) => {
  const paidTime = insuranceDeductibleLimit?.limitPaidTime;

  return {
    paidTime,
  };
};
